import styled, { css } from 'styled-components';

const SliderArrowWrapper = styled.button`
  ${({ disabled, isPrev, isNext, theme }) => css`
    background-color: transparent;
    border: 0;
    line-height: 40px;
    height: 40px;
    color: ${disabled ? theme.color('border') : theme.blue};
    font-size: 1rem;
    width: 24px;
    ${isPrev && `left: 0`};
    ${isNext && `right: 0`};

    &.slick-disabled {
      cursor: not-allowed;

      &:hover {
        background-color: transparent;
        color: ${theme.color('border')} !important;
      }
    }

    &:not(.slick-disabled):focus,
    &:not(.slick-disabled):hover {
      color: ${theme.color('blue-font')};
    }

    &::before {
      display: none;
    }
  `}
`;

export default SliderArrowWrapper;
