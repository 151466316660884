import { defineMessages } from 'react-intl';

export default defineMessages({
  Title: {
    id: 'Screens.FileImportsClients.Create.Title',
    defaultMessage: 'Error.Screens.FileImportsClients.Create.Title',
    description: 'FileImportsClients page title',
  },
  Create: {
    id: 'Buttons.Create',
    defaultMessage: 'Error.Buttons.Create',
  },
  NoElement: {
    id: 'Shared.NoElement',
    defaultMessage: 'Error.Shared.NoElement',
  },
  SuccessTitle: {
    id: 'Message.Success',
    defaultMessage: 'Error.Message.Success',
    description: 'Toast title in case of success',
  },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
    description: 'Toast title in case of error',
  },
  SuccessBody: {
    id: 'Screens.FileImportsClients.Create.SuccessBody',
    defaultMessage: 'Error.Screens.FileImportsClients.Create.SuccessBody',
  },
});
