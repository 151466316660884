import React from 'react';
import { useIntl } from 'react-intl';
import { FormGroup } from 'reactstrap';
import { FieldArray } from 'formik';

import {
  FormInputAutosuggest,
  FormInput,
  FormLabel,
  FormInputLogo,
} from 'components';
import { service } from 'services';
import { api } from 'utils';
import messages from './messages';

const getClientsSellsy = async (value) => {
  try {
    const response = await service.get(api.admin.getDatalake(value));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const Customer = ({
  errors,
  customerLogo,
  initialValues,
  setFieldValue,
  setFormLogoData,
  touched,
}) => {
  const { formatMessage } = useIntl();
  const handleAddClientSuggestion = (clientSellsy) => {
    setFieldValue('refClientSellsy', clientSellsy.externalPartnerId);
    setFieldValue('labelClientSellsy', clientSellsy.partnerLabel);
  };

  return (
    <>
      <FormInputLogo
        defaultImage={
          typeof customerLogo !== 'undefined' ? customerLogo : undefined
        }
        name='logoFile'
        refLogoName='refLogo'
        setFormLogoData={setFormLogoData}
      />
      <FormInput
        id='code'
        label={formatMessage(messages.Code)}
        name='code'
        required
        type='text'
      />
      <FormInput
        id='label'
        label={formatMessage(messages.Label)}
        name='label'
        required
        type='text'
      />
      <FieldArray>
        <FormGroup>
          <FormLabel required>{formatMessage(messages.ClientSellsy)}</FormLabel>
          <FormInputAutosuggest
            fetchRequest={getClientsSellsy}
            initialValue={initialValues.labelClientSellsy}
            inputProperties={{
              invalid: errors?.refClientSellsy && touched?.refClientSellsy,
              name: 'conversationReason',
              placeholder:
                customerLogo?.labelClientSellsy ||
                formatMessage(messages.EnterClientSellsy),
            }}
            labelKey='partnerLabel'
            onSuggestionSelected={handleAddClientSuggestion}
          />
          {errors?.labelClientSellsy &&
            errors?.refClientSellsy &&
            touched.labelClientSellsy &&
            touched.refClientSellsy && (
              <small className='text-danger'>{errors.labelClientSellsy}</small>
            )}
        </FormGroup>
      </FieldArray>
    </>
  );
};

export default Customer;
