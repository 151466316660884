import * as yup from 'yup';
import messages from './messages';

export default (languagesLength, intl) =>
  yup.object().shape({
    code: yup.string().required('Shared.FieldRequired'),
    name: yup
      .array()
      .of(
        yup.object().shape({
          content: yup.string().required('Shared.FieldRequired'),
        }),
      )
      .min(parseInt(languagesLength, 10), 'Tableau requis'),
    refOrganizationConfig: yup
      .string()
      .required(intl.formatMessage({ ...messages.FieldRequired })),
  });
