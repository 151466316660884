import React from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Formik, Form } from 'formik';
import { Col, Row, Spinner } from 'reactstrap';
import { historyShape } from 'react-router-props';

import { service } from 'services';
import { api, sendToast, urlSchema, useFetch } from 'utils';
import { Role } from 'models';
import { Button, PageHeader } from 'components';
import validationSchema from 'models/Role/validationSchema';
import { ToastTypes } from '../../../constants';
import messages from './messages';

const Update = ({ history }) => {
  const { roleId } = useParams();
  const [role, isRoleLoading] = useFetch(api.roles.getDetails(roleId));

  const onSubmit = (values, { setSubmitting }) => {
    service
      .put(api.roles.put(roleId), values)
      .then(({ data }) => {
        if (typeof data.succeeded !== 'undefined' && !data.succeeded) {
          sendToast(ToastTypes.error, {
            header: (
              <FormattedMessage
                values={{ code: role.code }}
                {...messages.Fail}
              />
            ),
            body: data.errorSet[0].description,
          });
        } else {
          sendToast(ToastTypes.success, {
            body: (
              <FormattedMessage
                values={{ code: values.code }}
                {...messages.Success}
              />
            ),
          });
          history.push(urlSchema.roles.list);
        }
      })
      .catch((err) => {
        sendToast(ToastTypes.error, {
          header: (
            <FormattedMessage values={{ code: role.code }} {...messages.Fail} />
          ),
          body: (
            <>
              <span className='m-0 small'>
                Code: {err?.response?.status} ({err?.response?.statusText})
              </span>
              <br />
              <span className='m-0 small'>
                URL: {err?.response?.config?.url}
              </span>
            </>
          ),
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <FormattedMessage
          tagName='h1'
          values={{ code: role.code || '' }}
          {...messages.Title}
        />
      </PageHeader>
      <Row>
        <Col md='6'>
          <section className='section'>
            <div className='section__body'>
              {isRoleLoading ? (
                <Spinner color='primary' size='lg' />
              ) : (
                <Formik
                  initialValues={role}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <Role />
                      <div className='text-right'>
                        <Button
                          color='primary'
                          isProcessing={isSubmitting}
                          label='Update'
                          size='lg'
                          type='submit '
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </section>
        </Col>
      </Row>
    </>
  );
};

Update.propTypes = { history: historyShape.isRequired };

export default Update;
