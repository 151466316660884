import React from 'react';
import PropTypes from 'prop-types';

const ApiError = ({ error }) => (
  <>
    <span className='m-0 small'>
      {`Code: ${error?.response?.status} (${error?.response?.statusText})`}
    </span>
    <br />
    <span className='m-0 small'>{`URL: ${error?.response?.config?.url}`}</span>
  </>
);

ApiError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default ApiError;
