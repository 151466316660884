function createLanguagesValues(languages) {
  const langs = {};
  languages.forEach((lang) => {
    langs[lang.code] = {
      name: '',
      description: '',
      cta: '',
    };
  });
  return langs;
}

const getInitialValues = (languages) => {
  return {
    code: '',
    logo: null,
    allowFollowUpEncounter: undefined,
    step: 1,
    name: '',
    description: '',
    refSpecialty: undefined,
    refLogo: undefined,
    cta: '',
    isValid: false,
    products: [],
    profiles: [],
    rooms: [],
    options: {
      isMedicalControlService: false,
    },
    ...createLanguagesValues(languages),
  };
};

export default getInitialValues;
