import React from 'react';
import PropTypes from 'prop-types';

import DefaultLogo from 'assets/images/dialogue_e_icon.svg';

const LogoItem = ({ data, title }) => {
  return (
    <div className='model__logo'>
      <img
        src={
          data
            ? `data:${data.contentType};base64, ${data.rawFile}`
            : DefaultLogo
        }
        alt='Logo'
      />
      {title && <figcaption>{title}</figcaption>}
    </div>
  );
};

LogoItem.defaultProps = {
  title: undefined,
};

LogoItem.propTypes = {
  data: PropTypes.shape({
    contentType: PropTypes.string.isRequired,
    rawFile: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string,
};

export default LogoItem;
