import React, { useEffect } from 'react';
import { runInAction, observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { ListWrapper } from 'themes/jupiter/elements';
import { urlSchema } from 'utils';
import {
  Checkbox,
  Spinner,
  PageHeader,
  RoleManager,
  Section,
  SectionBody,
  LinksList,
} from 'components';
import SurveyStore from '../store';
import messages from '../message';

const searchOptions = observable.object({
  value: '',
  valid: true,
  networkIsPending: false,
});

//When the input text value is changing
const onSearchInputChange = (input) => {
  const { value } = input.target;
  runInAction(() => {
    searchOptions.value = value;
  });
};

//Clear the input text
const clearSearchString = () => {
  runInAction(() => {
    searchOptions.value = '';
  });
};

//Update the list and the UI
const refreshSurveyList = () => {
  runInAction(() => {
    searchOptions.networkIsPending = true;
  });
  const submitOptions = {
    IsValid: searchOptions.valid,
  };
  if (searchOptions.value.length > 0) {
    submitOptions.SearchString = searchOptions.value;
  }
  SurveyStore.getAllSurveys(submitOptions).then(() => {
    runInAction(() => {
      searchOptions.networkIsPending = false;
    });
  });
};

//on Form submitting
const onFormSubmit = (form) => {
  form.preventDefault();
  refreshSurveyList();
};

//On mount
const componentDidMount = () => {
  refreshSurveyList();
};

//Observer Component - The search input & his clear value button
const InputGroup = observer(() => {
  const { formatMessage } = useIntl();
  const searchStringIsNotEmpty = searchOptions.value.length > 0;
  return (
    <div className='input-group'>
      <input
        type='text'
        className='form-control'
        value={searchOptions.value}
        onChange={onSearchInputChange}
        placeholder={formatMessage({ ...messages['list.searchPlaceholder'] })}
      />
      {searchStringIsNotEmpty && (
        <div className='input-group-append'>
          <button
            className='btn btn-outline-primary'
            onClick={clearSearchString}
            type='button'
          >
            <i aria-hidden className='uil uil-times' />
          </button>
        </div>
      )}
    </div>
  );
});

//Observer Component - The checkbox
const ValidCheckBox = observer(() => (
  <Checkbox
    onChange={() => {
      runInAction(() => {
        searchOptions.valid = !searchOptions.valid;
      });
    }}
    checked={searchOptions.valid}
    id='is-valid'
  >
    <FormattedMessage {...messages.Valid} />
  </Checkbox>
));

//Component - Ternary wrapper
const Suspense = observer((props) =>
  SurveyStore.questionnaires.length === 0 ? (
    searchOptions.networkIsPending === true ? (
      <div className='py-3 container-fluid'>
        <Spinner color='primary' size={props.size} />
      </div>
    ) : (
      <div className='py-3 container-fluid'>
        <FormattedMessage {...messages['list.noresults']} />
      </div>
    )
  ) : (
    props.children
  ),
);

//Component - a survey list item
const SurveyListItem = (props) => {
  const {
    title,
    message,
    dateCreation,
    questionnairesProducts,
    questionnairesProfiles,
    labelSpecialty,
    idQuestionnaire,
    isLast,
  } = props;

  const ProductsLinks = () => (
    <LinksList
      color='warning'
      data={questionnairesProducts}
      idKey={(el) => el.refProduct}
      labelKey={(el) => el.productCode}
      limit={4}
      urlBase={urlSchema.products.read}
    />
  );

  const ProfilsLinks = () => (
    <LinksList
      color='primary'
      data={questionnairesProfiles}
      idKey={(el) => el.refProfile}
      labelKey={(el) => el.profileCode}
      limit={4}
      urlBase={urlSchema.profiles.read}
    />
  );

  const Message = () =>
    message?.length > 0 ? (
      <>
        <br />
        <small className='text-secondary'>{message}</small>
      </>
    ) : null;

  return (
    <div className={`container-fluid py-3${isLast ? '' : ' border-bottom'}`}>
      <div className='row'>
        <div className='col-3'>
          {title}
          <Message />
        </div>
        <div className='col-2'>{moment(dateCreation).format('DD/MM/YYYY')}</div>
        <div className='col-2'>
          <ProductsLinks />
        </div>
        <div className='col-2'>
          <ProfilsLinks />
        </div>
        <div className='col-2'>{labelSpecialty}</div>
        <div className='col-1 text-right '>
          <Link
            to={`${urlSchema.surveyDetails}/${idQuestionnaire}`}
            className='list-action-anchor'
          >
            <i className='uil uil-eye uil--md' />
          </Link>
        </div>
      </div>
    </div>
  );
};

//Observer Component - The survey list
const SurveyList = observer(() => {
  return (
    <Suspense size='sm'>
      <div className='voucherList'>
        {SurveyStore.questionnaires.map((survey, index) => {
          const isLast = index === SurveyStore.questionnaires.length - 1;
          return <SurveyListItem {...survey} key={index} {...{ isLast }} />;
        })}
      </div>
    </Suspense>
  );
});

const SubmitButton = observer(() => (
  <button disabled={searchOptions.networkIsPending} className='btn btn-primary'>
    <FormattedMessage {...messages.Research} />
  </button>
));

export const List = () => {
  useEffect(() => {
    componentDidMount();
  }, []);

  return (
    <>
      <PageHeader>
        <h1>
          <FormattedMessage {...messages['list.title']} />
        </h1>
        <RoleManager entity='FileImportConfigs' right='CRUD'>
          <Link className='btn btn-primary' to={`${urlSchema.surveyCreate}`}>
            <FormattedMessage {...messages.Create} />
          </Link>
        </RoleManager>
      </PageHeader>
      <Section>
        <SectionBody>
          <ListWrapper>
            <section className='section mb-0 '>
              <div className='section__header px-0'>
                <form className='w-100' onSubmit={onFormSubmit}>
                  <div className='row'>
                    <div className='col'>
                      <InputGroup />
                    </div>

                    <div className='col-auto'>
                      <ValidCheckBox />
                    </div>

                    <div className='col-auto'>
                      <SubmitButton />
                    </div>
                  </div>
                </form>
              </div>
              <div className='mt-3'>
                <div className='research-table__header'>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-3'>
                        <FormattedMessage {...messages['list.col.title']} />
                      </div>
                      <div className='col-2'>
                        <FormattedMessage {...messages['list.col.creation']} />
                      </div>
                      <div className='col-2'>
                        <FormattedMessage {...messages['list.col.products']} />
                      </div>
                      <div className='col-2'>
                        <FormattedMessage {...messages['list.col.profils']} />
                      </div>
                      <div className='col-2'>
                        <FormattedMessage
                          {...messages['list.col.speciality']}
                        />
                      </div>
                      <div className='col-1'></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <SurveyList />
          </ListWrapper>
        </SectionBody>
      </Section>
    </>
  );
};
