import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Formik, Form } from 'formik';
import { Spinner } from 'reactstrap';

import { ApiError, Button, FormList } from 'components';
import { service } from 'services';
import { api, sendToast, useFetch } from 'utils';
import messages from '../messages';
import { ToastTypes } from '../../../../constants';

const Clients = ({ data }) => {
  const { partnerOperatorId } = useParams();
  const { formatMessage } = useIntl();
  const [clients, areClientsLoading] = useFetch(api.clients.getListItems);

  const onSubmit = (values, { setSubmitting }) => {
    service
      .put(api.partnerOperators.putClients(values.idUser), values)
      .then((res) => {
        if (res.data.succeeded === false) {
          sendToast(ToastTypes.error, {
            body: formatMessage({ ...messages.ToastClientsFail }),
          });
          return;
        }
        sendToast(ToastTypes.success, {
          body: formatMessage({ ...messages.ToastClientsSuccess }),
        });
      })
      .catch((error) => {
        sendToast(ToastTypes.error, {
          header: formatMessage({ ...messages.ToastClientsFail }),
          body: <ApiError {...{ error }} />,
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  if (areClientsLoading) return <Spinner color='primary' size='lg' />;

  return (
    <Formik
      initialValues={{
        idUser: partnerOperatorId,
        selectedClients: data,
      }}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <Form>
          <FormList
            data={clients}
            itemLabelKey='label'
            itemValueKey='id'
            name='selectedClients'
            researchOptions={{
              isEnabled: true,
              placeholder: formatMessage({ ...messages.ClientsPlaceholder }),
            }}
          />
          <Button
            color='primary'
            isProcessing={formikProps.isSubmitting}
            label='Save'
            type='submit'
          />
        </Form>
      )}
    </Formik>
  );
};

export default Clients;
