const DoctorAccountTypes = [
  {
    label: 'Practitien avec RPPS',
    code: 'PR',
  },
  {
    label: 'Practicien avec ADELI',
    code: 'PA',
  },
  {
    label: 'Personne hors annuaire de santé',
    code: 'PN',
  },
];

export default DoctorAccountTypes;
