import { defineMessages } from 'react-intl';

export default defineMessages({
  Title: {
    id: 'Screens.Patients.Create.PatientInformation.Title',
    defaultMessage: 'Error.Screens.Patients.Create.PatientInformation.Title',
  },
  AlertIsEligible: {
    id: 'Screens.Patients.Create.ElibilitySuccess',
    defaultMessage: 'Error.Screens.Patients.Create.ElibilitySuccess',
  },
  CGUAppliance: {
    id: 'Screens.Patients.Create.PatientInformation.CGUAppliance',
    defaultMessage:
      'Error.Screens.Patients.Create.PatientInformation.CGUAppliance',
  },
  EligibilityCode: {
    id: 'Screens.Patients.Create.CheckEligibility.EligibilityCode',
    defaultMessage:
      'Error.Screens.Patients.Create.CheckEligibility.EligibilityCode',
  },
});
