export default (prefix, arrayOfKeys = [], additionalKeys = null) => {
  const autoGeneratedKeys = {};

  arrayOfKeys
    .map((key) => {
      const obj = {};
      obj[key] = {
        id: `${prefix}${key}`,
        defaultMessage: `Error.${prefix}${key}`,
      };
      return obj;
    })
    .map((e) => {
      autoGeneratedKeys[Object.keys(e)[0]] = Object.values(e)[0];
    });

  return { ...autoGeneratedKeys, ...additionalKeys };
};
