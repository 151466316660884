import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import HealthPartnersListWrapper from './elements';
import HealthPartner from './HealthPartner';

import messages from './messages';

const HealthPartnersList = ({ data, refresh }) => {
  const validProducts = data.filter((product) => product.valid);
  const invalidProducts = data.filter((product) => !product.valid);

  if (data.length === 0) {
    return (
      <FormattedMessage
        id='Shared.NoElement'
        defaultMessage='Error.Shared.NoElement'
        tagName='span'
      />
    );
  }

  return (
    <HealthPartnersListWrapper>
      {validProducts.length > 0 && (
        <HealthPartnersListWrapper.List type='valid'>
          <HealthPartnersListWrapper.ListHeader>
            <FormattedMessage {...messages.ValidHealthPartners} />
          </HealthPartnersListWrapper.ListHeader>
          <HealthPartnersListWrapper.ListBody>
            {validProducts.map((item) => (
              <HealthPartner
                key={item.subscriptionId}
                refresh={refresh}
                data={item}
                isValid
              />
            ))}
          </HealthPartnersListWrapper.ListBody>
        </HealthPartnersListWrapper.List>
      )}

      {invalidProducts.length > 0 && (
        <HealthPartnersListWrapper.List type='invalid'>
          <HealthPartnersListWrapper.ListHeader>
            <FormattedMessage {...messages.InvalidHealthPartners} />
          </HealthPartnersListWrapper.ListHeader>
          <HealthPartnersListWrapper.ListBody>
            {invalidProducts.map((item) => (
              <HealthPartner
                key={item.refProduct}
                refresh={refresh}
                data={item}
                isInvalid
              />
            ))}
          </HealthPartnersListWrapper.ListBody>
        </HealthPartnersListWrapper.List>
      )}
    </HealthPartnersListWrapper>
  );
};

HealthPartnersList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  refresh: PropTypes.func.isRequired,
};

export default HealthPartnersList;
