import { defineMessages } from 'react-intl';

export default defineMessages({
  All: {
    id: 'Shared.All',
    defaultMessage: 'Error.Shared.All',
  },
  ButtonCreate: {
    id: 'Buttons.Create',
    defaultMessage: 'Error.Buttons.Create',
  },
  ButtonShow: {
    id: 'Buttons.Show',
    defaultMessage: 'Error.Buttons.Show',
  },
  Title: {
    id: 'Menu.Specialties',
    defaultMessage: 'Error.Menu.Patients',
  },
  Invalid: {
    id: 'Shared.Invalid',
    defaultMessage: 'Error.Shared.Invalid',
  },
  NoElement: {
    id: 'Shared.NoElement',
    defaultMessage: 'Error.Shared.NoElement',
  },
  ReadDoctors: {
    id: 'Screens.Specialties.ReadDoctors',
    defaultMessage: 'Error.Screens.Specialties.ReadDoctors',
  },
  SpecialtiesCode: {
    id: 'Screens.Specialties.Code',
    defaultMessage: 'Error.Screens.Specialties.Code',
  },
  SpecialtiesOffer: {
    id: 'Screens.Specialties.Offer',
    defaultMessage: 'Error.Screens.Specialties.Offer',
  },
  SpecialtiesSearch: {
    id: 'Screens.Specialties.Search',
    defaultMessage: 'Error.Screens.Specialties.Search',
  },
  SpecialtiesValidity: {
    id: 'Screens.Specialties.Validity',
    defaultMessage: 'Error.Screens.Specialties.Validity',
  },
  Valid: {
    id: 'Shared.Valid',
    defaultMessage: 'Error.Shared.Valid',
  },
});
