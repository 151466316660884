import { defineMessages } from 'react-intl';

export default defineMessages({
  Title: {
    id: 'Screens.FileImportsClients.Update.Title',
    defaultMessage: 'Error.Screens.FileImportsClients.Update.Title',
  },
  Update: {
    id: 'Buttons.Update',
    defaultMessage: 'Error.Buttons.Update',
  },
  NoElement: {
    id: 'Shared.NoElement',
    defaultMessage: 'Error.Shared.NoElement',
  },
  SuccessTitle: {
    id: 'Message.Success',
    defaultMessage: 'Error.Message.Success',
    description: 'Toast title in case of success',
  },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
    description: 'Toast title in case of error',
  },
  SuccessBody: {
    id: 'Screens.FileImportsClients.Update.SuccessBody',
    defaultMessage: 'Error.Screens.FileImportsClients.Update.SuccessBody',
  },
});
