import * as yup from 'yup';
import moment from 'moment';

import messages from './messages';

export default (refWebServiceVerificationType, intl) => {
  return yup.object().shape({
    code: yup
      .string()
      .max(
        50,
        intl.formatMessage({ ...messages.MaxCharacters }, { number: 50 }),
      )
      .required(intl.formatMessage({ ...messages.FieldRequired })),
    dateExpiration: yup
      .date()
      .min(moment().startOf('day').toISOString(), 'Model.FieldDateMinToday')
      .nullable(),
    duration: yup.number().moreThan(0).nullable(),
    label: yup
      .string()
      .max(
        255,
        intl.formatMessage({ ...messages.MaxCharacters }, { number: 255 }),
      )
      .required(intl.formatMessage({ ...messages.FieldRequired })),
    maxQuestionCount: yup.number().moreThan(0).nullable(),
    productTag: yup
      .string()
      .max(
        255,
        intl.formatMessage({ ...messages.MaxCharacters }, { number: 255 }),
      )
      .nullable(),
    refClient: yup
      .string()
      .required(intl.formatMessage({ ...messages.FieldRequired })),
    refClientSellsy: yup
      .string()
      .required(intl.formatMessage({ ...messages.FieldRequired })),
    refVerificationType: yup
      .string()
      .required(intl.formatMessage({ ...messages.FieldRequired })),
    refWebService: yup.string().when(['refVerificationType'], {
      is: (refVerificationType) =>
        refVerificationType === refWebServiceVerificationType,
      then: yup
        .string()
        .required(intl.formatMessage({ ...messages.FieldRequired })),
      otherwise: yup.string().nullable(),
    }),
  });
};
