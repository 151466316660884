import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, Form, Formik } from 'formik';
import qs from 'qs';

import { Button, Spinner, Pagination } from '../../../components';
import { service } from '../../../services';
import { ListWrapper } from '../../../themes/jupiter/elements';
import { api, removeKeysWithEmptyString } from '../../../utils';
import PatientMdListItem from './PatientMdListItem';

const PatientsMdList = () => {
  const location = useLocation();
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [patients, setPatients] = useState([]);

  const onSubmit = (values = {}) => {
    try {
      const parameters = qs.stringify(removeKeysWithEmptyString(values), {
        skipNulls: true,
      });
      setIsLoading(true);
      service
        .get(`${api.manager.getPatients}?${parameters}`)
        .then(({ data }) => {
          setPatients(data);
          setIsLoading(false);
          window.history.pushState(
            {},
            undefined,
            `${location.pathname}?${parameters}`,
          );
        })
        .catch((error) => {
          console.log(
            'Error fetching data from getPatients API:',
            error.message || error,
          );
          setIsLoading(false);
        });
    } catch (error) {
      console.log('Unexpected error:', error.message || error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (location.search) {
      onSubmit(qs.parse(location.search, { ignoreQueryPrefix: true }));
    }
  }, [location.search]);

  return (
    <ListWrapper>
      <ListWrapper.ResearchWrapper>
        <Formik
          initialValues={{
            Products: '',
            SearchConsultationNumber: '',
            SearchEmail: '',
            SearchFirstname: '',
            SearchLastname: '',
            SearchPhone: '',
          }}
          onSubmit={onSubmit}
        >
          {({ resetForm }) => (
            <Form>
              <div className='row my-3'>
                <div className='col-lg-2 mb-3'>
                  <Field
                    className='form-control'
                    name='SearchFirstname'
                    placeholder={formatMessage({
                      id: 'Patients.FirstName',
                      defaultMessage: 'Patients.FirstName',
                    })}
                    type='text'
                  />
                </div>
                <div className='col-lg-2 mb-3'>
                  <Field
                    className='form-control'
                    name='SearchLastname'
                    placeholder={formatMessage({
                      id: 'Patients.LastName',
                      defaultMessage: 'Error.Patients.LastName',
                    })}
                    type='text'
                  />
                </div>
                <div className='col-lg-2 mb-3'>
                  <Field
                    className='form-control'
                    name='SearchEmail'
                    placeholder={formatMessage({
                      id: 'Patients.Email',
                      defaultMessage: 'Error.Patients.Email',
                    })}
                    type='text'
                  />
                </div>
                <div className='col-lg-2 mb-3'>
                  <Field
                    className='form-control'
                    name='SearchPhone'
                    placeholder={formatMessage({
                      id: 'Patients.PhoneNumber',
                      defaultMessage: 'Error.Patients.PhoneNumber',
                    })}
                    type='text'
                  />
                </div>
                <div className='col-lg-4 text-right mb-3'>
                  <button
                    className='btn btn-outline-primary mr-2'
                    data-tracking='PatientsListBtnReset'
                    onClick={() => {
                      resetForm();
                    }}
                    type='button'
                  >
                    <FormattedMessage
                      id='Buttons.Reset'
                      defaultMessage='Error.Buttons.Reset'
                    />
                  </button>
                  <Button
                    className='text-ellipsis'
                    color='primary'
                    data-tracking='PatientsListBtnSearch'
                    isProcessing={isLoading}
                    label='Research'
                    type='submit'
                  />
                </div>
                <div className='col-lg-2'>
                  <Field
                    className='form-control'
                    name='SearchConsultationNumber'
                    placeholder={formatMessage({
                      id: 'Screens.Patients.List.ConsultationNumber',
                      defaultMessage:
                        'Error.Screens.Patients.List.ConsultationNumber',
                    })}
                    type='text'
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ListWrapper.ResearchWrapper>
      <ListWrapper.Header>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-xs-5 col-lg-3'>
              <FormattedMessage
                id='Screens.Patients.List.Patient'
                defaultMessage='Error.Screens.Patients.List.Patient'
                tagName='h5'
              />
            </div>
            <div className='col-md-3 d-none d-lg-block'>
              <FormattedMessage
                id='Screens.Patients.List.Contact'
                defaultMessage='Error.Screens.Patients.List.Contact'
                tagName='h5'
              />
            </div>
            <div className='col-md-2 d-none d-lg-block'>
              <FormattedMessage
                id='Screens.Patients.List.CreationDate'
                defaultMessage='Error.Screens.Patients.List.CreationDate'
                tagName='h5'
              />
            </div>
            <div className='col-xs-4 col-lg-3'>
              <FormattedMessage
                id='Screens.Patients.List.Subscriptions'
                defaultMessage='Error.Screens.Patients.List.Subscriptions'
                tagName='h5'
              />
            </div>
          </div>
        </div>
      </ListWrapper.Header>
      {isLoading ? (
        <Spinner size='lg' />
      ) : (
        <Pagination
          items={patients}
          itemsPerPage={10}
          renderItem={(patient) => <PatientMdListItem data={patient} />}
        />
      )}
    </ListWrapper>
  );
};

export default PatientsMdList;
