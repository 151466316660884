import styled from 'styled-components';
import { NavItem } from 'reactstrap';
import { lighten } from 'polished';
import { DotLink } from '../SidebarLink/elements';

const animatedMenu = () => {
  let styles = '';

  for (let i = 2; i < 10; i++) {
    styles += `&:nth-child(${i}) {
      transition-delay: ${(i * 0.1).toPrecision(1)}s;
    }`;
  }

  return styles;
};

const Chevron = styled.i.attrs({
  'aria-hidden': true,
})``;

const HeaderCollapseMenuWrapper = styled(NavItem)`
  ${({ theme }) => `
    &&& {
      border-bottom: 1px solid ${theme.color('black-border')};

      a {
        ${theme.fontSize(theme.fontSizeSm())};
        align-items: center;
        color: ${lighten(0.5, theme.black)};
        display: flex;
        padding: ${theme.space(1)} 0;
        position: relative;
        text-decoration: none;

        &:hover,
        &:active,
        &.active {
          ${DotLink} {
            background-color: ${theme.blue};
            width: 24px;
          }
        }
      }

      ul {
        padding: 0 ${theme.space(3)} ${theme.space(3)} ${theme.space(4)};
      }

      @media ${theme.screenMdMax} {
        ${animatedMenu()};
        transition: ${theme.transitionBase};

        &:first-child {
          transition-delay: 0.1s;
        }
      }
    }
  `}
`;

const CollapseNav = styled.ul`
  ${() => `
  `}
`;

const CollapseButton = styled.button.attrs({
  type: 'button',
})`
  ${({ isOpen, theme }) => `
    background-color: transparent;
    border: 0;
    border-radius: 0;
    color: ${isOpen ? theme.white : theme.color('black-lightest')};
    display: flex;
    align-items: center;
    letter-spacing: 0.02rem;
    padding: ${theme.space(3)} 0;
    position: relative;
    transition: ${theme.transitionBase};
    width: 100%;

    &:hover {
      background-color: transparent;
      color: ${theme.white};
    }

    span {
      ${theme.fontSize(theme.fontSizeBase, theme.fontWeightBold)};
      flex-grow: 1;
      padding-left: ${theme.space(3)};
      text-align: left;
    }

    ${Chevron} {
      ${isOpen && 'transform: rotate(180deg);'}
    }

    @media ${theme.screenMdMin} {
      padding: ${theme.space(3)};
    }
  `}
`;

HeaderCollapseMenuWrapper.Button = CollapseButton;
HeaderCollapseMenuWrapper.Nav = CollapseNav;
HeaderCollapseMenuWrapper.Chevron = Chevron;

export default HeaderCollapseMenuWrapper;
