import styled, { css } from 'styled-components';

const RoomWrapper = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.color('border')};
    border-radius: ${theme.borderRadius};
    margin-bottom: ${theme.space(2)};

    & > * {
      padding: ${theme.space(3)};
    }

    & > div > *:first-of-type {
      margin-top: 0;
    }

    header {
      border-bottom: 1px solid ${theme.color('gray-lightest')};

      h3 {
        margin: 0;
        ${theme.fontSize(theme.fontSizeBase, theme.fontWeightBold)};
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  `}
`;

export { RoomWrapper };
