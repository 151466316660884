import { defineMessages } from 'react-intl';

import { generateTranslationsObject } from 'utils';

const prefix = 'FileImportsClients.';
const keys = ['Partner', 'Entity', 'AssociatedProduct', 'Status'];

const additionalKeys = {
  ButtonsRead: {
    id: 'Buttons.Read',
    defaultMessage: 'Error.Buttons.Read',
  },
  Title: {
    id: 'Menu.FileImportClients',
    defaultMessage: 'Error.Menu.FileImportClients',
  },
  Create: {
    id: 'Buttons.Create',
    defaultMessage: 'Error.Buttons.Create',
  },
  NoElement: {
    id: 'Shared.NoElement',
    defaultMessage: 'Error.Shared.NoElement',
  },
  Valid: {
    id: 'Model.Valid',
    defaultMessage: 'Error.Model.Valid',
  },
  Invalid: {
    id: 'Model.Invalid',
    defaultMessage: 'Error.Model.Invalid',
  },
};

export default defineMessages(
  generateTranslationsObject(prefix, keys, additionalKeys),
);
