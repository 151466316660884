import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonEdit: {
    id: 'Buttons.Edit',
    defaultMessage: 'Error.Buttons.Edit',
  },
  ButtonRead: {
    id: 'Buttons.Read',
    defaultMessage: 'Error.Buttons.Read',
    description: 'Button to read a product',
  },
  ButtonResearch: {
    id: 'Buttons.Research',
    defaultMessage: 'Error.Buttons.Research',
    description: 'Button to research a product',
  },
  Title: {
    id: 'Screens.Products.List.Title',
    defaultMessage: 'Error.Screens.Products.List.Title',
    description: 'Products page title',
  },
  ButtonCreateProduct: {
    id: 'Buttons.Create',
    defaultMessage: 'Error.Buttons.Create',
    description: 'Button to create product',
  },
  ButtonCreateProductCode: {
    id: 'Buttons.CreateProductCode',
    defaultMessage: 'Error.Buttons.CreateProductCode',
    description: 'Button to create a product code',
  },
  ProductsCode: {
    id: 'Products.Code',
    defaultMessage: 'Error.Products.Code',
    description: 'Products page column title Code',
  },
  ProductsCustomer: {
    id: 'Products.Customer',
    defaultMessage: 'Error.Products.Customer',
    description: 'Products page column title Customer',
  },
  ProductsSpecialty: {
    id: 'Products.Specialty',
    defaultMessage: 'Error.Products.Specialty',
    description: 'Products page column title Specialty',
  },
  ProductsVia: {
    id: 'Products.Via',
    defaultMessage: 'Error.Products.Via',
    description: 'Products page column element Via Code',
  },
  ProductsListPlaceholderResearch: {
    id: 'Screens.Products.List.PlaceholderResearch',
    defaultMessage: 'Error.Screens.Products.List.PlaceholderResearch',
    description: 'Placeholder for research',
  },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
    description: 'Toast title in case of Error',
  },
  FailBody: {
    id: 'Message.Api.Error',
    defaultMessage: 'Error.Message.Api.Error',
    description: 'Toast body in case of error',
  },
});
