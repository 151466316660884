import { defineMessages } from 'react-intl';

export default defineMessages({
  Title: {
    id: 'Screens.Applications.CreateApplication.Title',
    defaultMessage: 'Error.Screens.Applications.CreateApplication.Title',
    description: 'Title of Application create screen',
  },
  ButtonCreate: {
    id: 'Buttons.Create',
    defaultMessage: 'Error.Buttons.Create',
    description: 'Label button create',
  },
  SuccessTitle: {
    id: 'Message.Success',
    defaultMessage: 'Error.Message.Success',
    description: 'Toast title in case of success',
  },
  SuccessBody: {
    id: 'Screens.Applications.Create.SuccessBody',
    defaultMessage: 'Error.Screens.Applications.Create.SuccessBody',
    description: 'Toast body in case of success',
  },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
    description: 'Toast title in case of Error',
  },
  FailBody: {
    id: 'Screens.Applications.Create.FailBody',
    defaultMessage: 'Error.Screens.Applications.Create.FailBody',
    description: 'Toast body in case of error',
  },
  Organization: {
    id: 'Application.Organization',
    defaultMessage: 'Error.Application.Organization',
    description: 'Label Organization',
  },
});
