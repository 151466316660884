import { generateTranslationsObject } from 'utils';

const prefixPartnerOperator = 'Model.PartnerOperator.';
const keysPartnerOperator = ['Clients', 'Profiles'];
const prefixUser = 'Model.User.';
const keysUser = [
  'AccountInformation',
  'Blocked',
  'CreationDate',
  'Email',
  'FirstName',
  'LastName',
  'MobilePhone',
  'Notification',
  'Profiles',
  'RefUniverse',
  'UserInformation',
];

export default {
  ...generateTranslationsObject(prefixPartnerOperator, keysPartnerOperator, {
    CodeCompany: {
      id: 'Model.Administrator.CodeCompany',
      defaultMessage: 'Error.Model.Administrator.CodeCompany',
    },
  }),
  ...generateTranslationsObject(prefixUser, keysUser),
};
