import { defineMessages } from 'react-intl';

export default defineMessages({
  CommunicationChannel: {
    id: 'Screens.Offers.Create.CreateRoomForm.LabelChannel',
    defaultMessage: 'Error.Screens.Offers.Create.CreateRoomForm.LabelChannel',
  },
  SelectAChannel: {
    id: 'Screens.Offers.Create.CreateRoomForm.SelectAChannel',
    defaultMessage: 'Error.Message.Success',
  },
  AlreadyUsed: {
    id: 'Screens.Offers.Create.CreateRoomForm.AlreadyUsed',
    defaultMessage: 'Error.Screens.Offers.Create.CreateRoomForm.AlreadyUsed',
  }
});
