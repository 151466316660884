import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import Papa from 'papaparse';
import { FormattedMessage } from 'react-intl';
import { isNil } from 'lodash';

import { PageHeader, RoleManager, Spinner } from 'components';
import { urlSchema } from 'utils';
import Store from '../store';
import messages from '../message';

const downloadCsv = async (voucherId) => {
  const fields = [
    'code',
    'dateExpiration',
    'dateUsed',
    'usedCount',
    'useLimit',
  ];
  try {
    await Store.getVouchers(voucherId);
    const data = Store.vouchers.map((v) => {
      v.dateExpiration = moment(v.dateExpiration).format('DD/MM/YYYY hh:mm:ss');
      v.dateUsed = v.dateUsed
        ? moment(v.dateUsed).format('DD/MM/YYYY hh:mm:ss')
        : '';
      return v;
    });
    let csv = Papa.unparse(
      {
        data,
        fields,
      },
      { delimiter: ';' },
    );
    fields.forEach((field) => {
      csv = csv
        .split(field)
        .join(field.charAt(0).toUpperCase() + field.slice(1));
    });
    const blob = new Blob([csv]);
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob, { type: 'text/plain' });
    a.download = 'vouchers.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (e) {
    console.error(e);
  }
};

const Read = observer(() => {
  const { voucherId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    Store.getVoucherGroupsDetails(voucherId);
  }, []);

  return (
    <>
      <PageHeader>
        <h1>
          <FormattedMessage {...messages['details.title']} />
        </h1>
        <RoleManager entity='VoucherGroups' right='CRUD'>
          <Link
            className='btn btn-primary ml-3'
            to={`${urlSchema.vouchersGroupsEdit}/${voucherId}`}
          >
            <FormattedMessage {...messages['edit.edit']} />
          </Link>
        </RoleManager>
      </PageHeader>
      <div className='row'>
        <div className='col-lg-8'>
          <section className='section'>
            {isNil(Store.voucherGroupsDetails) ? (
              <Spinner color='primary' size='lg' />
            ) : (
              <>
                <header className='section__header'>
                  <h2>{Store.voucherGroupsDetails.name}</h2>
                </header>
                <div className='section__body'>
                  <div className='model'>
                    <div className='model__property'>
                      <h4 className='model__title'>
                        <FormattedMessage
                          {...messages['details.description']}
                        />
                      </h4>
                      <p className='model__value'>
                        {Store.voucherGroupsDetails.description}
                      </p>
                    </div>
                  </div>
                  <div className='model'>
                    <div className='model__property'>
                      <h4 className='model__title'>
                        <FormattedMessage
                          {...messages['details.creationDate']}
                        />
                      </h4>
                      <p className='model__value'>
                        {moment(
                          Store.voucherGroupsDetails.dateLastUpdate,
                        ).format('DD/MM/YYYY hh:mm:ss')}
                      </p>
                    </div>
                  </div>
                  <div className='model'>
                    <div className='model__property'>
                      <h4 className='model__title'>
                        <FormattedMessage {...messages['add.prefix']} />
                      </h4>
                      <p className='model__value'>
                        {Store.voucherGroupsDetails.prefixe}
                      </p>
                    </div>
                  </div>
                  <div className='model'>
                    <div className='model__property'>
                      <h4 className='model__title'>
                        <FormattedMessage {...messages['voucherNumber']} />
                      </h4>
                      <p className='model__value'>
                        {Store.voucherGroupsDetails.numberOfVouchers}
                      </p>
                    </div>
                  </div>
                  <div className='model'>
                    <div className='model__property'>
                      <h4 className='model__title'>
                        <FormattedMessage {...messages['add.refProduct']} />
                      </h4>
                      <Link
                        className='model__value'
                        to={`${urlSchema.products.read}/${Store.voucherGroupsDetails.refProduct}`}
                      >
                        {Store.voucherGroupsDetails.productLabel}
                      </Link>
                    </div>
                  </div>
                  <div className='model'>
                    <div className='model__property'>
                      <h4 className='model__title'>
                        <FormattedMessage {...messages['add.productTag']} />
                      </h4>
                      <p className='model__value'>
                        {Store.voucherGroupsDetails.productTag}
                      </p>
                    </div>
                  </div>
                  <button
                    className='btn btn-lg btn-primary'
                    onClick={() => {
                      downloadCsv(voucherId);
                    }}
                    type='button'
                  >
                    <FormattedMessage {...messages['details.exportCsv']} />
                  </button>
                </div>
              </>
            )}
          </section>
        </div>
      </div>
    </>
  );
});
export default Read;
