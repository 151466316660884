import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonValidate: {
    id: 'Buttons.Validate',
    defaultMessage: 'Error.Buttons.Validate',
  },
  Title: {
    id: 'Screens.Customers.ManageProductTags.Title',
    defaultMessage: 'Error.Screens.Customers.ManageProductTags.Title',
  },
  ClientTags: {
    id: 'Screens.Clients.ProductTags.ClientTags',
    defaultMessage: 'Error.Screens.Clients.ProductTags.ClientTags',
  },
  CreationDate: {
    id: 'Screens.Clients.ProductTags.CreationDate',
    defaultMessage: 'Error.Screens.Clients.ProductTags.CreationDate',
  },
  FieldRequired: {
    id: 'Shared.FieldRequired',
    defaultMessage: 'Error.Shared.FieldRequired',
  },
  LabelStats: {
    id: 'Screens.Clients.ProductTags.LabelStats',
    defaultMessage: 'Error.Screens.Clients.ProductTags.LabelStats',
  },
  NoElement: {
    id: 'Shared.NoElement',
    defaultMessage: 'Error.Shared.NoElement',
  },
  Product: {
    id: 'Screens.Clients.ProductTags.Product',
    defaultMessage: 'Error.Screens.Clients.ProductTags.Product',
  },
  ProductTag: {
    id: 'Screens.Clients.ProductTags.ProductTag',
    defaultMessage: 'Error.Screens.Clients.ProductTags.ProductTag',
  },
  SelectLabel: {
    id: 'Screens.Clients.ProductTags.SelectLabel',
    defaultMessage: 'Error.Screens.Clients.ProductTags.SelectLabel',
  },
  SelectPlaceholder: {
    id: 'Screens.Clients.ProductTags.SelectPlaceholder',
    defaultMessage: 'Error.Screens.Clients.ProductTags.SelectPlaceholder',
  },
  ToastFail: {
    id: 'Toasts.Request.Fail',
    defaultMessage: 'Error.Toasts.Request.Fail',
  },
  ToastSuccess: {
    id: 'Toasts.Clients.ProductTags.Success',
    defaultMessage: 'Error.Toasts.Clients.ProductTags.Success',
  },
});
