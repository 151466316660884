import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import { FormattedMessage } from 'react-intl';
import Select, { components } from 'react-select';
import { ThemeContext } from 'styled-components';

import FormLabel from '../FormLabel';
import FormSelectWrapper from './elements';

const TranslatedText = ({ data }) => (
  <FormattedMessage
    id={data.labelKey}
    defaultMessage={`Error.${data.labelKey}`}
    values={{ [data.labelValueKey]: data.labelValue }}
  />
);

const CustomTranslateValue = (props) => (
  <components.SingleValue {...props}>
    <TranslatedText data={props.data} />
  </components.SingleValue>
);

const CustomTranslateOption = (props) => (
  <components.Option {...props}>
    <TranslatedText data={props.data} />
  </components.Option>
);

const FormSelect = (props) => {
  const theme = useContext(ThemeContext);
  const {
    className,
    hasError,
    label,
    defaultValue,
    name,
    onChange,
    options,
    required,
    errorMsg,
    meta,
    hasTranslatedOptions,
    ...otherProperties
  } = props;

  const styles = {
    control: (provided) => ({
      ...provided,
      borderColor: hasError ? theme.color('danger') : theme.color('border'),
      borderRadius: theme.borderRadius,
      boxShadow: 'none',
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: theme.borderRadiusSm,
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      borderTopRightRadius: theme.borderRadiusSm,
      borderBottomRightRadius: theme.borderRadiusSm,
    }),
  };

  return (
    <FormSelectWrapper className={className ?? ''}>
      {label && (
        <FormLabel for={name} required={required}>
          {label}
        </FormLabel>
      )}
      <Select
        {...{ name }}
        {...{ onChange }}
        {...{ options }}
        {...{ styles }}
        menuPlacement='auto'
        value={
          meta?.value
            ? options.find((option) => option.value === meta.value)
            : null
        }
        classNamePrefix='form-select'
        id={name}
        theme={(defaultTheme) => theme.reactSelectTheme(defaultTheme)}
        components={
          hasTranslatedOptions
            ? {
                Option: CustomTranslateOption,
                SingleValue: CustomTranslateValue,
              }
            : null
        }
        {...otherProperties}
      />
      <ErrorMessage name={name}>
        {(msg) => (
          <p className='text-danger small m-0'>
            {errorMsg ? (
              <FormattedMessage id={msg} defaultMessage={`Error.${msg}`} />
            ) : (
              msg
            )}
          </p>
        )}
      </ErrorMessage>
    </FormSelectWrapper>
  );
};

FormSelect.defaultProps = {
  className: undefined,
  hasError: false,
  label: undefined,
  required: false,
  defaultValue: undefined,
  errorMsg: false,
  hasTranslatedOptions: false,
};

FormSelect.propTypes = {
  className: PropTypes.string,
  hasTranslatedOptions: PropTypes.bool,
  defaultValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  hasError: PropTypes.bool,
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  required: PropTypes.bool,
};

export default FormSelect;
