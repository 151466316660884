import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { Badge, Col, Row } from 'reactstrap';

import {
  LinksList,
  PageHeader,
  RoleManager,
  Section,
  SectionBody,
  SectionHeader,
  Spinner,
} from 'components';
import { api, urlSchema, useFetch } from 'utils';
import messages from './messages';

const Read = ({ match }) => {
  const intl = useIntl();
  const { params } = match;
  const [product, isProductLoading] = useFetch(
    api.manager.getProductDetails(params.productId),
  );

  return (
    <>
      <PageHeader>
        <FormattedMessage
          tagName='h1'
          {...messages.Title}
          values={{ name: product?.label }}
        />
        <RoleManager entity='Products' right='CRUD'>
          <Link
            className='btn btn-primary'
            to={`${urlSchema.products.update}/${product.idProduct}`}
          >
            <FormattedMessage {...messages.ButtonEdit} />
          </Link>
        </RoleManager>
      </PageHeader>
      <Row>
        <Col lg='8'>
          <Section>
            {isProductLoading ? (
              <Spinner color='primary' size='lg' />
            ) : (
              <>
                <SectionHeader>
                  <h2 className='d-inline-block align-middle mr-3'>
                    {product.code}
                  </h2>
                </SectionHeader>
                <SectionBody>
                  <div className='model'>
                    <Row>
                      <Col lg='6'>
                        {product.rawFile ? (
                          <figure className='model__logo'>
                            <img
                              alt={`${product.label}'s logo`}
                              src={`data:image/*;base64, ${product.rawFile}`}
                            />
                          </figure>
                        ) : (
                          <div className='model__no-logo'>
                            <FormattedMessage
                              tagName='p'
                              {...messages.NoLogo}
                            />
                          </div>
                        )}
                        <h3 className='mb-3'>
                          <Badge color={product.valid ? 'success' : 'danger'}>
                            {product.valid ? (
                              <>
                                <i className='uil uil-check mr-1' aria-hidden />
                                <FormattedMessage {...messages.IsValid} />
                              </>
                            ) : (
                              <>
                                <i
                                  className='uil uil-multiply mr-1'
                                  aria-hidden
                                />
                                <FormattedMessage {...messages.IsInvalid} />
                              </>
                            )}
                          </Badge>
                        </h3>
                        <div className='model__actions'>
                          <Link
                            className='btn btn-outline-primary btn-sm'
                            to={`${urlSchema.patients.listMD}?Products=${product.idProduct}`}
                          >
                            {intl.formatMessage({
                              ...messages.ButtonSeeSubscribers,
                            })}
                          </Link>
                          {product.refVerificationTypeNavigation.code ===
                            'CODE' && (
                            <>
                              <a
                                className='btn btn-outline-primary btn-sm'
                                href={`${urlSchema.vouchersGroupsCreate}?idProduct=${product.idProduct}`}
                                rel='noopener noreferrer'
                              >
                                {intl.formatMessage({
                                  ...messages.ButtonGenerateVoucher,
                                })}
                              </a>
                              <Link
                                className='btn btn-outline-primary btn-sm'
                                to={`${urlSchema.vouchersGroups}?refProduct=${product.idProduct}`}
                              >
                                {intl.formatMessage({
                                  ...messages.ButtonSeeVouchers,
                                })}
                              </Link>
                            </>
                          )}
                        </div>
                      </Col>
                      <Col className='mt-sm-3' lg='6'>
                        {product.nbVouchers > 0 && (
                          <Link
                            className='btn btn-outline-warning btn-sm mb-3'
                            to={`${urlSchema.vouchers}?refProduct=${product.idProduct}`}
                          >
                            <i
                              aria-hidden
                              className=' align-middle uil uil-tag-alt uil--sm'
                            />
                            <span className='d-inline-block align-middle ml-1'>
                              {intl.formatMessage(
                                {
                                  ...messages.Vouchers,
                                },
                                { number: product.nbVouchers },
                              )}
                            </span>
                          </Link>
                        )}
                        <div className='model__property'>
                          <h4 className='model__title'>
                            {intl.formatMessage({ ...messages.Code })}
                          </h4>
                          <p className='model__value'>{product.code}</p>
                        </div>
                        <div className='model__property'>
                          <h4 className='model__title'>
                            {intl.formatMessage({ ...messages.Label })}
                          </h4>
                          <p className='model__value'>{product.label}</p>
                        </div>
                        <div className='model__property'>
                          <h4 className='model__title'>
                            {intl.formatMessage({ ...messages.Tag })}
                          </h4>
                          <p className='model__value'>{product.productTag}</p>
                        </div>
                        <div className='model__property'>
                          <h4 className='model__title'>
                            {intl.formatMessage({
                              ...messages.ExpirationDate,
                            })}
                          </h4>
                          <p className='model__value'>
                            {product.dateExpiration
                              ? moment(product.dateExpiration).format('LL')
                              : intl.formatMessage({ ...messages.Blank })}
                          </p>
                        </div>
                        <div className='model__property'>
                          <h4 className='model__title'>
                            {intl.formatMessage({
                              ...messages.MaxQuestionsCount,
                            })}
                          </h4>
                          <p className='model__value'>
                            {product.maxQuestionCount
                              ? product.maxQuestionCount
                              : intl.formatMessage({ ...messages.Blank })}
                          </p>
                        </div>
                        <div className='model__property'>
                          <h4 className='model__title'>
                            {intl.formatMessage({
                              ...messages.Duration,
                            })}
                          </h4>
                          <p className='model__value'>
                            {product.duration
                              ? product.duration
                              : intl.formatMessage({ ...messages.Blank })}
                          </p>
                        </div>
                        <div className='model__property'>
                          <h4 className='model__title'>
                            {intl.formatMessage({ ...messages.Client })}
                          </h4>
                          <Link
                            className='model__value'
                            to={`${urlSchema.clients.read}/${product.refClient}`}
                          >
                            {product.clientLabel}
                          </Link>
                        </div>
                        <div className='model__property'>
                          <h4 className='model__title'>
                            {intl.formatMessage({
                              ...messages.SellsyClient,
                            })}
                          </h4>
                          <p className='model__value'>
                            {product.labelClientSellsy
                              ? `${product.labelClientSellsy} (${product.refClientSellsy})`
                              : intl.formatMessage({ ...messages.Blank })}
                          </p>
                        </div>
                        <div className='model__property'>
                          <h4 className='model__title'>
                            {intl.formatMessage({
                              ...messages.VerificationType,
                            })}
                          </h4>
                          <p className='model__value'>
                            {product.refVerificationTypeNavigation.code}
                          </p>
                        </div>
                        <div className='model__property'>
                          <h4 className='model__title'>
                            <FormattedMessage
                              {...messages.CreationDate}
                              values={{
                                date: moment(product.dateCreation).format('LL'),
                              }}
                            />
                          </h4>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </SectionBody>
              </>
            )}
          </Section>
        </Col>
        <Col lg='4'>
          <Section>
            {isProductLoading ? (
              <Spinner color='primary' size='lg' />
            ) : (
              <>
                <SectionHeader>
                  <FormattedMessage tagName='h2' {...messages.Specialties} />
                  <RoleManager entity='Products' right='CRUD'>
                    <Link
                      className='btn btn-outline-primary btn-sm ml-auto'
                      to={`${urlSchema.products.editSpecialties}/${params.productId}`}
                    >
                      <FormattedMessage {...messages.ButtonManage} />
                    </Link>
                  </RoleManager>
                </SectionHeader>
                <SectionBody>
                  {product.productsSpecialties.length > 0 ? (
                    <LinksList
                      color='warning'
                      data={product.productsSpecialties}
                      idKey={(el) => el.refSpecialty}
                      labelKey={(el) => el.specialtyLabel}
                      urlBase={urlSchema.specialties.read}
                    />
                  ) : (
                    <p className='text-secondary m-0'>
                      <FormattedMessage {...messages.NoElement} />
                    </p>
                  )}
                </SectionBody>
              </>
            )}
          </Section>
        </Col>
      </Row>
    </>
  );
};

export default Read;
