import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, useFormikContext } from 'formik';
import { FixedSizeList as List } from 'react-window';
import { ThemeContext } from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';

import Checkbox from '../Checkbox';
import FormListWrapper from './elements';

const FormList = ({
  data,
  researchOptions,
  itemLabelKey,
  itemValueKey,
  label,
  name,
}) => {
  const theme = useContext(ThemeContext);
  const { setFieldValue, values } = useFormikContext();
  const [debouncedValue, setDebouncedValue] = useState('');
  const debouncedCallback = useDebouncedCallback((value) => {
    setDebouncedValue(value);
  }, 350);
  const items = useMemo(
    () =>
      debouncedValue !== ''
        ? data.filter((item) =>
            item[itemLabelKey].toLowerCase().includes(debouncedValue),
          )
        : data,
    [debouncedValue],
  );

  return (
    <FormListWrapper>
      {label && <p className='text-secondary mb-1'>{label}</p>}
      {researchOptions.isEnabled && (
        <input
          className='form-control form-control-sm mb-1'
          onChange={({ target: { value } }) => {
            debouncedCallback(value.toLowerCase());
          }}
          placeholder={researchOptions.placeholder}
          type='text'
        />
      )}
      <FormListWrapper.ButtonsWrapper>
        <FormListWrapper.Button
          isAllSelected={values[name]?.length === data.length}
          type='button'
          onClick={() => {
            if (values[name].length === data.length) {
              setFieldValue(name, []);
            } else {
              setFieldValue(
                name,
                data.map((item) => item[itemValueKey]),
              );
            }
          }}
        >
          <i className='uil uil-check uil--sm' aria-hidden />
        </FormListWrapper.Button>
        <FormattedMessage
          id='Buttons.SelectAll'
          defaultMessage='Error.Buttons.SelectAll'
          tagName='p'
        />
      </FormListWrapper.ButtonsWrapper>
      <List
        height={450}
        innerElementType='ul'
        itemCount={items.length}
        itemSize={48}
        style={{
          borderLeft: `1px solid ${theme.color('border')}`,
          borderRight: `1px solid ${theme.color('border')}`,
          borderBottom: `1px solid ${theme.color('border')}`,
          borderBottomLeftRadius: theme.borderRadius,
          borderBottomRightRadius: theme.borderRadius,
        }}
      >
        {({ index, style }) => (
          <li style={style}>
            <Field {...{ name }}>
              {({ field, form }) => {
                return (
                  <Checkbox
                    {...field}
                    checked={form.values[name]?.includes(
                      items[index][itemValueKey],
                    )}
                    id={`${name}-${index}`}
                    value={items[index][itemValueKey]}
                  >
                    {items[index][itemLabelKey]}
                  </Checkbox>
                );
              }}
            </Field>
          </li>
        )}
      </List>
    </FormListWrapper>
  );
};

FormList.defaultProps = {
  data: [],
  label: undefined,
  researchOptions: { isEnabled: false, placeholder: null },
};

FormList.propTypes = {
  data: PropTypes.instanceOf(Array),
  researchOptions: PropTypes.shape({
    isEnabled: PropTypes.bool,
    placeholder: PropTypes.string,
  }),
  itemLabelKey: PropTypes.string.isRequired,
  itemValueKey: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default FormList;
