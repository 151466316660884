import { defineMessages } from 'react-intl';

const prefix = 'Screens.FeeNotes.';

const keys = [
    'Download',
    'Cancel',
    'From',
    'To',
    'ConfirmCancellation',
    'ConfirmCancellationLabel',
    'ListDoctors',
    'GenerationDate',
    'ValidationDate',
    'Total',
    'Back',
    'CancelDefinitely',
    'NoOptionsMessage',
    'FilterByStatus',
    'Title',
    'FilterByReferenceMonth',
    'FilterByStatusLabel',
    'Doctors',
    'TotalSum',
    'ReferalMonth',
    'ValidationDateTitle',
    'Status',
    'SelectedSum',
    'Description',
    'SuccessfulOperation',
    'CancellationMade',
    'NoAvailableFilters',
    'Status.Generating',
    'Status.Generated',
    'Status.Validated',
    'Status.Exported',
    'Status.Cancelled',
    'Toast.DownloadUnavailable.Header',
    'Toast.DownloadUnavailable.Body',
];

const translations = {};

keys.map(key => {
    const obj = {};
    obj[key] = {
        id: `${prefix}${key}`,
        defaultMessage: `Error.${prefix}${key}`,
    }
    return obj;
}).map((e) => {
    translations[Object.keys(e)[0]] = Object.values(e)[0];
});

export default defineMessages(translations);