import React from 'react';
import { useOidc } from 'utils';
import { useCopyToClipboard } from 'react-use';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';

import messages from './messages';

import TokenWrapper from './elements';

const Token = () => {
  const oidcUser = useOidc();
  const token = `bearer ${oidcUser.access_token}`;
  const [copied, copyToClipboard] = useCopyToClipboard();

  return (
    <>
      <TokenWrapper>
        <TokenWrapper.Code>{token}</TokenWrapper.Code>
        <Button color='primary' onClick={() => copyToClipboard(token)}>
          <FormattedMessage {...messages.ButtonCopy} />
        </Button>
      </TokenWrapper>
      {copied.value && <FormattedMessage {...messages.Copied} />}
    </>
  );
};

export default Token;
