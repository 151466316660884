import React, { ReactNode } from 'react';
import { Icon } from '@teladochealth/components';

type PasswordHelperProperties = {
  isValid?: boolean | '';
  children: ReactNode;
};

export const Helper = ({
  isValid = '',
  children,
}: PasswordHelperProperties) => {
  const helper =
    typeof isValid === 'boolean'
      ? isValid === true
        ? {
            color: 'var(--semantics-surface-positive-text-positive)',
            icon: 'uis uis-check-circle',
          }
        : {
            color: 'var(--semantics-surface-negative-text-negative)',
            icon: 'uil uil-times-circle',
          }
      : {
          color: 'var(--semantics-surface-primary-text-secondary)',
          icon: 'uil uil-circle',
        };

  return (
    <p className='color-text-secondary typo-micro d-flex flex-row gap-1 align-items-center mb-0'>
      <Icon
        className={`icon-xs ${helper.icon}`}
        style={{ color: helper.color }}
      />
      {children}
    </p>
  );
};
