import PropTypes from 'prop-types';
import React from 'react';
import { useFetch, api, urlSchema } from '../../../utils';
import { Translation, Spinner, RoleManager } from '../../../components';
import moment from 'moment';
import { isNil } from 'lodash';
import { Link } from 'react-router-dom';
import { Logs } from './NHI/Logs';

const EMPTY_VALUE = '--';

const mapOidToTranslationKey = {
  8: 'Screens.Patient.Read.Nhi.Logs.ModalAccessLog.Oid.8',
  9: 'Screens.Patient.Read.Nhi.Logs.ModalAccessLog.Oid.9',
  10: 'Screens.Patient.Read.Nhi.Logs.ModalAccessLog.Oid.10',
  11: 'Screens.Patient.Read.Nhi.Logs.ModalAccessLog.Oid.11',
};

const renderOid = (oid) => {
  const oidKey = mapOidToTranslationKey[getOidLastNumber(oid)];
  if (oidKey && oidKey !== undefined) {
    return <Translation id={oidKey} />;
  }
  return EMPTY_VALUE;
};

const getOidLastNumber = (oidString) => {
  if (!oidString) return;
  const parts = oidString.split('.');
  return parts.at(-1);
};

const renderValue = (value, renderFunction) => {
  if (isNil(value)) {
    return EMPTY_VALUE;
  }
  return renderFunction ? renderFunction(value) : value;
};

const renderAttribute = (attribute) =>
  renderValue(attribute, (key) => (
    <span className='badge badge-danger rounded-pill px-3 font-size-14px'>
      <Translation id={`Screens.Patients.Read.Ins.Attribute.${key}`} />
    </span>
  ));

const renderStatus = (status) =>
  renderValue(status, (stat) => (
    <Translation id={`Screens.Patients.Read.Ins.Status.${stat}`} />
  ));

const getBadgeClass = (status) => {
  switch (status) {
    case 'Temporary':
    case 'Recovered': {
      return 'badge-warning';
    }
    case 'Validated':
    case 'Qualified': {
      return 'badge-success';
    }
    default: {
      return 'badge-secondary';
    }
  }
};

const formatNhiRegistrationNumber = (nhiNumber) => {
  if (!nhiNumber) {
    return EMPTY_VALUE;
  }

  // Format expected eg: 1 91 05 78 018 780 17
  let formattedNumber = '';
  formattedNumber += nhiNumber.slice(0, 1) + ' ';
  formattedNumber += nhiNumber.slice(1, 3) + ' ';
  formattedNumber += nhiNumber.slice(3, 5) + ' ';
  formattedNumber += nhiNumber.slice(5, 7) + ' ';
  formattedNumber += nhiNumber.slice(7, 10) + ' ';
  formattedNumber += nhiNumber.slice(10, 13) + ' ';
  formattedNumber += nhiNumber.slice(13, 15);

  return formattedNumber;
};

const ReadNhi = ({ patientId }) => {
  const [data, isDataLoading] = useFetch(
    api.admin.getNhiProfil(String(patientId)),
  );

  if (isDataLoading) {
    return (
      <div className='section'>
        <div className='section_body p-3'>
          <Spinner />
        </div>
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div className='section'>
        <div className='section_body p-3'>
          <Translation id='Shared.NoNhiDatas' />
        </div>
      </div>
    );
  }

  const formattedDate = moment(data.birthDate).format('DD/MM/YYYY');

  return (
    <RoleManager entity='NHIs' right='Reader'>
      <div className='row'>
        <div className='col-md-7'>
          <div className='section'>
            <div className='section__body'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='mb-4 d-flex justify-content-between align-items-center'>
                    <Translation
                      id='Screens.Patients.Read.Ins.Title'
                      tagName='h1'
                    />
                    <RoleManager entity='NHIs' right='CRUD'>
                      <Link
                        className='btn btn-primary'
                        to={`${urlSchema.patients.updateNHI}/${patientId}`}
                      >
                        <Translation id='Buttons.Edit' />
                      </Link>
                    </RoleManager>
                  </div>
                </div>
                <div className='col-md-4'>
                  <span className='text-secondary'>
                    <Translation id='Screens.Patients.Read.Ins.Label.Status' />
                  </span>
                  <h2>
                    <span
                      className={`badge ${getBadgeClass(data.status)} rounded-pill px-3 font-size-14px`}
                    >
                      {renderStatus(data.status)}
                    </span>
                  </h2>
                </div>
                <div className='col-md-4'>
                  <span className='text-secondary'>
                    <Translation id='Screens.Patients.Read.Ins.Label.Attributes' />
                  </span>
                  <h2>{renderAttribute(data.attribute)}</h2>
                </div>
                <div className='col-md-12 my-3'>
                  <hr />
                </div>
                <div className='col-md-4'>
                  <span className='text-secondary'>
                    <Translation id='Screens.Patients.Read.Ins.Label.Gender' />
                  </span>
                  <p>
                    <Translation id={`Shared.Gender.${data.birthGender}`} />
                  </p>
                </div>
                <div className='col-md-4'>
                  <span className='text-secondary'>
                    <Translation id='Screens.Patients.Read.Ins.Label.BirthDate' />
                  </span>
                  <p>{renderValue(formattedDate)}</p>
                </div>
                <div className='col-md-4'>
                  <span className='text-secondary'>
                    <Translation id='Screens.Patients.Read.Ins.Label.BirthFirstNames' />
                  </span>
                  <p>{renderValue(data.birthFirstNames)}</p>
                </div>
                <div className='col-md-4'>
                  <span className='text-secondary'>
                    <Translation id='Screens.Patients.Read.Ins.Label.birthFirstName' />
                  </span>
                  <p className='text-uppercase'>
                    {renderValue(data.birthFirstName)}
                  </p>
                </div>
                <div className='col-md-4'>
                  <span className='text-secondary'>
                    <Translation id='Screens.Patients.Read.Ins.Label.birthLastName' />
                  </span>
                  <p className='text-uppercase'>
                    {renderValue(data.birthLastName)}
                  </p>
                </div>
                <div className='col-md-4'>
                  <span className='text-secondary'>
                    <Translation id='Screens.Patients.Read.Ins.Label.usedFirstName' />
                  </span>
                  <p className='text-uppercase'>
                    {renderValue(data.usedFirstName)}
                  </p>
                </div>
                <div className='col-md-4'>
                  <span className='text-secondary'>
                    <Translation id='Screens.Patients.Read.Ins.Label.usedLastName' />
                  </span>
                  <p className='text-uppercase'>
                    {renderValue(data.usedLastName)}
                  </p>
                </div>
                <div className='col-md-4'>
                  <span className='text-secondary'>
                    <Translation id='Screens.Patients.Read.Ins.Label.BirthPlaceName' />
                  </span>
                  <p>{renderValue(data.birthPlace.name)}</p>
                </div>
                <div className='col-md-4'>
                  <span className='text-secondary'>
                    <Translation id='Screens.Patients.Read.Ins.Label.InseeCode' />
                  </span>
                  <p>{renderValue(data.birthPlace.inseeCode)}</p>
                </div>
                <div className='col-md-4'>
                  <span className='text-secondary'>
                    <Translation id='Screens.Patients.Read.Ins.Label.nhiRegistrationNumber' />
                  </span>
                  <p>
                    {formatNhiRegistrationNumber(data.nhiRegistrationNumber)}
                  </p>
                </div>
                <div className='col-md-4'>
                  <span className='text-secondary'>
                    <Translation id='Screens.Patients.Read.Ins.Label.oid' />
                  </span>
                  <p>{renderOid(data.oid)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-5'>
          <div className='section'>
            <div className='section__body'>
              <div className='row'>
                <div className='col-md-12'>
                  <h1 className='mb-4'>
                    <Translation id='Screens.Patient.Read.Nhi.Logs.Title' />
                  </h1>
                  <Logs patientId={patientId} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </RoleManager>
  );
};

ReadNhi.propTypes = {
  patientId: PropTypes.string.isRequired,
};

export default ReadNhi;
