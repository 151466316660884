import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonDelete: {
    id: 'Buttons.Delete',
    defaultMessage: 'Error.Buttons.Delete',
    description: 'Button to delete'
  },
  ButtonUpload: {
    id: 'Buttons.Upload',
    defaultMessage: 'Error.Buttons.Upload',
    description: 'Button to upload'
  },
  ErrorUpload: {
    id: 'Components.FormInputLogo.ErrorUpload',
    defaultMessage: 'Error.Components.FormInputLogo.ErrorUpload',
    description: 'Error shown when logo upload failed'
  }
});
