import * as Yup from 'yup';

const getValidationSchema = (formatMessage) =>
  Yup.object().shape({
    birthGender: Yup.string().required(
      formatMessage({
        id: 'Shared.FieldRequired',
        defaultMessage: 'Error.Shared.FieldRequired',
      }),
    ),
    birthDate: Yup.string().required(
      formatMessage({
        id: 'Shared.FieldRequired',
        defaultMessage: 'Error.Shared.FieldRequired',
      }),
    ),
    birthFirstName: Yup.string()
      .matches(
        /^[A-Za-z-]*$/,
        formatMessage({
          id: 'Patients.Nhis.Edit.OnlyLettersAndHyphensAuthorized',
          defaultMessage:
            'Error.Patients.Nhis.Edit.OnlyLettersAndHyphensAuthorized',
        }),
      )
      .required(
        formatMessage({
          id: 'Shared.FieldRequired',
          defaultMessage: 'Error.Shared.FieldRequired',
        }),
      ),
    birthLastName: Yup.string()
      .matches(
        /^[A-Za-z-]*$/,
        formatMessage({
          id: 'Patients.Nhis.Edit.OnlyLettersAndHyphensAuthorized',
          defaultMessage:
            'Error.Patients.Nhis.Edit.OnlyLettersAndHyphensAuthorized',
        }),
      )
      .required(
        formatMessage({
          id: 'Shared.FieldRequired',
          defaultMessage: 'Error.Shared.FieldRequired',
        }),
      ),
    usedFirstName: Yup.string().matches(
      /^[A-Za-z-]*$/,
      formatMessage({
        id: 'Patients.Nhis.Edit.OnlyLettersAndHyphensAuthorized',
        defaultMessage:
          'Error.Patients.Nhis.Edit.OnlyLettersAndHyphensAuthorized',
      }),
    ),
    usedLastName: Yup.string().matches(
      /^[A-Za-z-]*$/,
      formatMessage({
        id: 'Patients.Nhis.Edit.OnlyLettersAndHyphensAuthorized',
        defaultMessage:
          'Error.Patients.Nhis.Edit.OnlyLettersAndHyphensAuthorized',
      }),
    ),
    attribute: Yup.string().oneOf(
      ['', 'Homonymous', 'Dubious', 'Fictional'],
      formatMessage({
        id: 'Patients.Nhis.Edit.InvalidAttribute',
        defaultMessage: 'Error.Patients.Nhis.Edit.InvalidAttribute',
      }),
    ),
    birthPlace: Yup.object().shape({
      placeType: Yup.string()
        .oneOf(['UNKNOWN', 'FRENCH_PLACE', 'OTHER_COUNTRY'])
        .required(
          formatMessage({
            id: 'Shared.FieldRequired',
            defaultMessage: 'Error.Shared.FieldRequired',
          }),
        ),
      inseeCode: Yup.string().when('placeType', {
        is: (placeType) => placeType !== 'UNKNOWN',
        then: Yup.string().required(
          formatMessage({
            id: 'Shared.FieldRequired',
            defaultMessage: 'Error.Shared.FieldRequired',
          }),
        ),
        otherwise: Yup.string().notRequired(),
      }),
    }),
  });

export default getValidationSchema;
