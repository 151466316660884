import { defineMessages } from 'react-intl';

const messages = defineMessages({
  MainProduct: {
    id: 'Screens.Patients.Read.ProductsList.MainProduct',
    defaultMessage: 'Error.Screens.Patients.Read.ProductsList.MainProduct'
  },
  ValidHealthPartners: {
    id: 'Screens.Patients.Read.HealthPartnersList.ValidHealthPartners',
    defaultMessage: 'Error.Screens.Patients.Read.HealthPartnersList.ValidHealthPartners'
  },
  InvalidHealthPartners: {
    id: 'Screens.Patients.Read.HealthPartnersList.InvalidHealthPartners',
    defaultMessage: 'Error.Screens.Patients.Read.HealthPartnersList.InvalidHealthPartners'
  },
});

export default messages;