import { defineMessages } from 'react-intl';

import { generateTranslationsObject } from 'utils';

const prefix = 'Screens.Questionnaires.';
const keys = [
    'list.title',
    'list.searchPlaceholder',
    'list.noresults',
    'list.col.title',
    'list.col.creation',
    'list.col.products',
    'list.col.profils',
    'list.col.speciality',
];

const additionalKeys = {
    Create: {
        id: 'Buttons.Create',
        defaultMessage: 'Error.Buttons.Create',
    },
    Research: {
        id: 'Buttons.Research',
        defaultMessage: 'Error.Buttons.Research',
    },
    Valid: {
        id: 'Shared.Valid',
        defaultMessage: 'Error.Shared.Valid',
    }
};

export default defineMessages(
    generateTranslationsObject(prefix, keys, additionalKeys),
);
