import React from 'react';
import qs from 'qs';
import { Formik, Form } from 'formik';
import { FormattedMessage } from 'react-intl';

import { ApiError, PageHeader } from 'components';
import { TemplateMessage } from 'models';
import { service } from 'services';
import { api, sendToast, urlSchema } from 'utils';
import { validationSchema } from 'models/TemplateMessage';
import messages from './messages';
import { IFrame } from '../components/IFrame';
import { ToastTypes } from '../../../constants';

const Create = ({ history }) => {
  const { refOrganization } =
    qs.parse(history.location.search, { ignoreQueryPrefix: true }) ?? '';

  const onSubmit = (values, { setSubmitting }) => {
    service
      .post(api.templateMessages.post, values)
      .then(({ data }) => {
        if (data.succeeded === false) {
          setSubmitting(false);
          sendToast(ToastTypes.error, {
            body: <FormattedMessage {...messages.ToastFail} />,
          });
          setSubmitting(false);
          return;
        }
        sendToast(ToastTypes.success, {
          body: (
            <FormattedMessage
              {...messages.ToastSuccess}
              values={{ name: values.name }}
            />
          ),
        });
        history.push(`${urlSchema.templateMessages.read}/${data.idTemplate}`);
      })
      .catch((error) => {
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.ToastFail} />,
          body: <ApiError {...{ error }} />,
        });
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <FormattedMessage {...messages.Title} tagName='h1' />
      </PageHeader>
      <div className='row'>
        <div className='col-lg-6'>
          <main className='section'>
            <div className='section__body'>
              <Formik
                initialValues={{
                  codeLanguage: '',
                  name: '',
                  object: '',
                  message: '',
                  refOrganization,
                  type: '',
                }}
                {...{ validationSchema }}
                {...{ onSubmit }}
              >
                {() => (
                  <Form>
                    <TemplateMessage />
                    <button className='btn btn-primary btn-lg' type='submit'>
                      <FormattedMessage {...messages.ButtonCreate} />
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </main>
        </div>
        <div className='col-lg-6'>
          <IFrame />
        </div>
      </div>
    </>
  );
};

export default Create;
