import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonBack: {
    id: 'Buttons.Back',
    defaultMessage: 'Error.Buttons.Back',
  },
  Edit: {
    id: 'Screens.Applications.Update.Title',
    defaultMessage: 'Error.Screens.Applications.Update.Title',
    description: 'Update applications page title',
  },
  SuccessTitle: {
    id: 'Message.Success',
    defaultMessage: 'Error.Message.Success',
    description: 'Toast title in case of success',
  },
  SuccessBody: {
    id: 'Applications.Update.SuccessBody',
    defaultMessage: 'Error.Applications.Update.SuccessBody',
    description: 'Toast body in case of success',
  },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
    description: 'Toast title in case of Error',
  },
  FailBody: {
    id: 'Applications.Update.FailBody',
    defaultMessage: 'Error.Applications.Update.FailBody',
    description: 'Toast body in case of error',
  },
});
