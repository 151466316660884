import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

import UserLogsWrapper from './elements';
import { getNHIPatientLogsByPatientId } from 'services/manager/NHIs/getNHIPatientLogsByPatientId';
import { AccessLogsSurcharged, WebServiceLogsSurcharged } from './types';
import { Spinner, Translation } from 'components';
import { ModalAccessLogDetails } from './ModalAccessLogDetails';
import { ModalWebServiceLogDetails } from './ModalWebServiceLogDetails';
import { LogItem } from './LogItem';

const Logs = ({ patientId }: { patientId: string }) => {
  const [selectedLog, setSelectedLog] = useState<
    AccessLogsSurcharged | WebServiceLogsSurcharged
  >();
  const { data, isLoading, error } = useQuery({
    queryKey: ['nhiLogs', { patientId }],
    queryFn: () => getNHIPatientLogsByPatientId(patientId),
  });

  if (error) {
    return <Translation id='Shared.ErrorFetching' />;
  }

  if (isLoading) {
    return <Spinner color='primary' size='md' />;
  }

  const mergedList = [
    ...data.accessLogs.map((item) => ({
      ...item,
      date: item.createdOn,
      type: 'ACCESS_LOG',
    })),
    ...data.webServiceLogs.map((item) => ({
      ...item,
      date: item.endedAt,
      type: 'WEB_SERVICE',
    })),
  ];

  mergedList.sort((a, b) => b.date.localeCompare(a.date));

  if (mergedList.length === 0) {
    return <Translation id='Shared.NoElement' />;
  }

  return (
    <>
      <UserLogsWrapper>
        {mergedList.map((log) => (
          <UserLogsWrapper.Item logType={log.type} key={log.date}>
            <LogItem
              onLogClick={() =>
                setSelectedLog(
                  log as AccessLogsSurcharged | WebServiceLogsSurcharged,
                )
              }
              log={log as AccessLogsSurcharged | WebServiceLogsSurcharged}
            />

            <span className='text-nowrap'>
              {moment(log.date).format('DD/MM/YYYY, HH:mm')}
            </span>
          </UserLogsWrapper.Item>
        ))}
      </UserLogsWrapper>
      {selectedLog && selectedLog.type === 'WEB_SERVICE' && (
        <ModalWebServiceLogDetails
          onToggle={() => setSelectedLog(undefined)}
          log={selectedLog as WebServiceLogsSurcharged}
        />
      )}
      {selectedLog && selectedLog.type === 'ACCESS_LOG' && (
        <ModalAccessLogDetails
          onToggle={() => setSelectedLog(undefined)}
          log={selectedLog as AccessLogsSurcharged}
        />
      )}
    </>
  );
};

export { Logs };
