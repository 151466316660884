import React from 'react';
import { Alert, Col, Row } from 'reactstrap';
import { useFormikContext, Field } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  Checkbox,
  Section,
  FormInputFeedback,
  SectionHeader,
  SectionBody,
  FormInput,
  Button,
} from 'components';

import { ButtonsWrapper } from 'themes/jupiter/elements';
import { Patient } from 'models';
import messages from './messages';

const PatientInformation = () => {
  const { formatMessage } = useIntl();
  const { values, isSubmitting } = useFormikContext();

  return (
    <Row>
      <Col lg='8' className='ml-auto mr-auto'>
        <Section>
          <SectionHeader>
            <FormattedMessage {...messages.Title} tagName='h1' />
          </SectionHeader>
          <SectionBody>
            <Alert color='primary'>
              <FormattedMessage
                {...messages.AlertIsEligible}
                values={{ name: values.productLabel }}
              />
            </Alert>
            <FormInput
              id='userCode'
              label={formatMessage(messages.EligibilityCode)}
              name='userCode'
              type='text'
              disabled
            />
            <Patient />
            <div className='form-group'>
              <Field name='infosPatient.operatorInscriptionConsent'>
                {({ field }) => (
                  <Checkbox
                    {...field}
                    checked={values.infosPatient.operatorInscriptionConsent}
                    id='operatorInscriptionConsent'
                    required
                  >
                    <FormattedMessage {...messages.CGUAppliance} />
                  </Checkbox>
                )}
              </Field>
              <FormInputFeedback name='infosPatient.operatorInscriptionConsent' />
            </div>
            <ButtonsWrapper bottom>
              <Button
                isProcessing={isSubmitting}
                size='lg'
                type='submit'
                color='primary'
                label='Create'
              />
            </ButtonsWrapper>
          </SectionBody>
        </Section>
      </Col>
    </Row>
  );
};

export default PatientInformation;
