import * as yup from 'yup';

export default () =>
  yup.object().shape({
    title: yup.string().required('Model.FieldRequired'),
    body: yup.string().required('Model.FieldRequired'),
    url: yup.string(),
    dateStart: yup.date(),
    dateEnd: yup.date(),
    valid: yup.boolean(),
    ranking: 0,
  });
