import { isNil } from 'lodash';

import { oidcConfiguration } from '../constants';

const useOidc = () => {
  const oidc = localStorage.getItem(oidcConfiguration.providers[0].providerId);
  return isNil(oidc) ? undefined : JSON.parse(oidc);
};

export default useOidc;
