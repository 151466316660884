import React from 'react';
import { Switch } from 'react-router-dom';

import { ProtectedRoute } from 'components';
import { urlSchema } from 'utils';

import Create from './Create';
import EditSpecialties from './EditSpecialties';
import List from './List';
import Read from './Read';
import Update from './Update';

const routes = [
  { path: urlSchema.products.create, component: Create, right: 'CRUD' },
  {
    path: `${urlSchema.products.read}/:productId`,
    component: Read,
    right: 'Reader',
  },
  {
    path: `${urlSchema.products.update}/:productId`,
    component: Update,
    right: 'CRUD',
  },
  {
    path: `${urlSchema.products.editSpecialties}/:productId`,
    component: EditSpecialties,
    right: 'CRUD',
  },
  { path: '/', component: List, right: 'Reader' },
];

const Products = () => {
  return (
    <Switch>
      {routes.map((item) => (
        <ProtectedRoute entity='Products' key={item.path} {...item} />
      ))}
    </Switch>
  );
};

export default Products;
