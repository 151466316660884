import * as yup from 'yup';

export default () =>
  yup.object().shape({
    codeLanguage: yup.string().required('Shared.FieldRequired'),
    message: yup.string().required('Shared.FieldRequired'),
    name: yup.string().required('Shared.FieldRequired'),
    refOrganization: yup.string().required('Shared.FieldRequired'),
    type: yup.string().required('Shared.FieldRequired'),
  });
