import * as yup from 'yup';
import messages from './messages';

export default (formatMessage) =>
  yup.object().shape({
    code: yup.string().required('Model.FieldRequired'),
    label: yup.string().required('Model.FieldRequired'),
    labelClientSellsy: yup
      .string()
      .required(formatMessage(messages.FieldRequired)),
    refClientSellsy: yup
      .string()
      .required(formatMessage(messages.FieldRequired)),
  });
