import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonCreate: {
    id: 'Buttons.Create',
    defaultMessage: 'Error.Buttons.Create',
  },
  SuccessTitle: {
    id: 'Message.Success',
    defaultMessage: 'Error.Message.Success',
  },
  SuccessBody: {
    id: 'Screens.Products.Create.ToastSuccess',
    defaultMessage: 'Error.Screens.Products.Create.ToastSuccess',
  },
  FailBody: {
    id: 'Screens.Products.Create.ToastFail',
    defaultMessage: 'Error.Screens.Products.Create.ToastFail',
  },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
  },
  Title: {
    id: 'Screens.Products.Create.Title',
    defaultMessage: 'Error.Screens.Products.Create.Title',
  },
});
