import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { PageHeader } from 'components';
import { service } from 'services';
import { api, sendToast, urlSchema, useFetch } from 'utils';
import { ToastTypes } from '../../../constants';

import messages from './messages';

const deleteAccount = (patientId) =>
  service.delete(api.patients.delete(patientId));

const Delete = () => {
  const history = useHistory();
  const { patientId } = useParams();
  const { formatMessage } = useIntl();
  const [patient] = useFetch(api.patients.get(patientId));

  return (
    <>
      <PageHeader>
        <h1>
          <FormattedMessage
            {...messages.Title}
            values={{ name: `${patient.lastname} ${patient.firstname}` }}
          />
        </h1>
      </PageHeader>
      <div className='row'>
        <div className='col-6'>
          <div className='section'>
            <div className='section__body'>
              <p className='m-0'>
                <FormattedMessage
                  {...messages.Description}
                  values={{
                    br: (
                      <>
                        <br />
                        <br />
                      </>
                    ),
                  }}
                />
              </p>
              <p className='m-0 d-flex align-items-center mt-3'>
                <i
                  aria-hidden='true'
                  className='uil uil-exclamation-triangle mr-2'
                />
                <FormattedMessage {...messages.Warning} tagName='b' />
              </p>
              <div className='mt-3 text-right'>
                <button
                  className='btn btn-primary'
                  type='button'
                  onClick={async () => {
                    if (
                      window.confirm(formatMessage({ ...messages.Confirm }))
                    ) {
                      try {
                        await deleteAccount(patientId);
                        sendToast(ToastTypes.success, {
                          title: formatMessage({ ...messages.SuccessTitle }),
                          body: formatMessage(
                            { ...messages.SuccessBody },
                            {
                              name: `${patient.lastname} ${patient.firstname}`,
                            },
                          ),
                        });
                        history.push(urlSchema.patients.list);
                      } catch (error) {
                        console.log(error);
                      }
                    }
                  }}
                >
                  <FormattedMessage {...messages.ButtonDeleteAccount} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Delete.displayName = 'Screens_Patients_Delete';

export default Delete;
