const identityAPI = window.__env.REACT_APP_API_IDENTITY_URL;
const managerAPI = window.__env.REACT_APP_API_ADMIN_URL;
const userAPI = `${window.__env.REACT_APP_API_IDENTITY_URL}/Users`;
const subscriptionAPI = `${window.__env.REACT_APP_API_IDENTITY_URL}/Subscription`;
const dialogueAPI = window.__env.REACT_APP_API_DIALOGUE_URL;
const adminAPI = window.__env.REACT_APP_API_ADMIN_URL;

const actualities = {
  getActualities: `${managerAPI}/Actualities`,
  postActualities: `${managerAPI}/Actualities`,
  getActuality: (actuId) => `${managerAPI}/Actualities/${actuId}`,
  putActuality: (actuId) => `${managerAPI}/Actualities/${actuId}`,
  deleteActuality: (actuId) => `${managerAPI}/Actualities/${actuId}`,
  getActualityProfiles: (actuId) =>
    `${managerAPI}/Actualities/Profiles/${actuId}`,
};

const applications = {
  getDetails: (applicationId) => `${adminAPI}/Applications/${applicationId}`,
  putProfiles: (applicationId) =>
    `${adminAPI}/Applications/Profiles/${applicationId}`,
};

const clients = {
  get: `${managerAPI}/Clients`,
  getClient: (clientId) => `${managerAPI}/Clients/${clientId}/`,
  getDetails: (clientId) => `${managerAPI}/Clients/${clientId}/Details`,
  getListItems: `${managerAPI}/Clients/ListItems`,
  getProducts: (clientId) => `${managerAPI}/Clients/${clientId}/Products`,
  put: (clientId) => `${managerAPI}/Clients/${clientId}`,
};

const directories = {
  getPractitioners: (identifier) =>
    `${identityAPI}/Directories/Practitioners/${identifier}`,
};

const doctors = {
  forgotPassword: (id) => `${managerAPI}/Doctors/ForgotPassword/UserId/${id}`,
  get: `${managerAPI}/Doctors`,
  getContractTypes: `${managerAPI}/Doctors/ContractTypes`,
  getDetails: (doctorId) => `${managerAPI}/Doctors/${doctorId}/v2`,
  getDocument: (doctorId, referenceFile) =>
    `${managerAPI}/Doctors/${doctorId}/Document/${referenceFile}`,
  // Also works with ADELI identifiers
  getPractitioner: (RPPSNumber) =>
    `${identityAPI}/Directories/Practitioners/${RPPSNumber}`,
  getOrdinalQualifications: `${managerAPI}/Doctors/OrdinalQualifications`,
  post: `${managerAPI}/Doctors/v2`,
  postScanSignature: (doctorId) =>
    `${managerAPI}/Doctors/${doctorId}/UploadScanSignature`,
  put: (doctorId) => `${managerAPI}/Doctors/${doctorId}/v2`,
  putProfiles: (doctorId) => `${managerAPI}/Doctors/${doctorId}/Profiles`,
  putSpecialties: (doctorId) => `${managerAPI}/Doctors/${doctorId}/Specialties`,
};

const languages = { getValid: `${adminAPI}/Languages/Valid` };

const organizationConfigs = {
  getListItems: `${adminAPI}/OrganizationConfigs/ListItems`,
};

const partnerOperations = {
  deleteCsv: (partnerCode, csvPartnerUploadedId) =>
    `${managerAPI}/PartnerOperations/Csv/${partnerCode}/${csvPartnerUploadedId}`,
  getCsv: (partnerCode) => `${managerAPI}/PartnerOperations/Csv/${partnerCode}`,
  getPartnersMine: `${managerAPI}/PartnerOperations/Partners/Mine`,
  postCsv: (partnerCode) =>
    `${managerAPI}/PartnerOperations/Csv/${partnerCode}`,
};

const partnerOperators = {
  get: `${managerAPI}/Admins`,
  getDetails: (partnerOperatorId) =>
    `${managerAPI}/Admins/${partnerOperatorId}`,
  post: `${managerAPI}/Admins/PartnerOperators`,
  put: (partnerOperatorId) =>
    `${managerAPI}/Admins/${partnerOperatorId}/PartnerOperators`,
  putClients: (partnerOperatorId) =>
    `${managerAPI}/Admins/${partnerOperatorId}/PartnerOperators/Clients`,
};

const products = {
  editSpecialties: (productId) =>
    `${managerAPI}/Products/Specialties/${productId}`,
  get: `${managerAPI}/Products`,
  getListItems: `${managerAPI}/Products/ListItems`,
};

const profiles = {
  editProducts: (profileId) => `${managerAPI}/Profiles/Products/${profileId}`,
  editRoles: (profileId) => `${managerAPI}/Profiles/Roles/${profileId}`,
  get: `${managerAPI}/Profiles`,
  getDetails: (profileId) => `${managerAPI}/Profiles/${profileId}`,
  post: `${managerAPI}/Profiles`,
  put: (profileId) => `${managerAPI}/Profiles/${profileId}`,
  getListItems: `${managerAPI}/Profiles/ListItems`,
};

const roles = {
  get: `${managerAPI}/Roles`,
  getDetails: (roleId) => `${managerAPI}/Roles/${roleId}`,
  post: `${managerAPI}/Roles`,
  put: (roleId) => `${managerAPI}/Roles/${roleId}`,
};

const sepa = {
  list: `${managerAPI}/Fees/SepaDocument`,
  download: (id) => `${managerAPI}/Fees/SepaDocument/${id}/Download`,
};

const specialties = { get: `${adminAPI}/Specialties` };

const statistics = {
  getRegistrationsCount: `${adminAPI}/Statistics/Registrations/Count`,
  getRegistrationsCountByProduct: `${adminAPI}/Statistics/Subscriptions/CountByProducts`,
  getDialogueRootsCount: `${adminAPI}/Statistics/DialogueRoots/Count`,
  getDialogueRootsCountByProduct: `${adminAPI}/Statistics/DialogueRoots/CountByProducts`,
};

const tags = {
  getClients: (clientId) => `${adminAPI}/Tags/Clients/${clientId}`,
  getProducts: `${adminAPI}/Tags/Products`,
  putProducts: `${adminAPI}/Tags/Products`,
};

const universes = {
  get: `${adminAPI}/Universes`,
  getDetails: (universeId) => `${adminAPI}/Universes/${universeId}`,
  getListItems: `${adminAPI}/Universes/ListItems`,
};

const verificationsTypes = {
  getListItems: `${managerAPI}/VerificationsTypes/ListItems`,
};

const webServices = {
  get: `${managerAPI}/Webservices`,
  getConfiguration: `${managerAPI}/Webservices/Config`,
  getValid: `${managerAPI}/Webservices/Valid`,
  getWebServiceResponse: (webServiceId) =>
    `${managerAPI}/Webservices/Response/${webServiceId}`,
  getWebServiceResponseWithUserInfo: (webServiceId) =>
    `${managerAPI}/Webservices/Response/${webServiceId}/UserInfo`,
  postConfiguration: `${managerAPI}/Webservices/Config`,
  putConfiguration: (id) => `${managerAPI}/Webservices/Config/${id}`,
  // Dany
  /* TODO: Cleaner */
  getAll: `${managerAPI}/Webservices`,
  /* TODO: Cleaner */
  getAllConfig: `${managerAPI}/WebServices/Config`,
  getWebService: (webServiceId) => `${managerAPI}/WebServices/${webServiceId}`,
  getWebServiceEntityProducts: (webServiceId) =>
    `${managerAPI}/WebServices/${webServiceId}/EntityProducts`,
  putWebService: (webServiceId) => `${managerAPI}/WebServices/${webServiceId}`,
  postWebService: `${managerAPI}/Webservices`,
  putMigrationProduct: (webServiceId, productId) =>
    `${managerAPI}/WebServices/${webServiceId}/Product/${productId}/Migrate`,
};

const admin = {
  updatePatientAddress: (patientId) =>
    `${adminAPI}/Patients/${patientId}/Address`,
  getFamilyMembers: (patientId) =>
    `${adminAPI}/Patients/${patientId}/FamilyMembers/AllFamily`,
  getValidLanguages: `${adminAPI}/Languages/Valid`,
  // Applications
  postApplication: `${adminAPI}/Applications`,
  getApplication: (idApplication) =>
    `${adminAPI}/Applications/${idApplication}`,
  getDatalake: (searchString) => `${adminAPI}/DataLake/${searchString}`,
  getUniverses: `${adminAPI}/Universes/ListItems`,
  getUniverseById: (universeId) => `${adminAPI}/Universes/${universeId}`,
  // Clients
  postCustomer: `${adminAPI}/Clients`,
  postCustomerLogo: (refClient) => `${adminAPI}/Clients/${refClient}/Upload`,
  postCustomerDefaultLogo: (refClient) =>
    `${adminAPI}/Clients/${refClient}/SetDefaultLogo`,
  getCustomerDetails: (idCustomer) =>
    `${adminAPI}/Clients/${idCustomer}/Details`,
  getCustomerProducts: (idCustomer) =>
    `${adminAPI}/Clients/${idCustomer}/Products`,
  // Offers
  getOffers: `${adminAPI}/Offers`,
  getOffer: (refOffer) => `${adminAPI}/Offers/${refOffer}`,
  postOffer: `${adminAPI}/Offers`,
  putOffer: `${adminAPI}/Offers`,
  postOfferLogo: `${adminAPI}/Offers/Upload/Logo`,
  sortOffers: `${adminAPI}/Offers/Sort`,
  // Products
  getProducts: `${adminAPI}/Products`,
  // ProductTerms
  getProductTerms: `${adminAPI}/ProductTerms`,
  // Specialties
  getSpecialties: `${adminAPI}/Specialties`,
  getSpecialty: (specialtyId) =>
    `${adminAPI}/Specialties/${specialtyId}/Details`,
  postSpecialty: `${adminAPI}/Specialties`,
  // OrganizationConfigs
  getOrganizationConfigs: `${adminAPI}/OrganizationConfigs`,
  postOrganizationConfigs: `${adminAPI}/OrganizationConfigs`,
  getOrganizationConfigById: (refOrganizationConfig) =>
    `${adminAPI}/OrganizationConfigs/${refOrganizationConfig}`,
  putOrganizationConfig: (refOrganizationConfig) =>
    `${adminAPI}/OrganizationConfigs/${refOrganizationConfig}`,
  // Univers
  getUnivers: `${adminAPI}/Universes/ListItems`,
  // Vouchers
  getVouchers: `${adminAPI}/Vouchers/Paged`,
  getVoucher: (voucherId) => `${adminAPI}/Vouchers/${voucherId}`,
  postUniverses: `${adminAPI}/Universes`,
  // FileImports
  getFileImports: `${adminAPI}/FileImports/FileImports`,
  getClientsCode: `${adminAPI}/FileImports/ClientsCodes`,
  // FileImportClients
  getFileImportClients: `${adminAPI}/FileImportClients`,
  getFileImportClientById: (idFileImportClient) =>
    `${adminAPI}/FileImportClients/${idFileImportClient}`,
  postFileImportClients: `${adminAPI}/FileImportClients`,
  putFileImportClients: (idFileImportClient) =>
    `${adminAPI}/FileImportClients/${idFileImportClient}`,
  //Nhis
  getNhiProfil: (patientId) => `${adminAPI}/NHIs/Patients/${patientId}`,
  getNHI: `${adminAPI}/NHIs`,
  putNHI: `${adminAPI}/NHIs`,
};

const feeNotes = {
  status: `${adminAPI}/Fees/DoctorsNotesStatuses`,
  doctorsNote: `${adminAPI}/Fees/DoctorsNotes`,
  cancel: `${adminAPI}/Fees/DoctorsNotes/Cancel`,
  download: `${adminAPI}/Fees/DoctorsNotes/DownloadPdf`,
};

const identity = {
  getPrincipalPhone: (userId) =>
    `${identityAPI}/Users/Phones/${userId}/Principal`,
  getLifenDoctors: (searchString) =>
    `${identityAPI}/Lifen/Doctors/${searchString}/v3`,
  getCountries: `${identityAPI}/Countries`,
  getValidLanguages: `${identityAPI}/Languages/Valid`,
  isSubscriptionAllowed: `${identityAPI}/PatientRegistration/IsAllowed`,
  postRefreshAllSubscriptions: (userId) =>
    `${identityAPI}/Subscriptions/RefreshAll/User/${userId}`,
  putChangePassword: (doctorId) =>
    `${identityAPI}/Users/ChangePassword/${doctorId}`,
};

const manager = {
  getUniversById: (idOrganization) =>
    `${managerAPI}​/Universes/${idOrganization}`,
  putUniverses: (idOrganization) => `${managerAPI}/Universes/${idOrganization}`,
  putApplication: (idApplication) =>
    `${managerAPI}/Applications/${idApplication}`,
  getClients: `${managerAPI}/Clients`,
  getApplications: `${managerAPI}/Applications`,
  getDataLake: (searchString) => `${managerAPI}/DataLake/${searchString}`,
  getDocumentTypesEligibility: `${managerAPI}/DocumentTypes/Eligibility`,
  getOrganizationConfigs: `${managerAPI}/OrganizationConfigs`,
  getPartners: `${managerAPI}/EligiblePartners`,
  getPartner: (partnerId) => `
    ${managerAPI}/EligiblePartners/${partnerId}
  `,
  getPartnerResources: (partnerId) => `
    ${managerAPI}/EligiblePartnersRessources/EligiblePartners/${partnerId}`,
  postPartner: `${managerAPI}/EligiblePartners`,
  putPartner: `${managerAPI}/EligiblePartners`,
  getProduct: (productId) => `${managerAPI}/Products/${productId}`,
  getPatients: `${managerAPI}/Patients/Paged`,
  getPatient: (patientId) => `${managerAPI}/Patients/${patientId}`,
  getUserLogs: (patientId) => `${managerAPI}/Patients/${patientId}/Logs`,
  getVerificationsTypes: `${managerAPI}/VerificationsTypes`,
  validateUser: (userId) =>
    `${managerAPI}/IdentityVerifications/User/${userId}/Validate`,
  unvalidateUser: (userId) =>
    `${managerAPI}/IdentityVerifications/User/${userId}/Unvalidate`,
  forgotPassword: (email) => `${managerAPI}/Patients/ForgotPassword/${email}`,
  getPatientHealthPartners: (userId) =>
    `${managerAPI}/Subscriptions/User/${userId}`,
  reactivateSubscription: (idSubscription) =>
    `${managerAPI}/Subscriptions/${idSubscription}/Refresh`,
  postPatientNewHealthPartner: (idUser) =>
    `${managerAPI}/Subscriptions/User/${idUser}`,
  createPatient: `${managerAPI}/Patients`,
  // Products
  getProductDetails: (productId) =>
    `${managerAPI}/Products/${productId}/Details`,
  postProduct: `${managerAPI}/Products`,
  putProduct: (productId) => `${managerAPI}/Products/${productId}`,
  uploadProductLogo: (productId) =>
    `${managerAPI}/Products/${productId}/Upload`,
  // ProductTerms
  getProductTerms: `${managerAPI}/ProductTerms`,
  getProductTermsWithMandatoryTranslations: `${managerAPI}/ProductTerms?WithMandatoryTranslations=true`,
  getProductTermsDetails: (idProductTerm) =>
    `${managerAPI}/ProductTerms/${idProductTerm}`,
  postProductTerms: `${managerAPI}/ProductTerms`,
  putProductTerms: `${managerAPI}/ProductTerms`,
  // productTermsResources
  getProductTermsResourcesDetails: (idProductTerm) =>
    `${managerAPI}/ProductTermsResources/ProductTerms/${idProductTerm}`,
  getProductTermsResources: (idProductTermResource) =>
    `${managerAPI}/ProductTermsResources/${idProductTermResource}`,
  postProductTermsResources: `${managerAPI}/ProductTermsResources`,
  putProductTermsResources: `${managerAPI}/ProductTermsResources`,
  // Webservices
  getValidWebservices: `${managerAPI}/Webservices/Valid`,
  getUniverses: `${managerAPI}/Universes`,
  postMarketingContacts: `${managerAPI}/Marketing/Contacts/Number`,
  getSendinBlueFolder: `${managerAPI}/Marketing/SendinBlue/Folders`,
  exportToSendinBlue: (folderId, listName) =>
    `${managerAPI}/Marketing/Export/${folderId}/${listName}`,
  putNhi: `${managerAPI}/NHIs`,
  getNHIPatientLogs: (patientId) =>
    `${managerAPI}/NHIs/Patients/${patientId}/Logs`,
  getNHIPatientAccessLogDetail: (accessLogId) =>
    `${managerAPI}/NHIs/Patients/AccessLogs/${accessLogId}/Details`,
  getNHIPatientWebServiceLogDetail: (webServiceLogId) =>
    `${managerAPI}/NHIs/Patients/WebServiceLogs/${webServiceLogId}/Details`,
  getNHIBirthPlaces: `${managerAPI}/NHIs/Find/BirthPlaces`,
};

const subscription = {
  getPartners: (searchString) =>
    `${identityAPI}/EligiblePartners/${searchString}`,
  isAllowed: `${subscriptionAPI}/IsAllowed`,
  redirectWithToken: ({ entityId, key }) =>
    `${identityAPI}/Token/UserExit/${entityId}/${key}`,
};

const dialogues = {
  cancelAppointment: (appointmentId) =>
    `${dialogueAPI}/ConversationsWorkflow/Appointments/${appointmentId}/Cancel`,
  getPatientFile: (patientId) =>
    `${dialogueAPI}/PatientFiles/User/${patientId}`,
  // Do not use "/PatientFiles/{id}/v2" because no SSN is editable on Admin
  updatePatientFile: (patientFileId) =>
    `${dialogueAPI}/PatientFiles/${patientFileId}`,
  getConsultationTypeChoice: (channel, offerId) =>
    `${dialogueAPI}/Planning/Duties/Offer/${offerId}/Channel/${channel}/Bookable`,
  addAppointments: `${dialogueAPI}/ConversationsWorkflow`,
  postMessage: `${dialogueAPI}/Operator/Dialogues/Patient/Add`,
  uploadFile: (dialogueId, code, subcode = '') =>
    `${dialogueAPI}/Operator/Dialogues/${dialogueId}/Documents/Upload/${code}/${subcode}`,
  getDialogues: (patientId, codeLanguage) =>
    `${dialogueAPI}/Operator/Dialogues/User/${patientId}/${codeLanguage}`,
  getConversation: (patientId, codeLanguage = 'fr') =>
    `${dialogueAPI}/Operator/Dialogues/Conversation/${patientId}/${codeLanguage}`,
  getSurveys: (patientId) => `${dialogueAPI}/Questionnaires/User/${patientId}`,
  getOffers: (patientId, codeLanguage) =>
    `${dialogueAPI}/Offers/Patients/${patientId}/${codeLanguage}/v5`,
  getOfferSubscriptions: (refOffer, patientId, codeLanguage) =>
    `${dialogueAPI}/Offers/${refOffer}/Subscriptions/${patientId}/${codeLanguage}`,
  getPrerequisites: (userId) =>
    `${dialogueAPI}/Dialogues/Prerequisites/${userId}/Check/v2`,
  getDocument: (documentId) =>
    `${dialogueAPI}/DialogueRootDocuments/Document/${documentId}`,
};

const user = {
  create: subscriptionAPI,
  isEmailUsed: (email) => `${userAPI}/IsEmailUsed/${email}`,
};

const identityVerifications = {
  getPageD: `${adminAPI}/IdentityVerifications/Paged`,
  getDocument: (documentId) =>
    `${adminAPI}​/IdentityVerifications/Document/${documentId}`,
  getUser: (userId) => `${adminAPI}​/IdentityVerifications​/User/${userId}`,
  putDocumentStatus: (documentId, documentStatus) =>
    `${adminAPI}​/IdentityVerifications/Document/${documentId}/Status/${documentStatus}`,
};

const identityValidations = {
  getPages: `${adminAPI}/IdentityValidations/Paged`,
  getUser: (patientId) =>
    `${adminAPI}​/IdentityValidations/Patients/${patientId}`,
  getDocument: (documentId) =>
    `${adminAPI}​/IdentityValidations/Documents/${documentId}`,
  putDocumentStatus: `${adminAPI}​/IdentityValidations`,
};

const administrators = {
  getDetails: (adminId) => `${adminAPI}/Admins/${adminId}`,
  getList: `${adminAPI}/Admins`,
  post: `${managerAPI}/Admins`,
  put: (adminId) => `${adminAPI}/Admins/${adminId}`,
  putForgotPassword: (adminId) =>
    `${adminAPI}/Admins/ForgotPassword/UserId/${adminId}`,
  putProfiles: (adminId) => `${adminAPI}/Admins/${adminId}/Profiles`,
};

const voucherGroups = {
  getList: `${adminAPI}/VoucherGroups`,
  getVoucherById: (voucherId) => `${adminAPI}/VoucherGroups/${voucherId}`,
};

const fileImportConfigs = {
  getList: `${adminAPI}/FileImportConfigs`,
};

const patients = {
  delete: (patientId) => `${adminAPI}/Patients/${patientId}`,
  get: (patientId) => `${managerAPI}/Patients/${patientId}`,
  put: (patientId) => `${managerAPI}/Patients/${patientId}`,
};

const questionnaires = {
  getList: `${adminAPI}/Questionnaires`,
  post: `${adminAPI}/Questionnaires`,
  getDetails: (id) => `${adminAPI}/Questionnaires/${id}/Details`,
  put: (id) => `${adminAPI}/Questionnaires/${id}`,
  upload: (id) => `${adminAPI}/Questionnaires/${id}/Upload`,
};

const templateMessages = {
  get: (templateMessagesId) =>
    `${adminAPI}/TemplateMessages/${templateMessagesId}`,
  post: `${adminAPI}/TemplateMessages`,
  put: (templateMessagesId) =>
    `${adminAPI}/TemplateMessages/${templateMessagesId}`,
  getList: `${adminAPI}/TemplateMessages`,
  getOrganization: `${adminAPI}/Profiles/Questionnaires`,
};

const banners = {
  getAll: `${adminAPI}/Banners`,
  get: (bannerId) => `${adminAPI}/Banners/${bannerId}`,
  put: `${adminAPI}/Banners`,
  post: `${adminAPI}/Banners`,
};

const users = { getUserInformation: `${managerAPI}/Users/UserInfos` };

const api = {};

api.actualities = actualities;
api.admin = admin;
api.applications = applications;
api.administrators = administrators;
api.banners = banners;
api.clients = clients;
api.dialogues = dialogues;
api.directories = directories;
api.doctors = doctors;
api.feeNotes = feeNotes;
api.fileImportConfigs = fileImportConfigs;
api.identity = identity;
api.identityVerifications = identityVerifications;
api.identityValidations = identityValidations;
api.languages = languages;
api.manager = manager;
api.organizationConfigs = organizationConfigs;
api.partnerOperations = partnerOperations;
api.partnerOperators = partnerOperators;
api.patients = patients;
api.products = products;
api.profiles = profiles;
api.questionnaires = questionnaires;
api.roles = roles;
api.sepa = sepa;
api.specialties = specialties;
api.statistics = statistics;
api.subscription = subscription;
api.tags = tags;
api.templateMessages = templateMessages;
api.universes = universes;
api.user = user;
api.users = users;
api.verificationsTypes = verificationsTypes;
api.voucherGroups = voucherGroups;
api.webServices = webServices;
api.fileImportConfigs = fileImportConfigs;
api.questionnaires = questionnaires;
api.templateMessages = templateMessages;

export default api;
