import React, { useContext, useReducer, useRef, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import { service } from 'services';
import { IntlProvider } from 'react-intl';

import { api, getDomain } from 'utils';
import { FETCH_LANGUAGES, SET_LANGUAGE } from './actions';

import { EnTranslation, FrTranslation } from './translations';

import { reducer, initialState } from './reducer';

const Context = React.createContext();

export const LanguagesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const prevLanguageRef = useRef(null);

  const fetchLanguages = () => {
    service.get(api.identity.getValidLanguages).then((response) => {
      const languages = response.data.filter(
        (el) =>
          el.code.toLowerCase() !== 'es' && el.code.toLowerCase() !== 'pt',
      );

      dispatch({
        type: FETCH_LANGUAGES,
        payload: {
          languagesList: languages,
        },
      });
    });
  };

  const getBrowserLanguage = () => {
    const nav = window.navigator;
    const browserLanguagePropertyKeys = [
      'language',
      'browserLanguage',
      'systemLanguage',
      'userLanguage',
    ];
    let browserLanguage;

    for (let i = 0; i < browserLanguagePropertyKeys.length; i++) {
      browserLanguage = nav[browserLanguagePropertyKeys[i]];
      if (browserLanguage && browserLanguage.length) {
        return browserLanguage.slice(0, 2);
      }
      return null;
    }
  };

  const changeLanguage = (newLanguage) => {
    if (newLanguage === prevLanguageRef) return;

    const formattedLanguage = String(newLanguage).toUpperCase();
    document.cookie = `language=${formattedLanguage}; path=/; samesite=strict; domain=${getDomain()}`;
    prevLanguageRef.current = formattedLanguage;
    moment.locale(newLanguage.toLowerCase());

    dispatch({
      type: SET_LANGUAGE,
      payload: {
        currentLanguage: formattedLanguage,
      },
    });
  };

  const getUrlLanguage = () => {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const urlLanguage = url.searchParams.get('lg');

    if (urlLanguage) {
      return urlLanguage;
    }
    return null;
  };

  const getCookie = (cookieName) => {
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookiesArray = decodedCookie.split(';');
    for (let i = 0; i < cookiesArray.length; i++) {
      let cookie = cookiesArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) > -1) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return false;
  };

  const getTranslation = (newLanguage) => {
    switch (newLanguage) {
      case 'EN':
        return EnTranslation;

      default:
        return FrTranslation;
    }
  };

  return (
    <IntlProvider
      locale={state.currentLanguage || state.initialLanguage}
      defaultLocale='fr-FR'
      messages={getTranslation(state.currentLanguage || state.initialLanguage)}
      textComponent={React.Fragment}
    >
      <Context.Provider
        value={{
          state,
          changeLanguage,
        }}
      >
        {children}
      </Context.Provider>
    </IntlProvider>
  );
};

export const useLanguages = () => {
  return useContext(Context);
};
