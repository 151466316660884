import {
  SET_LANGUAGE,
  FETCH_LANGUAGES
} from './actions';

export const initialState = {
  initialLanguage: 'FR',
  currentLanguage: false,
  languagesList: []
};

export const reducer = (state = {}, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        ...action.payload
      };
    case FETCH_LANGUAGES:
      return {
        ...state,
        ...action.payload
      }
  }
}