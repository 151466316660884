import { defineMessages } from 'react-intl';

export default defineMessages({
  Title: {
    id: 'Specialties.Create.Title',
    defaultMessage: 'Error.Specialties.Create.Title',
  },
  ToastFail: {
    id: 'Specialties.Create.ErrorBody',
    defaultMessage: 'Error.Specialties.Create.ErrorBody',
  },
  ToastSuccess: {
    id: 'Specialties.Create.SuccessBody',
    defaultMessage: 'Error.Specialties.Create.SuccessBody',
  },
});
