import { defineMessages } from 'react-intl';

export default defineMessages({
  ProductTermsCode: {
    id: 'ProductTerms.Code',
    defaultMessage: 'Error.ProductTerms.Code',
  },
  ProductTermsLabel: {
    id: 'ProductTerms.Label',
    defaultMessage: 'Error.ProductTerms.Label',
  },
  ProductTermsOptional: {
    id: 'ProductTerms.Optional',
    defaultMessage: 'Error.ProductTerms.Optional',
  },
  ProductTermsValid: {
    id: 'ProductTerms.Valid',
    defaultMessage: 'Error.ProductTerms.Valid',
  },
});
