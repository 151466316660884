import { defineMessages } from 'react-intl';

export default defineMessages({
  Country: {
    id: 'Model.Admin.Country',
    defaultMessage: 'Error.Model.Admin.Country',
  },
  FieldRequired: {
    id: 'Model.FieldRequired',
    defaultMessage: 'Error.Model.FieldRequired',
  },
  InvalidEmail: {
    id: 'Shared.InvalidEmailFormat',
    defaultMessage: 'Error.Shared.InvalidEmailFormat',
  },
  NoProfiles: {
    id: 'Model.Doctor.NoProfiles',
    defaultMessage: 'Error.Model.Doctor.NoProfiles',
  },
  PersonalInfo: {
    id: 'Screens.Doctors.Create.PersonalInfo',
    defaultMessage: 'Error.Screens.Doctors.Create.PersonalInfo',
  },
  RefUniverse: {
    id: 'Model.User.RefUniverse',
    defaultMessage: 'Error.Model.User.RefUniverse',
  },
  WrongNumber: {
    id: 'Form.FormIntlTelInput.Invalid',
    defaultMessage: 'Error.Form.FormIntlTelInput.Invalid',
  },
});
