import { defineMessages } from 'react-intl';

export default defineMessages({
  Title: {
    id: 'Screens.ProductTermResources.Create.Title',
    defaultMessage: 'Error.Screens.ProductTermResources.Create.Title',
    description: 'ProductTermResources Create page title',
  },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
    description: 'Toast title in case of error',
  },
  ErrorBody: {
    id: 'Message.Api.Error',
    defaultMessage: 'Error.Message.Api.Error',
  },
  SuccessTitle: {
    id: 'Message.Success',
    defaultMessage: 'Error.Message.Success',
    description: 'Toast title in case of success',
  },
  SuccessBody: {
    id: 'Screens.ProductTermResources.Create.SuccessBody',
    defaultMessage: 'Error.Screens.ProductTermResources.Create.SuccessBody',
    description: 'Toast body in case of success',
  },
  AlreadyExist: {
    id: 'Toasts.ProductTermsRessources.AlreadyExist',
    defaultMessage: 'Toasts.ProductTermsRessources.AlreadyExist',
    description:
      'Toast body for code error:ProductTermsResourcesForThisLanguageAlreadyExist',
  },
});
