import { defineMessages } from 'react-intl';

import { generateTranslationsObject } from 'utils';

const prefix = 'FileImportsClients.';
const keys = ['Partner', 'Entity', 'AssociatedProduct', 'ProductPlaceHolder', 'Activated'];

const additionalKeys = {
  FieldRequired: {
    id: 'Shared.FieldRequired',
    defaultMessage: 'Error.Shared.FieldRequired',
  },
};

export default defineMessages(
  generateTranslationsObject(prefix, keys, additionalKeys),
);
