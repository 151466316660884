import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { ThemeContext } from 'styled-components';
import { useIntl } from 'react-intl';
import {
  Button,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import cn from 'classnames';
import qs from 'qs';
import ContentLoader from 'react-content-loader';

import { service } from 'services';
import { api } from 'utils';
import messages from './messages';
import Wrapper from './elements';

const ContentWithLoader = ({ isLoading, content, withIcon, ...props }) => {
  const theme = useContext(ThemeContext);

  return isLoading ? (
    <ContentLoader
      speed={2}
      width={88}
      height={16}
      viewBox='0 0 88 16'
      backgroundColor={theme.color('gray-lightest')}
      foregroundColor={theme.grayShade(200)}
      {...props}
    >
      <rect x={withIcon ? 24 : 0} y='2' rx='4' ry='4' width='64' height='12' />
      {withIcon && <circle cx='8' cy='8' r='8' />}
    </ContentLoader>
  ) : (
    content
  );
};

const WebServiceItem = (props) => {
  const intl = useIntl();
  const { isTriggered, name, userInfo, webServiceId } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [webServiceResponse, setWebServiceResponse] = useState(null);
  const [isValid, setIsValid] = useState(null);
  const [isCheckingDefaultConfig, setIsCheckingDefaultConfig] = useState(false);
  const [isCheckingUserInfo, setIsCheckingUserInfo] = useState(false);

  const checkUserInfo = (values, { setSubmitting }) => {
    setIsCheckingUserInfo(true);
    service
      .get(
        `${api.webServices.getWebServiceResponseWithUserInfo(
          webServiceId,
        )}?${qs.stringify(values)}`,
      )
      .then(({ data }) => {
        setWebServiceResponse(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        setIsCheckingUserInfo(false);
        setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      ...userInfo,
      birthDate: userInfo.birthDate ? userInfo.birthDate.substr(0, 10) : null,
    },
    onSubmit: checkUserInfo,
  });

  const checkDefaultConfiguration = () => {
    formik.resetForm();
    setIsValid(null);
    setIsCheckingDefaultConfig(true);
    service
      .get(api.webServices.getWebServiceResponse(webServiceId))
      .then(({ data }) => {
        setWebServiceResponse(data);
        setIsValid(data.isRecognized && data.isAllowed);
      })
      .catch((err) => {
        console.log(err);
        setIsValid(false);
      })
      .then(() => {
        setIsCheckingDefaultConfig(false);
      });
  };

  useEffect(() => {
    checkDefaultConfiguration();
  }, [isTriggered]);

  return (
    <Wrapper color={isValid === null ? null : isValid ? 'success' : 'danger'}>
      <div className='d-flex align-items-center justify-content-between py-2 px-3'>
        <div className='d-flex align-items-center justify-content-between'>
          <div
            className={cn('status-icon-container', {
              'status-icon-container--valid': isValid !== null && isValid,
              'status-icon-container--invalid': isValid !== null && !isValid,
            })}
          >
            {isCheckingDefaultConfig ? (
              <Spinner color='primary' size='sm' />
            ) : isValid ? (
              <i className='uil uil-check-circle uil--md' />
            ) : (
              <i className='uil uil-exclamation-triangle uil--md' />
            )}
          </div>
          <h3 className='d-inline-block m-0'>{name}</h3>
        </div>
        <div>
          <Button
            className='mr-3'
            color='primary'
            onClick={() => {
              formik.resetForm();
              checkDefaultConfiguration();
            }}
            outline
            size='sm'
            type='button'
          >
            {intl.formatMessage({ ...messages.ButtonCheck })}
          </Button>
          <button
            aria-label={intl.formatMessage({ ...messages.ButtonUnfold })}
            className={cn('bg-light icon-button icon-button--sm', {
              isOpen,
            })}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            type='button'
          >
            <i className='uil uil-angle-down uil--md' />
          </button>
        </div>
      </div>
      <Collapse isOpen={isOpen}>
        <div className='border-top p-3'>
          <Row>
            <Col md='6'>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col lg='6'>
                    <FormGroup>
                      <Label for='first-name'>
                        {intl.formatMessage({
                          ...messages.FirstName,
                        })}
                      </Label>
                      <Input
                        id='first-name'
                        name='firstName'
                        bsSize='sm'
                        type='text'
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg='6'>
                    <FormGroup>
                      <Label for='last-name'>
                        {intl.formatMessage({
                          ...messages.LastName,
                        })}
                      </Label>
                      <Input
                        id='last-name'
                        name='lastName'
                        bsSize='sm'
                        type='text'
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg='6'>
                    <FormGroup>
                      <Label for='membership-code'>
                        {intl.formatMessage({
                          ...messages.MembershipCode,
                        })}
                      </Label>
                      <Input
                        id='membership-code'
                        name='membershipCode'
                        bsSize='sm'
                        type='text'
                        onChange={formik.handleChange}
                        value={formik.values.membershipCode}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg='6'>
                    <FormGroup>
                      <Label for='birth-date'>
                        {intl.formatMessage({
                          ...messages.BirthDate,
                        })}
                      </Label>
                      <Input
                        id='birth-date'
                        name='birthDate'
                        bsSize='sm'
                        type='date'
                        onChange={formik.handleChange}
                        value={formik.values.birthDate}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Button
                  color='primary'
                  disabled={formik.isSubmitting}
                  outline
                  size='sm'
                  type='submit'
                >
                  {intl.formatMessage({
                    ...messages.ButtonCheckEligibility,
                  })}
                </Button>
              </Form>
            </Col>
            <Col md='6'>
              <div className='line-height-sm mb-2'>
                <p className='font-weight-bold m-0 small text-secondary'>
                  {intl.formatMessage({
                    ...messages.URL,
                  })}
                </p>
                <ContentWithLoader
                  isLoading={isCheckingUserInfo || isCheckingDefaultConfig}
                  content={
                    <code>
                      <small>{webServiceResponse?.endPoint}</small>
                    </code>
                  }
                />
              </div>
              <div className='line-height-sm mb-2'>
                <p className='font-weight-bold m-0 small text-secondary'>
                  {intl.formatMessage({
                    ...messages.Response,
                  })}
                </p>
                <ContentWithLoader
                  isLoading={isCheckingUserInfo || isCheckingDefaultConfig}
                  content={
                    <code>
                      <small>{webServiceResponse?.response}</small>
                    </code>
                  }
                />
              </div>
              <div className='line-height-sm mb-2'>
                <p className='font-weight-bold m-0 small text-secondary'>
                  {intl.formatMessage({
                    ...messages.Message,
                  })}
                </p>
                <ContentWithLoader
                  isLoading={isCheckingUserInfo || isCheckingDefaultConfig}
                  content={
                    <code>
                      <small>{webServiceResponse?.message}</small>
                    </code>
                  }
                />
              </div>
              <div className='line-height-sm mb-2'>
                <p className='font-weight-bold m-0 small text-secondary'>
                  {intl.formatMessage({
                    ...messages.ResponseTime,
                  })}
                </p>
                <ContentWithLoader
                  isLoading={isCheckingUserInfo || isCheckingDefaultConfig}
                  content={
                    <code>
                      <small>
                        {webServiceResponse?.timeToRespond}
                        ms
                      </small>
                    </code>
                  }
                />
              </div>
              <div>
                <p className='font-weight-bold m-0 small text-secondary'>
                  {intl.formatMessage({
                    ...messages.Status,
                  })}
                </p>
                <div>
                  <ContentWithLoader
                    isLoading={isCheckingUserInfo || isCheckingDefaultConfig}
                    content={
                      <div
                        className={cn('d-flex align-items-center', {
                          'text-success': webServiceResponse?.isRecognized,
                          'text-danger': !webServiceResponse?.isRecognized,
                        })}
                      >
                        {webServiceResponse?.isRecognized ? (
                          <>
                            <i className='uil uil-check-circle' />
                            <p className='font-weight-bold m-0 ml-2 small'>
                              {intl.formatMessage({
                                ...messages.IsRecognized,
                              })}
                            </p>
                          </>
                        ) : (
                          <>
                            <i className='uil uil-exclamation-triangle' />
                            <p className='font-weight-bold m-0 ml-2 small'>
                              {intl.formatMessage({
                                ...messages.IsNotRecognized,
                              })}
                            </p>
                          </>
                        )}
                      </div>
                    }
                    withIcon
                  />
                </div>
                <div>
                  <ContentWithLoader
                    isLoading={isCheckingUserInfo || isCheckingDefaultConfig}
                    content={
                      <div
                        className={cn('d-flex align-items-center', {
                          'text-success': webServiceResponse?.isAllowed,
                          'text-danger': !webServiceResponse?.isAllowed,
                        })}
                      >
                        {webServiceResponse?.isAllowed ? (
                          <>
                            <i aria-hidden className='uil uil-check-circle' />
                            <p className='font-weight-bold m-0 ml-2 small'>
                              {intl.formatMessage({
                                ...messages.IsAllowed,
                              })}
                            </p>
                          </>
                        ) : (
                          <>
                            <i
                              aria-hidden
                              className='uil uil-exclamation-triangle'
                            />
                            <p className='font-weight-bold m-0 ml-2 small'>
                              {intl.formatMessage({
                                ...messages.IsNotAllowed,
                              })}
                            </p>
                          </>
                        )}
                      </div>
                    }
                    withIcon
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Collapse>
    </Wrapper>
  );
};

WebServiceItem.defaultProps = {
  userInfo: {
    birthDate: '',
    firstName: '',
    lastName: '',
    memberShipCode: '',
    webServiceId: '',
  },
  webServiceId: undefined,
};

WebServiceItem.propTypes = {
  isTriggered: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  userInfo: PropTypes.oneOfType(PropTypes.object),
  webServiceId: PropTypes.string,
};

export default WebServiceItem;
