import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useIntl } from 'react-intl';
import { Spinner } from 'reactstrap';
import { observer } from 'mobx-react-lite';

import {
  Checkbox,
  FormInput,
  FormInputAutosuggest,
  FormInputFeedback,
  FormInputLogo,
  FormLabel,
  FormSelect,
} from 'components';
import { service } from 'services';
import { api, useFetch } from 'utils';
import ConfigurationStore from 'stores/Configuration';
import messages from './messages';

const getClientsSellsy = async (value) => {
  const response = await service.get(api.manager.getDataLake(value));
  return response.data;
};

const Product = observer(({ setLogo, ...props }) => {
  const intl = useIntl();
  // Formik properties
  const { errors, initialValues, setValues, touched, values } = props;
  const [clients, areClientsLoading] = useFetch(api.clients.getListItems);
  const [productTerms, areProductTermsLoading] = useFetch(
    api.manager.getProductTermsWithMandatoryTranslations,
  );
  const [validWebServices, areValidWebServicesLoading] = useFetch(
    api.webServices.getValid,
  );
  const [isWebserviceVerificationType, setIsWebserviceVerificationType] =
    useState(false);

  useEffect(() => {
    if (
      values.refVerificationType === ConfigurationStore.getRefWebServiceType()
    )
      setIsWebserviceVerificationType(true);
    else setIsWebserviceVerificationType(false);
  }, [values, ConfigurationStore.getRefWebServiceType]);

  return (
    <>
      {/* TODO: contentType manquant dans l'API (/Products/{id}/Details) */}
      <FormInputLogo
        defaultImage={
          initialValues.rawFile
            ? { contentType: 'image/*', rawFile: initialValues.rawFile }
            : undefined
        }
        name='logo'
        setFormLogoData={setLogo}
      />
      <FormInput
        label={intl.formatMessage({ ...messages.Code })}
        hasRawError
        name='code'
        type='text'
        required
      />
      <FormInput
        label={intl.formatMessage({ ...messages.Label })}
        hasRawError
        name='label'
        type='text'
        required
      />
      <FormInput
        label={intl.formatMessage({ ...messages.Tag })}
        name='productTag'
        type='text'
      />
      <FormInput
        label={intl.formatMessage({ ...messages.MaxQuestionsCount })}
        hasRawError
        min='0'
        name='maxQuestionCount'
        type='number'
      />
      <FormInput
        label={intl.formatMessage({ ...messages.Duration })}
        min='0'
        name='duration'
        type='number'
      />
      <FormInput
        label={intl.formatMessage({ ...messages.ExpirationDate })}
        name='dateExpiration'
        type='date'
      />
      {areClientsLoading ? (
        <Spinner color='primary' />
      ) : (
        <Field name='refClient'>
          {({ field, form, meta }) => (
            <FormSelect
              hasError={Boolean(meta.touched && meta.error)}
              label={intl.formatMessage({ ...messages.Client })}
              meta={meta}
              name={field.name}
              onChange={(opt) => {
                form.setFieldValue('refClient', opt.value);
              }}
              options={clients.map((el) => ({ label: el.label, value: el.id }))}
              required
            />
          )}
        </Field>
      )}
      <div className='mb-3'>
        <FormLabel
          className='font-weight-bold text-secondary'
          for='sellSyClientAutocomplete'
          required
        >
          {intl.formatMessage({
            ...messages.SellsyClient,
          })}
        </FormLabel>
        <FormInputAutosuggest
          fetchRequest={getClientsSellsy}
          initialValue={
            values.labelClientSellsy ? values.labelClientSellsy : ''
          }
          inputProperties={{
            invalid: touched?.refClientSellsy && errors?.refClientSellsy,
            name: 'sellSyClientAutocomplete',
            placeholder: intl.formatMessage({
              ...messages.SellsyClientPlaceholder,
            }),
          }}
          labelKey='partnerLabel'
          onSuggestionsFetchRequested={() => {
            setValues((formikProps) => {
              return {
                ...formikProps,
                refClientSellsy: '',
                labelClientSellsy: '',
                partnerLabel: '',
              };
            });
          }}
          onSuggestionSelected={(el) => {
            setValues((formikProps) => {
              return {
                ...formikProps,
                refClientSellsy: el.externalPartnerId,
                labelClientSellsy: el.partnerLabel,
                partnerLabel: el.partnerLabel,
              };
            });
          }}
          required
        />
        {touched.refClientSellsy && errors.refClientSellsy && (
          <small className='text-danger'>{errors.refClientSellsy}</small>
        )}
      </div>
      {ConfigurationStore.verificationsTypes.length > 0 ? (
        <Field name='refVerificationType'>
          {({ field, form, meta }) => (
            <FormSelect
              hasError={Boolean(meta.touched && meta.error)}
              label={intl.formatMessage({ ...messages.VerificationType })}
              meta={meta}
              name={field.name}
              onChange={(opt) => {
                form.setFieldValue('refVerificationType', opt.value);
              }}
              options={ConfigurationStore.verificationsTypes.map((el) => ({
                label: el.label,
                value: el.id,
              }))}
              required
            />
          )}
        </Field>
      ) : null}
      {isWebserviceVerificationType ? (
        areValidWebServicesLoading ? (
          <Spinner color='primary' />
        ) : (
          <Field name='refWebService'>
            {({ field, form, meta }) => (
              <FormSelect
                hasError={Boolean(meta.touched && meta.error)}
                label={intl.formatMessage({ ...messages.WebService })}
                meta={meta}
                name={field.name}
                onChange={(opt) => {
                  form.setFieldValue('refWebService', opt.value);
                }}
                options={validWebServices.map((el) => ({
                  label: el.name,
                  value: el.webServiceId,
                }))}
                required
              />
            )}
          </Field>
        )
      ) : null}
      {areProductTermsLoading ? (
        <Spinner color='primary' />
      ) : (
        <Field name='refProductTerm'>
          {({ field, form, meta }) => (
            <FormSelect
              hasError={Boolean(meta.touched && meta.error)}
              label={intl.formatMessage({ ...messages.ProductTerms })}
              meta={meta}
              name={field.name}
              onChange={(opt) => {
                form.setFieldValue('refProductTerm', opt.value);
              }}
              options={productTerms.map((el) => ({
                label: el.label,
                value: el.idProductTerm,
              }))}
            />
          )}
        </Field>
      )}
      <div>
        <Field name='valid'>
          {({ field }) => (
            <Checkbox {...field} checked={values.valid} id='valid'>
              {intl.formatMessage({ ...messages.Valid })}
            </Checkbox>
          )}
        </Field>
        <FormInputFeedback name='valid' />
      </div>
    </>
  );
});

Product.propTypes = {
  setLogo: PropTypes.func.isRequired,
};

Product.displayName = 'Models_Product';

export default Product;
