import React from 'react';
import { Switch } from 'react-router-dom';
import { urlSchema } from 'utils';

import { ProtectedRoute } from 'components';

// Screens
import List from './List';
import Read from './Read';
import Create from './Create';
import Update from './Update';

const routes = [
  { path: urlSchema.actualities.create, component: Create, right: 'CRUD' },
  { path: `${urlSchema.actualities.read}/:actualityId`, component: Read },
  {
    path: `${urlSchema.actualities.update}/:actuRef`,
    component: Update,
    right: 'CRUD',
  },
  {
    path: '/',
    component: List,
    right: 'Reader',
  },
  { path: '/', component: List, right: 'Reader' },
];

const Actualities = () => {
  return (
    <Switch>
      {routes.map((item) => (
        <ProtectedRoute entity='Actualities' key={item.path} {...item} />
      ))}
    </Switch>
  );
};

export default Actualities;
