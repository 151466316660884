import { api } from 'utils';
import { service } from 'services';
import { PlaceType, BirthPlace } from './types';

type BirthPlaceResponse = BirthPlace[];

type BirthPlaceOption = BirthPlace & {
  displayLabel: string;
};

type Response = {
  succeeded: false;
};

type Query = {
  placeType: PlaceType;
  birthPlaceName: string;
  birthDate: string;
  take: number;
};

export const getNhiBirthPlaces = async (
  birthPlaceQuery: Query,
): Promise<BirthPlaceOption[]> => {
  try {
    const { data } = await service.get<Response | BirthPlaceResponse>(
      api.manager.getNHIBirthPlaces,
      {
        params: birthPlaceQuery,
      },
    );

    if ('succeeded' in data && data.succeeded === false) {
      throw new Error('An error has occured.');
    }

    const birthPlaceArray = data as BirthPlaceResponse;

    if (birthPlaceArray.length === 0) {
      return [];
    }

    return birthPlaceArray.map((birthPlace) => ({
      ...birthPlace,
      displayLabel: `${birthPlace.name} (${birthPlace.inseeCode})`,
    }));
  } catch (error) {
    console.log(error);
    return [];
  }
};
