const initialValues = {
  logoFile: null,
  code: '',
  label: '',
  refClientSellsy: '',
  labelClientSellsy: '',
  refUniverse: '',
};

export default initialValues;
