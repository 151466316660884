import { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { isNil } from 'lodash';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { useOidc } from 'utils';
import { PasswordExpired } from './screens/PasswordExpired/PasswordExpired';
import ConfigurationStore from './stores/Configuration';
import Routes from './routes/Routes';
import StyledToastContainer from './components/Toast/ToastContainer';
import { Sidebar } from './components';

import {
  ContainerWrapper,
  MainContentWrapper,
} from './themes/jupiter/elements';
import { SidebarContent } from './constants';

const queryClient = new QueryClient();

const App = () => {
  const location = useLocation();
  const oidcUser = useOidc();

  useEffect(() => {
    if (!isNil(oidcUser)) {
      ConfigurationStore.initialize();
    }
  }, [oidcUser]);

  if (oidcUser.profile.isPasswordExpired)
    return (
      <Switch>
        <Route exact path='/password-expired'>
          <PasswordExpired />
        </Route>
        <Route path='/'>
          <Redirect
            to={{
              pathname: '/password-expired',
              state: { referrer: location.pathname },
            }}
          />
        </Route>
      </Switch>
    );

  return (
    <QueryClientProvider client={queryClient}>
      <ContainerWrapper>
        <Sidebar content={SidebarContent} />
        <MainContentWrapper
          id='MainContentWrapper'
          style={{ minHeight: '100vh' }}
        >
          <Routes />
        </MainContentWrapper>
        <StyledToastContainer />
      </ContainerWrapper>
    </QueryClientProvider>
  );
};

export default App;
