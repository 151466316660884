import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonCreate: {
    id: 'Buttons.Create',
    defaultMessage: 'Error.Buttons.Create',
  },
  Search: {
    id: 'Screens.Actualities.List.Search',
    defaultMessage: 'Error.Screens.Actualities.List.Search',
  },
  Title: {
    id: 'Screens.Actualities.List.Title',
    defaultMessage: 'Error.Screens.Actualities.List.Title',
  },
  Valid: {
    id: 'Screens.Actualities.List.Valid',
    defaultMessage: 'Error.Screens.Actualities.List.Valid',
  },
});
