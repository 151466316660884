import * as yup from 'yup';
import messages from './messages';

// Attention : le champ CodeCompany est obligatoire (pas comme dans Swagger)

export default (formatMessage) =>
  yup.object().shape({
    address1: yup.string(),
    address2: yup.string(),
    birthdate: yup.date().required(formatMessage(messages.FieldRequired)),
    city: yup.string(),
    codeCompany: yup.string(),
    codeCountry: yup.string(),
    email: yup
      .string()
      .email(formatMessage(messages.InvalidEmail))
      .required(formatMessage(messages.FieldRequired)),
    firstname: yup.string().required(formatMessage(messages.FieldRequired)),
    lastname: yup.string().required(formatMessage(messages.FieldRequired)),
    mobileNumber: yup.string(),
    notification: yup.string().required(formatMessage(messages.FieldRequired)),
    postalCode: yup.string(),
    phoneNumber: yup.string(),
    refUniverse: yup.string().required(formatMessage(messages.FieldRequired)),
    signature: yup.string(),
    valid: yup.boolean().required(formatMessage(messages.FieldRequired)),
  });
