import { defineMessages } from 'react-intl';

export default defineMessages({
  Administrator: {
    id: 'Screens.Admins.List.Administrator',
    defaultMessage: 'Error.Screens.Admins.List.Administrator',
  },
  ButtonCreate: {
    id: 'Buttons.Create',
    defaultMessage: 'Error.Buttons.Create',
  },
  ButtonManageProfiles: {
    id: 'Buttons.ManageProfiles',
    defaultMessage: 'Error.Buttons.ManageProfiles',
  },
  Clients: {
    id: 'Screens.Admins.List.Clients',
    defaultMessage: 'Error.Screens.Admins.List.Clients',
  },
  Contact: {
    id: 'Screens.Admins.List.Contact',
    defaultMessage: 'Error.Screens.Admins.List.Contact',
  },
  CreateAdmin: {
    id: 'Screens.Admins.List.CreateAdmin',
    defaultMessage: 'Error.Screens.Admins.List.CreateAdmin',
  },
  CreateOperatorPartner: {
    id: 'Screens.Admins.List.CreateOperatorPartner',
    defaultMessage: 'Error.Screens.Admins.List.CreateOperatorPartner',
  },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
    description: 'Toast title in case of Error',
  },
  FailBody: {
    id: 'Screens.Applications.Create.FailBody',
    defaultMessage: 'Error.Screens.Applications.Create.FailBody',
    description: 'Toast body in case of error',
  },
  NoElement: {
    id: 'Shared.NoElement',
    defaultMessage: 'Error.Shared.NoElement',
  },
  Profiles: {
    id: 'Screens.Admins.List.Profiles',
    defaultMessage: 'Error.Screens.Admins.List.Profiles',
  },
  Search: {
    id: 'Screens.Admins.List.Search',
    defaultMessage: 'Error.Screens.Admins.List.Search',
  },
  Title: {
    id: 'Screens.Admins.List.Title',
    defaultMessage: 'Error.Screens.Admins.List.Title',
  },
});
