import styled from 'styled-components';

const AuthenticationWrapper = styled.section`
  ${({ theme }) => `
    left: 50%;
    position: fixed;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);

    p { 
      margin: 0;
    }

    a {
      color: ${theme.color('blue-font')};
    }
  `}
`;

export default AuthenticationWrapper;
