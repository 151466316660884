import React from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-use';
import { FormattedMessage } from 'react-intl';

import DragAndDropWrapper from './elements';
import messages from './messages';

const DragAndDrop = ({ onFile }) => {
  const { over } = useDrop({
    onFiles: (files) => onFile(files),
    onUri: (uri) => console.log('uri', uri),
    onText: (text) => console.log('text', text),
  });

  return (
    <DragAndDropWrapper hovered={over}>
      <i className='uil uil-image-v uil--lg' aria-hidden />
      <FormattedMessage {...messages.NoImage} tagName='p' />
    </DragAndDropWrapper>
  );
};

DragAndDrop.propTypes = {
  onFile: PropTypes.func.isRequired,
};

export default DragAndDrop;
