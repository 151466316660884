import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Shared.Healthfile',
    defaultMessage: 'Error.Shared.Healthfile',
  },
  administrativeNavItem: {
    id: 'Screens.Dialogues.Create.PatientInformations.NavItem.Administrative',
    defaultMessage:
      'Error.Screens.Dialogues.Create.PatientInformations.NavItem.Administrative',
    description: 'Administrative nav Item',
  },
  medicalNavItem: {
    id: 'Screens.Dialogues.Create.PatientInformations.NavItem.Medical',
    defaultMessage:
      'Error.Screens.Dialogues.Create.PatientInformations.NavItem.Medical',
    description: 'Medical nav Item',
  },
});
