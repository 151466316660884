export default {
  address1: '',
  address2: '',
  available: false,
  birthdate: '',
  city: '',
  codeLanguage: '',
  collegeNumber: '',
  collegeNumberAutocomplete: '',
  comments: '',
  cpsCard: 'null',
  cpsReader: 'null',
  directoryMatchingId: '',
  email: '',
  feesContractTypeId: '',
  firstname: '',
  fonction: '',
  generateFeesNotes: 'false',
  googleAddress: '',
  healthProfessionalSelected: false,
  lastname: '',
  mobileNumber: '',
  nationality: '',
  notification: '',
  ordinalQualificationId: '',
  ordinalQualificationLabel: '',
  phoneNumber: '',
  practitionerIdentifierNumberType: '',
  prescriptionBook: 'null',
  refDocumentScanSignature: '',
  refUniverse: '',
  refUserCreator: '',
  selectedProfiles: [],
  selectedHealthProfessional: '',
  signature: '',
  title: '',
  valid: true,
  zipCode: '',
};
