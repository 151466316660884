import React from 'react';
import PropTypes from 'prop-types';

const SectionFooter = ({ children, ...props }) => (
  <footer className='section__footer' {...props}>
    {children}
  </footer>
);

SectionFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SectionFooter;
