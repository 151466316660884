import { defineMessages } from 'react-intl';

export default defineMessages({
  Code: {
    id: 'Applications.Code',
    defaultMessage: 'Error.Applications.Code',
    description: 'Code of an applications',
  },
  Label: {
    id: 'Applications.Label',
    defaultMessage: 'Error.Applications.Label',
    description: 'Label of an applications',
  },
  Url: {
    id: 'Applications.Url',
    defaultMessage: 'Error.Applications.Url',
    description: 'Url of an applications',
  },
});
