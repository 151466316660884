import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Formik, Form } from 'formik';
import { Col, Row } from 'reactstrap';

import { Button, PageHeader, Section, SectionBody } from 'components';
import { ButtonsWrapper } from 'themes/jupiter/elements';
import { service } from 'services';
import { api, sendToast, urlSchema } from 'utils';
import { ToastTypes } from '../../../constants';
import { OrganizationConfig } from '../../../models';
import initialValues from './initialValues';
import validationSchema from './validationShema';
import messages from './messages';

const Create = () => {
  const history = useHistory();

  const onSubmit = (values, { setSubmitting }) => {
    service
      .post(api.admin.postOrganizationConfigs, values)
      .then((res) => {
        if (res.data.succeeded === false) {
          sendToast(ToastTypes.error, {
            header: <FormattedMessage {...messages.FailTitle} />,
            body: (
              <FormattedMessage
                {...messages.FailBody}
                values={{
                  code: values.code,
                  label: values.label,
                  description: `${res.data.errorSet[0].description} ${res.data.errorSet[1]?.description}`,
                }}
              />
            ),
          });
          setSubmitting(false);
          return;
        }
        sendToast(ToastTypes.success, {
          header: <FormattedMessage {...messages.SuccessTitle} />,
          body: (
            <FormattedMessage
              {...messages.SuccessBody}
              values={{
                code: values.code,
              }}
            />
          ),
        });
        history.push(urlSchema.organizationConfigs.list);
      })
      .catch((res) => {
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.FailTitle} />,
          body: (
            <FormattedMessage
              {...messages.FailBody}
              values={{
                code: values.code,
                label: values.label,
                description: `${res.data.errorSet[0].description} ${res.data.errorSet[1]?.description}`,
              }}
            />
          ),
        });
        setSubmitting(false);
      });
  };
  return (
    <>
      <PageHeader>
        <FormattedMessage {...messages.Title} tagName='h1' />
      </PageHeader>
      <Row>
        <Col lg='6'>
          <Section>
            <SectionBody>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <OrganizationConfig />
                    <ButtonsWrapper bottom>
                      <Button
                        color='primary'
                        isProcessing={isSubmitting}
                        label='Create'
                        size='lg'
                        data-tracking='OrganizationConfigCreateSubmit'
                        type='submit'
                      />
                    </ButtonsWrapper>
                  </Form>
                )}
              </Formik>
            </SectionBody>
          </Section>
        </Col>
      </Row>
    </>
  );
};

export default Create;
