import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import { FormSelect, Button } from 'components';

import validationSchema from './validationSchema';
import messages from './messages';
import AddRoomFormWrapper from './elements';

const AddRoomForm = ({
  unshift,
  channelOptions,
  activeChannels,
  error = false,
}) => {
  const { formatMessage } = useIntl();
  const [translatedChannelOptions, setTranslatedChannelOptions] = useState(() =>
    channelOptions.map((option) => {
      return {
        value: option.value,
        label: formatMessage({
          id: `Constants.${option.label}`,
          defaultMessage: `Error.Constants.${option.label}`,
        }),
      };
    }),
  );

  const handleSubmit = ({ roomChannel }) => {
    if (!activeChannels.includes(roomChannel)) {
      unshift({ channel: roomChannel });
    }
  };

  useEffect(() => {
    const newOptions = channelOptions.map((channel) => {
      const found = activeChannels.includes(channel.value);

      return {
        ...channel,
        label: `${formatMessage({
          id: `Constants.${channel.label}`,
          defaultMessage: `Error.Constants.${channel.label}`,
        })} ${found ? formatMessage(messages.AlreadyUsed) : ''}`,
      };
    });

    setTranslatedChannelOptions(newOptions);
  }, [activeChannels]);

  return (
    <Formik
      initialValues={{ roomChannel: null }}
      onSubmit={(props) => handleSubmit(props)}
      validationSchema={validationSchema}
    >
      {({ setFieldTouched, setFieldValue, handleSubmit }) => (
        <AddRoomFormWrapper hasError={error}>
          <Row>
            <Col md='10'>
              <Field name='roomChannel'>
                {({ meta, field }) => (
                  <FormSelect
                    meta={meta}
                    hasError={Boolean(meta.touched && meta.error)}
                    label={formatMessage({
                      ...messages.CommunicationChannel,
                    })}
                    name={field.name}
                    onBlur={() => setFieldTouched(field.name, true)}
                    onChange={(opt) => setFieldValue(field.name, opt.value)}
                    options={translatedChannelOptions}
                    placeholder={formatMessage({
                      ...messages.SelectAChannel,
                    })}
                    required
                  />
                )}
              </Field>
            </Col>
            <Col md='2'>
              <Button onClick={handleSubmit} color='primary' label='Add' />
            </Col>
          </Row>
          {error && <p className='text-danger mb-3'>{error}</p>}
        </AddRoomFormWrapper>
      )}
    </Formik>
  );
};

AddRoomForm.propTypes = {
  unshift: PropTypes.func.isRequired,
  channelOptions: PropTypes.shape().isRequired,
  activeChannels: PropTypes.shape().isRequired,
  error: PropTypes.bool.isRequired,
};

export default AddRoomForm;
