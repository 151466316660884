import { makeAutoObservable } from "mobx";
import { Services } from '../services';

const CsvStore = class {
    _filesToUpload = {};
    _uploadHistory = [];
    _partners = [];
    _selectedPartner = '';
    _isProcessing = false;

    constructor() {
        makeAutoObservable(this);
    }

    getPartners() {
        Services.getPartnerOperationsPartnersMine().then((e) => {
            this.partners = e.data;
            this.selectedPartner = this.partners[0];
        })
    }

    getPartnerOperationsCsv(partnerCode) {
        Services.getPartnerOperationsCsv(partnerCode).then((e) => {
            this.uploadHistory = e.data;
        })
    }

    deletePartnerOperationsCsv(partnerCode, csvPartnerUploadedId) {
        return Services.deletePartnerOperationsCsv(partnerCode, csvPartnerUploadedId).then(() => {
            this.getPartnerOperationsCsv(this.selectedPartner);
        });
    }

    dropFile(fileName) {
        delete this.filesToUpload[fileName];
    }

    get filesToUpload() {
        return this._filesToUpload;
    }

    get isProcessing() {
        return this._isProcessing;
    }

    get partners() {
        return this._partners;
    }

    get selectedPartner() {
        return this._selectedPartner;
    }

    get uploadHistory() {
        return this._uploadHistory
            .slice()
            .sort((a, b) => (Number(b.id) - Number(a.id)));
    }

    set filesToUpload(files) {
        this._filesToUpload = files;
    }

    set uploadHistory(history) {
        this._uploadHistory = history;
    }

    set partners(partners) {
        this._partners = partners;
    }

    set selectedPartner(client) {
        this._selectedPartner = client;
    }

    set isProcessing(bool) {
        this._isProcessing = bool;
    }
}
export default new CsvStore();