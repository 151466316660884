import React from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { Spinner } from 'reactstrap';
import { historyShape, matchShape } from 'react-router-props';

import { Admin } from 'models';
import { ApiError, Button, PageHeader } from 'components';
import { service } from 'services';
import { api, sendToast, urlSchema, useFetch } from 'utils';
import validationSchema from 'models/Admin/validationSchema';

import { Formik, Form } from 'formik';
import Profiles from './Profiles';
import { ToastTypes } from '../../../constants';
import messages from './messages';

const Update = ({ history, match }) => {
  const { formatMessage } = useIntl();
  const { adminRef } = match.params;
  const [admin, isAdminLoading] = useFetch(
    api.administrators.getDetails(adminRef),
  );

  admin.birthdate = moment(admin.birthdate).format('YYYY-MM-DD');

  const onSubmit = (values, { setSubmitting }) => {
    service
      .put(api.administrators.put(values.idUser), values)
      .then(({ data }) => {
        if (data.succeeded === false) {
          sendToast(ToastTypes.error, {
            body: formatMessage(
              { ...messages.ToastFail },
              { name: `${values.firstname} ${values.lastname}` },
            ),
          });
          setSubmitting(false);
          return;
        }
        sendToast(ToastTypes.success, {
          body: formatMessage(
            { ...messages.ToastSuccess },
            { name: `${values.firstname} ${values.lastname}` },
          ),
        });
        history.push(urlSchema.administrators.list);
      })
      .catch((error) => {
        sendToast(ToastTypes.error, {
          header: formatMessage(
            { ...messages.ToastFail },
            { name: `${values.firstname} ${values.lastname}` },
          ),
          body: <ApiError {...{ error }} />,
        });
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <h1>
          {formatMessage(
            { ...messages.Title },
            { name: admin ? `${admin.firstname} ${admin.lastname}` : '' },
          )}
        </h1>
      </PageHeader>
      <div className='row'>
        <div className='col-7'>
          <section className='section'>
            <div className='section__body'>
              {isAdminLoading ? (
                <Spinner color='primary' size='lg' />
              ) : (
                <Formik
                  initialValues={admin}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema(formatMessage)}
                >
                  {(formikProps) => (
                    <Form>
                      {console.log(formikProps.values)}
                      <Admin />
                      <Button
                        color='primary'
                        isProcessing={formikProps.isSubmitting}
                        label='Save'
                        size='lg'
                        type='submit'
                      />
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </section>
        </div>
        <div className='col-5'>
          <section className='section'>
            <header className='section__header'>
              <h2>{formatMessage({ ...messages.Profiles })}</h2>
            </header>
            <div className='section__body'>
              {isAdminLoading ? (
                <Spinner color='primary' size='lg' />
              ) : (
                <Profiles
                  data={admin.usersProfiles}
                  isLoading={isAdminLoading}
                />
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

Update.propTypes = {
  history: historyShape.isRequired,
  match: matchShape.isRequired,
};

export default Update;
