const actualities = {
  create: `/Actualities/Create`,
  list: '/Actualities',
  read: `/Actualities/Read`,
  update: `/Actualities/Edit`,
};

const questionnaire = {
  read: `/Questionnaire/Details`,
  editQuestionnaire: `${window.__env.REACT_APP_ADMIN_WEBSITE_URL}/Questionnaires/Edit`,
};

const administrators = {
  create: '/Users/Admins/Create',
  list: '/Users/Admins',
  read: '/Users/Admins/Read',
  update: '/Users/Admins/Update',
};

const applications = {
  create: '/Applications/Create',
  editProfiles: '/Applications/EditProfiles',
  list: '/Applications',
  read: '/Applications/Read',
  update: `/Applications/Update`,
};

const clients = {
  create: '/Clients/Create',
  list: '/Clients',
  read: '/Clients/Details',
  update: '/Clients/Edit',
  manageProductTags: '/Clients/ProductTags',
};

const doctors = {
  create: '/Users/Doctors/Create',
  list: '/Users/Doctors',
  read: '/Users/Doctors/Details',
  update: '/Users/Doctors/Edit',
  editProfiles: '/Users/Doctors/EditProfiles',
  editSpecialties: '/Users/Doctors/EditSpecialties',
};

const marketing = {
  search: `/Marketing`,
  response: `/Marketing/Search`,
};
const fileImportClients = {
  list: `/FileImportClients`,
  read: `/FileImportClients/Details`,
  create: `/FileImportClients/Create`,
  edit: `/FileImportClients/Edit`,
};

const organizationConfigs = {
  list: '/OrganizationConfigs',
  read: `/OrganizationConfigs/Details`,
};

const partnerOperators = {
  create: '/Users/PartnerOperators/Create',
  read: '/Users/PartnerOperators/Read',
  update: '/Users/PartnerOperators/Update',
};

const patients = {
  // MD
  list: `/Users/Patients`,
  listMD: '/Users/Patients/MD',
  create: `/Users/Patients/MD/Create`,
  delete: '/Users/Patients/MD/Delete',
  readMd: '/Users/Patients/MD/Read',
  update: '/Users/Patients/MD/Update',
  // NHI
  listNHI: '/Users/Patients/NHI',
  createNHI: `/Users/Patients/NHI/Create`,
  deleteNHI: '/Users/Patients/NHI/Delete',
  readNHI: '/Users/Patients/NHI/Read',
  updateNHI: '/Users/Patients/NHI/Update',
  dialogueWhatToDo: (patientId) => `/Users/Patients/MD/${patientId}/WhatToDo`,
  addHealthPartner: (patientId) =>
    `/Users/Patients/${patientId}/AddHealthPartner`,
  newDialogue: (patientId) => `/Users/Patients/${patientId}/NewDialogue`,
};

const products = {
  create: '/Products/Create',
  editSpecialties: '/Products/EditSpecialties',
  list: '/Products',
  read: '/Products/Read',
  update: '/Products/Update',
};

const profiles = {
  create: '/Profiles/Create',
  editProducts: '/Profiles/EditProducts',
  editRoles: '/Profiles/EditRoles',
  list: '/Profiles',
  read: '/Profiles/Read',
  update: '/Profiles/Update',
};

const roles = {
  create: '/Roles/Create',
  list: '/Roles',
  read: `/Roles/Read`,
  update: '/Roles/Update',
};

const specialties = {
  create: '/Specialties/Create',
  list: '/Specialties',
  read: '/Specialties/Details',
  update: '/Specialties/Update',
};

const statistics = {
  list: '/Statistics',
};

const templateMessages = {
  create: '/TemplateMessages/Create',
  list: `/TemplateMessages`,
  read: '/TemplateMessages/Details',
  update: '/TemplateMessages/Update',
};

const universes = {
  list: '/Universes',
  create: `/Universes/Create`,
  read: '/Universes/Details',
  update: '/Universes/Edit',
};

const productTerms = {
  list: '/ProductTerms',
  read: `/ProductTerms/Read`,
  create: `/ProductTerms/Create`,
  edit: `/ProductTerms/Edit`,
};

const productTermsResources = {
  read: '/ProductTermsResources/Details',
  create: '/ProductTermsResources/Create',
  edit: '/ProductTermsResources/Edit',
};

const dialogues = {
  read: '/Users/Conversation',
};

const banners = {
  list: '/Banners',
  create: () => `/Banners/Create`,
  update: (id = ':id') => `/Banners/Update/${id}`,
};

const webServices = {
  configuration: {
    create: '/WebServices/Configuration/Create',
    update: '/WebServices/Configuration/Update',
    list: '/WebServices/Configuration',
    read: '/WebServices/Configuration/Read',
    details: '/WebServices/DetailsWebServiceConfigs',
  },
  root: '/WebServices',
  status: '/WebServices/Status',
  list: `/WebServices/ListWebservices`,
  create: `/WebServices/Create`,
  read: `/WebServices/Read`,
  update: `/WebServices/Update`,
};

export default {
  actualities,
  administrators,
  applications,
  banners,
  clients,
  doctors,
  dialogues,
  fileImportClients,
  organizationConfigs,
  partnerOperators,
  patients,
  products,
  profiles,
  roles,
  specialties,
  statistics,
  templateMessages,
  productTerms,
  productTermsResources,
  getWebsiteAdminUrlForOrganization: `${window.__env.REACT_APP_API_IDENTITY_URL}/Applications/Url/Suffix/WebSite.Admin`,
  marketing,
  universes,
  webServices,
  questionnaire,
  // FileImports
  fileImports: `/FileImports`,
  fileImportConfigs: `/FileImportConfigs`,
  fileImportConfigCreate: `/FileImportConfigs/Create`,
  fileImportConfigDetail: (configId) =>
    `/FileImportConfigs/Details/${configId}`,
  fileImportConfigEdit: (configId) => `/FileImportConfigs/Edit/${configId}`,
  // Clients
  createClient: `/Clients/Create`,
  readClient: (refCustomer) => `/Clients/Details/${refCustomer}`,
  editClient: (refCustomer) => `/Clients/Edit/${refCustomer}`,
  countries: `${window.__env.REACT_APP_ADMIN_WEBSITE_URL}/Countries`,
  dialogueActions: `${window.__env.REACT_APP_ADMIN_WEBSITE_URL}/DialogueActions`,
  dialogueStatus: `${window.__env.REACT_APP_ADMIN_WEBSITE_URL}/DialogueStatus`,
  dialogueTypes: `${window.__env.REACT_APP_ADMIN_WEBSITE_URL}/DialogueTypes`,
  createDoctor: `${window.__env.REACT_APP_ADMIN_WEBSITE_URL}/Users/CreateDoctor`,
  documentsType: `${window.__env.REACT_APP_ADMIN_WEBSITE_URL}/DocumentTypes`,
  hangfire: `/Hangfire`,
  identitiesVerification: `/IdentityVerifications`,
  languageLevel: `${window.__env.REACT_APP_ADMIN_WEBSITE_URL}/LanguageLevels`,
  news: `/Actualities`,
  // Partners
  partners: '/Partners',
  partnerOperations: '/PartnerOperations',
  createPartner: '/Partners/Create',
  readPartner: '/Partners/Read',
  updatePartner: '/Partners/Update',
  // Operator
  readOperator: (operatorId) =>
    `${window.__env.REACT_APP_ADMIN_WEBSITE_URL}/Users/Admin/${operatorId}`,
  // OrganizationConfigs
  editOrganizationConfigs: '/OrganizationConfigs/Edit',
  createOrganizationConfigs: `/OrganizationConfigs/Create`,
  detailsOrganizationConfigs: `/OrganizationConfigs/Details`,
  showDialogue: (dialogueId) => `/Users/Conversation/${dialogueId}`,
  showSurvey: (surveyId, patientId) =>
    `${window.__env.REACT_APP_ADMIN_WEBSITE_URL}/Users/CreateQuestionnaireResponse?questionnaireId=${surveyId}&patientId=${patientId}`,
  checkEligibility: `${window.__env.REACT_APP_ADMIN_WEBSITE_URL}/Users/CheckEligibility`,
  // Surveys
  surveys: '/Questionnaires',
  surveyDetails: `/Questionnaires/Details`,
  surveyCreate: `/Questionnaires/Create`,
  surveyUpdate: `/Questionnaires/Update`,
  // Token
  token: '/Account/Token',
  // Vouchers
  vouchersGroups: '/VoucherGroups',
  vouchersGroupsCreate: '/VoucherGroups/Create',
  vouchersGroupsDetails: '/VoucherGroups/Details',
  vouchersGroupsEdit: '/VoucherGroups/Edit',
  // Offers
  offers: `/Offers`,
  createOffer: '/Offers/Create',
  readOffer: '/Offers',
  sortOffers: '/Offers/Sort',
  updateOffer: '/Offers/Update',
  // Vouchers
  vouchers: '/Vouchers',
  readVoucher: '/Vouchers/Details',
  editVoucherGroups: (voucherGroupId, voucherId) =>
    `${window.__env.REACT_APP_ADMIN_WEBSITE_URL}/VoucherGroups/Edit/${voucherGroupId}?idVoucher=${voucherId}&returnUrl=%2FVouchers%3FRefVoucherGroup%3D%26RefProduct%3D%26SearchCode%3D11`,
  // Fees
  feesNotes: '/FeesNotes',
  // IdentityVerifications
  identityVerifications: '/IdentityVerifications',
  identityValidationsNhi: '/IdentityValidationsNhi',
  // Sepa
  sepa: '/Sepa',
  // Questionnaires
  questionnaires: '/Questionnaires',
};
