import { defineMessages } from 'react-intl';

export default defineMessages({
  Code: {
    id: 'OrganizationConfigs.Code',
    defaultMessage: 'Error.OrganizationConfigs.Code',
  },
  Label: {
    id: 'OrganizationConfigs.Label',
    defaultMessage: 'Error.OrganizationConfigs.Label',
  },
});
