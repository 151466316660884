import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import { Button, PageHeader, Section, SectionBody, Spinner } from 'components';
import { service } from 'services';
import { api, sendToast, useFetch, urlSchema } from 'utils';
import { ButtonsWrapper } from 'themes/jupiter/elements';
import validationSchema from './validationShema';
import { ToastTypes } from '../../../constants';
import { Application } from 'models';
import messages from './messages';

const Update = () => {
  const { idApplication } = useParams();
  const history = useHistory();
  const [application, isApplicationLoading] = useFetch(
    api.admin.getApplication(idApplication),
  );
  const isValid = application.valid;

  const onSubmit = (values, { setSubmitting }) => {
    let newValues = {
      idApplication: idApplication,
      code: values.code,
      label: values.label,
      url: values.url,
      valid: isValid,
    };
    service
      .put(api.manager.putApplication(idApplication), newValues)
      .then(() => {
        sendToast(ToastTypes.success, {
          header: <FormattedMessage {...messages.SuccessTitle} />,
          body: (
            <FormattedMessage
              {...messages.SuccessBody}
              values={{ label: application.label }}
            />
          ),
        });
        setSubmitting(false);
        history.push(urlSchema.applications.list);
      })
      .catch(() => {
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.FailTitle} />,
          body: (
            <FormattedMessage
              {...messages.FailBody}
              values={{ label: application.label }}
            />
          ),
        });
        setSubmitting(false);
      });
  };

  return (
    <Row>
      <Col lg='6'>
        <PageHeader>
          <FormattedMessage
            {...messages.Edit}
            tagName='h1'
            values={{ applicationLabel: application.label }}
          />
        </PageHeader>
        <Section>
          <SectionBody>
            {isApplicationLoading ? (
              <Spinner color='primary' size='lg' />
            ) : (
              <Formik
                initialValues={application}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Application application={application} />
                    <ButtonsWrapper bottom>
                      <Button
                        color='primary'
                        isProcessing={isSubmitting}
                        label='Update'
                        size='lg'
                        data-tracking='ApplicationCreateSubmit'
                        type='submit'
                      />
                    </ButtonsWrapper>
                  </Form>
                )}
              </Formik>
            )}
          </SectionBody>
        </Section>
      </Col>
    </Row>
  );
};

export default Update;
