import styled, { css } from 'styled-components';

const SurveyItemWrapper = styled.li`
  ${({ theme }) => `
    align-items: center;
    display: flex;
    border-bottom: 1px solid ${theme.grayShade(200)};
    padding-top: ${theme.space(2)};
    padding-bottom: ${theme.space(2)};
  `}
`;

const TextWrapper = styled.div`
  ${({ theme }) => css`
    padding-right: ${theme.space(3)};
  `}
`;

const SurveyType = styled.div`
  ${({ theme }) => css`
    padding: 0;
    position: relative;

    i {
      position: absolute;
      bottom: 0;
      left: 50%;
      display: block;
      text-align: center;
      line-height: 20px !important;
      width: 24px;
      color: ${theme.white};
      border: 2px solid ${theme.white};
      background-color: ${theme.color('success')};
      border-radius: 100%;
    }
  `}
`;

const TitleText = styled.p`
  ${({ theme }) => `
    ${theme.fontSize(theme.fontSizeBase, theme.fontWeightBold)};
    margin-bottom: 0;
  `}
`;

const DescriptionText = styled.p`
  ${({ theme }) => `
    margin-bottom: 0;
    ${theme.fontSize(theme.fontSizeSm())};
  `}
`;

SurveyItemWrapper.TextWrapper = TextWrapper;
SurveyItemWrapper.SurveyType = SurveyType;
SurveyItemWrapper.TitleText = TitleText;
SurveyItemWrapper.DescriptionText = DescriptionText;

export default SurveyItemWrapper;
