import React from 'react';
import { Formik, Form } from 'formik';
import { Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { ProductTermsResource } from 'models';
import { Button, PageHeader } from 'components';
import { service } from 'services';
import { api, urlSchema, sendToast } from 'utils';
import initialValues from './initialValues';
import validationSchema from 'models/ProductTermsResource/validationSchema';
import { ToastTypes } from '../../../constants';
import messages from './messages';

const Create = ({ history, match }) => {
  const intl = useIntl();
  const { params } = match;
  const onSubmit = (values, { setSubmitting }) => {
    service
      .post(api.manager.postProductTermsResources, values)
      .then((response) => {
        if (Boolean(response?.data) && response?.data?.errorSet?.length) {
          if (
            response.data.errorSet[0].code ===
            'ProductTermsResourcesForThisLanguageAlreadyExist'
          ) {
            sendToast(ToastTypes.error, {
              header: <FormattedMessage {...messages.FailTitle} />,
              body: <FormattedMessage {...messages.AlreadyExist} />,
            });
            return;
          }
        }
        sendToast(ToastTypes.success, {
          header: <FormattedMessage {...messages.SuccessTitle} />,
          body: <FormattedMessage {...messages.SuccessBody} />,
        });
        history.push(`${urlSchema.productTerms.read}/${params.refProductTerm}`);
      })
      .catch(() => {
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.FailTitle} />,
          body: <FormattedMessage {...messages.ErrorBody} />,
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <FormattedMessage {...messages.Title} tagName='h1' />
      </PageHeader>
      <Row>
        <Col md='8'>
          <section className='section'>
            <div className='section__body'>
              <Formik
                initialValues={{
                  ...initialValues,
                  idProductTermsResource:
                    '00000000-0000-0000-0000-000000000000',
                  refProductTerm: params.refProductTerm,
                }}
                onSubmit={onSubmit}
                validationSchema={validationSchema(intl)}
              >
                {({ isSubmitting, ...formikProps }) => (
                  <Form>
                    <ProductTermsResource {...formikProps} />
                    <div className='text-right'>
                      <Button
                        color='primary'
                        disabled={isSubmitting}
                        isProcessing={isSubmitting}
                        label='Create'
                        size='lg'
                        type='submit'
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </section>
        </Col>
      </Row>
    </>
  );
};

export default Create;
