const returnInitialValue = (value, isNumber = false) => {
  if (value === null) {
    return null;
  }
  if ((!isNumber && value !== '') || (isNumber && value !== 0)) {
    return 'true';
  }
  return 'false';
};

export default (patientFile) => {
  return {
    ...patientFile,
    familyDoctorAutocomplete: '',
    familyHistory:
      patientFile.familyHistory === null ? '' : patientFile.familyHistory,
    hasAdditionalInformations: String(patientFile.hasAdditionalInformations),
    hasFamilyDoctor: String(patientFile.hasFamilyDoctor),
    hasMedicatedAllergies: String(patientFile.hasMedicatedAllergies),
    hasMedicalHistory: String(patientFile.hasMedicalHistory),
    height: patientFile.height,
    isSmoking: returnInitialValue(patientFile.smoking, true),
    rpps: patientFile.hasFamilyDoctor
      ? patientFile.familyDoctorDetails.rpps
      : '',
    sexe: patientFile.gender ? String(patientFile.gender) : undefined,
  };
};
