import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { RoleManager, Translation } from '../../../components';
import { urlSchema } from '../../../utils';
import { ListWrapper } from '../../../themes/jupiter/elements';

const PatientNhiListItem = ({ data }) => {
  return (
    <div className='container-fluid'>
      <div className='row align-items-center'>
        <div className='col-lg-2'>
          <small>{data.birthFirstName}</small>
        </div>
        <div className='col-lg-2'>
          <small>{data.birthLastName}</small>
        </div>
        <div className='col-lg-2 d-none d-lg-block'>
          <small>{data.usedFirstName}</small>
        </div>
        <div className='col-lg-2 d-none d-lg-block'>
          <small>{data.usedLastName}</small>
        </div>
        <div className='col-lg-2'>
          <small>{moment(data.birthDate).format('DD/MM/YYYY')}</small>
        </div>
        <div className='col-lg-1'>
          <small>
            <Translation id={`Shared.Gender.${data.birthGender}`} />
          </small>
        </div>
        <div className='col-lg-1'>
          <ListWrapper.Actions>
            <RoleManager entity='Patients' right='CRUD'>
              <Link
                className='btn'
                data-tracking='PatientsNhiListBtnEdit'
                to={`${urlSchema.patients.updateNHI}/${data.patientId}`}
              >
                <i className='uil uil-pen uil--md' aria-hidden />
              </Link>
            </RoleManager>
            <RoleManager entity='Patients' right='Reader'>
              <Link
                className='btn'
                data-tracking='PatientsNhiListBtnRead'
                to={`${urlSchema.patients.readNHI}/${data.userId}`}
              >
                <i className='uil uil-eye uil--md' aria-hidden />
              </Link>
            </RoleManager>
          </ListWrapper.Actions>
        </div>
      </div>
    </div>
  );
};

PatientNhiListItem.defaultProps = { data: undefined };

PatientNhiListItem.propTypes = {
  data: PropTypes.shape({
    birthDate: PropTypes.instanceOf().isRequired,
    birthFirstName: PropTypes.string.isRequired,
    birthGender: PropTypes.string.isRequired,
    birthLastName: PropTypes.string.isRequired,
    patientId: PropTypes.string.isRequired,
    usedFirstName: PropTypes.string.isRequired,
    usedLastName: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
  }),
};

export default PatientNhiListItem;
