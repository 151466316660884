import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Auth } from '@teladochealth/auth';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Ds, ToastContainer } from '@teladochealth/components';

import * as serviceWorker from 'serviceWorker';
import { LanguagesProvider } from './contexts';
import App from './App';
import { AuthService } from 'services';
import { oidcConfiguration } from './constants';

// Stylesheets
import 'react-intl-tel-input/dist/main.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.scss';
import './th.scss';
import JupiterTheme from './themes/jupiter/JupiterTheme';

// Medias
import CoverDesktop from './assets/images/cover_auth/cover_desktop.webp';
import CoverMobile from './assets/images/cover_auth/cover_mobile.webp';
import LogoDesktop from './assets/images/MD_logo_full.svg';
import LogoMobile from './assets/images/MD_logo_bubble.svg';

// Create a client
const queryClient = new QueryClient();
const container = document.getElementById('root');
const root = createRoot(container);

const AuthOptions = {
  ...oidcConfiguration,
  headerRedirectUrl: window.__env.REACT_APP_SIGNUP_URL,
  logoUrls: {
    desktop: LogoDesktop,
    mobile: LogoMobile,
  },
  coverImage: {
    mobileUrl: CoverMobile,
    desktopUrl: CoverDesktop,
  },
  LanguageButton: () => null,
  isSignupAllowed: false,
};

root.render(
  <Router getUserConfirmation={() => {}}>
    <ThemeProvider theme={JupiterTheme}>
      <LanguagesProvider>
        <Auth service={AuthService} options={AuthOptions}>
          <QueryClientProvider client={queryClient}>
            <App />
            <Ds>
              <ToastContainer />
            </Ds>
          </QueryClientProvider>
        </Auth>
      </LanguagesProvider>
    </ThemeProvider>
  </Router>,
);

serviceWorker.unregister();
