import styled from 'styled-components';

const TokenWrapper = styled.div`
  ${({ theme }) => `
    align-items: center;
    display: flex;
  `}
`;

const TokenCode = styled.code`
  ${({ theme }) => `
    display: inline-block;
    max-width: 480px;
    overflow: hidden;
    padding-right: ${theme.space(3)};
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
`;

TokenWrapper.Code = TokenCode;

export default TokenWrapper;
