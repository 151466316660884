import { defineMessages } from 'react-intl';

export default defineMessages({
  All: { id: 'Shared.All', defaultMessage: 'Error.Shared.All' },
  ButtonCreate: {
    id: 'Buttons.Create',
    defaultMessage: 'Error.Buttons.Create',
    description: 'Button to create a new partner',
  },
  ButtonUpdate: {
    id: 'Buttons.Update',
    defaultMessage: 'Error.Buttons.Update',
    description: 'Button to update a partner',
  },
  ButtonRead: {
    id: 'Buttons.Read',
    defaultMessage: 'Error.Buttons.Read',
    description: 'Button to read a partner',
  },
  ButtonResearch: {
    id: 'Buttons.Research',
    defaultMessage: 'Error.Buttons.Research',
    description: 'Button to research partners',
  },
  IsEligible: {
    id: 'Shared.IsEligible',
    defaultMessage: 'Error.Shared.IsEligible',
  },
  IsNotEligible: {
    id: 'Shared.IsNotEligible',
    defaultMessage: 'Error.Shared.IsNotEligible',
  },
  PartnerClient: {
    id: 'Partners.Client',
    defaultMessage: 'Error.Partners.Client',
  },
  PartnerName: {
    id: 'Partners.Name',
    defaultMessage: 'Error.Partners.Name',
  },
  PartnerEligibilityKey: {
    id: 'Partners.EligibilityKey',
    defaultMessage: 'Error.Partners.EligibilityKey',
  },
  PartnerKeywords: {
    id: 'Partners.Keywords',
    defaultMessage: 'Error.Partners.Keywords',
  },
  Title: {
    id: 'Menu.EligiblePartners',
    defaultMessage: 'Error.Menu.EligiblePartners',
  },
  Website: { id: 'Shared.Website', defaultMessage: 'Error.Shared.Website' },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
    description: 'Toast title in case of Error',
  },
  FailBody: {
    id: 'Message.Api.Error',
    defaultMessage: 'Error.Message.Api.Error',
    description: 'Toast body in case of error',
  },
});
