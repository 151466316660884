import * as yup from 'yup';

function createLanguagesForm(languages) {
  const langs = {};
  languages.forEach((lang) => {
    langs[lang.code] = yup.object().shape({
      name: yup.string().required('Model.FieldRequired'),
      description: yup.string().required('Model.FieldRequired'),
      cta: yup.string().required('Model.FieldRequired'),
    });
  });
  return langs;
}

const getValidationSchema = (validLanguages) => {
  return yup.object().shape({
    logoFile: yup.string().when('refLogo', {
      is: undefined,
      then: yup.string().required('Components.FormInputLogo.Required'),
      otherwise: yup.string().notRequired(),
    }),
    code: yup
      .string()
      .required('Model.FieldRequired')
      .matches(/^[A-Z]+(?:_[A-Z]+)*$/, 'Offers.Create.CodeRegexError')
      .max(50, 'Offers.Create.CodeMaxLength'),
    refLogo: yup.string().notRequired(),
    refSpecialty: yup.string().required('Model.FieldRequired'),
    ...createLanguagesForm(validLanguages),
  });
};

export default getValidationSchema;
