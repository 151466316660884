import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { useDebouncedCallback } from 'use-debounce';

import { AutocompleteWrapper, Suggestion } from './elements';

const getSuggestionValue = (suggestion) => suggestion;

const FormInputAutosuggest = ({
  fetchRequest,
  initialValue,
  labelKey,
  onSuggestionSelected,
  onSuggestionsFetchRequested,
  inputProperties,
  ...otherProperties
}) => {
  const [currentValue, setCurrentValue] = useState(initialValue);
  const [suggestions, setSuggestions] = useState([]);
  const [hasSuggestionsLoaded, setHasSuggestionsLoaded] = useState(true);
  const minCharacters = 2;

  const debouncedCallback = useDebouncedCallback(async (newValue) => {
    setHasSuggestionsLoaded(false);
    try {
      const newSuggestions = await fetchRequest(newValue);
      setSuggestions(newSuggestions);
    } catch (error) {
      console.error(error);
      setSuggestions([]);
    }
    setHasSuggestionsLoaded(true);
  }, 350);

  const getValue = (value) => {
    if (typeof value === 'object') {
      return value[labelKey];
    }
    return value;
  };

  const shouldRenderSuggestions = (value) => {
    return getValue(value).trim().length > minCharacters;
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onChange = (event, { newValue }) => {
    setCurrentValue(getValue(newValue));
    setHasSuggestionsLoaded(true);
  };

  const onKeyUp = (e) => {
    if (e.keyCode === 'enter') {
      setCurrentValue(e.target.value);
      onSuggestionSelected('none');
    }
  };

  const inputProps = {
    onChange,
    value: currentValue,
    onKeyUp,
    ...inputProperties,
  };

  const renderSuggestion = (suggestion) => (
    <Suggestion selected={currentValue === suggestion[labelKey]}>
      {suggestion[labelKey]}
    </Suggestion>
  );

  const renderInputComponent = (properties) => {
    return (
      <AutocompleteWrapper>
        {!hasSuggestionsLoaded && (
          <AutocompleteWrapper.Spinner
            color='primary'
            style={{ height: '1rem', width: '1rem' }}
          />
        )}
        <AutocompleteWrapper.Input {...properties} id={inputProperties.name} />
      </AutocompleteWrapper>
    );
  };

  return (
    <Autosuggest
      {...{ getSuggestionValue }}
      {...{ onSuggestionsFetchRequested }}
      {...{ shouldRenderSuggestions }}
      {...{ onSuggestionsClearRequested }}
      {...{ suggestions }}
      {...{ inputProps }}
      {...{ renderInputComponent }}
      {...{ renderSuggestion }}
      {...otherProperties}
      onSuggestionsFetchRequested={({ value }) => {
        onSuggestionsFetchRequested();
        debouncedCallback(value);
      }}
      onSuggestionSelected={(event, { suggestion }) => {
        event.preventDefault();
        setCurrentValue(suggestion[labelKey]);
        onSuggestionSelected(suggestion);
      }}
    />
  );
};

FormInputAutosuggest.defaultProps = {
  initialValue: '',
  onSuggestionsFetchRequested: () => {},
};

FormInputAutosuggest.propTypes = {
  fetchRequest: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
  labelKey: PropTypes.string.isRequired,
  onSuggestionsFetchRequested: PropTypes.func,
  onSuggestionSelected: PropTypes.func.isRequired,
};

export default FormInputAutosuggest;
