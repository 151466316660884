import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { PageHeader, RoleManager, LinksList, Spinner } from 'components';
import { service } from 'services';
import { api, sendToast, urlSchema } from 'utils';
import { ToastTypes } from '../../../constants';
import messages from './messages';

const List = () => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [applications, setApplications] = useState(undefined);

  const getResults = (callback) => {
    setIsLoading(true);
    service
      .get(api.manager.getApplications)
      .then(({ data }) => {
        setApplications(data);
        setIsLoading(false);
        if (typeof callback === 'function') callback();
      })
      .catch(() => {
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.FailTitle} />,
          body: <FormattedMessage {...messages.FailBody} />,
        });
      });
  };

  useEffect(() => {
    getResults();
  }, []);

  return (
    <>
      <PageHeader>
        <>
          <FormattedMessage {...messages.Title} tagName='h1' />
          <RoleManager entity='Applications' right='CRUD'>
            <Link
              to={urlSchema.applications.create}
              className='btn btn-primary btm-sm'
            >
              <FormattedMessage {...messages.ButtonCreateApplication} />
            </Link>
          </RoleManager>
        </>
      </PageHeader>
      <section className='section'>
        <div className='section__body'>
          <div className='research-table__header'>
            <Container fluid>
              <Row>
                <Col sm='5' md='6' lg='4'>
                  <FormattedMessage
                    tagName='h5'
                    {...messages.TitleColumnCode}
                  />
                </Col>
                <Col lg='4' className='d-none d-md-none d-lg-block'>
                  <FormattedMessage tagName='h5' {...messages.TitleColumnUrl} />
                </Col>
                <Col md='auto' className='d-none d-sm-block'>
                  <FormattedMessage
                    tagName='h5'
                    {...messages.TitleColumnProfiles}
                  />
                </Col>
              </Row>
            </Container>
          </div>
          {isLoading ? (
            <Spinner size='lg' />
          ) : (
            <ul className='research-table__results'>
              {applications.map((item) => {
                return (
                  <li key={item.idApplication}>
                    <Container fluid>
                      <Row className='align-items-center' xs='2' sm='3' md='4'>
                        <Col sm='5' md='6' lg='4'>
                          <h5 className='mb-0'>{item.code}</h5>
                          <p className='small m-0 text-secondary'>
                            {item.label}
                          </p>
                        </Col>
                        <Col lg='4' className='d-none d-md-none d-lg-block'>
                          <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href={`${item.url}`}
                          >
                            <small>{item.url}</small>
                          </a>
                        </Col>
                        <Col md='3' className='d-none d-sm-block d-lg-block'>
                          <LinksList
                            areInnerLinks
                            color='warning'
                            data={item.applicationsProfiles}
                            idKey={(el) => el.refProfile}
                            labelKey={(el) => el.profileCode}
                            limit={2}
                            urlBase={urlSchema.profiles.read}
                          />
                        </Col>
                        <Col md='1' className='ml-auto'>
                          <div className='research-table__actions'>
                            <RoleManager entity='Applications' right='CRUD'>
                              <Link
                                title={intl.formatMessage({
                                  ...messages.ButtonEdit,
                                })}
                                to={`${urlSchema.applications.update}/${item.idApplication}`}
                              >
                                <i aria-hidden className='uil uil-pen' />
                              </Link>
                            </RoleManager>
                            <RoleManager entity='Applications' right='CRUD'>
                              <Link
                                aria-label={intl.formatMessage({
                                  ...messages.ButtonRead,
                                })}
                                title={intl.formatMessage({
                                  ...messages.ButtonRead,
                                })}
                                to={`${urlSchema.applications.read}/${item.idApplication}`}
                              >
                                <i aria-hidden className='uil uil-eye' />
                              </Link>
                            </RoleManager>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </section>
    </>
  );
};

export default List;
