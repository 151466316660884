import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import IntlTelInput from 'react-intl-tel-input';
import { FormGroup } from 'reactstrap';
import FormLabel from '../FormLabel';
import FormInputFeedback from '../FormInputFeedback';
import 'react-intl-tel-input/dist/main.css';

const FormIntlTelInput = ({
  customPlaceholder,
  id,
  labelId,
  locale,
  name,
  required,
}) => {
  const rawMobilePhoneInputName = 'rawMobilePhoneInputName';

  return (
    <Field name={name}>
      {({
        form: { errors, touched, setFieldTouched, setFieldValue },
        field,
      }) => {
        const formatPhoneNumberOutput = (
          isValid,
          currentNumber,
          properties,
          formattedNumber,
        ) => {
          if (isValid) {
            const validNumber = formattedNumber.replace(/(\s|-)/g, '');
            return validNumber;
          }
          return currentNumber;
        };

        return (
          <FormGroup>
            {labelId && (
              <FormLabel required={required} for={id}>
                <FormattedMessage
                  id={labelId}
                  defaultMessage={`Error.${labelId}`}
                />
              </FormLabel>
            )}
            <IntlTelInput
              placeholder={customPlaceholder}
              required={required}
              defaultCountry={locale}
              defaultValue={!field.value ? '' : field.value}
              fieldId={id}
              fieldName={rawMobilePhoneInputName}
              inputClassName={classNames(
                {
                  'is-invalid': Boolean(
                    touched[field.name] && errors[field.name],
                  ),
                },
                'form-control',
              )}
              onPhoneNumberBlur={() => {
                setFieldTouched(field.name, true);
              }}
              onPhoneNumberChange={(...args) =>
                setFieldValue(field.name, formatPhoneNumberOutput(...args))
              }
              separateDialCode
              preferredCountries={['fr']}
            />
            <FormInputFeedback name={field.name} />
          </FormGroup>
        );
      }}
    </Field>
  );
};

FormIntlTelInput.defaultProps = {
  customPlaceholder: undefined,
  labelId: undefined,
  required: false,
  locale: 'fr',
};

FormIntlTelInput.propTypes = {
  customPlaceholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  labelId: PropTypes.string,
  locale: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default FormIntlTelInput;
