import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Field } from 'formik';
import { useIntl } from 'react-intl';

import { Checkbox, FormInputFeedback, FormInput, Spinner } from 'components';
import { api, useFetch } from 'utils';
import { Methods } from '../../constants';
import messages from './messages';

const Profile = ({ method }) => {
  const { formatMessage } = useIntl();
  const [organizationConfigs, areOrganizationConfigsLoading] = useFetch(
    api.organizationConfigs.getListItems,
  );

  return (
    <>
      <FormInput
        id='code'
        label={formatMessage({ ...messages.Code })}
        name='code'
        required
        type='text'
      />
      <FormInput
        id='label'
        label={formatMessage({ ...messages.Label })}
        name='label'
        required
        type='text'
      />
      <FormInput
        id='ranking'
        label={formatMessage({ ...messages.Ranking })}
        min='0'
        name='ranking'
        type='number'
      />
      {areOrganizationConfigsLoading ? (
        <Spinner color='primary' />
      ) : (
        <div className='form-group'>
          <label htmlFor='ref-organization-config'>
            {formatMessage({ ...messages.RefOrganizationConfig })}
          </label>
          <Field
            className='form-control'
            id='ref-organization-config'
            name='refOrganizationConfig'
            disabled={method === Methods.PUT}
            as='select'
          >
            <option value=''>-</option>
            {organizationConfigs.map((el) => (
              <option key={el.id} value={el.id}>
                {`${el.label} (${el.code})`}
              </option>
            ))}
          </Field>
          <ErrorMessage name='refOrganizationConfig'>
            {(msg) => <p className='text-danger small m-0'>{msg}</p>}
          </ErrorMessage>
        </div>
      )}
      <div className='mb-3'>
        <Field name='strongAuth'>
          {({ field, form }) => (
            <Checkbox
              {...field}
              checked={form.values.strongAuth}
              id='strong-auth'
            >
              {formatMessage({ ...messages.StrongAuth })}
            </Checkbox>
          )}
        </Field>
        <FormInputFeedback name='strongAuth' />
      </div>
    </>
  );
};

Profile.defaultProps = { method: Methods.POST };

Profile.propTypes = { method: PropTypes.instanceOf(Methods) };

export default Profile;
