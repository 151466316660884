import * as yup from 'yup';

export default yup.object().shape({
  code: yup.string().required('Model.FieldRequired'),
  label: yup.string().required('Model.FieldRequired'),
  url: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url!',
    )
    .required('Model.FieldRequired'),
});
