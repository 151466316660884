import { defineMessages } from 'react-intl';

export default defineMessages({
  Title: {
    id: 'Partners.Update.Title',
    defaultMessage: 'Error.Partners.Update.Title',
    description: 'Title of partner edition page',
  },
  actionSuccess: {
    id: 'Message.Success',
    defaultMessage: 'Error.Message.Success',
    description: 'Toast title in case of success',
  },
  actionSuccessResponse: {
    id: 'Partners.Update.ActionSuccessResponse',
    defaultMessage: 'Error.Partners.Update.ActionSuccessResponse',
    description: 'Toast body in case of success',
  },
  actionError: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
    description: 'Toast title in case of Error',
  },
  actionErrorResponse: {
    id: 'Partners.Update.ActionErrorResponse',
    defaultMessage: 'Error.Partners.Update.ActionErrorResponse',
    description: 'Toast body in case of error',
  },
});
