import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonCreate: {
    id: 'Buttons.Create',
    defaultMessage: 'Error.Buttons.Create',
  },
  ButtonEdit: {
    id: 'Buttons.Edit',
    defaultMessage: 'Error.Buttons.Edit',
  },
  ButtonRead: {
    id: 'Buttons.Read',
    defaultMessage: 'Error.Buttons.Read',
  },
  Name: {
    id: 'Model.WebServiceConfiguration.Name',
    defaultMessage: 'Error.Model.WebServiceConfiguration.Name',
  },
  NoElement: {
    id: 'Shared.NoElement',
    defaultMessage: 'Error.Shared.NoElement',
  },
  Placeholder: {
    id: 'Screens.WebServicesConfiguration.Placeholder',
    defaultMessage: 'Error.Screens.WebServicesConfiguration.Placeholder',
  },
  Title: {
    id: 'Menu.WebservicesConfig',
    defaultMessage: 'Error.Menu.WebservicesConfig',
  },
});
