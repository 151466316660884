import React from 'react';
import PropTypes from 'prop-types';

const SectionBody = ({ children, ...props }) => (
  <div className='section__body' {...props}>
    {children}
  </div>
);

SectionBody.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SectionBody;
