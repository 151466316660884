import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Form } from 'formik';
import { historyShape } from 'react-router-props';

import { Button, ApiError, PageHeader } from 'components';
import { Admin } from 'models';
import { service } from 'services';
import { api, sendToast, urlSchema } from 'utils';
import validationSchema from 'models/Admin/validationSchema';
import Profiles from './Profiles';
import initialValues from './initialValues';
import messages from './messages';
import { ToastTypes } from '../../../constants';

const Create = ({ history }) => {
  const { formatMessage } = useIntl();
  const onSubmit = (values, { setSubmitting }) => {
    const formattedValues = values;
    formattedValues.selectedProfiles = values.selectedProfiles;
    // Attention : le champ CodeCompany est obligatoire (pas comme dans Swagger)
    service
      .post(api.administrators.post, formattedValues)
      .then(({ data }) => {
        if (data.succeeded === false) {
          sendToast(ToastTypes.error, {
            body: <FormattedMessage {...messages.ToastFail} />,
          });
          setSubmitting(false);
          return;
        }
        sendToast(ToastTypes.success, {
          body: <FormattedMessage {...messages.ToastSuccess} />,
        });
        history.push(urlSchema.administrators.list);
      })
      .catch((error) => {
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.ToastFail} />,
          body: <ApiError {...{ error }} />,
        });
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <FormattedMessage {...messages.Title} tagName='h1' />
      </PageHeader>
      <section className='section'>
        <div className='section__body'>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema(formatMessage)}
          >
            {(formikProps) => (
              <Form>
                <div className='row'>
                  <div className='col-lg-6'>
                    <Admin />
                  </div>
                  <div className='col-lg-6'>
                    <Profiles />
                  </div>
                </div>
                <Button
                  label='Create'
                  color='primary'
                  size='lg'
                  isProcessing={formikProps.isSubmitting}
                  type='submit'
                />
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </>
  );
};

Create.propTypes = { history: historyShape.isRequired };

export default Create;
