import React from 'react';
import { Switch } from 'react-router-dom';

import { ProtectedRoute } from 'components';
import { urlSchema } from 'utils';

import Create from './Create';
import List from './List';
import Read from './Read';
import Update from './Update';

const routes = [
  {
    path: urlSchema.roles.create,
    component: Create,
    right: 'CRUD',
  },
  {
    path: `${urlSchema.roles.read}/:roleId`,
    component: Read,
    right: 'Reader',
  },
  {
    path: `${urlSchema.roles.update}/:roleId`,
    component: Update,
    right: 'CRUD',
  },
  { path: '/', component: List, right: 'Reader' },
];

const Roles = () => {
  return (
    <Switch>
      {routes.map((item) => (
        <ProtectedRoute entity='Roles' key={item.path} {...item} />
      ))}
    </Switch>
  );
};

export default Roles;
