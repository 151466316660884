import React from 'react';
import { Col, Row } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import {
  PageHeader,
  Section,
  SectionBody,
  Spinner,
  RoleManager,
} from 'components';
import { api, urlSchema, useFetch } from 'utils';
import messages from './messages';

const Read = () => {
  const { idFileImportClient } = useParams();
  const [fileImportClients, FileImportClientsLoading] = useFetch(
    api.admin.getFileImportClientById(idFileImportClient),
  );

  return (
    <>
      <PageHeader>
        <FormattedMessage {...messages.Title} tagName='h1' />
        <RoleManager entity='FileImportClients' right='Reader'>
          <Link
            className='btn btn-primary'
            to={`${urlSchema.fileImportClients.edit}/${fileImportClients.idFileImportClient}`}
          >
            <FormattedMessage {...messages.Edit} />
          </Link>
        </RoleManager>
      </PageHeader>
      {FileImportClientsLoading ? (
        <Spinner size='lg' />
      ) : (
        <Col md='6'>
          <Section>
            <SectionBody>
              <div className='model'>
                <Row>
                  <Col md='4'>
                    <div className='model__property'>
                      <FormattedMessage {...messages.Partner} tagName='h5' />
                      <p className='model__value'>
                        {fileImportClients.codeClient}
                      </p>
                    </div>
                  </Col>
                  <Col md='8'>
                    <div className='model__property'>
                      <FormattedMessage {...messages.Entity} tagName='h5' />
                      <p className='model__value'>
                        {fileImportClients.codeEntity}
                      </p>
                    </div>
                  </Col>
                  <Col md='4'>
                    <div className='model__property'>
                      <FormattedMessage {...messages.Activated} tagName='h5' />
                      {fileImportClients.status === true ? (
                        <FormattedMessage {...messages.Valid} />
                      ) : (
                        <FormattedMessage {...messages.Invalid} />
                      )}
                    </div>
                  </Col>
                  <Col md='8'>
                    <div className='model__property'>
                      <FormattedMessage
                        {...messages.AssociatedProduct}
                        tagName='h5'
                      />
                      <p className='model__value'>
                        {fileImportClients.refProduct?.label}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </SectionBody>
          </Section>
        </Col>
      )}
    </>
  );
};

export default Read;
