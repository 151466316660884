import React from 'react';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams, Link } from 'react-router-dom';
import { Badge, Col, Row } from 'reactstrap';
import qs from 'qs';

import {
  PageHeader,
  Section,
  SectionBody,
  SectionHeader,
  Spinner,
  LogoItem,
  RoleManager,
} from 'components';
import { api, urlSchema, useFetch } from 'utils';
import messages from './messages';

const Read = () => {
  const { formatMessage } = useIntl();
  const { idCustomer } = useParams();
  const [customer, isCustomerLoading] = useFetch(
    api.clients.getDetails(idCustomer),
  );
  const [customerProducts, isCustumerProductsLoading] = useFetch(
    api.clients.getProducts(idCustomer),
  );

  return (
    <>
      <PageHeader>
        <FormattedMessage
          {...messages.DetailsOf}
          values={{ customerLabel: customer.label || '' }}
          tagName='h1'
        />
        <div>
          <RoleManager entity='Clients' right='CRUD'>
            <Link
              className='btn btn-primary'
              to={`${urlSchema.clients.update}/${customer.idClient}`}
            >
              <FormattedMessage {...messages.ButtonEdit} />
            </Link>
          </RoleManager>
          <RoleManager entity='Tags' right='CRUD'>
            <Link
              className='btn btn-primary ml-2'
              to={`${urlSchema.clients.manageProductTags}/${customer.idClient}`}
            >
              <FormattedMessage {...messages.ButtonManageProductTags} />
            </Link>
          </RoleManager>
        </div>
      </PageHeader>
      {isCustomerLoading ? (
        <Spinner size='lg' />
      ) : (
        <Row>
          <Col md='8'>
            <Section>
              <SectionBody>
                <div className='model'>
                  <LogoItem data={customer} />
                  <div className='model__actions'>
                    <Badge color={customer.valid ? 'success' : 'danger'}>
                      {customer.valid ? (
                        <FormattedMessage {...messages.Valid} />
                      ) : (
                        <FormattedMessage {...messages.Inactive} />
                      )}
                    </Badge>
                  </div>
                  <Row>
                    <Col md='4'>
                      <div className='model__property'>
                        <h3 className='model__title'>
                          <FormattedMessage {...messages.Code} />
                        </h3>
                        <p className='model__value'>{customer.code}</p>
                      </div>
                    </Col>
                    <Col md='4'>
                      <div className='model__property'>
                        <h3 className='model__title'>
                          <FormattedMessage {...messages.Label} />
                        </h3>
                        <p className='model__value'>{customer.label}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md='4'>
                      <div className='model__property'>
                        <h3 className='model__title'>
                          <FormattedMessage {...messages.CreatedAt} />
                        </h3>
                        <p className='model__value'>
                          <FormattedMessage
                            {...messages.CreationDate}
                            values={{
                              date: moment(customer.dateCreation).format('LL'),
                            }}
                          />
                        </p>
                      </div>
                    </Col>
                    <Col md='4'>
                      <div className='model__property'>
                        <h3 className='model__title'>
                          <FormattedMessage {...messages.Organization} />
                        </h3>
                        <p className='model__value'>
                          {customer.universeDisplayLabel}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md='4'>
                      <div className='model__property'>
                        <h3 className='model__title'>
                          <FormattedMessage {...messages.ClientSellsy} />
                        </h3>
                        <p className='model__value'>
                          {customer.labelClientSellsy
                            ? `${customer.labelClientSellsy} (${customer.refClientSellsy})`
                            : '-'}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </SectionBody>
            </Section>
          </Col>
          <Col md='4'>
            {isCustumerProductsLoading ? (
              <Spinner size='lg' />
            ) : (
              <Section>
                <SectionHeader>
                  <>
                    <FormattedMessage {...messages.Products} tagName='h2' />
                    <RoleManager entity='Products' right='CRUD'>
                      <Link
                        className='btn btn-outline-primary btn-sm ml-auto'
                        to={`${urlSchema.products.create}?${qs.stringify({
                          labelClientSellsy: customer.labelClientSellsy,
                          idClient: customer.idClient,
                          refClientSellsy: customer.refClientSellsy,
                        })}`}
                      >
                        {formatMessage({ ...messages.ButtonAdd })}
                      </Link>
                    </RoleManager>
                  </>
                </SectionHeader>
                <SectionBody>
                  {customerProducts.length > 0 ? (
                    customerProducts.map((product) => (
                      <Link
                        className='btn btn-outline-warning btn-sm mr-2 mb-2'
                        to={`${urlSchema.products.read}/${product.idProduct}`}
                      >
                        {product.label}
                      </Link>
                    ))
                  ) : (
                    <p className='text-secondary m-0'>
                      {formatMessage({ ...messages.NoElement })}
                    </p>
                  )}
                </SectionBody>
              </Section>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default Read;
