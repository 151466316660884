import React from 'react';
import { Field, FieldArray, Formik, Form } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input } from 'reactstrap';
import cn from 'classnames';

import { Button, PageHeader, Section, SectionBody, Spinner } from 'components';
import { service } from 'services';
import { api, sendToast, urlSchema, useFetch } from 'utils';
import { ButtonsWrapper } from 'themes/jupiter/elements';
import { ToastTypes } from '../../../constants';
import messages from './messages';

const EditSpecialties = ({ history, match }) => {
  const intl = useIntl();
  const { params } = match;
  const [product, isProductLoading] = useFetch(
    api.manager.getProductDetails(params.productId),
  );
  const [specialties, areSpecialtiesLoading] = useFetch(api.specialties.get, {
    isValid: true,
  });

  const onSubmit = (values, { setSubmitting }) => {
    service
      .put(api.products.editSpecialties(params.productId), values)
      .then(({ data }) => {
        if (data.succeeded === false) {
          sendToast(ToastTypes.error, {
            header: <FormattedMessage {...messages.FailTitle} />,
            body: (
              <>
                <FormattedMessage
                  {...messages.FailBody}
                  values={{ label: product.label }}
                  tagName='p'
                />
                {data.errorSet.map((error) => (
                  <p>
                    {error.code}: {error.description}
                  </p>
                ))}
              </>
            ),
          });
          return;
        }
        sendToast(ToastTypes.success, {
          header: <FormattedMessage {...messages.SuccessTitle} />,
          body: (
            <FormattedMessage
              {...messages.SuccessBody}
              values={{ label: product.label }}
            />
          ),
        });
        history.push(urlSchema.products.list);
      })
      .catch(({ data }) => {
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.FailTitle} />,
          body: (
            <>
              <FormattedMessage
                {...messages.FailBody}
                values={{ label: product.label }}
                tagName='p'
              />
              {data.errorSet.map((error) => (
                <p>
                  {error.code}: {error.description}
                </p>
              ))}
            </>
          ),
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  if (isProductLoading || areSpecialtiesLoading)
    return <Spinner color='primary' size='lg' />;

  return (
    <>
      <PageHeader>
        <FormattedMessage
          tagName='h1'
          {...messages.Title}
          values={{ label: product.code }}
        />
      </PageHeader>
      <Section>
        <SectionBody>
          <Formik
            initialValues={{
              idProduct: params.productId,
              searchString: '',
              selectedSpecialties:
                product.productsSpecialties.length > 0
                  ? product.productsSpecialties.flatMap((el) => el.refSpecialty)
                  : [],
            }}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, values }) => (
              <Form>
                <Field name='searchString'>
                  {({ field }) => (
                    <Input
                      className='mb-3'
                      placeholder={intl.formatMessage({
                        ...messages.Placeholder,
                      })}
                      size='lg'
                      type='text'
                      {...field}
                    />
                  )}
                </Field>
                <FieldArray
                  name='selectedSpecialties'
                  render={({ push, remove }) => (
                    <>
                      {specialties.map((specialty) => {
                        if (
                          specialty.label
                            .toLowerCase()
                            .includes(values.searchString.toLowerCase())
                        )
                          return (
                            <button
                              className={cn('combined-btn', {
                                'combined-btn--success':
                                  values.selectedSpecialties.findIndex(
                                    (el) => el === specialty.idSpecialty,
                                  ) >= 0,
                              })}
                              key={specialty.idSpecialty}
                              onClick={() => {
                                const index =
                                  values.selectedSpecialties.findIndex(
                                    (el) => el === specialty.idSpecialty,
                                  );
                                if (index >= 0) {
                                  remove(index);
                                } else push(specialty.idSpecialty);
                              }}
                              type='button'
                            >
                              <div className='combined-btn__container'>
                                <span>{specialty.label}</span>
                                {values.selectedSpecialties.findIndex(
                                  (el) => el === specialty.idSpecialty,
                                ) >= 0 ? (
                                  <i aria-hidden className='uil uil-check' />
                                ) : (
                                  <i aria-hidden className='uil uil-plus' />
                                )}
                              </div>
                            </button>
                          );

                        return null;
                      })}
                    </>
                  )}
                />
                <ButtonsWrapper bottom>
                  <Button
                    color='primary'
                    disabled={isSubmitting}
                    isProcessing={isSubmitting}
                    label='Validate'
                    size='lg'
                    type='submit'
                  />
                </ButtonsWrapper>
              </Form>
            )}
          </Formik>
        </SectionBody>
      </Section>
    </>
  );
};

export default EditSpecialties;
