import * as yup from 'yup';

const fieldRequiredKey = {
  id: 'Shared.FieldRequired',
  defaultMessage: 'Error.Shared.FieldRequired',
};

const validationSchema = (formatMessage) =>
  yup.object().shape({
    height: yup
      .number()
      .min(
        1,
        formatMessage({
          id: 'Screens.Dialogues.Create.UpdatePatientFile.Height.Error',
          defaultMessage:
            'Error.Screens.Dialogues.Create.UpdatePatientFile.Height.Error',
        }),
      )
      .max(
        300,
        formatMessage({
          id: 'Screens.Dialogues.Create.UpdatePatientFile.Height.Error',
          defaultMessage:
            'Error.Screens.Dialogues.Create.UpdatePatientFile.Height.Error',
        }),
      )
      .required(
        formatMessage({
          id: 'Screens.Dialogues.Create.UpdatePatientFile.Height.Error',
          defaultMessage:
            'Error.Screens.Dialogues.Create.UpdatePatientFile.Height.Error',
        }),
      )
      .typeError(
        formatMessage({
          id: 'Screens.Dialogues.Create.UpdatePatientFile.Height.Error.Type',
          defaultMessage:
            'Error.Screens.Dialogues.Create.UpdatePatientFile.Height.Error.Type',
        }),
      ),
    weight: yup
      .number()
      .min(
        1,
        formatMessage({
          id: 'Screens.Dialogues.Create.UpdatePatientFile.Weight.Error',
          defaultMessage:
            'Error.Screens.Dialogues.Create.UpdatePatientFile.Weight.Error.Type',
        }),
      )
      .max(
        500,
        formatMessage({
          id: 'Screens.Dialogues.Create.UpdatePatientFile.Weight.Error',
          defaultMessage:
            'Error.Screens.Dialogues.Create.UpdatePatientFile.Weight.Error.Type',
        }),
      )
      .required(
        formatMessage({
          id: 'Screens.Dialogues.Create.UpdatePatientFile.Weight.Error',
          defaultMessage:
            'Error.Screens.Dialogues.Create.UpdatePatientFile.Weight.Error',
        }),
      )
      .typeError(
        formatMessage({
          id: 'Screens.Dialogues.Create.UpdatePatientFile.Weight.Error',
          defaultMessage:
            'Error.Screens.Dialogues.Create.UpdatePatientFile.Weight.Error.Type',
        }),
      ),
    sexe: yup.string().required(formatMessage(fieldRequiredKey)),
    hasMedicalHistory: yup
      .boolean()
      .nullable()
      .required(formatMessage(fieldRequiredKey)),
    medicalHistory: yup.string().when('hasMedicalHistory', {
      is: true,
      then: yup
        .string()
        .nullable()
        .required(
          formatMessage({
            id: 'Screens.Dialogues.Create.UpdatePatientFile.MedicalHistory.Error',
            defaultMessage:
              'Error.Screens.Dialogues.Create.UpdatePatientFile.MedicalHistory.Error',
          }),
        ),
      otherwise: yup.string().nullable(),
    }),
    hasAdditionalInformations: yup
      .boolean()
      .nullable()
      .required(formatMessage(fieldRequiredKey)),
    additionalInformations: yup.string().when('hasAdditionalInformations', {
      is: true,
      then: yup
        .string()
        .nullable()
        .required(
          formatMessage({
            id: 'Screens.Dialogues.Create.UpdatePatientFile.AdditionalInformations.Error',
            defaultMessage:
              'Error.Screens.Dialogues.Create.UpdatePatientFile.AdditionalInformations.Error',
          }),
        ),
      otherwise: yup.string().nullable(),
    }),
    hasMedicatedAllergies: yup
      .boolean()
      .nullable()
      .required(formatMessage(fieldRequiredKey)),
    medicatedAllergies: yup.string().when('hasMedicatedAllergies', {
      is: true,
      then: yup
        .string()
        .nullable()
        .required(
          formatMessage({
            id: 'Screens.Dialogues.Create.UpdatePatientFile.MedicatedAllergies.Error',
            defaultMessage:
              'Error.Screens.Dialogues.Create.UpdatePatientFile.MedicatedAllergies.Error',
          }),
        ),
      otherwise: yup.string().nullable(),
    }),
    hasFamilyDoctor: yup
      .boolean()
      .nullable()
      .required(formatMessage(fieldRequiredKey)),
    rpps: yup.string().when('hasFamilyDoctor', {
      is: true,
      then: yup
        .string()
        .nullable()
        .required(
          formatMessage({
            id: 'Screens.Dialogues.Create.UpdatePatientFile.FamilyDoctor.Error',
            defaultMessage:
              'Error.Screens.Dialogues.Create.UpdatePatientFile.FamilyDoctor.Error',
          }),
        ),
      otherwise: yup.string().nullable(),
    }),
    familyHistory: yup
      .string()
      .max(
        4000,
        formatMessage(
          {
            id: 'Shared.Errors.MaxLengthCharacters',
            defaultMessage: 'Error.Shared.Errors.MaxLengthCharacters',
          },
          {
            maxLength: 4000,
          },
        ),
      )
      .nullable(),
    isSmoking: yup
      .boolean()
      .nullable()
      .required(formatMessage(fieldRequiredKey)),
    smoking: yup.number().when('isSmoking', {
      is: true,
      then: yup
        .number()
        .min(
          1,
          formatMessage({
            id: 'Screens.Dialogues.Create.UpdatePatientFile.Smoking.ErrorLength',
            defaultMessage:
              'Error.Screens.Dialogues.Create.UpdatePatientFile.Smoking.ErrorLength',
          }),
        )
        .max(
          100,
          formatMessage({
            id: 'Screens.Dialogues.Create.UpdatePatientFile.Smoking.ErrorLength',
            defaultMessage:
              'Error.Screens.Dialogues.Create.UpdatePatientFile.Smoking.ErrorLength',
          }),
        )
        .nullable()
        .required(
          formatMessage({
            id: 'Screens.Dialogues.Create.UpdatePatientFile.Smoking.Error',
            defaultMessage:
              'Error.Screens.Dialogues.Create.UpdatePatientFile.Smoking.Error',
          }),
        ),
      otherwise: yup.number().nullable(),
    }),
  });

export default validationSchema;
