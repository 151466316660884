import { FormInputAutosuggest, Translation } from 'components';
import { isNil } from 'lodash';
import { ErrorMessage, useFormikContext } from 'formik';
import moment from 'moment';
import { Form, BirthPlace } from './types';
import { getNhiBirthPlaces } from './getNhiFindBirthPlaces';

type BirthPlaceOption = BirthPlace & {
  displayLabel: string;
};

export const BirthPlaceAutocomplete = () => {
  const { errors, values, touched, setFieldValue } = useFormikContext<Form>();

  const handleSearch = (query: string) =>
    getNhiBirthPlaces({
      placeType: values.birthPlace.placeType,
      birthPlaceName: query,
      birthDate: moment(values.birthDate, 'DD/MM/YYYY').format('yyyy-MM-DD'),
      take: 20,
    });

  const handleSelect = (selectedValue: BirthPlaceOption) => {
    setFieldValue('birthPlace.inseeCode', selectedValue.inseeCode);
    setFieldValue('birthPlace.departmentCode', selectedValue.departmentCode);
    setFieldValue('birthPlace.name', selectedValue.name);
  };

  return (
    <div className='form-group'>
      <Translation id='Patients.Nhis.Update.Input.BirthPlace' tagName='label' />
      <FormInputAutosuggest
        name='label'
        labelKey='displayLabel'
        clearOnSelect
        fetchRequest={handleSearch}
        initialValue={
          isNil(values.birthPlace?.name)
            ? undefined
            : `${values.birthPlace.name} (${values.birthPlace.inseeCode})`
        }
        inputProperties={{
          invalid: touched?.birthPlace && errors?.birthPlace,
          name: 'familyDoctorAutocomplete',
        }}
        onSuggestionsFetchRequested={undefined}
        onSuggestionSelected={handleSelect}
        required
      />
      <p>
        <small className='text-danger'>
          <ErrorMessage name='birthPlace.inseeCode' />
        </small>
      </p>
    </div>
  );
};
