import { defineMessages } from 'react-intl';

import { generateTranslationsObject } from 'utils';

const prefix = 'Screens.VoucherGroups.';
const keys = [
  'add.expirationDate',
  'add.productTag',
  'add.refProduct',
  'add.successBody',
  'add.errorBody',
  'add.prefix',
  'add.refProduct',
  'add.informations.title',
  'add.voucherNumber',
  'add.selectAProduct',
  'add.expirationDate',
  'add.create',
  'create.title',
  'details.description',
  'edit.usage',
  'edit.usagePlural',
  'edit.unlimitedUsage',
  'edit.valid',
  'edit.title',
  'edit.edit',
  'edit.successTitle',
  'edit.successBody',
  'edit.errorTitle',
  'edit.errorBody',
  'details.title',
  'details.creationDate',
  'details.exportCsv',
  'voucherNumber',
  'title',
  'find',
  'col.name',
  'refProduct',
  'searchPlaceholder',
  'validation.numberOfVouchers',
  'validation.prefixe.required',
  'validation.prefixe.max',
  'validation.prefixe.min',
  'validation.dateExpiration',
  'validation.description',
];

export default defineMessages(generateTranslationsObject(prefix, keys, {}));
