import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classNames from 'classnames';

import TabsWrapper from './elements';

const FormResources = ({
  children,
  keyLabelNav,
  languagesArray,
  name,
  title,
}) => {
  const { errors, touched } = useFormikContext();
  const [activeTab, setActiveTab] = useState(0);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const isValid = (index) =>
    !getIn(errors, `${name}[${index}]`) && getIn(touched, `${name}[${index}]`);

  return (
    <TabsWrapper>
      {title && <p className='tabs-title'>{title}</p>}
      <Nav tabs>
        {languagesArray.map((language, index) => (
          <NavItem
            className={classNames({
              valid: isValid(index),
              invalid:
                getIn(errors, `${name}[${index}]`) &&
                getIn(touched, `${name}[${index}]`),
            })}
            active={activeTab === index}
            key={`nav-${language[keyLabelNav]}`}
            style={{
              maxWidth: `${100 / languagesArray.length}%`,
            }}
          >
            <NavLink onClick={() => toggle(index)}>
              <span>{language[keyLabelNav]}</span>
              <i
                aria-hidden
                className={`ml-2 uil uil-${
                  isValid(index)
                    ? 'check-circle'
                    : getIn(errors, `${name}[${index}]`) &&
                      getIn(touched, `${name}[${index}]`)
                    ? 'exclamation-triangle'
                    : 'question-circle'
                }`}
              />
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {languagesArray.map((language, index) => (
          <TabPane key={`tab-${language[keyLabelNav]}`} tabId={index}>
            {children(index)}
          </TabPane>
        ))}
      </TabContent>
    </TabsWrapper>
  );
};

FormResources.defaultProps = { title: null };

FormResources.propTypes = {
  children: PropTypes.func.isRequired,
  keyLabelNav: PropTypes.string.isRequired,
  languagesArray: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ).isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default FormResources;
