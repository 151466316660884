import React, { useEffect } from 'react';
import { runInAction, observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { FormattedMessage, useIntl } from 'react-intl';

import { urlSchema } from 'utils';
import {
  PageHeader,
  Section,
  SectionBody,
  Spinner,
  RoleManager,
} from 'components';
import { ListWrapper } from 'themes/jupiter/elements';
import messages from '../message';
import Store from '../store';

const urlParameters = observable({ params: { searchString: '' } });

const networkIsPending = observable.box(true);

const setWindowLocationSearch = () => {
  const nextURL = `${urlSchema.vouchersGroups}${
    Object.keys(urlParameters.params).length > 0
      ? `?${qs.stringify(urlParameters.params)}`
      : ''
  }`;
  window.history.pushState(null, null, nextURL);
};

// Update the list of voucher groups
const refreshVoucherGroupsList = () => {
  runInAction(() => {
    networkIsPending.set(true);
    Store.voucherGroups = [];
  });
  return Store.retrieveVoucherList(urlParameters.params).then((results) => {
    runInAction(() => {
      networkIsPending.set(false);
    });
    setWindowLocationSearch();
  });
};

// When the input text value is changing
const onSearchInputChange = (input) => {
  const { value } = input.target;
  runInAction(() => {
    urlParameters.params = { ...urlParameters.params, searchString: value };
  });
  const weGotNoResultsAndAnEmptySearchString =
    value.length === 0 && Store.voucherGroups.length === 0;
  weGotNoResultsAndAnEmptySearchString && refreshVoucherGroupsList();
};

// Observer Component - The voucher group list item
const VoucherGroupsItem = (props) => {
  const { idVoucherGroup, name, isLast } = props;
  return (
    <div className={`container-fluid py-3${isLast ? '' : ' border-bottom'}`}>
      <div className='row'>
        <div className='col-10'>{name}</div>
        <div className='col-2 text-right '>
          <Link
            className='list-action-anchor'
            to={`${urlSchema.vouchersGroupsDetails}/${idVoucherGroup}`}
          >
            <i className='uil uil-eye uil--md' />
          </Link>
        </div>
      </div>
    </div>
  );
};

const VoucherGroupsList = observer(() => {
  return (
    <Suspense size='sm'>
      <div className='voucherList'>
        {Store.voucherGroups.map((voucherGroup, index) => {
          const isLast = index === Store.voucherGroups.length - 1;
          return (
            <VoucherGroupsItem {...voucherGroup} key={index} {...{ isLast }} />
          );
        })}
      </div>
    </Suspense>
  );
});

// Component - Ternary wrapper
const Suspense = observer((props) =>
  Store.voucherGroups.length === 0 ? (
    networkIsPending.get() === true ? (
      <div className='py-3 container-fluid'>
        <Spinner color='primary' size={props.size} />
      </div>
    ) : (
      <div className='py-3 container-fluid'>Pas de résultats</div>
    )
  ) : (
    props.children
  ),
);

// Observer Component - The search input & his clear value button
const InputGroup = observer(() => {
  const intl = useIntl();
  const searchStringIsNotEmpty = urlParameters.searchString !== '';

  return (
    <div className='input-group'>
      <input
        type='text'
        className='form-control'
        value={urlParameters.searchString}
        onChange={onSearchInputChange}
        placeholder={intl.formatMessage({ ...messages.searchPlaceholder })}
      />
      {searchStringIsNotEmpty && (
        <div className='input-group-append'>
          <button
            className='btn btn-outline-primary'
            onClick={clearSearchString}
            type='button'
          >
            <i aria-hidden className='uil uil-times' />
          </button>
        </div>
      )}
    </div>
  );
});

// Clear the input text
const clearSearchString = () => {
  runInAction(() => {
    urlParameters.params = { ...urlParameters.params, searchString: '' };
    refreshVoucherGroupsList();
  });
};

const onFormSubmit = (form) => {
  form.preventDefault();
  refreshVoucherGroupsList();
};

const List = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  useEffect(() => {
    if (params.toString() !== '')
      runInAction(() => {
        urlParameters.params = qs.parse(params.toString(), {
          ignoreQueryPrefix: true,
        });
      });
    refreshVoucherGroupsList();
  }, []);

  return (
    <>
      <PageHeader>
        <h1>
          <FormattedMessage {...messages['title']} />
        </h1>
        <RoleManager entity='VoucherGroups' right='CRUD'>
          <Link
            to={urlSchema.vouchersGroupsCreate}
            className='btn btn-primary ml-3'
          >
            <FormattedMessage {...messages['add.create']} />
          </Link>
        </RoleManager>
      </PageHeader>
      <Section>
        <SectionBody>
          <ListWrapper>
            <section className='section mb-0 '>
              <div className='section__header px-0'>
                <form className='w-100' onSubmit={onFormSubmit}>
                  <div className='row'>
                    <div className='col'>
                      <InputGroup />
                    </div>
                    <div className='col-auto'>
                      <button className='btn btn-primary' type='submit'>
                        <FormattedMessage {...messages['find']} />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className='mt-3'>
                <div className='research-table__header'>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-10'>
                        <FormattedMessage {...messages['col.name']} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <VoucherGroupsList />
          </ListWrapper>
        </SectionBody>
      </Section>
    </>
  );
};

export default List;
