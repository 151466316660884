import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonDownload: {
    id: 'Screens.Customers.CreateCustomer.ButtonsDownload',
    defaultMessage: 'Error.Screens.Customers.CreateCustomer.ButtonsDownload',
  },
  Code: {
    id: 'Customers.Code',
    defaultMessage: 'Error.Customers.Code',
    description: 'Label code',
  },
  Label: {
    id: 'Customers.Label',
    defaultMessage: 'Error.Customers.Label',
    description: 'Label label',
  },
  ClientSellsy: {
    id: 'Customers.ClientSellsy',
    defaultMessage: 'Error.Customers.ClientSellsy',
    description: 'Label ClientSellsy',
  },
  EnterClientSellsy: {
    id: 'Screens.Customers.CreateCustomer.PlaceHolder',
    defaultMessage: 'Error.Screens.Customers.CreateCustomer.PlaceHolder',
  },
});
