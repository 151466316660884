import { defineMessages } from 'react-intl';

const prefix = 'Screens.IdentityVerifications.';

const keys = [
    'colTitle.send',
    'colTitle.status',
    'colTitle.patient',
    'title',
    'noResults',
    'modal.valid',
    'modal.errorDifferentName',
    'modal.errorInvalidID',
    'modal.errorNonReadableID',
    'modal.birthInformation',
    'modal.birthInformation.born',
    'find',
    'status.unknown',
    'status.errorNonReadableID',
    'status.errorDifferentName',
    'status.errorInvalidID',
    'status.pending',
    'status.valid',
    'status.notDone',
    'status.all',

];

const translations = {};

keys.map(key => {
    const obj = {};
    obj[key] = {
        id: `${prefix}${key}`,
        defaultMessage: `Error.${prefix}${key}`,
    }
    return obj;
}).map((e) => {
    translations[Object.keys(e)[0]] = Object.values(e)[0];
});

export default defineMessages(translations);