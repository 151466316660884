import React, { useState } from 'react';
import { Field, FieldArray, useFormikContext } from 'formik';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, Container, Spinner, FormGroup, Collapse } from 'reactstrap';
import { useFetch, api } from 'utils';
import cn from 'classnames';

import { Checkbox, FormInput, FormInputFeedback } from 'components';
import messages from '../../messages';

const FormPlateformCriteria = () => {
  const [isClientsListOpen, setClientsListOpen] = useState(false);
  const intl = useIntl();
  const { setFieldValue, values } = useFormikContext();
  const [clientList, isClientListLoading] = useFetch(api.clients.getListItems);
  clientList.sort((a, b) => a.label.localeCompare(b.label));

  const toggleAllClientsIds = (bool) => {
    let clientsId = [];
    if (bool) {
      clientsId = clientList.map(({ id }) => id);
    }
    setFieldValue('clients', clientsId);
  };

  return (
    <Container>
      <h3 className='text-primary'>
        <FormattedMessage {...messages.UsageCriteriaTitle} />
      </h3>
      <ul className='pl-3'>
        <li>
          <div className='d-flex flex-wrap align-items-baseline'>
            <FormattedMessage
              {...messages.RangeSignupDate}
              values={{
                inputPrevDate: (
                  <div className='mx-2'>
                    <FormInput
                      type='date'
                      name='registrationDateFrom'
                      id='registrationDateFrom'
                      required
                    />
                  </div>
                ),
                inputNextDate: (
                  <div className='mx-2'>
                    <FormInput
                      type='date'
                      name='registrationDateTo'
                      id='registrationDateTo'
                      required
                    />
                  </div>
                ),
              }}
            />
          </div>
        </li>
        <li>
          <div className='d-flex flex-wrap align-items-baseline'>
            <FormattedMessage
              {...messages.ConsultationsNumber}
              values={{
                inputs: (
                  <div className='d-flex flex-wrap align-items-baseline'>
                    <div className='mx-2'>
                      <Field
                        className='form-control'
                        id='consultationsNumberComparisonOperator'
                        name='consultationsNumberComparisonOperator'
                        as='select'
                      >
                        <option value='GreaterThanOrEqualTo'>
                          {intl.formatMessage(messages.GreaterThanOrEqualTo)}
                        </option>
                        <option value='LessThanOrEqualTo'>
                          {intl.formatMessage(messages.LessThanOrEqualTo)}
                        </option>
                        <option value='EqualTo'>
                          {intl.formatMessage(messages.EqualTo)}
                        </option>
                      </Field>
                    </div>
                    <div className='mx-2'>
                      <FormInput
                        type='number'
                        name='consultationsNumber'
                        id='consultationsNumber'
                        required
                      />
                    </div>
                  </div>
                ),
              }}
            />
          </div>
        </li>
        <li>
          <div className='d-flex flex-wrap align-items-baseline'>
            <FormattedMessage
              {...messages.RangeConsultationDate}
              values={{
                inputPrevDate: (
                  <div className='mx-2'>
                    <FormInput
                      type='date'
                      name='lastConsultationDateFrom'
                      id='lastConsultationDateFrom'
                      required
                    />
                  </div>
                ),
                inputNextDate: (
                  <div className='mx-2'>
                    <FormInput
                      type='date'
                      name='lastConsultationDateTo'
                      id='lastConsultationDateTo'
                      required
                    />
                  </div>
                ),
              }}
            />
          </div>
        </li>
      </ul>
      <div className='mb-3'>
        <Field name='onlyNewsletterChecked'>
          {({ field, form }) => (
            <Checkbox
              {...field}
              checked={form.values.onlyNewsletterChecked}
              id='onlyNewsletterChecked'
            >
              {intl.formatMessage({
                ...messages.UsageCriteriaOnlyNewsletterChecked,
              })}
            </Checkbox>
          )}
        </Field>
        <FormInputFeedback name='onlyNewsletterChecked' />
      </div>
      <Button
        type='button'
        outline
        color='primary'
        onClick={() => setClientsListOpen(!isClientsListOpen)}
      >
        <FormattedMessage {...messages.Clients} />
        <i
          className={`uil uil-angle-${isClientsListOpen ? 'up' : 'down'} ml-2`}
          aria-hidden
        />
      </Button>
      <Collapse isOpen={isClientsListOpen}>
        {isClientListLoading ? (
          <Spinner color='primary' />
        ) : (
          <>
            <FormGroup className='d-flex'>
              <button
                className='btn btn-primary mr-2 btn-sm mt-3'
                type='button'
                onClick={() => toggleAllClientsIds(true)}
              >
                <FormattedMessage {...messages.SelectAll} />
              </button>
              <button
                className='btn btn-primary btn-sm mt-3'
                type='button'
                onClick={() => toggleAllClientsIds(false)}
                disabled={!values.clients.length}
              >
                <FormattedMessage {...messages.UnselectAll} />
              </button>
            </FormGroup>
            <FieldArray
              name='clients'
              render={({ push, remove }) => (
                <>
                  {clientList.map((clientItem) => {
                    if (clientItem.label)
                      return (
                        <button
                          className={cn('combined-btn', {
                            'combined-btn--success':
                              values.clients.findIndex(
                                (id) => id === clientItem.id,
                              ) >= 0,
                          })}
                          key={clientItem.id}
                          onClick={() => {
                            const index = values.clients.findIndex((id) => {
                              return id === clientItem.id;
                            });
                            if (index >= 0) {
                              remove(index);
                            } else push(clientItem.id);
                          }}
                          type='button'
                        >
                          <span>{clientItem.label}</span>
                          {values.clients.findIndex(
                            (el) => el === clientItem.id,
                          ) >= 0 ? (
                            <i className='uil uil-check' />
                          ) : (
                            <i className='uil uil-plus' />
                          )}
                        </button>
                      );

                    return null;
                  })}
                </>
              )}
            />
          </>
        )}
      </Collapse>
    </Container>
  );
};

export default FormPlateformCriteria;
