import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import messages from './messages';
import LinksListWrapper from './elements';

const LinksList = ({
  areInnerLinks,
  color,
  data,
  idKey,
  target,
  labelKey,
  limit,
  linkMaxWidth,
  urlBase,
}) => {
  const intl = useIntl();
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const displayedItems = limit > 1 ? data.slice(0, limit - 1) : data;
  const listItems = limit > 1 ? data.slice(limit - 1) : [];

  return (
    <LinksListWrapper>
      {displayedItems.map((item) => (
        <Fragment key={idKey(item)}>
          {areInnerLinks ? (
            <Link
              className={`btn btn-outline-${color} btn-sm my-1 mr-1 text-ellipsis`}
              to={`${urlBase}/${idKey(item)}`}
            >
              {labelKey(item)}
            </Link>
          ) : (
            <LinksListWrapper.Link
              className={`btn btn-outline-${color} btn-sm my-1 mr-1 text-ellipsis`}
              href={`${urlBase}/${idKey(item)}`}
              linkMaxWidth={linkMaxWidth}
              rel='noopener noreferrer'
              target={target}
            >
              {labelKey(item)}
            </LinksListWrapper.Link>
          )}
        </Fragment>
      ))}
      {listItems.length > 0 && (
        <ButtonDropdown className='my-1' isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle caret color={color} outline size='sm'>
            {`${listItems.length} ${intl.formatMessage({
              ...messages.Others,
            })}`}
          </DropdownToggle>
          <DropdownMenu>
            {listItems.map((item) => (
              <Fragment key={idKey(item)}>
                {areInnerLinks ? (
                  <DropdownItem>
                    <Link to={`${urlBase}/${idKey(item)}`}>
                      {labelKey(item)}
                    </Link>
                  </DropdownItem>
                ) : (
                  <DropdownItem
                    href={`${urlBase}/${idKey(item)}`}
                    tag='a'
                    target={target}
                  >
                    {labelKey(item)}
                  </DropdownItem>
                )}
              </Fragment>
            ))}
          </DropdownMenu>
        </ButtonDropdown>
      )}
    </LinksListWrapper>
  );
};

LinksList.defaultProps = {
  areInnerLinks: false,
  // Bootstrap's color
  color: 'primary',
  target: '_self',
  limit: undefined,
  linkMaxWidth: undefined,
};

LinksList.propTypes = {
  areInnerLinks: PropTypes.bool,
  color: PropTypes.string,
  data: PropTypes.instanceOf(Array).isRequired,
  idKey: PropTypes.func.isRequired,
  target: PropTypes.string,
  labelKey: PropTypes.func.isRequired,
  limit: PropTypes.number,
  linkMaxWidth: PropTypes.string,
  urlBase: PropTypes.string.isRequired,
};

export default LinksList;
