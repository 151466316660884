import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';

const IdentityVerifications = () => {
    return (
        <Switch>
            <Route path='/' component={List} />
        </Switch>
    );
};


export default IdentityVerifications;
