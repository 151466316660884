import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonCreate: {
    id: 'Buttons.Create',
    defaultMessage: 'Error.Buttons.Create',
  },
  Title: {
    id: 'Screens.TemplateMessages.Create.Title',
    defaultMessage: 'Error.Screens.TemplateMessages.Create.Title',
  },
  ToastFail: {
    id: 'Toasts.Request.Fail',
    defaultMessage: 'Error.Toasts.Request.Fail',
  },
  ToastSuccess: {
    id: 'Toasts.TemplateMessages.Create.Success',
    defaultMessage: 'Error.Toasts.TemplateMessages.Create.Success',
  },
});
