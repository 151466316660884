import PropTypes from 'prop-types';

export default PropTypes.shape({
  channels: PropTypes.instanceOf(Array),
  cta: PropTypes.string,
  description: PropTypes.string,
  logo: PropTypes.shape({
    rawFile: PropTypes.string,
    contentType: PropTypes.string,
  }),
  name: PropTypes.string,
  specialtyLabel: PropTypes.string,
});
