import { darken, lighten } from 'polished';

const JupiterTheme = {
  // Colors
  black: '#2d3047',
  blue: '#32afff',
  red: '#e74c3c',
  green: '#02c39a',
  yellow: '#fab400',
  white: '#fff',
  grayShade(value) {
    switch (value) {
      case 100:
        return '#f2f4f7';
      case 200:
        return '#e9ecef';
      case 300:
        return '#ced4da';
      case 400:
        return '#ccc';
      case 500:
        return '#adb5bd';
      case 600:
        return '#6e6e6e';
      case 700:
        return '#494949';
      case 800:
        return '#343a40';
      case 900:
        return '#212529';
      default:
        return this.black;
    }
  },
  color(value) {
    switch (value) {
      case 'black-border':
        return darken(0.1, this.black);
      case 'black-lightest':
        return lighten(0.6, this.black);
      case 'blue-font':
        return darken(0.15, this.blue);
      case 'blue-lightest':
        return lighten(0.35, this.blue);
      case 'blue-lightest-border':
        return lighten(0.25, this.blue);
      case 'border':
        return this.grayShade(400);
      case 'danger':
        return this.red;
      case 'gray-font':
        return this.grayShade(600);
      case 'gray-lightest':
        return this.grayShade(100);
      case 'green-lightest':
        return lighten(0.55, this.green);
      case 'red-lightest':
        return lighten(0.4, this.red);
      case 'red-border':
        return '#fbdedb';
      case 'success':
        return this.green;
      case 'yellow-border':
        return darken(0.2, this.yellow);
      case 'yellow-font':
        return darken(0.1, this.yellow);
      case 'yellow-lightest':
        return lighten(0.47, this.yellow);
      default:
        return this.black;
    }
  },
  // Grids
  gridGutterWidth: '30px',
  // Fonts
  fontSizeBase: 16, // Assuming that 1rem = 16px
  fontSizeLg() {
    return `${this.fontSizeBase * 1.125}`;
  },
  fontSizeSm() {
    return `${this.fontSizeBase * 0.875}`;
  },
  h1FontSize() {
    return `${this.fontSizeBase * 1.5}`;
  },
  h2FontSize() {
    return `${this.fontSizeBase * 1.25}`;
  },
  h3FontSize() {
    return `${this.fontSizeBase * 1.25}`;
  },
  h4FontSize() {
    return `${this.fontSizeBase * 1.125}`;
  },
  h5FontSize() {
    return `${this.fontSizeBase}`;
  },
  h6FontSize() {
    return `${this.fontSizeBase}`;
  },
  fontFamilySansSerif:
    'Roboto, "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default',
  fontFamilyCustomSansSerif: '"Comfortaa", Helvetica, Arial, sans-serif',
  fontWeightLight: 100,
  fontWeightNormal: 400,
  fontWeightBold: 700,
  fontCustomWeightLight: 300,
  fontCustomWeightNormal: 400,
  fontCustomWeightBold: 700,
  // fontSize in pixels
  fontSize(fontSize, fontWeight = this.fontWeightNormal) {
    return `
      font-size: ${fontSize}px;
      font-size: ${fontSize / this.fontSizeBase}rem;
      font-weight: ${fontWeight};
    `;
  },
  // fontSize in pixels
  fontCustomSize(fontSize, fontWeight = this.fontCustomWeightNormal) {
    return `
      font-family: ${this.fontFamilyCustomSansSerif};
      font-size: ${fontSize}px;
      font-size: ${fontSize / 16}rem;
      font-weight: ${fontWeight};
    `;
  },
  reactSelectTheme(theme) {
    return {
      ...theme,
      borderRadius: this.borderRadius,
      colors: {
        ...theme.colors,
        danger: this.red,
        dangerLight: this.color('red-lightest'),
        primary: this.blue,
        primary25: this.color('blue-lightest'),
      },
    };
  },
  // Spacers
  spacer: 1,
  space(value) {
    switch (value) {
      case 0:
        return 0;
      case 1:
        return `${this.spacer * 0.25}rem`;
      case 2:
        return `${this.spacer * 0.5}rem`;
      case 3:
        return `${this.spacer * 1}rem`;
      case 4:
        return `${this.spacer * 1.5}rem`;
      case 5:
        return `${this.spacer * 3}rem`;
      case 6:
        return `${this.spacer * 6}rem`;
      case 7:
        return `${this.spacer * 12}rem`;
      default:
        return `${this.spacer}rem`;
    }
  },
  zIndex(value) {
    switch (value) {
      case 'dropdown':
        return 1000;
      case 'sticky':
        return 1020;
      case 'fixed':
        return 1030;
      case 'modal-backdrop':
        return 1040;
      case 'modal':
        return 1050;
      case 'popover':
        return 1060;
      case 'tooltip':
        return 1070;
      default:
        return 1000;
    }
  },
  // Media queries
  screenSmMin: '(min-width: 576px)',
  screenSmMax: '(max-width: 575px)',
  screenMdMin: '(min-width: 768px)',
  screenMdMax: '(max-width: 767px)',
  screenLgMin: '(min-width: 992px)',
  screenLgMax: '(max-width: 991px)',
  screenXlMin: '(min-width: 1200px)',
  screenXlMax: '(max-width: 1199px)',
  breakpointSm: 576,
  breakpointMd: 768,
  breakpointLg: 992,
  breakpointXl: 1200,
  // Border radius
  borderRadiusSm: '.25rem',
  borderRadius: '.375rem',
  borderRadiusLg: '.5rem',
  // Transitions
  transitionBase: 'all .2s ease-in-out',
  transitionFade: 'opacity .15s linear',
  // Header
  headerHeightSm: '64px',
  // Sidebar
  sidebarWidth: '240px',
  // Toasts
  toastWidth: '400px',
  toastHeight: '70px',
};

export default JupiterTheme;
