import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { urlSchema } from 'utils';
import { ProtectedRoute } from 'components';
import Create from './Create';
import Update from './Update';
import Read from './Read';

const routes = [
  { component: Create, path: urlSchema.partnerOperators.create, right: 'CRUD' },
  {
    component: Read,
    path: `${urlSchema.partnerOperators.read}/:partnerOperatorId`,
    right: 'Reader',
  },
  {
    component: Update,
    path: `${urlSchema.partnerOperators.update}/:partnerOperatorId`,
    right: 'CRUD',
  },
];

const PartnerOperators = () => {
  return (
    <Switch>
      {routes.map((el) => (
        <ProtectedRoute entity='Admins' {...el} />
      ))}
      <Route path='/'>
        <Redirect to={urlSchema.administrators.list} />
      </Route>
    </Switch>
  );
};

export default PartnerOperators;
