import React from 'react';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'reactstrap';

import {
  PageHeader,
  RoleManager,
  Section,
  SectionBody,
  SectionHeader,
  Spinner,
} from 'components';
import { api, urlSchema, useFetch } from 'utils';
import messages from './messages';

const Read = () => {
  const { idOrganizationConfig } = useParams();
  const [organizationConfig, isOrganizationConfigLoading] = useFetch(
    api.admin.getOrganizationConfigById(idOrganizationConfig),
  );

  return (
    <>
      <PageHeader>
        <FormattedMessage
          {...messages.DetailsOf}
          values={{
            organizationConfigCode: organizationConfig.code || '',
          }}
          tagName='h1'
        />
        <RoleManager entity='OrganizationConfigs' right='CRUD'>
          <Link
            className='btn btn-primary'
            to={`${urlSchema.editOrganizationConfigs}/${idOrganizationConfig}`}
          >
            <FormattedMessage {...messages.ButtonEdit} />
          </Link>
        </RoleManager>
      </PageHeader>
      {isOrganizationConfigLoading ? (
        <Spinner size='lg' />
      ) : (
        <Row>
          <Col md='7'>
            <Section>
              <SectionBody>
                <div className='model'>
                  <Row>
                    <Col md='4'>
                      <div className='model__property'>
                        <h3 className='model__title'>
                          <FormattedMessage {...messages.Code} />
                        </h3>
                        <p className='model__value'>
                          {organizationConfig.code}
                        </p>
                      </div>
                    </Col>
                    <Col md='8'>
                      <div className='model__property'>
                        <h3 className='model__title'>
                          <FormattedMessage {...messages.Name} />
                        </h3>
                        <p className='model__value'>
                          {organizationConfig.label}
                        </p>
                      </div>
                    </Col>
                    <Col md='7'>
                      <div className='model__property'>
                        <h3 className='model__title'>
                          <FormattedMessage {...messages.CreatedAt} />
                        </h3>
                        <p className='model__value'>
                          <FormattedMessage
                            {...messages.CreationDate}
                            values={{
                              date: moment(
                                organizationConfig.dateCreation,
                              ).format('LL'),
                            }}
                          />
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </SectionBody>
            </Section>
          </Col>
          <Col md='5'>
            <Section>
              <SectionHeader>
                <FormattedMessage {...messages.Universes} tagName='h2' />
                <RoleManager entity='Universes' right='CRUD'>
                  <Link
                    className='btn btn-outline-primary btn-sm ml-auto'
                    to={`${urlSchema.universes.create}?refOrganizationConfig=${idOrganizationConfig}`}
                  >
                    <FormattedMessage {...messages.ButtonAdd} />
                  </Link>
                </RoleManager>
              </SectionHeader>
              <SectionBody>
                <Link
                  to={`${urlSchema.universes.list}?refOrganizationConfig=${idOrganizationConfig}`}
                  className='btn btn-outline-info'
                >
                  <FormattedMessage {...messages.ReadOrganizationConfigs} />
                </Link>
              </SectionBody>
            </Section>
            <Section>
              <SectionHeader>
                <FormattedMessage {...messages.Specialty} tagName='h2' />
                <RoleManager entity='Specialties' right='CRUD'>
                  <Link
                    className='btn btn-outline-primary btn-sm ml-auto'
                    to={`${urlSchema.specialties.create}?refOrganizationConfig=${idOrganizationConfig}`}
                  >
                    <FormattedMessage {...messages.ButtonAdd} />
                  </Link>
                </RoleManager>
              </SectionHeader>
              <SectionBody>
                {organizationConfig.specialties.length > 0 ? (
                  organizationConfig.specialties.map((specialty) => (
                    <Link
                      to={`${urlSchema.specialties.read}/${specialty.id}`}
                      className='btn btn-outline-warning btn-sm mr-2 mb-2'
                    >
                      {specialty.label}
                    </Link>
                  ))
                ) : (
                  <p className='text-secondary m-0'>
                    <FormattedMessage {...messages.NoElement} />
                  </p>
                )}
              </SectionBody>
            </Section>
            <Section>
              <SectionHeader>
                <FormattedMessage {...messages.Profils} tagName='h2' />
                <RoleManager entity='Profiles' right='CRUD'>
                  <Link
                    className='btn btn-outline-primary btn-sm ml-auto'
                    to={`${urlSchema.profiles.create}?refOrganizationConfig=${idOrganizationConfig}`}
                  >
                    <FormattedMessage {...messages.ButtonAdd} />
                  </Link>
                </RoleManager>
              </SectionHeader>
              <SectionBody>
                {organizationConfig.profiles.length > 0 ? (
                  organizationConfig.profiles.map((profile) => (
                    <Link
                      className='btn btn-outline-warning btn-sm mr-1 mb-1'
                      to={`${urlSchema.profiles.read}/${profile.id}`}
                    >
                      {profile.label}
                    </Link>
                  ))
                ) : (
                  <p className='text-secondary m-0'>
                    <FormattedMessage {...messages.NoElement} />
                  </p>
                )}
              </SectionBody>
            </Section>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Read;
