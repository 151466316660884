import styled, { css } from 'styled-components';
import { Col, Row } from 'reactstrap';

const CreateRoomsWrapper = styled.div`
  margin: 0;
  display: flex;
  flex-direction: row;
`;

const RoomWrapper = styled(Col)`
  ${({ theme }) => css`
    padding-left: ${theme.space(2)};
    padding-right: ${theme.space(2)};
  `}
`;

const RoomBody = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space(3)};
    border: 1px solid ${theme.color('border')};
    border-top: none;
    border-bottom-left-radius: ${theme.borderRadiusSm};
    border-bottom-right-radius: ${theme.borderRadiusSm};
  `}
`;

const RoomHeader = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    padding: ${theme.space(3)};
    border: 1px solid ${theme.color('border')};
    border-top-left-radius: ${theme.borderRadiusSm};
    border-top-right-radius: ${theme.borderRadiusSm};
    border-bottom: 1px solid ${theme.grayShade(200)};
    justify-content: space-between;

    p {
      margin: 0;
      ${theme.fontSize(theme.fontSizeBase, theme.fontWeightBold)};
    }

    button {
      border: 0;
      background-color: transparent;
      padding: 0;
    }
  `}
`;

const RoomsList = styled(Row)`
  ${({ theme }) => css`
    min-height: 300px;
    margin-left: -${theme.space(2)};
    margin-right: -${theme.space(2)};
    display: flex;
    flex-direction: wrap;
  `}
`;

CreateRoomsWrapper.RoomsList = RoomsList;
CreateRoomsWrapper.RoomWrapper = RoomWrapper;
CreateRoomsWrapper.RoomHeader = RoomHeader;
CreateRoomsWrapper.RoomBody = RoomBody;

export default CreateRoomsWrapper;
