import React, { useState } from 'react';
import moment from 'moment';
import { useIntl, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';

import { service } from 'services';
import { api, sendToast, urlSchema } from 'utils';
import { ToastTypes } from '../../../constants';

import getValidationSchema from './validationSchema';
import messages from './messages';
import CheckEligibility from './CheckEligibility';
import Information from './Information';
import initialValues from './initialValues';

const Create = () => {
  const { formatMessage } = useIntl();
  const validationSchema = getValidationSchema(formatMessage);
  const history = useHistory();

  const [step, setStep] = useState(0);

  const showError = (setFieldValue, setSubmitting) => {
    setSubmitting(false);
    sendToast(ToastTypes.error, {
      header: <FormattedMessage {...messages.EligibilityErrorTitle} />,
      body: <FormattedMessage {...messages.EligibilityErrorBody} />,
    });
    setFieldValue('isNotEligible', true);
  };

  const createPatient = (values, { setSubmitting }) => {
    service
      .post(api.manager.createPatient, {
        ...values,
        birthdate: moment(values.formattedBirthdate).format('YYYY-MM-DD'),
      })
      .then(({ data: createResponseData }) => {
        setSubmitting(false);
        if (createResponseData.succeeded === false) {
          throw createResponseData;
        }

        sendToast(ToastTypes.success, {
          header: <FormattedMessage {...messages.CreationSuccessTitle} />,
          body: <FormattedMessage {...messages.CreationSuccessBody} />,
        });
        history.push(
          `${urlSchema.patients.readMd}/${createResponseData.idUser}`,
        );
      })
      .catch((err) => {
        const emailAlreadyUsed = err.errorSet[0].code === 'REGISTRATION_FAILED';

        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.CreationErrorTitle} />,
          body: emailAlreadyUsed ? (
            <FormattedMessage {...messages.AlreadyUsedEmailErrorBody} />
          ) : (
            <FormattedMessage {...messages.CreationErrorBody} />
          ),
        });
      });
  };

  const checkEligibility = (values, { setFieldValue, setSubmitting }) => {
    const { userCode, firstname, lastname } = values;

    const body = {
      userCode,
      firstname,
      lastname,
      birthdate: moment(values.formattedBirthdate).format('YYYY-MM-DD'),
    };

    service
      .post(api.identity.isSubscriptionAllowed, body)
      .then(({ data: subscriptionData }) => {
        if (subscriptionData.succeeded === false) {
          showError(setFieldValue, setSubmitting);
          return;
        }

        service
          .get(api.manager.getProduct(subscriptionData.idProduct))
          .then(({ data: productData }) => {
            if (productData.succeeded === false) {
              showError(setFieldValue, setSubmitting);
              return;
            }

            // success

            sendToast(ToastTypes.success, {
              header: (
                <FormattedMessage {...messages.EligibilitySuccessTitle} />
              ),
              body: (
                <FormattedMessage
                  {...messages.EligibilitySuccessBody}
                  values={{
                    name: `${productData.code} (${productData.clientLabel})`,
                  }}
                />
              ),
            });
            setFieldValue('refProduct', productData.idProduct);
            setFieldValue(
              'productLabel',
              `${productData.code} (${productData.clientLabel})`,
            );
            setFieldValue('isNotEligible', false);
            setSubmitting(false);
            setStep(1);
          })
          .catch(() => {
            showError(setFieldValue, setSubmitting);
          });
      })
      .catch(() => {
        showError(setFieldValue, setSubmitting);
      });
  };

  const handleSubmit = (values, props) => {
    props.setSubmitting(true);
    if (step === 0) {
      checkEligibility(values, props);
      props.setTouched({});
      return;
    }

    createPatient(values, props);
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema[step]}
    >
      {() => (
        <Form autoComplete='off'>
          {step === 0 ? <CheckEligibility /> : <Information />}
        </Form>
      )}
    </Formik>
  );
};

export default Create;
