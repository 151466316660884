import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Formik, Field } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, Col, Container, Row } from 'reactstrap';
import qs from 'qs';
import { historyShape } from 'react-router-props';

import {
  PageHeader,
  RoleManager,
  Spinner,
  Button,
  FormInputFeedback,
} from 'components';
import { service } from 'services';
import { api, useFetch, urlSchema } from 'utils';
import messages from './messages';

const List = ({ history }) => {
  const { formatMessage } = useIntl();
  const [organizationConfigs] = useFetch(api.organizationConfigs.getListItems);
  const [universes, setUniverses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getResults = (values = null, callback) => {
    setIsLoading(true);
    service
      .get(api.manager.getUniverses, {
        params: {
          ...qs.parse(history.location.search, { ignoreQueryPrefix: true }),
          ...values,
        },
      })
      .then(({ data }) => {
        setUniverses(data);
        setIsLoading(false);
        if (typeof callback === 'function') callback();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const onSubmit = (values, props) => {
    getResults(values, () => {
      props.setSubmitting(false);
    });
  };

  useEffect(() => {
    getResults();
  }, []);

  return (
    <>
      <PageHeader>
        <FormattedMessage {...messages.Title} tagName='h1' />
        <RoleManager entity='Universes' right='CRUD'>
          <Link
            className='btn btn-primary btm-sm'
            to={urlSchema.universes.create}
          >
            <FormattedMessage {...messages.ButtonCreate} />
          </Link>
        </RoleManager>
      </PageHeader>
      <section className='section'>
        <header className='section__header'>
          <Formik
            initialValues={{
              searchString: '',
              ...qs.parse(history.location.search, { ignoreQueryPrefix: true }),
            }}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form className='w-100'>
                <Row>
                  <Col md='4' lg='5' xl='5'>
                    <Field type='text' name='searchString'>
                      {({ field }) => {
                        const { onChange } = field;
                        return (
                          <Input
                            {...field}
                            id='searchString'
                            onChange={(e) => {
                              onChange(e);
                            }}
                            placeholder={formatMessage(messages.Placeholder)}
                          />
                        );
                      }}
                    </Field>
                  </Col>
                  <Col md='4' lg='5' xl='5'>
                    <Field name='refOrganizationConfig'>
                      {({ field, meta }) => (
                        <Input
                          {...field}
                          id='ref-organizationConfig'
                          type='select'
                          invalid={meta.error && meta.touched}
                        >
                          <option value=''>
                            {formatMessage(messages.DefaultValue)}
                          </option>
                          {organizationConfigs.map((organizationConfig) => (
                            <option
                              key={organizationConfig.id}
                              value={organizationConfig.id}
                            >
                              {organizationConfig.label} (
                              {organizationConfig.code})
                            </option>
                          ))}
                        </Input>
                      )}
                    </Field>
                    <FormInputFeedback name='refOrganizationConfig' />
                  </Col>
                  <Col className='ml-auto' md='auto'>
                    <Button
                      data-tracking='UniversesListBtnSearch'
                      color='primary'
                      isProcessing={isSubmitting}
                      label='Research'
                      type='submit'
                    />
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </header>
        <div className='section__body'>
          <header className='research-table__header'>
            <Container fluid>
              <Row>
                <Col md='3'>
                  <FormattedMessage tagName='h5' {...messages.Code} />
                </Col>
                <Col md='2'>
                  <FormattedMessage tagName='h5' {...messages.Universes} />
                </Col>
                <Col lg='2' className='d-none d-sm-none d-lg-block'>
                  <FormattedMessage tagName='h5' {...messages.Clients} />
                </Col>
                <Col lg='2' className='d-none d-md-none d-lg-block'>
                  <FormattedMessage tagName='h5' {...messages.Doctors} />
                </Col>
                <Col md='2' className='d-none d-md-none d-lg-block'>
                  <FormattedMessage
                    tagName='h5'
                    {...messages.TemplateMessages}
                  />
                </Col>
              </Row>
            </Container>
          </header>
          {isLoading ? (
            <Spinner color='primary' size='lg' />
          ) : (
            <ul className='research-table__results'>
              {universes.map((item) => {
                return (
                  <li key={item.idUniverse}>
                    <Container fluid>
                      <Row className='align-items-center'>
                        <Col md='4' lg='3'>
                          <h6 className='mb-0 font-weight-bold'>{item.code}</h6>
                          <p className='m-0 small text-secondary'>
                            {item.label}
                          </p>
                        </Col>
                        <Col
                          md='2'
                          className='d-none d-sm-none d-md-block d-lg-block'
                        >
                          <Link
                            className='mb-0'
                            to={`${urlSchema.detailsOrganizationConfigs}/${item.refOrganizationConfig}`}
                          >
                            {item.labelOrganizationConfig}
                          </Link>
                        </Col>
                        <Col md='2' className='d-none d-sm-none d-lg-block'>
                          {item?.clients.length > 0 ? (
                            <Link
                              to={`${urlSchema.clients.list}?${qs.stringify({
                                refUniverse: item.idUniverse,
                              })}`}
                              className='btn btn-sm btn-outline-primary'
                            >
                              <div className='d-flex justify-content-center align-items-center'>
                                <FormattedMessage
                                  {...messages.ReadCustomers}
                                  values={{ nbClients: item.clients.length }}
                                />
                              </div>
                            </Link>
                          ) : (
                            <p className='text-secondary m-0 small'>
                              <FormattedMessage {...messages.Empty} />
                            </p>
                          )}
                        </Col>
                        <Col lg='2' className='d-none d-sm-none d-lg-block'>
                          {item?.doctors ? (
                            <Link
                              to={`${urlSchema.doctors.list}?refUniverse=${item.idUniverse}`}
                              className='btn btn-outline-primary btn-sm'
                            >
                              <FormattedMessage
                                {...messages.ReadDoctors}
                                values={{ nbDoctors: item.doctors.length }}
                              />
                            </Link>
                          ) : (
                            <p className='text-secondary m-0 small'>
                              <FormattedMessage {...messages.Empty} />
                            </p>
                          )}
                        </Col>
                        <div className='col-md-2'>
                          {item.templateMessagesCount > 0 ? (
                            <Link
                              to={`${urlSchema.templateMessages.list}?refOrganization=${item.idUniverse}`}
                              className='btn btn-outline-primary btn-sm'
                            >
                              {`${formatMessage({
                                ...messages.ButtonReadTemplateMessages,
                              })} (${item.templateMessagesCount})`}
                            </Link>
                          ) : (
                            <p className='text-secondary m-0 small'>
                              <FormattedMessage {...messages.Empty} />
                            </p>
                          )}
                        </div>
                        <Col className='ml-auto'>
                          <div className='research-table__actions'>
                            <Link
                              aria-label={formatMessage(messages.ButtonRead)}
                              title={formatMessage(messages.ButtonRead)}
                              to={`${urlSchema.universes.read}/${item.idUniverse}`}
                            >
                              <i aria-hidden className='uil uil-eye uil--md' />
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </section>
    </>
  );
};

List.propTypes = { history: historyShape.isRequired };

export default List;
