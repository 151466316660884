export enum IdentityDocumentTypesDte {
  Passport = 'Passport',
  NIC = 'NIC',
  FRB = 'FRB',
  BirthCertificate = 'BirthCertificate',
  PRP = 'PRP',
}

export enum GendersDte {
  Unknown = 'Unknown',
  Male = 'Male',
  Female = 'Female',
}

export enum NhiIdentityStatusDtestring {
  Temporary = 'Temporary',
  Recovered = 'Recovered',
  Validated = 'Validated',
  Qualified = 'Qualified',
}

export enum InsDocumentValidationStatus {
  Pending = 'Pending',
  Validated = 'Validated',
  Refused = 'Refused',
  NotSubmited = 'NotSubmited',
}

export enum IdentityDocumentValidationStatusesDtestring {
  Unreadable = 'Unreadable',
  Invalid = 'Invalid',
  Mistaken = 'Mistaken',
}

export type User = {
  idDocument: string;
  title?: string;
  fileName?: string;
  contentType?: string;
  dateCreation: string;
  identityDocumentType: IdentityDocumentTypesDte;
  idUser?: string;
  lastname?: string;
  firstname?: string;
  birthdate?: string;
  phoneNumber?: string;
  mobileNumber?: string;
  email?: string;
  birthPlace?: string;
  refBirthCountry?: string;
  patientId: number;
  nhiLastName?: string;
  nhiLastNames?: string;
  nhiFirstName?: string;
  nhiUnQualifiedBirthDate?: string;
  nhiBirthDate?: string;
  nhiGender: GendersDte;
  nhiUsedLastName?: string;
  nhiUsedFirstName?: string;
  nhiStatus?: NhiIdentityStatusDtestring;
  documentValidationStatus: InsDocumentValidationStatus;
  documentRefusalReason?: IdentityDocumentValidationStatusesDtestring;
  processDate?: string;
  requestDate?: string;
  refUniverse?: string;
};

export type IdentityDocument = {
  idDocument: string;
  title: string;
  fileName: string;
  contentType: string;
  documentRawFile: string;
};
