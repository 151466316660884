import { defineMessages } from 'react-intl';

export default defineMessages({
  CodeLanguage: {
    id: 'Model.TemplateMessages.CodeLanguage',
    defaultMessage: 'Error.Model.TemplateMessages.CodeLanguage',
  },
  Message: {
    id: 'Model.TemplateMessages.Message',
    defaultMessage: 'Error.Model.TemplateMessages.Message',
  },
  Name: {
    id: 'Model.TemplateMessages.Name',
    defaultMessage: 'Error.Model.TemplateMessages.Name',
  },
  Object: {
    id: 'Model.TemplateMessages.Object',
    defaultMessage: 'Error.Model.TemplateMessages.Object',
  },
  RefOrganization: {
    id: 'Model.TemplateMessages.RefOrganization',
    defaultMessage: 'Error.Model.TemplateMessages.RefOrganization',
  },
  Type: {
    id: 'Model.TemplateMessages.Type',
    defaultMessage: 'Error.Model.TemplateMessages.Type',
  },
});
