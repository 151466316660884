import styled, { css } from 'styled-components';
import { Input, Spinner } from 'reactstrap';
import crossIcon from '../../../assets/images/cross_icon.svg';

const AutocompleteWrapper = styled.div`
  ${({ theme }) =>
    css`
      position: relative;
    `}
`;

const ASpinner = styled(Spinner)`
  ${({ theme, style }) => css`
    position: absolute;
    margin-top: calc(-${style.height} / 2);
    right: ${theme.space(3)};
    top: 50%;
  `}
`;

const AInput = styled(Input)`
  ${({ theme }) => css`
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      position: relative;
      left: 5px;
      height: 20px;
      width: 20px;
      background-image: url(${crossIcon});
      background-size: contain;
      background-repeat: no-repeat;
    }
  `}
`;

const Suggestion = styled.p`
  ${({ selected, theme }) => css`
    margin: 0;
    padding: 0;
    cursor: pointer;

    ${selected &&
    css`
      background-color: ${theme.grayShade(500)};
    `};

    .row {
      align-items: center;
    }
  `}
`;

AutocompleteWrapper.Spinner = ASpinner;
AutocompleteWrapper.Input = AInput;

export { AutocompleteWrapper, Suggestion };
