import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { useCopyToClipboard } from 'react-use';
import { matchShape } from 'react-router-props';

import { ModelProperty, PageHeader, RoleManager } from 'components';
import { api, urlSchema, useFetch } from 'utils';
import messages from './messages';

const Read = ({ match }) => {
  const { formatMessage } = useIntl();
  const {
    params: { idWebServiceConfiguration },
  } = match;
  const [configuration, isConfigurationLoading] = useFetch(
    `${api.webServices.getConfiguration}/${idWebServiceConfiguration}`,
  );
  const [copiedCertificate, copyCertificateToClipboard] = useCopyToClipboard();
  const [copiedCertificatePassword, copyCertificatePasswordToClipboard] =
    useCopyToClipboard();

  return (
    <>
      <PageHeader>
        <FormattedMessage
          {...messages.Title}
          tagName='h1'
          values={{ name: configuration.name || '' }}
        />
        <RoleManager entity='WebServices' right='CRUD'>
          <Link
            className='btn btn-primary'
            to={`${urlSchema.webServices.configuration.update}/${idWebServiceConfiguration}`}
          >
            <FormattedMessage {...messages.ButtonEdit} />
          </Link>
        </RoleManager>
      </PageHeader>
      <section className='section'>
        <div className='section__body'>
          {isConfigurationLoading ? (
            <div className='py-3 text-center'>
              <Spinner color='primary' size='lg' />
            </div>
          ) : (
            <>
              <div className='row'>
                <div className='col-md-7'>
                  <ModelProperty
                    title={formatMessage({ ...messages.Name })}
                    value={configuration.name}
                  />
                  <div className='model__property'>
                    <h5 className='model__title'>
                      <FormattedMessage {...messages.EndPoint} />
                    </h5>
                    <code>{configuration.endPoint}</code>
                  </div>
                  <ModelProperty
                    title={formatMessage({ ...messages.RequestType })}
                    value={configuration.requestType}
                  />
                  <ModelProperty
                    title={formatMessage({ ...messages.CodeOk })}
                    value={configuration.codeOk}
                  />
                  <ModelProperty
                    title={formatMessage({ ...messages.CodeKo })}
                    value={configuration.codeKo}
                  />
                  <ModelProperty
                    title={formatMessage({ ...messages.AuthentificationLogin })}
                    value={configuration.authentificationLogin}
                  />
                  <ModelProperty
                    title={formatMessage({
                      ...messages.AuthentificationPassword,
                    })}
                    value={configuration.authentificationPassword}
                  />
                  <div className='model__property'>
                    <h5 className='model__title'>
                      <FormattedMessage {...messages.Certificate} />
                    </h5>
                    {configuration.certificate ? (
                      <div className='d-flex align-items-center'>
                        <p className='text-ellipsis m-0 w-50'>
                          {configuration.certificate}
                        </p>
                        <button
                          className='btn btn-outline-primary btn-sm'
                          onClick={() =>
                            copyCertificateToClipboard(
                              configuration.certificate,
                            )
                          }
                          type='button'
                        >
                          {typeof copiedCertificate.value !== 'undefined'
                            ? formatMessage({ ...messages.Copied })
                            : formatMessage({ ...messages.ButtonCopy })}
                        </button>
                      </div>
                    ) : (
                      <p className='model__value'>-</p>
                    )}
                  </div>
                  <div className='model__property'>
                    <h5 className='model__title'>
                      <FormattedMessage {...messages.CertificatePassword} />
                    </h5>
                    {configuration.certificatePassword ? (
                      <div className='d-flex align-items-center'>
                        <p className='text-ellipsis m-0 w-50'>
                          {configuration.certificatePassword}
                        </p>
                        <button
                          className='btn btn-outline-primary btn-sm'
                          onClick={() =>
                            copyCertificatePasswordToClipboard(
                              configuration.certificatePassword,
                            )
                          }
                          type='button'
                        >
                          {typeof copiedCertificatePassword.value !==
                          'undefined'
                            ? formatMessage({ ...messages.Copied })
                            : formatMessage({ ...messages.ButtonCopy })}
                        </button>
                      </div>
                    ) : (
                      <p className='model__value'>-</p>
                    )}
                  </div>
                </div>
                <div className='col-md-5'>
                  <div className='bg-light rounded p-3'>
                    <h4 className='d-flex align-items-center'>
                      <i aria-hidden className='uil uil-user uil--md' />
                      <span className='ml-2'>
                        <FormattedMessage {...messages.UserTest} />
                      </span>
                    </h4>
                    <ModelProperty
                      title={formatMessage({ ...messages.UserAgentCode })}
                      value={configuration.userAgentCode}
                    />
                    <ModelProperty
                      title={formatMessage({ ...messages.UserFirstName })}
                      value={configuration.userFirstName}
                    />
                    <ModelProperty
                      title={formatMessage({ ...messages.UserLastName })}
                      value={configuration.userLastName}
                    />
                    <ModelProperty
                      title={formatMessage({ ...messages.UserMembershipCode })}
                      value={configuration.userMembershipCode}
                    />
                    <div className='model__property'>
                      <h5 className='model__title'>
                        <FormattedMessage {...messages.UserBirthDate} />
                      </h5>
                      <p className='model__value'>
                        {configuration.userBirthDate}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

Read.propTypes = { match: matchShape.isRequired };

export default Read;
