import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useOidc } from 'utils';
import { useIntl, FormattedDate } from 'react-intl';
import { useMedia } from 'react-use';
import { withTheme } from 'styled-components';
import { AuthenticationContext } from '@teladochealth/auth';

import Logo from 'assets/images/logo_medecindirect_white.svg';
import { urlSchema } from 'utils';
import HeaderCollapseMenu from './HeaderCollapseMenu';
import SidebarLink from './SidebarLink';
import messages from './messages';
import { LogoutButtonWrapper, SidebarWrapper } from './elements';
import { SidebarContent } from '../../constants';

const Sidebar = ({ theme }) => {
  const intl = useIntl();
  const oidcUser = useOidc();
  const Context = useContext(AuthenticationContext);
  const isMdMin = useMedia(theme.screenMdMin);
  const isMdMax = useMedia(theme.screenMdMax);
  const [isOpen, setIsOpen] = useState(false);

  const getSidebarContentWithRole = () => {
    const userRoles = oidcUser.profile.roles;
    const sidebarContent = SidebarContent.reduce((accCat, category) => {
      const links = category.links.reduce((acc, data) => {
        let display = false;
        const isRoleExclusive =
          !(typeof data.isRoleExclusive === 'undefined') &&
          data.isRoleExclusive;

        if (
          (userRoles.includes('Root') && !isRoleExclusive) ||
          userRoles.includes(`${data.entity}${data.role}`) ||
          (data.role === 'Reader' && userRoles.includes(`${data.entity}CRUD`))
        ) {
          display = true;
        }

        return display ? [...acc, data] : acc;
      }, []);

      return links.length ? [...accCat, { ...category, links }] : accCat;
    }, []);
    return sidebarContent;
  };

  const homeLink = () => {
    let url = 'empty string to fill';

    const profileRoles = Array.isArray(oidcUser.profile.roles)
      ? oidcUser.profile.roles
      : [oidcUser.profile.roles];

    switch (true) {
      case profileRoles.includes('Root') ||
        oidcUser.profile.profiles.includes('MD.OP'):
        url = urlSchema.statistics.list;
        break;
      case profileRoles.includes('PartnerOperationsCRUD'):
        url = urlSchema.partnerOperations;
        break;
      default:
        url = urlSchema.patients.list;
        break;
    }
    return url;
  };

  const LogoutButton = () => (
    <LogoutButtonWrapper onClick={() => Context?.auth.logout()}>
      <i className='uil uil-sign-out-alt uil--md' aria-hidden />
    </LogoutButtonWrapper>
  );

  return (
    <SidebarWrapper className='fixed-top'>
      <SidebarWrapper.Header>
        {isMdMax && (
          <SidebarWrapper.Hamburger
            onClick={() => setIsOpen(!isOpen)}
            isOpen={isOpen}
          >
            <span />
            <span />
            <span />
          </SidebarWrapper.Hamburger>
        )}
        <a href={homeLink()}>
          <img src={Logo} alt='Home' />
        </a>
        {isMdMax && <LogoutButton />}
      </SidebarWrapper.Header>
      <SidebarWrapper.Menu isOpen={isOpen}>
        {getSidebarContentWithRole().map(({ title, iconMenu, links }) => (
          <HeaderCollapseMenu
            iconMenu={iconMenu}
            textMenu={intl.formatMessage(messages[title])}
            key={title}
          >
            {links.map((link) => (
              <SidebarLink
                isInnerLink={link.isInnerLink}
                key={link.name}
                link={link.url}
                text={intl.formatMessage(messages[link.name])}
              />
            ))}
          </HeaderCollapseMenu>
        ))}
      </SidebarWrapper.Menu>
      {isMdMin && (
        <SidebarWrapper.UserWrapper>
          <div>
            <h3>
              <span>{oidcUser.profile.firstname}</span>
              <span> </span>
              <span>{oidcUser.profile.lastname}</span>
            </h3>
            <LogoutButton />
          </div>
          <p>
            <span>Copyright © </span>
            <FormattedDate date={Date.now()} year='numeric' />
            <span> MédecinDirect ®</span>
          </p>
        </SidebarWrapper.UserWrapper>
      )}
    </SidebarWrapper>
  );
};

Sidebar.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(Sidebar);
