import React, { useEffect, useState } from 'react';
import { Form, Formik, Field, FieldArray } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col, Collapse, Container, Input } from 'reactstrap';
import cn from 'classnames';
import qs from 'qs';

import {
  PageHeader,
  RoleManager,
  Button,
  LinksList,
  Spinner,
} from 'components';
import { service } from 'services';
import {
  api,
  removeKeysWithEmptyString,
  sendToast,
  urlSchema,
  useFetch,
} from 'utils';
import messages from './messages';
import { CodeCompany, ToastTypes } from '../../../constants';

const List = () => {
  const location = useLocation();
  const intl = useIntl();
  const [isCollapseOpen, setCollapseIsOpen] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [clients, areClientsLoading] = useFetch(api.clients.get);
  const [profiles, areProfilesLoading] = useFetch(api.profiles.get);
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { SearchString } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const getResults = (values = {}, callback) => {
    const parameters = qs.stringify(removeKeysWithEmptyString(values), {
      skipNulls: true,
    });

    setIsLoading(true);
    service
      .get(`${api.administrators.getList}?${parameters}`)
      .then(({ data }) => {
        setAdmins(data);
        setIsLoading(false);
        window.history.pushState({}, null, `/Users/Admins?${parameters}`);
        if (typeof callback === 'function') callback();
      })
      .catch(() => {
        setIsLoading(false);
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.FailTitle} />,
          body: <FormattedMessage {...messages.FailBody} />,
        });
      });
  };

  const onSubmit = (values, props) => {
    getResults(values, () => {
      props.setSubmitting(false);
    });
  };

  useEffect(() => {
    getResults(
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }),
    );
  }, []);

  const arrayLinksDropdownButton = [
    {
      label: intl.formatMessage({
        ...messages.CreateAdmin,
      }),
      href: `${urlSchema.administrators.create}`,
      key: 'idLinkCreateAdmin',
    },
    {
      label: intl.formatMessage({
        ...messages.CreateOperatorPartner,
      }),
      href: `${urlSchema.partnerOperators.create}`,
      key: 'idLinkCreateOperatorPartner',
    },
  ];

  return (
    <>
      <PageHeader>
        <FormattedMessage {...messages.Title} tagName='h1' />
        <RoleManager entity='Admins' right='CRUD'>
          <div
            className='dropdown'
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <button
              className='btn btn-primary drowdown-toggle'
              type='button'
              id='CreateDropdownButton'
              data-toggle='dropdown'
              aria-haspopup='true'
            >
              <FormattedMessage {...messages.ButtonCreate} />
            </button>
            <div
              className={`dropdown-menu${isDropdownOpen ? ' show' : ''}`}
              aria-labelledby='CreateDropdownButton'
            >
              {arrayLinksDropdownButton.map((link) => {
                return (
                  <Link className='dropdown-item' to={link.href} key={link.key}>
                    {link.label}
                  </Link>
                );
              })}
            </div>
          </div>
        </RoleManager>
      </PageHeader>
      <section className='section'>
        <header className='section__header'>
          <Formik
            initialValues={{
              SearchString: SearchString ?? '',
              profiles: [],
            }}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, values }) => (
              <Form className='w-100 '>
                <Row className='mb-3'>
                  <Col md='8'>
                    <Field
                      className='form-control'
                      name='SearchString'
                      type='text'
                    >
                      {({ field }) => {
                        const { onChange } = field;
                        return (
                          <Input
                            {...field}
                            id='search-string'
                            onChange={(e) => {
                              onChange(e);
                            }}
                            placeholder={intl.formatMessage({
                              ...messages.Search,
                            })}
                          />
                        );
                      }}
                    </Field>
                  </Col>
                  <Col md='2'>
                    <Button
                      color='primary'
                      type='button'
                      label='Profiles'
                      onClick={() => {
                        setCollapseIsOpen(!isCollapseOpen);
                      }}
                    />
                  </Col>
                  <Col md='auto' className='ml-auto'>
                    <Button
                      color='primary'
                      data-tracking='AdminsBtnSearch'
                      isProcessing={isSubmitting}
                      type='submit'
                      label='Research'
                    />
                  </Col>
                </Row>
                <Collapse isOpen={isCollapseOpen}>
                  <Container fluid>
                    <FieldArray
                      name='profiles'
                      render={({ push, remove }) => (
                        <>
                          {profiles.map((profile) => {
                            return (
                              <button
                                className={cn('combined-btn', {
                                  'combined-btn--success':
                                    values.profiles.findIndex(
                                      (id) => id === profile.idProfile,
                                    ) >= 0,
                                })}
                                key={profile.idProfile}
                                onClick={() => {
                                  const index = values.profiles.findIndex(
                                    (id) => {
                                      return id === profile.idProfile;
                                    },
                                  );
                                  if (index >= 0) {
                                    remove(index);
                                  } else push(profile.idProfile);
                                }}
                                type='button'
                              >
                                <span> {profile.code}</span>
                                {values.profiles.findIndex(
                                  (el) => el === profile.idProfile,
                                ) >= 0 ? (
                                  <i className='uil uil-check' />
                                ) : (
                                  <i className='uil uil-plus' />
                                )}
                              </button>
                            );
                          })}
                        </>
                      )}
                    />
                  </Container>
                </Collapse>
              </Form>
            )}
          </Formik>
        </header>
        <div className='section__body'>
          <div className='research-table__header'>
            <Container fluid>
              <Row>
                <Col xs='5' lg='3'>
                  <FormattedMessage tagName='h5' {...messages.Administrator} />
                </Col>
                <Col className='d-none d-lg-block' md='3'>
                  <FormattedMessage tagName='h5' {...messages.Contact} />
                </Col>
                <Col xs='2'>
                  <FormattedMessage tagName='h5' {...messages.Clients} />
                </Col>
                <Col xs='4'>
                  <FormattedMessage tagName='h5' {...messages.Profiles} />
                </Col>
              </Row>
            </Container>
          </div>
          {isLoading || areProfilesLoading || areClientsLoading ? (
            <Spinner size='lg' />
          ) : admins.length > 0 ? (
            <ul className='research-table__results'>
              {admins.map((item) => {
                return (
                  <li key={item.idUser}>
                    <Container fluid>
                      <Row className='align-items-center'>
                        <Col xs='5' lg='3'>
                          <p className='m-0 text-dark'>
                            {item.firstname} {item.lastname}
                          </p>
                          {item.login && (
                            <a
                              className='d-block d-lg-none text-ellipsis text-secondary'
                              href={`mailto:${item.login}`}
                            >
                              <i
                                className='align-middle uil uil-envelope mr-2'
                                aria-hidden
                              />
                              <small>{item.login}</small>
                            </a>
                          )}
                        </Col>
                        <Col className='d-none d-lg-block' md='3'>
                          {item.login && (
                            <a
                              className='d-block text-ellipsis text-secondary'
                              href={`mailto:${item.login}`}
                            >
                              <i
                                className='align-middle uil uil-envelope mr-2'
                                aria-hidden
                              />
                              <small>{item.login}</small>
                            </a>
                          )}
                        </Col>
                        <Col xs='2'>
                          <LinksList
                            areInnerLinks
                            color='warning'
                            data={item.infosAdmin.clients}
                            limit={2}
                            idKey={(el) => el.refClient}
                            labelKey={(elem) =>
                              clients.find(
                                (el) => el.idClient === elem.refClient,
                              ).label
                            }
                            urlBase={urlSchema.clients.read}
                          />
                        </Col>
                        <Col xs='2' lg='2'>
                          <LinksList
                            areInnerLinks
                            color='primary'
                            data={item.usersProfiles}
                            limit={2}
                            idKey={(el) => el}
                            labelKey={(elem) =>
                              profiles.find((el) => el.idProfile === elem).code
                            }
                            urlBase={urlSchema.profiles.read}
                          />
                        </Col>
                        <Col>
                          <div className='research-table__actions'>
                            <RoleManager entity='Admins' right='CRUD'>
                              {item.infosAdmin.codeCompany ===
                              CodeCompany.PARTENAIRE ? (
                                <Link
                                  to={`${urlSchema.partnerOperators.update}/${item.idUser}`}
                                  rel='noopener noreferrer'
                                >
                                  <i
                                    aria-hidden
                                    className='uil uil-pen uil--md'
                                  />
                                </Link>
                              ) : (
                                <Link
                                  to={`${urlSchema.administrators.update}/${item.idUser}`}
                                >
                                  <i
                                    aria-hidden
                                    className='uil uil-pen uil--md'
                                  />
                                </Link>
                              )}
                            </RoleManager>
                            <RoleManager entity='Admins' right='Reader'>
                              <Link
                                to={
                                  item.infosAdmin.codeCompany ===
                                  CodeCompany.PARTENAIRE
                                    ? `${urlSchema.partnerOperators.read}/${item.idUser}`
                                    : `${urlSchema.administrators.read}/${item.idUser}`
                                }
                              >
                                <i
                                  aria-hidden
                                  className='uil uil-eye uil--md'
                                />
                              </Link>
                            </RoleManager>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </li>
                );
              })}
            </ul>
          ) : (
            <p className='text-secondary m-0'>
              <FormattedMessage {...messages.NoElement} />
            </p>
          )}
        </div>
      </section>
    </>
  );
};

export default List;
