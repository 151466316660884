import React from 'react';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, PageHeader, Section, SectionBody, Spinner } from 'components';
import { ButtonsWrapper } from 'themes/jupiter/elements';
import { service } from 'services';
import { api, useFetch, sendToast } from 'utils';
import { ToastTypes } from '../../../constants';
import { FileImportClient } from 'models';
import validationSchema from 'models/FileImportClient/validationShema';
import messages from './messages';

const Update = () => {
  const intl = useIntl();
  const { idFileImportClient } = useParams();
  const [fileImportClients, FileImportClientsLoading] = useFetch(
    api.admin.getFileImportClientById(idFileImportClient),
  );

  const onSubmit = (values, { setSubmitting }) => {
    service
      .put(api.admin.putFileImportClients(idFileImportClient), values)
      .then(({ data }) => {
        if (data.succeeded === false) {
          sendToast(ToastTypes.error, {
            header: <FormattedMessage {...messages.FailTitle} />,
            body: (
              <>
                {data.errorSet.map((error) => (
                  <p>
                    {error.code}: {error.description}
                  </p>
                ))}
              </>
            ),
          });
          setSubmitting(false);
          return;
        }
        sendToast(ToastTypes.success, {
          header: <FormattedMessage {...messages.SuccessTitle} />,
          body: <FormattedMessage {...messages.SuccessBody} />,
        });
        setSubmitting(false);
      })
      .catch(({ data }) => {
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.FailTitle} />,
          body: (
            <>
              {data.errorSet.map((error) => (
                <p>
                  {error.code}: {error.description}
                </p>
              ))}
            </>
          ),
        });
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <FormattedMessage {...messages.Title} tagName='h1' />
      </PageHeader>
      {FileImportClientsLoading ? (
        <Spinner color='primary' size='lg' />
      ) : (
        <>
          <Row>
            <Col lg='6'>
              <Section>
                <SectionBody>
                  <Formik
                    initialValues={{
                      ...fileImportClients,
                      idFileImportClient: fileImportClients.idFileImportClient,
                      codeClient: fileImportClients.codeClient,
                      codeEntity: fileImportClients.codeEntity,
                      refProduct: fileImportClients.refProduct.id,
                      status: fileImportClients.status,
                    }}
                    validationSchema={validationSchema(intl)}
                    onSubmit={onSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <FileImportClient
                          fileImportClients={fileImportClients}
                          context='update'
                        />
                        <ButtonsWrapper bottom>
                          <Button
                            color='primary'
                            isProcessing={isSubmitting}
                            label='Update'
                            size='lg'
                            data-tracking='FileImportClientUpdateSubmit'
                            type='submit'
                          />
                        </ButtonsWrapper>
                      </Form>
                    )}
                  </Formik>
                </SectionBody>
              </Section>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Update;
