import { defineMessages } from 'react-intl';

export default defineMessages({
  labelHealthPartner: {
    id: 'Screens.Offers.Create.CreateRoomForm.LabelHealthPartner',
    defaultMessage:
      'Error.Screens.Offers.Create.CreateRoomForm.LabelHealthPartner',
  },
  CommunicationChannel: {
    id: 'Screens.Offers.Create.CreateRoomForm.LabelCanal',
    defaultMessage: 'Error.Screens.Offers.Create.CreateRoomForm.LabelCanal',
    description: 'Subtitle of partner creation page',
  },
  SelectACanal: {
    id: 'Screens.Offers.Create.CreateRoomForm.SelectACanal',
    defaultMessage: 'Error.Message.Success',
  },
  AlreadyUsed: {
    id: 'Screens.Offers.Create.CreateRoomForm.AlreadyUsed',
    defaultMessage: 'Error.Screens.Offers.Create.CreateRoomForm.AlreadyUsed',
  },
  AddThisRoom: {
    id: 'Screens.Offers.Create.CreateRoomForm.AddThisRoom',
    defaultMessage: 'Error.Screens.Offers.Create.CreateRoomForm.AddThisRoom',
  },
  FutureLimit: {
    id: 'Screens.Offers.RoomsInfo.FutureLimit',
    defaultMessage: 'Error.Screens.Offers.RoomsInfo.FutureLimit',
  },
  DutySlotDuration: {
    id: 'Screens.Offers.RoomsInfo.DutySlotDuration',
    defaultMessage: 'Error.Screens.Offers.RoomsInfo.DutySlotDuration',
  },
  ConsultationDuration: {
    id: 'Screens.Offers.RoomsInfo.ConsultationDuration',
    defaultMessage: 'Error.Screens.Offers.RoomsInfo.ConsultationDuration',
  },
  MinBookingNotice: {
    id: 'Screens.Offers.RoomsInfo.MinBookingNotice',
    defaultMessage: 'Error.Screens.Offers.RoomsInfo.MinBookingNotice',
  }

});
