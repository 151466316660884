import { defineMessages } from 'react-intl';

export default defineMessages({
  Title: {
    id: 'Screens.Statistics.List.Title',
    defaultMessage: 'Error.Screens.Statistics.List.Title',
  },
  TopSubsProduct: {
    id: 'Screens.Statistics.List.TopSubscribersAmountPerProduct',
    defaultMessage:
      'Error.Screens.Statistics.List.TopSubscribersAmountPerProduct',
  },
  TopQuestionProduct: {
    id: 'Screens.Statistics.List.TopQuestionAmountPerProduct',
    defaultMessage: 'Error.Screens.Statistics.List.TopQuestionAmountPerProduct',
  },
  QuestionAmount: {
    id: 'Screens.Statistics.List.QuestionAmount',
    defaultMessage: 'Error.Screens.Statistics.List.QuestionAmount',
  },
  RegistrationAmount: {
    id: 'Screens.Statistics.List.RegistrationAmount',
    defaultMessage: 'Error.Screens.Statistics.List.RegistrationAmount',
  },
});
