import React from 'react';
import { Switch } from 'react-router-dom';

import { ProtectedRoute } from 'components';

import List from './List';

const FileImports = ({ match }) => {
  const { path } = match;

  return (
    <Switch>
      <ProtectedRoute
        entity='FileImports'
        exact
        path={path}
        right='Reader'
        component={List}
      />
    </Switch>
  );
};

export default FileImports;
