import React from 'react';
import { Switch } from 'react-router-dom';

import { ProtectedRoute } from 'components';
import { urlSchema } from 'utils';

// Screens
import List from './List';
import Read from './Read';
import Update from './Update';
import Create from './Create';

const routes = [
  {
    path: urlSchema.createOrganizationConfigs,
    component: Create,
    right: 'CRUD',
  },
  {
    path: `${urlSchema.detailsOrganizationConfigs}/:idOrganizationConfig`,
    component: Read,
    right: 'Reader',
  },
  {
    path: `${urlSchema.editOrganizationConfigs}/:idOrganizationConfig`,
    component: Update,
    right: 'CRUD',
  },
  { path: '/', component: List, right: 'Reader' },
];

const OrganizationConfigs = () => {
  return (
    <Switch>
      {routes.map((item) => (
        <ProtectedRoute
          entity='OrganizationConfigs'
          key={item.path}
          {...item}
        />
      ))}
    </Switch>
  );
};

export default OrganizationConfigs;
