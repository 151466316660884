import ChannelCode from 'constants/ChannelCode';

const channelOptions = [
  { label: 'Messaging', value: ChannelCode.MESSAGING },
  { label: 'Phone', value: ChannelCode.PHONE },
  { label: 'Visio', value: ChannelCode.VISIO },
];

const minBookingNoticeOptions = [
  {
    labelValueKey: 'count',
    labelValue: 15,
    labelKey: 'Shared.Minute',
    value: 15,
  },
  {
    labelValueKey: 'count',
    labelValue: 30,
    labelKey: 'Shared.Minute',
    value: 30,
  },
  {
    labelValueKey: 'count',
    labelValue: 45,
    labelKey: 'Shared.Minute',
    value: 45,
  },
  { labelValueKey: 'count', labelValue: 1, labelKey: 'Shared.Hour', value: 60 },
  {
    labelValueKey: 'count',
    labelValue: 1,
    labelKey: 'Shared.Day',
    value: 1440,
  },
  {
    labelValueKey: 'count',
    labelValue: 2,
    labelKey: 'Shared.Day',
    value: 2880,
  },
  {
    labelValueKey: 'count',
    labelValue: 7,
    labelKey: 'Shared.Day',
    value: 10080,
  },
];

// all durations
const consultationDurationOptionsType1 = [
  {
    labelValueKey: 'count',
    labelValue: 12,
    labelKey: 'Shared.Minute',
    value: 12,
  },
  {
    labelValueKey: 'count',
    labelValue: 40,
    labelKey: 'Shared.Minute',
    value: 40,
  },
  {
    labelValueKey: 'count',
    labelValue: 45,
    labelKey: 'Shared.Minute',
    value: 45,
  },
];

// 45 or 12 minutes duration
const consultationDurationOptionsType2 =
  consultationDurationOptionsType1.filter((option) => option.labelValue !== 40);

// 40 or 12 minutes duration
const consultationDurationOptionsType3 =
  consultationDurationOptionsType1.filter((option) => option.labelValue !== 45);

// only 12 minutes duration
const consultationDurationOptionsType4 =
  consultationDurationOptionsType1.filter((option) => option.labelValue === 12);

const dutySlotDurationOptions = [
  { labelValueKey: 'count', labelValue: 1, labelKey: 'Shared.Hour', value: 60 },
  {
    labelValueKey: 'count',
    labelValue: 2,
    labelKey: 'Shared.Hour',
    value: 120,
  },
  {
    labelValueKey: 'count',
    labelValue: 3,
    labelKey: 'Shared.Hour',
    value: 180,
  },
  {
    labelValueKey: 'count',
    labelValue: 4,
    labelKey: 'Shared.Hour',
    value: 240,
  },
  {
    labelValueKey: 'count',
    labelValue: 5,
    labelKey: 'Shared.Hour',
    value: 300,
  },
  {
    labelValueKey: 'count',
    labelValue: 6,
    labelKey: 'Shared.Hour',
    value: 360,
  },
];

const futureLimitOptions = [
  { labelValueKey: 'count', labelValue: 1, labelKey: 'Shared.Day', value: 1 },
  { labelValueKey: 'count', labelValue: 2, labelKey: 'Shared.Day', value: 2 },
  { labelValueKey: 'count', labelValue: 3, labelKey: 'Shared.Day', value: 3 },
  { labelValueKey: 'count', labelValue: 4, labelKey: 'Shared.Day', value: 4 },
  { labelValueKey: 'count', labelValue: 5, labelKey: 'Shared.Day', value: 5 },
  { labelValueKey: 'count', labelValue: 6, labelKey: 'Shared.Day', value: 6 },
  { labelValueKey: 'count', labelValue: 7, labelKey: 'Shared.Day', value: 7 },
  { labelValueKey: 'count', labelValue: 8, labelKey: 'Shared.Day', value: 8 },
  { labelValueKey: 'count', labelValue: 9, labelKey: 'Shared.Day', value: 9 },
  { labelValueKey: 'count', labelValue: 10, labelKey: 'Shared.Day', value: 10 },
  { labelValueKey: 'count', labelValue: 60, labelKey: 'Shared.Day', value: 60 },
];

export {
  channelOptions,
  minBookingNoticeOptions,
  consultationDurationOptionsType1,
  consultationDurationOptionsType2,
  consultationDurationOptionsType3,
  consultationDurationOptionsType4,
  dutySlotDurationOptions,
  futureLimitOptions,
};
