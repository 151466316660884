import { defineMessages } from 'react-intl';

import { generateTranslationsObject } from 'utils';

const prefix = 'Screens.FileImportConfigs.';
const keys = [
    'list.title',
    'list.create',
    'list.partner',
    'list.directory',
    'list.status',
    'create.title',
    'detail.title',
    'detail.edit',
    'detail.partner',
    'detail.directory',
    'detail.status',
    'detail.email',
    'detail.update',
    'validation.partner',
    'validation.email.format',
    'validation.email',
    'validation.directory',
    'toast.add.success.header',
    'toast.add.success.body',
    'toast.add.error.header',
    'toast.add.error.body',
    'toast.edit.success.header',
    'toast.edit.success.body',
    'toast.edit.error.header',
    'toast.edit.error.body',
];

export default defineMessages(
    generateTranslationsObject(prefix, keys, {}),
);
