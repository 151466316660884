import React from 'react';
import { Switch } from 'react-router-dom';
import { urlSchema } from 'utils';

import { ProtectedRoute } from 'components';

// Screens
import Create from './Create';
import EditProfiles from './EditProfiles';
import EditSpecialties from './EditSpecialties';
import List from './List';
import Read from './Read';
import Update from './Update';

const routes = [
  {
    path: `${urlSchema.doctors.read}/:idUser`,
    component: Read,
    right: 'Reader',
  },
  {
    path: `${urlSchema.doctors.update}/:idUser`,
    component: Update,
    right: 'CRUD',
  },
  {
    path: urlSchema.doctors.create,
    component: Create,
    right: 'CRUD',
  },
  {
    path: `${urlSchema.doctors.editProfiles}/:idUser`,
    component: EditProfiles,
    right: 'CRUD',
  },
  {
    path: `${urlSchema.doctors.editSpecialties}/:idUser`,
    component: EditSpecialties,
    right: 'CRUD',
  },
  {
    path: '/',
    component: List,
    right: 'Reader',
  },
];

const Doctors = () => {
  return (
    <Switch>
      {routes.map((item) => (
        <ProtectedRoute entity='Doctors' key={item.path} {...item} />
      ))}
    </Switch>
  );
};

export default Doctors;
