import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Badge, Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  Button,
  PageHeader,
  RoleManager,
  Section,
  SectionBody,
  SectionHeader,
  Spinner,
  LogoItem,
} from '../../../components';
import { service } from '../../../services';
import { api, urlSchema, useFetch, sendToast } from '../../../utils';
import { AccountInformation } from './components/AccountInformation';
import { PersonalInformation } from './components/PersonalInformation';
import { ProfessionalInformation } from './components/ProfessionalInformation';
import { ToastTypes } from '../../../constants';
import messages from './messages';

const Read = () => {
  const intl = useIntl();
  const { idUser } = useParams();
  const [doctor, isDoctorLoading] = useFetch(api.doctors.getDetails(idUser));
  const [universes, areUniversesLoading] = useFetch(api.universes.getListItems);
  const [contratTypes, areContratTypesLoading] = useFetch(
    api.doctors.getContractTypes,
  );

  const forgotPassword = () => {
    service
      .put(api.doctors.forgotPassword(doctor.idUser))
      .then(() => {
        sendToast(ToastTypes.success, {
          header: <FormattedMessage {...messages.Success} />,
          body: <FormattedMessage {...messages.NewPasswordSuccess} />,
        });
      })
      .catch(() => {
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.Error} />,
          body: <FormattedMessage {...messages.NewPasswordError} />,
        });
      });
  };

  return (
    <>
      <PageHeader>
        <FormattedMessage
          {...messages.Title}
          tagName='h1'
          values={{
            name: `${doctor?.title} ${doctor?.firstname} ${doctor?.lastname}`,
          }}
        />
      </PageHeader>
      <Row>
        <Col md='8'>
          {isDoctorLoading || areUniversesLoading || areContratTypesLoading ? (
            <Spinner size='lg' />
          ) : (
            <Section>
              <SectionBody>
                <div className='model'>
                  <Row>
                    <Col md='5' className='text-center'>
                      <LogoItem />
                      <h1>
                        {doctor.title} {doctor.firstname} {doctor.lastname}
                      </h1>
                      <div className='model__actions'>
                        <Link
                          className='btn btn-outline-warning btn-sm ml-auto'
                          to={`${urlSchema.universes.read}/${doctor.refUniverse}`}
                        >
                          {
                            universes.find(
                              (element) => element.id === doctor.refUniverse,
                            ).label
                          }
                          (
                          {
                            universes.find(
                              (element) => element.id === doctor.refUniverse,
                            ).code
                          }
                          )
                        </Link>
                      </div>
                      <div className='model__actions'>
                        <Badge color={doctor.valid ? 'success' : 'danger'}>
                          <div className='d-flex align-items-center'>
                            {doctor.valid ? (
                              <>
                                <span className='mr-1'>
                                  <FormattedMessage {...messages.Valid} />
                                </span>
                                <i
                                  aria-hidden
                                  className='uil uil-check-circle'
                                />
                              </>
                            ) : (
                              <>
                                <span className='mr-1'>
                                  <FormattedMessage {...messages.Invalid} />
                                </span>
                                <i
                                  aria-hidden
                                  className='uil uil-times-circle'
                                />
                              </>
                            )}
                          </div>
                        </Badge>
                        <Badge color={doctor.available ? 'success' : 'danger'}>
                          <div className='d-flex align-items-center'>
                            {doctor.available ? (
                              <>
                                <span className='mr-1'>
                                  <FormattedMessage {...messages.Available} />
                                </span>
                                <i
                                  aria-hidden
                                  className='uil uil-check-circle'
                                />
                              </>
                            ) : (
                              <>
                                <span className='mr-1'>
                                  <FormattedMessage {...messages.Unavailable} />
                                </span>
                                <i
                                  aria-hidden
                                  className='uil uil-times-circle'
                                />
                              </>
                            )}
                          </div>
                        </Badge>
                      </div>
                      <div className='model__actions'>
                        <Button
                          onClick={() => forgotPassword()}
                          outline
                          size='sm'
                          color='primary'
                          label='GeneratePassword'
                        />
                        <Link
                          className='btn btn-outline-primary btn-sm ml-auto'
                          aria-label={intl.formatMessage({
                            ...messages.ButtonsEdit,
                          })}
                          title={intl.formatMessage({
                            ...messages.ButtonsEdit,
                          })}
                          to={`${urlSchema.doctors.update}/${doctor.idUser}`}
                        >
                          <FormattedMessage {...messages.ButtonsEdit} />
                        </Link>
                      </div>
                    </Col>
                    <Col md='7'>
                      <PersonalInformation data={doctor} />
                      <AccountInformation data={doctor} />
                      <ProfessionalInformation
                        {...{ contratTypes }}
                        data={doctor}
                      />
                    </Col>
                  </Row>
                </div>
              </SectionBody>
            </Section>
          )}
        </Col>
        <Col md='4'>
          <Section>
            <SectionHeader>
              <>
                <FormattedMessage {...messages.Specialties} tagName='h2' />
                <RoleManager entity='Doctors' right='CRUD'>
                  <Link
                    className='btn btn-outline-primary btn-sm ml-auto'
                    aria-label={intl.formatMessage({
                      ...messages.ButtonsManage,
                    })}
                    title={intl.formatMessage({
                      ...messages.ButtonsManage,
                    })}
                    to={`${urlSchema.doctors.editSpecialties}/${doctor.idUser}`}
                  >
                    <FormattedMessage {...messages.ButtonsManage} />
                  </Link>
                </RoleManager>
              </>
            </SectionHeader>
            <SectionBody>
              {doctor.specialties ? (
                doctor.specialties.map((specialty) => (
                  <Link
                    className='btn btn-outline-warning btn-sm mr-2 mb-2'
                    key={specialty.idSpecialty}
                    to={`${urlSchema.specialties.read}/${specialty.idSpecialty}`}
                  >
                    {specialty.label}
                  </Link>
                ))
              ) : (
                <FormattedMessage {...messages.NoElement} />
              )}
            </SectionBody>
          </Section>
          <Section>
            <SectionHeader>
              <>
                <FormattedMessage {...messages.Profiles} tagName='h2' />
                <RoleManager entity='Doctors' right='CRUD'>
                  <Link
                    className='btn btn-outline-primary btn-sm ml-auto'
                    aria-label={intl.formatMessage({
                      ...messages.ButtonsManage,
                    })}
                    title={intl.formatMessage({
                      ...messages.ButtonsManage,
                    })}
                    to={`${urlSchema.doctors.editProfiles}/${doctor.idUser}`}
                  >
                    <FormattedMessage {...messages.ButtonsManage} />
                  </Link>
                </RoleManager>
              </>
            </SectionHeader>
            <SectionBody>
              {doctor.profiles ? (
                doctor.profiles.map((profile) => (
                  <Link
                    className='btn btn-outline-warning btn-sm mr-2 mb-2'
                    key={profile.idProfile}
                    to={`${urlSchema.profiles.read}/${profile.idProfile}`}
                  >
                    {profile.label}
                  </Link>
                ))
              ) : (
                <FormattedMessage {...messages.NoElement} />
              )}
            </SectionBody>
          </Section>
        </Col>
      </Row>
    </>
  );
};

Read.displayName = 'Screens_Doctors_Read';

export default Read;
