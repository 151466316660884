import React from 'react';
import { useIntl } from 'react-intl';

import { FormInput } from 'components';
import messages from './messages';

const Organization = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <FormInput
        id='code'
        label={formatMessage(messages.Code)}
        name='code'
        required
        type='text'
      />
      <FormInput
        id='label'
        label={formatMessage(messages.Label)}
        name='label'
        required
        type='text'
      />
    </>
  );
};

export default Organization;
