import { defineMessages } from 'react-intl';

export default defineMessages({
  BirthDate: {
    id: 'Model.User.BirthDate',
    defaultMessage: 'Error.Model.User.BirthDate',
  },
  ButtonCheck: {
    id: 'Buttons.Check',
    defaultMessage: 'Error.Buttons.Check',
  },
  ButtonCheckEligibility: {
    id: 'Buttons.CheckEligibility',
    defaultMessage: 'Error.Buttons.CheckEligibility',
  },
  ButtonUnfold: {
    id: 'Buttons.Unfold',
    defaultMessage: 'Error.Buttons.Unfold',
  },
  FirstName: {
    id: 'Model.User.FirstName',
    defaultMessage: 'Error.Model.User.FirstName',
  },
  IsAllowed: {
    id: 'Screens.WebServiceItem.Status.IsAllowed',
    defaultMessage: 'Error.Screens.WebServiceItem.Status.IsAllowed',
  },
  IsNotAllowed: {
    id: 'Screens.WebServiceItem.Status.IsNotAllowed',
    defaultMessage: 'Error.Screens.WebServiceItem.Status.IsNotAllowed',
  },
  IsNotRecognized: {
    id: 'Screens.WebServiceItem.Status.IsNotRecognized',
    defaultMessage: 'Error.Screens.WebServiceItem.Status.IsNotRecognized',
  },
  IsRecognized: {
    id: 'Screens.WebServiceItem.Status.IsRecognized',
    defaultMessage: 'Error.Screens.WebServiceItem.Status.IsRecognized',
  },
  LastName: {
    id: 'Model.User.LastName',
    defaultMessage: 'Error.Model.User.LastName',
  },
  MembershipCode: {
    id: 'Model.User.MembershipCode',
    defaultMessage: 'Error.Model.User.MembershipCode',
  },
  Message: {
    id: 'Screens.WebServiceItem.Message',
    defaultMessage: 'Error.Screens.WebServiceItem.Message',
  },
  Response: {
    id: 'Screens.WebServiceItem.Response',
    defaultMessage: 'Error.Screens.WebServiceItem.Response',
  },
  ResponseTime: {
    id: 'Screens.WebServiceItem.ResponseTime',
    defaultMessage: 'Error.Screens.WebServiceItem.ResponseTime',
  },
  Status: {
    id: 'Screens.WebServiceItem.Status',
    defaultMessage: 'Error.Screens.WebServiceItem.Status',
  },
  URL: {
    id: 'Screens.WebServiceItem.URL',
    defaultMessage: 'Error.Screens.WebServiceItem.URL',
  },
});
