import styled, { css } from 'styled-components';

const heightHeaderMobile = '56px';

const SidebarWrapper = styled.nav`
  ${({ theme }) => css`
    background-color: ${theme.black};

    ul {
      list-style-type: none;
    }

    @media ${theme.screenMdMin} {
      display: flex;
      flex-direction: column;
      padding: 0;
      min-width: ${theme.sidebarWidth};
      width: ${theme.sidebarWidth};
      height: 100%;
    }
  `}
`;

const Menu = styled.ul`
  ${({ isOpen, theme }) => css`
    flex-grow: 1;
    padding: 0 ${theme.space(4)};
    max-height: calc(100vh - 175px);
    overflow-y: auto;

    @media ${theme.screenMdMax} {
      background-color: ${theme.black};
      position: fixed;
      bottom: ${isOpen ? 0 : heightHeaderMobile};
      left: 0;
      margin: 0;
      max-height: ${isOpen ? 'auto' : 0};
      overflow-y: auto;
      right: 0;
      top: ${heightHeaderMobile};
      z-index: ${theme.zIndex('fixed')};

      & > li {
        opacity: ${isOpen ? 1 : 0};
        transform: ${isOpen ? 'translateY(0)' : 'translateY(-5px)'};
      }
    }

    @media ${theme.screenMdMin} {
      padding: 0;
    }
  `}
`;

const Hamburger = styled.button.attrs({
  type: 'button',
})`
  ${({ isOpen, theme }) => css`
    background-color: transparent;
    border: 0;
    padding: 0;
    width: 32px;

    span {
      background-color: ${theme.color('black-lightest')};
      display: block;
      height: 2px;
      margin: 3px 0;
      width: 100%;
      transition: ${theme.transitionBase};

      &:first-child {
        ${isOpen && 'transform: translateY(5px); opacity: 0;'}
      }

      &:last-child {
        ${isOpen && 'transform: translateY(-5px); opacity: 0'}
      }
    }
  `}
`;

const Header = styled.header`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.color('black-border')};
    padding: 0 ${theme.space(3)};
    text-align: center;

    img {
      max-width: 200px;
    }

    @media ${theme.screenMdMax} {
      align-items: center;
      display: flex;
      height: ${heightHeaderMobile};
      justify-content: space-between;
    }

    @media ${theme.screenMdMin} {
      padding: ${theme.space(4)} ${theme.space(2)};
    }
  `}
`;

const UserWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.black};
    border-top: 1px solid ${theme.color('black-border')};
    color: ${theme.color('black-lightest')};
    padding: ${theme.space(3)};
    position: fixed;
    bottom: 0;

    @media ${theme.screenMdMin} {
      width: ${theme.sidebarWidth};
    }

    div {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    h3 {
      ${theme.fontSize(theme.fontSizeLg(), theme.fontWeightBold)};
      margin: 0;
      padding: 0 ${theme.space(3)} 0 0;
    }

    p {
      ${theme.fontSize(theme.fontSizeSm())};
      margin: 0;
    }
  `}
`;

const LogoutButtonWrapper = styled.button.attrs({ type: 'button' })`
  ${({ theme }) => css`
    background-color: transparent;
    border: 0;
    color: ${theme.color('black-lightest')};
    padding: 0;

    &:hover,
    &:active {
      color: ${theme.white};
    }
  `}
`;

SidebarWrapper.Hamburger = Hamburger;
SidebarWrapper.Header = Header;
SidebarWrapper.Menu = Menu;
SidebarWrapper.UserWrapper = UserWrapper;

export { LogoutButtonWrapper, SidebarWrapper };
