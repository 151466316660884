const CodeDialogueAction = {
  CALLFAILED: 'CALLFAILED',
  CALLSUCCESS: 'CALLSUCCESS',
  CALLMEAT: 'CALLMEAT',
  CLOSE: 'CLOSE',
  COMMENT: 'COMMENT',
  CONSENT: 'CONSENT',
  INPROGRESS: 'INPROGRESS',
  QUESTION: 'QUESTION',
  REOPEN: 'REOPEN',
  RESPONSE: 'RESPONSE',
  RESPONSETEMP: 'RESPONSETEMP',
  TRANSFER: 'TRANSFER',
  SURVEY: 'SURVEY',
  VISIOEND: 'VISIOEND',
  VISIOINVITATION: 'VISIOINVITATION',
  VISIOPATIENTIN: 'VISIOPATIENTIN',
  APPOINTMENTMADE: 'APPOINTMENTMADE',
  APPOINTMENTCANCELLED: 'APPOINTMENTCANCELLED',
  COMMENTOPERATOR: 'COMMENTOPERATOR',
};

export default CodeDialogueAction;
