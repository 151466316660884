/* eslint-disable unicorn/filename-case */
import { api } from '../../../utils';
import { service } from '../../index';
import { GenericErrorResponse } from '../../types';

export type Response = {
  requestBodyRaw: string;
  responseBodyRaw: string;
};

export const getNHIPatientWebServiceLogDetail = async (
  webServiceLogId: number,
): Promise<Response> => {
  try {
    const { data } = await service.get<GenericErrorResponse | Response>(
      api.manager.getNHIPatientWebServiceLogDetail(webServiceLogId),
    );

    if (data && 'succeeded' in data && data.succeeded === false) {
      throw new Error(data.errorSet[0].code);
    }

    return data as Response;
  } catch (error) {
    throw new Error(error.message);
  }
};
