import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { ThemeContext } from 'styled-components';

import { message } from 'models/TemplateMessage';

export const IFrame = observer(() => {
  const theme = useContext(ThemeContext);

  return (
    <iframe
      id='template-message'
      srcDoc={message.get()}
      style={{
        border: `1px solid ${theme.color('border')}`,
        height: '100%',
        width: '100%',
      }}
      title='Template message'
    />
  );
});
