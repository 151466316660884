import { defineMessages } from 'react-intl';

import { generateTranslationsObject } from 'utils';

const prefix = 'Model.WebServiceConfiguration.';
const keys = [
  'AuthentificationLogin',
  'AuthentificationPassword',
  'Certificate',
  'CertificatePassword',
  'CodeKo',
  'CodeOk',
  'CodeResigned',
  'EndPoint',
  'Name',
  'RequestType',
  'UserAgentCode',
  'UserBirthDate',
  'UserFirstName',
  'UserLastName',
  'UserMembershipCode',
  'UserTest',
];
const additionalKeys = {
  Title: { id: '', defaultMessage: '' },
  FieldMaxLength: {
    id: 'Shared.FieldMaxLength',
    defaultMessage: 'Error.Shared.FieldMaxLength',
  },
  FieldRequired: {
    id: 'Shared.FieldRequired',
    defaultMessage: 'Error.Shared.FieldRequired',
  },
};

export default defineMessages(
  generateTranslationsObject(prefix, keys, additionalKeys),
);
