import { defineMessages } from 'react-intl';

export default defineMessages({
  Code: {
    id: 'Universes.Code',
    defaultMessage: 'Error.Universes.Code',
  },
  Label: {
    id: 'Universes.Label',
    defaultMessage: 'Error.Universes.Label',
  },
});
