import styled, { css } from 'styled-components';
import { ellipsis } from 'polished';

const LinksListWrapper = styled.div`
  ${({ theme }) => css`
    .dropdown-menu {
      max-height: 240px;
      max-width: 200px;
      overflow-y: auto;
    }

    .dropdown-item {
      ${ellipsis('100%')};
    }
  `}
`;

const LinkWrapper = styled.a`
  ${({ linkMaxWidth, theme }) => css`
    display: inline-block;
    max-width: ${linkMaxWidth || `calc(100% - ${theme.space(1)})`};
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
    white-space: nowrap;
    ${theme.fontSize(theme.fontSizeSm(), theme.fontWeightBold)};
  `}
`;

LinksListWrapper.Link = LinkWrapper;

export default LinksListWrapper;
