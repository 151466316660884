import styled from 'styled-components';

const FormInputFeedbackWrapper = styled.p`
  ${({ theme }) => `
    ${theme.fontSize(theme.fontSizeSm())};
    color: ${theme.color('danger')};

    &::first-letter {
      text-transform: uppercase;
    }

    &&& {
      margin-bottom: 0;
    }
  `}
`;

export default FormInputFeedbackWrapper;
