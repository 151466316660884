import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Badge, Col, Row } from 'reactstrap';
import { matchShape } from 'react-router-props';

import { LinksList, PageHeader, RoleManager, Spinner } from 'components';

import { api, urlSchema, useFetch } from 'utils';
import messages from './messages';

const Read = ({ match }) => {
  const { formatMessage } = useIntl();
  const [application, isApplicationLoading] = useFetch(
    api.admin.getApplication(match.params.idApplication),
  );

  return (
    <>
      <PageHeader>
        <FormattedMessage
          {...messages.DetailsOf}
          values={{ applicationLabel: application.label }}
          tagName='h1'
        />
        <RoleManager entity='Applications' right='CRUD'>
          <Link
            className='btn btn-primary'
            to={`${urlSchema.applications.update}/${match.params.idApplication}`}
          >
            {formatMessage(messages.ButtonEdit)}
          </Link>
        </RoleManager>
      </PageHeader>
      {isApplicationLoading ? (
        <Spinner size='lg' />
      ) : (
        <Row>
          <Col md='8'>
            <section className='section'>
              <div className='section__body'>
                <div className='model'>
                  <div className='model__actions'>
                    <h3>
                      <Badge color={application.valid ? 'success' : 'danger'}>
                        {application.valid ? (
                          <FormattedMessage {...messages.Valid} />
                        ) : (
                          <FormattedMessage {...messages.Inactive} />
                        )}
                      </Badge>
                    </h3>
                  </div>
                  <Row>
                    <Col md='7'>
                      <div className='model__property'>
                        <h4 className='model__title'>
                          <FormattedMessage {...messages.Code} />
                        </h4>
                        <p className='model__value'>{application.code}</p>
                      </div>
                    </Col>
                    <Col md='7'>
                      <div className='model__property'>
                        <h4 className='model__title'>
                          <FormattedMessage {...messages.Label} />
                        </h4>
                        <p className='model__value'>{application.label}</p>
                      </div>
                    </Col>
                    <Col md='7'>
                      <div className='model__property'>
                        <h4 className='model__title'>
                          <FormattedMessage {...messages.Url} />
                        </h4>
                        <p className='model__value'>
                          <a
                            href={application.url}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            {application.url}
                          </a>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </section>
          </Col>
          <Col md='4'>
            <section className='section'>
              <header className='section__header'>
                <FormattedMessage {...messages.Profiles} tagName='h2' />
                <Link
                  className='btn btn-outline-primary btn-sm ml-auto'
                  to={`${urlSchema.applications.editProfiles}/${match.params.idApplication}
                  `}
                >
                  <FormattedMessage {...messages.ButtonManageProfiles} />
                </Link>
              </header>
              <div className='section__body'>
                {application.applicationsProfiles.length > 0 ? (
                  <LinksList
                    areInnerLinks
                    color='warning'
                    data={application.applicationsProfiles}
                    idKey={(el) => el.refProfile}
                    labelKey={(el) => el.profileCode}
                    urlBase={urlSchema.profiles.read}
                  />
                ) : (
                  <p className='text-secondary m-0'>
                    <FormattedMessage {...messages.NoElement} />
                  </p>
                )}
              </div>
            </section>
          </Col>
        </Row>
      )}
    </>
  );
};

Read.propTypes = { match: matchShape.isRequired };

export default Read;
