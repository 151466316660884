import * as yup from 'yup';

import messages from './messages';

export default (formatMessage) =>
  yup.object().shape({
    authentificationLogin: yup
      .string()
      .nullable()
      .max(100, formatMessage({ ...messages.FieldMaxLength }, { number: 100 })),
    authentificationPassword: yup
      .string()
      .nullable()
      .max(200, formatMessage({ ...messages.FieldMaxLength }, { number: 200 })),
    certificatePassword: yup
      .string()
      .nullable()
      .max(255, formatMessage({ ...messages.FieldMaxLength }, { number: 255 })),
    codeKo: yup
      .string()
      .nullable()
      .max(50, formatMessage({ ...messages.FieldMaxLength }, { number: 50 })),
    codeOk: yup
      .string()
      .nullable()
      .max(50, formatMessage({ ...messages.FieldMaxLength }, { number: 50 })),
    codeResigned: yup
      .string()
      .nullable()
      .max(50, formatMessage({ ...messages.FieldMaxLength }, { number: 50 })),
    endPoint: yup
      .string()
      .max(512, formatMessage({ ...messages.FieldMaxLength }, { number: 512 }))
      .required(formatMessage({ ...messages.FieldRequired })),
    name: yup
      .string()
      .max(50, formatMessage({ ...messages.FieldMaxLength }, { number: 50 }))
      .required(formatMessage({ ...messages.FieldRequired })),
    requestType: yup
      .string()
      .nullable()
      .max(50, formatMessage({ ...messages.FieldMaxLength }, { number: 50 })),
    userAgentCode: yup
      .string()
      .nullable()
      .max(50, formatMessage({ ...messages.FieldMaxLength }, { number: 50 })),
    userFirstName: yup
      .string()
      .nullable()
      .max(50, formatMessage({ ...messages.FieldMaxLength }, { number: 50 })),
    userLastName: yup
      .string()
      .nullable()
      .max(50, formatMessage({ ...messages.FieldMaxLength }, { number: 50 })),
    userMembershipCode: yup
      .string()
      .nullable()
      .max(50, formatMessage({ ...messages.FieldMaxLength }, { number: 50 })),
  });
