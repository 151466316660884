import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Field, Form, Formik } from 'formik';
import qs from 'qs';

import {
  Button,
  Spinner,
  Pagination,
  Translation,
  RoleManager,
} from '../../../components';
import { service } from '../../../services';
import { ListWrapper } from '../../../themes/jupiter/elements';
import { api, removeKeysWithEmptyString } from '../../../utils';
import PatientNhiListItem from './PatientNhiListItem';

const PatientsNhiList = () => {
  const location = useLocation();
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [patients, setPatients] = useState([]);

  const onSubmit = (values = {}) => {
    try {
      const parameters = qs.stringify(removeKeysWithEmptyString(values), {
        skipNulls: true,
      });
      setIsLoading(true);
      service
        .get(`${api.admin.getNHI}?${parameters}`)
        .then(({ data }) => {
          setPatients(data);
          setIsLoading(false);
          window.history.pushState(
            {},
            undefined,
            `${location.pathname}?${parameters}`,
          );
        })
        .catch((error) => {
          console.log('Error fetching data:', error.message || error);
          setIsLoading(false);
        });
    } catch (error) {
      console.log('Unexpected error:', error.message || error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (location.search) {
      onSubmit(qs.parse(location.search, { ignoreQueryPrefix: true }));
    }
  }, [location.search]);

  return (
    <RoleManager entity='NHIs' right='Reader'>
      <ListWrapper>
        <ListWrapper.ResearchWrapper>
          <Formik
            initialValues={{
              FirstName: '',
              LastName: '',
              BirthDate: '',
              NHIRegistrationNumber: '',
            }}
            onSubmit={onSubmit}
          >
            {({ resetForm }) => (
              <Form>
                <div className='row my-3'>
                  <div className='col-lg-2'>
                    <Field
                      className='form-control'
                      name='NHIRegistrationNumber'
                      placeholder={formatMessage({
                        id: 'Patients.NHIRegistrationNumber',
                      })}
                      type='text'
                    />
                  </div>
                  <div className='col-lg-2'>
                    <Field
                      className='form-control'
                      name='BirthDate'
                      placeholder={formatMessage({
                        id: 'Patients.BirthDate',
                      })}
                      type='date'
                    />
                  </div>
                  <div className='col-lg-2'>
                    <Field
                      className='form-control'
                      name='FirstName'
                      placeholder={formatMessage({
                        id: 'Patients.FirstName',
                      })}
                      type='text'
                    />
                  </div>
                  <div className='col-lg-2'>
                    <Field
                      className='form-control'
                      name='LastName'
                      placeholder={formatMessage({
                        id: 'Patients.LastName',
                      })}
                      type='text'
                    />
                  </div>
                  <div className='d-flex gap-3 ml-auto text-right mr-3'>
                    <button
                      className='btn btn-outline-primary mr-2'
                      data-tracking='PatientsListBtnReset'
                      onClick={() => {
                        resetForm();
                      }}
                      type='button'
                    >
                      <Translation id='Buttons.Reset' />
                    </button>
                    <Button
                      className='text-ellipsis'
                      color='primary'
                      data-tracking='PatientsListBtnSearch'
                      isProcessing={isLoading}
                      label='Research'
                      type='submit'
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </ListWrapper.ResearchWrapper>
        <ListWrapper.Header>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-2 d-block'>
                <Translation id='Patients.Nhis.Firstname' />
              </div>
              <div className='col-md-2 d-block'>
                <Translation id='Patients.Nhis.BirthName' />
              </div>
              <div className='col-md-2 d-none d-lg-block'>
                <Translation id='Patients.Nhis.UsedFirstName' />
              </div>
              <div className='col-md-2 d-none d-lg-block'>
                <Translation id='Patients.Nhis.UsedLastName' />
              </div>
              <div className='col-md-2 d-block'>
                <Translation id='Patients.BirthDate' />
              </div>
              <div className='col-md-1 d-block d-lg-block'>
                <Translation id='Shared.Gender' />
              </div>
            </div>
          </div>
        </ListWrapper.Header>
        {isLoading ? (
          <Spinner size='lg' />
        ) : (
          <Pagination
            items={patients}
            itemsPerPage={10}
            renderItem={(patient) => <PatientNhiListItem data={patient} />}
          />
        )}
      </ListWrapper>
    </RoleManager>
  );
};

export default PatientsNhiList;
