import React from 'react';
import { Formik, Form } from 'formik';
import { Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import { ProductTermsResource } from 'models';
import { Button, PageHeader, Spinner } from 'components';
import { service } from 'services';
import { api, urlSchema, sendToast, useFetch } from 'utils';
import { ToastTypes } from '../../../constants';
import validationSchema from './validationSchema';
import messages from './messages';

const Edit = ({ history, match }) => {
  const { params } = match;
  const [productTermsResource, isProductTermsResourceLoading] = useFetch(
    api.manager.getProductTermsResources(params.idProductTermResource),
  );

  const onSubmit = (values, { setSubmitting }) => {
    service
      .put(api.manager.putProductTermsResources, values)
      .then((response) => {
        if (Boolean(response.data) && response.data.errorSet.length) {
          if (
            response.data.errorSet[0].code ===
            'ProductTermsResourcesForThisLanguageAlreadyExist'
          ) {
            sendToast(ToastTypes.error, {
              header: <FormattedMessage {...messages.FailTitle} />,
              body: <FormattedMessage {...messages.AlreadyExist} />,
            });
            return;
          }
        }

        sendToast(ToastTypes.success, {
          header: <FormattedMessage {...messages.SuccessTitle} />,
          body: <FormattedMessage {...messages.SuccessBody} />,
        });
        history.push(
          `${urlSchema.productTermsResources.read}/${params.idProductTermResource}`,
        );
      })
      .catch(() => {
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.FailTitle} />,
          body: <FormattedMessage {...messages.ErrorBody} />,
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <FormattedMessage {...messages.Title} tagName='h1' />
      </PageHeader>
      {isProductTermsResourceLoading ? (
        <Spinner color='primary' size='lg' />
      ) : (
        <Row>
          <Col md='8'>
            <section className='section'>
              <div className='section__body'>
                <Formik
                  initialValues={{
                    ...productTermsResource,
                    idProductTermsResource:
                      productTermsResource.idProductTermsResource,
                    refProductTerm: productTermsResource.refProductTerm,
                    codeLanguage: productTermsResource.codeLanguage,
                    terms: productTermsResource.terms,
                  }}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  {({ isSubmitting, ...formikProps }) => (
                    <Form>
                      <ProductTermsResource {...formikProps} />
                      <div className='text-right'>
                        <Button
                          color='primary'
                          disabled={isSubmitting}
                          isProcessing={isSubmitting}
                          label='Update'
                          size='lg'
                          type='submit'
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </section>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Edit;
