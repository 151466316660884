import { useState, useEffect } from 'react';
import { Field, FieldArray, Form, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import ChannelCode from 'constants/ChannelCode';
import { FormSelect } from 'components';

import {
  channelOptions,
  consultationDurationOptionsType1,
  consultationDurationOptionsType2,
  consultationDurationOptionsType3,
  consultationDurationOptionsType4,
  dutySlotDurationOptions,
  minBookingNoticeOptions,
  futureLimitOptions,
} from './options';

import CreateRoomsWrapper from './elements';
import messages from './messages';
import DeleteRoomModal from './DeleteRoomModal';
import AddRoomForm from './AddRoomForm';

const CreateRoomForm = () => {
  const { errors, touched, values, setFieldTouched, setFieldValue } =
    useFormikContext();
  const { formatMessage } = useIntl();
  const [activeChannels, setActiveChannels] = useState([]);
  const [consultationDurationOptions, setConsultationDurationOptions] =
    useState([]);

  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    channel: 'Messaging',
    callback: () => null,
  });

  const deleteItem = () => {
    modalInfo.callback();
    setModalInfo({ isOpen: false });
  };

  const getError = (index, name) => {
    let error = '';
    if (!touched.rooms || !errors.rooms) return;
    if (!errors.rooms[index]) return;
    if (errors.rooms[index][name]) {
      error = errors.rooms[index][name];
    }
    return error;
  };

  useEffect(() => {
    const channels = values.rooms.map((room) => room.channel);
    setActiveChannels(channels);
  }, [values.rooms]);

  return (
    <>
      <FieldArray name='rooms'>
        {({ remove, unshift }) => (
          <Form>
            <AddRoomForm
              unshift={unshift}
              channelOptions={channelOptions}
              activeChannels={activeChannels}
              error={
                Boolean(touched.rooms) && typeof errors.rooms === 'string'
                  ? errors.rooms
                  : ''
              }
            />

            <CreateRoomsWrapper.RoomsList>
              {values.rooms.length > 0 &&
                values.rooms.map((room, index) => (
                  <CreateRoomsWrapper.RoomWrapper
                    lg='4'
                    key={`key-${room.channel}`}
                  >
                    <CreateRoomsWrapper.RoomHeader>
                      <FormattedMessage
                        id={`Constants.${room.channel}`}
                        defaultMessage={`Error.Constants.${room.channel}`}
                        tagName='p'
                      />
                      <button
                        type='button'
                        onClick={() =>
                          setModalInfo({
                            isOpen: true,
                            channel: room.channel,
                            callback: () => {
                              setFieldValue();
                              remove(index);
                            },
                          })
                        }
                        label='Delete'
                      >
                        <i
                          aria-hidden
                          className='uil uil-trash-alt uil--md text-danger'
                        />
                      </button>
                    </CreateRoomsWrapper.RoomHeader>
                    <CreateRoomsWrapper.RoomBody>
                      {room.channel !== ChannelCode.MESSAGING && (
                        <Field name={`rooms.${index}.futureLimit`}>
                          {({ field, meta, form }) => (
                            <FormSelect
                              meta={meta}
                              hasError={Boolean(touched.rooms && meta.error)}
                              errorMsg={getError(index, 'futureLimit')}
                              label={formatMessage({
                                ...messages.FutureLimit,
                              })}
                              name={field.name}
                              onBlur={() => setFieldTouched(field.name, true)}
                              onChange={(opt) =>
                                form.setFieldValue(field.name, opt.value)
                              }
                              options={futureLimitOptions}
                              placeholder={formatMessage({
                                ...messages.FutureLimit,
                              })}
                              hasTranslatedOptions
                              required
                            />
                          )}
                        </Field>
                      )}

                      <Field name={`rooms.${index}.dutySlotDuration`}>
                        {({ field, meta, form }) => (
                          <FormSelect
                            meta={meta}
                            hasError={Boolean(touched.rooms && meta.error)}
                            errorMsg={getError(index, 'dutySlotDuration')}
                            label={formatMessage({
                              ...messages.DutySlotDuration,
                            })}
                            name={field.name}
                            onBlur={() => setFieldTouched(field.name, true)}
                            onChange={(opt) => {
                              form.setFieldValue(field.name, opt.value);
                              form.setFieldValue(
                                `rooms.${index}.consultationDuration`,
                                0,
                              );
                              switch (opt.value) {
                                case 360:
                                  setConsultationDurationOptions(
                                    consultationDurationOptionsType1,
                                  );
                                  break;
                                case 180:
                                  setConsultationDurationOptions(
                                    consultationDurationOptionsType2,
                                  );
                                  break;
                                case 120:
                                case 240:
                                  setConsultationDurationOptions(
                                    consultationDurationOptionsType3,
                                  );
                                  break;
                                case 60:
                                case 300:
                                  setConsultationDurationOptions(
                                    consultationDurationOptionsType4,
                                  );
                                  break;
                                default:
                                  setConsultationDurationOptions(
                                    consultationDurationOptionsType1,
                                  );
                              }
                            }}
                            options={dutySlotDurationOptions}
                            placeholder={formatMessage({
                              ...messages.DutySlotDuration,
                            })}
                            hasTranslatedOptions
                            required
                          />
                        )}
                      </Field>
                      {room.channel !== ChannelCode.MESSAGING && (
                        <>
                          <Field name={`rooms.${index}.consultationDuration`}>
                            {({ field, meta, form }) => (
                              <FormSelect
                                meta={meta}
                                hasError={Boolean(touched.rooms && meta.error)}
                                errorMsg={getError(
                                  index,
                                  'consultationDuration',
                                )}
                                label={formatMessage({
                                  ...messages.ConsultationDuration,
                                })}
                                name={field.name}
                                onBlur={() => setFieldTouched(field.name, true)}
                                onChange={(opt) =>
                                  form.setFieldValue(field.name, opt.value)
                                }
                                options={consultationDurationOptions}
                                placeholder={formatMessage({
                                  ...messages.ConsultationDuration,
                                })}
                                hasTranslatedOptions
                                required
                              />
                            )}
                          </Field>
                          <Field name={`rooms.${index}.minBookingNotice`}>
                            {({ field, meta, form }) => (
                              <FormSelect
                                meta={meta}
                                hasError={Boolean(touched.rooms && meta.error)}
                                errorMsg={getError(index, 'minBookingNotice')}
                                label={formatMessage({
                                  ...messages.MinBookingNotice,
                                })}
                                name={field.name}
                                onBlur={() => setFieldTouched(field.name, true)}
                                onChange={(opt) =>
                                  form.setFieldValue(field.name, opt.value)
                                }
                                options={minBookingNoticeOptions}
                                placeholder={formatMessage({
                                  ...messages.MinBookingNotice,
                                })}
                                hasTranslatedOptions
                                required
                              />
                            )}
                          </Field>
                        </>
                      )}
                    </CreateRoomsWrapper.RoomBody>
                  </CreateRoomsWrapper.RoomWrapper>
                ))}
            </CreateRoomsWrapper.RoomsList>
          </Form>
        )}
      </FieldArray>
      <DeleteRoomModal
        offerName={values.name}
        modalInfo={modalInfo}
        setModalInfo={setModalInfo}
        deleteItem={deleteItem}
      />
    </>
  );
};

CreateRoomForm.propTypes = {
  errors: PropTypes.shape().isRequired,
  touched: PropTypes.shape().isRequired,
  values: PropTypes.shape().isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default CreateRoomForm;
