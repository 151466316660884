import { defineMessages } from 'react-intl';

import { generateTranslationsObject } from 'utils';

const prefix = 'Screens.Profiles.Update.';
const keys = ['Title'];

const additionalKeys = {
  ButtonEditRoles: {
    id: 'Buttons.EditRoles',
    defaultMessage: 'Error.Buttons.EditRoles',
  },
  ToastFail: {
    id: 'Toasts.Profiles.Update.Error',
    defaultMessage: 'Error.Toasts.Profiles.Update.Error',
  },
  ToastSuccess: {
    id: 'Toasts.Profiles.Update.Success',
    defaultMessage: 'Error.Toasts.Profiles.Update.Success',
  },
};

export default defineMessages(
  generateTranslationsObject(prefix, keys, additionalKeys),
);
