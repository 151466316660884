import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonReset: {
    id: 'Buttons.Reset',
    defaultMessage: 'Error.Buttons.Reset',
    description: "Button to reset offer's rank"
  },
  Description: {
    id: 'Offers.Sort.Description',
    defaultMessage: 'Error.Offers.Sort.Description',
    description: 'Description of sortable offers'
  },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
    description: 'Toast title in case of Error'
  },
  FailBody: {
    id: 'Offers.Sort.FailBody',
    defaultMessage: 'Error.Offers.Sort.FailBody',
    description: 'Toast body in case of error'
  },
  SuccessTitle: {
    id: 'Message.Success',
    defaultMessage: 'Error.Message.Success',
    description: 'Toast title in case of success'
  },
  SuccessBody: {
    id: 'Offers.Sort.SuccessBody',
    defaultMessage: 'Error.Offers.Sort.SuccessBody',
    description: 'Toast body in case of success'
  },
  Title: {
    id: 'Offers.Sort.Title',
    defaultMessage: 'Error.Offers.Sort.Title',
    description: 'Offers sort page title'
  }
});
