import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import { FormattedDate, LinksList, RoleManager } from 'components';
import { urlSchema } from 'utils';
import { ListWrapper } from 'themes/jupiter/elements';

const PatientMdListItem = ({ data }) => {
  return (
    <Container fluid>
      <Row className='align-items-center'>
        <Col xs='5' lg='3'>
          <p className='m-0 text-dark'>
            {data.firstname} {data.lastname}
          </p>
          {data.email && (
            <a
              className='d-block d-lg-none text-secondary text-ellipsis'
              href={`mailto:${data.email}`}
            >
              <i className='align-middle uil uil-envelope mr-2' aria-hidden />
              <small>{data.email}</small>
            </a>
          )}
        </Col>
        <Col className='d-none d-lg-block' md='3'>
          {data.email && (
            <a
              className='d-block text-ellipsis text-secondary'
              href={`mailto:${data.email}`}
            >
              <i className='align-middle uil uil-envelope mr-2' aria-hidden />
              <small>{data.email}</small>
            </a>
          )}
          {data.mobileNumber && (
            <a
              className='d-block text-ellipsis text-secondary'
              href={`tel:${data.mobileNumber}`}
            >
              <i className='align-middle uil uil-phone mr-2' aria-hidden />
              <small>{data.mobileNumber}</small>
            </a>
          )}
        </Col>
        <Col className='d-none d-lg-block' md='2'>
          <p>
            <FormattedDate value={data.dateCreation} />
          </p>
        </Col>
        <Col xs='4' lg='2'>
          <LinksList
            color='warning'
            data={data.usersProducts}
            idKey={(element) => element.refProduct}
            labelKey={(element) => element.productTag}
            limit={5}
            linkMaxWidth='100%'
            target='_blank'
            urlBase={urlSchema.products.read}
          />
        </Col>
        <Col>
          <ListWrapper.Actions>
            <RoleManager entity='Patients' right='CRUD'>
              <Link
                data-tracking='PatientsListBtnRead'
                to={`${urlSchema.patients.update}/${data.idUser}`}
              >
                <i className='uil uil-pen uil--md' aria-hidden />
              </Link>
            </RoleManager>
            <RoleManager entity='Patients' right='Reader'>
              <Link
                data-tracking='PatientsListBtnRead'
                to={`${urlSchema.patients.readMd}/${data.idUser}`}
              >
                <i className='uil uil-eye uil--md' aria-hidden />
              </Link>
            </RoleManager>
          </ListWrapper.Actions>
        </Col>
      </Row>
    </Container>
  );
};

PatientMdListItem.defaultProps = { data: undefined };

PatientMdListItem.propTypes = {
  data: PropTypes.shape({
    dateCreation: PropTypes.string,
    email: PropTypes.string,
    firstname: PropTypes.string,
    idUser: PropTypes.string,
    lastname: PropTypes.string,
    mobileNumber: PropTypes.string,
    usersProducts: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default PatientMdListItem;
