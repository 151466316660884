import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row, Badge, Container } from 'reactstrap';
import { Link } from 'react-router-dom';

import { PageHeader, Spinner, LinksList, RoleManager } from 'components';
import { api, urlSchema, useFetch } from 'utils';
import { EntityStatus, EntityActions } from './EntitiesUtils';
import messages from './messages';

const Read = ({ match }) => {
  const intl = useIntl();
  const [updateProductStatus, setUpdateProductStatus] = useState(null);
  const { webServiceId } = match.params;
  const [webService, isWebServiceLoading] = useFetch(
    api.webServices.getWebService(webServiceId),
  );
  const [entityProducts, isEntityProductsLoading] = useFetch(
    api.webServices.getWebServiceEntityProducts(webServiceId),
    {},
    [updateProductStatus],
  );
  const [getAllConfig, isAllConfigLoading] = useFetch(
    api.webServices.getAllConfig,
  );

  const listEntityProducts = entityProducts.map((entity) => (
    <li key={entity.productCode}>
      <Container fluid>
        <Row>
          <Col md={3}>
            <h5 className='m-0'>{entity.productCode}</h5>
            <p className='text-secondary m-0 small'>{entity.productLabel}</p>
          </Col>
          <Col md={3}>{entity.externalEntityCode}</Col>
          <Col md={3}>{EntityStatus(entity.status)}</Col>
          <Col md={3}>
            {EntityActions({
              status: entity.status,
              productId: entity.productId,
              webServiceId,
              setUpdateProductStatus,
            })}
          </Col>
        </Row>
      </Container>
    </li>
  ));

  if (isWebServiceLoading) return <Spinner size='lg' />;

  return (
    <>
      <PageHeader>
        <h1>
          <FormattedMessage
            values={{ name: webService.name }}
            {...messages.Title}
          />
        </h1>
        <RoleManager entity='WebServices' right='CRUD'>
          <Link
            className='btn btn-primary'
            to={`${urlSchema.webServices.update}/${webServiceId}`}
          >
            {intl.formatMessage({
              ...messages.ButtonEdit,
            })}
          </Link>
        </RoleManager>
      </PageHeader>
      <Container fluid className='d-sm-flex flex-column'>
        <Row>
          <Col md='8'>
            <section className='section'>
              <div className='section__body'>
                {isWebServiceLoading ? (
                  <Spinner color='primary' size='lg' />
                ) : (
                  <Container>
                    <Row>
                      <Col md='12'>
                        <div className='model__property'>
                          <div className='model__actions d-flex justify-content-between'>
                            <Badge
                              className='align-self-start'
                              color={webService.isValid ? 'success' : 'danger'}
                            >
                              {webService.isValid ? (
                                <span className='d-flex align-items-center'>
                                  <i className='uil uil-check mr-1' />
                                  <FormattedMessage {...messages.IsValid} />
                                </span>
                              ) : (
                                <span className='d-flex align-items-center'>
                                  <i className='uil uil-times mr-1' />
                                  <FormattedMessage {...messages.IsNotValid} />
                                </span>
                              )}
                            </Badge>
                          </div>
                        </div>
                      </Col>
                      <Col md='6'>
                        <div className='model__property'>
                          <h3 className='model__title'>
                            <FormattedMessage {...messages.Name} />
                          </h3>
                          <p className='model__value'>{webService.name}</p>
                        </div>
                      </Col>
                      <Col md='6'>
                        <div className='model__property'>
                          <h3 className='model__title'>
                            <FormattedMessage {...messages.LastUpdate} />
                          </h3>
                          <p className='model__value'>
                            {webService.dateLastUpdate}
                          </p>
                          {webService.dateLastUpdate !== null ? (
                            <p>{webService.dateLastUpdate}</p>
                          ) : (
                            <p>
                              <FormattedMessage {...messages.NoLastUpdate} />
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col md='6'>
                        <div className='model__property'>
                          <h3 className='model__title'>
                            <FormattedMessage {...messages.ClassName} />
                          </h3>
                          <p className='model__value'>{webService.className}</p>
                        </div>
                      </Col>
                      <Col md='6'>
                        <div className='model__property'>
                          <h3 className='model__title'>
                            <FormattedMessage {...messages.Configuration} />
                          </h3>
                          {webService.webServiceConfig ? (
                            <Link
                              to={`${urlSchema.webServices.configuration.read}/${webService.webServiceConfig.idWebServiceConfig}`}
                              className='btn btn-outline-warning btn-sm'
                            >
                              {webService.webServiceConfig.name}
                            </Link>
                          ) : (
                            <p className='text-danger'>
                              <FormattedMessage {...messages.NoConfiguration} />
                            </p>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                )}
              </div>
            </section>
            <section className='section'>
              <div className='section__header'>
                <h1>
                  <FormattedMessage {...messages.LinkStatus} />
                </h1>
              </div>
              {isEntityProductsLoading ? (
                <Spinner color='primary' size='lg' />
              ) : (
                <div className='section__body'>
                  <header className='research-table__header'>
                    <Container fluid>
                      <Row>
                        <Col md={3}>
                          <FormattedMessage {...messages.LinkedProduct} />
                        </Col>
                        <Col md={3}>
                          <FormattedMessage {...messages.Entity} />
                        </Col>
                        <Col md={3}>
                          <FormattedMessage {...messages.Status} />
                        </Col>
                        <Col md={3}>
                          <FormattedMessage {...messages.Action} />
                        </Col>
                      </Row>
                    </Container>
                  </header>
                  {isEntityProductsLoading ? (
                    <Spinner color='primary' size='lg' />
                  ) : (
                    <ul className='research-table__results'>
                      {listEntityProducts}
                    </ul>
                  )}
                </div>
              )}
            </section>
          </Col>
          <Col md='4'>
            <section className='section'>
              <header className='section__header'>
                <h2>
                  <FormattedMessage {...messages.ConfigAvailable} />
                </h2>
                <Link
                  className='btn btn-outline-primary btn-sm ml-auto'
                  to={`${urlSchema.webServices.configuration.create}?webServiceId=${webServiceId}`}
                >
                  <FormattedMessage {...messages.ButtonAdd} />
                </Link>
              </header>
              <div className='section__body'>
                {isAllConfigLoading ? (
                  <Spinner color='primary' />
                ) : (
                  <div>
                    <LinksList
                      color='warning'
                      data={getAllConfig}
                      idKey={(el) => el.idWebServiceConfig}
                      labelKey={(el) => el.name}
                      urlBase={urlSchema.webServices.configuration.read}
                    />
                  </div>
                )}
              </div>
            </section>
          </Col>
        </Row>
      </Container>
    </>
  );
};

Read.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      webServiceId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Read;
