import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { Col, Row } from 'reactstrap';
import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import {
  PageHeader,
  RoleManager,
  SectionBody,
  SectionHeader,
  Spinner,
} from 'components';
import { service } from 'services';
import { api, urlSchema } from 'utils';
import messages from './messages';

const universeDetail = observable.box({
  clients: [],
  code: '',
  dateCreation: '',
  doctors: [],
  idUniverse: '',
  label: '',
  labelOrganizationConfig: '',
  refOrganizationConfig: '',
});

const componentDidMount = (universeId) =>
  service.get(api.admin.getUniverseById(universeId)).then((res) => {
    universeDetail.set(res.data);
  });

const Suspense = (props) =>
  universeDetail.get().code === '' ? (
    <Spinner color='primary' size={props.size} />
  ) : (
    props.children
  );

export const Read = observer(({ match }) => {
  const { universeId } = match.params;
  const {
    clients,
    code,
    dateCreation,
    doctors,
    label,
    labelOrganizationConfig,
    refOrganizationConfig,
    templateMessagesCount,
  } = universeDetail.get();

  const values = { ...{ code } };

  useEffect(() => {
    componentDidMount(universeId);
  }, []);

  return (
    <>
      <PageHeader>
        <Suspense size='sm'>
          <FormattedMessage tagName='h1' {...messages.Title} {...{ values }} />
          <RoleManager entity='Universes' right='CRUD'>
            <Link
              className='btn btn-primary'
              to={`${urlSchema.universes.update}/${universeId}`}
            >
              <FormattedMessage {...messages.ButtonEdit} />
            </Link>
          </RoleManager>
        </Suspense>
      </PageHeader>
      <Row>
        <Col lg='8'>
          <section className='section'>
            <Suspense size='lg'>
              <div className='section__body'>
                <div className='model'>
                  <Row>
                    <Col lg='6'>
                      <div className='model__property'>
                        <h4 className='model__title'>
                          <FormattedMessage {...messages.Code} />
                        </h4>
                        <p className='model__value'>{code}</p>
                      </div>
                      <div className='model__property'>
                        <h4 className='model__title'>
                          <FormattedMessage
                            {...messages.RefOrganizationConfig}
                          />
                        </h4>
                        <Link
                          className='model__value'
                          to={`${urlSchema.detailsOrganizationConfigs}/${refOrganizationConfig}`}
                        >
                          {labelOrganizationConfig}
                        </Link>
                      </div>
                    </Col>
                    <Col lg='6'>
                      <div className='model__property'>
                        <h4 className='model__title'>
                          <FormattedMessage {...messages.Label} />
                        </h4>
                        <p className='model__value'>{label}</p>
                      </div>
                      <div className='model__property'>
                        <h4 className='model__title'>
                          <FormattedMessage {...messages.CreatedAt} />
                        </h4>
                        <p className='model__value'>
                          <FormattedMessage
                            {...messages.CreationDate}
                            values={{
                              date: moment(dateCreation).format('LL'),
                            }}
                          />
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Suspense>
          </section>
        </Col>
        <Col lg='4'>
          <section className='section'>
            <Suspense size='md'>
              <SectionHeader>
                <FormattedMessage tagName='h2' {...messages.Customers} />
                <RoleManager entity='Clients' right='CRUD'>
                  <Link
                    className='btn btn-outline-primary btn-sm ml-auto'
                    to={`${urlSchema.clients.create}?refUniverse=${universeId}`}
                  >
                    <FormattedMessage {...messages.ButtonAdd} />
                  </Link>
                </RoleManager>
              </SectionHeader>
              <SectionBody>
                <RoleManager entity='Clients' right='Reader'>
                  <Link
                    className='btn btn-outline-primary btn-sm'
                    to={`${urlSchema.clients.list}?refUniverse=${universeId}`}
                  >
                    <FormattedMessage
                      {...messages.ReadCustomers}
                      values={{ nbClients: clients.length }}
                    />
                  </Link>
                </RoleManager>
              </SectionBody>
            </Suspense>
          </section>
          <section className='section'>
            <Suspense size='md'>
              <SectionHeader>
                <FormattedMessage tagName='h2' {...messages.Doctors} />
                <RoleManager entity='Doctors' right='CRUD'>
                  <Link
                    className='btn btn-outline-primary btn-sm ml-auto'
                    to={`${urlSchema.doctors.create}?refUniverse=${universeId}`}
                  >
                    <FormattedMessage {...messages.ButtonAdd} />
                  </Link>
                </RoleManager>
              </SectionHeader>
              <SectionBody>
                <RoleManager entity='Doctors' right='Reader'>
                  <Link
                    className='btn btn-outline-primary btn-sm'
                    to={`${urlSchema.doctors.list}?refUniverse=${universeId}`}
                  >
                    <FormattedMessage
                      {...messages.ReadDoctors}
                      values={{ nbDoctors: doctors.length }}
                    />
                  </Link>
                </RoleManager>
              </SectionBody>
            </Suspense>
          </section>
          <section className='section'>
            <header className='section__header'>
              <FormattedMessage {...messages.TemplateMessages} tagName='h2' />
              <RoleManager entity='TemplateMessages' right='CRUD'>
                <Link
                  className='btn btn-outline-primary btn-sm ml-auto'
                  to={`${urlSchema.templateMessages.create}?refOrganization=${universeId}`}
                >
                  <FormattedMessage {...messages.ButtonAdd} />
                </Link>
              </RoleManager>
            </header>
            <div className='section__body'>
              {templateMessagesCount > 0 ? (
                <RoleManager entity='TemplateMessages' right='Reader'>
                  <Link
                    className='btn btn-outline-primary btn-sm'
                    to={`${urlSchema.templateMessages.list}?refOrganization=${universeId}`}
                  >
                    <FormattedMessage
                      {...messages.ButtonReadTemplateMessages}
                    />{' '}
                    ({templateMessagesCount})
                  </Link>
                </RoleManager>
              ) : (
                <p className='text-secondary m-0'>
                  <FormattedMessage {...messages.NoElement} />
                </p>
              )}
            </div>
          </section>
        </Col>
      </Row>
    </>
  );
});
