import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useWindowSize } from 'react-use';
import { ModalBody, Button, ButtonGroup } from 'reactstrap';

import { service } from 'services';
import Spinner from '../Spinner';
import { ModalWrapper } from '../../themes/jupiter/elements';

const onePxTransparentPng =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUAAACnej3aAAAAAXRSTlMAQObYZgAAAApJREFUCNdjYAAAAAIAAeIhvDMAAAAASUVORK5CYII=';

const ImageModal = ({ options }) => {
  const intl = useIntl();
  const { fileName, source } = options;
  const [originalDimensions, setOriginalDimensions] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const { height } = useWindowSize();
  const { formatMessage } = intl;
  // Refs
  const imageRef = useRef(null);
  const imageWrapper = useRef(null);

  const getSizes = () => {
    if (originalDimensions && imageWrapper) {
      return {
        width: `${
          originalDimensions.width < imageWrapper.current.clientWidth
            ? originalDimensions.width * zoom
            : imageWrapper.current.clientWidth * zoom
        }px`,
      };
    }
    return;
  };

  useEffect(() => {
    if (!originalDimensions) {
      service.get(source, { responseType: 'blob' }).then((result) => {
        const blobUrl = URL.createObjectURL(result.data);
        imageRef.current.onload = function () {
          const imgHeight = this.height;
          const imgWidth = this.width;
          setOriginalDimensions({ height: imgHeight, width: imgWidth });
        };
        imageRef.current.src = blobUrl;
      });
    }
  }, [originalDimensions]);

  return (
    <ModalBody>
      {!originalDimensions && !imageWrapper ? (
        <Spinner />
      ) : (
        <>
          <div className='overflow-auto'>
            <ModalWrapper.ImageWrapper
              height={height * 0.6}
              ref={imageWrapper}
              rotation={rotation}
            >
              <img
                ref={imageRef}
                src={onePxTransparentPng}
                style={getSizes()}
                alt={fileName}
              />
            </ModalWrapper.ImageWrapper>
          </div>
          <ModalWrapper.Toolbar>
            <ButtonGroup>
              <Button
                aria-label={formatMessage({
                  id: 'Buttons.ZoomIn',
                  defaultMessage: 'Error.Buttons.ZoomIn',
                })}
                color='primary'
                onClick={() => setZoom(zoom + 0.25)}
                outline
              >
                <i aria-hidden className='uil uil-search-plus' />
              </Button>
              <Button
                aria-label={formatMessage({
                  id: 'Buttons.ZoomOut',
                  defaultMessage: 'Error.Buttons.ZoomOut',
                })}
                color='primary'
                onClick={() => {
                  if (zoom - 0.25 > 0) setZoom(zoom - 0.25);
                }}
                outline
              >
                <i aria-hidden className='uil uil-search-minus' />
              </Button>
              <Button
                aria-label={formatMessage({
                  id: 'Buttons.Rotate',
                  defaultMessage: 'Error.Buttons.Rotate',
                })}
                color='primary'
                onClick={() => {
                  if (rotation + 90 < 360) setRotation(rotation + 90);
                  else setRotation(0);
                }}
                outline
              >
                <i aria-hidden className='uil uil-redo' />
              </Button>
            </ButtonGroup>
          </ModalWrapper.Toolbar>
        </>
      )}
    </ModalBody>
  );
};

ImageModal.propTypes = {
  options: PropTypes.object.isRequired,
};

export default ImageModal;
