import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonsCreate: {
    id: 'Buttons.Create',
    defaultMessage: 'Error.Buttons.Create',
  },
  ButtonEdit: { id: 'Buttons.Edit', defaultMessage: 'Error.Buttons.Edit' },
  ButtonShow: {
    id: 'Buttons.Show',
    defaultMessage: 'Error.Buttons.Show',
  },
  CodeLanguage: {
    id: 'Model.TemplateMessages.CodeLanguage',
    defaultMessage: 'Error.Model.TemplateMessages.CodeLanguage',
  },
  Fail: {
    id: 'Toasts.Request.Fail',
    defaultMessage: 'Error.Toasts.Request.Fail',
  },
  Languages: {
    id: 'Screens.TemplateMessages.List.Languages',
    defaultMessage: 'Error.Screens.TemplateMessages.List.Languages',
  },
  Lastname: {
    id: 'Shared.Lastname',
    defaultMessage: 'Error.Shared.Lastname',
  },
  NoElement: {
    id: 'Shared.NoElement',
    defaultMessage: 'Error.Shared.NoElement',
  },
  Organization: {
    id: 'Screens.TemplateMessages.List.Organization',
    defaultMessage: 'Error.Screens.TemplateMessages.List.Organization',
  },
  PlaceHolder: {
    id: 'Screens.TemplateMessages.List.PlaceHolder',
    defaultMessage: 'Error.Screens.TemplateMessages.List.PlaceHolder',
  },
  PlaceHolderType: {
    id: 'Screens.TemplateMessages.List.PlaceholderType',
    defaultMessage: 'Error.Screens.TemplateMessages.List.PlaceholderType',
  },
  RefOrganization: {
    id: 'Model.TemplateMessages.RefOrganization',
    defaultMessage: 'Error.Model.TemplateMessages.RefOrganization',
  },
  Title: {
    id: 'Screens.TemplateMessages.List.Title',
    defaultMessage: 'Error.Screens.TemplateMessages.List.Title',
  },
  Type: {
    id: 'Model.TemplateMessages.Type',
    defaultMessage: 'Error.Model.TemplateMessages.Type',
  },
});
