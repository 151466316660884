import React, { Children, forwardRef } from 'react';
import PropTypes from 'prop-types';

const Checkbox = forwardRef((props, reference) => {
  const { children, id, required, ...otherProps } = props;

  return (
    <div className='custom-checkbox'>
      <input type='checkbox' {...{ id }} {...otherProps} ref={reference} />
      <label htmlFor={id}>
        <span className='custom-checkbox__box'>
          <i aria-hidden className='custom-checkbox__icon uil uil-check' />
        </span>
        {Children.count(children) > 0 && (
          <span className='custom-checkbox__label'>
            {children}
            {required && <span className='text-danger ml-1'>*</span>}
          </span>
        )}
      </label>
    </div>
  );
});

Checkbox.defaultProps = { required: false };

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

Checkbox.displayName = 'Checkbox';

export default Checkbox;
