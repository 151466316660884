import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ProtectedRoute } from '../../components';
import { urlSchema } from '../../utils';

import AddHealthPartner from './AddHealthPartner';
import Create from './Create';
import Delete from './Delete';
import List from './List';
import NewDialogue from './NewDialogue';
import Read from './Read';
import Update from './Update';
import UpdateNhiProfil from './Update/NhiProfil/UpdateNhiProfil';

const routes = [
  // MD routes
  {
    path: urlSchema.patients.create,
    component: Create,
    right: 'CRUD',
  },
  {
    path: `${urlSchema.patients.delete}/:patientId`,
    component: Delete,
    right: 'CRUD',
  },
  {
    path: `${urlSchema.patients.readMd}/:patientId`,
    component: Read,
    right: 'Reader',
  },
  {
    path: `${urlSchema.patients.update}/:patientId`,
    component: Update,
    right: 'CRUD',
  },
  // NHI routes
  {
    path: `${urlSchema.patients.readNHI}/:patientId`,
    component: Read,
    right: 'Reader',
  },
  {
    path: `${urlSchema.patients.updateNHI}/:patientId`,
    component: UpdateNhiProfil,
    right: 'CRUD',
  },
  { path: '/', component: List, right: 'Reader' },
];

const Patients = () => {
  return (
    <Switch>
      <Route
        exact
        path='/Users/Patients/:patientId/NewDialogue'
        component={NewDialogue}
      />
      <Route
        exact
        path='/Users/Patients/:patientId/AddHealthPartner'
        component={AddHealthPartner}
      />
      {routes.map((item) => (
        <ProtectedRoute entity='Patients' key={item.path} {...item} />
      ))}
    </Switch>
  );
};

export default Patients;
