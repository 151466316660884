import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonEdit: {
    id: 'Buttons.Edit',
    defaultMessage: 'Error.Buttons.Edit',
  },
  ToastSuccess: {
    id: 'Message.Success',
    defaultMessage: 'Error.Message.Success',
  },
  ToastSuccessLink: {
    id: 'Toasts.Product.Success.Product.Link',
    defaultMessage: 'Error.Toasts.Product.Success.Product.Link',
  },
  ToastSuccessMigrate: {
    id: 'Toasts.Product.Success.Product.Migrate',
    defaultMessage: 'Error.Toasts.Product.Success.Product.Migrate',
  },
  ToastSuccessFix: {
    id: 'Toasts.Product.Success.Product.Fix',
    defaultMessage: 'Error.Toasts.Product.Success.Product.Fix',
  },
  ToastError: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
  },
  ButtonValidate: {
    id: 'Buttons.Validate',
    defaultMessage: 'Error.Buttons.Validate',
  },
  ButtonCancel: {
    id: 'Buttons.Cancel',
    defaultMessage: 'Error.Buttons.Cancel',
  },
  Title: {
    id: 'Screens.WebServices.Read.Title',
    defaultMessage: 'Screens.WebServices.Read.Title',
  },
  IsNotValid: {
    id: 'Shared.Invalid',
    defaultMessage: 'Shared.Invalid',
  },
  IsValid: {
    id: 'Shared.Valid',
    defaultMessage: 'Error.Shared.Valid',
  },
  Name: {
    id: 'Screens.WebServices.Read.Name',
    defaultMessage: 'Error.Screens.WebServices.Read.Name',
  },
  ClassName: {
    id: 'Screens.WebServices.Read.ClassName',
    defaultMessage: 'Error.Screens.WebServices.Read.ClassName',
  },
  LastUpdate: {
    id: 'Screens.WebServices.Read.LastUpdate',
    defaultMessage: 'Error.Screens.WebServices.Read.LastUpdate',
  },
  NoLastUpdate: {
    id: 'Screens.WebServices.Read.NoLastUpdate',
    defaultMessage: 'Error.Screens.WebServices.Read.NoLastUpdate',
  },
  Configuration: {
    id: 'Screens.WebServices.Read.Configuration',
    defaultMessage: 'Error.Screens.WebServices.Read.Configuration',
  },
  LinkedProduct: {
    id: 'Screens.WebServices.Read.LinkedProduct',
    defaultMessage: 'Error.Screens.WebServices.Read.LinkedProduct',
  },
  Entity: {
    id: 'Screens.WebServices.Read.Entity',
    defaultMessage: 'Error.Screens.WebServices.Read.Entity',
  },
  Status: {
    id: 'Screens.WebServices.Read.Status',
    defaultMessage: 'Error.Screens.WebServices.Read.Status',
  },
  ConfigAvailable: {
    id: 'Screens.WebServices.Read.ConfigAvailable',
    defaultMessage: 'Error.Screens.WebServices.Read.ConfigAvailable',
  },
  TooltipWronglyLinkedToWs: {
    id: 'Screens.WebServices.Read.TooltipWronglyLinkedToWs',
    defaultMessage: 'Error.Screens.WebServices.Read.TooltipWronglyLinkedToWs',
  },
  WronglyLinkedToWs: {
    id: 'Screens.WebServices.Read.Entity.WronglyLinkedToWs',
    defaultMessage: 'Error.Screens.WebServices.Read.Entity.WronglyLinkedToWs',
  },
  NotLinked: {
    id: 'Screens.WebServices.Read.Entity.NotLinked',
    defaultMessage: 'Error.Screens.WebServices.Read.Entity.NotLinked',
  },
  TooltipNotLinked: {
    id: 'Screens.WebServices.Read.TooltipNotLinked',
    defaultMessage: 'Error.Screens.WebServices.Read.TooltipNotLinked',
  },
  WrongVerificationType: {
    id: 'Screens.WebServices.Read.Entity.WrongVerificationType',
    defaultMessage:
      'Error.Screens.WebServices.Read.Entity.WrongVerificationType',
  },
  TooltipWrongVerificationType: {
    id: 'Screens.WebServices.Read.TooltipWrongVerificationType',
    defaultMessage:
      'Error.Screens.WebServices.Read.TooltipWrongVerificationType',
  },
  LinkStatus: {
    id: 'Screens.WebServices.Read.LinkStatus',
    defaultMessage: 'Error.Screens.WebServices.Read.LinkStatus',
  },
  ButtonAdd: {
    id: 'Buttons.Add',
    defaultMessage: 'Error.Buttons.Add',
  },
  Action: {
    id: 'Screens.WebServices.Read.Action',
    defaultMessage: 'Error.Screens.WebServices.Read.Action',
  },
  Unknown: {
    id: 'Screens.WebServices.Read.Entity.Unknown',
    defaultMessage: 'Error.Screens.WebServices.Read.Entity.Unknown',
  },
  TooltipUnknown: {
    id: 'Screens.WebServices.Read.TooltipUnknown',
    defaultMessage: 'Error.Screens.WebServices.Read.TooltipUnknown',
  },
  Ok: {
    id: 'Screens.WebServices.Read.Entity.Ok',
    defaultMessage: 'Error.Screens.WebServices.Read.Entity.Ok',
  },
  TooltipOk: {
    id: 'Screens.WebServices.Read.TooltipOK',
    defaultMessage: 'Error.Screens.WebServices.Read.TooltipOK',
  },
  Link: {
    id: 'Screens.WebServices.Read.Entity.Link',
    defaultMessage: 'Error.Screens.WebServices.Read.Entity.Link',
  },
  Migrate: {
    id: 'Screens.WebServices.Read.Entity.Migrate',
    defaultMessage: 'Error.Screens.WebServices.Read.Entity.Migrate',
  },
  Fix: {
    id: 'Screens.WebServices.Read.Entity.Fix',
    defaultMessage: 'Error.Screens.WebServices.Read.Entity.Fix',
  },
  MigrateModalHeader: {
    id: 'Screens.WebServices.Read.Entity.Modal.Title.Migrate',
    defaultMessage: 'Error.Screens.WebServices.Read.Entity.Modal.Title.Migrate',
  },
  MigrateModalBody: {
    id: 'Screens.WebServices.Read.Entity.Modal.Text.Migrate',
    defaultMessage: 'Error.Screens.WebServices.Read.Entity.Modal.Text.Migrate',
  },
  LinkModalHeader: {
    id: 'Screens.WebServices.Read.Entity.Modal.Title.Link',
    defaultMessage: 'Error.Screens.WebServices.Read.Entity.Modal.Title.Link',
  },
  LinkModalBody: {
    id: 'Screens.WebServices.Read.Entity.Modal.Text.Link',
    defaultMessage: 'Error.Screens.WebServices.Read.Entity.Modal.Text.Link',
  },
  FixModalHeader: {
    id: 'Screens.WebServices.Read.Entity.Modal.Title.Fix',
    defaultMessage: 'Error.Screens.WebServices.Read.Entity.Modal.Title.Fix',
  },
  FixModalBody: {
    id: 'Screens.WebServices.Read.Entity.Modal.Text.Fix',
    defaultMessage: 'Error.Screens.WebServices.Read.Entity.Modal.Text.Fix',
  },
  NoConfiguration: {
    id: 'Screens.WebServices.Read.Configuration.Empty',
    defaultMessage: 'Error.Screens.WebServices.Read.Configuration.Empty',
  },
});
