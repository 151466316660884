import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import messages from '../messages';
import { urlSchema } from 'utils';
import { Spinner, PageHeader } from 'components';
import Store from '../store';
import { runInAction } from 'mobx';

const Suspense = (props) =>
  Store.importConfigInfo === null ? (
    <div className='py-3 container-fluid d-flex'>
      <Spinner color='primary' size={props.size} />
    </div>
  ) : (
    props.children
  );

const componentDidMount = (configId) => {
  window.scrollTo(0, 0);
  runInAction(() => {
    Store.importConfigInfo = null;
  });
  Store.retrieveFileImportConfigInfos(configId);
};

const Read = observer(() => {
  let { configId } = useParams();
  useEffect(() => {
    componentDidMount(configId);
  }, []);
  return (
    <>
      <PageHeader>
        <h1>
          <FormattedMessage {...messages['detail.title']} />
        </h1>
        <Link
          className='btn btn-primary ml-4'
          to={`${urlSchema.fileImportConfigEdit(configId)}`}
        >
          <FormattedMessage {...messages['detail.edit']} />
        </Link>
      </PageHeader>
      <div className='row'>
        <div className='col-lg-8'>
          <Suspense size='sm'>
            <section className='section'>
              <div className='section__body'>
                <div className='model'>
                  <div className='model__property'>
                    <h4 className='model__title'>
                      <FormattedMessage {...messages['detail.partner']} />
                    </h4>
                    <p className='model__value'>
                      {Store.importConfigInfo?.codeClient}
                    </p>
                  </div>
                </div>
                <div className='model'>
                  <div className='model__property'>
                    <h4 className='model__title'>
                      <FormattedMessage {...messages['detail.directory']} />
                    </h4>
                    <p className='model__value'>
                      {Store.importConfigInfo?.filePath}
                    </p>
                  </div>
                </div>
                <div className='model'>
                  <div className='model__property'>
                    <h4 className='model__title'>
                      <FormattedMessage {...messages['detail.status']} />
                    </h4>
                    <p className='model__value'>
                      {String(Store.importConfigInfo?.status)}
                    </p>
                  </div>
                </div>
                <div className='model'>
                  <div className='model__property'>
                    <h4 className='model__title'>
                      <FormattedMessage {...messages['detail.email']} />
                    </h4>
                    <p className='model__value'>
                      {Store.importConfigInfo?.email}
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </Suspense>
        </div>
      </div>
    </>
  );
});
export default Read;
