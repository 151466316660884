import * as yup from 'yup';

import messages from './messages';

export default (intl) =>
  yup.object().shape({
    codeLanguage: yup
      .string()
      .required(intl.formatMessage({ ...messages.FieldRequired })),
    terms: yup
      .string()
      .required(intl.formatMessage({ ...messages.FieldRequired })),
  });
