import { defineMessages } from 'react-intl';

export default defineMessages({
  DeleteModalTitle: {
    id: 'Screens.Offers.Create.CreateRoomForm.DeleteModal.Title',
    defaultMessage:
      'Error.Screens.Offers.Create.CreateRoomForm.DeleteModal.Title',
  },
  DeleteModalBody: {
    id: 'Screens.Offers.Create.CreateRoomForm.DeleteModal.Body',
    defaultMessage: 'Error.Screens.Offers.Create.CreateRoomForm.DeleteModal.Body',
  },
});
