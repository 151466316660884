import styled from 'styled-components';
import { math } from 'polished';

const dotDimensions = '6px';

const DotLink = styled.i.attrs({ 'aria-hidden': true })`
  ${({ theme }) => `
    background-color: ${theme.color('black-border')};
    border-radius: ${math(`${dotDimensions} / 2`)};
    display: block;
    height: ${dotDimensions};
    transition: ${theme.transitionBase};
    width: ${dotDimensions};
    margin-right: ${theme.space(2)};
  `}
`;

export { DotLink };
