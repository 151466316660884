import { defineMessages } from 'react-intl';

export default defineMessages({
  Title: {
    id: 'Screens.WebServices.Create.Title',
    defaultMessage: 'Error.Screens.WebServices.Create.Title',
  },
  Fail: {
    id: 'Toasts.WebServices.Update.Fail',
    defaultMessage: 'Error.Toasts.WebServices.Update.Fail',
  },
  Success: {
    id: 'Toasts.WebServices.Update.Success',
    defaultMessage: 'Error.Toasts.WebServices.Update.Success',
  },
});
