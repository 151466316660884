import styled, { css } from 'styled-components';
import { Modal, ButtonToolbar } from 'reactstrap';

const MainContentWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space(3)};

    @media ${theme.screenMdMin} {
      flex-grow: 1;
      position: relative;
      left: 240px; /* can't use the $sidebar-width variable here */
    }
  `}
`;

const ContainerWrapper = styled.div`
  ${({ theme }) => css`
    @media ${theme.screenMdMin} {
      display: flex;
      max-width: 100vw;

      ${MainContentWrapper} {
        max-width: calc(100vw - ${theme.sidebarWidth});
      }
    }
  `}
`;

const ButtonsWrapper = styled.div`
  ${({ bottom, theme, top }) => css`
    margin-top: ${bottom ? theme.space(3) : 0};
    margin-bottom: ${top ? theme.space(3) : 0};
    text-align: right;
  `}
`;

const IsValidIcon = styled.div`
  text-align: center;

  i {
    color: ${({ isValid, theme }) =>
      isValid ? theme.color('success') : theme.color('danger')};
  }
`;

const ListActions = styled.div.attrs({ className: 'research-table__actions' })`
  ${({ theme }) => css``}
`;

const ListHeader = styled.div.attrs({ className: 'research-table__header' })`
  ${({ theme }) => css``}
`;

const ListItem = styled.li`
  ${({ theme }) => css``}
`;

const ListColumnTitle = styled.h5`
  ${({ theme }) => css``}
`;

const ListResearch = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.space(2)};

    @media ${theme.screenLgMax} {
      [class*='col'] {
        margin-bottom: ${theme.space(1)};
      }
    }
  `}
`;

const ListWrapper = styled.div`
  ${({ theme }) => css`
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    .row {
      align-items: center;
    }

    ${ListResearch} + ${ListHeader} {
      margin-top: ${theme.space(2)};
    }
  `}
`;

ListWrapper.Actions = ListActions;
ListWrapper.Header = ListHeader;
ListWrapper.Item = ListItem;
ListWrapper.ColumnTitle = ListColumnTitle;
ListWrapper.ResearchWrapper = ListResearch;

const ReadModelWrapper = styled.div`
  ${({ theme }) => css`
    a {
      ${theme.fontSize(theme.fontSizeSm())};
    }
  `}
`;

const DecoratorWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);

    img {
      width: auto;
    }
  `}
`;

const PropertyWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.space(3)};
  `}
`;

const PropertyItem = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.color('border')};
    border-radius: ${theme.borderRadius};
    margin-top: ${theme.space(3)};
    padding: ${theme.space(3)};
    position: relative;

    ${PropertyWrapper}:first-of-type {
      margin-top: 0;
    }
  `}
`;

const PropertyTitle = styled.h5`
  ${({ theme }) => css`
    ${theme.fontSize(theme.fontSizeBase, theme.fontWeightBold)};
    margin: 0;
  `}
`;

const PropertyValue = styled.p`
  ${({ theme }) => css`
    margin: 0;
  `}
`;

ReadModelWrapper.DecoratorWrapper = DecoratorWrapper;
ReadModelWrapper.PropertyWrapper = PropertyWrapper;
ReadModelWrapper.PropertyItem = PropertyItem;
ReadModelWrapper.PropertyTitle = PropertyTitle;
ReadModelWrapper.PropertyValue = PropertyValue;

const ModalWrapper = styled(Modal)`
  ${({ theme }) => css`
    ${ImageModal}, ${PDFModal} {
      background-color: ${theme.color('gray-lightest')};
      margin-bottom: ${theme.space(3)};
      overflow-x: auto;
      overflow-y: auto;
    }
  `}
`;

const ModalCloseButton = styled.button.attrs({
  type: 'button',
})`
  ${({ theme }) => css`
    background-color: transparent;
    border: 0;
    line-height: 24px;
    padding: 0;
    text-align: center;
    width: 24px;
  `}
`;

const ImageModal = styled.div`
  ${({ height, rotation, theme }) => css`
    height: ${height}px;
    max-height: ${height}px;

    img {
      display: block;
      margin: auto;
      transform: rotate(${rotation}deg);
      width: auto;
      transform-origin: center;
    }
  `}
`;

const ModalToolbar = styled(ButtonToolbar)`
  ${({ theme }) => css`
    &&& {
      width: 100%;
      justify-content: space-between;

      button {
        min-width: auto;
      }
    }
  `}
`;

const PDFModal = styled.div`
  ${({ height, theme }) => css`
    height: ${height}px;
    max-height: ${height}px;

    canvas {
      border: 1px solid ${theme.color('border')};
      display: block;
      margin: ${theme.space(3)} auto;
    }
  `}
`;
const UserInformation = styled.div`
  ${({ theme }) => css`
    p {
      ${theme.fontSize(theme.fontSizeSm(), theme.fontWeightBold)};
      margin: 0;
    }
  `}
`;

ModalWrapper.CloseButton = ModalCloseButton;
ModalWrapper.ImageWrapper = ImageModal;
ModalWrapper.PDFModal = PDFModal;
ModalWrapper.Toolbar = ModalToolbar;
ModalWrapper.UserInformation = UserInformation;

export {
  ButtonsWrapper,
  ContainerWrapper,
  IsValidIcon,
  ListWrapper,
  MainContentWrapper,
  ReadModelWrapper,
  ModalWrapper,
};
