import { string, mixed } from 'yup';
import moment from 'moment';

export const csvColumns = {
    "PARTENAIRE": string().required(),
    "ENTITE": string().required(),
    "NUMERO ADHERENT": string().required(),
    "NOM": string().required(),
    "PRENOM": string().required(),
    "DATE DE NAISSANCE": mixed()
        .required()
        .test('date-is-valid', 'date is not valid', (e) => (moment(e, "DD/MM/YYYY", true).isValid())),
    "ProductTag": string()
};
