import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import { Button as RSButton, Col, Row } from 'reactstrap';

import {
  Button,
  LogoItem,
  PageHeader,
  Section,
  SectionBody,
  Spinner,
} from 'components';
import { service } from 'services';
import { api, sendToast, useFetch } from 'utils';
import { ToastTypes } from '../../../constants';
import { ButtonsWrapper } from 'themes/jupiter/elements';
import messages from './messages';
import ListWrapper from './elements';

const Sort = () => {
  const [offers, areOffersLoading] = useFetch(api.admin.getOffers);
  const [items, setItems] = useState([]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const arrayFiltered = (array, key) => {
    return array.map((item) => item[key]);
  };

  const onDragEnd = (result, setFieldValue) => {
    // When item is dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index,
    );

    setItems(newItems);
    setFieldValue('ranking', arrayFiltered(newItems, 'id'));
  };

  const onSubmit = (values, { setSubmitting }) => {
    service
      .put(api.admin.sortOffers, values.ranking)
      .then(() => {
        sendToast(ToastTypes.success, {
          header: <FormattedMessage {...messages.SuccessTitle} />,
          body: <FormattedMessage {...messages.SuccessBody} />,
        });
        setSubmitting(false);
      })
      .catch(() => {
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.FailTitle} />,
          body: <FormattedMessage {...messages.FailBody} />,
        });
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (offers.length > 0) setItems(offers);
  }, [offers]);

  return (
    <>
      <PageHeader>
        <FormattedMessage {...messages.Title} tagName='h1' />
      </PageHeader>
      <Row>
        <Col md='6'>
          <Section>
            {areOffersLoading || items.length === 0 ? (
              <Spinner size='lg' />
            ) : (
              <SectionBody>
                <FormattedMessage {...messages.Description} tagName='p' />
                <Formik
                  initialValues={{
                    ranking: arrayFiltered(offers, 'id'),
                  }}
                  onSubmit={onSubmit}
                >
                  {({ isSubmitting, setFieldValue }) => (
                    <Form>
                      <ButtonsWrapper className='text-right' top>
                        <RSButton
                          color='secondary'
                          onClick={() => {
                            setItems(offers);
                            setFieldValue(
                              'ranking',
                              arrayFiltered(offers, 'id'),
                            );
                          }}
                          outline
                          size='sm'
                          type='button'
                        >
                          <FormattedMessage {...messages.ButtonReset} />
                        </RSButton>
                      </ButtonsWrapper>
                      <DragDropContext
                        onDragEnd={(e) => onDragEnd(e, setFieldValue)}
                      >
                        <Droppable droppableId='droppable'>
                          {(provided, snapshot) => (
                            <ListWrapper
                              ref={provided.innerRef}
                              isDraggingOver={snapshot.isDraggingOver}
                              {...provided.droppableProps}
                            >
                              {items.map((item, index) => (
                                <Draggable
                                  key={item.id}
                                  draggableId={String(item.id)}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <>
                                      <ListWrapper.Item
                                        ref={provided.innerRef}
                                        isDragging={snapshot.isDragging}
                                        style={provided.draggableProps.style}
                                        {...provided.dragHandleProps}
                                        {...provided.draggableProps}
                                      >
                                        <LogoItem data={item.logo} />
                                        <p>{item.name}</p>
                                        <ListWrapper.Item.Rank>
                                          #{index + 1}
                                        </ListWrapper.Item.Rank>
                                      </ListWrapper.Item>
                                      {provided.placeholder}
                                    </>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </ListWrapper>
                          )}
                        </Droppable>
                      </DragDropContext>
                      <ButtonsWrapper bottom>
                        <Button
                          color='primary'
                          isProcessing={isSubmitting}
                          label='Update'
                          size='lg'
                        />
                      </ButtonsWrapper>
                    </Form>
                  )}
                </Formik>
              </SectionBody>
            )}
          </Section>
        </Col>
      </Row>
    </>
  );
};

export default Sort;
