import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Label: {
    id: 'Components.InputFile.Button.Label',
    defaultMessage: 'Error.Components.InputFile.Button.Label',
    description: 'Input file button label',
  },
  AvailableSpace: {
    id: 'Components.InputFile.AvailableSpace',
    defaultMessage:
      'Error.Components.InputFile.AvailableSpace {availableSpace}',
    description: 'Input file available space',
  },
});

export default messages;
