import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, Form, Formik } from 'formik';
import { Col, Container, Input, Row } from 'reactstrap';
import { useDebouncedCallback } from 'use-debounce';
import qs from 'qs';
import { historyShape } from 'react-router-props';

import { Button, PageHeader, RoleManager, Spinner } from 'components';
import { service } from 'services';
import { api, sendToast, urlSchema, useFetch } from 'utils';
import ProfileItem from './ProfileItem';
import messages from './messages';
import { ToastTypes } from '../../../constants';

const List = ({ history }) => {
  const intl = useIntl();
  const [organizationConfigs, areOrganizationConfigsLoading] = useFetch(
    api.organizationConfigs.getListItems,
  );
  const [profiles, setProfiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const debouncedCallback = useDebouncedCallback((fn) => {
    fn();
  }, 350);

  const getResults = (values = null, callback) => {
    setIsLoading(true);
    service
      .get(api.profiles.get, {
        params: {
          ...qs.parse(history.location.search, { ignoreQueryPrefix: true }),
          ...values,
        },
      })
      .then(({ data }) => {
        setProfiles(data);
      })
      .catch(() => {
        sendToast(ToastTypes.error, {
          header: '',
          body: <FormattedMessage {...messages.Fail} />,
        });
      })
      .then(() => {
        setIsLoading(false);
        if (typeof callback === 'function') callback();
      });
  };
  const onSubmit = (values, { setSubmitting }) => {
    getResults(values, () => {
      setSubmitting(false);
    });
  };

  useEffect(() => {
    getResults();
  }, []);

  return (
    <>
      <PageHeader>
        <FormattedMessage tagName='h1' {...messages.Title} />
        <RoleManager entity='Profiles' right='CRUD'>
          <Link
            data-tracking='ProfilesListBtnCreate'
            className='btn btn-primary'
            to={urlSchema.profiles.create}
          >
            <FormattedMessage {...messages.ButtonCreate} />
          </Link>
        </RoleManager>
      </PageHeader>
      <section className='section'>
        <header className='section__header'>
          <Formik
            initialValues={{
              searchString: '',
              refOrganizationConfig: '',
              refUniverse: null,
              ...qs.parse(history.location.search, { ignoreQueryPrefix: true }),
            }}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form className='w-100'>
                {areOrganizationConfigsLoading ? (
                  <Spinner color='primary' />
                ) : (
                  <Row>
                    <Col md='5'>
                      <Field name='searchString' type='text'>
                        {({ field }) => {
                          const { onChange } = field;
                          return (
                            <Input
                              {...field}
                              id='search-string'
                              onChange={(e) => {
                                onChange(e);
                                debouncedCallback(handleSubmit);
                              }}
                              placeholder={intl.formatMessage({
                                ...messages.Placeholder,
                              })}
                            />
                          );
                        }}
                      </Field>
                    </Col>
                    <Col md='4'>
                      <Field name='refOrganizationConfig'>
                        {({ field, form }) => (
                          <select
                            className='form-control'
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                              form.submitForm();
                            }}
                          >
                            <option value=''>
                              {intl.formatMessage({
                                ...messages.AllOrganizationsConfig,
                              })}
                            </option>
                            {organizationConfigs.map((el) => (
                              <option key={el.id} value={el.id}>
                                {`${el.label} (${el.code})`}
                              </option>
                            ))}
                          </select>
                        )}
                      </Field>
                    </Col>
                    <Col className='ml-auto'>
                      <Button
                        color='primary'
                        data-tracking='ProfilesListBtnResearch'
                        isProcessing={isLoading || isSubmitting}
                        label='Research'
                        type='submit'
                      />
                    </Col>
                  </Row>
                )}
              </Form>
            )}
          </Formik>
        </header>
        <div className='section__body'>
          <header className='research-table__header'>
            <Container fluid>
              <Row>
                <Col md='3'>
                  <FormattedMessage tagName='h5' {...messages.Code} />
                </Col>
                <Col md='2'>
                  <FormattedMessage tagName='h5' {...messages.Offers} />
                </Col>
                <Col md='3'>
                  <FormattedMessage tagName='h5' {...messages.Roles} />
                </Col>
              </Row>
            </Container>
          </header>
          {isLoading ? (
            <Spinner color='primary' size='lg' />
          ) : profiles.length > 0 ? (
            <ul className='research-table__results'>
              {profiles.map((el) => (
                <li key={el.idProfile}>
                  <ProfileItem {...el} />
                </li>
              ))}
            </ul>
          ) : (
            <p className='text-secondary m-0'>
              <FormattedMessage {...messages.NoElement} />
            </p>
          )}
        </div>
      </section>
    </>
  );
};

List.propTypes = { history: historyShape.isRequired };

export default List;
