import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'Screens.Dialogues.Create.UpdateAddress..Title',
    defaultMessage:
      'Error.Screens.Dialogues.Create.UpdateAddress..Title {firstname}',
  },
  subtitle: {
    id: 'Screens.Dialogues.Create.UpdateAddress.SubTitle',
    defaultMessage: 'Error.Screens.Dialogues.Create.UpdateAddress.Subtitle',
  },
  addressLabel: {
    id: 'Screens.Dialogues.Create.UpdateAddress.AddressLabel',
    defaultMessage: 'Error.Screens.Dialogues.Create.UpdateAddress.AddressLabel',
  },
  addressPlaceholder: {
    id: 'Screens.Dialogues.Create.UpdateAddress.AddressPlaceholder',
    defaultMessage:
      'Error.Screens.Dialogues.Create.UpdateAddress.AddressPlaceholder',
  },
  addressError: {
    id: 'Screens.Dialogues.Create.UpdateAddress.AddressError',
    defaultMessage: 'Error.Screens.Dialogues.Create.UpdateAddress.AddressError',
  },
  address2Label: {
    id: 'Screens.Dialogues.Create.UpdateAddress.Address2Label',
    defaultMessage:
      'Error.Screens.Dialogues.Create.UpdateAddress.Address2Label',
  },
  address2Placeholder: {
    id: 'Screens.Dialogues.Create.UpdateAddress.Address2Placeholder',
    defaultMessage:
      'Error.Screens.Dialogues.Create.UpdateAddress.Address2Placeholder',
  },
  address2Error: {
    id: 'Screens.Dialogues.Create.UpdateAddress.Address2Error',
    defaultMessage:
      'Error.Screens.Dialogues.Create.UpdateAddress.Address2Error',
  },
});

export default messages;
