import { defineMessages } from 'react-intl';

import { generateTranslationsObject } from 'utils';

const prefix = 'Model.Profile.';
const keys = [
  'Code',
  'Label',
  'Products',
  'Ranking',
  'RefOrganizationConfig',
  'Roles',
  'StrongAuth',
];

const additionalKeys = {
  ButtonEdit: {
    id: 'Buttons.Edit',
    defaultMessage: 'Error.Buttons.Edit',
  },
  ButtonManage: {
    id: 'Buttons.Manage',
    defaultMessage: 'Error.Buttons.Manage',
  },
  NoElement: {
    id: 'Shared.NoElement',
    defaultMessage: 'Error.Shared.NoElement',
  },
  Title: {
    id: 'Screens.Profiles.Read.Title',
    defaultMessage: 'Error.Screens.Profiles.Read.Title',
  },
  ToastFail: {
    id: 'Profiles.Update.Error',
    defaultMessage: 'Error.Profiles.Update.Error',
  },
  ToastSuccess: {
    id: 'Profiles.Update.Success',
    defaultMessage: 'Error.Profiles.Update.Success',
  },
};

export default defineMessages(
  generateTranslationsObject(prefix, keys, additionalKeys),
);
