import { defineMessages } from 'react-intl';

export default defineMessages({
  Title: {
    id: 'Menu.WebServicesStatus',
    defaultMessage: 'Error.Menu.WebServicesStatus',
  },
  ButtonCheckAll: {
    id: 'Buttons.CheckAll',
    defaultMessage: 'Error.Buttons.CheckAll',
  },
});
