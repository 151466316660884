import React from 'react';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Spinner } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import { ApiError, Button, PageHeader } from 'components';
import { TemplateMessage } from 'models';
import { validationSchema } from 'models/TemplateMessage';
import { service } from 'services';
import { api, sendToast, urlSchema, useFetch } from 'utils';
import messages from './messages';
import { IFrame } from '../components/IFrame';
import { ToastTypes } from '../../../constants';

const Update = ({ history }) => {
  const { refTemplateMessages } = useParams();
  const [templateMessage, isTemplateMessageLoading] = useFetch(
    api.templateMessages.get(refTemplateMessages),
  );

  const onSubmit = (values, { setSubmitting }) => {
    service
      .put(api.templateMessages.put(refTemplateMessages), values)
      .then(({ data }) => {
        if (data.succeeded === false) {
          setSubmitting(false);
          sendToast(ToastTypes.error, {
            body: <FormattedMessage {...messages.ToastFail} />,
          });
          setSubmitting(false);
          return;
        }
        sendToast(ToastTypes.success, {
          body: (
            <FormattedMessage
              {...messages.ToastSuccess}
              values={{ name: values.name }}
            />
          ),
        });
        history.push(
          `${urlSchema.templateMessages.read}/${refTemplateMessages}`,
        );
      })
      .catch((error) => {
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.ToastFail} />,
          body: <ApiError {...{ error }} />,
        });
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <FormattedMessage
          {...messages.Title}
          values={{ name: templateMessage?.name }}
          tagName='h1'
        />
      </PageHeader>
      <div className='row'>
        <div className='col-lg-6'>
          <main className='section'>
            <div className='section__body'>
              {isTemplateMessageLoading ? (
                <Spinner color='primary' size='lg' />
              ) : (
                <Formik
                  initialValues={templateMessage}
                  {...{ validationSchema }}
                  {...{ onSubmit }}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <TemplateMessage />
                      <div className='text-right'>
                        <Button
                          color='primary'
                          isProcessing={isSubmitting}
                          label='Update'
                          size='lg'
                          type='submit'
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </main>
        </div>
        <div className='col-lg-6'>
          <IFrame />
        </div>
      </div>
    </>
  );
};

export default Update;
