import React from 'react';
import { Switch } from 'react-router-dom';

import { ProtectedRoute } from 'components';
import { urlSchema } from 'utils';

import Create from './Create';
import List from './List';
import Read from './Read';
import Update from './Update';

const routes = [
  {
    component: Create,
    path: urlSchema.webServices.configuration.create,
    right: 'CRUD',
  },
  {
    component: Read,
    path: `${urlSchema.webServices.configuration.read}/:idWebServiceConfiguration`,
    right: 'CRUD',
  },
  {
    component: Update,
    path: `${urlSchema.webServices.configuration.update}/:idWebServiceConfiguration`,
    right: 'CRUD',
  },
  {
    component: List,
    path: urlSchema.webServices.configuration.list,
    right: 'CRUD',
  },
];

const Configuration = () => {
  return (
    <Switch>
      {routes.map((item) => (
        <ProtectedRoute entity='WebServices' key={item.path} {...item} />
      ))}
    </Switch>
  );
};

export default Configuration;
