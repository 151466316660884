const initialValues = {
  infosPatient: {
    operatorInscriptionConsent: false,
    newsletter: false,
    optin: false,
    privacyPolicy: false,
  },
  address1: undefined,
  address2: undefined,
  birthdate: '',
  city: undefined,
  codeCountry: undefined,
  codeLanguage: undefined,
  email: undefined,
  firstname: '',
  formattedBirthdate: undefined,
  gender: undefined,
  googleAddress: '',
  isNotEligible: false,
  lastname: '',
  mobileNumber: undefined,
  notification: undefined,
  phoneNumber: undefined,
  productLabel: undefined,
  refProduct: undefined,
  title: undefined,
  userCode: '',
  valid: true,
  zipCode: undefined,
};

export default initialValues;
