import React, { useState } from 'react';
import Proptypes from 'prop-types';
import { Collapse, NavItem } from 'reactstrap';
import HeaderCollapseMenuWrapper from './elements';

const HeaderCollapseMenu = ({
  children,
  iconMenu,
  textMenu,
  onChildrenClick,
}) => {
  const [isCollapseMenuOpen, setIsCollapseMenuOpen] = useState(false);

  const toggle = () => {
    setIsCollapseMenuOpen(!isCollapseMenuOpen);
  };

  const navItems = React.Children.map(children, (child) => {
    return <NavItem onClick={onChildrenClick}>{child}</NavItem>;
  });

  return (
    <HeaderCollapseMenuWrapper>
      <HeaderCollapseMenuWrapper.Button
        isOpen={isCollapseMenuOpen}
        onClick={toggle}
      >
        <i className={`uil uil-${iconMenu}`} aria-hidden />
        <span>{textMenu}</span>
        <HeaderCollapseMenuWrapper.Chevron className='uil uil-angle-down' />
      </HeaderCollapseMenuWrapper.Button>
      <Collapse isOpen={isCollapseMenuOpen}>
        <HeaderCollapseMenuWrapper.Nav vertical>
          {navItems}
        </HeaderCollapseMenuWrapper.Nav>
      </Collapse>
    </HeaderCollapseMenuWrapper>
  );
};

HeaderCollapseMenu.defaultProps = {
  onChildrenClick: null,
};

HeaderCollapseMenu.propTypes = {
  children: Proptypes.node.isRequired,
  iconMenu: Proptypes.node.isRequired,
  textMenu: Proptypes.node.isRequired,
  onChildrenClick: Proptypes.func,
};

export default HeaderCollapseMenu;
