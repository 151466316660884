import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonAdd: {
    id: 'Buttons.Add',
    defaultMessage: 'Error.Buttons.Add',
    description: 'Button to add',
  },
  ButtonEdit: {
    id: 'Buttons.Edit',
    defaultMessage: 'Error.Buttons.Edit',
    description: 'Button to edit an organizationConfigs',
  },
  ReadOrganizationConfigs: {
    id: 'Buttons.ReadOrganizationConfigs',
    defaultMessage: 'Error.Buttons.ReadOrganizationConfigs',
  },
  ReadMessageTemplates: {
    id: 'Buttons.ReadMessageTemplates',
    defaultMessage: 'Error.Buttons.ReadMessageTemplates',
  },
  Code: {
    id: 'OrganizationConfigs.Code',
    defaultMessage: 'Error.OrganizationConfigs.Code',
  },
  CreatedAt: {
    id: 'Model.CreatedAt',
    defaultMessage: 'Error.Model.CreatedAt',
  },
  CreationDate: {
    id: 'Model.CreationDate',
    defaultMessage: 'Error.Model.CreationDate',
  },
  DetailsOf: {
    id: 'Screens.OrganizationConfigs.Read.Title',
    defaultMessage: 'Error.Screens.OrganizationConfigs.Read.Title',
    description: 'Details of an organizationConfigs page title',
  },
  Name: {
    id: 'OrganizationConfigs.Name',
    defaultMessage: 'Error.OrganizationConfigs.Name',
  },
  NoElement: {
    id: 'Shared.NoElement',
    defaultMessage: 'Error.Shared.NoElement',
  },
  CreationDate: {
    id: 'Model.CreationDate',
    defaultMessage: 'Error.Model.CreationDate',
  },
  Profils: {
    id: 'OrganizationConfigs.Profils',
    defaultMessage: 'Error.OrganizationConfigs.Profils',
  },
  Specialty: {
    id: 'OrganizationConfigs.Specialty',
    defaultMessage: 'Error.OrganizationConfigs.Specialty',
  },
  Universes: {
    id: 'Menu.Universes',
    defaultMessage: 'Error.Menu.Universes',
  },
});
