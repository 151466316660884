import styled, { css } from 'styled-components';

const Title = styled.p`
  ${({ isActive, theme }) => css`
    color: ${isActive && theme.blue};
    margin: 0;
  `}
`;

const FormCellRadioButtonWrapper = styled.div`
  ${({ isActive, theme }) => css`
    align-items: center;
    display: flex;
    padding-left: ${theme.space(1)};
    padding-right: ${theme.space(1)};

    label {
      border: 1px solid ${isActive ? theme.blue : theme.color('border')};
      border-radius: ${theme.borderRadius};
      cursor: pointer;
      padding: ${theme.space(2)};
      text-align: center;
      transition: ${theme.transitionBase};
      width: 100%;

      span {
        display: block;
      }

      &:hover,
      &:focus {
        border-color: ${theme.blue};
      }
    }

    ${Title} {
      color: ${isActive && theme.blue};
    }
  `};
`;

FormCellRadioButtonWrapper.Title = Title;

export default FormCellRadioButtonWrapper;
