import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FastField } from 'formik';

import { FormInput } from 'components';
import messages from './messages';

const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

const WebServiceConfiguration = () => {
  const { formatMessage } = useIntl();
  const formKeys = [
    'authentificationLogin',
    'authentificationPassword',
    'certificate',
    'certificatePassword',
    'codeKo',
    'codeOk',
    'codeResigned',
    'requestType',
    'userAgentCode',
  ];
  const userFormKeys = ['userMembershipCode', 'userFirstName', 'userLastName'];

  return (
    <div className='row'>
      <div className='col-lg-7'>
        <FormInput
          label={formatMessage({ ...messages.Name })}
          hasRawError
          name='name'
          required
        />
        <FormInput
          Component={FastField}
          hasRawError
          label={formatMessage({ ...messages.EndPoint })}
          name='endPoint'
          required
        />
        {formKeys.map((key) => (
          <FormInput
            Component={FastField}
            hasRawError
            key={key}
            label={formatMessage({ ...messages[capitalizeFirstLetter(key)] })}
            name={key}
          />
        ))}
      </div>
      <div className='col-lg-5'>
        <div className='bg-light rounded p-3'>
          <h4 className='d-flex align-items-center'>
            <i aria-hidden className='uil uil-user uil--md' />
            <span className='ml-2'>
              <FormattedMessage {...messages.UserTest} />
            </span>
          </h4>
          {userFormKeys.map((key) => (
            <FormInput
              Component={FastField}
              hasRawError
              key={key}
              label={formatMessage({
                ...messages[capitalizeFirstLetter(key)],
              })}
              name={key}
            />
          ))}
          <FormInput
            Component={FastField}
            hasRawError
            label={formatMessage({ ...messages.UserBirthDate })}
            name='userBirthDate'
            type='date'
          />
        </div>
      </div>
    </div>
  );
};

export default WebServiceConfiguration;
