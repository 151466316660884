import React from 'react';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';

import { Button, PageHeader, Section, SectionBody, Spinner } from 'components';
import { service } from 'services';
import { api, sendToast, urlSchema, useFetch } from 'utils';
import { ToastTypes } from '../../../constants';
import { Specialty } from 'models';
import validationSchema from 'models/Specialty/validationSchema';
import { ButtonsWrapper } from 'themes/jupiter/elements';
import messages from './messages';

const Update = ({ history, match }) => {
  const intl = useIntl();
  const { refSpecialty } = match.params;
  const [specialty, isSpecialtyLoading] = useFetch(
    api.admin.getSpecialty(refSpecialty),
  );

  const onSubmit = (values, { setSubmitting }) => {
    // TODO: "label" field added as french translation for backward compatibility. Remove it when no need
    const frenchLabel = values.name.filter((el) => el.languageCode === 'FR');

    service
      .put(`${api.admin.postSpecialty}/${refSpecialty}`, {
        ...values,
        // TODO: "label" field added as french translation for backward compatibility. Remove it when no need
        label: frenchLabel[0].content,
      })
      .then(({ data }) => {
        if (typeof data.succeeded !== 'undefined' && !data.succeeded) {
          sendToast(ToastTypes.error, {
            header: <FormattedMessage {...messages.ToastFail} />,
            body: data.errorSet[0].description,
          });
        } else {
          sendToast(ToastTypes.success, {
            header: '',
            body: <FormattedMessage {...messages.ToastSuccess} />,
          });
          history.push(`${urlSchema.specialties.read}/${refSpecialty}`);
        }
      })
      .catch(() => {
        sendToast(ToastTypes.error, {
          header: '',
          body: <FormattedMessage {...messages.ToastFail} />,
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <FormattedMessage {...messages.Title} tagName='h1' />
      </PageHeader>
      <Row>
        <Col md='10' lg='8'>
          <Section>
            <SectionBody>
              {isSpecialtyLoading ? (
                <Spinner size='lg' />
              ) : (
                <Formik
                  initialValues={specialty}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema(
                    specialty.name.length,
                    intl,
                  )}
                >
                  {({ isSubmitting, ...props }) => (
                    <Form>
                      <Specialty isUpdating {...props} />
                      <ButtonsWrapper bottom>
                        <Button
                          color='primary'
                          label='Update'
                          isProcessing={isSubmitting}
                          size='lg'
                        />
                      </ButtonsWrapper>
                    </Form>
                  )}
                </Formik>
              )}
            </SectionBody>
          </Section>
        </Col>
      </Row>
    </>
  );
};

export default Update;
