import React from 'react';
import * as yup from 'yup';

import { FormattedMessage } from 'react-intl';
import messages from '../message';

export default yup.object().shape({
  description: yup.string(),
  dateExpiration: yup.date()
    .min(new Date(), <FormattedMessage {...messages['validation.dateExpiration']} />)
    .required(<FormattedMessage {...messages['validation.dateExpiration']} />),
});
