import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Form } from 'formik';
import { Col, Row } from 'reactstrap';
import qs from 'qs';
import { historyShape } from 'react-router-props';

import { Button, PageHeader, Section, SectionBody } from 'components';
import { Profile } from 'models';
import { service } from 'services';
import { api, sendToast, urlSchema } from 'utils';
import validationSchema from 'models/Profile/validationSchema';
import { ToastTypes } from '../../../constants';
import messages from './messages';

const Create = ({ history }) => {
  const intl = useIntl();

  const onSubmit = (values, { setSubmitting }) => {
    service
      .post(api.profiles.post, values)
      .then(({ data }) => {
        if (typeof data.succeeded !== 'undefined' && !data.succeeded) {
          sendToast(ToastTypes.error, {
            header: <FormattedMessage {...messages.ToastFail} />,
            body: data.errorSet[0].description,
          });
        } else {
          sendToast(ToastTypes.success, {
            header: '',
            body: <FormattedMessage {...messages.ToastSuccess} />,
          });
          history.push(urlSchema.profiles.list);
        }
      })
      .catch(() => {
        sendToast(ToastTypes.error, {
          header: '',
          body: <FormattedMessage {...messages.ToastFail} />,
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <FormattedMessage tagName='h1' {...messages.Title} />
      </PageHeader>
      <Row>
        <Col md='6'>
          <Section>
            <SectionBody>
              <Formik
                initialValues={{
                  code: '',
                  label: '',
                  ranking: 0,
                  refOrganizationConfig: '',
                  strongAuth: false,
                  ...qs.parse(history.location.search, {
                    ignoreQueryPrefix: true,
                  }),
                }}
                onSubmit={onSubmit}
                validationSchema={validationSchema(intl)}
              >
                {(formikProps) => (
                  <Form>
                    <Profile {...formikProps} />
                    <div className='text-right'>
                      <Button
                        color='primary'
                        isProcessing={formikProps.isSubmitting}
                        label='Create'
                        size='lg'
                        type='submit'
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </SectionBody>
          </Section>
        </Col>
      </Row>
    </>
  );
};

Create.propTypes = { history: historyShape.isRequired };

export default Create;
