import React, { ReactElement } from 'react';
import moment from 'moment-timezone';
import Select from 'react-select';
import * as yup from 'yup';
import { Field, Form, Formik, FormikHelpers } from 'formik';

import { Spinner, FormRadioButton, Translation } from '../../components';
import { useIdentityVerificationNhiStore } from './store';
import {
  IdentityDocumentTypesDte,
  IdentityDocumentValidationStatusesDtestring,
  InsDocumentValidationStatus,
  User,
} from './types';

type DocumentTypeSelectOption = {
  value: IdentityDocumentTypesDte;
  label: ReactElement;
};

type DocumentTypeSelectOptions = DocumentTypeSelectOption[];
type RadioOptions = IdentityDocumentValidationStatusesDtestring | 'Valid';

type FormValues = {
  documentType: DocumentTypeSelectOption;
  patientId: string;
  refusalReason: RadioOptions;
};

const optionsMainUser: DocumentTypeSelectOptions = [
  {
    value: IdentityDocumentTypesDte.NIC,
    label: (
      <Translation
        id={`IdentityVerifications.Document.${IdentityDocumentTypesDte.NIC}`}
      />
    ),
  },
  {
    value: IdentityDocumentTypesDte.Passport,
    label: (
      <Translation
        id={`IdentityVerifications.Document.${IdentityDocumentTypesDte.Passport}`}
      />
    ),
  },
  {
    value: IdentityDocumentTypesDte.PRP,
    label: (
      <Translation
        id={`IdentityVerifications.Document.${IdentityDocumentTypesDte.PRP}`}
      />
    ),
  },
];

const optionBeneficiary: DocumentTypeSelectOptions = [
  {
    value: IdentityDocumentTypesDte.NIC,
    label: (
      <Translation
        id={`IdentityVerifications.Document.${IdentityDocumentTypesDte.NIC}`}
      />
    ),
  },
  {
    value: IdentityDocumentTypesDte.Passport,
    label: (
      <Translation
        id={`IdentityVerifications.Document.${IdentityDocumentTypesDte.Passport}`}
      />
    ),
  },
  {
    value: IdentityDocumentTypesDte.PRP,
    label: (
      <Translation
        id={`IdentityVerifications.Document.${IdentityDocumentTypesDte.PRP}`}
      />
    ),
  },
  {
    value: IdentityDocumentTypesDte.BirthCertificate,
    label: (
      <Translation
        id={`IdentityVerifications.Document.${IdentityDocumentTypesDte.BirthCertificate}`}
      />
    ),
  },
  {
    value: IdentityDocumentTypesDte.FRB,
    label: (
      <Translation
        id={`IdentityVerifications.Document.${IdentityDocumentTypesDte.FRB}`}
      />
    ),
  },
];

const validationSchema = yup.object().shape({
  documentType: yup
    .object()
    .shape({
      value: yup
        .mixed()
        .oneOf(Object.values(IdentityDocumentTypesDte))
        .required('Document type value is required'),
      label: yup.mixed().required('Document type label is required'),
    })
    .required('Document type is required'),

  patientId: yup.string().required('Patient ID is required'),

  refusalReason: yup
    .mixed()
    .oneOf([
      ...Object.values(IdentityDocumentValidationStatusesDtestring),
      'Valid',
    ])
    .required('Document status is required'),
});

export const ModalForm = ({
  user,
  isImageVisible,
}: {
  user: User;
  isImageVisible: boolean;
}) => {
  const isUserBeneficiary = Boolean(
    moment().diff(moment(user?.nhiBirthDate), 'years') < 18,
  );
  const selectOptions = isUserBeneficiary ? optionBeneficiary : optionsMainUser;
  const updateDocumentStatus = useIdentityVerificationNhiStore(
    (store) => store.updateDocumentStatus,
  );

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    try {
      setSubmitting(true);
      updateDocumentStatus({
        documentStatus:
          values.refusalReason === 'Valid'
            ? InsDocumentValidationStatus.Validated
            : InsDocumentValidationStatus.Refused,
        refusalReason:
          values.refusalReason === 'Valid' ? undefined : values.refusalReason,
        documentType: values.documentType.value,
        patientId: String(user.patientId),
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={{
        documentStatus: 'Refused',
        documentType: selectOptions.find(
          (item) => item.value === user.identityDocumentType,
        ),
        patientId: String(user.patientId),
        refusalReason: undefined,
      }}
    >
      {({ setFieldValue, values, isSubmitting, isValid, dirty }) => (
        <Form>
          <div className='d-flex mt-2 flex-column gap-4'>
            <div>
              <p className='font-size-14px mb-0 text-secondary'>
                <Translation id='Screens.IdentityValidationsIns.ModalForm.Form.IdentityDocument' />
              </p>
              <Select
                defaultValue={values.documentType}
                onChange={(option) => setFieldValue('documentType', option)}
                placeholder='Statut'
                options={selectOptions}
              />
            </div>
            <div className='d-flex flex-column gap-2'>
              <Field
                name='refusalReason'
                id='documentStatusNonReadableID'
                component={FormRadioButton}
                inputValue={
                  IdentityDocumentValidationStatusesDtestring.Unreadable
                }
                label={
                  <Translation id='Screens.IdentityValidationsIns.DocumentRefusalReason.Unreadable' />
                }
              />
              <Field
                name='refusalReason'
                id='documentStatusInvalidID'
                component={FormRadioButton}
                inputValue={IdentityDocumentValidationStatusesDtestring.Invalid}
                label={
                  <Translation id='Screens.IdentityValidationsIns.DocumentRefusalReason.Invalid' />
                }
              />
              <Field
                name='refusalReason'
                id='documentStatusDifferentName'
                component={FormRadioButton}
                inputValue={
                  IdentityDocumentValidationStatusesDtestring.Mistaken
                }
                label={
                  <Translation id='Screens.IdentityValidationsIns.DocumentRefusalReason.Mistaken' />
                }
              />
              <Field
                name='refusalReason'
                id='documentStatusValid'
                component={FormRadioButton}
                inputValue={'Valid'}
                label={
                  <Translation id='Screens.IdentityValidationsIns.DocumentValidationStatus.Validated' />
                }
              />
            </div>
            <button
              disabled={Boolean(!(isValid && dirty) || !isImageVisible)}
              className='btn btn-primary d-flex flex-row justify-content-center gap-2'
              type='submit'
            >
              {isSubmitting && <Spinner color='white' size='xs' />}
              <Translation id='Shared.Approve' />
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
