import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Formik, Form } from 'formik';
import { Col, Row } from 'reactstrap';
import { historyShape } from 'react-router-props';

import { service } from 'services';
import { api, sendToast, urlSchema } from 'utils';
import { Role } from 'models';
import { Button, PageHeader } from 'components';
import validationSchema from 'models/Role/validationSchema';
import { ToastTypes } from '../../../constants';
import messages from './messages';

const Create = ({ history }) => {
  const onSubmit = (values, { setSubmitting }) => {
    service
      .post(api.roles.post, values)
      .then(({ data }) => {
        if (typeof data.succeeded !== 'undefined' && !data.succeeded) {
          sendToast(ToastTypes.error, {
            header: (
              <FormattedMessage
                values={{ code: values.code }}
                {...messages.Fail}
              />
            ),
            body: data.errorSet[0].description,
          });
        } else {
          sendToast(ToastTypes.success, {
            body: (
              <FormattedMessage
                values={{ code: values.code }}
                {...messages.Success}
              />
            ),
          });
          history.push(urlSchema.roles.list);
        }
      })
      .catch((err) => {
        sendToast(ToastTypes.error, {
          header: (
            <FormattedMessage
              values={{ code: values.code }}
              {...messages.Fail}
            />
          ),
          body: (
            <>
              <span className='m-0 small'>
                Code: {err?.response?.status} ({err?.response?.statusText})
              </span>
              <br />
              <span className='m-0 small'>
                URL: {err?.response?.config?.url}
              </span>
            </>
          ),
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <FormattedMessage tagName='h1' {...messages.Title} />
      </PageHeader>
      <Row>
        <Col md='6'>
          <section className='section'>
            <div className='section__body'>
              <Formik
                initialValues={{
                  code: '',
                  label: '',
                  ranking: '',
                  published: false,
                }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Role />
                    <div className='text-right'>
                      <Button
                        color='primary'
                        isProcessing={isSubmitting}
                        label='Create'
                        size='lg'
                        type='submit '
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </section>
        </Col>
      </Row>
    </>
  );
};

Create.propTypes = { history: historyShape.isRequired };

export default Create;
