import { defineMessages } from 'react-intl';

export default defineMessages({
  Valid: {
    id: 'Screens.Applications.Read.Valid',
    defaultMessage: 'Error.Screens.Applications.Read.Valid',
  },
  ButtonBack: {
    id: 'Buttons.Back',
    defaultMessage: 'Error.Buttons.Back',
  },
  ButtonEdit: {
    id: 'Buttons.Edit',
    defaultMessage: 'Error.Buttons.Edit',
    description: 'Button to edit an applications',
  },
  ButtonManageProfiles: {
    id: 'Buttons.ManageProfiles',
    defaultMessage: 'Error.Buttons.ManageProfiles',
    description: 'Button to manage profiles',
  },
  Code: {
    id: 'Applications.Code',
    defaultMessage: 'Error.Applications.Code',
    description: 'Code of an applications',
  },
  DetailsOf: {
    id: 'Screens.Applications.Read.Title',
    defaultMessage: 'Error.Screens.Applications.Read.Title',
    description: 'Details of an applications page title',
  },
  Inactive: {
    id: 'Shared.Inactive',
    defaultMessage: 'Error.Shared.Inactive',
  },
  Label: {
    id: 'Applications.Label',
    defaultMessage: 'Error.Applications.Label',
    description: 'Label of an applications',
  },
  NoElement: {
    id: 'Shared.NoElement',
    defaultMessage: 'Error.Shared.NoElement',
  },
  Profiles: {
    id: 'Screens.Applications.Read.Profiles',
    defaultMessage: 'Error.Screens.Applications.Read.Profiles',
    description: 'Profiles title',
  },
  Url: {
    id: 'Applications.Url',
    defaultMessage: 'Error.Applications.Url',
    description: 'Url of an applications',
  },
});
