import { defineMessages } from 'react-intl';

import { generateTranslationsObject } from 'utils';

const prefix = 'Screens.Roles.Create.';
const keys = ['Title'];

const additionalKeys = {
  Fail: {
    id: 'Toasts.Roles.Create.Fail',
    defaultMessage: 'Error.Toasts.Roles.Create.Fail',
  },
  Success: {
    id: 'Toasts.Roles.Create.Success',
    defaultMessage: 'Error.Toasts.Roles.Create.Success',
  },
};

export default defineMessages(
  generateTranslationsObject(prefix, keys, additionalKeys),
);
