import { defineMessages } from 'react-intl';

import { generateTranslationsObject } from 'utils';

const prefix = 'Screens.Profiles.Create.';
const keys = ['Title'];

const additionalKeys = {
  ToastFail: {
    id: 'Toasts.Profiles.Create.Error',
    defaultMessage: 'Error.Toasts.Profiles.Create.Error',
  },
  ToastSuccess: {
    id: 'Toasts.Profiles.Create.Success',
    defaultMessage: 'Error.Toasts.Profiles.Create.Success',
  },
};

export default defineMessages(
  generateTranslationsObject(prefix, keys, additionalKeys),
);
