import styled from 'styled-components';

const SpinnerWrapper = styled.div`
  ${({ size, theme }) => `
    display: flex;
    align-items: center;
    padding: ${size === 'lg' ? theme.space(4) : 0} 0;
    
    .spinner-border {
      margin: 0 auto;
    }
  `}
`;

export default SpinnerWrapper;
