import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Row, Col } from 'reactstrap';
import moment from 'moment';

import messages from '../messages';

export const PersonalInformation = ({ data }) => {
  const intl = useIntl();

  return (
    <>
      <div className='mb-3'>
        <FormattedMessage {...messages.UserInformation} tagName='h2' />
      </div>
      <Row>
        <Col md='6'>
          <div className='model__property'>
            <h3 className='model__title'>
              {intl.formatMessage({
                ...messages.Email,
              })}
            </h3>
            <p className='model__value'>{data.email}</p>
          </div>
        </Col>
        <Col md='6'>
          <div className='model__property'>
            <h3 className='model__title'>
              {intl.formatMessage({
                ...messages.Mobile,
              })}
            </h3>
            <p className='model__value'>{data.mobileNumber ?? '-'}</p>
          </div>
        </Col>
        <Col md='6'>
          <div className='model__property'>
            <h3 className='model__title'>
              {intl.formatMessage({
                ...messages.Birthdate,
              })}
            </h3>
            <p className='model__value'>
              {moment(data.birthdate).format('LL')}
            </p>
          </div>
        </Col>
        <Col md='6'>
          <div className='model__property'>
            <h3 className='model__title'>
              {intl.formatMessage({
                ...messages.Notification,
              })}
            </h3>
            <p className='model__value'>{data.notification}</p>
          </div>
        </Col>
        <Col md='6'>
          <div className='model__property'>
            <h3 className='model__title'>
              {intl.formatMessage({
                ...messages.Address,
              })}
            </h3>
            <p className='model__value'>
              {data.address1} {data.zipCode}, {data.city}
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};

PersonalInformation.displayName = 'Screens_Doctors_Read_Personal_Information';
