import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Badge, Col, Row } from 'reactstrap';

import {
  FormattedDate,
  LinksList,
  LogoItem,
  PageHeader,
  RoleManager,
  Section,
  SectionHeader,
  SectionBody,
  Spinner,
} from 'components';

import { api, urlSchema, useFetch } from 'utils';
import { ReadModelWrapper } from 'themes/jupiter/elements';
import messages from './messages';
import { RoomWrapper } from './elements';

const Read = () => {
  const intl = useIntl();
  const locale = intl.locale.split('-')[0].toUpperCase();
  const { refOffer } = useParams();
  const [offer, isOfferLoading] = useFetch(api.admin.getOffer(refOffer));
  const doesMedicalControlServiceExist =
    offer.options && offer?.options?.isMedicalControlService === true;
  const doesFollowUpEncounterExist =
    offer.allowFollowUpEncounter && offer?.allowFollowUpEncounter === true;
  const offerName = offer?.name?.find(
    (item) => item.languageCode === locale,
  ).content;

  return (
    <>
      <PageHeader>
        <FormattedMessage {...messages.DetailsOf} tagName='h1' />
        <RoleManager entity='Offers' right='CRUD'>
          <Link
            className='btn btn-primary'
            to={`${urlSchema.updateOffer}/${refOffer}`}
          >
            {intl.formatMessage({ ...messages.ButtonEdit })}
          </Link>
        </RoleManager>
      </PageHeader>
      {isOfferLoading ? (
        <Spinner size='lg' />
      ) : (
        <Row>
          <Col xl='6'>
            <Section>
              <SectionBody>
                <Row>
                  <Col className='text-center' md='5'>
                    {offer.logo ? (
                      <LogoItem data={offer.logo} title={offerName} />
                    ) : (
                      <h2>{offer.code}</h2>
                    )}
                    <h4>
                      <Badge color={offer.isValid ? 'success' : 'danger'}>
                        {offer.isValid ? (
                          <FormattedMessage {...messages.Active} />
                        ) : (
                          <FormattedMessage {...messages.Inactive} />
                        )}
                      </Badge>
                    </h4>
                    {doesMedicalControlServiceExist && (
                      <h4>
                        <Badge color='light'>
                          <FormattedMessage
                            id='Offers.CMD'
                            defaultMessage='Offers.CMD'
                          />
                        </Badge>
                      </h4>
                    )}
                    {doesFollowUpEncounterExist && (
                      <h4>
                        <Badge color='info'>
                          <FormattedMessage
                            id='Offers.FollowUpEncounter'
                            defaultMessage='Offers.FollowUpEncounter'
                          />
                        </Badge>
                      </h4>
                    )}
                    <p>
                      <FormattedMessage
                        {...messages.CreationDate}
                        tagName='small'
                        values={{
                          date: <FormattedDate value={offer.dateCreation} />,
                        }}
                      />
                    </p>
                    {offer.dateLastUpdate && (
                      <p>
                        <FormattedMessage
                          {...messages.LastUpdateDate}
                          tagName='small'
                          values={{
                            date: (
                              <FormattedDate value={offer.dateLastUpdate} />
                            ),
                          }}
                        />
                      </p>
                    )}
                  </Col>
                  <Col md='7'>
                    <div className='d-flex align-items-center'>
                      <i
                        aria-hidden
                        className='uil uil-user-md uil--md mr-3'
                        title={intl.formatMessage({
                          ...messages.DefaultSpecialty,
                        })}
                      />
                      <Link
                        className='btn btn-outline-primary btn-sm'
                        to={`${urlSchema.specialties.read}/${offer.refSpecialty}`}
                      >
                        {offer.specialtyLabel}
                      </Link>
                    </div>
                    <ReadModelWrapper>
                      <div className='model__property'>
                        {offer.name && (
                          <>
                            <div className='model__title'>
                              <FormattedMessage {...messages.Name} />
                            </div>
                            {offer.name.map((ressource) => (
                              <div className='model__value'>
                                <img
                                  alt='Flag icon'
                                  className='mr-2'
                                  height='16'
                                  src={require(`assets/images/flags/${ressource.languageCode.toLowerCase()}_flag.svg`)}
                                />
                                <span>{ressource.content}</span>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                      <div className='model__property'>
                        {offer.description && (
                          <>
                            <div className='model__title'>
                              <FormattedMessage {...messages.Description} />
                            </div>
                            {offer.description.map((ressource) => (
                              <div className='model__value'>
                                <img
                                  alt='Flag icon'
                                  className='mr-2'
                                  height='16'
                                  src={require(`assets/images/flags/${ressource.languageCode.toLowerCase()}_flag.svg`)}
                                />
                                <span>{ressource.content}</span>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                      <div className='model__property'>
                        {offer.cta && (
                          <>
                            <div className='model__title'>
                              <FormattedMessage {...messages.CTA} />
                            </div>
                            {offer.cta.map((ressource) => (
                              <div className='model__value'>
                                <img
                                  alt='Flag icon'
                                  className='mr-2'
                                  height='16'
                                  src={require(`assets/images/flags/${ressource.languageCode.toLowerCase()}_flag.svg`)}
                                />
                                <span>{ressource.content}</span>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </ReadModelWrapper>
                  </Col>
                </Row>
              </SectionBody>
            </Section>
            <Section>
              <SectionHeader>
                <FormattedMessage {...messages.Rooms} tagName='h2' />
              </SectionHeader>
              <SectionBody>
                {offer.rooms.length > 0 ? (
                  <Row>
                    {offer.rooms.map((room) => (
                      <Col md={12} xl={12 / offer.rooms.length}>
                        <RoomWrapper>
                          <header>
                            <FormattedMessage
                              id={`Constants.${room.channel}`}
                              defaultMessage={`Error.Constants.${room.channel}`}
                              tagName='h3'
                            />
                          </header>
                          <div>
                            {room.futureLimit && (
                              <div className='model__property'>
                                <div className='model__title'>
                                  <FormattedMessage {...messages.FutureLimit} />
                                </div>
                                <div className='model__value'>
                                  <FormattedMessage
                                    {...messages.Day}
                                    values={{ count: room.futureLimit }}
                                  />
                                </div>
                              </div>
                            )}
                            <div className='model__property'>
                              <div className='model__title'>
                                <FormattedMessage
                                  {...messages.DutySlotDuration}
                                />
                              </div>
                              <div className='model__value'>
                                {room.dutySlotDuration < 60 ? (
                                  <FormattedMessage
                                    {...messages.Minute}
                                    values={{ count: room.dutySlotDuration }}
                                  />
                                ) : (
                                  <FormattedMessage
                                    {...messages.Hour}
                                    values={{
                                      count: room.dutySlotDuration / 60,
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                            {room.consultationDuration && (
                              <div className='model__property'>
                                <div className='model__title'>
                                  <FormattedMessage
                                    {...messages.ConsultationDuration}
                                  />
                                </div>
                                <div className='model__value'>
                                  {room.consultationDuration < 60 ? (
                                    <FormattedMessage
                                      {...messages.Minute}
                                      values={{
                                        count: room.consultationDuration,
                                      }}
                                    />
                                  ) : (
                                    <FormattedMessage
                                      {...messages.Hour}
                                      values={{
                                        count: room.consultationDuration / 60,
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                            {room.minBookingNotice && (
                              <div className='model__property'>
                                <div className='model__title'>
                                  <FormattedMessage
                                    {...messages.MinBookingNotice}
                                  />
                                </div>
                                <div className='model__value'>
                                  {room.minBookingNotice < 1440 ? (
                                    <FormattedMessage
                                      {...messages.Minute}
                                      values={{ count: room.minBookingNotice }}
                                    />
                                  ) : (
                                    <FormattedMessage
                                      {...messages.Day}
                                      values={{
                                        count: room.minBookingNotice / 1440,
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </RoomWrapper>
                      </Col>
                    ))}
                  </Row>
                ) : null}
              </SectionBody>
            </Section>
          </Col>
          <Col xl='6'>
            <Section>
              <SectionHeader>
                <FormattedMessage {...messages.Profiles} tagName='h2' />
              </SectionHeader>
              <SectionBody>
                <LinksList
                  color='primary'
                  data={offer.profiles}
                  idKey={(el) => el.refProfile}
                  labelKey={(el) => el.displayLabel}
                  urlBase={urlSchema.profiles.read}
                />
              </SectionBody>
            </Section>
            <Section>
              <SectionHeader>
                <FormattedMessage {...messages.Products} tagName='h2' />
              </SectionHeader>
              <SectionBody>
                <LinksList
                  color='primary'
                  data={offer.products}
                  idKey={(el) => el.refProduct}
                  labelKey={(el) => el.displayLabel}
                  urlBase={urlSchema.products.read}
                />
              </SectionBody>
            </Section>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Read;
