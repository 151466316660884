import React from 'react';
import { Translation } from 'components';
import { AccessLogsSurcharged, WebServiceLogsSurcharged } from './types';

const mapAccessLogToKey = {
  READ: 'Screens.Patient.Read.Nhi.Logs.AccessLogRead',
  CREATE: 'Screens.Patient.Read.Nhi.Logs.AccessLogCreate',
  UPDATE: 'Screens.Patient.Read.Nhi.Logs.AccessLogUpdate',
};

const mapWebServiceLogToKey = {
  UNIT_SEARCH: 'Screens.Patient.Read.Nhi.Logs.WebServiceLogUnitSearch',
  UNIT_VERIF: 'Screens.Patient.Read.Nhi.Logs.WebServiceLogUnitVerif',
};

const mapReportCodeToKey = {
  UniqueNHIFound: 'Screens.Patient.Read.Nhi.Logs.WebServiceLog.UniqueNHIFound',
  NHINotFound: 'Screens.Patient.Read.Nhi.Logs.WebServiceLog.NHINotFound',
  MultipleNHIFound:
    'Screens.Patient.Read.Nhi.Logs.WebServiceLog.MultipleNHIFound',
};

const renderLogDetails = (
  log: AccessLogsSurcharged | WebServiceLogsSurcharged,
) => {
  switch (log.type) {
    case 'ACCESS_LOG': {
      const currentLog = log as AccessLogsSurcharged;
      const authorName = currentLog.context.includes('AUTO_') ? (
        <Translation id='Screens.Patient.Read.Nhi.Logs.AccessLog.INSiAuthor' />
      ) : currentLog.firstName && currentLog.lastName ? (
        `${currentLog.firstName} ${currentLog.lastName}`
      ) : undefined;

      return (
        <p className='mb-0'>
          <Translation
            id={mapAccessLogToKey[currentLog.operation]}
            values={{
              authorName,
            }}
          />
        </p>
      );
    }
    case 'WEB_SERVICE': {
      const currentLog = log as WebServiceLogsSurcharged;

      return (
        <p className='mb-0'>
          <Translation id={mapWebServiceLogToKey[currentLog.requestType]} />
          {currentLog.reportCode ? (
            <>
              {' '}
              : <Translation id={mapReportCodeToKey[currentLog.reportCode]} />
            </>
          ) : undefined}
        </p>
      );
    }
    default: {
      return;
    }
  }
};

export const LogItem = ({
  log,
  onLogClick,
}: {
  log: AccessLogsSurcharged | WebServiceLogsSurcharged;
  onLogClick: () => void;
}) => (
  <button className='text-left' onClick={onLogClick}>
    {renderLogDetails(log)}
  </button>
);
