import React from 'react';
import { Field, useFormikContext } from 'formik';
import { useIntl, FormattedMessage } from 'react-intl';
import { Row, Container, Col, Button } from 'reactstrap';

import { Checkbox, FormInput, FormInputFeedback } from 'components';
import messages from '../../messages';

const FormDemographicCriteria = () => {
  const intl = useIntl();
  const { resetForm } = useFormikContext();

  return (
    <Container className='mb-3'>
      <Row>
        <Col md='9'>
          <h3 className='text-primary'>
            <FormattedMessage {...messages.DemographicCriteriaTitle} />
          </h3>
        </Col>
        <Col md='3'>
          <div className='d-flex justify-content-end'>
            <Button
              onClick={() => resetForm()}
              size='sm'
              color='secondary'
              outline
            >
              <FormattedMessage
                id='Buttons.Reset'
                defaultMessage='Error.Buttons.Reset'
              />
            </Button>
          </div>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col md='3'>
          <Field name='maleChecked'>
            {({ field, form }) => (
              <Checkbox
                {...field}
                checked={form.values.maleChecked}
                id='maleChecked'
              >
                {intl.formatMessage({
                  ...messages.Man,
                })}
              </Checkbox>
            )}
          </Field>
        </Col>
        <Col md='3'>
          <Field name='femaleChecked'>
            {({ field, form }) => (
              <Checkbox
                {...field}
                checked={form.values.femaleChecked}
                id='femaleChecked'
              >
                {intl.formatMessage({
                  ...messages.Woman,
                })}
              </Checkbox>
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md='6'>
          <FormInput
            label={intl.formatMessage({
              ...messages.DemographicCriteriaAgeFrom,
            })}
            type='number'
            min='0'
            name='ageFrom'
            id='ageFrom'
            hasRawError
          />
        </Col>
        <Col md='6'>
          <FormInput
            label={intl.formatMessage({
              ...messages.DemographicCriteriaAgeTo,
            })}
            type='number'
            min='0'
            name='ageTo'
            id='ageTo'
            hasRawError
          />
        </Col>
      </Row>
      <div>
        <Field name='minorBeneficiaryChecked'>
          {({ field, form }) => (
            <Checkbox
              {...field}
              checked={form.values.minorBeneficiaryChecked}
              id='minorBeneficiaryChecked'
            >
              {intl.formatMessage({
                ...messages.DemographicCriteriaMinorBeneficiaryChecked,
              })}
            </Checkbox>
          )}
        </Field>
        <FormInputFeedback name='minorBeneficiaryChecked' />
      </div>
    </Container>
  );
};

export default FormDemographicCriteria;
