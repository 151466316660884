import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonCreate: {
    id: 'Buttons.Create',
    defaultMessage: 'Error.Buttons.Create',
  },
  ButtonEdit: {
    id: 'Buttons.Edit',
    defaultMessage: 'Error.Buttons.Edit',
  },
  ButtonRead: {
    id: 'Buttons.Read',
    defaultMessage: 'Error.Buttons.Read',
  },
  ButtonSearch: {
    id: 'Buttons.Search',
    defaultMessage: 'Error.Buttons.Search',
  },
  Code: {
    id: 'Model.Role.Code',
    defaultMessage: 'Error.Model.Role.Code',
  },
  Fail: {
    id: 'Toasts.Request.Fail',
    defaultMessage: 'Error.Toasts.Request.Fail',
  },
  NoElement: {
    id: 'Shared.NoElement',
    defaultMessage: 'Error.Shared.NoElement',
  },
  Placeholder: {
    id: 'Screens.Roles.List.Placeholder',
    defaultMessage: 'Error.Screens.Roles.List.Placeholder',
  },
  Title: {
    id: 'Screens.Roles.List.Title',
    defaultMessage: 'Error.Screens.Roles.List.Title',
  },
});
