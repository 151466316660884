import * as yup from 'yup';
import { MESSAGE_MAX_LENGTH } from './constants';

const ModelFieldRequiredKey = {
  id: 'Model.FieldRequired',
  defaultMessage: 'Error.Model.FieldRequired',
};

const getValidationSchema = (formatMessage) =>
  yup.object().shape({
    message: yup
      .string()
      .max(
        MESSAGE_MAX_LENGTH,
        formatMessage({
          id: 'Screens.Banners.Errors.MessageTooLong',
          defaultMessage: 'Error.Screens.Banners.Errors.MessageTooLong',
        }),
      )
      .required(formatMessage(ModelFieldRequiredKey)),
    startDate: yup.date().required(formatMessage(ModelFieldRequiredKey)),
    endDate: yup.date().min(
      yup.ref('startDate'),
      formatMessage({
        id: 'Screens.Banners.Errors.EndDateAfterStartDate',
        defaultMessage: 'Error.Screens.Banners.Errors.EndDateAfterStartDate',
      }),
    ),
    valid: yup.boolean().required(),
    bannersUserTypes: yup
      .array()
      .of(
        yup
          .string()
          .oneOf(['PATIENT', 'PRACTITIONER'])
          .required(formatMessage(ModelFieldRequiredKey)),
      )
      .min(1, formatMessage(ModelFieldRequiredKey))
      .required(formatMessage(ModelFieldRequiredKey)),
    startHour: yup.string().matches(
      /^(\d{2}):(\d{2})$/,
      formatMessage({
        id: 'Screens.Banners.Errors.StartTimeFormat',
        defaultMessage: 'Error.Screens.Banners.Errors.StartTimeFormat',
      }),
    ),
    endHour: yup
      .string()
      .matches(
        /^(\d{2}):(\d{2})$/,
        formatMessage({
          id: 'Screens.Banners.Errors.EndTimeFormat',
          defaultMessage: 'Error.Screens.Banners.Errors.EndTimeFormat',
        }),
      )
      .test(
        'is-greater-than-start-hour',
        formatMessage({
          id: 'Screens.Banners.Errors.EndTimeAfterStartTime',
          defaultMessage: 'Error.Screens.Banners.Errors.EndTimeAfterStartTime',
        }),
        function (value) {
          const { startDate, endDate, startHour } = this.parent;
          if (
            startDate &&
            endDate &&
            startDate.getTime() === endDate.getTime()
          ) {
            if (!value) return;
            const [startHours, startMinutes] = startHour.split(':').map(Number);
            const [endHours, endMinutes] = value.split(':').map(Number);
            const startTime = new Date(0, 0, 0, startHours, startMinutes);
            const endTime = new Date(0, 0, 0, endHours, endMinutes);
            return endTime > startTime;
          }
          return true;
        },
      ),
  });

export { getValidationSchema };
