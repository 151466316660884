import { useContext } from 'react';
import { Field, Formik, Form } from 'formik';
import { useIntl } from 'react-intl';
import { AuthenticationContext } from '@teladochealth/auth';
import {
  Ds,
  RegularButton,
  TextField,
  toaster,
} from '@teladochealth/components';
import { useHistory } from 'react-router-dom';
import { useMeasure } from 'react-use';

import validationSchema from './validationSchema';
import { regularExpressions } from './RegularExpressions';
import { Helper, Translation } from '../../components';
import { saveToStorage, useOidc } from '../../utils';
import { changePassword } from '../../services/identity/users/ChangePassword';
import { getUserInformation } from '../../services/manager/admins/UserInformation';

const className = 'password-expired';

const PasswordExpired = () => {
  const { formatMessage } = useIntl();
  const history: any = useHistory();
  const oidcUser = useOidc();
  const Context: any = useContext(AuthenticationContext);
  const [ref, { right }] = useMeasure();

  const onSubmit = async (values) => {
    try {
      await changePassword(oidcUser.profile.idUser, values);
      const response = await getUserInformation();

      // TODO: We should not store user's information into localStorage
      // but rather in a store in consumer's websites (ex: Patient, Médecin)
      const newOidc: any = { ...oidcUser, profile: response.data };
      saveToStorage(Context.auth.currentConfiguration.localStorageKey, newOidc);
      const redirectURI: string = history.location.state?.referrer ?? '/';

      toaster({
        title: (
          <Translation id='artifact_auth_page_resetPassword_toast_success' />
        ),
        type: 'success',
      });

      history.push(redirectURI);
    } catch (error) {
      console.error(error);
      toaster({
        text: <Translation id={`api_error_${error.message}`} />,
        type: 'error',
      });
    }
  };

  return (
    <Ds>
      <main {...{ className }}>
        <header>
          <img className={`${className}__logo`} alt='MedecinDirect' />
        </header>
        <div className={`${className}__form`}>
          <Formik
            {...{ onSubmit }}
            initialValues={{
              oldPassword: '',
              confirmPassword: '',
              newPassword: '',
            }}
            validationSchema={() => validationSchema(formatMessage)}
          >
            {({ values }) => (
              <Form className='vstack gap-4'>
                <h3 className={`${className}__title`}>
                  <Translation id='screens_password_expired_title' />
                </h3>
                <p className={`${className}__subtitle`}>
                  <Translation id='screens_password_expired_subtitle' />
                </p>
                <div className='vstack gap-2'>
                  <Field name='oldPassword'>
                    {({ field, form }) => (
                      <TextField
                        {...field}
                        autocomplete='off'
                        id='input-old-password'
                        options={{
                          errorMessage:
                            form.touched[field.name] && form.errors[field.name],
                          label: (
                            <Translation id='shared_password_renewal_old_password' />
                          ),
                          passwordToggler: {
                            hide: (
                              <Translation id='Artifact.Auth.Input.PasswordToggler.Hide' />
                            ),
                            show: (
                              <Translation id='Artifact.Auth.Input.PasswordToggler.Show' />
                            ),
                          },
                        }}
                        type='password'
                      />
                    )}
                  </Field>
                  <Field name='newPassword'>
                    {({ field, form }) => (
                      <TextField
                        {...field}
                        autocomplete='off'
                        data-tracking='LoginResetPasswordNewPasswordInput'
                        id='input-new-password'
                        options={{
                          errorMessage:
                            form.touched[field.name] && form.errors[field.name],
                          label: (
                            <Translation id='shared_password_renewal_new_password' />
                          ),
                          passwordToggler: {
                            hide: (
                              <Translation id='Artifact.Auth.Input.PasswordToggler.Hide' />
                            ),
                            show: (
                              <Translation id='Artifact.Auth.Input.PasswordToggler.Show' />
                            ),
                          },
                        }}
                        type='password'
                      />
                    )}
                  </Field>
                  <Field name='confirmPassword'>
                    {({ field, form }) => (
                      <TextField
                        {...field}
                        autocomplete='off'
                        data-tracking='LoginResetPasswordNewPasswordConfirmationInput'
                        id='input-confirm-password'
                        options={{
                          errorMessage:
                            form.touched[field.name] && form.errors[field.name],
                          label: (
                            <Translation id='shared_password_renewal_confirm_password' />
                          ),
                          passwordToggler: {
                            hide: (
                              <Translation id='Artifact.Auth.Input.PasswordToggler.Hide' />
                            ),
                            show: (
                              <Translation id='Artifact.Auth.Input.PasswordToggler.Show' />
                            ),
                          },
                        }}
                        type='password'
                      />
                    )}
                  </Field>
                  <ul className='list-unstyled vstack gap-1 mb-0'>
                    {Object.values(regularExpressions).map((element) => (
                      <li key={element.translationKey}>
                        <Helper
                          isValid={
                            values.newPassword &&
                            element.regularExpression.test(values.newPassword)
                          }
                        >
                          <Translation id={element.translationKey} />
                        </Helper>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className='text-right'>
                  <RegularButton type='submit'>
                    <Translation id='Buttons.Save' />
                  </RegularButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className={`${className}__cover`} ref={ref}>
          <div
            className={`${className}__cover-item`}
            style={{ left: `calc(100vw - ${right}px)` }}
          ></div>
        </div>
      </main>
    </Ds>
  );
};

PasswordExpired.displayName = 'Screens_PasswordExpired';

export { PasswordExpired };
