import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Alert, Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';

import PatientInformations from './PatientInformations';
import { service } from 'services';
import { api } from 'utils';
import { Spinner } from 'components';
import messages from './messages';

const NewDialogue = () => {
  const { patientId } = useParams();
  const intl = useIntl();
  const [prerequisitesTrigger, setPrerequisitesTrigger] = useState(null);
  const [prerequisites, setPrerequisites] = useState(null);
  const [alert, setAlert] = useState(null);
  const [alertVisible, setAlertVisible] = useState(true);

  const setAlertMessage = (messages, color = 'success') => {
    setAlert({ messages, color });
    setAlertVisible(true);
  };

  useEffect(() => {
    service.get(api.dialogues.getPrerequisites(patientId)).then((response) => {
      setPrerequisites(response.data);
      const errors = [];
      if (!response.data.hasValidatedIdentity) {
        errors.push(intl.formatMessage(messages.identityValidationAlert));
      }
      if (!response.data.hasAddressValid) {
        errors.push(intl.formatMessage(messages.AddressValidAlert));
      }
      if (!response.data.hasPatientFileCompleted) {
        errors.push(intl.formatMessage(messages.PatientFileCompletedAlert));
      }
      if (errors.length > 0) setAlertMessage(errors, 'danger');
    });
  }, [patientId, prerequisitesTrigger]);

  if (prerequisites === null) {
    return <Spinner />;
  }

  return (
    <>
      {Boolean(alert) && (
        <Alert color={alert.color} isOpen={alertVisible}>
          {alert.messages.length > 0 && (
            <ul>
              {alert.messages.map((mes) => (
                <li key={mes}>
                  <p>{mes}</p>
                </li>
              ))}
            </ul>
          )}
        </Alert>
      )}
      <Row>
        <Col lg='12'>
          <PatientInformations
            {...prerequisites}
            setMessages={setAlertMessage}
            setPrerequisitesTrigger={setPrerequisitesTrigger}
            data-tracking='patientInformationsTab'
          />
        </Col>
      </Row>
    </>
  );
};

export default NewDialogue;
