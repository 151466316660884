import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'formik';
import { Input, FormFeedback } from 'reactstrap';
import shortid from 'shortid';
import bytes from 'bytes';

import { FormUploadFileWrapper, SubLabel } from './elements';
import messages from './messages';

const InputFileForm = ({
  name,
  value,
  error,
  buttonLabel,
  singleFile,
  size,
  subLabel,
}) => {
  const availableSize = value.reduce(
    (acc, currentValue) => acc + currentValue.File.size,
    0,
  );

  const addFile = (e, push) => {
    if (value.length && singleFile) {
      return;
    }
    if (e.currentTarget.files[0]) {
      push({
        id: shortid.generate(),
        File: e.currentTarget.files[0],
      });
    }
  };

  const displayButton = !singleFile || (!value.length && singleFile);

  return (
    <FieldArray name={name}>
      {({ push, remove }) => (
        <FormUploadFileWrapper>
          {subLabel && <SubLabel>{subLabel}</SubLabel>}
          <Field name={name}>
            {() => (
              <>
                {displayButton && (
                  <label
                    className={`btn ${
                      size !== null ? `btn-${size}` : ''
                    } btn-outline-primary`}
                    htmlFor='file'
                  >
                    {buttonLabel || <FormattedMessage {...messages.Label} />}
                  </label>
                )}
                <Input
                  className='hidden-input'
                  id='file'
                  name={name}
                  invalid={!!error}
                  type='file'
                  accept='image/png, image/jpeg, application/pdf'
                  onChange={(e) => addFile(e, push)}
                />
                <FormFeedback valid={false}>{error}</FormFeedback>
              </>
            )}
          </Field>
          <FormUploadFileWrapper.AvailableSizeWrapper>
            <FormattedMessage
              {...messages.AvailableSpace}
              values={{ availableSpace: bytes(26210000 - availableSize) }}
            />
          </FormUploadFileWrapper.AvailableSizeWrapper>
          {!!value.length && (
            <FormUploadFileWrapper.FileListWrapper>
              {value.map((file, id) => (
                <li key={file.id}>
                  {file.File.type === 'image/jpeg' ||
                  file.File.type === 'image/png' ? (
                    <img
                      src={URL.createObjectURL(file.File)}
                      alt='upload-preview'
                    />
                  ) : (
                    <p className='file-element'>{file.File.name}</p>
                  )}
                  <button type='button' onClick={() => remove(id)}>
                    <i
                      className='uil uil-times uil--sm align-middle'
                      aria-hidden
                    />
                  </button>
                </li>
              ))}
            </FormUploadFileWrapper.FileListWrapper>
          )}
        </FormUploadFileWrapper>
      )}
    </FieldArray>
  );
};

InputFileForm.defaultProps = {
  name: 'files',
  label: '',
  error: undefined,
  buttonLabel: undefined,
  singleFile: false,
  size: null,
  subLabel: '',
  disabled: false,
};

InputFileForm.propTypes = {
  name: PropTypes.string,
  value: PropTypes.array.isRequired,
  error: PropTypes.string,
  label: PropTypes.string,
  buttonLabel: PropTypes.string,
  singleFile: PropTypes.bool,
  size: PropTypes.string,
  subLabel: PropTypes.string,
  disabled: PropTypes.bool,
};

export default InputFileForm;
