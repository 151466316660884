import { useQuery } from '@tanstack/react-query';
import { isNil, isObject } from 'lodash';

import { Translation } from '../../../components';
import { getPractitioners } from '../../../services/identity/directories/getPractitioners';
import { IPractitioner } from 'types';

const fetchDoctor = async ({ queryKey }) => {
  const [_key, { identifier }] = queryKey;
  try {
    const response = await getPractitioners(identifier.id, identifier.type);

    if (response && 'succeeded' in response && response.succeeded === false) {
      throw new Error(response.errorSet[0].code);
    }

    return response[0];
  } catch (error) {
    console.error(error);
  }
};

const LegalInformation = ({
  data: practitionerData = null,
  identifier = null,
}: {
  data?: IPractitioner;
  identifier?: { id: string; type: string };
}) => {
  const {
    data,
    isError,
    isFetching,
  }: { data: IPractitioner; isError: boolean; isFetching: boolean } = useQuery({
    queryKey: ['getDirectoriesPractitioners', { identifier }],
    queryFn: fetchDoctor,
    enabled: isObject(identifier),
  });
  const doctor = practitionerData ?? data;

  if (isNil(data) && isNil(practitionerData)) return null;

  if (isError) return null;

  return (
    <div className='border rounded p-4 mb-3'>
      <div className='row'>
        <div className='col-lg-6'>
          <div className='model__property'>
            <div className='model__title'>
              <Translation id='Model.Doctor.DirectoryFirstName' />
            </div>
            <div className='model__value'>{doctor.firstName}</div>
          </div>
        </div>
        <div className='col-lg-6'>
          <div className='model__property'>
            <div className='model__title'>
              <Translation id='Model.Doctor.DirectoryName' />
            </div>
            <div className='model__value'>{doctor.lastName}</div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-6'>
          <div className='model__property'>
            <div className='model__title'>
              <Translation id='Model.Doctor.DirectorySpecialty' />
            </div>
            <div className='model__value'>{doctor.professionLabel}</div>
          </div>
        </div>
        <div className='col-lg-6'>
          <div className='model__property'>
            <div className='model__title'>
              <Translation id='Model.Doctor.DirectoryProfessionalCategory' />
            </div>
            <div className='model__value'>
              {doctor.professionalCategoryLabel}
            </div>
          </div>
        </div>
        <div className='col-lg-6'>
          <div className='model__property'>
            <div className='model__title'>
              <Translation id='Model.Doctor.DirectoryIDNPS' />
            </div>
            <div className='model__value'>{doctor.nationalIdentifier}</div>
          </div>
        </div>
      </div>
      {doctor.email ? (
        <div className='row'>
          <div className='col-lg-12'>
            <div className='model__property mb-0'>
              <div className='model__title'>
                <Translation id='Model.Doctor.DirectoryEMail' />
              </div>
              <div className='model__value'>{doctor.email}</div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

LegalInformation.displayName = 'Models_Doctor_LegalInformation';

export { LegalInformation };
