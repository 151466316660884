import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams, Link, useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { locationShape } from 'react-router-props';
import { Spinner } from 'reactstrap';
import moment from 'moment';

import {
  ApiError,
  Button,
  PageHeader,
  RoleManager,
  Section,
  SectionBody,
  Translation,
} from '../../../components';
import { Doctor } from '../../../models';
import { service } from '../../../services';
import { api, sendToast, useFetch, urlSchema } from '../../../utils';
import validationSchema from '../../../models/Doctor/validationSchema';
import { ButtonsWrapper } from '../../../themes/jupiter/elements';
import { ToastTypes } from '../../../constants';

const Update = () => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const { idUser } = useParams();
  const [doctor, isDoctorLoading] = useFetch(api.doctors.getDetails(idUser));
  const [universes, areUniversesLoading] = useFetch(api.universes.getListItems);
  const [formLogoData, setFormLogoData] = useState(null);
  const [doctorDocument, setDoctorDocument] = useState();
  const [doctorDirectoryData, setDoctorDirectoryData] = useState();
  const [isDoctorDirectoryDataLoading, setIsDoctorDirectoryDataLoading] =
    useState(true);

  const matchingIdentifierType = { PA: 0, PR: 8 };

  const getDoctorDocument = () => {
    service
      .get(
        api.doctors.getDocument(doctor.idUser, doctor.refDocumentScanSignature),
      )
      .then(({ data }) => {
        setDoctorDocument(data);
      });
  };

  const getDoctorDirectoryData = async () => {
    try {
      const { data } = await service.get(
        api.doctors.getPractitioner(doctor.collegeNumber),
        {
          params: {
            identifierType:
              matchingIdentifierType[doctor.practitionerIdentifierNumberType],
          },
        },
      );
      setDoctorDirectoryData(
        data.find(
          (element) =>
            Number.parseInt(element.id) === doctor.directoryMatchingId,
        ),
      );
    } catch (error) {
      console.log(error);
    }
    setIsDoctorDirectoryDataLoading(false);
  };

  const onSubmit = (values, { setSubmitting }) => {
    const formattedValues = values;

    formattedValues.birthdate = moment(values.birthdate).format('YYYY-MM-DD');
    // Cast boolean for radiobutton
    formattedValues.cpsCard = JSON.parse(values.cpsCard);
    formattedValues.cpsReader = JSON.parse(values.cpsReader);
    formattedValues.generateFeesNotes = JSON.parse(values.generateFeesNotes);
    formattedValues.prescriptionBook = JSON.parse(values.prescriptionBook);
    // Formatting phone numbers for back-end regex
    formattedValues.mobileNumber = values.mobileNumber.replace('+', '00');
    formattedValues.phoneNumber = values.phoneNumber.replace('+', '00');

    if (formLogoData !== null) {
      service
        .post(api.doctors.postScanSignature(idUser), formLogoData)
        .catch(({ error }) => {
          sendToast(ToastTypes.error, {
            header: <Translation id='Message.Error' />,
            body: <ApiError {...{ error }} />,
          });
        });
    }
    service
      .put(api.doctors.put(idUser), formattedValues)
      .then(({ data }) => {
        if (data.succeeded === false) {
          sendToast(ToastTypes.error, {
            header: <Translation id='Message.Error' />,
            body: data.errorSet[0].description,
          });
          return;
        }
        sendToast(ToastTypes.success, {
          header: <Translation id='Message.Success' />,
          body: <Translation id='Screens.Doctors.Update.SuccesBody' />,
        });
        history.push(`${urlSchema.doctors.read}/${idUser}`);
      })
      .catch((error) => {
        sendToast(ToastTypes.error, {
          header: <Translation id='Message.Error' />,
          body: error.response.data.errorSet[0].innerMessage,
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (!isDoctorLoading) {
      getDoctorDirectoryData();
      doctor.refDocumentScanSignature && getDoctorDocument();
    }
  }, [isDoctorLoading]);

  return (
    <>
      <PageHeader>
        <Translation
          id='Screens.Doctors.Update.Title'
          tagName='h1'
          values={{
            name: `${doctor?.title} ${doctor?.firstname} ${doctor?.lastname}`,
          }}
        />
        <RoleManager entity='Doctors' right='CRUD'>
          <Link
            className='btn btn-primary'
            to={`${urlSchema.doctors.editProfiles}/${doctor?.idUser}`}
          >
            <Translation id='Buttons.ManageProfiles' />
          </Link>
          <Link
            className='btn btn-primary'
            to={`${urlSchema.doctors.editSpecialties}/${doctor?.idUser}`}
          >
            <Translation id='Buttons.ManageSpecialties' />
          </Link>
        </RoleManager>
      </PageHeader>
      <Section>
        <SectionBody>
          {isDoctorLoading ||
          areUniversesLoading ||
          isDoctorDirectoryDataLoading ? (
            <Spinner color='primary' size='lg' />
          ) : (
            <>
              <div className='mb-3'>
                <Link
                  className='btn btn-outline-warning btn-sm ml-auto'
                  to={`${urlSchema.universes.read}/${doctor.refUniverse}`}
                >
                  {
                    universes.find(
                      (element) => element.id === doctor.refUniverse,
                    ).label
                  }
                  (
                  {
                    universes.find(
                      (element) => element.id === doctor.refUniverse,
                    ).code
                  }
                  )
                </Link>
              </div>
              <Formik
                initialValues={{
                  ...doctor,
                  address2: doctor.address2 === null ? '' : doctor.address2,
                  birthdate: moment(doctor.birthdate).format('YYYY-MM-DD'),
                  collegeNumberAutocomplete: doctor.collegeNumber,
                  cpsCard:
                    doctor.cpsCard === null
                      ? 'null'
                      : doctor.cpsCard.toString(),
                  cpsReader:
                    doctor.cpsReader === null
                      ? 'null'
                      : doctor.cpsReader.toString(),
                  feesContractTypeId: doctor.feesContractTypeId.toString(),
                  generateFeesNotes: doctor.generateFeesNotes.toString(),
                  googleAddress: '',
                  ordinalQualificationId:
                    doctor.ordinalQualificationId.toString(),
                  phoneNumber:
                    doctor.phoneNumber === null ? '' : doctor.phoneNumber,
                  prescriptionBook:
                    doctor.prescriptionBook === null
                      ? 'null'
                      : doctor.prescriptionBook.toString(),
                }}
                {...{ onSubmit }}
                validationSchema={validationSchema(formatMessage)}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Doctor
                      type='update'
                      setFormLogoData={setFormLogoData}
                      doctorDocument={doctorDocument}
                      doctorDirectoryDataFromUpdate={doctorDirectoryData}
                    />
                    <ButtonsWrapper bottom>
                      <Button
                        color='primary'
                        isProcessing={isSubmitting}
                        label='Update'
                        size='lg'
                        data-tracking='DoctorUpdateSubmit'
                        type='submit'
                      />
                    </ButtonsWrapper>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </SectionBody>
      </Section>
    </>
  );
};

Update.propTypes = { location: locationShape.isRequired };

Update.displayName = 'Screens_Doctors_Update';

export default Update;
