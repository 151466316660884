import React from 'react';
import { Link } from 'react-router-dom';
import { urlSchema } from 'utils';
import { PageHeader, RoleManager, Spinner, Translation } from 'components';
import Item from './Item';

const List = ({ banners }) => {
  return (
    <>
      <PageHeader>
        <h1>
          <Translation id='Screens.Banners.List.Title' />
        </h1>
        <RoleManager entity='Banners' right='CRUD'>
          <Link
            to={urlSchema.banners.create()}
            className='btn btn-primary btm-sm'
          >
            <Translation id='Buttons.Create' />
          </Link>
        </RoleManager>
      </PageHeader>
      <section className='section'>
        <div className='section__body'>
          <div className='container-fluid'>
            <div className='row mt-3'>
              {banners.isLoading ? (
                <Spinner />
              ) : banners.data.length > 0 ? (
                banners.data.map((item) => <Item key={item.id} data={item} />)
              ) : (
                <Translation id='Shared.NoResultFound' />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default List;
