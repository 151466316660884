import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const Translation = ({ id, values, tagName, children }) => {
  return (
    <FormattedMessage
      id={id}
      defaultMessage={`Error.${id}`}
      values={values}
      tagName={tagName}
    >
      {children}
    </FormattedMessage>
  );
};

Translation.displayName = 'Translation';

Translation.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
  tagName: PropTypes.string,
  children: PropTypes.any,
};

export default Translation;
