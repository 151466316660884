import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonDeleteAccount: {
    id: 'Buttons.DeleteAccount',
    defaultMessage: 'Error.Buttons.DeleteAccount',
  },
  Confirm: {
    id: 'Screens.Patients.Delete.Confirm',
    defaultMessage: 'Error.Screens.Patients.Delete.Confirm',
  },
  Description: {
    id: 'Screens.Patients.Delete.Description',
    defaultMessage: 'Screens.Patients.Delete.Description',
  },
  SuccessBody: {
    id: 'Toasts.Patients.Delete.Success',
    defaultMessage: 'Error.Toasts.Patients.Delete.Success',
  },
  SuccessTitle: {
    id: 'Shared.Success',
    defaultMessage: 'Error.Shared.Success',
  },
  Title: {
    id: 'Screens.Patients.Delete.Title',
    defaultMessage: 'Error.Screens.Patients.Delete.Title',
  },
  Warning: {
    id: 'Screens.Patients.Delete.Warning',
    defaultMessage: 'Error.Screens.Patients.Delete.Warning',
  },
});
