import styled, { css } from 'styled-components';

const GreyBlockWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.grayShade(100)};
    padding: ${theme.space(3)};
    margin-bottom: ${theme.space(3)};
    border-radius: ${theme.borderRadiusSm};
  `}
`;

export { GreyBlockWrapper };
