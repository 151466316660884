import React from 'react';
import { Switch } from 'react-router-dom';

import { ProtectedRoute } from 'components';
import { urlSchema } from 'utils';

import Read from './Read';
import Create from './Create';
import Edit from './Edit';

const routes = [
  {
    path: `${urlSchema.productTermsResources.create}/:refProductTerm`,
    component: Create,
    right: 'CRUD',
  },
  {
    path: `${urlSchema.productTermsResources.read}/:idProductTermResource`,
    component: Read,
    right: 'Reader',
  },
  {
    path: `${urlSchema.productTermsResources.edit}/:idProductTermResource`,
    component: Edit,
    right: 'CRUD',
  },
];

const ProductTermsResources = () => {
  return (
    <Switch>
      {routes.map((item) => (
        <ProtectedRoute
          entity='ProductTermsResources'
          key={item.path}
          {...item}
        />
      ))}
    </Switch>
  );
};

export default ProductTermsResources;
