import styled from 'styled-components';

const DragAndDropWrapper = styled.div`
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 2px dashed
    ${({ hovered, theme }) => (hovered ? theme.blue : theme.color('border'))};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100px;
  padding: ${({ theme }) => theme.space(3)};
  text-align: center;
  transition: ${({ theme }) => theme.transitionBase};
  width: 100%;

  i,
  p {
    color: ${({ hovered, theme }) =>
      hovered ? theme.blue : theme.color('gray-font')};
    transition: ${({ theme }) => theme.transitionBase};
  }

  i {
    margin-bottom: ${({ theme }) => theme.space(3)};
  }

  p {
    ${({ theme }) => theme.fontSize(theme.fontSizeSm())};
    margin: 0;
  }
`;

export default DragAndDropWrapper;
