import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonEdit: {
    id: 'Buttons.Edit',
    defaultMessage: 'Error.Buttons.Edit',
  },
  ButtonAdd: {
    id: 'Buttons.Add',
    defaultMessage: 'Error.Buttons.Add',
  },
  ButtonManageProductTags: {
    id: 'Buttons.ManageProductTags',
    defaultMessage: 'Error.Buttons.ManageProductTags',
  },
  CreatedAt: {
    id: 'Customers.CreatedAt',
    defaultMessage: 'Error.Customers.CreatedAt',
  },
  CreationDate: {
    id: 'Model.CreationDate',
    defaultMessage: 'Error.Model.CreationDate',
  },
  ClientSellsy: {
    id: 'Customers.ClientSellsy',
    defaultMessage: 'Error.Customers.ClientSellsy',
    description: 'ClientSellsy of an customers',
  },
  Code: {
    id: 'Customers.Code',
    defaultMessage: 'Error.Customers.Code',
    description: 'Code of an customers',
  },
  DetailsOf: {
    id: 'Screens.Customers.Read.Title',
    defaultMessage: 'Error.Screens.Customers.Read.Title',
    description: 'Details of an customer page title',
  },
  Label: {
    id: 'Customers.Label',
    defaultMessage: 'Error.Customers.Label',
    description: 'Label of an customers',
  },
  NoElement: {
    id: 'Shared.NoElement',
    defaultMessage: 'Error.Shared.NoElement',
  },
  Organization: {
    id: 'Customers.Organization',
    defaultMessage: 'Error.Customers.Organization',
    description: 'Organization of an customers',
  },
  Products: {
    id: 'Screens.Customer.Read.Products',
    defaultMessage: 'Error.Screens.Customer.Read.Products',
    description: 'Products title',
  },
  Valid: {
    id: 'Shared.Valid',
    defaultMessage: 'Error.Shared.Valid',
  },
  Inactive: {
    id: 'Shared.Inactive',
    defaultMessage: 'Error.Shared.Inactive',
  },
});
