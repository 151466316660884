import { defineMessages } from 'react-intl';

const messages = defineMessages({
  ButtonShow: { id: 'Buttons.Show', defaultMessage: 'Error.Buttons.Show' },
  MadeOn: {
    id: 'Screens.Patients.Read.PatientDialoguesList.MadeOn',
    defaultMessage: 'Error.Screens.Patients.Read.PatientDialoguesList.MadeOn',
  },
  FromOperator: {
    id: 'Screens.Patients.Read.PatientDialoguesList.FromOperator',
    defaultMessage:
      'Error.Screens.Patients.Read.PatientDialoguesList.FromOperator',
  },
  AppointmentDate: {
    id: 'Screens.Patients.Read.PatientDialoguesList.AppointmentDate',
    defaultMessage:
      'Error.Screens.Patients.Read.PatientDialoguesList.AppointmentDate',
  },
  NoElement: {
    id: 'Shared.NoElement',
    defaultMessage: 'Error.Shared.NoElement',
  },
});

export default messages;
