import { service } from '../../../services';
import { api } from '../../../utils';
import {
  IdentityDocumentTypesDte,
  IdentityDocumentValidationStatusesDtestring,
  InsDocumentValidationStatus,
} from '../types';

export type PutDocumentStatusPayload = {
  patientId: string;
  documentType: IdentityDocumentTypesDte;
  documentStatus: InsDocumentValidationStatus;
  refusalReason: IdentityDocumentValidationStatusesDtestring;
};

interface ErrorResponse {
  succeeded: false;
  errorSet: {
    stacktrace: string;
    code: string;
    description: string;
    innerMessage: null;
  }[];
}

export const putDocumentStatus = async (
  parameters?: PutDocumentStatusPayload,
) => {
  try {
    const { data } = await service.put<undefined | ErrorResponse>(
      api.identityValidations.putDocumentStatus,
      parameters,
    );

    if (data && 'succeeded' in data && data.succeeded === false) {
      const errorResponse = data as ErrorResponse;
      throw new Error(errorResponse.errorSet[0].code);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};
