import React from 'react';
import PropTypes from 'prop-types';
import CloseButtonWrapper from './elements';

const CloseButton = ({ closeToast }) => (
  <CloseButtonWrapper type='button' onClick={closeToast}>
    <i aria-hidden className='uil uil-multiply uil--md' />
  </CloseButtonWrapper>
);

CloseButton.propTypes = {
  closeToast: PropTypes.func.isRequired,
};

export default CloseButton;
