import React, { ReactElement, useEffect } from 'react';
import Select from 'react-select';
import { Translation } from '../../components';
import { useIdentityVerificationNhiStore } from './store';
import { InsDocumentValidationStatus } from './types';

type SelectOptions = {
  value?: InsDocumentValidationStatus;
  label: ReactElement;
}[];

const selectOptions: SelectOptions = [
  {
    value: undefined,
    label: (
      <Translation id='Screens.IdentityValidationsIns.DocumentValidationStatus.All' />
    ),
  },
  {
    value: InsDocumentValidationStatus.Pending,
    label: (
      <Translation id='Screens.IdentityValidationsIns.DocumentValidationStatus.Pending' />
    ),
  },
  {
    value: InsDocumentValidationStatus.Refused,
    label: (
      <Translation id='Screens.IdentityValidationsIns.DocumentValidationStatus.Refused' />
    ),
  },
  {
    value: InsDocumentValidationStatus.Validated,
    label: (
      <Translation id='Screens.IdentityValidationsIns.DocumentValidationStatus.Validated' />
    ),
  },
];

export const Header = () => {
  const setSearchStatus = useIdentityVerificationNhiStore(
    (store) => store.setSearchStatus,
  );

  const { searchString, setSearchString } = useIdentityVerificationNhiStore(
    (store) => ({
      searchString: store.searchString,
      setSearchString: store.setSearchString,
    }),
  );

  const isLoading = useIdentityVerificationNhiStore((store) => store.isLoading);

  const fetchUsers = useIdentityVerificationNhiStore(
    (store) => store.fetchUsers,
  );

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className='section__header px-0'>
      <form className='w-100'>
        <div className='row'>
          <div className='col-4'>
            <Select
              defaultValue={selectOptions.find(
                (option) =>
                  option.value === InsDocumentValidationStatus.Pending,
              )}
              onChange={(event) => setSearchStatus(event.value)}
              placeholder='Statut'
              options={selectOptions}
            />
          </div>
          <div className='col-5'>
            <div className='input-group'>
              <input
                type='text'
                className='form-control'
                value={searchString}
                onChange={(event) => setSearchString(event.target.value)}
                placeholder='Entrez un nom, prénom, email, etc.'
              />
              {searchString && (
                <div className='input-group-append'>
                  <button
                    className='btn btn-outline-primary'
                    onClick={() => setSearchString('')}
                    type='button'
                  >
                    <i aria-hidden className='align-middle uil uil-times' />
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className='col-3'>
            <button
              disabled={isLoading}
              onClick={fetchUsers}
              className='btn btn-primary w-100'
              type='button'
            >
              <Translation id='Buttons.Search' />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
