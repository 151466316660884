import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext, getIn } from 'formik';
import { TabContent, NavLink, Nav, NavItem } from 'reactstrap';

import FormLabel from '../FormLabel';
import TranslationFormWrapper from './elements';

const TranslationForm = ({ label, validLanguages, children }) => {
  const languages = validLanguages
    .sort((a, b) => a.ranking - b.ranking)
    .map((lang) => lang.code);
  const [currentLang, setCurrentLang] = useState(() => languages[0]);
  const { errors, touched } = useFormikContext();

  const getStatus = (lang) => {
    const hasBeenTouched = getIn(touched, `[${lang}]`);
    const hasError = getIn(errors, `[${lang}]`);

    if (!hasBeenTouched) return;
    if (hasError)
      return (
        <i aria-hidden className='uil uil-exclamation-triangle text-danger' />
      );
    return <i aria-hidden className='uil uil-check-circle text-success' />;
  };

  return (
    <TranslationFormWrapper>
      {label && <FormLabel>{label}</FormLabel>}
      <TranslationFormWrapper.Component>
        <Nav tabs>
          {languages.map((lang) => (
            <NavItem key={`navItem-${lang}`}>
              <NavLink
                className={lang === currentLang ? 'active' : ''}
                onClick={() => setCurrentLang(lang)}
              >
                {lang}
                {getStatus(lang)}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent>{children(currentLang)}</TabContent>
      </TranslationFormWrapper.Component>
    </TranslationFormWrapper>
  );
};

TranslationForm.propTypes = {
  label: PropTypes.string.isRequired,
  languages: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  children: PropTypes.func.isRequired,
};

export default TranslationForm;
