const initialValues = {
  title: '',
  message: '',
  valid: false,
  isGenerateQuestion: false,
  rawFile: null,
  refSpecialty: '',
  jsonContent: '{\r\n}',
  jsContent: 'function onValueChanged(){\r\n}',
  selectedProducts: [],
  selectedProfiles: [],
};

export default initialValues;
