import * as yup from 'yup';

import messages from './messages';

export default (formatMessage) =>
  yup.object().shape({
    email: yup.string().required(formatMessage({ ...messages.FieldRequired })),
    firstname: yup
      .string()
      .required(formatMessage({ ...messages.FieldRequired })),
    lastname: yup
      .string()
      .required(formatMessage({ ...messages.FieldRequired })),
  });
