import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { PageHeader, Spinner, RoleManager } from 'components';
import { api, useFetch, urlSchema } from 'utils';
import messages from './messages';

const List = () => {
  const [fileImportClients, FileImportClientsLoading] = useFetch(
    api.admin.getFileImportClients,
  );
  return (
    <>
      <PageHeader>
        <FormattedMessage {...messages.Title} tagName='h1' />
        <RoleManager entity='FileImportClients' right='CRUD'>
          <Link
            className='btn btn-primary btm-sm'
            to={urlSchema.fileImportClients.create}
          >
            <FormattedMessage {...messages.Create} />
          </Link>
        </RoleManager>
      </PageHeader>
      <section className='section'>
        <section className='section__body'>
          <header className='research-table__header'>
            <Container fluid>
              <Row>
                <Col md='2'>
                  <FormattedMessage {...messages.Partner} tagName='h5' />
                </Col>
                <Col md='3'>
                  <FormattedMessage {...messages.Entity} tagName='h5' />
                </Col>
                <Col md='3'>
                  <FormattedMessage
                    {...messages.AssociatedProduct}
                    tagName='h5'
                  />
                </Col>
                <Col md='auto'>
                  <FormattedMessage {...messages.Status} tagName='h5' />
                </Col>
              </Row>
            </Container>
          </header>
          {FileImportClientsLoading ? (
            <Spinner size='lg' />
          ) : (
            <ul className='research-table__results'>
              {fileImportClients.length > 0 ? (
                fileImportClients.map((item) => {
                  return (
                    <li key={item.idFileImportClient}>
                      <Container fluid>
                        <Row className='align-items-center'>
                          <Col md='2'>{item.codeClient}</Col>
                          <Col md='3'>{item.codeEntity}</Col>
                          <Col md='3'>
                            <Link
                              to={`${urlSchema.products.read}/${item.refProduct.id}`}
                            >
                              {item.refProduct.label}
                            </Link>
                          </Col>
                          <Col md='2'>
                            {item.status === true ? (
                              <FormattedMessage {...messages.Valid} />
                            ) : (
                              <FormattedMessage {...messages.Invalid} />
                            )}
                          </Col>
                          <Col className='ml-auto'>
                            <div className='research-table__actions'>
                              <RoleManager
                                entity='FileImportClients'
                                right='CRUD'
                              >
                                <Link
                                  to={`${urlSchema.fileImportClients.edit}/${item.idFileImportClient}`}
                                >
                                  <i
                                    aria-hidden
                                    className='uil uil--md uil-pen'
                                  />
                                </Link>
                              </RoleManager>
                              <RoleManager
                                entity='FileImportClients'
                                right='Reader'
                              >
                                <Link
                                  to={`${urlSchema.fileImportClients.read}/${item.idFileImportClient}`}
                                >
                                  <i
                                    aria-hidden
                                    className='uil uil--md uil-eye'
                                  />
                                </Link>
                              </RoleManager>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </li>
                  );
                })
              ) : (
                <FormattedMessage {...messages.NoElement} />
              )}
            </ul>
          )}
        </section>
      </section>
    </>
  );
};

export default List;
