import Select from 'react-select';
import { ErrorMessage, useFormikContext } from 'formik';
import { isNil } from 'lodash';
import { Translation } from 'components';
import { useIntl } from 'react-intl';
import { Form } from './types';

const placeTypeTranslationKeyMap = {
  FRENCH_PLACE: 'Shared.PlaceType.FrenchPlace',
  OTHER_COUNTRY: 'Shared.PlaceType.OtherCountry',
  UNKNOWN: 'Shared.PlaceType.Unknown',
};

export const PlaceTypeSelect = () => {
  const { formatMessage } = useIntl();
  const { values, setFieldValue } = useFormikContext<Form>();

  const options = [
    {
      value: 'FRENCH_PLACE',
      label: formatMessage({ id: placeTypeTranslationKeyMap['FRENCH_PLACE'] }),
    },
    {
      value: 'OTHER_COUNTRY',
      label: formatMessage({ id: placeTypeTranslationKeyMap['OTHER_COUNTRY'] }),
    },
    {
      value: 'UNKNOWN',
      label: formatMessage({ id: placeTypeTranslationKeyMap['UNKNOWN'] }),
    },
  ];

  const handleChange = (newValue: { value: string; label: string }) => {
    setFieldValue('birthPlace.name', undefined);
    setFieldValue('birthPlace.departmentCode', undefined);
    setFieldValue('birthPlace.inseeCode', undefined);
    setFieldValue('birthPlace.placeType', newValue.value);
  };

  const defaultValue = options.find(
    (option) => option.value === values.birthPlace.placeType,
  );

  return (
    <div className='form-group'>
      <Translation
        id='Patients.Nhis.Update.Input.BirthPlaceType'
        tagName='label'
      />
      <Select
        defaultValue={
          defaultValue ?? {
            value: 'UNKNOWN',
            label: formatMessage({ id: placeTypeTranslationKeyMap['UNKNOWN'] }),
          }
        }
        onChange={handleChange}
        placeholder='Statut'
        {...{ options }}
      />
      <p>
        <small className='text-danger'>
          <ErrorMessage name='birthPlace.placeType' />
        </small>
      </p>
    </div>
  );
};
