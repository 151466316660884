import React from 'react';
import { Switch } from 'react-router-dom';

import { ProtectedRoute } from 'components';
import { urlSchema } from 'utils';

import List from './List';
import Read from './Read';
import Create from './Create';
import Edit from './Edit';

const routes = [
  { path: urlSchema.productTerms.create, component: Create, right: 'CRUD' },
  {
    path: `${urlSchema.productTerms.read}/:idProductTerm`,
    component: Read,
    right: 'Reader',
  },
  {
    path: `${urlSchema.productTerms.edit}/:idProductTerm`,
    component: Edit,
    right: 'CRUD',
  },
  { path: '/', component: List, right: 'Reader' },
];

const ProductTerms = () => {
  return (
    <Switch>
      {routes.map((item) => (
        <ProtectedRoute entity='ProductTerms' key={item.path} {...item} />
      ))}
    </Switch>
  );
};

export default ProductTerms;
