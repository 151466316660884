import { defineMessages } from 'react-intl';

export default defineMessages({
  CheckBoxe: {
    id: 'Customers.CheckBoxe',
    defaultMessage: 'Error.Customers.CheckBoxe',
  },
  Edit: {
    id: 'Screens.Customers.Update.Title',
    defaultMessage: 'Error.Screens.Customers.Update.Title',
    description: 'Edit of an customer page title',
  },
  Organization: {
    id: 'Customers.Organization',
    defaultMessage: 'Error.Customers.Organization',
  },
  SuccessTitle: {
    id: 'Message.Success',
    defaultMessage: 'Error.Message.Success',
  },
  SuccessBody: {
    id: 'Screens.Customers.Update.SuccessBody',
    defaultMessage: 'Error.Screens.Customers.Update.SuccessBody',
  },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
  },
  FailBody: {
    id: 'Screens.Customers.Update.FailBody',
    defaultMessage: 'Error.Screens.Customers.Update.FailBody',
  },
  FieldRequired: {
    id: 'Model.FieldRequired',
    defaultMessage: 'Error.Model.FieldRequired',
  },
});
