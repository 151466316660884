import React from 'react';
import { useIntl } from 'react-intl';
import { Field, useFormikContext } from 'formik';

import {
  Checkbox,
  FormInput,
  FormInputFeedback,
  FormResources,
  FormSelect,
  Spinner,
} from 'components';
import { api, useFetch } from 'utils';
import messages from './messages';

const Specialty = () => {
  const { formatMessage } = useIntl();
  const [organisationsConfig, areOrganisationsConfigLoading] = useFetch(
    api.organizationConfigs.getListItems,
  );
  const { initialValues, setFieldTouched, values } = useFormikContext();

  return (
    <>
      <FormInput
        label={formatMessage({ ...messages.Code })}
        name='code'
        required
        type='text'
      />
      <FormResources
        keyLabelNav='languageCode'
        languagesArray={initialValues.name}
        name='name'
        title={formatMessage({ ...messages.Translations })}
      >
        {(index) => (
          <FormInput
            label={formatMessage({ ...messages.Label })}
            name={`name[${index}].content`}
            required
            type='text'
          />
        )}
      </FormResources>
      <Field name='refOrganizationConfig'>
        {({ field, meta, form }) =>
          areOrganisationsConfigLoading ? (
            <Spinner />
          ) : (
            <FormSelect
              hasError={Boolean(meta.touched && meta.error)}
              label={formatMessage({ ...messages.RefOrganizationConfig })}
              meta={meta}
              name={field.name}
              onBlur={() => setFieldTouched(field.name, true)}
              onChange={(opt) => {
                form.setFieldValue(field.name, opt.value);
              }}
              options={organisationsConfig.map((el) => {
                return {
                  code: el.code,
                  label: `${el.label} (${el.code})`,
                  value: el.id,
                };
              })}
              required
            />
          )
        }
      </Field>
      <div className='mb-3'>
        <Field name='active'>
          {({ field }) => (
            <Checkbox {...field} checked={values.active} id='is-active'>
              {formatMessage({ ...messages.Active })}
            </Checkbox>
          )}
        </Field>
        <FormInputFeedback name='active' />
      </div>
      <div className='mb-3'>
        <Field name='exclusive'>
          {({ field }) => (
            <Checkbox {...field} checked={values.exclusive} id='is-exclusive'>
              {formatMessage({ ...messages.Exclusive })}
            </Checkbox>
          )}
        </Field>
        <FormInputFeedback name='exclusive' />
      </div>
      <div className='mb-3'>
        <Field name='valid'>
          {({ field }) => (
            <Checkbox {...field} checked={values.valid} id='is-valid'>
              {formatMessage({ ...messages.Valid })}
            </Checkbox>
          )}
        </Field>
        <FormInputFeedback name='valid' />
      </div>
    </>
  );
};

export default Specialty;
