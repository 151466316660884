import { CodeCompany } from '../../../constants';

export default {
  email: '',
  firstname: '',
  lastname: '',
  infosAdmin: { codeCompany: CodeCompany.PARTENAIRE, selectedClients: [] },
  selectedClients: [],
  valid: false,
};
