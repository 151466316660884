import React from 'react';
import { FastField } from 'formik';
import { useIntl } from 'react-intl';

import { Checkbox, FormInputFeedback, FormInput } from 'components';
import messages from './messages';

const PartnerOperator = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <FormInput
        Component={FastField}
        hasRawError
        id='first-name'
        label={formatMessage({ ...messages.FirstName })}
        name='firstname'
        required
        type='text'
      />
      <FormInput
        Component={FastField}
        hasRawError
        id='last-name'
        label={formatMessage({ ...messages.LastName })}
        name='lastname'
        required
        type='text'
      />
      <FormInput
        Component={FastField}
        hasRawError
        label={formatMessage({ ...messages.Email })}
        id='email'
        name='email'
        required
        type='email'
      />
      <div className='mb-3'>
        <FastField name='valid'>
          {({ field, form }) => (
            <Checkbox {...field} checked={form.values.valid} id='is-valid'>
              {formatMessage({ ...messages.Valid })}
            </Checkbox>
          )}
        </FastField>
        <FormInputFeedback name='valid' />
      </div>
    </>
  );
};

export default PartnerOperator;
