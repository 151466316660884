import { defineMessages } from 'react-intl';

export default defineMessages({
  Fail: {
    id: 'Toasts.WebServicesConfiguration.Update.Fail',
    defaultMessage: 'Error.Toasts.WebServicesConfiguration.Update.Fail',
  },
  Success: {
    id: 'Toasts.WebServicesConfiguration.Update.Success',
    defaultMessage: 'Error.Toasts.WebServicesConfiguration.Update.Success',
  },
  Title: {
    id: 'Screens.WebServicesConfiguration.Update.Title',
    defaultMessage: 'Error.Screens.WebServicesConfiguration.Update.Title',
  },
});
