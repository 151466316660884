import { defineMessages } from 'react-intl';

export default defineMessages({
  Title: {
    id: 'Screens.Universes.Update.Title',
    defaultMessage: 'Error.Screens.Universes.Update.Title',
  },
  SuccessTitle: {
    id: 'Message.Success',
    defaultMessage: 'Error.Message.Success',
    description: 'Toast title in case of success',
  },
  SuccessBody: {
    id: 'Screens.Universes.Update.SuccessBody',
    defaultMessage: 'Error.Screens.Universes.Update.SuccessBody',
    description: 'Toast title in case of success',
  },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
    description: 'Toast title in case of Error',
  },
  FailBody: {
    id: 'Screens.Universes.Update.FailBody',
    defaultMessage: 'Error.Screens.Universes.Update.FailBody',
    description: 'Toast title in case of Error',
  },
  OrganizationConfigs: {
    id: 'Universes.OrganizationConfigs',
    defaultMessage: 'Error.Universes.OrganizationConfigs',
  },
});
