import styled, { css } from 'styled-components';

const UserLogsWrapper = styled.ul`
  ${({ theme }) => css`
    max-height: ${theme.space(7)};
    overflow-y: scroll;
    margin-right: -${theme.space(1)};
    overflow-x: hidden;
    border: 1px solid ${theme.grayShade(200)};
    border-right: none;
    border-radius: ${theme.space(1)};
    padding-right: ${theme.space(3)};
    padding-left: ${theme.space(3)};
    padding-top: ${theme.space(2)};
    padding-bottom: ${theme.space(2)};
  `}
`;

const Item = styled.li`
  ${({ theme, logType }) => css`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: ${theme.space(3)};
    border-left: 2px solid ${theme.grayShade(200)};
    padding-top: 1px;
    padding-bottom: 1px;
    color: ${theme.color('yellow-font')};
    ${logType === 'WEB_SERVICE' ? `color: ${theme.blue};` : ''};

    button {
      background-color: transparent;
      margin: 0;
      padding: 0;
      color: ${theme.color('yellow-font')};
      border: 0;
      ${logType === 'WEB_SERVICE' ? `color: ${theme.blue};` : ''};
    }

    p {
      ${theme.fontSize(theme.fontSizeSm(), theme.fontWeightBold)};
      margin-bottom: 0;
      text-decoration: underline;

      a {
        margin-left: ${theme.space(1)};
        color: ${theme.color('gray-font')};
        font-weight: ${theme.fontWeightNormal};
      }

      .infoText {
        margin-left: ${theme.space(1)};
        color: ${theme.color('gray-font')};
        // text-decoration: underline;
        font-weight: ${theme.fontWeightNormal};
      }
    }

    

    span {
       ${theme.fontSize(theme.fontSizeSm(), theme.fontWeightNormal)};
       margin-bottom: 0;
    }


    &:before {
      content: "";
      position: absolute;
      left: -9px;
      top: 50%;
      margin-top: -${theme.space(2)};
      width: ${theme.space(3)};
      height: ${theme.space(3)};
      border: 3px solid #fff;
      background-color: ${theme.grayShade(500)};
      border-radius: 100%;
      background-color: ${theme.yellow};
      ${logType === 'WEB_SERVICE' ? `background-color: ${theme.blue};` : ''};
`}
`;

UserLogsWrapper.Item = Item;

export default UserLogsWrapper;
