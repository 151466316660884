import styled, { css } from 'styled-components';

const HealthJourneyItemWrapper = styled.li`
  ${({ theme }) => `
    background-color: ${theme.color('blue-lightest')};
    margin-bottom: ${theme.space(3)};
  `}
`;

const HealthJourneyHeader = styled.div`
  ${({ theme }) => css`
    button {
      align-items: center;
      display: flex;
      background-color: transparent;
      word-break: break-word;
      width: 100%;
      border: none;
      text-align: left;
      padding: ${theme.space(3)};
      outline: none;
      color: ${theme.blue};

      &:focus {
        outline: none;
      }

      &:hover {
        background-color: transparent;
      }

      i {
        margin-left: auto;
      }

      span {
        margin-right: ${theme.space(2)};
        ${theme.fontSize(theme.fontSizeBase, theme.fontWeightBold)};
      }
    }
  `}
`;

const HealthJourneyBody = styled.div`
  ${({ theme }) => `
    border-top: 1px solid ${theme.color('blue-lightest-border')};
    padding: ${theme.space(3)};
    h5 {
      ${theme.fontSize(theme.fontSizeSm(), theme.fontWeightBold)};
      margin-bottom: ${theme.space(1)};
    }
    p {
      ${theme.fontSize(theme.fontSizeSm())};
    }
    ul {
      padding - left: ${theme.space(2)};
      list - style - type: '- ';
      margin - bottom: ${theme.space(2)};
    }
    li {
          &: not(${SpecialtyItem}){
        ${theme.fontSize(theme.fontSizeBase)};
      }
    }
`}
`;

const SpecialtyItem = styled.p`
  ${({ color, theme }) => css`
    &&& {
      margin: ${theme.space(1)} ${theme.space(1)} 0 0;

      &:last-child {
        margin-right: 0;
      }
    }

    border-radius: ${theme.borderRadius};
    border: 1px solid ${theme[color]};
    color: ${color === 'green' ? theme.green : theme.color('gray-font')};
    display: inline-block;
    padding: ${theme.space(1)} ${theme.space(2)};
    text-align: center;
    ${theme.fontSize(
      theme.fontSizeSm(),
      color === 'green' ? theme.fontWeightBold : theme.fontWeightNormal,
    )};

    img {
      height: auto;
      margin-right: ${theme.space(2)};
      width: 20px;
    }
  `}
`;

const PartnerCoverage = styled.span`
  ${({ theme }) => `
    display: inline-block;
    border-radius: ${theme.borderRadius};
    padding: ${theme.space(2)};
    border: 1px solid ${theme.green};
    color: ${theme.green};
    margin-bottom: ${theme.space(2)};
    margin-right: ${theme.space(2)};
    ${theme.fontSize(theme.fontSizeSm(), theme.fontWeightBold)};
  `};
`;

HealthJourneyItemWrapper.HealthJourneyHeader = HealthJourneyHeader;
HealthJourneyItemWrapper.HealthJourneyBody = HealthJourneyBody;
HealthJourneyItemWrapper.SpecialtyItem = SpecialtyItem;
HealthJourneyItemWrapper.PartnerCoverage = PartnerCoverage;

export default HealthJourneyItemWrapper;
