import { defineMessages } from 'react-intl';

import { generateTranslationsObject } from 'utils';

const prefix = 'Model.Role.';
const keys = ['Code', 'Label', 'Ranking'];

const additionalKeys = {
  ButtonEdit: {
    id: 'Buttons.Edit',
    defaultMessage: 'Error.Buttons.Edit',
  },
  Published: {
    id: 'Model.Published',
    defaultMessage: 'Error.Model.Published',
  },
  Title: {
    id: 'Screens.Roles.Read.Title',
    defaultMessage: 'Error.Screens.Roles.Read.Title',
  },
  Unpublished: {
    id: 'Model.Unpublished',
    defaultMessage: 'Error.Model.Unpublished',
  },
};

export default defineMessages(
  generateTranslationsObject(prefix, keys, additionalKeys),
);
