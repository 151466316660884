import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonValidate: {
    id: 'Buttons.Validate',
    defaultMessage: 'Error.Buttons.Validate',
  },
  FailBody: {
    id: 'Screens.Products.Update.ToastFail',
    defaultMessage: 'Error.Screens.Products.Update.ToastFail',
  },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
  },
  Placeholder: {
    id: 'Specialties.Placeholder',
    defaultMessage: 'Error.Specialties.Placeholder',
  },
  SuccessTitle: {
    id: 'Message.Success',
    defaultMessage: 'Error.Message.Success',
  },
  SuccessBody: {
    id: 'Screens.Products.Update.ToastSuccess',
    defaultMessage: 'Error.Screens.Products.Update.ToastSuccess',
  },
  Title: {
    id: 'Screens.Products.ManageSpecialties.Title',
    defaultMessage: 'Error.Screens.Products.ManageSpecialties.Title',
  },
});
