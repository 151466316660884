import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';

import { PageHeader } from 'components';
import { service } from 'services';
import { sendToast, api } from 'utils';
import { FormattedMessage } from 'react-intl';
import ExportForm from './ExportForm';
import SearchForm from './SearchForm';
import { ToastTypes } from '../../constants';
import messages from './messages';

const Marketing = () => {
  const [queryValues, setQueryValues] = useState({});
  const [contactsLength, setContactsLength] = useState(false);
  const offsetTop = 85;

  const searchContacts = (values) => {
    const newValues = {
      ...values,
      lastConsultationDateFrom: values.lastConsultationDateFrom
        ? `${values.lastConsultationDateFrom} 00:00:00`
        : '',
      lastConsultationDateTo: values.lastConsultationDateTo
        ? `${values.lastConsultationDateTo} 23:59:59`
        : '',
      registrationDateFrom: values.registrationDateFrom
        ? `${values.registrationDateFrom} 00:00:00`
        : '',
      registrationDateTo: values.registrationDateTo
        ? `${values.registrationDateTo} 23:59:59`
        : '',
    };
    setQueryValues(newValues);
    service
      .post(api.manager.postMarketingContacts, newValues)
      .then(({ data }) => {
        setQueryValues(values);
        setContactsLength(data.numberOfContacts);
      })
      .catch(() => {
        sendToast(ToastTypes.error, {
          header: (
            <FormattedMessage
              id='Shared.Error'
              defaultMessage='Error.Shared.Error'
            />
          ),
          body: <FormattedMessage {...messages.SearchContactsFailedBody} />,
        });
      });
  };

  const exportToSendinBlue = (values, { setSubmitting }) => {
    const { folderId, listName } = values;
    if (queryValues && contactsLength) {
      service
        .post(api.manager.exportToSendinBlue(folderId, listName), queryValues)
        .then(() => {
          sendToast(ToastTypes.success, {
            header: (
              <FormattedMessage
                id='Shared.Success'
                defaultMessage='Error.Shared.Success'
              />
            ),
            body: (
              <FormattedMessage
                values={{ listName }}
                {...messages.ExportMarketingSuccessBody}
              />
            ),
          });
        })
        .catch(() => {
          sendToast(ToastTypes.error, {
            header: (
              <FormattedMessage
                id='Shared.Error'
                defaultMessage='Error.Shared.Error'
              />
            ),
            body: <FormattedMessage {...messages.ExportMarketingFailedBody} />,
          });
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return (
    <>
      <PageHeader>
        <div>
          <h1>
            <FormattedMessage {...messages.Title} />
          </h1>
          <small>
            <FormattedMessage {...messages.Subtitle} />
          </small>
        </div>
      </PageHeader>
      <Row>
        <Col lg='8'>
          <SearchForm onSubmit={(data) => searchContacts(data)} />
        </Col>
        <Col lg='4'>
          <div className='sticky-top' style={{ top: offsetTop + 'px' }}>
            <ExportForm
              contactsLength={contactsLength}
              onSubmit={exportToSendinBlue}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Marketing;
