import { makeObservable, observable, computed } from 'mobx';
import _ from 'lodash';

import { service } from 'services';
import { api } from 'utils';

class fileImportConfigsData {
  _importConfigs = [];
  _importConfigInfo = null;

  constructor() {
    makeObservable(this, {
      _importConfigs: observable.shallow,
      _importConfigInfo: observable.shallow,
      importConfigs: computed,
      importConfigInfo: computed,
    });
  }

  get importConfigs() {
    return this._importConfigs;
  }

  set importConfigs(list) {
    return (this._importConfigs = list);
  }

  get importConfigInfo() {
    return this._importConfigInfo;
  }

  set importConfigInfo(configInfo) {
    return (this._importConfigInfo = configInfo);
  }

  async retrieveConfigsList() {
    const results = await service.get(api.fileImportConfigs.getList);
    this.importConfigs = results.data;
  }

  async retrieveFileImportConfigInfos(configId) {
    const results = await service.get(
      `${api.fileImportConfigs.getList}/${configId}`,
    );
    this.importConfigInfo = results.data;
  }

  addFileImportConfig(params) {
    const { email, codeClient, filePath, status, dateLastProcess } = params;
    return service.post(`${api.fileImportConfigs.getList}`, {
      email,
      codeClient,
      filePath,
      status,
      dateLastProcess,
    });
  }

  updateFileImportConfig(params) {
    const { email, codeClient, filePath, status, idFileConfig } = params;
    return service.put(
      `${api.fileImportConfigs.getList}/${this.importConfigInfo.idFileConfig}`,
      {
        email,
        codeClient,
        filePath,
        status,
        idFileConfig,
      },
    );
  }
}

export default new fileImportConfigsData();
