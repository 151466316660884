import { Translation } from '../../components';
import { InsDocumentValidationStatus } from './types';

type MapObject = Record<
  InsDocumentValidationStatus,
  {
    className: string;
    translationKey: string;
  }
>;
const mapObject: MapObject = {
  Pending: {
    className: 'badge-warning',
    translationKey:
      'Screens.IdentityValidationsIns.DocumentValidationStatus.Pending',
  },
  Validated: {
    className: 'badge-success',
    translationKey:
      'Screens.IdentityValidationsIns.DocumentValidationStatus.Validated',
  },
  Refused: {
    className: 'badge-danger',
    translationKey:
      'Screens.IdentityValidationsIns.DocumentValidationStatus.Refused',
  },
  NotSubmited: {
    className: 'badge-warning',
    translationKey:
      'Screens.IdentityValidationsIns.DocumentValidationStatus.NotSubmited',
  },
};

export const DocumentValidationBadge = ({
  documentValidationStatus,
}: {
  documentValidationStatus: InsDocumentValidationStatus;
}) => {
  const badgeClassname = mapObject[documentValidationStatus];

  if (!badgeClassname) {
    return;
  }

  return (
    <span
      className={`badge font-size-14px text-uppercase ${badgeClassname.className} `}
    >
      <Translation id={badgeClassname.translationKey} />
    </span>
  );
};
