import React from 'react';
import PropTypes from 'prop-types';

import ToastWrapper from './elements';

const Toast = ({ messages = { header: null, body: null } }) => {
  return (
    <ToastWrapper>
      <ToastWrapper.Text>
        {messages.header && <h5>{messages.header}</h5>}
        {messages.body && <p className='m-0'>{messages.body}</p>}
      </ToastWrapper.Text>
    </ToastWrapper>
  );
};

Toast.propTypes = {
  messages: PropTypes.shape({
    header: PropTypes.element,
    body: PropTypes.element,
  }).isRequired,
};

export default Toast;
