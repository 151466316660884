export default {
  title: '',
  body: '',
  url: '',
  dateStart: '',
  dateEnd: '',
  valid: false,
  ranking: 0,
  refProduct: '',
  selectedProfiles: [],
};
