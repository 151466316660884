export default ({ idClient, labelClientSellsy, refClientSellsy }) => {
  return {
    code: '',
    dateExpiration: '',
    duration: '',
    label: '',
    labelClientSellsy: labelClientSellsy || '',
    logo: null,
    maxQuestionCount: '',
    partnerLabel: '',
    productTag: '',
    refClient: idClient || '',
    refClientSellsy: refClientSellsy || '',
    refProductTerm: '',
    refVerificationType: '',
    refWebService: '',
    sellSyClientAutocomplete: '',
    valid: false,
  };
};
