import React, { useState } from 'react';
import {
  UncontrolledTooltip,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import { service } from 'services';
import { api, sendToast } from 'utils';
import messages from './messages';
import {
  WronglyLinkedToWs,
  NotLinked,
  WrongVerificationType,
  Unknown,
  Ok,
} from '../StatusConstants';
import { ToastTypes } from '../../../constants';

const EntitiesActionAndModal = ({
  actionName,
  productId,
  webServiceId,
  setUpdateProductStatus,
}) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const migrateProduct = () => {
    service
      .put(api.webServices.putMigrationProduct(webServiceId, productId))
      .then((response) => {
        if (response.status === 204) {
          toggle();
          setUpdateProductStatus(Math.random());
          sendToast(ToastTypes.success, {
            header: <FormattedMessage {...messages.ToastSuccess} />,
            body: (
              <FormattedMessage {...messages[`ToastSuccess${actionName}`]} />
            ),
          });
        } else {
          toggle();
          sendToast(ToastTypes.error, {
            header: <FormattedMessage {...messages.ToastError} />,
            body: <span>{response.data.errorSet[0].description}</span>,
          });
        }
      });
  };

  return (
    <>
      <button type='button' className='btn btn-primary btn-sm' onClick={toggle}>
        <FormattedMessage {...messages[actionName]} />
      </button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>
          <FormattedMessage {...messages[`${actionName}ModalHeader`]} />
        </ModalHeader>
        <ModalBody>
          <FormattedMessage {...messages[`${actionName}ModalBody`]} />
        </ModalBody>
        <ModalFooter>
          <div className='w-100 text-right'>
            <button
              type='button'
              className='btn btn-primary btn-sm mr-3'
              onClick={() => migrateProduct()}
            >
              <FormattedMessage {...messages.ButtonValidate} />
            </button>
            <button
              type='button'
              className='btn btn-secondary btn-sm'
              onClick={toggle}
            >
              <FormattedMessage {...messages.ButtonCancel} />
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

const EntityStatus = (props) => {
  let StatusComponent = '';
  switch (props) {
    case WronglyLinkedToWs:
      StatusComponent = (
        <Badge color='danger'>
          <i className='uil uil-ban mr-1' />
          <span id='WronglyLinkedToWs'>
            <FormattedMessage {...messages.WronglyLinkedToWs} />
          </span>
          <UncontrolledTooltip placement='top' target='WronglyLinkedToWs'>
            <FormattedMessage {...messages.TooltipWronglyLinkedToWs} />
          </UncontrolledTooltip>
        </Badge>
      );
      break;
    case NotLinked:
      StatusComponent = (
        <Badge color='warning'>
          <span id='NotLinked'>
            <i className='uil uil-exclamation-triangle mr-1' />
            <FormattedMessage {...messages.NotLinked} />
          </span>
          <UncontrolledTooltip placement='top' target='NotLinked'>
            <FormattedMessage {...messages.TooltipNotLinked} />
          </UncontrolledTooltip>
        </Badge>
      );
      break;
    case (NotLinked, WrongVerificationType):
      StatusComponent = (
        <div className='d-flex flex-column'>
          <div>
            <Badge color='warning'>
              <i className='uil uil-exclamation-triangle mr-1' />
              <span id='NotLinkedAndWrongVerif'>
                <FormattedMessage {...messages.NotLinked} />
              </span>
              <UncontrolledTooltip
                placement='top'
                target='NotLinkedAndWrongVerif'
              >
                <FormattedMessage {...messages.TooltipNotLinked} />
              </UncontrolledTooltip>
            </Badge>
          </div>
          <div>
            <Badge color='warning'>
              <i className='uil uil-ban mr-1' />
              <span id='WrongVerificationType'>
                <FormattedMessage {...messages.WrongVerificationType} />
              </span>
              <UncontrolledTooltip
                placement='top'
                target='WrongVerificationType'
              >
                <FormattedMessage {...messages.TooltipWrongVerificationType} />
              </UncontrolledTooltip>
            </Badge>
          </div>
        </div>
      );
      break;
    case Unknown:
      StatusComponent = (
        <Badge color='danger'>
          <i className='uil uil-times mr-1' />
          <span id='Unknown'>
            <FormattedMessage {...messages.Unknown} />
          </span>
          <UncontrolledTooltip placement='top' target='Unknown'>
            <FormattedMessage {...messages.TooltipUnknown} />
          </UncontrolledTooltip>
        </Badge>
      );
      break;
    case Ok:
      StatusComponent = (
        <Badge color='success'>
          <i className='uil uil-check mr-1' />
          <span id='OK'>
            <FormattedMessage {...messages.Ok} />
          </span>
          <UncontrolledTooltip placement='top' target='OK'>
            <FormattedMessage {...messages.TooltipOk} />
          </UncontrolledTooltip>
        </Badge>
      );
      break;
    default:
      StatusComponent = <>{props}</>;
  }
  return StatusComponent;
};

const EntityActions = ({
  status,
  productId,
  webServiceId,
  setUpdateProductStatus,
}) => {
  const link = 'Link';
  const migrate = 'Migrate';
  const fix = 'Fix';
  let ActionComponent = '';
  switch (status) {
    case NotLinked:
      ActionComponent = (
        <EntitiesActionAndModal
          productId={productId}
          webServiceId={webServiceId}
          actionName={link}
          setUpdateProductStatus={setUpdateProductStatus}
        />
      );
      break;
    case (NotLinked, WrongVerificationType):
      ActionComponent = (
        <EntitiesActionAndModal
          productId={productId}
          webServiceId={webServiceId}
          actionName={migrate}
          setUpdateProductStatus={setUpdateProductStatus}
        />
      );
      break;
    case WrongVerificationType:
      ActionComponent = (
        <EntitiesActionAndModal
          productId={productId}
          webServiceId={webServiceId}
          actionName={fix}
          setUpdateProductStatus={setUpdateProductStatus}
        />
      );
      break;
    default:
      ActionComponent = '';
  }
  return ActionComponent;
};

export { EntityStatus, EntityActions };
