import { defineMessages } from 'react-intl';

import { generateTranslationsObject } from 'utils';

const prefix = 'Screens.Profiles.List.';
const keys = ['Code', 'Offers', 'Placeholder', 'Roles', 'Title'];

const additionalKeys = {
  AllOrganizationsConfig: {
    id: 'Screens.Profiles.List.AllOrganizationsConfig',
    defaultMessage: 'Error.Screens.Profiles.List.AllOrganizationsConfig',
  },
  ButtonCreate: {
    id: 'Buttons.Create',
    defaultMessage: 'Error.Buttons.Create',
  },
  ButtonSearch: {
    id: 'Buttons.Search',
    defaultMessage: 'Error.Buttons.Search',
  },
  Fail: {
    id: 'Toasts.Request.Fail',
    defaultMessage: 'Error.Toasts.Request.Fail',
  },
  NoElement: {
    id: 'Shared.NoElement',
    defaultMessage: 'Error.Shared.NoElement',
  },
};

export default defineMessages(
  generateTranslationsObject(prefix, keys, additionalKeys),
);
