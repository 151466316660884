import { defineMessages } from 'react-intl';

export default defineMessages({
  FailBody: {
    id: 'Screens.Products.Update.ToastFail',
    defaultMessage: 'Error.Screens.Products.Update.ToastFail',
  },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
  },
  SuccessTitle: {
    id: 'Message.Success',
    defaultMessage: 'Error.Message.Success',
  },
  SuccessBody: {
    id: 'Screens.Products.Update.ToastSuccess',
    defaultMessage: 'Error.Screens.Products.Update.ToastSuccess',
  },
  Title: {
    id: 'Screens.Products.Update.Title',
    defaultMessage: 'Error.Screens.Products.Update.Title',
  },
});
