import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';

import {
  ApiError,
  Button,
  PageHeader,
  Section,
  SectionBody,
  Spinner,
} from 'components';
import { Product } from 'models';
import validationSchema from 'models/Product/validationSchema';
import ConfigurationStore from 'stores/Configuration';
import { service } from 'services';
import { api, sendToast, urlSchema, useFetch } from 'utils';
import { ButtonsWrapper } from 'themes/jupiter/elements';
import messages from './messages';
import { ToastTypes } from '../../../constants';

const Update = ({ history, match }) => {
  const intl = useIntl();
  const { params } = match;
  const [product, isProductLoading] = useFetch(
    api.manager.getProductDetails(params.productId),
  );
  const [logo, setLogo] = useState(null);

  const onSubmit = (values, { setSubmitting }) => {
    delete values.rawFile;

    service
      .put(api.manager.putProduct(params.productId), values)
      .then(({ data }) => {
        if (data.succeeded === false) {
          sendToast(ToastTypes.error, {
            header: <FormattedMessage {...messages.FailTitle} />,
            body: (
              <>
                <FormattedMessage
                  {...messages.FailBody}
                  values={{ label: values.label }}
                  tagName='p'
                />
                {data.errorSet.map((error) => (
                  <p>
                    {error.code}: {error.description}
                  </p>
                ))}
              </>
            ),
          });
          return;
        }
        if (logo !== null)
          service
            .post(api.manager.uploadProductLogo(params.productId), logo)
            .then(() => {
              sendToast(ToastTypes.success, {
                header: <FormattedMessage {...messages.SuccessTitle} />,
                body: (
                  <FormattedMessage
                    {...messages.SuccessBody}
                    values={{ label: values.label }}
                  />
                ),
              });
              history.push(urlSchema.products.list);
            });
        else {
          sendToast(ToastTypes.success, {
            header: <FormattedMessage {...messages.SuccessTitle} />,
            body: (
              <FormattedMessage
                {...messages.SuccessBody}
                values={{ label: values.label }}
              />
            ),
          });
          history.push(urlSchema.products.list);
        }
      })
      .catch((error) => {
        // TODO: Need to fix API sending 500
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.FailTitle} />,
          body: <ApiError {...{ error }} />,
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <FormattedMessage {...messages.Title} tagName='h1' />
      </PageHeader>
      <Section>
        <SectionBody>
          {isProductLoading ||
          ConfigurationStore.verificationsTypes.length === 0 ? (
            <Spinner color='primary' size='lg' />
          ) : (
            <Formik
              initialValues={{
                ...product,
                dateExpiration: product.dateExpiration
                  ? moment(product.dateExpiration).format('YYYY-MM-DD')
                  : '',
                refVerificationType:
                  product?.refVerificationTypeNavigation?.idVerificationType ??
                  '',
                refProductTerm:
                  product?.refProductTermNavigation?.idProductTerm ?? '',
              }}
              {...{ onSubmit }}
              validationSchema={validationSchema(
                ConfigurationStore.getRefWebServiceType(),
                intl,
              )}
            >
              {({ isSubmitting, ...props }) => (
                <Form>
                  <Product setLogo={setLogo} {...props} />
                  <ButtonsWrapper bottom>
                    <Button
                      color='primary'
                      isProcessing={isSubmitting}
                      label='Edit'
                      size='lg'
                      type='submit'
                    />
                  </ButtonsWrapper>
                </Form>
              )}
            </Formik>
          )}
        </SectionBody>
      </Section>
    </>
  );
};

export default Update;
