import React from 'react';
import { Switch } from 'react-router-dom';

import { ProtectedRoute } from 'components';
import { urlSchema } from 'utils';

// Screens
import Create from './Create';
import EditProfiles from './EditProfiles';
import List from './List';
import Read from './Read';
import Update from './Update';

const routes = [
  { path: urlSchema.applications.create, component: Create, right: 'CRUD' },
  {
    path: `${urlSchema.applications.read}/:idApplication`,
    component: Read,
    right: 'Reader',
  },
  {
    path: `${urlSchema.applications.update}/:idApplication`,
    component: Update,
    right: 'CRUD',
  },
  {
    path: `${urlSchema.applications.editProfiles}/:idApplication`,
    component: EditProfiles,
    right: 'CRUD',
  },
  { path: '/', component: List, right: 'Reader' },
];

const Applications = () => {
  return (
    <Switch>
      {routes.map((item) => (
        <ProtectedRoute entity='Applications' key={item.path} {...item} />
      ))}
    </Switch>
  );
};

export default Applications;
