import { defineMessages } from 'react-intl';

const messages = defineMessages({
  ActionSuccess: {
    id: 'Message.Success',
    defaultMessage: 'Error.Message.Success',
  },
  actionError: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
  },
  ButtonDeleteAccount: {
    id: 'Buttons.DeleteAccount',
    defaultMessage: 'Error.Buttons.DeleteAccount',
  },
  ButtonEdit: {
    id: 'Buttons.Edit',
    defaultMessage: 'Error.Buttons.Edit',
  },
  LastSeen: {
    id: 'Screens.Patients.Read.PatientInformation.LastSeen',
    defaultMessage: 'Error.Screens.Patients.Read.PatientInformation.LastSeen',
  },
  NewPasswordSuccess: {
    id: 'Screens.Patients.Read.PatientInformation.NewPasswordSuccess',
    defaultMessage:
      'Error.Screens.Patients.Read.PatientInformation.NewPasswordSuccess',
  },
  NewPasswordError: {
    id: 'Screens.Patients.Read.PatientInformation.NewPasswordError',
    defaultMessage:
      'Error.Screens.Patients.Read.PatientInformation.NewPasswordError',
  },
  IdentityValidBadge: {
    id: 'Screens.Patients.Read.PatientInformation.IdentityValid',
    defaultMessage:
      'Error.Screens.Patients.Read.PatientInformation.IdentityValid',
  },
  IdentityInvalidBadge: {
    id: 'Screens.Patients.Read.PatientInformation.IdentityInvalid',
    defaultMessage:
      'Error.Screens.Patients.Read.PatientInformation.IdentityInvalid',
  },
  InvalidIdentitySuccess: {
    id: 'Screens.Patients.Read.PatientInformation.InvalidIdentitySuccess',
    defaultMessage:
      'Error.Screens.Patients.Read.PatientInformation.InvalidIdentitySuccess',
  },
  InvalidIdentityError: {
    id: 'Screens.Patients.Read.PatientInformation.InvalidIdentityError',
    defaultMessage:
      'Error.Screens.Patients.Read.PatientInformation.InvalidIdentityError',
  },
  ValidIdentitySuccess: {
    id: 'Screens.Patients.Read.PatientInformation.ValidIdentitySuccess',
    defaultMessage:
      'Error.Screens.Patients.Read.PatientInformation.ValidIdentitySuccess',
  },
  ValidIdentityError: {
    id: 'Screens.Patients.Read.PatientInformation.ValidIdentityError',
    defaultMessage:
      'Error.Screens.Patients.Read.PatientInformation.ValidIdentityError',
  },
  Email: {
    id: 'Shared.Email',
    defaultMessage: 'Error.Shared.Email',
  },
  Mobile: {
    id: 'Shared.Mobile',
    defaultMessage: 'Error.Shared.Mobile',
  },
  Birthdate: {
    id: 'Shared.Birthdate',
    defaultMessage: 'Error.Shared.Birthdate',
  },
  Address: {
    id: 'Shared.Address',
    defaultMessage: 'Error.Shared.Address',
  },
  NotificationPreference: {
    id: 'Shared.NotificationPreference',
    defaultMessage: 'Error.Shared.NotificationPreference',
  },
  AdditionalAddress: {
    id: 'Shared.AdditionalAddress',
    defaultMessage: 'Error.Shared.AdditionalAddress',
  },
  UserInformation: {
    id: 'Screens.Patients.Read.PatientInformation.UserInformation',
    defaultMessage:
      'Error.Screens.Patients.Read.PatientInformation.UserInformation',
  },
  AccountInformation: {
    id: 'Screens.Patients.Read.PatientInformation.AccountInformation',
    defaultMessage:
      'Error.Screens.Patients.Read.PatientInformation.AccountInformation',
  },
  AccountCreationDate: {
    id: 'Shared.AccountCreationDate',
    defaultMessage: 'Error.Shared.AccountCreationDate',
  },
});

export default messages;
