import { defineMessages } from 'react-intl';

import { generateTranslationsObject } from 'utils';

const prefix = 'Model.WebServiceConfiguration.';
const keys = [
  'AuthentificationLogin',
  'AuthentificationPassword',
  'Certificate',
  'CertificatePassword',
  'CodeKo',
  'CodeOk',
  'CodeResigned',
  'EndPoint',
  'Name',
  'RequestType',
  'UserAgentCode',
  'UserBirthDate',
  'UserFirstName',
  'UserLastName',
  'UserMembershipCode',
  'UserTest',
];

const additionalKeys = {
  ButtonCopy: { id: 'Buttons.Copy', defaultMessage: 'Error.Buttons.Copy' },
  ButtonEdit: { id: 'Buttons.Edit', defaultMessage: 'Error.Buttons.Edit' },
  Copied: { id: 'Shared.Copied', defaultMessage: 'Error.Shared.Copied' },
  Title: {
    id: 'Screens.WebServicesConfiguration.Read.Title',
    defaultMessage: 'Error.Screens.WebServicesConfiguration.Read.Title',
  },
};

export default defineMessages(
  generateTranslationsObject(prefix, keys, additionalKeys),
);
