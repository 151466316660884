import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import { LinksList, RoleManager } from 'components';
import { urlSchema } from 'utils';
import messages from './messages';

const ProfileItem = ({
  code,
  idProfile,
  label,
  organizationConfigCode,
  profilesRoles,
  refOrganizationConfig,
}) => {
  const intl = useIntl();

  return (
    <Container fluid>
      <Row className='align-items-center'>
        <Col md='3'>
          <h5 className='font-weight-bold m-0'>{code}</h5>
          <p className='text-secondary m-0 small'>{label}</p>
        </Col>
        <Col md='2'>
          <Link
            to={`${urlSchema.detailsOrganizationConfigs}/${refOrganizationConfig}`}
          >
            {organizationConfigCode}
          </Link>
        </Col>
        <Col md='3'>
          <LinksList
            areInnerLinks
            color='warning'
            data={profilesRoles}
            idKey={(el) => el.refRole}
            labelKey={(el) => el.roleCode}
            limit={2}
            urlBase={urlSchema.roles.read}
          />
        </Col>
        <Col className='ml-auto research-table__actions'>
          <RoleManager entity='Profiles' right='CRUD'>
            <Link
              className='btn btn-sm btn-outline-primary mr-3'
              to={`${urlSchema.profiles.editRoles}/${idProfile}`}
            >
              <FormattedMessage {...messages.ButtonEditRoles} />
            </Link>
          </RoleManager>
          <RoleManager entity='Profiles' right='CRUD'>
            <Link
              aria-label={intl.formatMessage({ ...messages.ButtonEdit })}
              to={`${urlSchema.profiles.update}/${idProfile}`}
            >
              <i aria-hidden className='uil uil-pen' />
            </Link>
          </RoleManager>
          <RoleManager entity='Profiles' right='Reader'>
            <Link
              aria-label={intl.formatMessage({ ...messages.ButtonRead })}
              className='mr-3'
              to={`${urlSchema.profiles.read}/${idProfile}`}
            >
              <i aria-hidden className='uil uil-eye' />
            </Link>
          </RoleManager>
        </Col>
      </Row>
    </Container>
  );
};

ProfileItem.defaultProps = { profilesRoles: [] };

ProfileItem.propTypes = {
  code: PropTypes.string.isRequired,
  idProfile: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  organizationConfigCode: PropTypes.string.isRequired,
  profilesRoles: PropTypes.arrayOf(PropTypes.object),
  refOrganizationConfig: PropTypes.string.isRequired,
};

export default ProfileItem;
