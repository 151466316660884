import styled, { css } from 'styled-components';

const UserLogsWrapper = styled.ul`
  ${({ theme }) => css`
    max-height: ${theme.space(7)};
    overflow-y: scroll;
    margin-right: -${theme.space(1)};
    overflow-x: hidden;
    border: 1px solid ${theme.grayShade(200)};
    border-right: none;
    border-radius: ${theme.space(1)};
    padding-right: ${theme.space(3)};
    padding-left: ${theme.space(3)};
    padding-top: ${theme.space(2)};
    padding-bottom: ${theme.space(2)};
  `}
`;

const Item = styled.li`
  ${({ theme, code }) => css`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: ${theme.space(3)};
    border-left: 2px solid ${theme.grayShade(200)};
    padding-top: 1px;
    padding-bottom: 1px;

    ${
      code === 'ADDRESS_UPDATED' ||
      code === 'PREFERENCES_UPDATED' ||
      code === 'ACCOUNT_UNBLOCKED' ||
      code === 'PROFILE_UPDATED'
        ? `
      color: ${theme.blue};
    `
        : ''
    };
     ${
       code === 'USER_CONNEXION' ||
       code === 'SUBSCRIPTION_ACTIVATION' ||
       code === 'SUBSCRIPTION_CREATION'
         ? `
        color: ${theme.color('yellow-font')};
      `
         : ''
     };
    ${
      code === 'WRONG_PASSWORD'
        ? `
      color: ${theme.color('danger')};
    `
        : null
    };

    p {
      ${theme.fontSize(theme.fontSizeSm(), theme.fontWeightBold)};
      margin-bottom: 0;

      a {
        margin-left: ${theme.space(1)};
        color: ${theme.color('gray-font')};
        text-decoration: underline;
        font-weight: ${theme.fontWeightNormal};
      }
    }

    span {
       ${theme.fontSize(theme.fontSizeSm(), theme.fontWeightNormal)};
       margin-bottom: 0;
    }


    &:before {
      content: "";
      position: absolute;
      left: -9px;
      top: 50%;
      margin-top: -${theme.space(2)};
      width: ${theme.space(3)};
      height: ${theme.space(3)};
      border: 3px solid #fff;
      background-color: ${theme.grayShade(500)};
      border-radius: 100%;

     ${
       code === 'ADDRESS_UPDATED' ||
       code === 'PREFERENCES_UPDATED' ||
       code === 'ACCOUNT_UNBLOCKED' ||
       code === 'PROFILE_UPDATED'
         ? `
      background-color: ${theme.blue};
    `
         : ''
     };
     ${
       code === 'USER_CONNEXION' ||
       code === 'SUBSCRIPTION_ACTIVATION' ||
       code === 'SUBSCRIPTION_CREATION'
         ? `
        background-color: ${theme.yellow};
      `
         : ''
     };
    ${
      code === 'WRONG_PASSWORD'
        ? `
      background-color: ${theme.color('danger')};
    `
        : null
    }

`}
`;

UserLogsWrapper.Item = Item;

export default UserLogsWrapper;
