import styled, { css } from 'styled-components';

export default styled.li`
  ${({ color = null, theme }) => css`
    border: 1px solid
      ${color === null ? theme.color('border') : theme.color(color)};
    border-radius: ${theme.borderRadius};
    margin-bottom: ${theme.space(2)};
    transition: ${theme.transitionBase};

    button.isOpen {
      i::before {
        transform: rotate(0.5turn);
      }
    }

    .status-icon-container {
      margin-right: ${theme.space(1)};
      min-width: 48px;

      &--valid i {
        color: ${theme.color('success')};
      }
      &--invalid i {
        color: ${theme.color('danger')};
      }
    }
  `}
`;
