import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Col, Row, Input, FormGroup } from 'reactstrap';
import { Field, Formik, Form } from 'formik';
import qs from 'qs';

import { service } from 'services';
import { api, sendToast, useFetch, urlSchema } from 'utils';
import {
  Section,
  SectionBody,
  Button,
  FormLabel,
  FormInputFeedback,
  PageHeader,
  Spinner,
} from 'components';
import { ToastTypes } from '../../../constants';
import messages from './messages';
import { Customer } from '../../../models';
import initialValues from './initialValues';
import validationSchema from './validationShema';
import { ButtonsWrapper } from 'themes/jupiter/elements';

const Create = () => {
  const history = useHistory();
  const parameters = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });
  const [formLogoData, setFormLogoData] = useState();
  const { formatMessage } = useIntl();
  const [universes, areUniversesLoading] = useFetch(api.admin.getUniverses);

  const onSubmit = (values, { setSubmitting }) => {
    let newValues = {
      code: values.code,
      label: values.label,
      refUniverse: values.refUniverse,
      refClientSellsy: values.refClientSellsy,
      labelClientSellsy: values.labelClientSellsy,
    };

    service.post(api.admin.postCustomer, newValues).then(({ data }) => {
      if (data.succeeded === false) {
        setSubmitting(false);
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.FailTitle} />,
          body: <FormattedMessage {...messages.FailBody} />,
        });
        return;
      }
      if (values.logoFile !== null) {
        service
          .post(api.admin.postCustomerLogo(data.idClient), formLogoData)
          .then(() => {
            sendToast(ToastTypes.success, {
              header: <FormattedMessage {...messages.SuccessTitle} />,
              body: <FormattedMessage {...messages.SuccessBody} />,
            });
            history.push(`${urlSchema.clients.read}/${data.idClient}`);
          })
          .catch(() => {
            sendToast(ToastTypes.error, {
              header: <FormattedMessage {...messages.FailTitle} />,
              body: <FormattedMessage {...messages.FailBody} />,
            });
          });
      } else {
        service
          .post(api.admin.postCustomerDefaultLogo(data.idClient))
          .then(() => {
            sendToast(ToastTypes.success, {
              header: <FormattedMessage {...messages.SuccessTitle} />,
              body: <FormattedMessage {...messages.SuccessBody} />,
            });
            history.push(`${urlSchema.clients.read}/${data.idClient}`);
          })
          .catch(() => {
            sendToast(ToastTypes.error, {
              header: <FormattedMessage {...messages.FailTitle} />,
              body: <FormattedMessage {...messages.FailBody} />,
            });
          });
      }
    });
  };

  return (
    <>
      <PageHeader>
        <FormattedMessage {...messages.Title} tagName='h1' />
      </PageHeader>
      <Row>
        <Col lg='6'>
          <Section>
            <SectionBody>
              <Formik
                initialValues={{ ...initialValues, ...parameters }}
                validationSchema={validationSchema(formatMessage)}
                onSubmit={onSubmit}
              >
                {({ isSubmitting, ...props }) => (
                  <Form>
                    <Customer
                      isSubmitting={isSubmitting}
                      setFormLogoData={setFormLogoData}
                      onSubmit={onSubmit}
                      {...props}
                    />
                    {areUniversesLoading ? (
                      <Spinner size='lg' />
                    ) : (
                      <FormGroup>
                        <FormLabel required for='refUniverse'>
                          {formatMessage(messages.Organization)}
                        </FormLabel>
                        <Field name='refUniverse'>
                          {({ field, meta }) => (
                            <Input
                              {...field}
                              id='ref-universe'
                              type='select'
                              invalid={meta.error && meta.touched}
                            >
                              <option value=''>-</option>
                              {universes.map((universe) => (
                                <option key={universe.id} value={universe.id}>
                                  {universe.label}
                                </option>
                              ))}
                            </Input>
                          )}
                        </Field>
                        <FormInputFeedback name='refUniverse' />
                      </FormGroup>
                    )}
                    <ButtonsWrapper bottom>
                      <Button
                        color='primary'
                        isProcessing={isSubmitting}
                        label='Create'
                        size='lg'
                        data-tracking='ApplicationCreateSubmit'
                        type='submit'
                      />
                    </ButtonsWrapper>
                  </Form>
                )}
              </Formik>
            </SectionBody>
          </Section>
        </Col>
      </Row>
    </>
  );
};
export default Create;
