import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { urlSchema } from 'utils';
import { FormattedDate } from 'components';
import resources from './resources';
import UserLogsWrapper from './elements';

const UserLogs = ({ data }) => {
  if (data.length === 0) {
    return (
      <FormattedMessage
        id='Shared.NoElement'
        defaultMessage='Error.Shared.NoElement'
        tagName='span'
      />
    )
  }

  return (
    <UserLogsWrapper>
      {data.map(({ code, label, createdAt, operatorId }) => (
        <UserLogsWrapper.Item key={createdAt} code={code}>
          <p>
            {(typeof label === 'undefined') ? resources[code] : label}
            {operatorId && (
              <a href={urlSchema.readOperator(operatorId)}>
                <FormattedMessage
                  id='Screens.Patients.Read.PatientDialoguesList.FromOperator'
                  defaultMessage='Error.Screens.Patients.Read.PatientDialoguesList.FromOperator'
                />
              </a>
            )}
          </p>
          <span>
            <FormattedDate value={createdAt} origin='fr' />
          </span>
        </UserLogsWrapper.Item>
      ))}
    </UserLogsWrapper>
  )
};

UserLogs.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default UserLogs;
