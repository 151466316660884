/* eslint-disable unicorn/filename-case */
import { api } from '../../../utils';
import { service } from '../../index';
import { GenericErrorResponse } from '../../types';

export type AccessLogs = {
  id: number;
  userId: string;
  firstName?: string;
  lastName?: string;
  nhiHistoryId: number;
  operation: 'READ' | 'CREATE' | 'UPDATE';
  context:
    | 'MANUAL_ADMIN_APP'
    | 'MANUAL_PATIENT_APP'
    | 'MANUAL_ADMIN_APP'
    | 'AUTO_DOCUMENT_GENERATION'
    | 'AUTO_NHI_RETURN';
  createdOn: string;
  status: 'Temporary' | 'Recovered' | 'Validated' | 'Qualified';
};

export type WebServiceLogs = {
  id: number;
  requestType: 'UNIT_SEARCH' | 'UNIT_VERIF';
  reportCode?: 'UniqueNHIFound' | 'NHINotFound' | 'MultipleNHIFound';
  reportCodeWsValue?: string;
  unexpectedFail: boolean;
  startedAt: string;
  endedAt: string;
};

export type Response = {
  accessLogs: AccessLogs[];
  webServiceLogs: WebServiceLogs[];
};

export const getNHIPatientLogsByPatientId = async (
  patientId: string,
): Promise<Response> => {
  try {
    const { data } = await service.get<GenericErrorResponse | Response>(
      api.manager.getNHIPatientLogs(patientId),
    );

    if (data && 'succeeded' in data && data.succeeded === false) {
      throw new Error(data.errorSet[0].code);
    }

    return data as Response;
  } catch (error) {
    throw new Error(error.message);
  }
};
