import styled from 'styled-components';

const CloseButtonWrapper = styled.button`
  ${({ theme }) => `
    background-color: transparent;
    border: none;
    color: ${theme.color('gray-font')};
    height: ${theme.space(4)};
    padding-right: ${theme.space(2)};
    margin-right: ${theme.space(1)};
  `}
`;

export default CloseButtonWrapper;
