import React from 'react';
import { observer } from 'mobx-react-lite';
import CsvStore from './stores/CsvStore';

import moment from 'moment';

const deleteTheUploadedFile = (file) =>
  CsvStore.deletePartnerOperationsCsv(CsvStore.selectedPartner, file.id);

const HistoryList = () => (
  <>
    {CsvStore.uploadHistory.map((e, i) => {
      const fileIsIntegrated = e.importStatus !== 0;
      const DateToDisplay = () =>
        fileIsIntegrated
          ? moment(new Date(e.processDate)).format('DD/MM/YYYY hh:mm:ss')
          : 'n/a';

      const Badge = () =>
        fileIsIntegrated ? (
          <span className='badge bg-dffff8 text-success fw-bold'>INTÉGRÉ</span>
        ) : (
          <span className={`badge bg-warning text-dark fw-bold`}>
            EN ATTENTE
          </span>
        );

      const Action = observer(() =>
        !fileIsIntegrated ? (
          <button
            disabled={CsvStore.isProcessing === true}
            onClick={() => {
              CsvStore.isProcessing = true;
              deleteTheUploadedFile(e).finally(() => {
                CsvStore.isProcessing = false;
              });
            }}
            className='btn text-danger'
          >
            <u>Supprimer</u>
          </button>
        ) : null,
      );

      return (
        <tr key={i}>
          <td>
            {e.fileName} <Action />
          </td>
          <td>{CsvStore.selectedPartner}</td>
          <td>
            <DateToDisplay />
          </td>
          <td>
            <Badge />
          </td>
        </tr>
      );
    })}
  </>
);

export const UploadHistory = observer(() =>
  CsvStore.uploadHistory.length > 0 ? (
    <>
      <div className='py-3'>
        <h6 className='text-color-2d3047 m-0 fw-bold'>
          Historique des intégrations
        </h6>
      </div>
      <p>
        Retrouvez les fichiers selon leur statut d'intégration. Un fichier déjà
        intégré ne peut pas être supprimé
      </p>
      <div className='table-responsive'>
        <table className='table text-color-242627 border'>
          <thead>
            <tr className='bg-f1f1f1 align-middle'>
              <th className='fw-normal border-bottom'>Nom du fichier</th>
              <th className='fw-normal border-bottom'>Partenaire</th>
              <th className='fw-normal border-bottom'>Date de l’intégration</th>
              <th className='fw-normal border-bottom'>Statut</th>
            </tr>
          </thead>
          <tbody>
            <HistoryList />
          </tbody>
        </table>
      </div>
    </>
  ) : null,
);
