import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Formik, Form, useFormikContext } from 'formik';
import { Section, SectionBody } from 'components';
import FormDemographicCriteria from './FormDemographicCriteria';
import FormPlateformCriteria from './FormPlateformCriteria';

const initialValues = {
  clients: [],
  maleChecked: false,
  femaleChecked: false,
  ageFrom: "",
  ageTo: "",
  minorBeneficiaryChecked: false,
  alreadyConsultedChecked: false,
  consultationsNumber: "",
  consultationsNumberComparisonOperator: "GreaterThanOrEqualTo",
  onlyNewsletterChecked: false,
  registrationDateFrom: "",
  registrationDateTo: "",
  lastConsultationDateFrom: "",
  lastConsultationDateTo: ""
};

const MyAutoSavingComponent = () => {
  const { values, submitForm } = useFormikContext();

  useEffect(() => {
    submitForm();
  }, [values]);

  return null;
};

const SearchForm = ({ onSubmit }) => (
  <Section>
    <SectionBody>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form>
            <FormDemographicCriteria />
            <FormPlateformCriteria />
            <MyAutoSavingComponent />
          </Form>
        )}
      </Formik>
    </SectionBody>
  </Section>
);

export default SearchForm;
