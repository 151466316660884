import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import HealthJourneyItem from './HealthJourneyItem';
import JourneysList from './elements';

const HealthJourney = ({ data }) => {

  if (data.length === 0) {
    return (
      <FormattedMessage
        id="Shared.NoElement"
        defaultMessage="Error.Shared.NoElement"
        tagName="span"
      />
    )
  }

  return (
    <JourneysList>
      {data.map((offer) => <HealthJourneyItem
        key={offer.id}
        data={offer}
      />
      )}
    </JourneysList>
  )
}

HealthJourney.propTypes = {
  data: PropTypes.shape().isRequired
}

export default HealthJourney;
