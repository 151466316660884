import styled from 'styled-components';

const radioDimensions = '20px';

const FormRadioWrapper = styled.div`
  ${({ theme }) => `
    display: inline-block;
    margin-right: ${theme.space(4)};
    position: relative;
    vertical-align: middle;

    input {
      position: absolute;
      left: -10000px;
      opacity: 0;
      z-index: -1;
    }

    label {
      align-items: center;  
      display: flex;
      margin-bottom: 0;
    }
  `}
`;

const Label = styled.span`
  ${({ theme }) => `
    color: ${theme.black};
    font-weight: ${theme.fontWeightNormal};

    &::first-letter {
      text-transform: uppercase;
    }
  `}
`;

const Radio = styled.span`
  ${({ checked, theme }) => `
    background-color: ${theme.white};
    border: 2px solid ${checked ? theme.blue : theme.color('border')};
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: ${radioDimensions};
    margin-right: ${theme.space(2)};
    position: relative;
    width: ${radioDimensions};

    &:hover {
      border-color: ${theme.blue};
    }

    &::before {
      background-color: ${theme.blue};
      border-radius: 100%;
      content: "";
      height: calc(${radioDimensions} - 10px);
      left: 50%;
      opacity: ${checked ? 1 : 0};
      position: absolute;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      width: calc(${radioDimensions} - 10px);
    }
  `}
`;

FormRadioWrapper.Label = Label;
FormRadioWrapper.Radio = Radio;

export default FormRadioWrapper;
