import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonCreateCustomers: {
    id: 'Buttons.Create',
    defaultMessage: 'Error.Buttons.Create',
    description: 'Button to create customers',
  },
  ButtonCreateProduct: {
    id: 'Buttons.CreateProduct',
    defaultMessage: 'Error.Buttons.CreateProduct',
    description: 'Button to create product',
  },
  ButtonEdit: {
    id: 'Buttons.Edit',
    defaultMessage: 'Error.Buttons.Edit',
  },
  ButtonRead: {
    id: 'Buttons.Read',
    defaultMessage: 'Error.Buttons.Read',
    description: 'Button to read a client',
  },
  CustomersListPlaceholderResearch: {
    id: 'Screens.Customers.List.PlaceholderResearch',
    defaultMessage: 'Error.Screens.Customers.List.PlaceholderResearch',
    description: 'Placeholder for research',
  },
  Title: {
    id: 'Screens.Customers.List.Title',
    defaultMessage: 'Error.Screens.Customers.List.Title',
    description: 'Customers page title',
  },
  TitleColumnCustomer: {
    id: 'Customers.Customers',
    defaultMessage: 'Error.Screens.Customers.Customers',
    description: 'Customers page column title customer',
  },
  TitleColumnOrganization: {
    id: 'Customers.Organization',
    defaultMessage: 'Error.Customers.Organization',
    description: 'Customers page column title organization',
  },
  TitleColumnCreation: {
    id: 'Customers.Creation',
    defaultMessage: 'Error.Customers.Creation',
    description: 'Customers page column title creation',
  },
  TitleColumnProducts: {
    id: 'Customers.Products',
    defaultMessage: 'Error.Customers.Products',
    description: 'Customers page column title products',
  },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
    description: 'Toast title in case of Error',
  },
  FailBody: {
    id: 'Message.Api.Error',
    defaultMessage: 'Error.Message.Api.Error',
    description: 'Toast body in case of error',
  },
});
