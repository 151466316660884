import { defineMessages } from 'react-intl';

export default defineMessages({
  Title: {
    id: 'Screens.ProductTerms.List.Title',
    defaultMessage: 'Error.Screens.ProductTerms.List.Title',
    description: 'ProductTerms page title',
  },
  ButtonsCreate: {
    id: 'Buttons.Create',
    defaultMessage: 'Error.Buttons.Create',
    description: 'Button to create a ProductTerms',
  },
  Placeholder: {
    id: 'Screens.ProductTerms.List.Placeholder',
    defaultMessage: 'Error.Screens.ProductTerms.List.Placeholder',
  },
  ProductTermsCode: {
    id: 'ProductTerms.Code',
    defaultMessage: 'Error.ProductTerms.Code',
    description: 'ProductTerms page column title Code',
  },
  ButtonsRead: {
    id: 'Buttons.Read',
    defaultMessage: 'Error.Buttons.Read',
    description: 'Button to read a ProductTerms',
  },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
    description: 'Toast title in case of error',
  },
});
