import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import {
  Section,
  SectionBody,
  FormInput,
  Button,
  FormSelect,
} from 'components';
import { ButtonsWrapper } from 'themes/jupiter/elements';
import { api, useFetch } from 'utils';
import * as yup from 'yup';
import { useIntl, FormattedMessage } from 'react-intl';
import messages from '../messages';

const getValidationSchema = (formatMessage) =>
  yup.object().shape({
    folderId: yup.number().required('Model.FieldRequired'),
    listName: yup.string().required(
      formatMessage({
        id: 'Model.FieldRequired',
      }),
    ),
  });

const initialValues = {
  folderId: undefined,
  listName: undefined,
};

const ShowResults = ({ contactsLength, children }) => {
  if (Number.isInteger(contactsLength)) {
    if (contactsLength > 0) {
      return children;
    }

    return (
      <FormattedMessage
        {...messages.ContactExportNumberContactFound}
        values={{
          number: contactsLength,
        }}
        tagName='p'
      />
    );
  }

  return (
    <FormattedMessage {...messages.ContactExtractPlaceholder} tagName='p' />
  );
};

const ExportForm = ({ contactsLength, onSubmit }) => {
  const { formatMessage } = useIntl();
  const [folders, setFolders] = useState([]);
  const [data, isDataLoading] = useFetch(api.manager.getSendinBlueFolder);

  useEffect(() => {
    if (data.folders && data.folders.length) {
      const formattedFolders = data.folders.map((item) => {
        return { value: item.id, label: item.name };
      });
      setFolders(formattedFolders);
    }
  }, [data]);

  return (
    <div>
      <Section>
        <SectionBody>
          <Formik
            validationSchema={getValidationSchema(formatMessage)}
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {({ isValid, isSubmitting, errors }) => (
              <Form>
                <h3 className='text-primary'>
                  <FormattedMessage {...messages.ContactExportTitle} />
                </h3>
                <ShowResults contactsLength={contactsLength}>
                  <>
                    <FormattedMessage
                      {...messages.ContactExportNumberContactFound}
                      values={{
                        number: contactsLength,
                      }}
                      tagName='p'
                    />
                    <FormInput
                      label={formatMessage({
                        ...messages.ContactExportNumberListName,
                      })}
                      name='listName'
                      required
                      type='text'
                      hasRawError
                    />
                    <Field name='folder'>
                      {({ field, form, meta }) => (
                        <FormSelect
                          {...{ meta }}
                          hasError={Boolean(meta.touched && errors['folderId'])}
                          errorMsg={meta.touched && errors['folderId']}
                          label={formatMessage({
                            ...messages.ContactExportFolderLabel,
                          })}
                          name={field.name}
                          onBlur={() => form.setFieldTouched(field.name, true)}
                          onChange={(opt) => {
                            form.setFieldValue('folderId', opt.value);
                          }}
                          options={folders}
                          placeholder={formatMessage({
                            ...messages.SelectFilePlaceholder,
                          })}
                          required
                        />
                      )}
                    </Field>
                    <FormattedMessage
                      {...messages.ContactExportInformationMutedText}
                      tagName='small'
                    />
                    <ButtonsWrapper bottom>
                      <Button
                        color='primary'
                        disabled={!isValid}
                        isProcessing={isSubmitting}
                        customLabel
                        label='Screens.Marketing.UsageCriteria.ContactExport.Information.ExportToSendinblue'
                        size='lg'
                        type='submit'
                      />
                    </ButtonsWrapper>
                  </>
                </ShowResults>
              </Form>
            )}
          </Formik>
        </SectionBody>
      </Section>
    </div>
  );
};

ExportForm.propTypes = {
  contactsLength: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default ExportForm;
