import styled, { css } from 'styled-components';

const InformationWrapper = styled.div``;

const InfoWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.space(2)};

    h5 {
      ${theme.fontSize(theme.fontSizeSm(), theme.fontWeightBold)};
      color: ${theme.color('gray-font')};
      margin-bottom: 0;
    }

    p {
      margin-bottom: 0;
      ${theme.fontSize(theme.fontSizeSm())};
    }
  `}
`;

const SectionTitle = styled.h4`
  ${({ theme }) => `
    color: ${theme.blue};
    ${theme.fontSize(theme.h4FontSize(), theme.fontWeightBold)};
  `};
`;

const LastSeenText = styled.p`
  ${({ theme }) => css`
    ${theme.fontSize(theme.fontSizeSm())};
    margin-bottom: ${theme.space(2)};
    strong {
      color: ${theme.color('gray-font')};
    }
  `}
`;

const StatusWrapper = styled.h2`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    margin-bottom: ${theme.space(2)};

    * {
      margin-right: ${theme.space(1)};
    }
  `}
`;

const ButtonsWrapper = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme.space(4)};

    a, button {
      margin-bottom: ${theme.space(1)};
      margin-right: ${theme.space(1)};
    }
`}
`;

InformationWrapper.LastSeenText = LastSeenText;
InformationWrapper.StatusWrapper = StatusWrapper;
InformationWrapper.ButtonsWrapper = ButtonsWrapper;
InformationWrapper.InfoWrapper = InfoWrapper;
InformationWrapper.SectionTitle = SectionTitle;

export default InformationWrapper;
