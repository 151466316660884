import React from 'react';
import { Switch } from 'react-router-dom';

import List from './List';
import Read from './Read';
import Update from './Update';
import Create from './Create';
import { ProtectedRoute } from 'components';
import { urlSchema } from 'utils';

const FileImportClients = () => {
  const routes = [
    {
      path: urlSchema.fileImportClients.create,
      component: Create,
      right: 'Reader',
    },
    {
      path: `${urlSchema.fileImportClients.read}/:idFileImportClient`,
      component: Read,
      right: 'Reader',
    },
    {
      path: `${urlSchema.fileImportClients.edit}/:idFileImportClient`,
      component: Update,
      right: 'Reader',
    },
    { path: '/', component: List, right: 'Reader' },
  ];

  return (
    <Switch>
      {routes.map((item) => (
        <ProtectedRoute entity='FileImportClients' key={item.path} {...item} />
      ))}
    </Switch>
  );
};

export default FileImportClients;
