import React, { useState } from 'react';
import { Modal, Spinner } from 'reactstrap';

import { ImageModal, PDFModal, Translation } from 'components';
import { service } from 'services';
import { api } from 'utils';

const Document = ({ data }) => {
  const { fileName, idDocument } = data;
  const [modalVisibility, setModalVisibility] = useState(false);
  const [document, setDocument] = useState({
    modalOptions: {},
    type: '',
    isLoaded: false,
  });

  const showModal = () => {
    if (!document.isLoaded) {
      service
        .get(api.dialogues.getDocument(idDocument))
        .then((res) => {
          const source = `data:${res.data.contentType};base64, ${res.data.rawFile}`;
          setDocument({
            downloadFile: source,
            modalOptions: {
              fileName,
              source,
            },
            type: res.data.contentType.includes('image') ? 'image' : 'pdf',
            isLoaded: true,
          });
          setModalVisibility(true);
        })
        .catch((err) => console.log(err));
    } else {
      setModalVisibility(true);
    }
  };

  return (
    <div className='mb-1'>
      <button
        className='btn btn-light btn-sm font-weight-inherit'
        onClick={() => showModal()}
      >
        <i className='uil uil-file font-size-19px mr-1' />
        {fileName}
      </button>
      <Modal isOpen={modalVisibility} toggle={() => setModalVisibility(false)}>
        {document.isLoaded ? (
          <>
            {document.type === 'image' ? (
              <ImageModal options={document.modalOptions} />
            ) : (
              <PDFModal options={document.modalOptions} />
            )}
            <div className='p-3 pt-0 justify-content-end d-flex'>
              <a
                href={document.downloadFile}
                download={fileName}
                className='btn btn-primary'
              >
                <Translation id='Buttons.Download' />
              </a>
            </div>
          </>
        ) : (
          <Spinner color='primary' />
        )}
      </Modal>
    </div>
  );
};

export default Document;
