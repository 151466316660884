import { defineMessages } from 'react-intl';

export default defineMessages({
  // eligiblity
  NotEligibleAlert: {
    id: 'Screens.Patients.Create.ElibilityError',
    defaultMessage: 'Error.Screens.Patients.Create.ElibilityError',
  },
  // toasts
  EligibilitySuccessTitle: {
    id: 'Message.Success',
    defaultMessage: 'Error.Message.Success',
  },
  EligibilitySuccessBody: {
    id: 'Screens.Patients.Create.ElibilitySuccess',
    defaultMessage: 'Error.Screens.Patients.Create.ElibilitySuccess',
  },
  EligibilityErrorTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
  },
  EligibilityErrorBody: {
    id: 'Screens.Patients.Create.ElibilityError',
    defaultMessage: 'Error.Screens.Patients.Create.ElibilityError',
  },
  AlreadyUsedEmailErrorBody: {
    id: 'Screens.Patients.Create.AlreadyUsedEmailError',
    defaultMessage: 'Error.Screens.Patients.Create.AlreadyUsedEmailError',
  },
  // creation
  CreationSuccessTitle: {
    id: 'Message.Success',
    defaultMessage: 'Error.Message.Success',
  },
  CreationSuccessBody: {
    id: 'Screens.Patients.Create.CreationSuccess',
    defaultMessage: 'Error.Screens.Patients.Create.CreationSuccess',
  },
  CreationErrorTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
  },
  CreationErrorBody: {
    id: 'Screens.Patients.Create.CreationError',
    defaultMessage: 'Error.Screens.Patients.Create.CreationError',
  },
  FieldRequired: {
    id: 'Model.FieldRequired',
    defaultMessage: 'Error.Model.FieldRequired',
  },
  WrongNumber: {
    id: 'Form.FormIntlTelInput.Invalid',
    defaultMessage: 'Error.Form.FormIntlTelInput.Invalid',
  },
});
