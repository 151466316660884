import { Translation } from 'components';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import { CodeDialogueAction } from '../../../constants';
import Document from './Document';

const MessageDate = ({ date }) => (
  <>
    <hr />
    <small className='text-center d-block mt-3 my-1 text-black-50'>
      {moment(date).format('dddd Do MMMM YYYY [à] HH:mm')}
    </small>
  </>
);

const InfoMessage = ({ data }) => (
  <>
    <MessageDate date={data.dateCreation} />
    <small className='text-center font-italic d-flex align-items-center justify-content-center'>
      <i className={`uil uil-info-circle mr-1`} />
      <FormattedMessage
        id={data.codeDialogueAction}
        defaultMessage={`Error.${data.codeDialogueAction}`}
        values={{
          specialty: data.codeSpecialty,
          phone: data.message,
          name: `${data.sender.title} ${data.sender.firstname} ${data.sender.lastname}`,
          date: moment(data.dateCreation).format('dddd Do MMMM YYYY [à] HH:mm'),
        }}
      />
    </small>
  </>
);

const Message = ({ data }) => {
  const isFromPatient = data.codeDialogueAction === CodeDialogueAction.QUESTION;
  const isNoteOperator =
    data.codeDialogueAction === CodeDialogueAction.COMMENTOPERATOR;
  const { firstname, lastname } = data.sender;

  const backgroundColor = isFromPatient
    ? 'bg-color-e9f4ff'
    : isNoteOperator
    ? 'bg-color-dffff8'
    : 'bg-color-f1f1f6';

  return (
    <div className='mb-1'>
      <MessageDate date={data.dateCreation} />
      <div
        className={`${
          !isFromPatient ? 'align-items-end' : ''
        } d-flex flex-column gap-2`}
      >
        <div>
          <small className='mb-1 d-flex font-weight-bold'>
            {isNoteOperator ? (
              <Translation
                id='screens_conversation_messages_item_operatorNoteAuthor'
                values={{ name: `${firstname} ${lastname}` }}
              />
            ) : (
              <>
                <i
                  className={`uil uil-${
                    isFromPatient
                      ? 'user-circle order-first mr-1'
                      : 'heart-medical ml-1'
                  }`}
                />
                {firstname} {lastname}
              </>
            )}
          </small>
          <div className='d-flex'>
            <p className={`rounded-lg p-3 m-0 ${backgroundColor}`}>
              {isNoteOperator ? (
                data.message
              ) : (
                <>
                  <i className='uil uil-lock mr-1' />
                  <Translation id='screens_conversation_messages_item_confidentialMessage' />
                </>
              )}
            </p>
          </div>
        </div>
        {isNoteOperator && data.documents.length ? (
          <div className='mt-2'>
            <p className='mb-0 font-size-14px d-flex font-weight-bold'>
              <Translation id='screens_conversation_messages_item_filesTitle' />
            </p>
            {data.documents.map((documentData) => (
              <Document key={documentData.idDocument} data={documentData} />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const MessagesList = ({ data, conversation }) => {
  const getMessageType = (messageData) => {
    switch (messageData.codeDialogueAction) {
      case CodeDialogueAction.QUESTION:
      case CodeDialogueAction.RESPONSE:
        return <Message key={messageData.idDialogue} data={messageData} />;

      case CodeDialogueAction.COMMENTOPERATOR:
        return (
          <Message key={messageData.idDialogue} data={{ ...messageData }} />
        );
      case CodeDialogueAction.APPOINTMENTMADE:
        return (
          <InfoMessage
            key={messageData.idDialogue}
            data={{
              ...messageData,
              dateCreation: conversation?.appointment?.startDate,
            }}
          />
        );
      case CodeDialogueAction.CALLFAILED:
      case CodeDialogueAction.CALLMEAT:
      case CodeDialogueAction.CALLSUCCESS:
      case CodeDialogueAction.CLOSE:
      case CodeDialogueAction.CONSENT:
      case CodeDialogueAction.INPROGRESS:
      case CodeDialogueAction.REOPEN:
      case CodeDialogueAction.TRANSFER:
      case CodeDialogueAction.APPOINTMENTCANCELLED:
      case CodeDialogueAction.VISIOEND:
      case CodeDialogueAction.COMMENT:
      case CodeDialogueAction.COMMENTOPERATOR:
      case CodeDialogueAction.VISIOINVITATION:
      case CodeDialogueAction.SURVEY:
        return <InfoMessage key={messageData.idDialogue} data={messageData} />;

      default:
        return;
    }
  };

  const getMessages = () =>
    data.map((value) => {
      return getMessageType(value);
    });

  return <div>{getMessages()}</div>;
};

export default MessagesList;
