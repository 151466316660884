import styled, { css } from 'styled-components';

const TranslationFormWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.space(3)};

    label {
      font-weight: ${theme.fontWeightBold};
    }
  `}
`;

TranslationFormWrapper.Component = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.grayShade(400)};
    border-radius: ${theme.borderRadius};
    overflow: hidden;

    .nav-tabs {
      justify-content: space-around;
      border-bottom: 0;

      .nav-item {
        flex-grow: 1;
        border-bottom: 1px solid ${theme.grayShade(400)};

        a {
          height: 44px;
          position: relative;
          ${theme.fontSize(theme.fontSizeBase, theme.fontWeightBold)};
          color: ${theme.color('gray-font')};
          padding: 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border: none;
          border-right: 1px solid ${theme.grayShade(400)};
          border-radius: 0;
          cursor: pointer;
        }

        i {
          margin-left: ${theme.space(2)};
        }

        &:last-child a {
          border-right: none;
        }
      }
    }
  `}
`;

export default TranslationFormWrapper;
