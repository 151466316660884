import { defineMessages } from 'react-intl';

export default defineMessages({
  HandleTitle: {
    id: 'Screens.Patients.AddHealthPartner.HandleTitle',
    defaultMessage: 'Error.Screens.Patients.AddHealthPartner.HandleTitle',
  },
  AddTitle: {
    id: 'Screens.Patients.AddHealthPartner.AddTitle',
    defaultMessage: 'Error.Screens.Patients.AddHealthPartner.AddTitle',
  },
  HealthPartnerLabel: {
    id: 'Screens.Patients.AddHealthPartner.HealthPartnerLabel',
    defaultMessage: 'Error.Screens.Patients.AddHealthPartner.HealthPartnerLabel',
  },
  UserCodeLabel: {
    id: 'Screens.Patients.AddHealthPartner.UserCodeLabel',
    defaultMessage: 'Error.Screens.Patients.AddHealthPartner.UserCodeLabel',
  },
  AddError: {
    id: 'Screens.Patients.AddHealthPartner.AddError',
    defaultMessage: 'Error.Screens.Patients.AddHealthPartner.AddError',
  },
  AddSuccessHeader: {
    id: 'Message.Success',
    defaultMessage: 'Error.Message.Success',
  },
  AddSuccessBody: {
    id: 'Screens.Patients.AddHealthPartner.AddSuccessBody',
    defaultMessage: 'Error.Screens.Patients.AddHealthPartner.AddSuccessBody',
  },
});
