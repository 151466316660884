import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { PageHeader, RoleManager, Spinner } from 'components';
import { api, urlSchema, useFetch } from 'utils';
import messages from './messages';

const Read = ({ match }) => {
  const intl = useIntl();
  const { params } = match;
  const [productTermsResource, isProductTermsLoading] = useFetch(
    api.manager.getProductTermsResources(params.idProductTermResource),
  );

  return (
    <>
      <PageHeader>
        <FormattedMessage {...messages.Title} tagName='h1' />
        <RoleManager entity='ProductTermsResources' right='CRUD'>
          <Link
            className='btn btn-primary'
            to={`${urlSchema.productTermsResources.edit}/${params.idProductTermResource}`}
          >
            {intl.formatMessage(messages.ButtonsEdit)}
          </Link>
        </RoleManager>
      </PageHeader>
      {isProductTermsLoading ? (
        <Spinner color='primary' size='lg' />
      ) : (
        <Row>
          <Col lg='6'>
            <section className='section'>
              <div className='section__body'>
                <div className='model'>
                  <div className='model__property'>
                    <h4 className='model__title'>
                      {intl.formatMessage(messages.ProductTermsLanguage)}
                    </h4>
                    <p className='model__value'>
                      {productTermsResource.codeLanguage}
                    </p>
                  </div>
                  <div className='model__property'>
                    <h4 className='model__title'>
                      {intl.formatMessage(messages.ProductTermsCP)}
                    </h4>
                    <p className='model__value'>{productTermsResource.terms}</p>
                  </div>
                </div>
              </div>
            </section>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Read;
