import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Form, Field } from 'formik';
import { Col, Row } from 'reactstrap';

import { PageHeader, Spinner, Button, FormLabel } from 'components';
import { WebService } from 'models';
import { service } from 'services';
import { api, sendToast, urlSchema, useFetch } from 'utils';
import { ToastTypes } from '../../../constants';
import messages from './messages';
import ValidationSchema from './ValidationSchema';

const Update = ({ match, history }) => {
  const { formatMessage } = useIntl();
  const { webServiceId } = match.params;
  const [webService, isWebServiceLoading] = useFetch(
    api.webServices.getWebService(webServiceId),
  );
  const [webServiceConfig, isWebServiceConfigLoading] = useFetch(
    api.webServices.getAllConfig,
  );

  const onSubmit = (values, { setSubmitting }) => {
    service
      .put(api.webServices.putWebService(webServiceId), values)
      .then(({ data }) => {
        if (typeof data.succeeded !== 'undefined' && !data.succeeded) {
          sendToast(ToastTypes.error, {
            header: (
              <FormattedMessage
                values={{ name: webService.name }}
                {...messages.Fail}
              />
            ),
            body: data.errorSet[0].description,
          });
        } else {
          sendToast(ToastTypes.success, {
            body: (
              <FormattedMessage
                values={{ name: values.name }}
                {...messages.Success}
              />
            ),
          });
          history.push(`${urlSchema.webServices.read}/${values.idWebService}`);
        }
      })
      .catch((err) => {
        sendToast(ToastTypes.error, {
          header: (
            <FormattedMessage
              values={{ name: webService.name }}
              {...messages.Fail}
            />
          ),
          body: (
            <>
              <span className='m-0 small'>
                code: {err?.response?.status} ({err?.response?.statusText})
              </span>
              <br />
              <span className='m-0 small'>
                URL: {err?.response?.config?.url}
              </span>
            </>
          ),
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      {isWebServiceLoading ? (
        <Spinner size='sm' />
      ) : (
        <>
          <PageHeader>
            <h1>
              <FormattedMessage
                values={{ name: webService.name }}
                {...messages.Title}
              />
            </h1>
          </PageHeader>
          <Row>
            <Col md='6'>
              <section className='section'>
                <div className='section__body'>
                  <Formik
                    initialValues={webService}
                    onSubmit={onSubmit}
                    validationSchema={ValidationSchema}
                  >
                    {(formikProps) => (
                      <Form>
                        <WebService {...formikProps}>
                          {isWebServiceConfigLoading ? (
                            <Spinner size='sm' />
                          ) : (
                            <div className='form-group d-flex flex-column'>
                              <FormLabel
                                className='mb-1'
                                for='refWebServiceConfigs'
                                required
                              >
                                <FormattedMessage {...messages.LinkedConfig} />
                              </FormLabel>
                              <Field as='select' name='refWebServiceConfigs'>
                                <option value='' key={0}>
                                  {formatMessage(messages.SelectPlaceholder)}
                                </option>
                                {webServiceConfig.map((item) => (
                                  <option
                                    value={item.idWebServiceConfig}
                                    key={item.idWebServiceConfig}
                                  >
                                    {item.name}
                                  </option>
                                ))}
                              </Field>
                            </div>
                          )}
                        </WebService>
                        <div className='text-right'>
                          <Button
                            color='primary'
                            isProcessing={formikProps.isSubmitting}
                            label='Update'
                            size='lg'
                            type='submit '
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </section>
            </Col>
          </Row>
        </>
      )}
      ;
    </>
  );
};

export default Update;
