import React from 'react';
import { FastField } from 'formik';
import { useIntl } from 'react-intl';

import { Checkbox, FormInput, FormInputFeedback } from 'components';
import messages from './messages';

const ProductTerm = () => {
  const intl = useIntl();

  return (
    <>
      <FormInput
        id='code'
        label={intl.formatMessage(messages.ProductTermsCode)}
        name='code'
        required
        type='text'
      />
      <FormInput
        id='label'
        label={intl.formatMessage(messages.ProductTermsLabel)}
        name='label'
        required
        type='text'
      />
      <div className='mb-3'>
        <FastField name='optional'>
          {({ field, form }) => (
            <Checkbox {...field} checked={form.values.optional} id='optional'>
              {intl.formatMessage(messages.ProductTermsOptional)}
            </Checkbox>
          )}
        </FastField>
        <FormInputFeedback name='optional' />
      </div>
      <div>
        <FastField name='valid'>
          {({ field, form }) => (
            <Checkbox {...field} checked={form.values.valid} id='valid'>
              {intl.formatMessage(messages.ProductTermsValid)}
            </Checkbox>
          )}
        </FastField>
        <FormInputFeedback name='valid' />
      </div>
    </>
  );
};

export default ProductTerm;
