import { defineMessages } from 'react-intl';

export default defineMessages({
  SearchString: {
    id: 'Screens.Doctors.EditProfiles.SearchString',
    defaultMessage: 'Error.Screens.Doctors.EditProfiles.SearchString',
  },
  Title: {
    id: 'Screens.Doctors.EditProfiles.Title',
    defaultMessage: 'Error.Screens.Doctors.EditProfiles.Title',
  },
  ToastFail: {
    id: 'Toasts.Profiles.Fail',
    defaultMessage: 'Error.Toasts.Profiles.Fail',
  },
  ToastSuccess: {
    id: 'Toasts.Profiles.Success',
    defaultMessage: 'Error.Toasts.Profiles.Success',
  },
});
