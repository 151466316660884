import styled, { css } from 'styled-components';

const ListWrapper = styled.ul`
  ${({ theme }) => css`
    border: 2px dashed ${theme.color('border')};
    border-radius: ${theme.borderRadius};
    list-style-type: none;
    max-width: 100%;
    padding: ${theme.space(2)};
  `}
`;

const ItemWrapper = styled.li`
  ${({ isDragging, theme }) => css`
    align-items: center;
    background-color: ${isDragging
      ? theme.color('blue-lightest')
      : theme.white};
    border: 1px solid ${isDragging ? theme.blue : theme.color('border')};
    border-radius: ${theme.borderRadius};
    display: flex;
    margin-bottom: ${theme.space(1)};
    padding: ${theme.space(3)};
    position: relative;
    ${isDragging && `box-shadow: 0 2px ${theme.blue};`};

    figure {
      margin: 0;
    }

    img {
      max-width: 48px;
    }

    p {
      margin: 0 0 0 ${theme.space(3)};
    }
  `}
`;

const Rank = styled.p`
  ${({ theme }) => css`
    position: absolute;
    color: ${theme.grayShade(500)};
    top: ${theme.space(1)};
    right: ${theme.space(2)};
    ${theme.fontSize(theme.fontSizeSm(), theme.fontWeightBold)};
  `}
`;

ItemWrapper.Rank = Rank;
ListWrapper.Item = ItemWrapper;

export default ListWrapper;
