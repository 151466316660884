import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Formik, Field } from 'formik';
import { Container, Input, Col, Row } from 'reactstrap';
import qs from 'qs';

import {
  PageHeader,
  Section,
  SectionBody,
  Button,
  RoleManager,
} from 'components';
import { ListWrapper } from 'themes/jupiter/elements';
import { service } from 'services';
import { api, sendToast, urlSchema } from 'utils';
import { ToastTypes } from '../../../constants';
import messages from './messages';

const List = ({ location }) => {
  const searchCodeInput = useRef(null);
  const { formatMessage } = useIntl();
  const [vouchers, setVouchers] = useState([]);

  const getResults = (
    values = {
      searchCode: null,
    },
    callback,
  ) => {
    const locationParams = location.search
      ? qs.parse(location.search, { ignoreQueryPrefix: true })
      : {};
    const parsedParams = { ...values, ...locationParams };

    service
      .get(api.admin.getVouchers, { params: parsedParams })
      .then(({ data }) => {
        setVouchers(data);
        if (typeof callback === 'function') callback();
      })
      .catch(() => {
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.FailTitle} />,
          body: <FormattedMessage {...messages.FailBody} />,
        });
      });
  };

  const onSubmit = (values, { setSubmitting }) => {
    getResults(values, () => {
      setSubmitting(false);
    });
  };

  useEffect(() => {
    if (location.search)
      getResults(qs.parse(location.search, { ignoreQueryPrefix: true }));
  }, []);

  return (
    <>
      <PageHeader>
        <FormattedMessage {...messages.Title} tagName='h1' />
      </PageHeader>
      <Section>
        <SectionBody>
          <ListWrapper>
            <ListWrapper.ResearchWrapper>
              <Formik initialValues={{ searchCode: '' }} onSubmit={onSubmit}>
                {({ isSubmitting }) => (
                  <Form>
                    <Row>
                      <Col md='8' lg='9' xl='10'>
                        <Field type='text' name='searchCode'>
                          {({ field }) => {
                            const { onChange } = field;
                            return (
                              <Input
                                {...field}
                                ref={searchCodeInput}
                                id='search-code'
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                placeholder={formatMessage(messages.Code)}
                              />
                            );
                          }}
                        </Field>
                      </Col>
                      <Col className='ml-auto' md='auto'>
                        <Button
                          data-tracking='VouchersListBtnSearch'
                          color='primary'
                          isProcessing={isSubmitting}
                          label='Research'
                          type='submit'
                        />
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </ListWrapper.ResearchWrapper>
            <ListWrapper.Header>
              <Container fluid>
                <Row>
                  <Col md='2'>
                    <ListWrapper.ColumnTitle>
                      <FormattedMessage {...messages.Code} />
                    </ListWrapper.ColumnTitle>
                  </Col>
                  <Col className='d-none d-sm-block d-lg-block' md='3'>
                    <ListWrapper.ColumnTitle>
                      <FormattedMessage {...messages.ProductDisplayLabel} />
                    </ListWrapper.ColumnTitle>
                  </Col>
                  <Col className='d-none d-sm-none d-lg-block' md='3'>
                    <ListWrapper.ColumnTitle>
                      <FormattedMessage {...messages.DateExpiration} />
                    </ListWrapper.ColumnTitle>
                  </Col>
                  <Col className='d-none d-sm-none d-lg-block' md='auto'>
                    <ListWrapper.ColumnTitle>
                      <FormattedMessage {...messages.DateCreation} />
                    </ListWrapper.ColumnTitle>
                  </Col>
                </Row>
              </Container>
            </ListWrapper.Header>
            <ul className='research-table__results'>
              {vouchers.length > 0 ? (
                vouchers.map((item) => {
                  return (
                    <ListWrapper.Item key={item.idVoucher}>
                      <Container fluid>
                        <Row>
                          <Col md='2'>
                            <h6 className='mb-0'>{item.code}</h6>
                          </Col>
                          <Col md='3'>
                            <Link
                              to={`${urlSchema.products.read}/${item.refProduct}`}
                              className='btn btn-sm btn-outline-primary'
                            >
                              {item.productDisplayLabel}
                            </Link>
                          </Col>
                          <Col md='3' className='d-none d-sm-none d-lg-block'>
                            <small className='mb-0'>
                              <FormattedMessage
                                {...messages.ExpirationDate}
                                values={{
                                  date: moment(item.dateExpiration).format(
                                    'LL',
                                  ),
                                }}
                              />
                            </small>
                          </Col>
                          <Col md='2' className='d-none d-sm-none d-lg-block'>
                            <small className='mb-0'>
                              <FormattedMessage
                                {...messages.CreationDate}
                                values={{
                                  date: moment(item.dateCreation).format('LL'),
                                }}
                              />
                            </small>
                          </Col>
                          <Col md='1' className='ml-auto'>
                            <ListWrapper.Actions>
                              <RoleManager entity='Vouchers' right='Reader'>
                                <Link
                                  aria-label={formatMessage(
                                    messages.ButtonsRead,
                                  )}
                                  title={formatMessage(messages.ButtonsRead)}
                                  to={`${urlSchema.readVoucher}/${item.idVoucher}`}
                                >
                                  <i
                                    className='uil uil-eye uil--md'
                                    aria-hidden
                                  />
                                </Link>
                              </RoleManager>
                            </ListWrapper.Actions>
                          </Col>
                        </Row>
                      </Container>
                    </ListWrapper.Item>
                  );
                })
              ) : (
                <FormattedMessage {...messages.NoElement} />
              )}
            </ul>
          </ListWrapper>
        </SectionBody>
      </Section>
    </>
  );
};

export default List;
