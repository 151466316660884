import styled from 'styled-components';

const JourneysList = styled.ul`
  ${({ theme }) => `
    padding-left: 0px;
    list-style-type: none;
  `}
`;

export default JourneysList;
