import React from 'react';
import { Formik, Form } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { historyShape } from 'react-router-props';

import { ApiError, Button, PageHeader } from 'components';
import { WebServiceConfiguration } from 'models';
import { service } from 'services';
import { api, sendToast, urlSchema } from 'utils';
import validationSchema from 'models/WebServiceConfiguration/validationSchema';
import { ToastTypes } from '../../../../constants';
import messages from './messages';
import initialValues from './initialValues';

const Create = ({ history }) => {
  const { formatMessage } = useIntl();

  const onSubmit = (values) => {
    service
      .post(api.webServices.postConfiguration, values)
      .then(({ data }) => {
        if (typeof data.succeeded !== 'undefined' && !data.succeeded) {
          sendToast(ToastTypes.error, {
            header: (
              <FormattedMessage
                values={{ name: values.name }}
                {...messages.Fail}
              />
            ),
            body: data.errorSet[0].description,
          });
        } else {
          sendToast(ToastTypes.success, {
            body: (
              <FormattedMessage
                values={{ name: values.name }}
                {...messages.Success}
              />
            ),
          });
          history.push(
            `${urlSchema.webServices.configuration.read}/${data.idWebServiceConfig}`,
          );
        }
      })
      .catch((error) => {
        sendToast(ToastTypes.error, {
          header: (
            <FormattedMessage
              values={{ name: values.name }}
              {...messages.Fail}
            />
          ),
          body: <ApiError {...{ error }} />,
        });
      });
  };

  return (
    <>
      <PageHeader>
        <FormattedMessage {...messages.Title} tagName='h1' />
      </PageHeader>
      <section className='section'>
        <div className='section__body'>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema(formatMessage)}
          >
            {(formikProps) => (
              <Form>
                <WebServiceConfiguration {...formikProps} />
                <Button
                  data-tracking='WebServicesConfigurationCreateBtn'
                  color='primary'
                  isProcessing={formikProps.isSubmitting}
                  label='Create'
                  size='lg'
                />
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </>
  );
};

Create.propTypes = { history: historyShape.isRequired };

export default Create;
