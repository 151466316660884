import React, { useEffect, useRef } from 'react';
import Select from 'react-select';
import moment from 'moment-timezone';
import * as Yup from 'yup';
import _ from 'lodash';
import { observable, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';
import { ModalHeader, Modal } from 'reactstrap';
import { Field, Form, Formik } from 'formik';

import { service } from 'services';
import { api, urlSchema } from 'utils';
import { ListWrapper } from 'themes/jupiter/elements';
import {
  PageHeader,
  Section,
  SectionBody,
  Spinner,
  PDFModal,
  ImageModal,
  FormRadioButton,
  Button,
} from 'components';
import enumDocumentStatus from 'constants/EnumIdentityDocumentVerifications';
import messages from './messages';

// Observables
const identities = observable.box([]);
const documentStatus = observable.box('');
const searchString = observable.box('');
const networkIsPending = observable.box(true);
const pdfModalVisibility = observable.box(false);
const countries = observable.box([]);

const currentIdentityDocument = observable.box({
  firstname: '',
  lastname: '',
  birthdate: '',
  refBirthCountry: '',
  documentStatus: { value: '-1', label: 'Tout' },
});

const currentIdentityFileDefaultProps = {
  fileName: null,
  documentRawFile: null,
  birthPlace: '',
  refBirthCountry: '',
  contentType: '',
  documentStatus: { value: '-1', label: 'Tout' },
};
const currentIdentityFile = observable.box(currentIdentityFileDefaultProps);

// Status Select options
const options = [
  {
    value: '-1',
    label: <FormattedMessage {...messages['status.all']} />,
    badgeCls: 'badge-info',
  },
  {
    value: '0',
    label: <FormattedMessage {...messages['status.notDone']} />,
    badgeCls: 'badge-info',
  },
  {
    value: '1',
    label: <FormattedMessage {...messages['status.valid']} />,
    badgeCls: 'badge-success',
  },
  {
    value: '2',
    label: <FormattedMessage {...messages['status.pending']} />,
    badgeCls: 'badge-warning',
  },
  {
    value: '3',
    label: <FormattedMessage {...messages['status.errorDifferentName']} />,
    badgeCls: 'badge-danger',
  },
  {
    value: '4',
    label: <FormattedMessage {...messages['status.errorNonReadableID']} />,
    badgeCls: 'badge-danger',
  },
  {
    value: '5',
    label: <FormattedMessage {...messages['status.errorInvalidID']} />,
    badgeCls: 'badge-danger',
  },
  {
    value: '-99',
    label: <FormattedMessage {...messages['status.unknown']} />,
    badgeCls: 'badge-info',
  },
];

// Update the window history state with the requested get parameters
const setWindowLocationSearch = () => {
  const nextURL = `${
    urlSchema.identityVerifications
  }?documentStatus=${documentStatus.get()}&searchString=${searchString
    .get()
    .trim()}`;
  if (window.location.href.includes('IdentityVerifications'))
    window.history.replaceState(null, null, nextURL);
};

// Get all the countries
const getCountries = () => {
  service.get(api.identity.getCountries).then((results) => {
    runInAction(() => {
      countries.set(results.data);
    });
  });
};

// Get the requested document
const getCurrentDocument = () => {
  const { idDocument } = currentIdentityDocument.get();
  service
    .get(api.identityVerifications.getDocument(idDocument))
    .then((result) => {
      runInAction(() => {
        currentIdentityFile.set(result.data);
      });
    });
};

// http promise
const getPageD = () =>
  service.get(api.identityVerifications.getPageD, {
    params: {
      DocumentStatus:
        documentStatus.get() === '-1' ? null : documentStatus.get(),
      SearchString: searchString.get().trim(),
    },
  });

// Update the list of identities to verify
const refreshIdentitiesList = () => {
  runInAction(() => {
    networkIsPending.set(true);
    identities.set([]);
  });
  return getPageD().then((results) => {
    runInAction(() => {
      networkIsPending.set(false);
      identities.set(_.orderBy(results.data, ['dateCreation'], ['desc']));
    });
    setWindowLocationSearch();
  });
};

// When a status in the Select is selected
const onStatusChange = ({ value }) => {
  runInAction(() => {
    documentStatus.set(value);
    refreshIdentitiesList();
  });
};

// When the input text value is changing
const onSearchInputChange = (input) => {
  const { value } = input.target;
  runInAction(() => {
    searchString.set(value);
  });
  const weGotNoResultsAndAnEmptySearchString =
    value.length === 0 && identities.get().length === 0;
  weGotNoResultsAndAnEmptySearchString && refreshIdentitiesList();
};

// Clear the input text
const clearSearchString = () => {
  runInAction(() => {
    searchString.set('');
    refreshIdentitiesList();
  });
};

// When a click occcur on an identity list item
const onIdentityClick = (identity) => {
  runInAction(() => {
    currentIdentityDocument.set(identity);
    getCurrentDocument();
    openPdfModal();
  });
};

// Close the modal
const closePdfModal = () => {
  runInAction(() => {
    pdfModalVisibility.set(false);
    currentIdentityFile.set(currentIdentityFileDefaultProps);
  });
};

// Open the modal
const openPdfModal = () => {
  runInAction(() => {
    pdfModalVisibility.set(true);
  });
};

// Change the status of a document
const assignDocument = async (newDocumentStatus) => {
  document.activeElement.blur();
  const { idDocument } = currentIdentityDocument.get();
  const httpUri = api.identityVerifications.putDocumentStatus(
    idDocument,
    newDocumentStatus,
  );
  runInAction(() => {
    networkIsPending.set(true);
  });
  await service.put(httpUri).then(() => {
    runInAction(() => {
      currentIdentityFile.set(currentIdentityFileDefaultProps);
    });
    refreshIdentitiesList().then(() => {
      const randomIndex = identities
        .get()
        .findIndex((el) => el.fileName !== 'ci traitee.jpg');
      const nextDocument = identities.get()[randomIndex];
      if (typeof nextDocument !== 'undefined') {
        runInAction(() => {
          networkIsPending.set(false);
          currentIdentityDocument.set(identities.get()[randomIndex]);
        });
        getCurrentDocument();
      } else {
        runInAction(() => {
          refreshIdentitiesList().then(() => {
            networkIsPending.set(false);
            closePdfModal();
          });
        });
      }
    });
  });
};

// Observer Component - The list of identities to check
const IdentityList = observer(() => {
  useEffect(getCountries, []);
  return (
    <Suspense size='sm'>
      {identities.get().map((identity, index) => {
        const isLast = index === identities.get().length - 1;
        return <IdentityListItem {...identity} key={index} {...{ isLast }} />;
      })}
    </Suspense>
  );
});

// Observer Component - The identity list item
const IdentityListItem = (props) => {
  const {
    lastname,
    firstname,
    email,
    documentStatus,
    isLast,
    fileName,
    dateCreation,
  } = props;
  const { label, badgeCls } = _.find(options, {
    value: String(documentStatus),
  });
  const disabled = fileName === 'ci traitee.jpg';
  return (
    <div className={`container-fluid py-3${isLast ? '' : ' border-bottom'}`}>
      <div className='row'>
        <div className='col-6'>
          {firstname} {lastname} <br />
          <small className='text-muted'>
            <i className='uil uil-envelope mr-2' />
            {email}
          </small>
        </div>
        <div className='col-2 text-uppercase'>
          <span className={`badge ${badgeCls}`}>{label}</span>
        </div>
        <div className='col-2'>
          <span className='text-muted'>{moment(dateCreation).fromNow()}</span>
        </div>
        <div className='col-2 text-right'>
          <button
            {...{ disabled }}
            className='btn btn-link list-action-anchor'
            onClick={() => onIdentityClick(props)}
            type='button'
          >
            <i aria-hidden className='uil uil-eye uil--md' />
          </button>
        </div>
      </div>
    </div>
  );
};

// Observer Component - The submit button
const SubmitButton = observer(() => {
  const disabled = !(searchString.get().length > 0);

  const onClick = (button) => {
    button.preventDefault();
    refreshIdentitiesList();
  };

  return (
    <button
      {...{ disabled }}
      {...{ onClick }}
      className='btn btn-primary w-100'
      type='button'
    >
      <FormattedMessage {...messages['find']} />
    </button>
  );
});

// Observer Component - The search input & his clear value button
const InputGroup = observer(() => {
  const searchStringIsNotEmpty = searchString.get().length > 0;
  return (
    <div className='input-group'>
      <input
        type='text'
        className='form-control'
        value={searchString.get()}
        onChange={onSearchInputChange}
        placeholder='Entrez un nom, prénom, email, etc.'
      />
      {searchStringIsNotEmpty && (
        <div className='input-group-append'>
          <button
            className='btn btn-outline-primary'
            onClick={clearSearchString}
            type='button'
          >
            <i aria-hidden className='align-middle uil uil-times' />
          </button>
        </div>
      )}
    </div>
  );
});

// Observer Component - The MediaViewer
const MediaViewer = observer(() => {
  const hasCurrentIdentityFile =
    currentIdentityFile.get() !== null &&
    currentIdentityFile.get().fileName !== null;

  return hasCurrentIdentityFile ? (
    currentIdentityFile.get().contentType.includes('image') ? (
      <ImageModal
        options={{
          fileName: currentIdentityFile.get().fileName,
          contentType: currentIdentityFile.get().contentType,
          source: `data:${currentIdentityFile.get().contentType};base64, ${
            currentIdentityFile.get().documentRawFile
          }`,
        }}
      />
    ) : (
      <PDFModal
        options={{
          fileName: currentIdentityFile.get().fileName,
          source: `data:${currentIdentityFile.get().contentType};base64, ${
            currentIdentityFile.get().documentRawFile
          }`,
        }}
      />
    )
  ) : null;
});

const validationSchema = Yup.object().shape({
  documentStatus: Yup.string()
    .oneOf([
      enumDocumentStatus.NotDone,
      enumDocumentStatus.Valid,
      enumDocumentStatus.Pending,
      enumDocumentStatus.ErrorDifferentName,
      enumDocumentStatus.ErrorNonReadableID,
      enumDocumentStatus.ErrorInvalidID,
      enumDocumentStatus.Unknown,
    ])
    .required(),
});

// Observer Component - The document Viewer
const PdfModal = observer(() => {
  const { formatMessage } = useIntl();
  const { firstname, lastname, birthdate } = currentIdentityFile.get();
  const status = currentIdentityDocument.get().documentStatus;
  const statusOptions = _.find(options, { value: String(status) });
  const statusOptionsIsDefined = typeof statusOptions !== 'undefined';
  const label = statusOptionsIsDefined ? statusOptions.label : '';
  const badgeCls = statusOptionsIsDefined ? statusOptions.badgeCls : '';

  const imgIsLoading = currentIdentityFile.get().contentType === '';
  const isAValidBirthDate = moment(birthdate).isBefore(moment());

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    await assignDocument(values.documentStatus);
    setSubmitting(false);
    resetForm();
  };

  return (
    <Modal isOpen={pdfModalVisibility.get()} toggle={closePdfModal} size='xl'>
      <ModalHeader toggle={closePdfModal}>
        <FormattedMessage
          id='Screens.IdentityVerifications.modal.title'
          defaultMessage='Error.Screens.IdentityVerifications.modal.title'
        />
      </ModalHeader>
      <div className='d-flex flex-row'>
        <div className='col-md-7 bg-color-f1f1f6'>
          {imgIsLoading ? (
            <div className='min-height-50vh align-items-center d-flex justify-content-center'>
              <Spinner color='primary' size='lg' />
            </div>
          ) : (
            <MediaViewer />
          )}
        </div>
        <div className='col-md-5'>
          <div className='d-flex flex-column align-items-start mt-3 gap-3'>
            <span className={`badge font-size-14px ${badgeCls}`}>{label}</span>
            <div className='w-100'>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='model__property'>
                    <h3 className='font-size-14px mb-0 text-secondary'>
                      <FormattedMessage
                        id='Shared.Lastname'
                        defaultMessage='Error.Shared.Lastname'
                      />
                    </h3>
                    <p className='mb-0'>{lastname ? lastname : '/'}</p>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='model__property'>
                    <h3 className='font-size-14px mb-0 text-secondary'>
                      <FormattedMessage
                        id='Shared.Firstname'
                        defaultMessage='Error.Shared.Firstname'
                      />
                    </h3>
                    <p className='mb-0'>{firstname ? firstname : '/'}</p>
                  </div>
                </div>
              </div>

              <div className='model__property'>
                <h3 className='font-size-14px mb-0 text-secondary'>
                  <FormattedMessage
                    id='Shared.Birthdate'
                    defaultMessage='Error.Shared.Birthdate'
                  />
                </h3>
                <p className='mb-0'>
                  {isAValidBirthDate ? (
                    <>
                      {moment(birthdate).format('DD/MM/YYYY')} (
                      <FormattedMessage
                        id='Shared.Age'
                        defaultMessage='Error.Shared.Age'
                        values={{
                          age: moment().diff(moment(birthdate), 'years'),
                        }}
                      />
                      )
                    </>
                  ) : (
                    '/'
                  )}
                </p>
              </div>
            </div>
          </div>
          {}
          <Formik
            key={status}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            initialValues={{ documentStatus: String(status) }}
          >
            {({ isSubmitting, isValid, dirty }) => (
              <Form>
                <div className='d-flex flex-column gap-3'>
                  <div className='d-flex flex-column gap-2'>
                    <Field
                      name='documentStatus'
                      id='documentStatusNonReadableID'
                      component={FormRadioButton}
                      inputValue={enumDocumentStatus.ErrorNonReadableID}
                      label={formatMessage({
                        id: 'Screens.IdentityVerifications.status.errorNonReadableID',
                        defaultMessage:
                          'Error.Screens.IdentityVerifications.status.errorNonReadableID',
                      })}
                    />
                    <Field
                      name='documentStatus'
                      id='documentStatusInvalidID'
                      component={FormRadioButton}
                      inputValue={enumDocumentStatus.ErrorInvalidID}
                      label={formatMessage({
                        id: 'Screens.IdentityVerifications.status.errorInvalidID',
                        defaultMessage:
                          'Error.Screens.IdentityVerifications.status.errorInvalidID',
                      })}
                    />
                    <Field
                      name='documentStatus'
                      id='documentStatusDifferentName'
                      component={FormRadioButton}
                      inputValue={enumDocumentStatus.ErrorDifferentName}
                      label={formatMessage({
                        id: 'Screens.IdentityVerifications.status.errorDifferentName',
                        defaultMessage:
                          'Error.Screens.IdentityVerifications.status.errorDifferentName',
                      })}
                    />
                    <Field
                      name='documentStatus'
                      id='documentStatusValid'
                      component={FormRadioButton}
                      inputValue={enumDocumentStatus.Valid}
                      label={formatMessage({
                        id: 'Screens.IdentityVerifications.status.valid',
                        defaultMessage:
                          'Error.Screens.IdentityVerifications.status.valid',
                      })}
                    />
                  </div>
                  <Button
                    label='Confirm'
                    color='primary'
                    size='md'
                    disabled={!(isValid && dirty) || imgIsLoading}
                    isProcessing={isSubmitting}
                    type='submit'
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
});

// Component - Ternary wrapper
const Suspense = (props) =>
  identities.get().length === 0 ? (
    networkIsPending.get() === true ? (
      <div className='py-3 container-fluid'>
        <Spinner color='primary' size={props.size} />
      </div>
    ) : (
      <div className='py-3 container-fluid'>
        <FormattedMessage {...messages['noResults']} />
      </div>
    )
  ) : (
    props.children
  );

// Component - the main Component
const List = () => {
  const selectRef = useRef(null);
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const documentStatusParameter =
    params.get('documentStatus') ?? options[3].value;
  const { label } = _.find(options, { value: String(documentStatusParameter) });

  useEffect(() => {
    if (params.get('searchString'))
      runInAction(() => {
        searchString.set(params.get('searchString'));
      });
    onStatusChange({
      value: documentStatusParameter,
    });
  }, [window.location.href]);

  return (
    <>
      <PageHeader>
        <h1>
          <FormattedMessage {...messages['title']} />
        </h1>
      </PageHeader>
      <Section>
        <SectionBody>
          <ListWrapper>
            <section className='section mb-0'>
              <div className='section__header px-0'>
                <form className='w-100'>
                  <div className='row'>
                    <div className='col-4'>
                      <Select
                        ref={selectRef}
                        defaultValue={{
                          value: documentStatus,
                          ...{ label },
                        }}
                        onChange={onStatusChange}
                        placeholder='Statut'
                        {...{ options }}
                      />
                    </div>
                    <div className='col-6'>
                      <InputGroup />
                    </div>
                    <div className='col-2'>
                      <SubmitButton />
                    </div>
                  </div>
                </form>
              </div>
              <div className='mt-3'>
                <div className='research-table__header'>
                  <div className='container-fluid'>
                    <div className='row fw-bold'>
                      <div className='col-6'>
                        <FormattedMessage {...messages['colTitle.patient']} />
                      </div>
                      <div className='col-2'>
                        <FormattedMessage {...messages['colTitle.status']} />
                      </div>
                      <div className='col-2'>
                        <FormattedMessage {...messages['colTitle.send']} />
                      </div>
                      <div className='col-2' />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <IdentityList />
          </ListWrapper>
        </SectionBody>
      </Section>
      <PdfModal />
    </>
  );
};
export default List;
