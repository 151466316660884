import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Form, Field } from 'formik';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { api, useFetch, sendToast } from 'utils';

import {
  Section,
  SectionBody,
  SectionHeader,
  ReturnButton,
  FormSelect,
  FormInput,
  Button,
  Spinner,
} from 'components';
import { service } from 'services';

import { ToastTypes } from '../../../constants';
import messages from './messages';
import validationSchema from './validationSchema';
import HealthPartnersList from '../Read/HealthPartnersList';

const AddHealthPartner = () => {
  const { formatMessage } = useIntl();
  const { patientId } = useParams();
  const [patient, isPatientLoading] = useFetch(
    api.manager.getPatient(patientId),
  );

  const [refreshHealthPartner, setRefreshHealthPartners] = useState(0);
  const [productsOptions, setProductsOptions] = useState(null);
  const [products, areProductsLoading] = useFetch(api.admin.getProducts);
  const [healthPartners, areHealthPartnersLoading] = useFetch(
    api.manager.getPatientHealthPartners(patientId),
    null,
    [refreshHealthPartner],
  );

  const initialValues = {
    userCode: '',
    selectedProduct: '',
  };

  const onSubmit = (values, { setSubmitting, resetForm, setErrors }) => {
    service
      .post(api.manager.postPatientNewHealthPartner(patientId), values)
      .then((e) => {
        setSubmitting(false);

        if (!e.data.succeeded && e.status !== 204) {
          setErrors({ submit: true });
        } else {
          resetForm({});
          setRefreshHealthPartners(Math.random());
          sendToast(ToastTypes.success, {
            header: <FormattedMessage {...messages.AddSuccessHeader} />,
            body: <FormattedMessage {...messages.AddSuccessBody} />,
          });
        }
      })
      .catch((err) => {
        setErrors({ submit: err });
      });
  };

  useEffect(() => {
    const newArray = products.map((product) => {
      return {
        label: product.code,
        value: product.idProduct,
        productTag: product.productTag,
      };
    });
    setProductsOptions(newArray);
  }, [products]);

  return isPatientLoading ? (
    <Spinner size='md' />
  ) : (
    <>
      <div className='mb-3'>
        <ReturnButton />
      </div>
      <Row>
        <Col lg='7'>
          <Section>
            <SectionHeader>
              <FormattedMessage
                {...messages.HandleTitle}
                tagName='h1'
                values={{
                  firstname: patient.firstname,
                  lastname: patient.lastname,
                }}
              />
            </SectionHeader>
            <SectionBody>
              <div className='mt-3'>
                {areHealthPartnersLoading ? (
                  <Spinner size='md' />
                ) : (
                  <HealthPartnersList
                    refresh={setRefreshHealthPartners}
                    data={healthPartners}
                  />
                )}
              </div>
            </SectionBody>
          </Section>
        </Col>
        <Col lg='5'>
          <Section>
            <SectionHeader>
              <FormattedMessage {...messages.AddTitle} tagName='h1' />
            </SectionHeader>
            <SectionBody>
              {areProductsLoading ? (
                <Spinner size='md' />
              ) : (
                <Formik
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                  initialValues={initialValues}
                >
                  {({ isSubmitting, setFieldTouched, errors }) => (
                    <Form>
                      <Field name='selectedProduct'>
                        {({ field, meta, form }) => (
                          <FormSelect
                            meta={meta}
                            hasError={Boolean(meta.touched && meta.error)}
                            label={formatMessage({
                              ...messages.HealthPartnerLabel,
                            })}
                            name={field.name}
                            onBlur={() => setFieldTouched(field.name, true)}
                            onChange={(opt) => {
                              form.setFieldValue(field.name, opt.value);
                            }}
                            options={productsOptions}
                            required
                          />
                        )}
                      </Field>
                      <FormInput
                        id='userCode'
                        name='userCode'
                        label={formatMessage({ ...messages.UserCodeLabel })}
                        type='text'
                        required
                      />
                      <div className='text-center'>
                        {errors && errors.submit && (
                          <FormattedMessage {...messages.AddError}>
                            {(msg) => <p className='mt-1 text-danger'>{msg}</p>}
                          </FormattedMessage>
                        )}
                        <Button
                          color='primary'
                          isProcessing={isSubmitting}
                          label='Add'
                          type='submit'
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </SectionBody>
          </Section>
        </Col>
      </Row>
    </>
  );
};

export default AddHealthPartner;
