import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Spinner } from 'reactstrap';

import { PageHeader } from 'components';
import { api, urlSchema, useFetch } from 'utils';

import { ThemeContext } from 'styled-components';
import messages from './messages';

const Read = () => {
  const { refTemplateMessages } = useParams();
  const theme = useContext(ThemeContext);
  const [templateMessage, isTemplateMessageLoading] = useFetch(
    api.templateMessages.get(refTemplateMessages),
  );
  return (
    <>
      <PageHeader>
        <FormattedMessage
          {...messages.Title}
          values={{ name: templateMessage?.name }}
          tagName='h1'
        />
        <Link
          className='btn btn-primary'
          to={`${urlSchema.templateMessages.update}/${refTemplateMessages}`}
        >
          <FormattedMessage {...messages.UpdateButton} />
        </Link>
      </PageHeader>
      <div className='row'>
        <div className='col-lg-6'>
          <main className='section'>
            <div className='section__body'>
              {isTemplateMessageLoading ? (
                <Spinner color='primary' size='lg' />
              ) : (
                <>
                  <div className='row'>
                    <div className='col-6'>
                      <div className='model__property'>
                        <h3 className='model__title'>
                          <FormattedMessage {...messages.Organization} />
                        </h3>
                        <p className='model__value'>
                          {templateMessage.organizationDisplay}
                        </p>
                      </div>
                    </div>
                    <div className='col-6'>
                      <div className='model__property'>
                        <h3 className='model__title'>
                          <FormattedMessage {...messages.Name} />
                        </h3>
                        <p className='model__value'>
                          {templateMessage.name ? templateMessage.name : '-'}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-6'>
                      <div className='model__property'>
                        <h3 className='model__title'>
                          <FormattedMessage {...messages.Object} />
                        </h3>
                        <p className='model__value'>
                          {templateMessage.object
                            ? templateMessage.object
                            : '-'}
                        </p>
                      </div>
                    </div>
                    <div className='col-6'>
                      <div className='model__property'>
                        <h3 className='model__title'>
                          <FormattedMessage {...messages.Type} />
                        </h3>
                        <p className='model__value'>
                          {templateMessage.type ? templateMessage.type : '-'}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-6'>
                      <div className='model__property'>
                        <h3 className='model__title'>
                          <FormattedMessage {...messages.Language} />
                        </h3>
                        <p className='model__value'>
                          {templateMessage.codeLanguage
                            ? templateMessage.codeLanguage
                            : '-'}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </main>
        </div>
        <div className='col-md-6'>
          <iframe
            id='template-message'
            srcDoc={templateMessage.message}
            style={{
              border: `1px solid ${theme.color('border')}`,
              width: '100%',
              minHeight: 'calc(100vh - 120px)',
            }}
            title='Template message'
          />
        </div>
      </div>
    </>
  );
};

export default Read;
