import React from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';

import { Inputs } from '../inputs'
import messages from '../messages';
import validationSchema from '../validationShema';
import { PageHeader } from 'components';
import { urlSchema, sendToast } from 'utils';
import { ToastTypes } from '../../../constants';
import Store from '../store';

const CustomForm = () => {
    const history = useHistory();
    return <Formik
        enableReinitialize={false}
        validationSchema={validationSchema}
        initialValues={
            {
                codeClient: '',
                dateLastProcess: new Date(),
                email: '',
                filePath: '',
                idFileConfig: '',
                status: true,
            }
        }
        onSubmit={
            (values, { setSubmitting }) => {
                Store.addFileImportConfig(values).then((result) => {
                    history.push(`${urlSchema.fileImportConfigDetail(result.data.idFileConfig)}`);
                    setSubmitting(false);
                    sendToast(ToastTypes.success, {
                        header: <FormattedMessage {...messages['toast.add.success.header']} />,
                        body: <FormattedMessage {...messages['toast.add.success.body']} />,
                    });
                }).catch(() => {
                    setSubmitting(false);
                    sendToast(ToastTypes.error, {
                        header: <FormattedMessage {...messages['toast.add.error.header']} />,
                        body: <FormattedMessage {...messages['toast.add.error.body']} />,
                    });
                });
            }}
    >
        {
            ({ isSubmitting }) => <Inputs {...{ isSubmitting }} submitLabel={'list.create'} disabledStatus={true} />
        }
    </Formik>
}

const Create = () => <>
    <PageHeader>
        <h1><FormattedMessage {...messages['create.title']} /></h1>
    </PageHeader>

    <section className='section voucherGroupsEdit' >
        <div className="section__body">
            <CustomForm />
        </div>
    </section>
</>

export default Create;