import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext, Field } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';
import { api, useFetch } from 'utils';

import {
  Checkbox,
  FormInput,
  FormInputFeedback,
  FormInputLogo,
  FormList,
  FormSelect,
  Spinner,
  TranslationForm,
} from 'components';

import messages from './messages';

const CreateOfferForm = ({ setFormLogoData, validLanguages, update }) => {
  const { formatMessage } = useIntl();
  const { setFieldValue, setFieldTouched, values } = useFormikContext();
  const [specialties, areSpecialtiesLoading] = useFetch(api.specialties.get, {
    isValid: true,
  });
  const [products, areProductsLoading] = useFetch(api.admin.getProducts);
  const [profiles, areProfilesLoading] = useFetch(api.profiles.get);
  const [specialtiesOptions, setSpecialtiesOptions] = useState([]);

  useEffect(() => {
    let newArray = [];
    specialties.forEach((specialty) => {
      newArray = [
        ...newArray,
        { label: specialty.label, value: specialty.idSpecialty },
      ];
    });
    setSpecialtiesOptions(newArray);
  }, [specialties]);

  useEffect(() => {
    setFieldValue('step', 1);
  }, []);

  const isloading =
    !validLanguages ||
    areSpecialtiesLoading ||
    areProductsLoading ||
    areProfilesLoading;

  if (isloading) {
    return <Spinner size='lg' />;
  }

  return (
    <Row>
      <Col md='6'>
        <FormInputLogo
          defaultImage={values.logo}
          setFormLogoData={setFormLogoData}
          name='logoFile'
          refLogoName='refLogo'
        />
        <TranslationForm
          label='Contenu et traductions'
          validLanguages={validLanguages}
        >
          {(lang) => (
            <div className='pt-3 pr-3 pl-3 pb-0-3 pb-0'>
              <FormInput
                type='text'
                id={`[${lang}].name`}
                placeholder={`${formatMessage({ ...messages.Name })} ${lang}`}
                label={formatMessage({ ...messages.Name })}
                name={`[${lang}].name`}
                required
              />
              <FormInput
                id={`[${lang}].description`}
                placeholder={`${formatMessage({
                  ...messages.Description,
                })} ${lang}`}
                label={formatMessage({ ...messages.Description })}
                name={`[${lang}].description`}
                required
                type='textarea'
              />
              <FormInput
                id={`[${lang}].cta`}
                placeholder={`${formatMessage({ ...messages.CTA })} ${lang}`}
                label={formatMessage({ ...messages.CTA })}
                name={`[${lang}].cta`}
                required
                type='text'
              />
            </div>
          )}
        </TranslationForm>
        <div className='mb-3'>
          <Field name='isValid'>
            {({ field }) => (
              <Checkbox {...field} checked={values.isValid} id='is-valid'>
                {formatMessage(messages.IsValid)}
              </Checkbox>
            )}
          </Field>
          <FormInputFeedback name='isValid' />
        </div>
      </Col>
      <Col md='6'>
        <fieldset disabled={update}>
          <FormInput
            id='code'
            label={formatMessage({ ...messages.Code })}
            name='code'
            required
            type='text'
            placeholder={formatMessage({ ...messages.CodePlaceholder })}
          />
        </fieldset>
        <Field name='refSpecialty'>
          {({ field, meta, form }) => (
            <FormSelect
              meta={meta}
              hasError={Boolean(meta.touched && meta.error)}
              label={formatMessage({ ...messages.DefaultSpecialty })}
              name={field.name}
              onBlur={() => setFieldTouched(field.name, true)}
              onChange={(opt) => form.setFieldValue(field.name, opt.value)}
              options={specialtiesOptions}
              placeholder={formatMessage({
                ...messages.ButtonSelect,
              })}
              required
            />
          )}
        </Field>
        <FormList
          data={products}
          itemLabelKey='code'
          itemValueKey='idProduct'
          label={formatMessage({ ...messages.RelatedProducts })}
          name='products'
        />
        <FormList
          data={profiles}
          itemLabelKey='label'
          itemValueKey='idProfile'
          label={formatMessage({ ...messages.RelatedProfiles })}
          name='profiles'
        />
        <p className='font-weight-bold text-secondary mb-1'>
          <FormattedMessage {...messages.Options} />
          {update && (
            <small className='ml-1'>
              (<FormattedMessage {...messages.NotEditable} />)
            </small>
          )}
        </p>
        <fieldset disabled={update}>
          <Field name='options.isMedicalControlService' value='false'>
            {({ field }) => (
              <Checkbox
                {...field}
                checked={values.options.isMedicalControlService}
                id='isMedicalControlService'
              >
                {formatMessage(messages.MedicalControlService)}
              </Checkbox>
            )}
          </Field>
          <FormInputFeedback name='options.isMedicalControlService' />
        </fieldset>
        <fieldset>
          <Field name='allowFollowUpEncounter' value='false'>
            {({ field }) => (
              <Checkbox
                {...field}
                checked={values.allowFollowUpEncounter}
                id='allowFollowUpEncounter'
              >
                {formatMessage({ ...messages.FollowUpEncounter })}
              </Checkbox>
            )}
          </Field>
          <FormInputFeedback name='allowFollowUpEncounter' />
        </fieldset>
      </Col>
    </Row>
  );
};

CreateOfferForm.defaultProps = {
  update: false,
};

CreateOfferForm.propTypes = {
  setFormLogoData: PropTypes.func.isRequired,
  validLanguages: PropTypes.array.isRequired,
  update: PropTypes.bool,
};

export default CreateOfferForm;
