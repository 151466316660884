import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { historyShape, matchShape } from 'react-router-props';
import { useParams } from 'react-router-dom';

import { Button, ApiError, PageHeader, Spinner } from 'components';
import { Actualities } from 'models';
import { service } from 'services';
import { api, sendToast, urlSchema, useFetch } from 'utils';
import validationSchema from 'models/Actualities/validationSchema';
import Profiles from './Profiles';
import messages from './messages';
import { ToastTypes } from '../../../constants';

const Update = ({ history }) => {
  const actuId = useParams().actuRef;
  const { formatMessage } = useIntl();
  const [actuality, isActualityLoading] = useFetch(
    api.actualities.getActuality(actuId),
  );
  const [actuProfiles, areActuProfilesLoading] = useFetch(
    api.actualities.getActualityProfiles(actuId),
  );

  if (actuality) {
    actuality.dateStart = moment(actuality.dateStart).format('YYYY-MM-DD');
    actuality.dateEnd = moment(actuality.dateEnd).format('YYYY-MM-DD');
  }

  const onSubmit = (values, { setSubmitting }) => {
    service
      .put(api.actualities.putActuality(actuId), values)
      .then(({ data }) => {
        if (data.succeeded === false) {
          sendToast(ToastTypes.error, {
            body: <FormattedMessage {...messages.ToastFail} />,
          });
          setSubmitting(false);
          return;
        }
        sendToast(ToastTypes.success, {
          body: (
            <FormattedMessage
              {...messages.ToastSuccess}
              values={{ name: data.title }}
            />
          ),
        });
        history.push(`${urlSchema.actualities.read}/${actuId}`);
      })
      .catch((error) => {
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.ToastFail} />,
          body: <ApiError {...{ error }} />,
        });
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <h1>
          <FormattedMessage id='Screens.Actualities.Update.Title' />{' '}
          {actuality.title}
        </h1>
      </PageHeader>
      {isActualityLoading || areActuProfilesLoading ? (
        <Spinner color='primary' size='lg' />
      ) : (
        <Formik
          initialValues={{
            ...actuality,
            selectedProfiles: actuProfiles,
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema(formatMessage)}
        >
          {(formikProps) => (
            <Form>
              <div className='row'>
                <div className='col-7'>
                  <section className='section'>
                    <div className='section__body'>
                      <Actualities />
                      <Button
                        color='primary'
                        isProcessing={formikProps.isSubmitting}
                        label='Save'
                        size='lg'
                        type='submit'
                      />
                    </div>
                  </section>
                </div>
                <div className='col-5'>
                  <section className='section'>
                    <header className='section__header'>
                      <h2>{formatMessage({ ...messages.Profiles })}</h2>
                    </header>
                    <div className='section__body'>
                      <Profiles />
                    </div>
                  </section>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

Update.propTypes = {
  history: historyShape.isRequired,
  match: matchShape.isRequired,
};
export default Update;
