import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonValidate: {
    id: 'Buttons.Validate',
    defaultMessage: 'Error.Buttons.Validate',
  },
  FailBody: {
    id: 'Toasts.Profiles.EditProducts.Error',
    defaultMessage: 'Error.Toasts.Profiles.EditProducts.Error',
  },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
  },
  Placeholder: {
    id: 'Screens.Profiles.EditProducts.Placeholder',
    defaultMessage: 'Error.Screens.Profiles.EditProducts.Placeholder',
  },
  SuccessTitle: {
    id: 'Message.Success',
    defaultMessage: 'Error.Message.Success',
  },
  SuccessBody: {
    id: 'Toasts.Profiles.EditProducts.Success',
    defaultMessage: 'Error.Toasts.Profiles.EditProducts.Success',
  },
  Title: {
    id: 'Screens.Profiles.EditRoles.Title',
    defaultMessage: 'Error.Screens.Profiles.EditRoles.Title',
  },
});
