import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import SurveysWrapper from './elements';
import SurveyItem from './SurveyItem';

const SurveysList = ({ data }) => {
  if (data.length === 0) {
    return (
      <FormattedMessage
        id='Shared.NoElement'
        defaultMessage='Error.Shared.NoElement'
        tagName='span'
      />
    );
  }

  return (
    <SurveysWrapper>
      {data.map((survey) => (
        <SurveyItem key={survey.idQuestionnaire} data={survey} />
      ))}
    </SurveysWrapper>
  );
};

SurveysList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default SurveysList;
