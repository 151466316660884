import PropTypes from 'prop-types';

export default {
  eligiblePartnersResources: PropTypes.arrayOf(
    PropTypes.shape({
      eligibilityKey: PropTypes.string,
      eligibilityKeyDescription: PropTypes.string,
    })
  ),
  name: PropTypes.string.isRequired,
  isEligible: PropTypes.bool,
  keywords: PropTypes.string,
  refClient: PropTypes.string,
  partnerPhoneNumber: PropTypes.string,
  urlPartner: PropTypes.string,
};
