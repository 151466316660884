import styled, { css } from 'styled-components';
import { Container } from 'reactstrap';

const OfferItemWrapper = styled(Container)`
  ${({ theme }) => css`
    border: 1px solid ${theme.color('border')};
    border-radius: ${theme.borderRadiusLg};
    padding-bottom: ${theme.space(3)};
    padding-top: ${theme.space(3)};

    h5 {
      color: ${theme.color('gray-font')};
      margin-bottom: ${theme.space(1)};
      ${theme.fontSize(theme.fontSizeSm(), theme.fontWeightBold)};
    }

    & > .row {
      /* Buttons Col */
      & > [class*='col']:last-child {
        text-align: right;
        align-self: center;
      }
    }



    @media ${theme.screenXlMax} {
      text-align: center;

      /* Buttons Col */
      & > .row > [class*='col']:last-child {
          margin-top: ${theme.space(3)};
          text-align: center;
        }
      }
    }
  `}
`;

const OfferIcon = styled.span`
  ${({ theme, color }) => css`
    height: 40px;
    width: 40px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: ${theme.space(3)};
    border-radius: ${theme.borderRadiusSm};
    background-color: ${color === 'blue'
      ? theme.color('blue-lightest')
      : theme.color('green-lightest')};

    img {
      width: 16px;
      height: 16px;
    }
  `}
`;

const Header = styled.header`
  ${({ theme }) => css`
    margin-bottom: ${theme.space(2)};
    margin-top: ${theme.space(3)};

    p {
      margin: 0;
    }

    @media ${theme.screenXlMin} {
      margin-top: 0;
    }
  `}
`;

const Logo = styled.img`
  ${({ theme }) => css`
    border-radius: ${theme.borderRadiusLg};
    max-width: 100%;
    height: auto;
    width: 40px;

    @media ${theme.screenXlMax} {
      max-height: 64px;
      width: auto;
    }
  `}
`;

const Title = styled.h4`
  ${({ theme }) => css`
    color: ${theme.blue};
    margin: 0;
    ${theme.fontCustomSize(theme.fontSizeBase, theme.fontCustomWeightBold)};
  `}
`;

OfferItemWrapper.Header = Header;
OfferItemWrapper.Logo = Logo;
OfferItemWrapper.Title = Title;
OfferItemWrapper.OfferIcon = OfferIcon;

export default OfferItemWrapper;
