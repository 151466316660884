import { defineMessages } from 'react-intl';

export default defineMessages({
  Title: {
    id: 'Screens.Application.List.Title',
    defaultMessage: 'Error.Screens.Application.List.Title',
    description: 'Application page title',
  },
  TitleColumnCode: {
    id: 'Application.Code',
    defaultMessage: 'Error.Application.Code',
    description: 'Title Code',
  },
  TitleColumnUrl: {
    id: 'Application.Url',
    defaultMessage: 'Error.Application.Url',
    description: 'Title Url',
  },
  TitleColumnProfiles: {
    id: 'Application.Profiles',
    defaultMessage: 'Error.Application.Profiles',
    description: 'Title Profiles',
  },
  ButtonCreateApplication: {
    id: 'Buttons.Create',
    defaultMessage: 'Error.Buttons.Create',
    description: 'Button to create a application',
  },
  ButtonEdit: {
    id: 'Buttons.Edit',
    defaultMessage: 'Error.Buttons.Edit',
  },
  ButtonRead: {
    id: 'Buttons.Read',
    defaultMessage: 'Error.Buttons.Read',
    description: 'Button to read a application',
  },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
    description: 'Toast title in case of Error',
  },
  FailBody: {
    id: 'Message.Api.Error',
    defaultMessage: 'Error.Message.Api.Error',
    description: 'Toast body in case of error',
  },
});
