import React from 'react';
import { Switch } from 'react-router-dom';

import { ProtectedRoute } from 'components';
import { urlSchema } from 'utils';

// Screens
import List from './List';
import Read from './Read';

const routes = [
  {
    path: `${urlSchema.readVoucher}/:idVoucher`,
    component: Read,
    right: 'Reader',
  },
  { path: '/', component: List, right: 'Reader' },
];

const Vouchers = () => {
  return (
    <Switch>
      {routes.map((item) => (
        <ProtectedRoute entity='Vouchers' key={item.path} {...item} />
      ))}
    </Switch>
  );
};

export default Vouchers;
