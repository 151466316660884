import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Translation from './../Translation';

const renderPageNumbers = (currentPage, totalPages, separator) => {
  const pageNumbers = [];
  const ellipsisThreshold = 3;

  if (totalPages <= ellipsisThreshold) {
    for (let index = 1; index <= totalPages; index++) {
      pageNumbers.push(index);
    }
  } else {
    pageNumbers.push(1);
    pageNumbers.push(2);

    if (currentPage > 3 && currentPage < totalPages - 1) {
      pageNumbers.push(separator);
      pageNumbers.push(currentPage);
      pageNumbers.push(separator);
    } else if (currentPage <= 3) {
      if (totalPages > 3) {
        pageNumbers.push(3);
      }
      pageNumbers.push(separator);
    } else if (currentPage >= totalPages - 1) {
      pageNumbers.push(separator);
      pageNumbers.push(totalPages - 2);
      pageNumbers.push(totalPages - 1);
    }

    pageNumbers.push(totalPages);
  }

  return pageNumbers;
};

const Pagination = ({ items, itemsPerPage, renderItem }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(items.length / itemsPerPage);
  const separator = '...';

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  if (items.length === 0) {
    return (
      <p className='text-secondary m-0 mt-3'>
        <Translation id='Shared.NoElement' />
      </p>
    );
  }

  return (
    <div className='pagination-container'>
      <ul className='research-table__results'>
        {items
          .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
          .map((item) => (
            <li key={item.idUser}>{renderItem(item)}</li>
          ))}
      </ul>
      <nav className='my-4 py-3'>
        <ul className='pagination justify-content-center'>
          {currentPage !== 1 && (
            <li className='page-item'>
              <button
                className='page-link'
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <Translation id='Shared.Previous' />
              </button>
            </li>
          )}
          {renderPageNumbers(currentPage, totalPages, separator).map(
            (number, index) => (
              <React.Fragment key={index}>
                {number === separator ? (
                  <li className='page-item disabled'>
                    <span className='page-link'>{number}</span>
                  </li>
                ) : (
                  <li
                    className={`page-item ${currentPage === number ? 'active' : ''}`}
                  >
                    <button
                      className='page-link'
                      onClick={() => handlePageChange(number)}
                    >
                      {number}
                    </button>
                  </li>
                )}
              </React.Fragment>
            ),
          )}
          {currentPage !== totalPages && (
            <li className='page-item'>
              <button
                className='page-link'
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <Translation id='Shared.Next' />
              </button>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

Pagination.propTypes = {
  items: PropTypes.array.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  renderItem: PropTypes.func.isRequired,
};

export default Pagination;
