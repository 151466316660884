import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Badge, Col, Row, Spinner } from 'reactstrap';

import { api, urlSchema, useFetch } from 'utils';
import { PageHeader, RoleManager } from 'components';
import messages from './messages';

const Read = () => {
  const intl = useIntl();
  const { roleId } = useParams();
  const [role, isRoleLoading] = useFetch(api.roles.getDetails(roleId));

  return (
    <>
      <PageHeader>
        <FormattedMessage
          tagName='h1'
          values={{ code: role.code || '' }}
          {...messages.Title}
        />
        <RoleManager entity='Roles' right='CRUD'>
          <Link
            className='btn btn-primary'
            to={`${urlSchema.roles.update}/${role.idRole}`}
          >
            {intl.formatMessage({ ...messages.ButtonEdit })}
          </Link>
        </RoleManager>
      </PageHeader>
      <Row>
        <Col md='6'>
          <section className='section'>
            {isRoleLoading ? (
              <Spinner color='primary' size='lg' />
            ) : (
              <div className='section__body'>
                <div className='model'>
                  <div className='model__actions'>
                    <Badge color={role.published ? 'success' : 'danger'}>
                      <div className='d-flex align-items-center'>
                        {role.published ? (
                          <>
                            <span className='mr-1'>
                              <FormattedMessage {...messages.Published} />
                            </span>
                            <i aria-hidden className='uil uil-check-circle' />
                          </>
                        ) : (
                          <>
                            <span className='mr-1'>
                              <FormattedMessage {...messages.Unpublished} />
                            </span>
                            <i aria-hidden className='uil uil-times-circle' />
                          </>
                        )}
                      </div>
                    </Badge>
                  </div>
                  <div className='model__property'>
                    <h3 className='model__title'>
                      <FormattedMessage {...messages.Code} />
                    </h3>
                    <p className='model__value'>{role.code}</p>
                  </div>
                  <div className='model__property'>
                    <h3 className='model__title'>
                      <FormattedMessage {...messages.Label} />
                    </h3>
                    <p className='model__value'>{role.label}</p>
                  </div>
                  <div className='model__property'>
                    <h3 className='model__title'>
                      <FormattedMessage {...messages.Ranking} />
                    </h3>
                    <p className='model__value'>{role.ranking}</p>
                  </div>
                </div>
              </div>
            )}
          </section>
        </Col>
      </Row>
    </>
  );
};

export default Read;
