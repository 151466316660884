import React from 'react';
import { Form, Field } from 'formik';
import { FormattedMessage } from 'react-intl';

import { Checkbox, FormInput } from 'components';
import messages from '../messages';

const fields = [
  {
    name: 'codeClient',
    label: <FormattedMessage {...messages['detail.partner']} />,
    required: true,
  },
  {
    name: 'filePath',
    label: <FormattedMessage {...messages['detail.directory']} />,
    required: true,
  },
  {
    name: 'email',
    label: <FormattedMessage {...messages['detail.email']} />,
    required: false,
  },
];

const Fields = () =>
  fields.map((field) => (
    <div className='form-group' key={field.name}>
      <FormInput
        id={field.name}
        label={field.label}
        name={field.name}
        type='text'
        required={field.required}
      />
    </div>
  ));

export const Inputs = ({ isSubmitting, submitLabel, disabledStatus }) => (
  <Form>
    <Fields />
    <div className='form-group'>
      <Field name='status'>
        {({ field, form }) => (
          <Checkbox
            {...field}
            checked={form.values.status}
            id='form-control-valid'
            disabled={disabledStatus}
          >
            <FormattedMessage {...messages['detail.status']} />
          </Checkbox>
        )}
      </Field>
    </div>
    <div className='form-group text-right'>
      <button
        className='btn btn-primary btn-lg'
        type='submit'
        disabled={isSubmitting}
      >
        <FormattedMessage {...messages[submitLabel]} />
      </button>
    </div>
  </Form>
);
