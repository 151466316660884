import React, { useEffect, useRef } from 'react';
import CsvStore from '../stores/CsvStore';
import { observer } from 'mobx-react-lite';


const onOptionChange = option => {
    CsvStore.selectedPartner = CsvStore.partners[CsvStore.partners.findIndex((c) => c === option.target.value)];
    CsvStore.getPartnerOperationsCsv(CsvStore.selectedPartner);
}

export const SelectPartners = () => {
    const selectRef = useRef(null);
    useEffect(() => {
        CsvStore.getPartnerOperationsCsv(selectRef.current.value);
    }, []);

    const OptionList = observer(() => (
        <select
            ref={selectRef}
            className=''
            value={CsvStore.selectedPartner}
            onChange={onOptionChange}
            name="partnerId"
            id="floatingSelect"
            className="form-control border-primary text-color-242627">
            {
                CsvStore.partners.map((e, i) => <option key={i} value={e}>{e}</option>)
            }
        </select>
    ));

    return <div className="form-floating">
        <label htmlFor="floatingSelect">Sélectionner le partenaire</label>
        <OptionList />
    </div>
};