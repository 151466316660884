import React from 'react';
import Proptypes from 'prop-types';
import { Label } from 'reactstrap';
import { Field } from 'formik';

import {
  FormTileRadioButtonWrapper,
  CheckedIconWrapper,
  IconWrapper,
  ContentWrapper,
} from './elements';

const FormTileRadioButton = ({
  name,
  label,
  value,
  description,
  icon,
  onClick,
}) => (
  <Field name={name}>
    {({ field: { onChange, onBlur }, form: { values } }) => (
      <FormTileRadioButtonWrapper isActive={values[name] === value}>
        <Label for={`${name}-${value}`}>
          <IconWrapper>{icon}</IconWrapper>
          <ContentWrapper>
            <input
              className='hidden-input'
              name={name}
              id={`${name}-${value}`}
              type='radio'
              value={value}
              checked={values[name] === value}
              onChange={onChange}
              onBlur={onBlur}
              onClick={onClick}
            />
            <FormTileRadioButtonWrapper.Title>
              {label}
            </FormTileRadioButtonWrapper.Title>
            <FormTileRadioButtonWrapper.Description>
              {description}
            </FormTileRadioButtonWrapper.Description>
          </ContentWrapper>
          {values[name] === value && (
            <CheckedIconWrapper className='uil uil-check' aria-hidden />
          )}
        </Label>
      </FormTileRadioButtonWrapper>
    )}
  </Field>
);

FormTileRadioButton.defaultProps = {
  description: '',
};

FormTileRadioButton.propTypes = {
  name: Proptypes.string.isRequired,
  label: Proptypes.string.isRequired,
  value: Proptypes.string.isRequired,
  description: Proptypes.string,
  icon: Proptypes.node.isRequired,
  onClick: Proptypes.func.isRequired,
};

export default FormTileRadioButton;
