import React from 'react';
import { Switch } from 'react-router-dom';

import { ProtectedRoute } from 'components';
import { urlSchema } from 'utils';
import Create from './Create';
import Read from './Read';
import Update from './Update';
import List from './List';

const TemplateMessages = () => {
  const routes = [
    {
      path: urlSchema.templateMessages.create,
      component: Create,
      right: 'CRUD',
    },
    {
      path: `${urlSchema.templateMessages.read}/:refTemplateMessages`,
      component: Read,
      right: 'Reader',
    },
    {
      path: `${urlSchema.templateMessages.update}/:refTemplateMessages`,
      component: Update,
      right: 'CRUD',
    },
    { path: '/', component: List, right: 'Reader' },
  ];

  return (
    <Switch>
      {routes.map((item) => (
        <ProtectedRoute entity='TemplateMessages' key={item.path} {...item} />
      ))}
    </Switch>
  );
};

export default TemplateMessages;
