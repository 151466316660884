import * as yup from 'yup';
import { Helpers } from '../../constants';

const duplicatedMessage = {
  FieldRequired: 'Model.FieldRequired',
};

export default (formatMessage) => ({
  address1: yup.string().nullable(),
  address2: yup.string().nullable(),
  birthdate: yup.string().required(duplicatedMessage.FieldRequired),
  city: yup.string().nullable(),
  codeCountry: yup.string().nullable(),
  email: yup.string().required(duplicatedMessage.FieldRequired),
  firstname: yup.string().required(duplicatedMessage.FieldRequired),
  lastname: yup.string().required(duplicatedMessage.FieldRequired),
  mobileNumber: yup.lazy((value) => {
    return value
      ? yup
          .string()
          .matches(
            Helpers.PHONE_REGEX,
            formatMessage({
              id: 'Form.FormIntlTelInput.Invalid',
              defaultMessage: 'Error.Form.FormIntlTelInput.Invalid',
            }),
          )
          .required(
            formatMessage({
              id: duplicatedMessage.FieldRequired,
              defaultMessage: `{Error.${duplicatedMessage.FieldRequired}}`,
            }),
          )
      : yup.string().required(
          formatMessage({
            id: duplicatedMessage.FieldRequired,
            defaultMessage: `{Error.${duplicatedMessage.FieldRequired}}`,
          }),
        );
  }),
  notification: yup.string().required(duplicatedMessage.FieldRequired),
  phoneNumber: yup.lazy((value) => {
    return value
      ? yup.string().matches(
          Helpers.PHONE_REGEX,
          formatMessage({
            id: 'Form.FormIntlTelInput.Invalid',
            defaultMessage: 'Error.Form.FormIntlTelInput.Invalid',
          }),
        )
      : yup.string().nullable();
  }),
  valid: yup.boolean().required(),
  zipCode: yup.string().nullable(),
});
