import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Spinner } from 'reactstrap';
import { saveAs } from 'file-saver';

import { PageHeader } from 'components';
import { service } from 'services';
import { api, useFetch } from 'utils';
import messages from './messages';

export default () => {
  const { formatMessage } = useIntl();
  const [sepaFiles, areSepaFilesLoading] = useFetch(api.sepa.list);

  const download = (idDocument) => {
    service
      .get(api.sepa.download(idDocument), {
        responseType: 'blob',
      })
      .then((res) => {
        const fileName = res.headers['content-disposition']
          .split('filename=')[1]
          .split(';')[0];
        const file = new Blob([res.data], {
          type: res.headers['content-type'],
        });
        saveAs(file, fileName);
      });
  };

  return (
    <>
      <PageHeader>
        <div>
          <h1>{formatMessage({ ...messages.Title })}</h1>
          <p className='m-0'>{formatMessage({ ...messages.Subtitle })}</p>
        </div>
      </PageHeader>
      <section className='section'>
        <div className='section__body'>
          {areSepaFilesLoading ? (
            <Spinner color='primary' size='lg' />
          ) : sepaFiles.length > 0 ? (
            <ul className='list-group'>
              {sepaFiles.map((item) => (
                <li className='list-group-item' key={item.idDocument}>
                  <div className='d-flex align-items-center justify-content-between'>
                    <h4 className='m-0'>{item.fileName}</h4>
                    <div>
                      <Button
                        color='primary'
                        onClick={() => {
                          download(item.idDocument);
                        }}
                        size='sm'
                        outline
                      >
                        {formatMessage({ ...messages.ButtonDownload })}
                      </Button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className='text-secondary small m-0'>
              {formatMessage({ ...messages.NoElement })}
            </p>
          )}
        </div>
      </section>
    </>
  );
};
