import { defineMessages } from 'react-intl';

import { generateTranslationsObject } from 'utils';

const prefix = 'Model.Profile.';
const keys = [
  'Code',
  'Label',
  'Ranking',
  'RefOrganizationConfig',
  'StrongAuth',
];

const additionalKeys = {
  FieldRequired: {
    id: 'Shared.FieldRequired',
    defaultMessage: 'Error.Shared.FieldRequired',
  },
};

export default defineMessages({
  ...generateTranslationsObject(prefix, keys),
  ...additionalKeys,
});
