import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { urlSchema } from 'utils';

import List from './List';
import Read from './Read';
import Update from './Update';
import Create from './Create';

const routes = [
  {
    path: `${urlSchema.surveyUpdate}/:surveyId`,
    component: Update,
    right: 'Reader',
  },
  {
    path: `${urlSchema.surveyDetails}/:surveyId`,
    component: Read,
    right: 'Reader',
  },
  {
    path: `${urlSchema.surveyCreate}`,
    component: Create,
    right: 'Reader',
  },

  { path: '/', component: List, right: 'Reader' },
];

export const Questionnaires = () => (
  <Switch>
    {routes.map((item) => (
      <Route key={item.path} entity='Surveys' {...item} />
    ))}
  </Switch>
);
