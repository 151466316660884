import { useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const createWrapperAndAppendToBody = (id) => {
  const element = document.createElement('div');
  element.setAttribute('id', id);
  document.body.append(element);

  return element;
};

const Portal = ({ children, wrapperId }) => {
  const [wrapperElement, setWrapperElement] = useState(null);

  // On mount function
  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    let systemCreated = false;

    if (!element) {
      systemCreated = true;
      element = createWrapperAndAppendToBody(wrapperId);
    }
    setWrapperElement(element);

    // On unmount function
    return () => {
      // delete the programatically created element
      if (systemCreated && element.parentNode) {
        element.remove();
      }
    };
  }, [wrapperId]);

  // wrapperElement state will be null on very first render.
  if (wrapperElement === null) return null;

  // return the createPortal function
  return createPortal(children, wrapperElement);
};

Portal.propTypes = {
  children: PropTypes.node.isRequired,
  wrapperId: PropTypes.string.isRequired,
};

export { Portal };
