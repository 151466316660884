import styled from 'styled-components';

const ReturnButtonContainer = styled.div`
  button,
  a {
    color: #fff;
    i {
      margin-right: ${(props) => props.theme.space(2)};
      position: relative;
      left: 0;
      transition: ${(props) => props.theme.transitionBase};
      vertical-align: middle;
    }
    &:hover {
      color: #fff;

      i {
        left: ${(props) => props.theme.space(2)};
      }
    }
  }
`;

export { ReturnButtonContainer };
