import React from 'react';
import PropTypes from 'prop-types';
import { Link, Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

const ProtectedRoute = ({ component, entity, path, right, ...props }) => {
  return true ? (
    <Route component={component} path={path} {...props} />
  ) : (
    <div id='access-denied'>
      <div className='mx-auto'>
        <h1>403</h1>
        <FormattedMessage tagName='h2' {...messages.Subtitle} />
        <FormattedMessage tagName='h3' {...messages.Description} />
        <Link to='/'>
          <FormattedMessage {...messages.ButtonBack} />
        </Link>
      </div>
    </div>
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  entity: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  right: PropTypes.string.isRequired,
};

export default ProtectedRoute;
