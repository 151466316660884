import { defineMessages } from 'react-intl';

export default defineMessages({
  CreateOfferTitle: {
    id: 'Screens.Offers.CreateOffer.Title',
    defaultMessage: 'Error.Screens.Offers.CreateOffer.Title',
    description: 'Title of partner creation page',
  },
  CreateOfferSubtitle: {
    id: 'Screens.Offers.CreateOffer.Subtitle',
    defaultMessage: 'Error.Screens.Offers.CreateOffer.Subtitle',
    description: 'Subtitle of partner creation page',
  },
  CreateRoomTitle: {
    id: 'Screens.Offers.CreateRoom.Title',
    defaultMessage: 'Error.Screens.Offers.CreateRoom.Title',
  },
  CreateRoomSubtitle: {
    id: 'Screens.Offers.CreateRoom.Subtitle',
    defaultMessage: 'Error.Screens.Offers.CreateRoom.Subtitle',
  },
  RoomsRequired: {
    id: 'Screens.Offers.CreateRoom.RoomsRequired',
    defaultMessage: 'Error.Screens.Offers.CreateRoom.RoomsRequired',
  },
  CTA: {
    id: 'Screens.Offers.CreateOffer.CTA',
    defaultMessage: 'Error.Screens.Offers.CreateOffer.CTA',
  },
  SuccessTitle: {
    id: 'Message.Success',
    defaultMessage: 'Error.Message.Success',
    description: 'Toast title in case of success',
  },
  SuccessBody: {
    id: 'Offers.Create.SuccessBody',
    defaultMessage: 'Error.Offers.Create.SuccessBody',
    description: 'Toast body in case of success',
  },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
    description: 'Toast title in case of Error',
  },
  FailBody: {
    id: 'Offers.Create.FailBody',
    defaultMessage: 'Error.Offers.Create.FailBody',
    description: 'Toast body in case of error',
  },
  CodeMaxLength: {
    id: 'Offers.Create.CodeMaxLength',
    defaultMessage: 'Error.Offers.Create.CodeMaxLength',
  },
  CodeRegexError: {
    id: "Offers.Create.CodeRegexError",
    defaultMessage: "Errors.Offers.Create.CodeRegexError"
  },
  ToastErrorDescription: {
    id: 'Toasts.Offers.Error.Description',
    defaultMessage: 'Error.Toasts.Offers.Error.Description',
  },
  ToastErrorCode: {
    id: 'Toasts.Offers.Error.Code',
    defaultMessage: 'Error.Toasts.Offers.Error.Code',
  },
  ToastDescriptionDuplicateCode: {
    id: 'Toasts.Offers.Error.Description.DuplicateCode',
    defaultMessage: 'Error.Toasts.Offers.Error.Description.DuplicateCode',
  },
});
