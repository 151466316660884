import { defineMessages } from 'react-intl';

export default defineMessages({
  Title: {
    id: 'Screens.Universes.Details.Title',
    defaultMessage: 'Error.Screens.Universes.Details.Title',
  },
  ButtonAdd: {
    id: 'Buttons.Add',
    defaultMessage: 'Error.Buttons.Add',
  },
  ButtonEdit: {
    id: 'Buttons.Edit',
    defaultMessage: 'Error.Buttons.Edit',
  },
  ButtonReadTemplateMessages: {
    id: 'Buttons.ReadMessageTemplates',
    defaultMessage: 'Error.Buttons.ReadMessageTemplates',
  },
  Code: {
    id: 'Model.Profile.Code',
    defaultMessage: 'Error.Model.Profile.Code',
  },
  RefOrganizationConfig: {
    id: 'Model.Universe.RefOrganizationConfig',
    defaultMessage: 'Error.Model.Universe.RefOrganizationConfig',
  },
  Label: {
    id: 'Model.Profile.Label',
    defaultMessage: 'Error.Model.Profile.Label',
  },
  CreatedAt: {
    id: 'Model.CreatedAt',
    defaultMessage: 'Error.Model.CreatedAt',
  },
  CreationDate: {
    id: 'Model.CreationDate',
    defaultMessage: 'Error.Model.CreationDate',
  },
  Customers: {
    id: 'Universes.Customers',
    defaultMessage: 'Error.Universes.Customers',
  },
  NoElement: {
    id: 'Shared.NoElement',
    defaultMessage: 'Error.Shared.NoElement',
  },
  ReadCustomers: {
    id: 'Screens.Universes.List.Buttons.ReadCustomers',
    defaultMessage: 'Error.Screens.Universes.List.Buttons.ReadCustomers',
  },
  ReadDoctors: {
    id: 'Screens.Universes.List.Buttons.ReadDoctors',
    defaultMessage: 'Error.Screens.Universes.List.Buttons.ReadDoctors',
  },
  TemplateMessages: {
    id: 'Model.Universe.TemplateMessages',
    defaultMessage: 'Error.Model.Universe.TemplateMessages',
  },
  Doctors: {
    id: 'Universes.Doctors',
    defaultMessage: 'Error.Universes.Doctors',
  },
});
