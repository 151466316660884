import { defineMessages } from 'react-intl';

export default defineMessages({
  AvailableOnly: {
    id: 'Screens.Doctors.List.AvailableOnly',
    defaultMessage: 'Error.Screens.Doctors.List.AvailableOnly',
  },
  ButtonCreate: {
    id: 'Buttons.Create',
    defaultMessage: 'Error.Buttons.Create',
  },
  Contact: {
    id: 'Screens.Doctors.List.Contact',
    defaultMessage: 'Error.Screens.Doctors.List.Contact',
  },
  CreateButton: {
    id: 'Buttons.Create',
    defaultMessage: 'Error.Buttons.Create',
  },
  Doctor: {
    id: 'Screens.Doctors.List.Doctor',
    defaultMessage: 'Error.Screens.Doctors.List.Doctor',
  },
  DoctorsSearch: {
    id: 'Screens.Doctors.List.Search',
    defaultMessage: 'Screens.Doctors.List.Search',
  },
  EditButton: {
    id: 'Buttons.Edit',
    defaultMessage: 'Error.Buttons.Edit',
  },
  FailBody: {
    id: 'Screens.Applications.Create.FailBody',
    defaultMessage: 'Error.Screens.Applications.Create.FailBody',
    description: 'Toast body in case of error',
  },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
    description: 'Toast title in case of Error',
  },
  NoElement: {
    id: 'Shared.NoElement',
    defaultMessage: 'Error.Shared.NoElement',
  },
  Organization: {
    id: 'Screens.Doctors.List.Organization',
    defaultMessage: 'Error.Screens.Doctors.List.Organization',
  },
  ReadButton: {
    id: 'Buttons.Read',
    defaultMessage: 'Error.Buttons.Read',
  },
  Specialties: {
    id: 'Screens.Doctors.List.Specialties',
    defaultMessage: 'Error.Screens.Doctors.List.Specialties',
  },
  Title: {
    id: 'Screens.Doctors.List.Title',
    defaultMessage: 'Error.Screens.Doctors.List.Title',
  },
});
