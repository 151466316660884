import React from 'react';
import { Switch } from 'react-router-dom';

import { ProtectedRoute } from 'components';
import { urlSchema } from 'utils';

// Screens
import Create from './Create';
import List from './List';
import Read from './Read';
import Update from './Update';

const routes = [
  { path: urlSchema.createPartner, component: Create, right: 'CRUD' },
  {
    path: `${urlSchema.readPartner}/:partnerId`,
    component: Read,
    right: 'Reader',
  },
  {
    path: `${urlSchema.updatePartner}/:partnerId`,
    component: Update,
    right: 'CRUD',
  },
  { path: '/', component: List, right: 'Reader' },
];

const Partners = () => {
  return (
    <Switch>
      {routes.map((item) => (
        <ProtectedRoute entity='EligiblePartners' key={item.path} {...item} />
      ))}
    </Switch>
  );
};

export default Partners;
