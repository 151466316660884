import React from 'react';
import { Switch } from 'react-router-dom';

import { ProtectedRoute } from 'components';
import { urlSchema } from 'utils';

// Screens
import Configuration from './Configuration';
import Status from './Status';
import Create from './Create';
import List from './List';
import Read from './Read';
import Update from './Update';

const routes = [
  {
    component: Configuration,
    path: urlSchema.webServices.configuration.list,
    right: 'CRUD',
  },
  { component: Status, path: urlSchema.webServices.status, right: 'Reader' },
  { component: Create, path: urlSchema.webServices.create, right: 'CRUD' },
  {
    component: Read,
    path: `${urlSchema.webServices.read}/:webServiceId`,
    right: 'Reader',
  },
  {
    component: Update,
    path: `${urlSchema.webServices.update}/:webServiceId`,
    right: 'CRUD',
  },
  { component: List, path: urlSchema.webServices.list, right: 'Reader' },
];

const WebServices = () => {
  return (
    <Switch>
      {routes.map((item) => (
        <ProtectedRoute entity='WebServices' key={item.path} {...item} />
      ))}
    </Switch>
  );
};

export default WebServices;
