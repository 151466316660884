import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, FormGroup, InputGroup, Label } from 'reactstrap';

import { Settings } from '../../../constants';
import FormInputFeedback from '../FormInputFeedback';

const googleMapsId = 'googleMaps';

const loadMaps = (callback) => {
  const existingScript = document.getElementById(googleMapsId);
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${
      Settings.API_GOOGLE_MAPS_KEY
    }&libraries=places&s=${Math.random(2)}`;
    script.id = googleMapsId;
    document.body.appendChild(script);
    // Action to do after a script is loaded in our case setState
    script.addEventListener('load', () => {
      if (callback) callback();
    });
  }
  if (existingScript && callback) callback();
};

const unloadMaps = () => {
  console.info('Script: Unload Google Maps');
  const googlePlacesScript = document.getElementById(googleMapsId);
  if (googlePlacesScript) {
    googlePlacesScript.remove();
  }
};

const getAddressComponentsType = (addressComponents, type) => {
  const addressComponent = addressComponents.find(
    (current) => current.types[0] === type,
  );
  if (addressComponent) {
    return addressComponent.short_name;
  }
  return null;
};

const FormPlacesAutoComplete = ({
  addressKeys,
  field,
  form,
  label,
  placeholder,
  required,
  ...props
}) => {
  const { name } = field;
  const googleInputName = 'googleAddress';
  const { errors, setFieldValue, setFieldTouched, setValues, touched, values } =
    form;
  const inputRef = useRef();
  const [autocomplete, setAutocomplete] = useState(false);
  const [isAutocompleteReady, setIsAutocompleteReady] = useState(false);

  const setFields = (placeData) => {
    if (
      !Object.prototype.hasOwnProperty.call(placeData, 'address_components') ||
      !Object.prototype.hasOwnProperty.call(placeData, 'formatted_address')
    ) {
      return;
    }
    setValues((formikValues) => {
      return {
        ...formikValues,
        [googleInputName]: placeData.formatted_address,
        [name]: placeData.name,
        [addressKeys.city]: getAddressComponentsType(
          placeData.address_components,
          'locality',
        ),
        [addressKeys.codeCountry]: getAddressComponentsType(
          placeData.address_components,
          'country',
        ),
        [addressKeys.zipCode]: getAddressComponentsType(
          placeData.address_components,
          'postal_code',
        ),
      };
    });
    setFieldTouched(name);
  };

  const resetFields = () => {
    setFields({ formatted_address: null, address_components: [] });
  };

  useEffect(() => {
    loadMaps(() => {
      setAutocomplete(
        new window.google.maps.places.Autocomplete(inputRef.current),
      );
    });

    return () => {
      unloadMaps();
    };
  }, []);

  useEffect(() => {
    const handleSelectPlace = () => {
      resetFields();
      setFields(autocomplete.getPlace());
    };
    if (autocomplete && !isAutocompleteReady) {
      autocomplete.addListener('place_changed', handleSelectPlace);
      if (values[googleInputName] === '') {
        const { address1, zipCode, city, codeCountry } = values;
        const formattedAddress = address1
          ? `${address1}, ${zipCode} ${city}${
              codeCountry ? `, ${codeCountry}` : ''
            }`
          : '';
        setFieldValue(googleInputName, formattedAddress);
      }
      setIsAutocompleteReady(true);
    }
  }, [autocomplete]);

  return (
    <>
      <FormGroup>
        <Label for={googleInputName}>
          {label}
          {required && <span className='text-danger ml-1'>*</span>}
        </Label>
        <InputGroup>
          <Input
            {...field}
            {...{ placeholder }}
            innerRef={inputRef}
            invalid={touched[name] && errors[name]}
            name={googleInputName}
            onChange={(e) => {
              resetFields();
            }}
            value={values[googleInputName]}
          />
        </InputGroup>
        {touched[name] && errors[name] ? (
          <FormInputFeedback {...{ name }} />
        ) : null}
      </FormGroup>
      <input {...{ name }} type='hidden' {...field} />
    </>
  );
};

FormPlacesAutoComplete.defaultProps = {
  addressKeys: {
    city: 'city',
    zipCode: 'zipCode',
    codeCountry: 'codeCountry',
  },
  size: undefined,
  required: false,
};

FormPlacesAutoComplete.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  addressKeys: PropTypes.object,
  required: PropTypes.bool,
};

FormPlacesAutoComplete.displayName = 'Components_FormPlacesAutoComplete';

export default FormPlacesAutoComplete;
