import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Row, Col } from 'reactstrap';
import moment from 'moment';

import messages from '../messages';

export const AccountInformation = ({ data }) => {
  const intl = useIntl();

  return (
    <>
      <div className='mb-3'>
        <FormattedMessage {...messages.AccountInformation} tagName='h2' />
      </div>
      <Row>
        <Col md='12'>
          <div className='model__property'>
            <h3 className='model__title'>
              {intl.formatMessage({
                ...messages.AccountCreationDate,
              })}
            </h3>
            <p className='model__value'>
              {moment(data.dateCreation).format('LL')}
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};

AccountInformation.displayName = 'Screens_Doctors_Read_Account_Information';
