import styled from 'styled-components';

const IsActiveWrapper = styled.div`
  ${({ isActive, theme }) => `
    align-items: center;
    display: flex;

    i {
      color: ${isActive ? theme.green : theme.grayShade(200)};
      margin-right: ${theme.space(3)};
    }

    p {
      margin: 0;
    }
  `}
`;

export { IsActiveWrapper };
