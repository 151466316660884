import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import { Col, Row } from 'reactstrap';
import { locationShape } from 'react-router-props';
import qs from 'qs';
import { isNil } from 'lodash';

import { Button, PageHeader, Section, SectionBody, Spinner } from 'components';
import { Specialty } from 'models';
import validationSchema from 'models/Specialty/validationSchema';
import { service } from 'services';
import { api, sendToast, urlSchema, useFetch } from 'utils';
import { ButtonsWrapper } from 'themes/jupiter/elements';
import { ToastTypes } from '../../../constants';
import messages from './messages';
import { initialValues } from './initialValues';

const setInitialValues = (languages, data) => {
  const newValues = data;

  languages.forEach((validLanguage) =>
    newValues.name.push({
      languageCode: validLanguage.code,
      content: '',
    }),
  );

  return newValues;
};

const Create = ({ location }) => {
  const intl = useIntl();
  const { search } = location;
  const [validLanguages, areValidLanguagesLoading] = useFetch(
    api.admin.getValidLanguages,
  );

  const onSubmit = async (values, { setSubmitting }) => {
    // TODO: "label" field added as french translation for backward compatibility. Remove it when no need
    const frenchLabel = values.name.find(
      (element) => element.languageCode === 'FR',
    );

    try {
      const { data } = await service.post(api.admin.postSpecialty, {
        ...values,
        // TODO: "label" field added as french translation for backward compatibility. Remove it when no need
        label: frenchLabel.content,
      });
      if (!isNil(data.succeeded) && data.succeeded === false) {
        throw new Error(data.errorSet[0].description);
      }
      sendToast(ToastTypes.success, {
        header: '',
        body: <FormattedMessage {...messages.ToastSuccess} />,
      });
      window.location = `${urlSchema.specialties.read}/${data.idSpecialty}`;
    } catch (error) {
      sendToast(ToastTypes.error, {
        header: <FormattedMessage {...messages.ToastFail} />,
        body: error.message,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageHeader>
        <FormattedMessage {...messages.Title} tagName='h1' />
      </PageHeader>
      <Row>
        <Col md='10' lg='8'>
          <Section>
            <SectionBody>
              {areValidLanguagesLoading || initialValues === null ? (
                <Spinner size='lg' />
              ) : (
                <Formik
                  initialValues={{
                    ...setInitialValues(validLanguages, initialValues),
                    ...qs.parse(search, { ignoreQueryPrefix: true }),
                  }}
                  {...{ onSubmit }}
                  validationSchema={validationSchema(
                    validLanguages.length,
                    intl,
                  )}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <Specialty />
                      <ButtonsWrapper bottom>
                        <Button
                          color='primary'
                          label='Create'
                          isProcessing={isSubmitting}
                          size='lg'
                        />
                      </ButtonsWrapper>
                    </Form>
                  )}
                </Formik>
              )}
            </SectionBody>
          </Section>
        </Col>
      </Row>
    </>
  );
};

Create.propTypes = { location: locationShape.isRequired };

export default Create;
