import * as yup from 'yup';
import messages from './messages';

export default (intl) =>
  yup.object().shape({
    codeClient: yup
      .string()
      .required(intl.formatMessage({ ...messages.FieldRequired })),
    codeEntity: yup
      .string()
      .required(intl.formatMessage({ ...messages.FieldRequired })),
    refProduct: yup
      .string()
      .required(intl.formatMessage({ ...messages.FieldRequired })),
  });
