import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { TabContent, TabPane, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Section, SectionBody, SectionNav, PageHeader } from 'components';
import UpdateAddressTab from './UpdateAddressTab';
import UpdatePersonalInformations from './UpdatePersonalInformations';
import messages from './messages';

const PatientInformations = ({ address, setPrerequisitesTrigger }) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <PageHeader>
        <FormattedMessage {...messages.title} tagName='h1' />
      </PageHeader>
      <Section>
        <SectionNav>
          <NavItem style={{ maxWidth: '50%' }}>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggle('1');
              }}
            >
              <FormattedMessage {...messages.administrativeNavItem} />
            </NavLink>
          </NavItem>
          <NavItem style={{ maxWidth: '50%' }}>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                toggle('2');
              }}
            >
              <FormattedMessage {...messages.medicalNavItem} />
            </NavLink>
          </NavItem>
        </SectionNav>
        <SectionBody>
          <TabContent activeTab={activeTab}>
            <TabPane tabId='1'>
              <UpdateAddressTab
                address={address}
                setPrerequisitesTrigger={setPrerequisitesTrigger}
              />
            </TabPane>
            <TabPane tabId='2'>
              <UpdatePersonalInformations
                setPrerequisitesTrigger={setPrerequisitesTrigger}
              />
            </TabPane>
          </TabContent>
        </SectionBody>
      </Section>
    </>
  );
};

PatientInformations.propTypes = {
  address: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
  }),
  setPrerequisitesTrigger: PropTypes,
};

export default PatientInformations;
