import styled, { css } from 'styled-components';
import { Col } from 'reactstrap';

const PatientDialoguesListWrapper = styled.ul`
  ${({ theme }) => `
    padding-left: 0;
  `}
`;

const Item = styled.li`
  ${({ theme }) => `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: ${theme.space(2)};
    padding-top: ${theme.space(2)};
    border-bottom: 1px solid ${theme.grayShade(200)};

    .row {
      width: 100%;
      margin: 0;
      flex: 100%;

      div {
        padding-left: 0;
        padding-right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  `};
`;

const DialogueInfo = styled.div`
  ${({ theme }) => css`
    p {
      ${theme.fontSize(theme.fontSizeSm())};
      margin-bottom: 0;
    }
  `}
`;

const DialogueDescription = styled(Col)`
  ${({ theme }) => `
     p {
       margin-bottom: 0;
     }
  `}
`;

const DialogueInfoAppointment = styled.p`
  ${({ theme }) => `
    ${theme.fontSize(theme.fontSizeSm())};
  `}
`;

const DialogueInfoDate = styled.p`
  ${({ theme }) => `
    ${theme.fontSize(theme.fontSizeBase, theme.fontWeightBold)};  
  `};
`;

const DialogueInfoOperator = styled.a`
  ${({ theme }) => `
    ${theme.fontSize(theme.fontSizeSm())};
    color: ${theme.color('gray-font')};
    text-decoration: underline;
    margin-left: ${theme.space(1)};
  `}
`;

const InfoType = styled(Col)`
  ${({ theme }) => css`
    max-width: ${theme.space(5)};
    width: 40px;
    margin-right: ${theme.space(3)};

    h3 {
      line-height: 40px;
      border-radius: 100%;
      background-color: ${theme.color('blue-lightest')};
      color: ${theme.blue};
      text-align: center;
      margin-left: ${theme.space(1)};
      margin-right: ${theme.space(1)};
    }

    h4 {
      margin-left: ${theme.space(1)};
      ${theme.fontSize(theme.fontSizeSm(), theme.fontWeightBold)};
      color: ${theme.color('blue-font')};
      margin-bottom: 0;
    }
  `}
`;

PatientDialoguesListWrapper.Item = Item;
PatientDialoguesListWrapper.InfoType = InfoType;
PatientDialoguesListWrapper.DialogueInfo = DialogueInfo;
PatientDialoguesListWrapper.DialogueDescription = DialogueDescription;
PatientDialoguesListWrapper.DialogueInfoDate = DialogueInfoDate;
PatientDialoguesListWrapper.DialogueInfoAppointment = DialogueInfoAppointment;
PatientDialoguesListWrapper.DialogueInfoOperator = DialogueInfoOperator;

export default PatientDialoguesListWrapper;
