import styled from 'styled-components';

const FormListWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.space(3)};

  &&& {
    li {
      & > * {
        align-items: center;
        border-bottom: 1px solid ${({ theme }) => theme.color('border')};
        display: flex;
        height: 100%;
        margin-bottom: 0;
        width: 100%;
      }

      label {
        margin-bottom: 0;
      }
    }
  }

  li {
    padding: 0 ${({ theme }) => theme.space(3)};

    &:last-child {
      border-bottom: 0;
    }
  }

  p {
    font-weight: ${({ theme }) => theme.fontWeightBold};
    margin: 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }
`;

const Button = styled.button.attrs({
  type: 'button',
})`
  background-color: ${({ isAllSelected, theme }) =>
    isAllSelected ? theme.blue : theme.white};
  border: 1px solid
    ${({ isAllSelected, theme }) =>
      isAllSelected ? theme.blue : theme.color('border')};
  border-radius: ${({ theme }) => theme.borderRadius};
  height: 20px;
  margin-right: ${({ theme }) => theme.space(2)};
  position: relative;
  width: 20px;

  i {
    color: ${({ theme }) => theme.white};
    left: 50%;
    opacity: ${({ isAllSelected }) => (isAllSelected ? 1 : 0)};
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const ButtonsWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.color('gray-lightest')};
  border: 1px solid ${({ theme }) => theme.color('border')};
  border-top-left-radius: ${({ theme }) => theme.borderRadius};
  border-top-right-radius: ${({ theme }) => theme.borderRadius};
  display: flex;
  padding: ${({ theme }) => theme.space(2)} ${({ theme }) => theme.space(3)};

  p {
    ${({ theme }) => theme.fontSize(theme.fontSizeSm())};
  }
`;

FormListWrapper.Button = Button;
FormListWrapper.ButtonsWrapper = ButtonsWrapper;

export default FormListWrapper;
