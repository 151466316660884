import React from 'react';
import { Formik, Form } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Spinner } from 'reactstrap';
import { historyShape, matchShape } from 'react-router-props';

import { ApiError, Button, PageHeader } from 'components';
import { WebServiceConfiguration } from 'models';
import { service } from 'services';
import { api, sendToast, urlSchema, useFetch } from 'utils';
import validationSchema from 'models/WebServiceConfiguration/validationSchema';
import { ToastTypes } from '../../../../constants';
import messages from './messages';

const Update = ({ history, match }) => {
  const { formatMessage } = useIntl();
  const { idWebServiceConfiguration } = match.params;
  const [webServiceConfiguration, isWebServiceConfigurationLoading] = useFetch(
    `${api.webServices.getConfiguration}/${idWebServiceConfiguration}`,
  );

  const onSubmit = (values) => {
    service
      .put(api.webServices.putConfiguration(idWebServiceConfiguration), values)
      .then(({ data }) => {
        if (typeof data.succeeded !== 'undefined' && !data.succeeded) {
          sendToast(ToastTypes.error, {
            header: (
              <FormattedMessage
                values={{ name: values.name }}
                {...messages.Fail}
              />
            ),
            body: data.errorSet[0].description,
          });
        } else {
          sendToast(ToastTypes.success, {
            body: (
              <FormattedMessage
                values={{ name: values.name }}
                {...messages.Success}
              />
            ),
          });
          history.push(
            `${urlSchema.webServices.configuration.read}/${values.idWebServiceConfig}`,
          );
        }
      })
      .catch((error) => {
        sendToast(ToastTypes.error, {
          header: (
            <FormattedMessage
              values={{ name: values.name }}
              {...messages.Fail}
            />
          ),
          body: <ApiError {...{ error }} />,
        });
      });
  };

  return (
    <>
      <PageHeader>
        <FormattedMessage
          {...messages.Title}
          tagName='h1'
          values={{ name: webServiceConfiguration.name || '' }}
        />
      </PageHeader>
      <section className='section'>
        <div className='section__body'>
          {isWebServiceConfigurationLoading ? (
            <div className='py-3 text-center'>
              <Spinner color='primary' size='lg' />
            </div>
          ) : (
            <Formik
              initialValues={{
                ...webServiceConfiguration,
                refWebService: '',
                userBirthDate: webServiceConfiguration.userBirthDate
                  ? webServiceConfiguration.userBirthDate.split('T')[0]
                  : '',
              }}
              onSubmit={onSubmit}
              validationSchema={validationSchema(formatMessage)}
            >
              {(formikProps) => (
                <Form>
                  <WebServiceConfiguration {...formikProps} />
                  <Button
                    data-tracking='WebServicesConfigurationUpdateBtn'
                    color='primary'
                    isProcessing={formikProps.isSubmitting}
                    label='Update'
                    size='lg'
                  />
                </Form>
              )}
            </Formik>
          )}
        </div>
      </section>
    </>
  );
};

Update.propTypes = {
  history: historyShape.isRequired,
  match: matchShape.isRequired,
};

export default Update;
