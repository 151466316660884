import React, { useEffect } from 'react';
import { Form } from './Form';
import { UploadHistory } from './UploadHistory';
import CsvStore from './stores/CsvStore';

export const CsvValidator = () => {
    useEffect(() => {
        CsvStore.getPartners();
    }, []);
    
    return <>
        <Form />
        <UploadHistory />
    </>
};