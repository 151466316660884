import { defineMessages } from 'react-intl';

export default defineMessages({
  Blank: {
    id: 'Shared.Blank',
    defaultMessage: 'Error.Shared.Blank',
  },
  ButtonEdit: {
    id: 'Buttons.Edit',
    defaultMessage: 'Error.Buttons.Edit',
  },
  ButtonGenerateVoucher: {
    id: 'Buttons.GenerateVoucher',
    defaultMessage: 'Error.Buttons.GenerateVoucher',
  },
  ButtonManage: {
    id: 'Buttons.Manage',
    defaultMessage: 'Error.Buttons.Manage',
  },
  ButtonSeeSubscribers: {
    id: 'Buttons.SeeSubscribers',
    defaultMessage: 'Error.Buttons.SeeSubscribers',
  },
  ButtonSeeVouchers: {
    id: 'Buttons.SeeVouchers',
    defaultMessage: 'Error.Buttons.SeeVouchers',
  },
  Client: {
    id: 'Models.Product.Client',
    defaultMessage: 'Error.Models.Product.Client',
  },
  Code: {
    id: 'Models.Product.Code',
    defaultMessage: 'Error.Models.Product.Code',
  },
  CreationDate: {
    id: 'Model.CreationDate',
    defaultMessage: 'Error.Model.CreationDate',
  },
  Duration: {
    id: 'Models.Product.Duration',
    defaultMessage: 'Error.Models.Product.Duration',
  },
  ExpirationDate: {
    id: 'Models.Product.ExpirationDate',
    defaultMessage: 'Error.Models.Product.ExpirationDate',
  },
  IsValid: { id: 'Shared.Valid', defaultMessage: 'Error.Shared.Valid' },
  IsInvalid: { id: 'Shared.Invalid', defaultMessage: 'Error.Shared.Invalid' },
  Label: {
    id: 'Models.Product.Label',
    defaultMessage: 'Error.Models.Product.Label',
  },
  MaxQuestionsCount: {
    id: 'Models.Product.MaxQuestionsCount',
    defaultMessage: 'Error.Models.Product.MaxQuestionsCount',
  },
  NoElement: {
    id: 'Shared.NoElement',
    defaultMessage: 'Error.Shared.NoElement',
  },
  NoLogo: {
    id: 'Shared.NoLogo',
    defaultMessage: 'Error.Shared.NoLogo',
  },
  SellsyClient: {
    id: 'Models.Product.SellsyClient',
    defaultMessage: 'Error.Models.Product.SellsyClient',
  },
  Specialties: {
    id: 'Products.Specialty',
    defaultMessage: 'Error.Products.Specialty',
  },
  Tag: {
    id: 'Models.Product.Tag',
    defaultMessage: 'Error.Models.Product.Tag',
  },
  Title: {
    id: 'Screens.Products.Read.Title',
    defaultMessage: 'Error.Screens.Products.Read.Title',
  },
  VerificationType: {
    id: 'Models.Product.VerificationType',
    defaultMessage: 'Error.Models.Product.VerificationType',
  },
  Vouchers: {
    id: 'Models.Product.Vouchers',
    defaultMessage: 'Error.Models.Product.Vouchers',
  },
});
