export default {
  address1: '',
  address2: '',
  birthdate: '',
  city: '',
  codeCountry: '',
  description: '',
  email: '',
  firstname: '',
  infosAdmin: { description: '', codeCompany: '' },
  lastname: '',
  mobileNumber: '',
  notification: 'EMAIL',
  refUniverse: '',
  phoneNumber: '',
  zipCode: '',
  selectedProfiles: [],
  valid: false,
};
