import { defineMessages } from 'react-intl';

export default defineMessages({
  Active: {
    id: 'Model.Specialty.ActiveLabel',
    defaultMessage: 'Error.Specialty.ActiveLabel',
  },
  ButtonEdit: {
    id: 'Buttons.Edit',
    defaultMessage: 'Error.Buttons.Edit',
  },
  SpecialtyCode: {
    id: 'Model.Specialty.Code',
    defaultMessage: 'Error.Model.Specialty.Code',
  },
  SpecialtyName: {
    id: 'Model.Specialty.Name',
    defaultMessage: 'Error.Model.Specialty.Name',
  },
  Exclusive: {
    id: 'Model.Specialty.ExclusiveLabel',
    defaultMessage: 'Model.Specialty.ExclusiveLabel',
  },
  Inactive: {
    id: 'Model.Specialty.InactiveLabel',
    defaultMessage: 'Error.Model.Specialty.InactiveLabel',
  },
  Invalid: {
    id: 'Shared.Invalid',
    defaultMessage: 'Error.Shared.Invalid',
  },
  OrganizationConfigCode: {
    id: 'Model.Specialty.OrganizationConfigCode',
    defaultMessage: 'Error.Model.Specialty.OrganizationConfigCode',
  },
  ReadDoctors: {
    id: 'Screens.Specialties.ReadDoctors',
    defaultMessage: 'Error.Screens.Specialties.ReadDoctors',
  },
  SpecialtyDetailsTitle: {
    id: 'Screens.Specialties.Details.Title',
    defaultMessage: 'Error.Screens.Specialties.Details.Title',
  },
  Unexclusive: {
    id: 'Model.Specialty.UnexclusiveLabel',
    defaultMessage: 'Model.Specialty.UnexclusiveLabel',
  },
  Valid: {
    id: 'Shared.Valid',
    defaultMessage: 'Error.Shared.Valid',
  },
});
