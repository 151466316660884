import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Formik, Form } from 'formik';
import { Col, Row } from 'reactstrap';

import { PageHeader, Button } from 'components';
import { WebService } from 'models';
import { service } from 'services';
import { api, sendToast, urlSchema } from 'utils';
import { ToastTypes } from '../../../constants';
import messages from './messages';
import ValidationSchema from './ValidationSchema';

const Create = ({ history }) => {
  const onSubmit = (values, { setSubmitting }) => {
    service
      .post(api.webServices.postWebService, values)
      .then(({ data }) => {
        if (typeof data.succeeded !== 'undefined' && !data.succeeded) {
          sendToast(ToastTypes.error, {
            header: (
              <FormattedMessage
                values={{ name: values.name }}
                {...messages.Fail}
              />
            ),
            body: data.errorSet[0].description,
          });
        } else {
          sendToast(ToastTypes.success, {
            body: (
              <FormattedMessage
                values={{ name: values.name }}
                {...messages.Success}
              />
            ),
          });
          history.push(`${urlSchema.webServices.read}/${data.idWebService}`);
        }
      })
      .catch((err) => {
        sendToast(ToastTypes.error, {
          header: (
            <FormattedMessage
              values={{ name: values.name }}
              {...messages.Fail}
            />
          ),
          body: (
            <>
              <span className='m-0 small'>
                code: {err?.response?.status} ({err?.response?.statusText})
              </span>
              <br />
              <span className='m-0 small'>
                URL: {err?.response?.config?.url}
              </span>
            </>
          ),
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  const initialValues = {
    name: '',
    className: '',
    isValid: false,
  };

  return (
    <>
      <PageHeader>
        <h1>
          <FormattedMessage {...messages.Title} />
        </h1>
      </PageHeader>
      <Row>
        <Col md='6'>
          <section className='section'>
            <div className='section__body'>
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={ValidationSchema}
              >
                {(formikProps) => (
                  <Form>
                    <WebService {...formikProps} />
                    <div className='text-right'>
                      <Button
                        color='primary'
                        isProcessing={formikProps.isSubmitting}
                        label='Create'
                        size='lg'
                        type='submit '
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </section>
        </Col>
      </Row>
    </>
  );
};

Create.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default Create;
