import styled, { css } from 'styled-components';

const Bar = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.color('gray-font')};
    height: 2px;
    width: 100%;
  `}
`;

const dotDimensions = '14px';
const Dot = styled.i`
  ${({ active, theme }) => css`
    background-color: ${active ? theme.blue : theme.color('gray-font')};
    border-radius: 100%;
    display: block;
    height: ${dotDimensions};
    width: ${dotDimensions};
  `}
`;

const NavigationLinkWrapper = styled.li`
  ${({ active, checked, isFirst, isLast, theme }) => css`
    flex-grow: 1;
    text-align: center;

    ${Bar} {
      background-color: ${checked && theme.blue};

      &:first-of-type {
        background-color: ${isFirst
      ? 'transparent'
      : (!isFirst && active) || (!isFirst && checked)
        ? theme.blue
        : theme.color('gray-font')
    };
      }

      &:last-of-type {
        background-color: ${isLast
      ? 'transparent'
      : !isLast && checked
        ? theme.blue
        : theme.color('gray-font')
    };
      }
    }

    ${Dot} {
      background-color: ${active ? theme.blue : theme.color('gray-font')};
    }

    a,
    p,
    button {
      ${theme.fontSize(theme.fontSizeSm())};
      color: ${active || checked ? theme.blue : theme.color('gray-font')};
      margin: 0;
      padding: 0;
    }

    a {
      &.active {
        color: ${theme.blue};
      }
    }
    
    p {
      display: inline;
      margin: 0;
    }
  `}
`;



const dotCheckedDimensions = '24px';
const DotChecked = styled.i`
  ${({ theme }) => css`
    background-color: ${theme.blue};
    border-radius: 100%;
    color: ${theme.white};
    display: block;
    line-height: ${dotCheckedDimensions} !important;
    text-align: center;
    width: ${dotCheckedDimensions};
  `}
`;

const DotContainer = styled.div`
  ${({ theme }) => css`
    margin: 0 ${theme.space(2)};
  `}
`;

const Flex = styled.div`
  align-items: center;
  display: flex;
  min-height: calc(${dotCheckedDimensions} + 8px);
`;

export { Bar, Dot, DotChecked, DotContainer, Flex, NavigationLinkWrapper };
