import styled from 'styled-components';
import { Nav } from 'reactstrap';

const SectionNavWrapper = styled(Nav)`
  border-bottom: 1px solid ${({ theme }) => theme.color('border')};
  flex-wrap: nowrap;

  a {
    border-bottom: 2px solid transparent;
    color: ${({ theme }) => theme.color('gray-font')};
    cursor: pointer;
    margin-bottom: -1px;
    max-width: 100%;
    overflow: hidden;
    padding: ${({ theme }) => theme.space(3)};
    position: relative;
    text-overflow: ellipsis;
    transition: ${({ theme }) => theme.transitionBase};
    white-space: nowrap;

    &:hover {
      border-color: ${({ theme }) => theme.grayShade(400)};
    }

    &.active {
      border-color: ${({ theme }) => theme.blue};
      color: ${({ theme }) => theme.black};
    }
  }

  li {
    flex-grow: 1;
    text-align: center;
  }

  @media ${({ theme }) => theme.screenMdMin} {
    a {
      padding-left: ${({ theme }) => theme.space(4)};
      padding-right: ${({ theme }) => theme.space(4)};
    }
  }
`;

export default SectionNavWrapper;
