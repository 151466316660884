import styled from 'styled-components';

const ToastWrapper = styled.div`
  ${({ theme }) => `
    min-height: ${theme.toastHeight};
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
  `}
`;

const Text = styled.div`
  ${({ theme }) => `
		color: ${theme.color('gray-font')};

		h5, 
		p {
			margin: 0;
		}

		p {
			${theme.fontSize(theme.fontSizeSm())};
		}
	`}
`;

ToastWrapper.Text = Text;

export default ToastWrapper;
