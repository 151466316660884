import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Code: {
    id: 'Screens.Patients.Read.HealthPartnersList.HealthPartner.Code',
    defaultMessage: 'Screens.Patients.Read.HealthPartnersList.HealthPartner.Code'
  },
  QuestionCount: {
    id: 'Screens.Patients.Read.HealthPartnersList.HealthPartner.QuestionCount',
    defaultMessage: 'Screens.Patients.Read.HealthPartnersList.HealthPartner.QuestionCount'
  },
  SubscriptionDate: {
    id: 'Screens.Patients.Read.HealthPartnersList.HealthPartner.SubscriptionDate',
    defaultMessage: 'Screens.Patients.Read.HealthPartnersList.HealthPartner.SubscriptionDate'
  },
  ProductExpired: {
    id: 'Screens.Patients.Read.HealthPartnersList.HealthPartner.ProductExpired',
    defaultMessage: 'Error.Screens.Patients.Read.HealthPartnersList.HealthPartner.ProductExpired'
  },
  ActionSuccess: {
    id: 'Message.Success',
    defaultMessage: 'Error.Message.Success',
  },
  ActionError: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
  },
  ReactivateSubscriptionButton: {
    id: 'Screens.Patients.Read.HealthPartnersList.HealthPartner.ReactivateSubscriptionButton',
    defaultMessage: 'Error.Screens.Patients.Read.HealthPartnersList.HealthPartner.ReactivateSubscriptionButton'
  },
  ReactivateSubscriptionSuccess: {
    id: 'Screens.Patients.Read.HealthPartnersList.HealthPartner.ReactivateSubscriptionSuccess',
    defaultMessage: 'Error.Screens.Patients.Read.HealthPartnersList.HealthPartner.ReactivateSubscriptionSuccess'
  },
  ReactivateSubscriptionError: {
    id: 'Screens.Patients.Read.HealthPartnersList.HealthPartner.ReactivateSubscriptionSuccess',
    defaultMessage: 'Error.Screens.Patients.Read.HealthPartnersList.HealthPartner.ReactivateSubscriptionError'
  }
});

export default messages;
