import { create } from 'zustand';
import { InsDocumentValidationStatus, User } from './types';
import { getIdentityValidationsPaged } from './services/getIdentityValidationsPaged';
import {
  PutDocumentStatusPayload,
  putDocumentStatus,
} from './services/putDocumentStatus';

type IdentityVerificationNhiStore = {
  isLoading: boolean;
  users: User[];
  searchStatus: InsDocumentValidationStatus;
  searchString: string;
  selectedPatientId: number;
  setSelectedPatientId: (patientId?: number) => void;
  setSearchStatus: (searchStatus: InsDocumentValidationStatus) => void;
  setSearchString: (searchString: string) => void;
  fetchUsers: () => void;
  updateDocumentStatus: (data: PutDocumentStatusPayload) => void;
};

const defaultStoreValues = {
  isLoading: true,
  users: [],
  selectedPatientId: undefined,
  searchStatus: InsDocumentValidationStatus.Pending,
  searchString: '',
};

export const useIdentityVerificationNhiStore =
  create<IdentityVerificationNhiStore>()((set, get) => ({
    ...defaultStoreValues,
    setSearchStatus: (searchStatus) =>
      set(() => ({
        searchStatus,
      })),
    setSearchString: (searchString) => {
      set(() => ({
        searchString,
      }));
    },
    setSelectedPatientId: (selectedPatientId) => {
      set(() => ({
        selectedPatientId,
      }));
    },
    fetchUsers: async () => {
      try {
        set(() => ({
          isLoading: true,
        }));
        const data = await getIdentityValidationsPaged({
          SearchString: get().searchString,
          DocumentStatusSelected: get().searchStatus,
        });
        set(() => ({
          users: data,
          isLoading: false,
        }));
      } catch (error) {
        console.error(error);
        set(() => ({
          isLoading: false,
        }));
      }
    },
    updateDocumentStatus: async (data) => {
      try {
        await putDocumentStatus(data);

        const usersList = get().users;

        const currentUserIndex = usersList.findIndex(
          (user) => user.patientId === get().selectedPatientId,
        );

        const nextUser = usersList[currentUserIndex + 1];
        if (nextUser && nextUser.patientId) {
          get().setSelectedPatientId(nextUser.patientId);
        } else {
          get().setSelectedPatientId();
        }

        get().fetchUsers();
      } catch (error) {
        console.error(error);
      }
    },
  }));
