import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useWindowSize } from 'react-use';
import { usePdf } from 'react-pdf-js';
import Spinner from '../Spinner';
import { ModalWrapper } from '../../themes/jupiter/elements';
import { Button, ButtonGroup, ModalBody } from 'reactstrap';
import {
  UilSearchPlus,
  UilSearchMinus,
  UilRedo,
  UilAngleLeft,
  UilAngleRight
} from '@iconscout/react-unicons';

const PDFModal = ({ options }) => {
  const intl = useIntl();
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(null);
  const [rotate, setRotate] = useState(0);
  const [scale, setScale] = useState(1.2);
  const canvasEl = useRef(null);
  const { height } = useWindowSize();
  const { formatMessage } = intl;

  const [loading, numPages] = usePdf({
    file: `${options.source}`,
    page,
    rotate,
    scale,
    canvasEl,
  });

  useEffect(() => {
    setPages(numPages);
  }, [numPages]);

  return (
    <ModalBody >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="overflow-auto">
            <ModalWrapper.PDFModal height={height * 0.6}>
              <canvas ref={canvasEl} />
            </ModalWrapper.PDFModal>
          </div>

          <ModalWrapper.Toolbar>
            <ButtonGroup>
              <Button
                aria-label={formatMessage({
                  id: 'Buttons.ZoomIn',
                  defaultMessage: 'Error.Buttons.ZoomIn',
                })}
                color='primary'
                onClick={() => setScale(scale + 0.3)}
                outline
              >
                <UilSearchPlus size="24" />
              </Button>
              <Button
                aria-label={formatMessage({
                  id: 'Buttons.ZoomOut',
                  defaultMessage: 'Error.Buttons.ZoomOut',
                })}
                color='primary'
                onClick={() => setScale(scale - 0.3)}
                outline
              >
                <UilSearchMinus size="24" />
              </Button>
              <Button
                aria-label={formatMessage({
                  id: 'Buttons.Rotate',
                  defaultMessage: 'Error.Buttons.Rotate',
                })}
                color='primary'
                onClick={() => setRotate(rotate + 90)}
                outline
              >
                <UilRedo size="24" />
              </Button>
            </ButtonGroup>
            {pages > 1 && (
              <div className='d-flex align-items-center'>
                <p className='mb-0 mr-2'>
                  Page: {page}/{pages}
                </p>
                <ButtonGroup>
                  <Button
                    aria-label={formatMessage({
                      id: 'Buttons.PreviousPage',
                      defaultMessage: 'Error.Buttons.PreviousPage',
                    })}
                    color='primary'
                    onClick={() => setPage(page - 1)}
                    disabled={page === 1}
                    outline
                  >
                    <UilAngleLeft size="24" />
                  </Button>
                  <Button
                    aria-label={formatMessage({
                      id: 'Buttons.NextPage',
                      defaultMessage: 'Error.Buttons.NextPage',
                    })}
                    color='primary'
                    onClick={() => setPage(page + 1)}
                    disabled={page === pages}
                    outline
                  >
                    <UilAngleRight size="24" />
                  </Button>
                </ButtonGroup>
              </div>
            )}
          </ModalWrapper.Toolbar>
        </>
      )}
    </ModalBody>
  );
};

PDFModal.propTypes = {
  options: PropTypes.shape({
    fileName: PropTypes.string,
    source: PropTypes.string,
  }).isRequired,
};

export default PDFModal;
