import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { LinksList, PageHeader, RoleManager, Spinner } from 'components';
import { service } from 'services';
import { api, sendToast, urlSchema } from 'utils';
import { ToastTypes } from '../../../constants';
import messages from './messages';

const List = () => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [organisations, setOrganisation] = useState(undefined);

  const getResults = (callback) => {
    setIsLoading(true);
    service
      .get(api.admin.getOrganizationConfigs)
      .then(({ data }) => {
        setOrganisation(data);
        setIsLoading(false);
        if (typeof callback === 'function') callback();
      })
      .catch(() => {
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.FailTitle} />,
          body: <FormattedMessage {...messages.FailBody} />,
        });
      });
  };

  useEffect(() => {
    getResults();
  }, []);

  return (
    <>
      <PageHeader>
        <>
          <FormattedMessage {...messages.Title} tagName='h1' />
          <RoleManager entity='OrganizationConfigs' right='CRUD'>
            <Link
              className='btn btn-primary btm-sm'
              to={urlSchema.createOrganizationConfigs}
            >
              <FormattedMessage {...messages.ButtonsCreate} />
            </Link>
          </RoleManager>
        </>
      </PageHeader>
      <section className='section'>
        <div className='section__body'>
          <header className='research-table__header'>
            <Container fluid>
              <Row>
                <Col md='4'>
                  <FormattedMessage
                    tagName='h5'
                    {...messages.OrganizationConfigsCode}
                  />
                </Col>
                <Col md='3' lg='2'>
                  <FormattedMessage
                    tagName='h5'
                    {...messages.OrganizationConfigsProfils}
                  />
                </Col>
                <Col lg='2' className='d-none d-md-none d-lg-block'>
                  <FormattedMessage
                    tagName='h5'
                    {...messages.OrganizationConfigsSpecialty}
                  />
                </Col>
              </Row>
            </Container>
          </header>
          {isLoading ? (
            <Spinner size='lg' />
          ) : (
            <ul className='research-table__results'>
              {organisations.map((item) => {
                return (
                  <li key={item.idOrganizationConfig}>
                    <Container fluid>
                      <Row className='align-items-center'>
                        <Col md='4'>
                          <h5 className='mb-0'>{item.code}</h5>
                          <p className='m-0 small text-secondary'>
                            {item.label}
                          </p>
                        </Col>
                        <Col md='3' lg='2'>
                          {item.profiles.length <= 0 ? (
                            <FormattedMessage
                              id='Shared.NoElement'
                              defaultMessage='Error.Shared.NoElement'
                            />
                          ) : (
                            <Link
                              to={`${urlSchema.profiles.list}?refOrganizationConfig=${item.idOrganizationConfig}`}
                              className='btn btn-outline-primary btn-sm'
                            >
                              <FormattedMessage
                                {...messages.ButtonReadProfiles}
                                values={{
                                  profilesLength: item.profiles.length,
                                }}
                              />
                            </Link>
                          )}
                        </Col>
                        <Col lg='2' className='d-none d-md-none d-lg-block'>
                          {item.specialties.length <= 0 ? (
                            <FormattedMessage
                              id='Shared.NoElement'
                              defaultMessage='Error.Shared.NoElement'
                            />
                          ) : (
                            <LinksList
                              color='warning'
                              data={item.specialties}
                              idKey={(el) => el.id}
                              labelKey={(el) => el.label}
                              limit='3'
                              urlBase={urlSchema.specialties.read}
                            />
                          )}
                        </Col>
                        <Col className='ml-auto'>
                          <div className='research-table__actions'>
                            <RoleManager entity='Universes' right='Reader'>
                              <Link
                                to={`${urlSchema.universes.list}?refOrganizationConfig=${item.idOrganizationConfig}`}
                                className='btn btn-outline-primary btn-sm'
                              >
                                <FormattedMessage
                                  {...messages.ButtonReadOrganizationConfigs}
                                />
                              </Link>
                            </RoleManager>
                            <RoleManager
                              entity='OrganizationConfigs'
                              right='Reader'
                            >
                              <Link
                                aria-label={intl.formatMessage({
                                  ...messages.ButtonRead,
                                })}
                                title={intl.formatMessage({
                                  ...messages.ButtonRead,
                                })}
                                to={`${urlSchema.detailsOrganizationConfigs}/${item.idOrganizationConfig}`}
                              >
                                <i
                                  aria-hidden
                                  className='uil uil-eye uil--md'
                                />
                              </Link>
                            </RoleManager>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </section>
    </>
  );
};

export default List;
