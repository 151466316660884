import { defineMessages } from 'react-intl';

export default defineMessages({
  Title: {
    id: 'Screens.Vouchers.Read.Title',
    defaultMessage: 'Error.Screens.Vouchers.Read.Title',
    description: 'Details voucher page title',
  },
  ButtonsBack: {
    id: 'Buttons.Back',
    defaultMessage: 'Error.Buttons.Back',
  },
  ButtonEdit: {
    id: 'Buttons.Edit',
    defaultMessage: 'Error.Buttons.Edit',
  },
  Code: {
    id: 'Vouchers.Code',
    defaultMessage: 'Error.Vouchers.Code',
  },
  VoucherGroupName: {
    id: 'Vouchers.VoucherGroupName',
    defaultMessage: 'Error.Vouchers.VoucherGroupName',
  },
  VoucherGroupDescription: {
    id: 'Vouchers.VoucherGroupDescription',
    defaultMessage: 'Error.Vouchers.VoucherGroupDescription',
  },
  RelatedProduct: {
    id: 'Vouchers.RelatedProduct',
    defaultMessage: 'Error.Vouchers.RelatedProduct',
  },
  VoucherUsed: {
    id: 'Vouchers.VoucherUsed',
    defaultMessage: 'Error.Vouchers.VoucherUsed',
  },
  VoucherDateExpiration: {
    id: 'Vouchers.VoucherDateExpiration',
    defaultMessage: 'Error.Vouchers.VoucherDateExpiration',
  },
  VoucherDateCreation: {
    id: 'Vouchers.VoucherDateCreation',
    defaultMessage: 'Error.Vouchers.VoucherDateCreation',
  },
  CreationDate: {
    id: 'Model.CreationDate',
    defaultMessage: 'Error.Model.CreationDate',
  },
  ExpirationDate: {
    id: 'Model.ExpirationDate',
    defaultMessage: 'Error.Model.ExpirationDate',
  },
  Valid: {
    id: 'Shared.Valid',
    defaultMessage: 'Error.Shared.Valid',
  },
  Invalid: {
    id: 'Shared.Invalid',
    defaultMessage: 'Error.Shared.Invalid',
  },
});
