import { defineMessages } from 'react-intl';

import { generateTranslationsObject } from 'utils';

const prefix = 'Model.PartnerOperator.';
const keys = ['CodeCompany', 'Email', 'FirstName', 'LastName', 'Valid'];

export default defineMessages(
  generateTranslationsObject(prefix, keys, {
    FieldRequired: {
      id: 'Shared.FieldRequired',
      defaultMessage: 'Error.Shared.FieldRequired',
    },
  }),
);
