import * as yup from 'yup';
import ChannelCode from 'constants/ChannelCode';
import messages from './messages';

function isPhoneOrVisio(value) {
  return value === ChannelCode.PHONE || value === ChannelCode.VISIO;
}

function createLanguagesForm(languages) {
  const langs = {};
  languages.forEach((lang) => {
    langs[lang.code] = yup.object().shape({
      name: yup.string().required('Model.FieldRequired'),
      description: yup.string().required('Model.FieldRequired'),
      cta: yup.string().required('Model.FieldRequired'),
    });
  });
  return langs;
}

const step1Shape = {
  logoFile: yup.string().when('refLogo', {
    is: undefined,
    then: yup.string().required('Components.FormInputLogo.Required'),
    otherwise: yup.string().notRequired(),
  }),
  refLogo: yup.string().notRequired(),
  code: yup
    .string()
    .required('Model.FieldRequired')
    .matches(/^[A-Z]+(?:_[A-Z]+)*$/, 'Offers.Create.CodeRegexError')
    .max(50, 'Offers.Create.CodeMaxLength'),
  refSpecialty: yup.string().required('Model.FieldRequired'),
  isValid: yup.bool(),
  options: yup.object().shape({
    isMedicalControlService: yup.bool(),
  }),
};

const getValidationSchema = (currentStep, intl, validLanguages) => {
  const step1 = yup.object().shape({
    ...step1Shape,
    ...createLanguagesForm(validLanguages),
  });

  const step2 = yup.object().shape({
    rooms: yup
      .array()
      .of(
        yup.object({
          channel: yup.string().required('Model.FieldRequired'),
          dutySlotDuration: yup.number().required('Model.FieldRequired'),
          minBookingNotice: yup.number().when('channel', {
            is: (val) => isPhoneOrVisio(val),
            then: yup.number().required('Model.FieldRequired'),
            otherwise: yup.number().nullable(),
          }),
          consultationDuration: yup.number().when('channel', {
            is: (val) => isPhoneOrVisio(val),
            then: yup.number().required('Model.FieldRequired'),
            otherwise: yup.number().nullable(),
          }),
          futureLimit: yup.number().when('channel', {
            is: (val) => isPhoneOrVisio(val),
            then: yup.number().required('Model.FieldRequired'),
            otherwise: yup.number().nullable(),
          }),
        }),
      )
      .required(intl.formatMessage(messages.RoomsRequired)),
  });

  return [step1, step2][currentStep];
};

export default getValidationSchema;
