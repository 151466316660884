import { service } from '../../../services';
import { api } from '../../../utils';
import { InsDocumentValidationStatus, User } from '../types';

type PagedParameters = {
  PageNumber?: number;
  Limit?: number;
  DocumentStatusSelected?: InsDocumentValidationStatus;
  DocumentStatus?: number[];
  SearchString?: string;
  SortOrder?: string;
};

interface ErrorResponse {
  succeeded: false;
  errorSet: {
    stacktrace: string;
    code: string;
    description: string;
    innerMessage: null;
  }[];
}

export const getIdentityValidationsPaged = async (
  parameters: PagedParameters,
) => {
  try {
    const { data } = await service.get<ErrorResponse | User[]>(
      api.identityValidations.getPages,
      {
        params: parameters,
      },
    );

    if (data && 'succeeded' in data && data.succeeded === false) {
      const errorResponse = data as ErrorResponse;
      throw new Error(errorResponse.errorSet[0].code);
    }

    return data as User[];
  } catch (error) {
    throw new Error(error.message);
  }
};
