import React from 'react';
import { Field } from 'formik';
import { useIntl } from 'react-intl';

import { Checkbox, FormInputFeedback, FormInput } from 'components';
import messages from './messages';

const WebService = ({ method, children, values, ...formikProps }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <FormInput
        label={formatMessage({ ...messages.Name })}
        name='name'
        required
        type='text'
      />
      <FormInput
        label={formatMessage({ ...messages.ClassName })}
        name='className'
        required
        type='text'
      />
      {children}
      <div>
        <Field name='isValid'>
          {({ field }) => (
            <Checkbox {...field} checked={values.isValid} id='isValid'>
              {formatMessage({ ...messages.Valid })}
            </Checkbox>
          )}
        </Field>
        <FormInputFeedback name='isValid' />
      </div>
    </>
  );
};

export default WebService;
