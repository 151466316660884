import React from 'react';
import PropTypes from 'prop-types';

import SliderArrowWrapper from './elements';

const SliderArrow = ({ className, isPrev, isNext, onClick }) => {
  return (
    <SliderArrowWrapper
      className={className}
      disabled={className.includes('disabled')}
      isPrev={isPrev}
      isNext={isNext}
      onClick={onClick}
      type='button'
    >
      <i
        aria-hidden
        className={`uil uil-angle-${isNext ? 'right' : 'left'}-b`}
      />
    </SliderArrowWrapper>
  );
};

SliderArrow.defaultProps = {
  className: undefined,
  isPrev: false,
  isNext: false,
  onClick: undefined,
};

SliderArrow.propTypes = {
  className: PropTypes.string,
  isPrev: PropTypes.bool,
  isNext: PropTypes.bool,
  onClick: PropTypes.func,
};

export default SliderArrow;
