import styled, { css } from 'styled-components';

const Title = styled.span`
  ${() => css`
    display: block;
  `}
`;

const FormTileRadioButtonWrapper = styled.div`
  ${({ theme, isActive }) => css`
    margin-bottom: ${theme.space(1)};

    svg {
      height: 32px;
    }

    label {
      align-items: center;
      border-radius: ${theme.borderRadius};
      border: 1px solid ${isActive ? theme.blue : theme.color('border')};
      cursor: pointer;
      display: flex;
      margin: 0;
      max-width: 100%;
      min-height: 80px;
      padding-bottom: ${theme.space(2)};
      padding-top: ${theme.space(2)};
      position: relative;
      transition: ${theme.transitionBase};

      &:hover,
      &:focus {
        border-color: ${theme.blue};
      }
    }

    ${Title} {
      color: ${isActive && theme.blue};
    }
  `};
`;

const Description = styled.small`
  ${({ theme }) => css`
    &&& {
      color: ${theme.blue};
    }
  `}
`;

const iconCheckedDimensions = '24px';
const CheckedIconWrapper = styled.i`
  ${({ theme }) => css`
    &&& {
      line-height: ${iconCheckedDimensions} !important;
    }

    background-color: ${theme.blue};
    border-radius: 100%;
    color: ${theme.white};
    position: absolute;
    right: ${theme.space(3)};
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    width: ${iconCheckedDimensions};
  `}
`;

const IconWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space(3)};
  `}
`;

const ContentWrapper = styled.div`
  ${() => css`
    padding-right: 56px;
  `}
`;

FormTileRadioButtonWrapper.Description = Description;
FormTileRadioButtonWrapper.Title = Title;

export {
  FormTileRadioButtonWrapper,
  CheckedIconWrapper,
  IconWrapper,
  ContentWrapper,
};
