const regularExpressions = {
  minCharacters: {
    regularExpression: /^(?=.{8,30}$)/,
    translationKey: 'Artifact.Auth.Input.NewPasswordHelperText1',
  },
  number: {
    regularExpression: /.*[0-9].*/,
    translationKey: 'Artifact.Auth.Input.NewPasswordHelperText4',
  },
  lower: {
    regularExpression: /[a-z]+/,
    translationKey: 'Artifact.Auth.Input.NewPasswordHelperText3',
  },
  upper: {
    regularExpression: /[A-Z]+/,
    translationKey: 'Artifact.Auth.Input.NewPasswordHelperText2',
  },
};

const allRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,30}$/;

export { allRules, regularExpressions };
