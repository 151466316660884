import React from 'react';
import { Switch } from 'react-router-dom';

import { ProtectedRoute } from 'components';
import { urlSchema } from 'utils';

// Screens
import Create from './Create';
import Update from './Update';
import Read from './Read';
import List from './List';

const routes = [
  { path: urlSchema.universes.create, component: Create, right: 'CRUD' },
  {
    path: `${urlSchema.universes.read}/:universeId`,
    component: Read,
    right: 'Reader',
  },
  {
    path: `${urlSchema.universes.update}/:universeId`,
    component: Update,
    right: 'CRUD',
  },
  { path: '/', component: List, right: 'Reader' },
];

const Universes = () => {
  return (
    <Switch>
      {routes.map((item) => (
        <ProtectedRoute entity='Universes' key={item.path} {...item} />
      ))}
    </Switch>
  );
};

export default Universes;
