import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Field, FieldArray, Form, Formik } from 'formik';
import { FormGroup, Input } from 'reactstrap';
import {
  Button,
  Checkbox,
  FormInput,
  FormInputFeedback,
  FormIntlTelInput,
  FormLabel,
  Spinner,
} from 'components';

import { ButtonsWrapper } from 'themes/jupiter/elements';

import { api, useFetch } from 'utils';
import { Methods } from '../../constants';
import messages from './messages';
import partnerShape from './shape';
import validationSchema from './validationSchema';

const Partner = ({ initialValues, isLoading, method, onSubmit }) => {
  const intl = useIntl();
  const [clients, areClientsLoading] = useFetch(api.clients.getListItems);

  if (isLoading || areClientsLoading)
    return <Spinner color='primary' size='lg' />;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <FormInput
            id='name'
            label={intl.formatMessage({ ...messages.Name })}
            name='name'
            required
            type='text'
          />
          <FormGroup>
            <FormLabel for='ref-client'>
              {intl.formatMessage({ ...messages.Client })}
            </FormLabel>
            <Field name='refClient'>
              {({ field }) => (
                <Input {...field} id='ref-client' type='select'>
                  <option value=''>-</option>
                  {clients.map((client) => (
                    <option key={client.id} value={client.id}>
                      {client.label}
                    </option>
                  ))}
                </Input>
              )}
            </Field>
            <FormInputFeedback name='refClient' />
          </FormGroup>
          <FieldArray name='eligiblePartnersResources'>
            {({ move, swap, push, insert, unshift, pop, form }) => (
              <>
                <FormInput
                  id='eligibility-key'
                  label={intl.formatMessage({ ...messages.EligibilityKey })}
                  name='eligiblePartnersResources[0].eligibilityKey'
                  type='text'
                />
                <FormInput
                  id='eligibility-description'
                  label={intl.formatMessage({
                    ...messages.EligibilityKeyDescription,
                  })}
                  name='eligiblePartnersResources[0].eligibilityKeyDescription'
                  type='textarea'
                />
              </>
            )}
          </FieldArray>
          <FormInput
            extraLabel={intl.formatMessage({
              ...messages.KeywordsDescription,
            })}
            id='keywords'
            label={intl.formatMessage({ ...messages.Keywords })}
            name='keywords'
            placeholder={intl.formatMessage({
              ...messages.KeywordsPlaceholder,
            })}
            type='text'
          />
          <FormIntlTelInput
            id='phone-number'
            labelId='Partners.PhoneNumber'
            locale={intl.locale}
            name='partnerPhoneNumber'
          />
          <div className='mb-3'>
            <Field name='isEligible'>
              {({ field }) => (
                <Checkbox
                  {...field}
                  checked={values.isEligible}
                  id='is-eligible'
                >
                  {intl.formatMessage({ ...messages.IsEligible })}
                </Checkbox>
              )}
            </Field>
            <FormInputFeedback name='isEligible' />
          </div>
          <ButtonsWrapper bottom>
            <Button
              color='primary'
              data-tracking={
                method === Methods.POST
                  ? 'PartnerCreateSubmit'
                  : 'PartnerUpdateSubmit'
              }
              isProcessing={isSubmitting}
              label={method === Methods.POST ? 'Create' : 'Update'}
              size='lg'
            />
          </ButtonsWrapper>
        </Form>
      )}
    </Formik>
  );
};

Partner.defaultProps = {
  isLoading: false,
};

Partner.propTypes = {
  initialValues: PropTypes.shape(partnerShape).isRequired,
  isLoading: PropTypes.bool,
  method: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Partner;
