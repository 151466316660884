import * as yup from 'yup';

import validationSchema from 'models/Patient/validationSchema';
import messages from './messages';

const getValidationSchema = (formatMessage) => {
  const formattedFieldRequired = formatMessage(messages.FieldRequired);

  const eligibility = yup.object().shape({
    userCode: yup.string('Model.FieldRequired').required('Model.FieldRequired'),
    firstname: yup.string().required('Model.FieldRequired'),
    lastname: yup.string().required('Model.FieldRequired'),
    birthdate: yup.string().required('Model.FieldRequired'),
  });

  const information = yup.object().shape({
    ...validationSchema(formatMessage),
    infosPatient: yup.object().shape({
      operatorInscriptionConsent: yup
        .boolean()
        .oneOf([true], formattedFieldRequired)
        .required(formattedFieldRequired),
      newsletter: yup.boolean().required('Model.FieldRequired'),
      optin: yup.boolean().required('Model.FieldRequired'),
    }),
    refProduct: yup.string().required('Model.FieldRequired'),
  });

  return [eligibility, information];
};

export default getValidationSchema;
