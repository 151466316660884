import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext, useField } from 'formik';
import { useIntl } from 'react-intl';
import { useTimeout } from 'react-use';
import { Spinner } from 'reactstrap';

import DragAndDrop from './DragAndDrop';
import FormInputFeedback from '../FormInputFeedback';
import FormInputLogoWrapper from './elements';
import { Settings } from '../../../constants';
import messages from './messages';

const FormInputLogo = ({ setFormLogoData, defaultImage, name, ...props }) => {
  const [, meta, helpers] = useField({ type: 'text', name });
  const { values, ...formikProps } = useFormikContext();
  const intl = useIntl();
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isReady, , reset] = useTimeout(500);

  const setFile = (file) => {
    const newFormData = new FormData();
    newFormData.append(file.name, file);
    reset();
    setUploadedImage(file);
    setFormLogoData(newFormData);
    helpers.setValue(file);
  };

  const addFile = (e) => {
    if (e.currentTarget.files[0].size < Settings.MAX_FILE_SIZE) {
      setFile(e.currentTarget.files[0]);
    } else {
      helpers.setError('Components.FormInputLogo.ErrorFileTooBig');
    }
  };

  const addFileFromDrop = (files) => {
    if (files.length === 1) {
      if (files[0].type.match('^image/')) {
        if (files[0].size < Settings.MAX_FILE_SIZE) {
          setFile(files[0]);
        } else {
          helpers.setError('Components.FormInputLogo.ErrorFileTooBig');
        }
      } else {
        helpers.setError('Components.FormInputLogo.ErrorFormat');
      }
    } else {
      helpers.setError('Components.FormInputLogo.ErrorSingle');
    }
  };

  return (
    <FormInputLogoWrapper>
      {!defaultImage && !uploadedImage && (
        <DragAndDrop onFile={addFileFromDrop} />
      )}
      {(defaultImage || uploadedImage) && (
        <FormInputLogoWrapper.ImageWrapper>
          {!isReady() && (
            <FormInputLogoWrapper.Loader>
              <Spinner color='primary' />
            </FormInputLogoWrapper.Loader>
          )}
          {isReady() && defaultImage && !uploadedImage && (
            <img
              src={`data:${defaultImage.contentType};base64, ${defaultImage.rawFile}`}
              alt='Logo'
            />
          )}
          {isReady() && uploadedImage && (
            <>
              <img src={URL.createObjectURL(uploadedImage)} alt='Logo' />
              <button
                aria-label={intl.formatMessage({
                  ...messages.ButtonDelete,
                })}
                data-tracking='BtnLogoFileDelete'
                type='button'
                onClick={() => {
                  reset();
                  setUploadedImage(undefined);
                  helpers.setValue('');
                }}
              >
                <i aria-hidden className='uil uil-times uil--sm' />
              </button>
            </>
          )}
        </FormInputLogoWrapper.ImageWrapper>
      )}
      <FormInputLogoWrapper.InputWrapper>
        <label
          className='btn btn-primary'
          data-tracking='BtnLogoFileUpload'
          htmlFor='logoFile'
        >
          {intl.formatMessage({ ...messages.ButtonUpload })}
        </label>
        <input
          accept='image/png, image/jpeg'
          className='hidden-input'
          id='logoFile'
          onChange={(e) => {
            addFile(e);
            e.currentTarget.value = null;
          }}
          type='file'
        />
        {formikProps.touched.refLogo && meta.error && (
          <FormInputFeedback msg={meta.error} />
        )}
      </FormInputLogoWrapper.InputWrapper>
    </FormInputLogoWrapper>
  );
};

FormInputLogo.defaultProps = {
  defaultImage: undefined,
  isSubmitted: false,
};

FormInputLogo.propTypes = {
  defaultImage: PropTypes.shape({
    contentType: PropTypes.string,
    rawFile: PropTypes.string,
  }),
  setFormLogoData: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default FormInputLogo;
