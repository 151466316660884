import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedDate as RIFormattedDate } from 'react-intl';

import { service } from 'services';
import { Button, FormattedDate, RoleManager } from 'components';
import { api, urlSchema, sendToast } from 'utils';
import { ToastTypes } from '../../../../constants';
import InformationWrapper from './elements';

import messages from './messages';

const patientData = (data) => [
  { title: messages.Email, value: data.email },
  { title: messages.Mobile, value: data.mobileNumber },
  {
    title: messages.Birthdate,
    value: <RIFormattedDate value={data.birthdate} />,
  },
  {
    title: messages.NotificationPreference,
    value: data.notification,
  },
];

const PatientInformation = ({ patient, refresh, logs }) => {
  const lastLogin =
    logs
      .sort((logA, logB) => logA.createdAt > logB.createdAt)
      .find((log) => log.code === 'USER_CONNEXION') || null;

  const sendNewPasswordEmail = () => {
    service
      .put(api.manager.forgotPassword(patient.idUser))
      .then(() => {
        sendToast(ToastTypes.success, {
          header: <FormattedMessage {...messages.ActionSuccess} />,
          body: <FormattedMessage {...messages.NewPasswordSuccess} />,
        });
      })
      .catch((err) => {
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.ActionError} />,
          body: <FormattedMessage {...messages.NewPasswordError} />,
        });
      });
  };
  const switchIdentityValidation = (valid) => {
    if (valid) {
      service
        .put(api.manager.validateUser(patient.idUser))
        .then((data) => {
          refresh(Math.random());
          sendToast(ToastTypes.success, {
            header: <FormattedMessage {...messages.ActionSuccess} />,
            body: (
              <FormattedMessage
                {...messages.ValidIdentitySuccess}
                values={{ name: `${patient.firstname} ${patient.lastname}` }}
              />
            ),
          });
        })
        .catch((err) => {
          sendToast(ToastTypes.error, {
            header: <FormattedMessage {...messages.ActionError} />,
            body: (
              <FormattedMessage
                {...messages.ValidIdentityError}
                values={{ name: `${patient.firstname} ${patient.lastname}` }}
              />
            ),
          });
        });
    } else {
      service
        .put(api.manager.unvalidateUser(patient.idUser))
        .then((data) => {
          refresh(Math.random());
          sendToast(ToastTypes.success, {
            header: <FormattedMessage {...messages.ActionSuccess} />,
            body: (
              <FormattedMessage
                {...messages.InvalidIdentitySuccess}
                values={{ name: `${patient.firstname} ${patient.lastname}` }}
              />
            ),
          });
        })
        .catch((err) => {
          sendToast(ToastTypes.error, {
            header: <FormattedMessage {...messages.ActionError} />,
            body: (
              <FormattedMessage
                {...messages.ValidIdentityError}
                values={{ name: `${patient.firstname} ${patient.lastname}` }}
              />
            ),
          });
        });
    }
  };

  return (
    <>
      {lastLogin && (
        <InformationWrapper.LastSeenText>
          <strong>
            <FormattedMessage {...messages.LastSeen} />
          </strong>{' '}
          <FormattedDate value={lastLogin.createdAt} />
        </InformationWrapper.LastSeenText>
      )}
      <InformationWrapper.StatusWrapper>
        <>
          {patient.valid ? (
            <Badge size='xl' color='primary'>
              <FormattedMessage
                id='Shared.Valid'
                defaultMessage='Error.Shared.Valid'
              />
            </Badge>
          ) : (
            <Badge size='xl' color='danger'>
              <FormattedMessage
                id='Shared.Invalid'
                defaultMessage='Error.Shared.Invalid'
              />
            </Badge>
          )}
          {patient.infosPatient.isIdentityValidationApproved ? (
            <Badge color='primary'>
              <FormattedMessage {...messages.IdentityValidBadge} />
            </Badge>
          ) : (
            <Badge color='danger'>
              <FormattedMessage {...messages.IdentityInvalidBadge} />
            </Badge>
          )}
        </>
      </InformationWrapper.StatusWrapper>
      <InformationWrapper.ButtonsWrapper>
        <Button
          onClick={() => sendNewPasswordEmail()}
          outline
          size='sm'
          color='primary'
          label='GeneratePassword'
        />
        {patient.infosPatient.isIdentityValidationApproved ? (
          <Button
            onClick={() => switchIdentityValidation(false)}
            outline
            size='sm'
            color='danger'
            label='InvalidateIdentity'
          />
        ) : (
          <Button
            onClick={() => switchIdentityValidation(true)}
            outline
            size='sm'
            color='primary'
            label='ValidateIdentity'
          />
        )}
        <RoleManager entity='Patients' right='CRUD'>
          <Link
            className='btn btn-outline-danger btn-sm'
            to={`${urlSchema.patients.delete}/${patient.idUser}`}
          >
            <FormattedMessage {...messages.ButtonDeleteAccount} />
          </Link>
        </RoleManager>
      </InformationWrapper.ButtonsWrapper>
      <Row>
        <Col md='6'>
          <InformationWrapper.SectionTitle>
            <FormattedMessage {...messages.UserInformation} />
          </InformationWrapper.SectionTitle>
          {patientData(patient).map((el) => (
            <div className='model__property'>
              <h5 className='model__title'>
                <FormattedMessage {...el.title} />
              </h5>
              <p className='model__value'>{el.value}</p>
            </div>
          ))}
          {patient.address1 && (
            <div className='model__property'>
              <h5 className='model__title'>
                <FormattedMessage {...messages.Address} />
              </h5>
              <p className='model__value'>
                {patient.address1}, {patient.zipCode}, {patient.city}
              </p>
            </div>
          )}
          {patient.address2 && (
            <div className='model__property'>
              <h5 className='model__title'>
                <FormattedMessage {...messages.AdditionalAddress} />
              </h5>
              <p className='model__value'>{patient.address2} </p>
            </div>
          )}
        </Col>
        <Col md='6'>
          <InformationWrapper.SectionTitle>
            <FormattedMessage {...messages.AccountInformation} />
          </InformationWrapper.SectionTitle>
          <div className='model__property'>
            <h5 className='model__title'>
              <FormattedMessage {...messages.AccountCreationDate} />
            </h5>
            <p className='model__value'>
              <FormattedDate origin='fr' value={patient.dateCreation} />
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};

PatientInformation.propTypes = {
  patient: PropTypes.shape().isRequired,
  refresh: PropTypes.func.isRequired,
};

export default PatientInformation;
