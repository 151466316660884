import * as yup from 'yup';
import { Helpers } from '../../constants';

export default yup.object().shape({
  keywords: yup.lazy((value) => {
    return !value
      ? yup.string().nullable()
      : yup
          .string()
          .matches(Helpers.COMMA_REGEX, 'Partners.KeywordsWrongFormat');
  }),
  name: yup.string().required('Partners.NameRequired'),
  partnerPhoneNumber: yup.lazy((value) => {
    return !value
      ? yup.string().nullable()
      : yup
          .string()
          .matches(Helpers.PHONE_REGEX, 'Form.FormIntlTelInput.Invalid');
  })
});
