export default {
  eligiblePartnersResources: [
    {
      codeLanguage: 'fr',
      eligibilityKey: '',
      eligibilityKeyDescription: '',
    },
  ],
  isEligible: false,
  keywords: '',
  name: '',
  refClient: '',
  partnerPhoneNumber: '',
  urlPartner: '',
};
