import { defineMessages } from 'react-intl';

export default defineMessages({
  Title: {
    id: 'Specialties.Update.Title',
    defaultMessage: 'Error.Specialties.Update.Title',
  },
  ToastSuccess: {
    id: 'Shared.Toast.Update.Success',
    defaultMessage: 'Error.Shared.Toast.Update.Success',
  },
  ToastFail: {
    id: 'Shared.Toast.Update.Fail',
    defaultMessage: 'Error.Shared.Toast.Update.Fail',
  },
});
