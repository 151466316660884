import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
  Row,
  Col,
  Input,
  FormGroup,
  InputGroupAddon,
  InputGroup,
} from 'reactstrap';
import moment from 'moment';

import validationSchema from './validationSchema';
import { service } from 'services';
import { api, useFetch, sendToast } from 'utils';
import PropTypes from 'prop-types';
import {
  Button,
  FormBooleanCard,
  FormLabel,
  FormErrorMessage,
  FormInput,
  FormRadioButton,
  Spinner,
  Translation,
} from 'components';
import { FormLifenInput } from './FormLifenInput';
import { ErrorMessageContent, BooleanCardErrorWrapper } from './elements';
import initialValues from './initialValues';
import { ButtonsWrapper } from 'themes/jupiter/elements';
import { FamilyDoctor, ToastTypes } from '../../../../../constants';

const isNotEmptyOrNull = (value) => value === '' || value === null;
const UpdatePersonalInformations = ({ setPrerequisitesTrigger }) => {
  const { patientId } = useParams();
  const intl = useIntl();
  const { formatMessage } = intl;

  const [selectedPatientId, setSelectedPatientId] = useState(patientId);
  const [patientFile, setPatientFile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [familyMembers, isFamilyMembersLoading] = useFetch(
    api.admin.getFamilyMembers(patientId),
  );

  const isGuardianSelected =
    !isFamilyMembersLoading && familyMembers[0].idUser == selectedPatientId;

  const onSubmit = (values, { setSubmitting }) => {
    const parameters = {
      ...values,
      medicalHistory:
        values.hasMedicalHistory === 'true' ? values.medicalHistory : '',
      familyDoctorStatus:
        values.hasFamilyDoctor === 'false'
          ? FamilyDoctor.DECLARED_NOT_HAVING_ONE
          : FamilyDoctor.HAS_FAMILY_DOCTOR,
      familyHistory: values.familyHistory,
      additionalInformations:
        values.hasAdditionalInformations === 'true'
          ? values.additionalInformations
          : '',
      medicatedAllergies:
        values.hasMedicatedAllergies === 'true'
          ? values.medicatedAllergies
          : '',
      rpps: values.hasFamilyDoctor === 'true' ? values.rpps : '',
      smoking: values.isSmoking === 'true' ? values.smoking : 0,
    };

    // API only allows "null" or not having this key
    if (parameters.hasFamilyDoctor === 'false') delete parameters.rpps;

    service
      .put(
        api.dialogues.updatePatientFile(patientFile.idPatientFile),
        parameters,
      )
      .then(({ data }) => {
        setSubmitting(false);
        if (data.succeeded === false) {
          throw new Error(data.errorSet[0].code);
        }

        sendToast(ToastTypes.success, {
          header: formatMessage({
            id: 'Shared.Success',
            defaultMessage: 'Error.Shared.Success',
          }),
          body: formatMessage({
            id: 'Shared.Toast.Update.Success',
            defaultMessage: 'Error.Shared.Toast.Update.Success',
          }),
        });
        setPrerequisitesTrigger(Math.random());
        setSubmitting(false);
      })
      .catch((error) => {
        sendToast(ToastTypes.error, {
          header: formatMessage({
            id: 'Shared.Error',
            defaultMessage: 'Error.Shared.Error',
          }),
          body: formatMessage({
            id: 'Shared.Toast.Update.Fail',
            defaultMessage: 'Error.Shared.Toast.Update.Fail',
          }),
        });
        console.error(error);
        setSubmitting(false);
      });
  };

  const getPatientFile = async (patientId) => {
    try {
      setIsLoading(true);

      const response = await service.get(
        api.dialogues.getPatientFile(patientId),
      );

      if (
        response.status !== 200 ||
        typeof response.data.idPatientFile !== 'string'
      ) {
        throw new Error('Unexpected response status: ' + response.status);
      }

      setPatientFile(response.data);
    } catch (error) {
      sendToast(ToastTypes.error, {
        header: formatMessage({
          id: 'Error.Fetching.PatientFile',
          defaultMessage: 'Error.Error.Fetching.PatientFile',
        }),
        body: 'error while fetching patientFile: ' + error.message,
      });
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPatientFile(selectedPatientId);
  }, [selectedPatientId]);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={initialValues(patientFile)}
          {...{ onSubmit }}
          validationSchema={validationSchema(formatMessage)}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form>
              <Row>
                <Col md='12'>
                  {familyMembers.length > 1 && !isFamilyMembersLoading && (
                    <FormGroup>
                      <FormLabel>
                        <Translation id='Screens.Patients.List.Patient' />
                      </FormLabel>
                      <Field name='familyMembers' id='familyMembers'>
                        {({ field }) => (
                          <Input
                            {...field}
                            type='select'
                            onChange={(event) =>
                              setSelectedPatientId(event.target.value)
                            }
                          >
                            {familyMembers.map(
                              ({ idUser, firstname, lastname }) => (
                                <option
                                  key={idUser}
                                  value={idUser}
                                  selected={idUser === selectedPatientId}
                                >
                                  {firstname} {lastname}
                                </option>
                              ),
                            )}
                          </Input>
                        )}
                      </Field>
                    </FormGroup>
                  )}
                  <FormGroup>
                    <FormLabel>
                      <Translation id='Shared.Birthdate' />
                      <p className='mb-0 text-dark'>
                        {moment(patientFile.birthdate).format('DD-MM-YYYY')}
                      </p>
                    </FormLabel>
                  </FormGroup>
                  <FormGroup>
                    <FormLabel for='Gender' required>
                      <Translation id='Shared.Gender' />
                    </FormLabel>
                    <InputGroup>
                      <Field
                        name='sexe'
                        id='gender-male'
                        component={FormRadioButton}
                        inputValue='1'
                        label={formatMessage({ id: 'Shared.Man' })}
                        required
                      />
                      <Field
                        name='sexe'
                        id='gender-female'
                        className='ml-3'
                        component={FormRadioButton}
                        inputValue='2'
                        label={formatMessage({ id: 'Shared.Woman' })}
                        required
                      />
                    </InputGroup>
                    <FormErrorMessage name='sexe' />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md='6'>
                  <FormGroup>
                    <FormLabel for='height' required>
                      <Translation id='Shared.Height' />
                    </FormLabel>
                    <InputGroup>
                      <Input
                        tag={Field}
                        id='height'
                        name='height'
                        invalid={Boolean(touched.height && errors.height)}
                        onChange={(event) => {
                          setFieldValue(
                            'height',
                            event.target.value.replace(',', '.'),
                          );
                          setFieldTouched('height');
                        }}
                      />
                      <InputGroupAddon addonType='append'>cm</InputGroupAddon>
                      <FormErrorMessage name='height' />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md='6'>
                  <FormGroup>
                    <FormLabel for='weight' required>
                      <Translation id='Shared.Weight' />
                    </FormLabel>
                    <InputGroup>
                      <Input
                        tag={Field}
                        id='weight'
                        name='weight'
                        invalid={Boolean(touched.weight && errors.weight)}
                        onChange={(event) => {
                          setFieldValue(
                            'weight',
                            event.target.value.replace(',', '.'),
                          );
                          setFieldTouched('weight');
                        }}
                      />
                      <InputGroupAddon addonType='append'>kg</InputGroupAddon>
                      <FormErrorMessage name='weight' />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              {isGuardianSelected && (
                <Row>
                  <Col md='12'>
                    <FormGroup>
                      <FormInput
                        name='profession'
                        label={intl.formatMessage({
                          id: 'Screens.Dialogues.Create.UpdatePatientFile.ProfessionPlaceHolder',
                          defaultMessage:
                            'Error.Screens.Health.PatientFile.professionPlaceHolder',
                        })}
                        type='text'
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}

              <Row>
                <Col md='12'>
                  <FormBooleanCard
                    name='familyDoctor'
                    radioName='hasFamilyDoctor'
                    title={formatMessage({
                      id: 'Screens.Dialogues.Create.UpdatePatientFile.HasFamilyDoctor.Label',
                      defaultMessage:
                        'Error.Screens.Dialogues.Create.UpdatePatientFile.HasFamilyDoctorLabel.Label',
                    })}
                    placeholder={formatMessage({
                      id: 'Screens.Dialogues.Create.UpdatePatientFile.hasFamilyDoctorPlaceholder',
                      defaultMessage:
                        'Error.Screens.Dialogues.Create.UpdatePatientFile.FamilyDoctor.Placeholder',
                    })}
                    values={values}
                    required
                  >
                    <FormLifenInput name='familyDoctorAutocomplete' />
                  </FormBooleanCard>
                  {isNotEmptyOrNull(values.hasFamilyDoctor) && (
                    <BooleanCardErrorWrapper>
                      <ErrorMessage name='familyDoctor'>
                        {(message) => (
                          <ErrorMessageContent>{message}</ErrorMessageContent>
                        )}
                      </ErrorMessage>
                    </BooleanCardErrorWrapper>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md='12'>
                  <FormBooleanCard
                    title={formatMessage({
                      id: 'Screens.Dialogues.Create.UpdatePatientFile.isSmoking',
                      defaultMessage:
                        'Error.Screens.Dialogues.Create.UpdatePatientFile.isSmoking',
                    })}
                    name='smoking'
                    radioName='isSmoking'
                    values={values}
                    placeholder={formatMessage({
                      id: 'Screens.Dialogues.Create.UpdatePatientFile.isSmokingPlaceholder',
                      defaultMessage:
                        'Error.Screens.Dialogues.Create.UpdatePatientFile.isSmokingPlaceholder',
                    })}
                    inputType='number'
                    unit={formatMessage({
                      id: 'Screens.Dialogues.Create.UpdatePatientFile.isSmokingPlaceholderUnit',
                      defaultMessage:
                        'Error.Screens.Dialogues.Create.UpdatePatientFile.isSmokingPlaceholderUnit',
                    })}
                    required
                  />
                  {isNotEmptyOrNull(values.isSmoking) && (
                    <BooleanCardErrorWrapper>
                      <ErrorMessage name='smoking'>
                        {(message) => (
                          <ErrorMessageContent>{message}</ErrorMessageContent>
                        )}
                      </ErrorMessage>
                    </BooleanCardErrorWrapper>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md='12'>
                  <FormBooleanCard
                    title={formatMessage({
                      id: 'Screens.Dialogues.Create.UpdatePatientFile.hasAdditionalInformations',
                      defaultMessage:
                        'Screens.Screens.Dialogues.Create.UpdatePatientFile.hasAdditionalInformations',
                    })}
                    name='additionalInformations'
                    radioName='hasAdditionalInformations'
                    values={values}
                    placeholder={formatMessage({
                      id: 'Screens.Dialogues.Create.UpdatePatientFile.hasAdditionalInformationsPlaceholder',
                      defaultMessage:
                        'Error.Screens.Dialogues.Create.UpdatePatientFile.hasAdditionalInformationsPlaceholder',
                    })}
                    inputType='textarea'
                    required
                  />
                  {isNotEmptyOrNull(values.hasAdditionalInformations) && (
                    <BooleanCardErrorWrapper>
                      <ErrorMessage name='additionalInformations'>
                        {(message) => (
                          <ErrorMessageContent>{message}</ErrorMessageContent>
                        )}
                      </ErrorMessage>
                    </BooleanCardErrorWrapper>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md='12'>
                  <FormBooleanCard
                    title={formatMessage({
                      id: 'Screens.Dialogues.Create.UpdatePatientFile.hasMedicatedAllergies',
                      defaultMessage:
                        'Error.Screens.Dialogues.Create.UpdatePatientFile.hasMedicatedAllergies',
                    })}
                    name='medicatedAllergies'
                    radioName='hasMedicatedAllergies'
                    values={values}
                    placeholder={formatMessage({
                      id: 'Screens.Dialogues.Create.UpdatePatientFile.hasMedicatedAllergiesPlaceholder',
                      defaultMessage:
                        'Error.Screens.Dialogues.Create.UpdatePatientFile.hasMedicatedAllergiesPlaceholder',
                    })}
                    inputType='textarea'
                    required
                  />
                  {isNotEmptyOrNull(values.hasMedicatedAllergies) && (
                    <BooleanCardErrorWrapper>
                      <ErrorMessage name='medicatedAllergies'>
                        {(message) => (
                          <ErrorMessageContent>{message}</ErrorMessageContent>
                        )}
                      </ErrorMessage>
                    </BooleanCardErrorWrapper>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md='12'>
                  <FormBooleanCard
                    title={formatMessage({
                      id: 'Screens.Dialogues.Create.UpdatePatientFile.HasMedicalHistory',
                      defaultMessage:
                        'Error.Screens.Dialogues.Create.UpdatePatientFile.HasMedicalHistory',
                    })}
                    name='medicalHistory'
                    radioName='hasMedicalHistory'
                    values={values}
                    placeholder={formatMessage({
                      id: 'Screens.Dialogues.Create.UpdatePatientFile.HasMedicalHistoryPlaceholder',
                      defaultMessage:
                        'Error.Screens.Dialogues.Create.UpdatePatientFile.HasMedicalHistoryPlaceholder',
                    })}
                    inputType='textarea'
                    required
                  />
                  {isNotEmptyOrNull(values.hasMedicalHistory) && (
                    <BooleanCardErrorWrapper>
                      <ErrorMessage name='medicalHistory'>
                        {(message) => (
                          <ErrorMessageContent>{message}</ErrorMessageContent>
                        )}
                      </ErrorMessage>
                    </BooleanCardErrorWrapper>
                  )}
                </Col>
              </Row>
              {isGuardianSelected && (
                <Row>
                  <Col md='12'>
                    <FormInput
                      label={formatMessage({
                        id: 'Screens.Dialogues.Create.UpdatePatientFile.furtherInformation',
                        defaultMessage:
                          'Error.Screens.Dialogues.Create.UpdatePatientFile.furtherInformation',
                      })}
                      name='familyHistory'
                      id='familyHistory'
                      type='textarea'
                      placeholder={formatMessage({
                        id: 'Screens.Dialogues.Create.UpdatePatientFile.furtherInformationPlaceholder',
                        defaultMessage:
                          'Error.Screens.Dialogues.Create.UpdatePatientFile.furtherInformationPlaceholder',
                      })}
                    />
                  </Col>
                </Row>
              )}
              <ButtonsWrapper bottom>
                <Button
                  type='submit'
                  color='primary'
                  label='Confirm'
                  disabled={isSubmitting}
                />
              </ButtonsWrapper>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

UpdatePersonalInformations.propTypes = {
  setPrerequisitesTrigger: PropTypes.func.isRequired,
};

export default UpdatePersonalInformations;
