import { defineMessages } from 'react-intl';

export default defineMessages({
  Title: {
    id: 'Screens.ProductTermResources.Read.Title',
    defaultMessage: 'Error.Screens.ProductTermResources.Read.Title',
    description: 'ProductTermResources Read page title',
  },
  ButtonsEdit: {
    id: 'Buttons.Edit',
    defaultMessage: 'Error.Buttons.Edit',
    description: 'Button to edit ProductTerms',
  },
  ProductTermsLanguage: {
    id: 'ProductTerms.Language',
    defaultMessage: 'Error.ProductTerms.Language',
  },
  ProductTermsCP: {
    id: 'ProductTerms.CP',
    defaultMessage: 'Error.ProductTerms.CP',
  },
});
