import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonsCreate: {
    id: 'Buttons.Create',
    defaultMessage: 'Error.Buttons.Create',
    description: 'Button to create a OrganizationConfigs',
  },
  ButtonRead: {
    id: 'Buttons.Read',
    defaultMessage: 'Error.Buttons.Read',
    description: 'Button to read a organization',
  },
  ButtonReadOrganizationConfigs: {
    id: 'Buttons.ReadOrganizationConfigs',
    defaultMessage: 'Error.Message.ReadOrganizationConfigs',
    description: 'Button to read a organization',
  },
  ButtonReadProfiles: {
    id: 'Buttons.ReadProfiles',
    defaultMessage: 'Error.Message.ReadProfiles',
    description: 'Button to read a profiles',
  },
  ButtonReadSpecialties: {
    id: 'Buttons.ReadSpecialties',
    defaultMessage: 'Error.Message.ReadSpecialties',
    description: 'Button to read a specialties',
  },
  OrganizationConfigsCode: {
    id: 'OrganizationConfigs.Code',
    defaultMessage: 'Error.OrganizationConfigs.Code',
    description: 'OrganizationConfigs page column title code',
  },
  OrganizationConfigsProfils: {
    id: 'OrganizationConfigs.Profils',
    defaultMessage: 'Error.OrganizationConfigs.Profils',
    description: 'OrganizationConfigs page column title profils',
  },
  OrganizationConfigsSpecialty: {
    id: 'OrganizationConfigs.Specialty',
    defaultMessage: 'Error.OrganizationConfigs.Specialty',
    description: 'OrganizationConfigs page column title specialty',
  },
  Title: {
    id: 'Screens.OrganizationConfigs.List.Title',
    defaultMessage: 'Error.Screens.OrganizationConfigs.List.Title',
    description: 'OrganizationConfigs page title',
  },
  ButtonReadModel: {
    id: 'Buttons.ReadModel',
    defaultMessage: 'Error.Message.ReadModel',
    description: 'Button to read a model',
  },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
    description: 'Toast title in case of error',
  },
  FailBody: {
    id: 'Message.Api.Error',
    defaultMessage: 'Error.Message.Api.Error',
    description: 'Toast body in case of error',
  },
});
