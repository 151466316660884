import React from 'react';

import { CsvValidator } from 'components/CsvValidator';

export const PartnerOperations = () => {
  return (
    <main className='mainContent max-height-100vh overflow-auto'>
      <div className='bg-white rounded px-4 mt-4 py-4'>
        <div className='pb-4'>
          Vous pouvez déposer ici un fichier contenant les informations
          d’inscription de vos utilisateurs. <br />
          Une fois le fichier déposé, puis intégré par nos équipes, les
          utilisateurs seront reconnus éligibles.
        </div>
        <h6 className='text-color-212529 fw-bold'>Joindre un fichier .csv</h6>
        <p>
          Les fichiers doivent contenir les en-têtes suivantes :
          PARTENAIRE;ENTITE;NUMERO ADHERENT;NOM;PRENOM;DATE DE
          NAISSANCE;ProductTag.
        </p>
        <CsvValidator />
      </div>
    </main>
  );
};
