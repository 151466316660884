import styled, { css } from 'styled-components';

const OffersListWrapper = styled.div`
  ${({ theme }) => css`
    > a {
      margin-left: 0;
    }
  `};
`;

OffersListWrapper.List = styled.ul`
  ${({ theme }) => css`
    border: 1px solid ${theme.color('border')};
    border-radius: ${theme.borderRadiusLg};
    list-style-type: none;
    margin: 0;
    padding: 0;
  `}
`;

const OfferItemWrapper = styled.li`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.color('border')};
    padding-bottom: ${theme.space(3)};
    padding-top: ${theme.space(3)};

    &:last-child {
      border-bottom: 0;
    }

    .row {
      align-items: center;
    }
  `}
`;

const Actions = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    justify-content: space-around;

    a {
      color: ${theme.color('gray-font')};
      text-decoration: none;

      &:hover,
      &:active {
        color: ${theme.blue};
      }
    }
  `}
`;

const IllustratedList = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    margin-bottom: ${theme.space(1)};

    &:last-of-type {
      margin-bottom: 0;
    }

    & > *:not(i) {
      flex: 1 auto;
      max-width: 100%;
    }

    i {
      margin-right: ${theme.space(3)};
    }
  `}
`;

OfferItemWrapper.Actions = Actions;
OfferItemWrapper.IllustratedList = IllustratedList;

export { OffersListWrapper, OfferItemWrapper };
