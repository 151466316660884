import React from 'react';
import { useIntl } from 'react-intl';
import { Spinner } from 'reactstrap';

import { FormList } from 'components';
import { api, useFetch } from 'utils';
import messages from '../messages';

const Profiles = () => {
  const [profiles, areProfilesLoading] = useFetch(api.profiles.get);
  const { formatMessage } = useIntl();

  if (areProfilesLoading) return <Spinner color='primary' size='lg' />;

  return (
    <FormList
      data={profiles}
      itemLabelKey='label'
      itemValueKey='idProfile'
      researchOptions={{
        isEnabled: true,
        placeholder: formatMessage({ ...messages.ProfilesPlaceholder }),
      }}
      name='selectedProfiles'
    />
  );
};

export default Profiles;
