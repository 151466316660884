import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonEdit: { id: 'Buttons.Edit', defaultMessage: 'Error.Buttons.Edit' },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
    description: 'Toast title in case of Error',
  },
  FailBody: {
    id: 'Message.Api.Error',
    defaultMessage: 'Error.Message.Api.Error',
    description: 'Toast body in case of error',
  },
  Title: {
    id: 'Screens.Patients.Read.Title',
    defaultMessage: 'Error.Screens.Patients.Read.Title',
  },
});
