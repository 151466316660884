import React from 'react';
import { Field } from 'formik';
import { useIntl } from 'react-intl';

import { Checkbox, FormInputFeedback, FormInput } from 'components';
import messages from './messages';

const Role = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <FormInput
        label={formatMessage({ ...messages.Code })}
        name='code'
        required
        type='text'
      />
      <FormInput
        label={formatMessage({ ...messages.Label })}
        name='label'
        required
        type='text'
      />
      <FormInput
        label={formatMessage({ ...messages.Ranking })}
        min='0'
        name='ranking'
        type='number'
      />
      <div className='mb-3'>
        <Field name='published'>
          {({ field, form }) => (
            <Checkbox {...field} checked={form.values.published} id='published'>
              {formatMessage({ ...messages.Published })}
            </Checkbox>
          )}
        </Field>
        <FormInputFeedback name='published' />
      </div>
    </>
  );
};

export default Role;
