import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { ThemeContext } from 'styled-components';

import { PageHeader, BarChart } from 'components';
import { api, useFetch } from 'utils';
import messages from './messages';

const List = () => {
  const theme = useContext(ThemeContext);
  const today = `${moment().format('MM-DD-YYYY')} 23:59:59`;

  const twoWeeksAgo = moment(moment())
    .subtract(13, 'days')
    .format('MM-DD-YYYY');

  const [dates, setDates] = useState([]);
  const [registrationsPerDayCount, setRegistrationsPerDayCount] = useState([]);
  const [topRegistrationsCode, setTopRegistrationsCode] = useState([]);
  const [topRegistrationsCount, setTopRegistrationsCount] = useState([]);
  const [dialogueRootsPerDayCount, setDialogueRootsPerDayCount] = useState([]);
  const [topDialogueRootsCode, setDialogueRootsCode] = useState([]);
  const [topDialogueRootsCount, setDialogueRootsCount] = useState([]);

  const [registrationsCount, areRegistrationsCountLoading] = useFetch(
    `${
      api.statistics.getRegistrationsCount
    }${`?Frequency=1&StartDate=${twoWeeksAgo}&EndDate=${today}`}`,
  );
  const [topRegistrations, areTopRegistrationsLoading] = useFetch(
    `${
      api.statistics.getRegistrationsCountByProduct
    }${`?TopNumber=5&StartDate=${twoWeeksAgo}&EndDate=${today}`}`,
  );
  const [dialogueRootsCounts, areDialogueRootsLoading] = useFetch(
    `${
      api.statistics.getDialogueRootsCount
    }${`?Frequency=1&StartDate=${twoWeeksAgo}&EndDate=${today}`}`,
  );
  const [topDialogueRoots, areTopDialogueRootsLoading] = useFetch(
    `${
      api.statistics.getDialogueRootsCountByProduct
    }${`?TopNumber=5&StartDate=${twoWeeksAgo}&EndDate=${today}`}`,
  );

  useEffect(() => {
    if (!areRegistrationsCountLoading) {
      setDates(
        registrationsCount.numberOfDataByDate.map((d) =>
          moment(d.date).format('dddd DD/MM'),
        ),
      );
      setRegistrationsPerDayCount(
        registrationsCount.numberOfDataByDate.map(
          (d) => d.numberOfConsultations,
        ),
      );
    }
  }, [areRegistrationsCountLoading]);

  useEffect(() => {
    if (!areTopRegistrationsLoading) {
      setTopRegistrationsCode(
        topRegistrations.numberOfDataByEntity.map((c) => c.code),
      );
      setTopRegistrationsCount(
        topRegistrations.numberOfDataByEntity.map((c) => c.value),
      );
    }
  }, [areTopRegistrationsLoading]);

  useEffect(() => {
    if (!areDialogueRootsLoading) {
      setDialogueRootsPerDayCount(
        dialogueRootsCounts.numberOfDataByDate.map(
          (e) => e.numberOfConsultations,
        ),
      );
    }
  }, [areDialogueRootsLoading]);

  useEffect(() => {
    if (!areTopDialogueRootsLoading) {
      setDialogueRootsCode(
        topDialogueRoots.numberOfDataByEntity.map((e) => e.code),
      );
      setDialogueRootsCount(
        topDialogueRoots.numberOfDataByEntity.map((e) => e.value),
      );
    }
  }, [areTopDialogueRootsLoading]);

  return (
    <>
      <PageHeader>
        <div>
          <h1>
            <FormattedMessage {...messages.Title} />
          </h1>
          <h3>{moment().format('dddd D MMMM YYYY')}</h3>
        </div>
      </PageHeader>
      <div className='row'>
        <div className='col-lg-6'>
          <div className='section d-flex align-items-center justify-content-center'>
            <div className='section__body '>
              <FormattedMessage {...messages.RegistrationAmount} tagName='h5' />
              <div>
                <BarChart
                  id='SubsCountChart'
                  abscissa={dates}
                  values={registrationsPerDayCount}
                  barsColor={theme.blue}
                  width='500'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-6'>
          <div className='section d-flex align-items-center justify-content-center'>
            <div className='section__body'>
              <FormattedMessage {...messages.TopSubsProduct} tagName='h5' />
              <div className='ml-4'>
                <BarChart
                  id='TopSubsByProductChart'
                  abscissa={topRegistrationsCode}
                  values={topRegistrationsCount}
                  barsColor={theme.blue}
                  width='500'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-6'>
          <div className='section d-flex align-items-center justify-content-center'>
            <div className='section__body'>
              <FormattedMessage {...messages.QuestionAmount} tagName='h5' />
              <div className='ml-4'>
                <BarChart
                  id='DialogueRootsCountChart'
                  abscissa={dates}
                  values={dialogueRootsPerDayCount}
                  barsColor={theme.green}
                  width='500'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-6'>
          <div className='section d-flex align-items-center justify-content-center'>
            <div className='section__body'>
              <FormattedMessage {...messages.TopQuestionProduct} tagName='h5' />
              <div className='ml-4'>
                <BarChart
                  id='TopDialogueRootsChart'
                  abscissa={topDialogueRootsCode}
                  values={topDialogueRootsCount}
                  barsColor={theme.green}
                  width='500'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default List;
