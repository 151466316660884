import { defineMessages } from 'react-intl';

export default defineMessages({
  Active: {
    id: 'Shared.Active',
    defaultMessage: 'Error.Shared.Active',
    description: 'If an offer is active',
  },
  ButtonCreate: {
    id: 'Buttons.Create',
    defaultMessage: 'Errors.Buttons.Create',
    description: 'Button to create an offer',
  },
  ButtonUpdate: {
    id: 'Buttons.Update',
    defaultMessage: 'Error.Buttons.Update',
    description: 'Button to update an offer',
  },
  ButtonRead: {
    id: 'Buttons.Read',
    defaultMessage: 'Error.Buttons.Read',
    description: 'Button to read an offer',
  },
  ButtonSort: {
    id: 'Buttons.Sort',
    defaultMessage: 'Error.Buttons.Sort',
    description: 'Button to sort offers',
  },
  DefaultSpecialty: {
    id: 'Shared.DefaultSpecialty',
    defaultMessage: 'Error.Shared.DefaultSpecialty',
  },
  Inactive: {
    id: 'Shared.Inactive',
    defaultMessage: 'Error.Shared.Inactive',
    description: 'If an offer is not active',
  },
  NoOffer: {
    id: 'Offers.NoOffer',
    defaultMessage: 'Error.Offers.NoOffer',
    description: 'No offer is created',
  },
  Title: {
    id: 'Screens.Offers.List.Title',
    defaultMessage: 'Error.Screens.Offers.List.Title',
    description: 'Offers page title',
  },
  Subtitle: {
    id: 'Screens.Offers.List.Subtitle',
    defaultMessage: 'Error.Screens.Offers.List.Subtitle',
  },
  Products: { id: 'Menu.Products', defaultMessage: 'Error.Menu.Products' },
  Profiles: { id: 'Menu.Profiles', defaultMessage: 'Error.Menu.Profiles' },
});
