import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Formik, Field } from 'formik';
import { useDebouncedCallback } from 'use-debounce';
import { Input, Row, Col } from 'reactstrap';

import { Button, PageHeader, RoleManager } from 'components';
import { ListWrapper } from 'themes/jupiter/elements';
import { service } from 'services';
import { api, sendToast, urlSchema } from 'utils';
import { IsEligibleWrapper } from './elements';
import { ToastTypes } from '../../../constants';
import messages from './messages';

const RESEARCH_INITIAL_VALUES = {
  isValid: 'true',
  searchString: '',
};

const List = () => {
  const intl = useIntl();
  const debouncedCallback = useDebouncedCallback((fn) => {
    fn();
  }, 350);
  const [isLoading, setIsLoading] = useState(true);
  const [webServices, setWebServices] = useState([]);

  const getResults = (values = RESEARCH_INITIAL_VALUES, callback) => {
    setIsLoading(true);
    service
      .get(api.webServices.getAll, {
        params: values,
      })
      .then(({ data }) => {
        setWebServices(data);
        setIsLoading(false);
        if (typeof callback === 'function') callback();
      })
      .catch(() => {
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.FailTitle} />,
          body: <FormattedMessage {...messages.FailBody} />,
        });
      });
  };

  const onSubmit = (values, props) => {
    getResults(values, () => {
      props.setSubmitting(false);
    });
  };

  useEffect(() => {
    getResults();
  }, []);

  return (
    <>
      <PageHeader>
        <FormattedMessage {...messages.Title} tagName='h1' />
        <RoleManager entity='WebServices' right='CRUD'>
          <Link
            data-tracking='WebServicesListBtnCreate'
            className='btn btn-primary'
            to={urlSchema.webServices.create}
          >
            {intl.formatMessage({ ...messages.ButtonCreate })}
          </Link>
        </RoleManager>
      </PageHeader>
      <section className='section'>
        <div className='section__body'>
          <Formik initialValues={RESEARCH_INITIAL_VALUES} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting }) => (
              <Form>
                <ListWrapper.ResearchWrapper>
                  <Row>
                    <Col md='7'>
                      <Field name='searchString' type='text'>
                        {({ field }) => {
                          const { onChange } = field;
                          return (
                            <Input
                              {...field}
                              id='search-string'
                              onChange={(e) => {
                                onChange(e);
                                debouncedCallback(handleSubmit);
                              }}
                              placeholder='Rechercher un webservice'
                            />
                          );
                        }}
                      </Field>
                    </Col>
                    <Col>
                      <Field name='isValid'>
                        {({ field }) => (
                          <Input
                            {...field}
                            id='is-valid'
                            onChange={(e) => {
                              field.onChange(e);
                              handleSubmit();
                            }}
                            type='select'
                          >
                            <option value=''>
                              {intl.formatMessage({ ...messages.All })}
                            </option>
                            <option value='true'>
                              {intl.formatMessage({
                                ...messages.IsValid,
                              })}
                            </option>
                            <option value='false'>
                              {intl.formatMessage({
                                ...messages.IsNotValid,
                              })}
                            </option>
                          </Input>
                        )}
                      </Field>
                    </Col>
                    <Col md='auto'>
                      <Button
                        color='primary'
                        isProcessing={isSubmitting}
                        label='Research'
                        type='submit'
                      />
                    </Col>
                  </Row>
                </ListWrapper.ResearchWrapper>
              </Form>
            )}
          </Formik>
          <header className='research-table__header'>
            <div className='container-fluid'>
              <Row>
                <Col md='7'>
                  <ListWrapper.ColumnTitle>
                    <FormattedMessage {...messages.Label} />
                  </ListWrapper.ColumnTitle>
                </Col>
                <Col md='3'>
                  <ListWrapper.ColumnTitle>
                    <FormattedMessage {...messages.Settings} />
                  </ListWrapper.ColumnTitle>
                </Col>
              </Row>
            </div>
          </header>
          <ul className='research-table__results'>
            {webServices.map((item) => (
              <li key={item.idWebService}>
                <div className='container-fluid'>
                  <Row className='align-items-center'>
                    <Col xs='7'>
                      <IsEligibleWrapper isEligible={item.isValid}>
                        <i
                          className={
                            item.isValid ? 'uil uil-check' : 'uil uil-times'
                          }
                          aria-hidden
                        />
                        <div>
                          <p>{item.name}</p>
                        </div>
                      </IsEligibleWrapper>
                    </Col>
                    <Col className='d-none d-lg-block' md='3'>
                      {item.refWebServiceConfigs ? (
                        <Link
                          to={`${urlSchema.webServices.configuration.read}/${item.webServiceConfig.idWebServiceConfig}`}
                        >
                          <small className='text-ellipsis'>
                            {item.webServiceConfig.name}
                          </small>
                        </Link>
                      ) : (
                        <small>-</small>
                      )}
                    </Col>
                    <Col>
                      <div className='research-table__actions'>
                        <RoleManager entity='WebServices' right='CRUD'>
                          <Link
                            aria-label={intl.formatMessage({
                              ...messages.ButtonUpdate,
                            })}
                            title={intl.formatMessage({
                              ...messages.ButtonUpdate,
                            })}
                            to={`${urlSchema.webServices.update}/${item.idWebService}`}
                          >
                            <i className='uil uil-pen uil--md' />
                          </Link>
                        </RoleManager>
                        <RoleManager entity='WebServices' right='Reader'>
                          <Link
                            aria-label={intl.formatMessage({
                              ...messages.ButtonRead,
                            })}
                            title={intl.formatMessage({
                              ...messages.ButtonRead,
                            })}
                            to={`${urlSchema.webServices.read}/${item.idWebService}`}
                          >
                            <i className='uil uil-eye uil--md' />
                          </Link>
                        </RoleManager>
                      </div>
                    </Col>
                  </Row>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </>
  );
};

export default List;
