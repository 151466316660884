import { AxiosResponse } from 'axios';

import { api } from '../../../utils';
import { service } from '../../auth';
import { GenericErrorResponse } from '../../../types';

export const getUserInformation = async () => {
  try {
    const response = await service.get<GenericErrorResponse | AxiosResponse>(
      api.users.getUserInformation,
    );

    if (
      response.data &&
      'succeeded' in response.data &&
      response.data.succeeded === false
    ) {
      throw new Error(response.data.errorSet[0].code);
    }

    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};
