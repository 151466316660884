import styled, { css } from 'styled-components';

const TabsWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.space(3)};

    .tabs-title {
      color: ${theme.color('gray-font')};
      font-weight: bold;
      margin: 0;
    }

    .form-group {
      margin: 0;
    }
    .nav {
      &-item {
        flex-grow: 1;

        &:first-child {
          .nav-link {
            border-top-left-radius: ${theme.borderRadius};
          }
        }

        &:last-child {
          .nav-link {
            border-top-right-radius: ${theme.borderRadius};
            border-right: 1px solid ${theme.color('border')};
          }
        }

        &.active {
          .nav-link {
            background-color: ${theme.color('gray-lightest')};

            span {
              color: ${theme.black};
            }
          }
        }

        &.invalid,
        &.valid {
          i {
            opacity: 1;
          }
        }

        &.invalid i {
          color: ${theme.color('danger')};
        }
        &.valid i {
          color: ${theme.color('success')};
        }

        i {
          opacity: 0;
          transition: ${theme.transitionBase};
        }
      }

      &-link {
        background-color: transparent;
        border-bottom: 1px solid ${theme.color('border')};
        border-left: 1px solid ${theme.color('border')};
        border-right: none;
        border-top: 1px solid ${theme.color('border')};
        border-radius: 0;
        cursor: pointer;
        text-align: center;
        width: 100%;

        & > * {
          display: inline-block;
          vertical-align: middle;
        }

        &:hover,
        &:active {
          border-color: ${theme.color('border')};
        }

        span {
          color: ${theme.color('gray-font')};
          font-weight: bold;
        }
      }

      &-tabs {
        align-items: center;
      }
    }

    .tab-pane {
      border-bottom-left-radius: ${theme.borderRadius};
      border-bottom-right-radius: ${theme.borderRadius};
      border-bottom: 1px solid ${theme.color('border')};
      border-left: 1px solid ${theme.color('border')};
      border-right: 1px solid ${theme.color('border')};
      padding: ${theme.space(3)};
    }
  `}
`;

export default TabsWrapper;
