import * as yup from 'yup';
import messages from './messages';
import { Helpers } from '../../constants';

export default (formatMessage) =>
  yup.object().shape({
    address1: yup.string().required('Model.FieldRequired'),
    address2: yup.string(),
    available: yup.boolean(),
    birthdate: yup.date().required(formatMessage(messages.FieldRequired)),
    city: yup.string().required(formatMessage(messages.FieldRequired)),
    codeLanguage: yup.string(),
    collegeNumber: yup.string().when('practitionerIdentifierNumberType', {
      is: 'PN',
      then: (schema) => schema.nullable(),
      otherwise: (schema) =>
        schema.required(
          formatMessage({
            ...messages.FieldRequired,
          }),
        ),
    }),
    comments: yup.string().nullable(),
    cpsCard: yup.string().required(formatMessage(messages.FieldRequired)),
    cpsReader: yup.string().required(formatMessage(messages.FieldRequired)),
    directoryMatchingId: yup.number().nullable(),
    email: yup
      .string()
      .email(formatMessage(messages.InvalidEmail))
      .required(formatMessage(messages.FieldRequired)),
    feesContractTypeId: yup
      .number()
      .required(formatMessage(messages.FieldRequired)),
    firstname: yup.string().required(formatMessage(messages.FieldRequired)),
    fonction: yup.string().nullable(),
    generateFeesNotes: yup
      .string()
      .required(formatMessage(messages.FieldRequired)),
    healthProfessionalSelected: yup.boolean(),
    lastname: yup.string().required(formatMessage(messages.FieldRequired)),
    mobileNumber: yup.lazy((value) => {
      return value
        ? yup
            .string()
            .trim()
            .matches(Helpers.PHONE_REGEX, formatMessage(messages.WrongNumber))
            .required(formatMessage(messages.FieldRequired))
        : yup.string().required(formatMessage(messages.FieldRequired));
    }),
    nationality: yup.string().nullable(),
    notification: yup.string(),
    ordinalQualificationId: yup
      .number()
      .required(formatMessage(messages.FieldRequired)),
    ordinalQualificationLabel: yup.string(),
    phoneNumber: yup
      .string()
      .matches(Helpers.PHONE_REGEX, formatMessage(messages.WrongNumber)),
    practitionerIdentifierNumberType: yup
      .string()
      .required(formatMessage(messages.FieldRequired)),
    prescriptionBook: yup
      .string()
      .required(formatMessage(messages.FieldRequired)),
    refUniverse: yup.string().required(formatMessage(messages.FieldRequired)),
    refUserCreator: yup.string(),
    selectedProfiles: yup.array().min(1, formatMessage(messages.FieldRequired)),
    signature: yup.string().nullable(),
    title: yup.string(),
    valid: yup.boolean(),
    zipCode: yup.string().required(formatMessage(messages.FieldRequired)),
  });
