import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { urlSchema } from 'utils';
import SurveyDialogueIcon from 'assets/images/dialogue_q_icon.svg';
import SurveyItemWrapper from './elements';

const SurveyItem = ({ data }) => {
  return (
    <SurveyItemWrapper>
      <SurveyItemWrapper.SurveyType>
        <img src={SurveyDialogueIcon} alt='' height='48px' />
        {data.isAnswered && <i aria-hidden className='uil uil-check' />}
      </SurveyItemWrapper.SurveyType>
      <SurveyItemWrapper.TextWrapper>
        <SurveyItemWrapper.TitleText>{data.title}</SurveyItemWrapper.TitleText>
        <SurveyItemWrapper.DescriptionText>
          {data.message}
        </SurveyItemWrapper.DescriptionText>
      </SurveyItemWrapper.TextWrapper>
      <div className='ml-auto'>
        {data.isAnswered ? (
          <a
            className='btn btn-sm btn-outline-primary'
            href={urlSchema.showDialogue(data.idDialogueRoot)}
          >
            <FormattedMessage
              id='Buttons.Show'
              defaultMessage='Error.Buttons.Show'
            />
          </a>
        ) : (
          <a
            className='btn btn-sm btn-outline-primary'
            href={urlSchema.showSurvey(data.idQuestionnaire, data.idUser)}
          >
            <FormattedMessage
              id='Buttons.Answer'
              defaultMessage='Error.Buttons.Answer'
            />
          </a>
        )}
      </div>
    </SurveyItemWrapper>
  );
};

SurveyItem.propTypes = {
  data: PropTypes.shape({
    idDialogueRoot: PropTypes.string,
    idQuestionnaire: PropTypes.string.isRequired,
    idUser: PropTypes.string.isRequired,
    isAnswered: PropTypes.bool.isRequired,
  }).isRequired,
};

export default SurveyItem;
