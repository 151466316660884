import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonEditRoles: {
    id: 'Buttons.EditRoles',
    defaultMessage: 'Error.Buttons.EditRoles',
  },
  ButtonEdit: {
    id: 'Buttons.Edit',
    defaultMessage: 'Error.Buttons.Edit',
  },
  ButtonRead: {
    id: 'Buttons.Read',
    defaultMessage: 'Error.Buttons.Read',
  },
});
