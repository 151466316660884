import React from 'react';
import { ToastContainer as RTToastContainer } from 'react-toastify';
import CloseButton from '../CloseButton';
import ToastContainerWrapper from './elements';

const ToastContainer = () => {
  return (
    <ToastContainerWrapper>
      <RTToastContainer closeButton={<CloseButton />} />
    </ToastContainerWrapper>
  );
};

export default ToastContainer;
