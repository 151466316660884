import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { observable, runInAction } from 'mobx';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import messages from '../messages';
import { urlSchema } from 'utils';
import {
  Spinner,
  PageHeader,
  RoleManager,
  Section,
  SectionBody,
} from 'components';
import { ListWrapper } from 'themes/jupiter/elements';

import Store from '../store';

const networkIsPending = observable.box(true);

//Update the list of config list
const refreshFileImportConfigsList = () => {
  return Store.retrieveConfigsList();
};

//Component - Ternary wrapper
const Suspense = observer((props) =>
  networkIsPending.get() === true ? (
    <div className='py-3 container-fluid'>
      <Spinner color='primary' size={props.size} />
    </div>
  ) : (
    props.children
  ),
);

//Observer Component - The voucher group list item
const FileImportConfigItem = (props) => {
  const { isLast, codeClient, filePath, idFileConfig, status } = props;
  return (
    <div className={`container-fluid py-3${isLast ? '' : ' border-bottom'}`}>
      <div className='row'>
        <div className='col-4 text-ellipsis'>{codeClient}</div>
        <div className='col-4 text-ellipsis'>{filePath}</div>
        <div className='col-2'>{String(status)}</div>
        <div className='col-2 text-right'>
          <Link
            className='list-action-anchor'
            to={`${urlSchema.fileImportConfigDetail(idFileConfig)}`}
          >
            <i className='uil uil-eye uil--md' />
          </Link>
        </div>
      </div>
    </div>
  );
};

const FileImportConfigsList = observer(() => {
  return (
    <Suspense size='sm'>
      <div className='voucherList'>
        {Store.importConfigs.map((config, index) => {
          const isLast = index === Store.importConfigs.length - 1;
          return (
            <FileImportConfigItem {...config} key={index} {...{ isLast }} />
          );
        })}
      </div>
    </Suspense>
  );
});

const List = () => {
  useEffect(() => {
    runInAction(() => {
      Store.importConfigs = [];
    });
    refreshFileImportConfigsList();
    runInAction(() => {
      networkIsPending.set(false);
    });
  }, []);
  return (
    <>
      <PageHeader>
        <h1>
          <FormattedMessage {...messages['list.title']} />
        </h1>
        <RoleManager entity='FileImportConfigs' right='CRUD'>
          <Link
            to={urlSchema.fileImportConfigCreate}
            className='btn btn-primary ml-3'
          >
            <FormattedMessage {...messages['list.create']} />
          </Link>
        </RoleManager>
      </PageHeader>
      <Section>
        <SectionBody>
          <ListWrapper>
            <div className='research-table__header'>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-4'>
                    <FormattedMessage {...messages['list.partner']} />
                  </div>
                  <div className='col-4'>
                    <FormattedMessage {...messages['list.directory']} />
                  </div>
                  <div className='col-2'>
                    <FormattedMessage {...messages['list.status']} />
                  </div>
                </div>
              </div>
            </div>
            <FileImportConfigsList />
          </ListWrapper>
        </SectionBody>
      </Section>
    </>
  );
};
export default List;
