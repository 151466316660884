import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, FieldArray, Formik, Form } from 'formik';
import { matchShape } from 'react-router-props';
import { Input } from 'reactstrap';
import cn from 'classnames';

import { Button, PageHeader, Section, SectionBody, Spinner } from 'components';
import { service } from 'services';
import { api, sendToast, useFetch } from 'utils';
import { ToastTypes } from '../../../constants';
import messages from './messages';

const EditProducts = ({ match }) => {
  const intl = useIntl();
  const { profileId } = match.params;
  const [profile, isProfileLoading] = useFetch(
    api.profiles.getDetails(profileId),
  );
  const [products, areProductsLoading] = useFetch(api.products.get);

  const onSubmit = (values, { setSubmitting }) => {
    service
      .put(api.profiles.editProducts(profileId), values)
      .then(({ data }) => {
        if (typeof data.succeeded !== 'undefined' && !data.succeeded) {
          sendToast(ToastTypes.error, {
            header: <FormattedMessage {...messages.FailTitle} />,
            body: <FormattedMessage {...messages.FailBody} />,
          });
        } else
          sendToast(ToastTypes.success, {
            header: <FormattedMessage {...messages.SuccessTitle} />,
            body: <FormattedMessage {...messages.SuccessBody} />,
          });
      })
      .catch(() => {
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.FailTitle} />,
          body: <FormattedMessage {...messages.FailBody} />,
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <FormattedMessage
          tagName='h1'
          {...messages.Title}
          values={{ label: profile.code || '' }}
        />
      </PageHeader>
      <Section>
        <SectionBody>
          {areProductsLoading || isProfileLoading ? (
            <Spinner color='primary' size='lg' />
          ) : (
            <Formik
              initialValues={{
                idProfile: profileId,
                searchString: '',
                selectedProducts:
                  profile.profilesProducts.length > 0
                    ? profile.profilesProducts.flatMap((el) => el.refProduct.id)
                    : [],
              }}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, values }) => (
                <Form>
                  <Field name='searchString'>
                    {({ field }) => (
                      <Input
                        className='mb-3'
                        placeholder={intl.formatMessage({
                          ...messages.Placeholder,
                        })}
                        size='lg'
                        type='text'
                        {...field}
                      />
                    )}
                  </Field>
                  <FieldArray
                    name='selectedProducts'
                    render={({ push, remove }) => (
                      <>
                        {products.map((product) => {
                          if (
                            product.label
                              .toLowerCase()
                              .includes(values.searchString.toLowerCase())
                          )
                            return (
                              <button
                                className={cn('combined-btn', {
                                  'combined-btn--success':
                                    values.selectedProducts.findIndex(
                                      (id) => id === product.idProduct,
                                    ) >= 0,
                                })}
                                key={product.idProduct}
                                onClick={() => {
                                  const index =
                                    values.selectedProducts.findIndex(
                                      (id) => id === product.idProduct,
                                    );
                                  if (index >= 0) {
                                    remove(index);
                                  } else push(product.idProduct);
                                }}
                                type='button'
                              >
                                <div className='combined-btn__container'>
                                  <span>{product.code}</span>
                                  {values.selectedProducts.findIndex(
                                    (el) => el === product.idProduct,
                                  ) >= 0 ? (
                                    <i aria-hidden className='uil uil-check' />
                                  ) : (
                                    <i aria-hidden className='uil uil-plus' />
                                  )}
                                </div>
                              </button>
                            );

                          return null;
                        })}
                      </>
                    )}
                  />
                  <div className='mt-3 text-right'>
                    <Button
                      color='primary'
                      disabled={isSubmitting}
                      isProcessing={isSubmitting}
                      label='Validate'
                      size='lg'
                      type='submit'
                    />
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </SectionBody>
      </Section>
    </>
  );
};

EditProducts.propTypes = { match: matchShape.isRequired };

export default EditProducts;
