import { defineMessages } from 'react-intl';

import { generateTranslationsObject } from 'utils';

const prefix = 'FileImportsClients.';
const keys = ['Partner', 'Entity', 'AssociatedProduct', 'Status', 'Activated'];

const additionalKeys = {
  Title: {
    id: 'Screens.FileImportsClients.Read.Title',
    defaultMessage: 'Error.Screens.FileImportsClients.Read.Title',
  },
  Edit: {
    id: 'Buttons.Edit',
    defaultMessage: 'Error.Buttons.Edit',
  },
  NoElement: {
    id: 'Shared.NoElement',
    defaultMessage: 'Error.Shared.NoElement',
  },
  Valid: {
    id: 'Model.Valid',
    defaultMessage: 'Error.Model.Valid',
  },
  Invalid: {
    id: 'Model.Invalid',
    defaultMessage: 'Error.Model.Invalid',
  },
};

export default defineMessages(
  generateTranslationsObject(prefix, keys, additionalKeys),
);
