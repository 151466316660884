import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Spinner } from 'reactstrap';
import { Formik, Form } from 'formik';
import {
  PageHeader,
  Section,
  SectionBody,
  SectionFooter,
  Button,
} from 'components';
import { service } from 'services';
import { api, useFetch, sendToast, urlSchema } from 'utils';
import { ToastTypes } from '../../../constants';

import CreateOfferForm from '../Create/CreateOfferForm';

import getValidationSchema from './validationSchema';
import formatDefaultData from './initialValues';
import messages from './messages';

const toastUploadLogoFailed = (error) =>
  sendToast(ToastTypes.error, {
    header: <FormattedMessage {...messages.FailTitle} />,
    body: (
      <>
        <p>
          <FormattedMessage {...messages.ToastErrorCode} />{' '}
          <code>{error.code}</code>
        </p>
        {error.code === 'DuplicateCode' ? (
          <p>
            <FormattedMessage {...messages.ToastDescriptionDuplicateCode} />
          </p>
        ) : (
          <p>
            <FormattedMessage {...messages.ToastErrorDescription} />{' '}
            <code>{error.description}</code>
          </p>
        )}
      </>
    ),
  });

const toastUploadOfferFailed = (error) =>
  sendToast(ToastTypes.error, {
    header: <FormattedMessage {...messages.FailTitle} />,
    body: (
      <>
        <p>
          <FormattedMessage {...messages.ToastErrorCode} />{' '}
          <code>{error.code}</code>
        </p>
        {error.code === 'DuplicateCode' ? (
          <p>
            <FormattedMessage {...messages.ToastDescriptionDuplicateCode} />
          </p>
        ) : (
          <p>
            <FormattedMessage {...messages.ToastErrorDescription} />{' '}
            <code>{error.description}</code>
          </p>
        )}
      </>
    ),
  });

const Update = () => {
  const history = useHistory();
  const { refOffer } = useParams();
  const [formLogoData, setFormLogoData] = useState(null);
  const [defaultOfferData, setDefaultOfferData] = useState(null);
  const [validLanguages, areValidLanguagesLoading] = useFetch(
    api.identity.getValidLanguages,
  );

  useEffect(() => {
    if (!validLanguages.length || !refOffer) return;

    service
      .get(api.admin.getOffer(refOffer))
      .then((response) => {
        const defaultData = formatDefaultData(validLanguages, response.data);
        setDefaultOfferData(defaultData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [validLanguages, refOffer]);

  const formatTranslationValues = (values, languages) => {
    const formattedValue = {
      name: [],
      cta: [],
      description: [],
    };

    languages.forEach((lang) => {
      Object.keys(formattedValue).forEach((key) => {
        formattedValue[key].push({
          content: values[lang.code][key],
          languageCode: lang.code,
        });
      });
    });

    return formattedValue;
  };

  const uploadLogoOrReturnCurrentRef = (values) => {
    if (formLogoData !== null) {
      return service
        .post(api.admin.postOfferLogo, formLogoData)
        .then((response) => {
          if (response.succeeded === false) {
            toastUploadLogoFailed(response.errorSet[0]);
            return values.refLogo;
          } else {
            return response.data.fileId;
          }
        })
        .catch((err) => {
          toastUploadLogoFailed();
          return values.refLogo;
        });
    } else {
      return values.refLogo;
    }
  };

  const onSubmit = async (values, { setSubmitting }) => {
    const newRefLogo = await uploadLogoOrReturnCurrentRef(values);
    const formattedTranslations = formatTranslationValues(
      values,
      validLanguages,
    );
    const newValues = {
      code: values.code,
      id: refOffer,
      isValid: values.isValid,
      products: values.products,
      allowFollowUpEncounter: values.allowFollowUpEncounter,
      profiles: values.profiles,
      refSpecialty: values.refSpecialty,
      refLogo: newRefLogo,
      ...formattedTranslations,
    };

    try {
      const response = await service.put(api.admin.putOffer, newValues);
      if (response.data.succeeded === false) throw response.data;
      sendToast(ToastTypes.success, {
        header: <FormattedMessage {...messages.SuccessTitle} />,
        body: <FormattedMessage {...messages.SuccessBody} />,
      });
      history.push(`${urlSchema.readOffer}/${refOffer}`);
    } catch (e) {
      console.log(e);
      toastUploadOfferFailed(e.errorSet[0]);
    } finally {
      setSubmitting(false);
    }
  };

  if (areValidLanguagesLoading || !defaultOfferData || !refOffer)
    return <Spinner />;

  return (
    <>
      <PageHeader>
        <FormattedMessage {...messages.UpdateOfferTitle} tagName='h1' />
      </PageHeader>
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          ...defaultOfferData,
          // "options" can be null if none of properties are true
          options: {
            ...defaultOfferData.options,
            isMedicalControlService:
              defaultOfferData.options?.isMedicalControlService ?? false,
          },
        }}
        validationSchema={getValidationSchema(validLanguages)}
      >
        {({ isSubmitting }) => (
          <Section>
            <Form>
              <SectionBody>
                <CreateOfferForm
                  validLanguages={validLanguages}
                  setFormLogoData={setFormLogoData}
                  update
                />
              </SectionBody>
              <SectionFooter>
                <div className='d-flex justify-content-end'>
                  <Button
                    label='Update'
                    type='submit'
                    color='primary'
                    isProcessing={isSubmitting}
                  />
                </div>
              </SectionFooter>
            </Form>
          </Section>
        )}
      </Formik>
    </>
  );
};

export default Update;
