import styled, { css } from 'styled-components';
import { FormGroup } from 'reactstrap';

const FormUploadFileWrapper = styled(FormGroup)`
  ${({ theme }) => css`
    display: inline-block;
    max-width: 100%;
    margin: 0 ${theme.space(2)} ${theme.space(2)} 0;

    label {
      color: ${theme.blue};

      &:hover {
        color: ${theme.white};
      }
    }
  `}
`;

const FileListWrapper = styled.ul`
  ${({ theme }) => css`
    list-style-type: none;
    margin: 0;
    padding: 0;

    button {
      ${theme.fontSize(theme.fontSizeSm())};
      background-color: ${theme.red};
      border-radius: 100%;
      border: 0;
      color: ${theme.white};
      line-height: 20px;
      padding: 0;
      position: absolute;
      right: -10px;
      text-align: center;
      top: -10px;
      width: 20px;
    }

    img {
      max-width: 100%;
      max-height: 128px;
      height: auto;
      border-radius: ${theme.borderRadius};
    }

    li {
      position: relative;
      display: inline-block;
      vertical-align: top;
      max-width: 100%;
      margin: 0 ${theme.space(2)} ${theme.space(2)} 0;

      p {
        ${theme.fontSize(theme.fontSizeSm())};
        border: 1px solid ${theme.color('border')};
        border-radius: ${theme.borderRadius};
        margin: 0;
        padding: ${theme.space(2)} ${theme.space(3)};
      }
    }
  `}
`;

const AvailableSizeWrapper = styled.p`
  ${({ theme }) => `
    ${theme.fontSize(theme.fontSizeSm())};
    color: ${theme.color('gray-font')};
    margin-bottom: ${theme.space(3)};
  `}
`;

const SubLabel = styled.p`
  ${({ theme }) => `
  ${theme.fontSize(theme.fontSizeSm())}
  color: ${theme.color('gray-font')}
  `}
`;

FormUploadFileWrapper.AvailableSizeWrapper = AvailableSizeWrapper;
FormUploadFileWrapper.FileListWrapper = FileListWrapper;

export { FormUploadFileWrapper, SubLabel };
