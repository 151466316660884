import React from 'react';
import { Spinner as RSSpinner } from 'reactstrap';
import PropTypes from 'prop-types';

import SpinnerWrapper from './elements';

const Spinner = ({ color, size, ...props }) => (
  <SpinnerWrapper size={size}>
    <RSSpinner color={color} size={size} {...props} />
  </SpinnerWrapper>
);

Spinner.defaultProps = {
  color: 'primary',
  size: undefined
};

Spinner.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string
};

export default Spinner;
