import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';

import { urlSchema } from 'utils';
import { Translation, RoleManager } from 'components';

const Item = ({ data }) => {
  const { formatMessage } = useIntl();
  const usersType = data.bannersUserTypes.map((user) => {
    return formatMessage({
      id: `Screens.Banners.Item.ClientLabel.${user}`,
      defaultMessage: `Error.Screens.Banners.Item.ClientLabel.${user}`,
    });
  });
  return (
    <div className='col col-lg-6'>
      <div className='border rounded px-3 mb-4'>
        <div className='d-flex justify-content-between align-items-center py-3 border-bottom'>
          <div className='d-flex align-items-center gap-2'>
            <h5 className='m-0'>
              <Translation
                id='Screens.Banners.Item.Title'
                values={{
                  id: data.id,
                }}
              />
            </h5>
            <Badge
              className='align-self-start'
              color={data.valid ? 'success' : 'danger'}
            >
              <span className='d-flex align-items-center'>
                <Translation
                  id={data.valid ? 'Shared.Activated' : 'Shared.Deactivated'}
                />
              </span>
            </Badge>
          </div>
          <RoleManager entity='Banners' right='ROOT'>
            <Link
              to={urlSchema.banners.update(data.id)}
              className='btn btn-outline-primary btn-sm ml-auto'
            >
              <Translation id='Buttons.Modify' />
            </Link>
          </RoleManager>
        </div>
        <div className='row pt-3'>
          <div className='col-lg-12'>
            <div className='model__property'>
              <h3 className='model__title'>
                <Translation id='Screens.Banners.Item.TextLabel' />
              </h3>
              <p className='model__value text-break'>{data.message}</p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='model__property'>
              <h3 className='model__title'>
                <Translation id='Screens.Banners.Item.StartDateLabel' />
              </h3>
              <p className='model__value'>
                {moment(data.startDate).format('DD/MM/YYYY')}
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='model__property'>
              <h3 className='model__title'>
                <Translation id='Screens.Banners.Item.EndDateLabel' />
              </h3>
              <p className='model__value'>
                {data.endDate ? moment(data.endDate).format('DD/MM/YYYY') : '/'}
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='model__property'>
              <h3 className='model__title'>
                <Translation id='Screens.Banners.Item.StartHourLabel' />
              </h3>
              <p className='model__value'>
                {moment(data.startDate).format('HH[h]mm')}
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='model__property'>
              <h3 className='model__title'>
                <Translation id='Screens.Banners.Item.EndHourLabel' />
              </h3>
              <p className='model__value'>
                {data.endDate ? moment(data.endDate).format('HH[h]mm') : '/'}
              </p>
            </div>
          </div>
          <div className='col-lg-12'>
            <div className='model__property'>
              <h3 className='model__title'>
                <Translation id='Screens.Banners.Item.ClientLabel' />
              </h3>
              <p className='model__value'>{usersType.join(' / ')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Item.defaultProps = {
  endDate: undefined,
  updatedOn: undefined,
};

Item.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string,
    valid: PropTypes.bool.isRequired,
    createdOn: PropTypes.string.isRequired,
    updatedOn: PropTypes.string,
    bannersUserTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
};

export default Item;
