import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Row, Col, Spinner } from 'reactstrap';
import { isNil } from 'lodash';

import { api, urlSchema, useFetch } from 'utils';
import { FormattedDate } from 'components';
import PrescriptionIcon from 'assets/images/prescription_icon.svg';
import DialoguesListWrapper from './elements';
import messages from './messages';

const PatientDialoguesList = ({ patientId }) => {
  const { locale } = useIntl();
  const [dialogues, areDialoguesLoading] = useFetch(
    api.dialogues.getDialogues(patientId, locale.split('-')[0]),
    { SortOrder: 'DateCreation_desc' },
    [locale],
  );

  if (areDialoguesLoading) return <Spinner size='lg' color='primary' />;

  if (isNil(dialogues) || dialogues.length === 0) {
    return <FormattedMessage {...messages.NoElement} tagName='span' />;
  }

  return (
    <DialoguesListWrapper>
      {dialogues.map((dialogue) => (
        <DialoguesListWrapper.Item key={dialogue.idDialogueRoot}>
          <Row>
            <DialoguesListWrapper.InfoType md='2'>
              <h3>{dialogue.codeDialogueType}</h3>
              <h4>{dialogue.idDialogueRoot}</h4>
            </DialoguesListWrapper.InfoType>
            <DialoguesListWrapper.DialogueDescription md='8'>
              <DialoguesListWrapper.DialogueInfoDate>
                <FormattedMessage
                  {...messages.MadeOn}
                  values={{
                    date: (
                      <FormattedDate
                        year='numeric'
                        month='numeric'
                        day='numeric'
                        value={dialogue.dateCreation}
                      />
                    ),
                  }}
                />
                {dialogue.refOperator && (
                  <DialoguesListWrapper.DialogueInfoOperator
                    href={urlSchema.readOperator(dialogue.refOperator)}
                  >
                    <FormattedMessage {...messages.FromOperator} />
                  </DialoguesListWrapper.DialogueInfoOperator>
                )}
              </DialoguesListWrapper.DialogueInfoDate>
              {dialogue.appointment && (
                <DialoguesListWrapper.DialogueInfoAppointment>
                  <FormattedMessage
                    {...messages.AppointmentDate}
                    values={{
                      date: (
                        <FormattedDate
                          year='numeric'
                          month='numeric'
                          day='numeric'
                          value={dialogue.appointment.startDate}
                        />
                      ),
                    }}
                  />
                </DialoguesListWrapper.DialogueInfoAppointment>
              )}
              <DialoguesListWrapper.DialogueInfo>
                <p>
                  <b>{dialogue.offerLabel}</b>
                </p>
              </DialoguesListWrapper.DialogueInfo>
            </DialoguesListWrapper.DialogueDescription>
            {dialogue.withPrescription && (
              <Col md='2'>
                <img src={PrescriptionIcon} alt='' width='24px' />
              </Col>
            )}
          </Row>
          <a
            className='btn btn-outline-primary btn-sm'
            href={`${urlSchema.showDialogue(dialogue.idDialogueRoot)}`}
          >
            <FormattedMessage {...messages.ButtonShow} />
          </a>
        </DialoguesListWrapper.Item>
      ))}
    </DialoguesListWrapper>
  );
};

export default PatientDialoguesList;
