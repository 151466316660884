import { useState } from 'react';
import { Form, Formik } from 'formik';
import moment from 'moment-timezone';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import {
  Button,
  FormInput,
  InputFileForm,
  PageHeader,
  Spinner,
  Translation,
} from 'components';
import { service } from 'services';
import { api, sendToast, useFetch, useOidc } from 'utils';
import { ChannelCodeType, Settings, ToastTypes } from '../../../constants';
import MessagesList from './Conversation';

const showPrompt = (message, callback) => {
  if (window.confirm(message)) callback();
};

const Read = () => {
  const [triggerConversation, setTriggerConversation] = useState();
  const { formatMessage } = useIntl();
  const { conversationId } = useParams();
  const [conversation, isConversationLoading] = useFetch(
    api.dialogues.getConversation(conversationId, 'fr'),
    null,
    [triggerConversation],
  );

  if (isConversationLoading) {
    return <Spinner size='lg' />;
  }

  const handleCancelAppointment = (appointmentId) => {
    const msg = formatMessage({
      id: 'screens_conversation_prompt_cancelMeeting',
      defaultMessage: 'screens_conversation_prompt_cancelMeeting',
    });

    showPrompt(msg, () => {
      service
        .put(api.dialogues.cancelAppointment(appointmentId))
        .then(({ data }) => {
          if (data.succeeded === false) {
            if (res.data.succeeded === false) throw new Error(res.data);
          }

          sendToast(ToastTypes.success, {
            header: formatMessage({
              id: 'screens_conversation_toast_appointmentCancelled_successTitle',
              defaultMessage:
                'error.screens_conversation_toast_appointmentCancelled_successTitle',
            }),
            body: formatMessage({
              id: 'screens_conversation_toast_appointmentCancelled_successBody',
              defaultMessage:
                'error.screens_conversation_toast_appointmentCancelled_successBody',
            }),
          });
          setTriggerConversation(Math.random());
        })
        .catch((err) => {
          sendToast(ToastTypes.error, {
            header: formatMessage({
              id: 'screens_conversation_toast_appointmentCancelled_errorTitle',
              defaultMessage:
                'error.screens_conversation_toast_appointmentCancelled_errorTitle',
            }),
            body: formatMessage({
              id: 'screens_conversation_toast_appointmentCancelled_errorBody',
              defaultMessage:
                'error.screens_conversation_toast_appointmentCancelled_errorBody',
            }),
          });
          console.log(err);
        });
    });
  };

  const canCancelAppointment =
    conversation.info.codeDialogueType !== ChannelCodeType.Messaging &&
    conversation.appointment?.status === 'Made';

  return (
    <div>
      <PageHeader>
        <div className='d-flex align-items-center justify-content-between w-100'>
          <div className='d-flex align-items-center'>
            <div class='alert alert-primary rounded-circle font-size-19px font-weight-bold m-0'>
              {conversation.info.codeDialogueType}
            </div>
            <div className='d-flex flex-column justify-content-center ml-2'>
              <h1>#{conversationId}</h1>
              <p className='m-0'>
                <Translation
                  id='screens_conversation_messages_creationDate'
                  values={{
                    date: moment(conversation.info.dateCreation).format(
                      'dddd Do MMMM YYYY [à] HH:mm',
                    ),
                  }}
                />
              </p>
            </div>
          </div>
          {canCancelAppointment && (
            <button
              onClick={() =>
                handleCancelAppointment(conversation.appointment.idAppointment)
              }
              className='btn btn-danger'
            >
              <Translation id='screens_conversation_cancelMeetingBtn' />
            </button>
          )}
        </div>
      </PageHeader>

      <div className='section'>
        <div className='section__body'>
          <div className='d-flex flex-row align-items-center justify-content-between'>
            <Translation
              id='screens_conversation_messages_title'
              tagName='h1'
            />
            {conversation.info.isIdentityValidationApproved ? (
              <div className='alert alert-success m-0'>
                <Translation id='screens_conversation_messages_authorizedStatus' />
              </div>
            ) : (
              <div className='alert alert-danger m-0'>
                <Translation id='screens_conversation_messages_deniedStatus' />
              </div>
            )}
          </div>
          <div className='d-grid gap-2'>
            <MessagesList data={conversation.dialogues} {...{ conversation }} />
          </div>
        </div>
      </div>

      <div className='section'>
        <div className='section__body'>
          <MessageBlock
            updateConversationView={() => setTriggerConversation(Math.random())}
          />
        </div>
      </div>
    </div>
  );
};

const MessageBlock = ({ updateConversationView }) => {
  const oidcUser = useOidc();
  const { conversationId } = useParams();
  const { formatMessage } = useIntl();

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    const formData = new FormData();

    const obj = {
      refOperator: oidcUser.profile.idUser,
      source: Settings.APPLICATION_NAME,
      refDialogueRoot: conversationId,
      message: values.message,
    };

    Object.entries(obj).forEach(([k, v]) => formData.append(k, v));

    values.files.forEach((file) => {
      formData.append('Files', file.File);
    });

    // Post message
    service
      .post(api.dialogues.postMessage, formData)
      .then((res) => {
        if (res.data.succeeded === false) throw new Error(res.data);

        sendToast(ToastTypes.success, {
          header: formatMessage({
            id: 'screens_conversation_toast_postMessage_successTitle',
            defaultMessage: 'error.screens_conversation_toasts_successTitle',
          }),
          body: formatMessage({
            id: 'screens_conversation_toast_postMessage_successBody',
            defaultMessage: 'error.screens_conversation_toasts_successMessage',
          }),
        });

        updateConversationView();
        resetForm();
      })
      .catch((err) => {
        sendToast(ToastTypes.error, {
          header: formatMessage({
            id: 'screens_conversation_toast_postMessage_errorTitle',
            defaultMessage: 'error.screens_conversation_toasts_errorTitle',
          }),
          body: formatMessage({
            id: 'screens_conversation_toast_postMessage_errorBody',
            defaultMessage: 'error.screens_conversation_toasts_errorMessage',
          }),
        });
        console.log(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div>
      <Translation id='screens_conversation_answer_title' tagName='h1' />
      <div className='mt-2'>
        <Formik
          initialValues={{
            message: '',
            files: [],
          }}
          onSubmit={handleSubmit}
        >
          {({ errors, values, isSubmitting }) => (
            <Form>
              <FormInput
                id='Message'
                placeholder='Message'
                label={formatMessage({
                  id: 'screens_conversation_label_message',
                  defaultMessage: ' Error.screens_conversation_label_message',
                })}
                name='message'
                type='textarea'
                required
              />
              <InputFileForm
                data-tracking='fileInput'
                label={formatMessage({
                  id: 'screens_conversation_label_files',
                  defaultMessage: ' Error.screens_conversation_label_files',
                })}
                name='files'
                error={errors.files}
                value={values.files}
              />
              <div className='d-flex justify-content-end'>
                <Button
                  color='primary'
                  isProcessing={isSubmitting}
                  label={formatMessage({
                    id: 'screens_conversation_label_send',
                    defaultMessage: ' Error.screens_conversation_label_send',
                  })}
                  size='lg'
                  type='submit'
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Read;
