import React, { useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Spinner } from 'reactstrap';
import { matchShape } from 'react-router-props';

import { service } from 'services';
import { api, sendToast, urlSchema, useFetch } from 'utils';
import { ApiError, PageHeader, RoleManager } from 'components';
import messages from './messages';
import { ToastTypes } from '../../../constants';

const Property = ({ options }) => (
  <div className='col-6'>
    <div className='model__property'>
      <h4 className='model__title'>
        <FormattedMessage {...messages[options.key]} />
      </h4>
      <p className='model__value'>{options.value || '-'}</p>
    </div>
  </div>
);

const getValidityUser = {
  true: { key: 'Valid', icon: 'check' },
  false: { key: 'Invalid', icon: 'times' },
};

const Read = ({ match }) => {
  const { formatMessage } = useIntl();
  const { partnerOperatorId } = match.params;
  const [partnerOperator, isPartnerOperatorLoading] = useFetch(
    api.partnerOperators.getDetails(partnerOperatorId),
  );
  const [clients, areClientsLoading] = useFetch(api.clients.getListItems);
  const [universes, areUniversesLoading] = useFetch(api.universes.getListItems);
  const [disabled, setDisabled] = useState(false);
  const keysUser = [
    { key: 'FirstName', value: partnerOperator.firstname },
    { key: 'LastName', value: partnerOperator.lastname },
    {
      key: 'Email',
      value: (
        <>
          {partnerOperator.email}{' '}
          {partnerOperator.verifiedEmail ? (
            <i aria-hidden className='uil uil-check-circle uil--sm' />
          ) : (
            ''
          )}
        </>
      ),
    },
    { key: 'MobilePhone', value: partnerOperator.mobileNumber },
  ];
  const keysAccount = [
    { key: 'CodeCompany', value: partnerOperator.infosAdmin?.codeCompany },
    { key: 'Notification', value: partnerOperator.notification },
    {
      key: 'CreationDate',
      value: moment(partnerOperator.dateCreation).format('LL'),
    },
  ];

  const putForgotPassword = () => {
    setDisabled(true);
    service
      .put(api.administrators.putForgotPassword(partnerOperatorId))
      .then(({ data }) => {
        if (typeof data !== 'undefined' && data.succeeded === false) {
          setDisabled(false);
          sendToast(ToastTypes.error, {
            body: formatMessage({ ...messages.ToastFail }),
          });
          return;
        }
        sendToast(ToastTypes.success, {
          body: formatMessage({ ...messages.ToastSuccess }),
        });
      })
      .catch((error) => {
        sendToast(ToastTypes.error, {
          header: formatMessage({ ...messages.ToastFail }),
          body: <ApiError {...{ error }} />,
        });
      })
      .then(() => {
        setDisabled(false);
      });
  };

  return (
    <>
      <PageHeader>
        <FormattedMessage
          {...messages.Title}
          values={{
            name: `${partnerOperator.firstname} ${partnerOperator.lastname}`,
          }}
          tagName='h1'
        />
        <Link
          className='btn btn-primary ml-2'
          to={`${urlSchema.partnerOperators.update}/${partnerOperatorId}`}
        >
          <FormattedMessage {...messages.ButtonEdit} />
        </Link>
      </PageHeader>
      <div className='row'>
        <div className='col-8'>
          <section className='section'>
            <div className='section__body'>
              {isPartnerOperatorLoading ? (
                <Spinner color='primary' size='lg' />
              ) : (
                <div className='row'>
                  <div className='col-5'>
                    <h2 className='mb-3'>{`${partnerOperator.firstname} ${partnerOperator.lastname}`}</h2>
                    <div>
                      <div
                        className={`badge badge-${
                          partnerOperator.valid ? 'success' : 'danger'
                        } mb-2`}
                      >
                        <span className='d-inline-block align-middle mr-1'>
                          <FormattedMessage
                            {...messages[
                              getValidityUser[partnerOperator.valid].key
                            ]}
                          />
                        </span>
                        <i
                          aria-hidden
                          className={`uil uil-${
                            getValidityUser[partnerOperator.valid].icon
                          }-circle uil--sm align-middle`}
                        />
                      </div>
                      {partnerOperator.blocked ? (
                        <div className='badge badge-danger ml-2'>
                          <span className='d-inline-block align-middle mr-1'>
                            <FormattedMessage {...messages.Blocked} />
                            <i
                              aria-hidden
                              className='uil uil-ban uil--sm align-middle'
                            />
                          </span>
                        </div>
                      ) : null}
                    </div>
                    {!areUniversesLoading && (
                      <Link
                        className='btn btn-outline-info btn-sm mb-2'
                        to={`${urlSchema.universes.read}/${partnerOperator.refUniverse}`}
                      >
                        <i aria-hidden className='uil uil-globe' />
                        <span className='ml-2'>
                          {
                            universes.find(
                              (el) => el.id === partnerOperator.refUniverse,
                            ).label
                          }
                        </span>
                      </Link>
                    )}
                    <br />
                    <button
                      className='btn btn-outline-primary btn-sm mr-2 mb-2'
                      disabled={disabled}
                      onClick={putForgotPassword}
                      type='button'
                    >
                      <FormattedMessage {...messages.ButtonGeneratePassword} />
                    </button>
                  </div>
                  <div className='col-7'>
                    <div>
                      <FormattedMessage
                        {...messages.UserInformation}
                        tagName='h3'
                      />
                      <div className='row'>
                        {keysUser.map((el) => (
                          <Property options={el} />
                        ))}
                      </div>
                    </div>
                    <div>
                      <FormattedMessage
                        {...messages.AccountInformation}
                        tagName='h3'
                      />
                      <div className='row'>
                        {keysAccount.map((el) => (
                          <Property options={el} />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
        <div className='col-4'>
          <section className='section'>
            <header className='section__header'>
              <FormattedMessage {...messages.Clients} tagName='h2' />
              <RoleManager entity='Admins' right='CRUD'>
                <Link
                  className='btn btn-outline-primary btn-sm ml-auto'
                  to={`${urlSchema.partnerOperators.update}/${partnerOperatorId}`}
                >
                  <FormattedMessage {...messages.ButtonManage} />
                </Link>
              </RoleManager>
            </header>
            <div className='section__body'>
              {isPartnerOperatorLoading || areClientsLoading ? (
                <Spinner color='primary' size='lg' />
              ) : (
                partnerOperator.infosAdmin.clients.map((el) => (
                  <Link
                    className='btn btn-outline-warning btn-sm mr-2 mb-2'
                    to={`${urlSchema.clients.read}/${el.refClient}`}
                  >
                    {clients.find((client) => client.id === el.refClient).label}
                  </Link>
                ))
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

Read.propTypes = { match: matchShape.isRequired };

export default Read;
