import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
import { Spinner, Translation } from 'components';

import { AccessLogsSurcharged } from './types';

import { getNHIPatientAccessLogDetail } from 'services/manager/NHIs/getNHIPatientAccessLogDetail';

const EMPTY_VALUE = '--';

const mapKeyToTranslationKey = {
  oid: 'Screens.Patient.Read.Nhi.Logs.ModalAccessLog.Label.oid',
  nhiRegistrationNumber:
    'Screens.Patient.Read.Nhi.Logs.ModalAccessLog.Label.nhiRegistrationNumber',
  birthGender: 'Screens.Patient.Read.Nhi.Logs.ModalAccessLog.Label.birthGender',
  birthFirstName:
    'Screens.Patient.Read.Nhi.Logs.ModalAccessLog.Label.birthFirstName',
  birthFirstNames:
    'Screens.Patient.Read.Nhi.Logs.ModalAccessLog.Label.birthFirstNames',
  birthLastName:
    'Screens.Patient.Read.Nhi.Logs.ModalAccessLog.Label.birthLastName',
  birthDate: 'Screens.Patient.Read.Nhi.Logs.ModalAccessLog.Label.birthDate',
  birthPlaceInseeCode:
    'Screens.Patient.Read.Nhi.Logs.ModalAccessLog.Label.birthPlaceInseeCode',
  usedFirstName:
    'Screens.Patient.Read.Nhi.Logs.ModalAccessLog.Label.usedFirstName',
  usedLastName:
    'Screens.Patient.Read.Nhi.Logs.ModalAccessLog.Label.usedLastName',
  status: 'Screens.Patient.Read.Nhi.Logs.ModalAccessLog.Label.status',
  attribute: 'Screens.Patient.Read.Nhi.Logs.ModalAccessLog.Label.attribute',
};

const attributeTranslationKey = {
  attribute: 'Screens.Patients.Read.Ins.Attribute.',
  status: 'Screens.Patients.Read.Ins.Status.',
  birthGender: 'Shared.Gender.',
};

const mapOidToTranslationKey = {
  8: 'Screens.Patient.Read.Nhi.Logs.ModalAccessLog.Oid.8',
  9: 'Screens.Patient.Read.Nhi.Logs.ModalAccessLog.Oid.9',
  10: 'Screens.Patient.Read.Nhi.Logs.ModalAccessLog.Oid.10',
  11: 'Screens.Patient.Read.Nhi.Logs.ModalAccessLog.Oid.11',
};

const getOidLastNumber = (oidString?: string) => {
  if (!oidString) return;
  const parts = oidString.split('.');
  return parts.at(-1);
};

const PropertyItem = ({
  item,
}: {
  item: {
    label: string;
    hasChanged: boolean;
    previousValue: string | number | React.JSX.Element;
    value: string | number | React.JSX.Element;
  };
}) => {
  return (
    <div className='col-md-6 mb-3'>
      <p className='mb-0 text-secondary'>
        <Translation id={item.label} />
      </p>

      <p className='mb-0'>
        {item.hasChanged ? (
          <>
            <span className='badge badge-danger'>
              {item.previousValue ?? EMPTY_VALUE}
            </span>
            <span className='badge badge-success'>
              {item.value ?? EMPTY_VALUE}
            </span>
          </>
        ) : item.value ? (
          <span className='badge badge-light'>{item.value}</span>
        ) : (
          <span className='badge badge-light'>{EMPTY_VALUE}</span>
        )}
      </p>
    </div>
  );
};

const formatData = (
  data: Record<
    string,
    {
      hasChanged: boolean;
      previousValue: string | number;
      value: string | number;
    }
  >,
) =>
  Object.keys(data)
    .map((keyName) => {
      const currentItem = data[keyName];

      const labelKey = mapKeyToTranslationKey[keyName];

      switch (keyName) {
        // keys not rendered
        case 'unQualifiedBirthDate':
        case 'nhiHistoryId': {
          return;
        }

        // keys who needs to be rendered as they are
        case 'birthFirstName':
        case 'birthFirstNames':
        case 'nhiRegistrationNumber':
        case 'birthLastName':
        case 'birthPlaceInseeCode':
        case 'usedFirstName':
        case 'usedLastName': {
          return {
            ...currentItem,
            label: labelKey,
          };
        }

        // keys who needs translation (enum, formating)
        case 'oid': {
          const formattedValue = currentItem.value ? (
            <Translation
              id={
                mapOidToTranslationKey[
                  getOidLastNumber(currentItem.value as string)
                ]
              }
            />
          ) : (
            EMPTY_VALUE
          );

          const formattedPreviousValue = currentItem.previousValue ? (
            <Translation
              id={
                mapOidToTranslationKey[
                  getOidLastNumber(currentItem.previousValue as string)
                ]
              }
            />
          ) : undefined;

          return {
            label: labelKey,
            hasChanged: currentItem.hasChanged,
            previousValue: formattedPreviousValue,
            value: formattedValue,
          };
        }

        case 'birthDate': {
          const formattedValue =
            currentItem.value ?? data['unQualifiedBirthDate'].value;
          const formattedPreviousValue =
            currentItem.previousValue ??
            data['unQualifiedBirthDate'].previousValue;
          return {
            label: labelKey,
            hasChanged: currentItem.hasChanged,
            previousValue: formattedPreviousValue,
            value: formattedValue,
          };
        }

        case 'status':
        case 'birthGender':
        case 'attribute': {
          return {
            label: labelKey,
            hasChanged: currentItem.hasChanged,
            previousValue: currentItem.previousValue ? (
              <Translation
                id={`${attributeTranslationKey[keyName]}${currentItem.previousValue}`}
              />
            ) : undefined,
            value: currentItem.value ? (
              <Translation
                id={`${attributeTranslationKey[keyName]}${currentItem.value}`}
              />
            ) : undefined,
          };
        }

        default: {
          return { ...currentItem, label: labelKey };
        }
      }
    })
    .filter((item) => item !== undefined);

export const ModalAccessLogDetails = ({
  log,
  onToggle,
}: {
  log: AccessLogsSurcharged;
  onToggle: () => void;
}) => {
  const { data, error, isLoading } = useQuery({
    queryKey: ['nhi-log-detail', { logId: log.id }],
    queryFn: () => getNHIPatientAccessLogDetail(log.id),
  });

  return (
    <Modal isOpen={true} toggle={onToggle}>
      <ModalHeader toggle={onToggle}>
        <Translation id='Screens.Patient.Read.Nhi.Logs.ModalAccessLog.Title' />
      </ModalHeader>
      <ModalBody>
        {error && <Translation id='Shared.ErrorFetching' />}
        {isLoading && <Spinner color='primary' size='sm' />}
        {data && (
          <div className='row'>
            {formatData(data).map((item) => (
              <PropertyItem key={item.label} item={item} />
            ))}
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};
