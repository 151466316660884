import React from 'react';
import PropTypes from 'prop-types';

import { FormRadioButtonWrapper } from './elements';

const FormRadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  inputValue,
  ...props
}) => {
  return (
    <FormRadioButtonWrapper {...props}>
      <input
        name={name}
        id={id}
        type='radio'
        value={inputValue}
        checked={inputValue === value}
        onChange={onChange}
        onBlur={onBlur}
      />
      <label htmlFor={id}>{label}</label>
    </FormRadioButtonWrapper>
  );
};

FormRadioButton.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default FormRadioButton;
