import React, { useEffect, useState } from 'react';
import { useOidc } from 'utils';

const Hangfire = () => {
  const oidcUser = useOidc();
  const { access_token } = oidcUser;
  const { REACT_HANGFIRE_WEBSITE_URL } = window.__env;
  const hangfireCookieName = 'HangFireCookie';
  const [iframeSrc, setIframeSrc] = useState('');
  const frameStyle = {
    width: '100%',
    height: 'calc(100vh - 40px)',
  };
  const isHttpsUrl =
    REACT_HANGFIRE_WEBSITE_URL.toLowerCase().startsWith('https');
  const secureCookieInfo =
    'Domain=.medecindirect.fr; Path=/; SameSite=None; Secure';

  useEffect(() => {
    document.cookie = `${hangfireCookieName}=${access_token};${
      isHttpsUrl ? secureCookieInfo : ''
    }`;
    setIframeSrc(REACT_HANGFIRE_WEBSITE_URL);

    return () => {
      document.cookie = `${hangfireCookieName}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;${
        isHttpsUrl ? secureCookieInfo : ''
      }`;
    };
  }, []);

  return iframeSrc === '' ? null : (
    <iframe
      src={REACT_HANGFIRE_WEBSITE_URL}
      frameborder='0'
      style={frameStyle}
    />
  );
};
export default Hangfire;
