import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { ModalHeader, Modal } from 'reactstrap';
import { Spinner, PDFModal, ImageModal, Translation } from '../../components';
import { useIdentityVerificationNhiStore } from './store';
import { IdentityDocument, User } from './types';
import { getDocument } from './services/getDocument';
import { ModalForm } from './ModalForm';
import { DocumentValidationBadge } from './DocumentValidationBadge';

const MediaViewer = ({ document }: { document: IdentityDocument }) => {
  if (!document) {
    return (
      <div className='min-height-50vh align-items-center d-flex justify-content-center'>
        <Spinner color='primary' size='lg' />
      </div>
    );
  }

  return document.contentType.includes('image') ? (
    <ImageModal
      options={{
        fileName: document.fileName,
        contentType: document.contentType,
        source: `data:${document.contentType};base64, ${document.documentRawFile}`,
      }}
    />
  ) : (
    <PDFModal
      options={{
        fileName: document.fileName,
        source: `data:${document.contentType};base64, ${document.documentRawFile}`,
      }}
    />
  );
};

export const ValidationModal = () => {
  const [document, setDocument] = useState<IdentityDocument | undefined>();
  const isLoading = useIdentityVerificationNhiStore((store) => store.isLoading);
  const [currentIdentityUser, setCurrentIdentityUser] = useState<
    User | undefined
  >();
  const users = useIdentityVerificationNhiStore((store) => store.users);
  const [selectedPatientId, setSelectedPatientId] =
    useIdentityVerificationNhiStore((store) => [
      store.selectedPatientId,
      store.setSelectedPatientId,
    ]);

  useEffect(() => {
    const fetchDocument = async (idDocument: string) => {
      const documentReponse = await getDocument(idDocument);
      setDocument(documentReponse);
    };

    if (currentIdentityUser?.idDocument) {
      fetchDocument(currentIdentityUser.idDocument);
    }
  }, [currentIdentityUser]);

  const closeModal = () => setSelectedPatientId();

  useEffect(() => {
    if (selectedPatientId) {
      const user = users.find((user) => user.patientId === selectedPatientId);
      setCurrentIdentityUser(user);
    } else {
      setCurrentIdentityUser(undefined);
    }
  }, [selectedPatientId]);

  if (isLoading) {
    return (
      <Modal isOpen={Boolean(selectedPatientId)} toggle={closeModal} size='xl'>
        <Spinner size='xl' color='primary' />
      </Modal>
    );
  }

  if (!currentIdentityUser) return;

  return (
    <Modal isOpen={Boolean(selectedPatientId)} toggle={closeModal} size='xl'>
      <ModalHeader toggle={closeModal}>
        <Translation id='Screens.IdentityValidationsIns.Title' />
      </ModalHeader>
      <div className='p-0 col d-flex flex-row'>
        <div className='bg-color-f1f1f6 col-md-7'>
          <MediaViewer document={document} />
        </div>
        <div className='col-md-5'>
          <div className='d-flex flex-column align-items-start mt-3 gap-3 p-2'>
            <DocumentValidationBadge
              documentValidationStatus={
                currentIdentityUser.documentValidationStatus
              }
            />
            <div className='w-100'>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='model__property'>
                    <h3 className='font-size-14px mb-0 text-secondary'>
                      <Translation id='Screens.IdentityValidationsIns.ModalForm.BirthLastname' />
                    </h3>
                    <p className='mb-0'>
                      {currentIdentityUser?.nhiLastName ?? '/'}
                    </p>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='model__property'>
                    <h3 className='font-size-14px mb-0 text-secondary'>
                      <Translation id='Screens.IdentityValidationsIns.ModalForm.BirthFirstname' />
                    </h3>
                    <p className='mb-0'>
                      {currentIdentityUser?.nhiFirstName ?? '/'}
                    </p>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-6'>
                  <div className='model__property'>
                    <h3 className='font-size-14px mb-0 text-secondary'>
                      <Translation id='Screens.IdentityValidationsIns.ModalForm.BirthDate' />
                    </h3>
                    <p className='mb-0'>
                      {currentIdentityUser?.nhiBirthDate ? (
                        <>
                          {moment(currentIdentityUser.nhiBirthDate).format(
                            'DD/MM/YYYY',
                          )}{' '}
                          (
                          <Translation
                            id='Shared.Age'
                            values={{
                              age: moment().diff(
                                moment(currentIdentityUser.nhiBirthDate),
                                'years',
                              ),
                            }}
                          />
                          )
                        </>
                      ) : (
                        '/'
                      )}
                    </p>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='model__property'>
                    <h3 className='font-size-14px mb-0 text-secondary'>
                      <Translation id='Screens.IdentityValidationsIns.ModalForm.BirthPlace' />
                    </h3>
                    {currentIdentityUser?.birthPlace ? (
                      <p className='mb-0'>{currentIdentityUser.birthPlace}</p>
                    ) : (
                      '/'
                    )}
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-6'>
                  <div className='model__property'>
                    <h3 className='font-size-14px mb-0 text-secondary'>
                      <Translation id='Shared.Gender' />
                    </h3>
                    {currentIdentityUser?.nhiGender ? (
                      <p className='mb-0'>
                        <Translation
                          id={`Screens.IdentityValidationsIns.ModalForm.Profile.${currentIdentityUser?.nhiGender}`}
                        />
                      </p>
                    ) : (
                      '/'
                    )}
                  </div>
                </div>
                <div className='col-md-6'>
                  {currentIdentityUser?.nhiBirthDate ? (
                    <div className='model__property'>
                      <h3 className='font-size-14px mb-0 text-secondary'>
                        <Translation id='Screens.IdentityValidationsIns.ModalForm.Profile' />
                      </h3>
                      <p className='mb-0'>
                        {moment().diff(
                          moment(currentIdentityUser.nhiBirthDate),
                          'years',
                        ) > 18 ? (
                          <Translation id='Screens.IdentityValidationsIns.ModalForm.Profile.Main' />
                        ) : (
                          <Translation id='Screens.IdentityValidationsIns.ModalForm.Profile.Beneficiary' />
                        )}
                      </p>
                    </div>
                  ) : (
                    '/'
                  )}
                </div>
              </div>
            </div>
          </div>
          <ModalForm
            user={currentIdentityUser}
            isImageVisible={Boolean(document)}
          />
        </div>
      </div>
    </Modal>
  );
};
