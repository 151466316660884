import styled from 'styled-components';
import { Section } from 'components';

const IsEligibleWrapper = styled.div`
  ${({ isEligible, theme }) => `
    align-items: center;
    display: flex;

    i {
      color: ${isEligible ? theme.green : theme.grayShade(200)};
      margin-right: ${theme.space(3)};
    }

    p {
      margin: 0;
    }
  `}
`;

const SearchPartnersWrapper = styled(Section)`
  .form-group {
    margin-bottom: 0;
  }
`;

export { IsEligibleWrapper, SearchPartnersWrapper };
