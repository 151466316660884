import React, { useEffect, useState } from 'react';
import { Field } from 'formik';
import { Spinner } from 'reactstrap';
import { useIntl } from 'react-intl';

import { FormInput, FormSelect } from 'components';
import { api, useFetch } from 'utils';
import messages from './messages';

const ProductTermsResource = ({ initialValues }) => {
  const intl = useIntl();
  const [language, isLanguageLoading] = useFetch(api.admin.getValidLanguages);
  const [languageArray, setLanguageArray] = useState([]);
  useEffect(() => {
    const array = [];
    if (!isLanguageLoading)
      language.forEach((el) => {
        array.push({
          label: el.label,
          value: el.code,
        });
      });
    setLanguageArray(array);
  }, [isLanguageLoading]);

  return (
    <>
      {isLanguageLoading ? (
        <Spinner size='lg' />
      ) : languageArray.length ? (
        <Field name='codeLanguage'>
          {({ field, form, meta }) => (
            <FormSelect
              {...{ meta }}
              defaultValue={
                initialValues.codeLanguage
                  ? languageArray.filter(
                      (el) => el.value === initialValues.codeLanguage,
                    )[0]
                  : null
              }
              hasError={Boolean(meta.touched && meta.error)}
              label={intl.formatMessage(messages.ProductTermsLanguage)}
              name={field.name}
              onChange={(opt) => {
                form.setFieldValue(field.name, opt.value);
              }}
              options={languageArray}
              required
            />
          )}
        </Field>
      ) : (
        <Spinner color='primary' />
      )}
      <FormInput
        id='terms'
        hasRawError
        label={intl.formatMessage(messages.ProductTermsCP)}
        name='terms'
        type='textarea'
      />
    </>
  );
};

export default ProductTermsResource;
