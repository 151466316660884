import React from 'react';
import * as yup from 'yup';

import { FormattedMessage } from 'react-intl';
import messages from './messages';

export default yup.object().shape({
  email: yup.string().email(<FormattedMessage {...messages['validation.email.format']} />),
  codeClient: yup.string().required(<FormattedMessage {...messages['validation.partner']} />),
  filePath: yup.string().required(<FormattedMessage {...messages['validation.directory']} />)
});
