import React from 'react';
import Proptypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ErrorMessage, Field } from 'formik';
import { FormGroup, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import FormLabel from '../FormLabel';

const InputWrapper = ({ addonType, inputGroup, inputGroupAddon, children }) =>
  inputGroup ? (
    <InputGroup>
      {children}
      <InputGroupAddon addonType={addonType}>{inputGroupAddon}</InputGroupAddon>
    </InputGroup>
  ) : (
    <>{children}</>
  );

const FormInput = ({
  addonType,
  bsSize,
  Component = Field,
  extraLabel,
  hasRawError,
  id,
  inputGroup,
  inputGroupAddon,
  label,
  name,
  required,
  ...inputProps
}) => {
  return (
    <FormGroup>
      {label && (
        <FormLabel
          className={bsSize === 'sm' ? 'small' : ''}
          for={id}
          required={required}
        >
          {label}
        </FormLabel>
      )}
      {extraLabel && <small>{extraLabel}</small>}
      <Component name={name}>
        {({ field, meta: { error, touched } }) => (
          <InputWrapper
            addonType={addonType}
            inputGroup={inputGroup}
            inputGroupAddon={inputGroupAddon}
          >
            <Input
              {...field}
              {...inputProps}
              bsSize={bsSize}
              invalid={Boolean(error && touched)}
            />
            <ErrorMessage name={name}>
              {(msg) => (
                <p className='invalid-feedback'>
                  {hasRawError ? (
                    msg
                  ) : (
                    <FormattedMessage
                      id={msg}
                      defaultMessage={`Error.${msg}`}
                    />
                  )}
                </p>
              )}
            </ErrorMessage>
          </InputWrapper>
        )}
      </Component>
    </FormGroup>
  );
};

FormInput.defaultProps = {
  addonType: undefined,
  bsSize: undefined,
  extraLabel: undefined,
  hasRawError: false,
  id: undefined,
  inputGroup: false,
  inputGroupAddon: undefined,
  invalid: false,
  label: undefined,
  required: false,
};

FormInput.propTypes = {
  addonType: Proptypes.string,
  bsSize: Proptypes.string,
  extraLabel: Proptypes.string,
  hasRawError: Proptypes.bool,
  id: Proptypes.string,
  inputGroup: Proptypes.bool,
  inputGroupAddon: Proptypes.string,
  invalid: Proptypes.bool,
  label: Proptypes.string,
  name: Proptypes.string.isRequired,
  required: Proptypes.bool,
};

export default FormInput;
