import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse as RSCollapse } from 'reactstrap';
import classNames from 'classnames';

const Collapse = ({
  hasPadding,
  title,
  isOpen: initalIsOpenState,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(initalIsOpenState);

  return (
    <div>
      <button
        className={classNames('collapse-title', { collapsed: isOpen })}
        onClick={() => setIsOpen(!isOpen)}
        type='button'
      >
        <span>{title}</span>
        <i className='uil uil-angle-down' aria-hidden />
      </button>
      <RSCollapse haspadding={hasPadding.toString()} isOpen={isOpen}>
        {children}
      </RSCollapse>
    </div>
  );
};

Collapse.defaultProps = {
  hasPadding: false,
  isOpen: false,
};

Collapse.propTypes = {
  hasPadding: PropTypes.bool,
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Collapse;
