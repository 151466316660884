import PropTypes from 'prop-types';
import { api, sendToast, useFetch } from '../../../../utils';
import {
  Translation,
  Spinner,
  PageHeader,
  Button,
} from '../../../../components';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Formik, Form, Field } from 'formik';
import { service } from 'services';
import { Banner, Ds } from '@teladochealth/components';
import { useIntl } from 'react-intl';
import { ToastTypes } from '../../../../constants';
import getValidationSchema from './validationSchema';
import { BirthPlaceAutocomplete } from './BirthPlaceAutocomplete';
import { PlaceTypeSelect } from './PlaceTypeSelect';

const UpdateNhiProfil = () => {
  const { formatMessage } = useIntl();
  const { patientId } = useParams();

  const [data, isDataLoading] = useFetch(
    api.admin.getNhiProfil(String(patientId)),
  );

  if (isDataLoading) {
    return (
      <div className='section'>
        <div className='section_body p-3'>
          <Spinner />
        </div>
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div className='section'>
        <div className='section_body p-3'>
          <Translation id='Shared.NoNhiDatas' />
        </div>
      </div>
    );
  }

  const formattedDate = moment(data.birthDate).format('DD/MM/YYYY');

  const initialValues = {
    patientId: patientId,
    birthGender: formatMessage({ id: `Shared.Gender.${data.birthGender}` }),
    birthDate: formattedDate,
    birthFirstName: data.birthFirstName,
    birthLastName: data.birthLastName,
    usedFirstName: data.usedFirstName || '',
    usedLastName: data.usedLastName || '',
    attribute: data.attribute || '',
    birthPlace: data.birthPlace || {
      placeType: 'FRENCH_PLACE',
    },
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const payload = {
      patientId: values.patientId,
      birthFirstName: values.birthFirstName,
      birthLastName: values.birthLastName,
      birthPlaceInseeCode: values.birthPlace.inseeCode ?? '99999',
      usedFirstName: values.usedFirstName,
      usedLastName: values.usedLastName,
      attribute: values.attribute,
    };

    try {
      const { data } = await service.put(api.admin.putNHI, payload);

      if (data.succeeded === false) {
        throw new Error(data.errorSet[0].code);
      }

      sendToast(ToastTypes.success, {
        header: (
          <Translation id='Shared.Toast.UpdateInformations.Success.Title' />
        ),
      });
      setSubmitting(false);
    } catch (error) {
      console.log('An unexpected error happened on NHI update.', error);
      sendToast(ToastTypes.error, {
        header: <Translation id='Shared.Toast.UpdateInformations.Fail.Title' />,
        body: <Translation id='Shared.Toast.UpdateInformations.Fail.Body' />,
      });
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageHeader>
        <h1>
          <Translation id='Patients.Nhis.Edit.Title' />
        </h1>
      </PageHeader>
      <div className='section'>
        <div className='section__body'>
          <Formik
            initialValues={initialValues}
            validationSchema={getValidationSchema(formatMessage)}
            onSubmit={handleSubmit}
          >
            {({ errors, values, touched, isSubmitting }) => (
              <Form>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='form-group'>
                      <Ds>
                        <Banner
                          id='alert-editing-nhis-profil'
                          options={{
                            content: (
                              <Translation id='Patients.Nhis.Edit.Banner.WarningMessage' />
                            ),
                            type: 'warning',
                          }}
                        />
                      </Ds>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label htmlFor='birthGender' className='text-secondary'>
                        <Translation id='Screens.Patients.Read.Ins.Label.Gender' />
                      </label>
                      <Field
                        name='birthGender'
                        className={`form-control ${touched.birthGender && errors.birthGender ? 'is-invalid' : ''}`}
                        type='text'
                        disabled
                      />
                      {touched.birthGender && errors.birthGender && (
                        <div className='invalid-feedback'>
                          {errors.birthGender}
                        </div>
                      )}
                    </div>
                    <span>
                      <Translation id='Patients.Nhis.Edit.HelperMessage' />
                    </span>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label htmlFor='birthDate' className='text-secondary'>
                        <Translation id='Screens.Patients.Read.Ins.Label.BirthDate' />
                      </label>
                      <Field
                        name='birthDate'
                        className={`form-control ${touched.birthDate && errors.birthDate ? 'is-invalid' : ''}`}
                        type='text'
                        disabled
                      />
                      {touched.birthDate && errors.birthDate && (
                        <div className='invalid-feedback'>
                          {errors.birthDate}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <hr />
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label htmlFor='birthFirstName'>
                        <Translation id='Screens.Patients.Read.Ins.Label.birthFirstName' />
                      </label>
                      <Field
                        name='birthFirstName'
                        className={`form-control text-uppercase ${touched.birthFirstName && errors.birthFirstName ? 'is-invalid' : ''}`}
                      />
                      {touched.birthFirstName && errors.birthFirstName && (
                        <div className='invalid-feedback'>
                          {errors.birthFirstName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label htmlFor='birthLastName'>
                        <Translation id='Screens.Patients.Read.Ins.Label.birthLastName' />
                      </label>
                      <Field
                        name='birthLastName'
                        className={`form-control text-uppercase ${touched.birthLastName && errors.birthLastName ? 'is-invalid' : ''}`}
                      />
                      {touched.birthLastName && errors.birthLastName && (
                        <div className='invalid-feedback'>
                          {errors.birthLastName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label htmlFor='usedFirstName'>
                        <Translation id='Screens.Patients.Read.Ins.Label.usedFirstName' />
                      </label>
                      <Field
                        name='usedFirstName'
                        className={`form-control text-uppercase ${touched.usedFirstName && errors.usedFirstName ? 'is-invalid' : ''}`}
                      />
                      {touched.usedFirstName && errors.usedFirstName && (
                        <div className='invalid-feedback'>
                          {errors.usedFirstName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label htmlFor='usedLastName'>
                        <Translation id='Screens.Patients.Read.Ins.Label.usedLastName' />
                      </label>
                      <Field
                        name='usedLastName'
                        className={`form-control text-uppercase ${touched.usedLastName && errors.usedLastName ? 'is-invalid' : ''}`}
                      />
                      {touched.usedLastName && errors.usedLastName && (
                        <div className='invalid-feedback'>
                          {errors.usedLastName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <PlaceTypeSelect />
                  </div>
                  {/* Small hack to reset the autocomplete if the place type changes. */}
                  {values.birthPlace.placeType === 'FRENCH_PLACE' && (
                    <div className='col-md-6'>
                      <BirthPlaceAutocomplete placeType='FRENCH_PLACE' />
                    </div>
                  )}
                  {values.birthPlace.placeType === 'OTHER_COUNTRY' && (
                    <div className='col-md-6'>
                      <BirthPlaceAutocomplete placeType='OTHER_COUNTRY' />
                    </div>
                  )}
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label htmlFor='attribute'>
                        <Translation id='Screens.Patients.Read.Ins.Label.Attributes' />
                      </label>
                      <Field
                        name='attribute'
                        as='select'
                        className={`form-control text-uppercase ${touched.attribute && errors.attribute ? 'is-invalid' : ''}`}
                      >
                        <option value=''>
                          <Translation id='Patients.Nhis.Edit.Attribute.Empty' />
                        </option>
                        <option value='Homonymous'>
                          <Translation id='Patients.Nhis.Edit.Attribute.Homonymous' />
                        </option>
                        <option value='Dubious'>
                          <Translation id='Patients.Nhis.Edit.Attribute.Dubious' />
                        </option>
                        <option value='Fictional'>
                          <Translation id='Patients.Nhis.Edit.Attribute.Fictional' />
                        </option>
                      </Field>
                      {touched.attribute && errors.attribute && (
                        <div className='invalid-feedback'>
                          {errors.attribute}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className='text-right'>
                      <Button
                        color='primary'
                        isProcessing={isSubmitting}
                        label='Update'
                        size='lg'
                        type='submit'
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

UpdateNhiProfil.propTypes = {
  patientId: PropTypes.string.isRequired,
};

export default UpdateNhiProfil;
