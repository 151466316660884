import React from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';

const BarChart = ({ id, abscissa, values, valueName, width, barsColor }) => {
  const BarChartOptions = {
    chart: {
      id: { id },
    },
    stroke: {
      width: 1,
      colors: [barsColor],
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    fill: {
      colors: [barsColor],
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        opacityFrom: 0.7,
        opacityTo: 0.85,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: abscissa,
    },
  };

  const chartData = {
    options: BarChartOptions,
    series: [
      {
        name: valueName,
        data: values,
      },
    ],
  };

  return (
    <div className='app'>
      <div className='row'>
        <div className='mixed-chart'>
          <Chart
            options={chartData.options}
            series={chartData.series}
            type='bar'
            width={width}
          />
        </div>
      </div>
    </div>
  );
};

BarChart.defaultProps = {
  valueName: '',
};
BarChart.propTypes = {
  abscissa: PropTypes.arrayOf(PropTypes.string).isRequired,
  barsColor: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  valueName: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  width: PropTypes.number.isRequired,
};

export default BarChart;
