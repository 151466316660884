import { defineMessages } from 'react-intl';

export default defineMessages({
  Client: {
    id: 'Models.Product.Client',
    defaultMessage: 'Error.Models.Product.Client',
  },
  Code: {
    id: 'Models.Product.Code',
    defaultMessage: 'Error.Models.Product.Code',
  },
  Duration: {
    id: 'Models.Product.Duration',
    defaultMessage: 'Error.Models.Product.Duration',
  },
  ExpirationDate: {
    id: 'Models.Product.ExpirationDate',
    defaultMessage: 'Error.Models.Product.ExpirationDate',
  },
  FieldRequired: {
    id: 'Model.FieldRequired',
    defaultMessage: 'Error.Model.FieldRequired',
  },
  Label: {
    id: 'Models.Product.Label',
    defaultMessage: 'Error.Models.Product.Label',
  },
  MaxCharacters: {
    id: 'Model.MaxCharacters',
    defaultMessage: 'Error.Model.MaxCharacters',
  },
  MaxQuestionsCount: {
    id: 'Models.Product.MaxQuestionsCount',
    defaultMessage: 'Error.Models.Product.MaxQuestionsCount',
  },
  ProductTerms: {
    id: 'Models.Product.ProductTerms',
    defaultMessage: 'Error.Models.Product.ProductTerms',
  },
  SellsyClient: {
    id: 'Models.Product.SellsyClient',
    defaultMessage: 'Error.Models.Product.SellsyClient',
  },
  SellsyClientPlaceholder: {
    id: 'Models.Product.SellsyClientPlaceholder',
    defaultMessage: 'Error.Models.Product.SellsyClientPlaceholder',
  },
  Tag: {
    id: 'Models.Product.Tag',
    defaultMessage: 'Error.Models.Product.Tag',
  },
  Valid: {
    id: 'Models.Product.Valid',
    defaultMessage: 'Error.Models.Product.Valid',
  },
  VerificationType: {
    id: 'Models.Product.VerificationType',
    defaultMessage: 'Error.Models.Product.VerificationType',
  },
  WebService: {
    id: 'Models.Product.WebService',
    defaultMessage: 'Error.Models.Product.WebService',
  },
});
