import { defineMessages } from 'react-intl';

import { generateTranslationsObject } from 'utils';

const prefix = 'Screens.Questionnaires.Create.';
const keys = [];

const additionalKeys = {
  ToastFail: {
    id: `${prefix}Toasts.Profiles.Create.Error`,
    defaultMessage: 'Error.Toasts.Profiles.Create.Error',
  },
  ToastSuccess: {
    id: `${prefix}Toasts.Profiles.Create.Success`,
    defaultMessage: 'Error.Toasts.Profiles.Create.Success',
  },
};

export default defineMessages(
  generateTranslationsObject(prefix, keys, additionalKeys),
);
