import api from './api';
import generateTranslationsObject from './generateTranslationsObject';
import hasRightToAccess from './hasRightToAccess';
import getParsedDate from './getParsedDate';
import removeKeysWithEmptyString from './removeKeysWithEmptyString';
import urlSchema from './urlSchema';
import useFetch from './useFetch';
import sendToast from './toasts';
import useOidc from './useOidc';

const getDomain = () =>
  window.location.hostname.split('.').length > 1
    ? '.'.concat(window.location.hostname.split('.').slice(-2).join('.'))
    : window.location.hostname.split('.').slice(-2).join('.');

const isNullorEmptyOrUndefined = (value) =>
  value === null || value === undefined || value === '';

export { saveToStorage } from './storage-utils';

export {
  api,
  hasRightToAccess,
  generateTranslationsObject,
  getDomain,
  getParsedDate,
  isNullorEmptyOrUndefined,
  removeKeysWithEmptyString,
  sendToast,
  useFetch,
  urlSchema,
  useOidc,
};
