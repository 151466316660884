import styled, { css } from 'styled-components';

const OffersCreateHeader = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.space(3)};
    h1 {
      color: ${theme.blue};
    }
  `};
`;

export default OffersCreateHeader;
