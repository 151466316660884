import { defineMessages } from 'react-intl';

export default defineMessages({
  Client: {
    id: 'Partners.Client',
    defaultMessage: 'Error.Partners.Client',
    description: "Partner's client",
  },
  EligibilityKey: {
    id: 'Partners.EligibilityKey',
    defaultMessage: 'Error.Partners.EligibilityKey',
    description: 'The way a patient wants to log in',
  },
  EligibilityKeyDescription: {
    id: 'Partners.EligibilityKeyDescription',
    defaultMessage: 'Error.Partners.EligibilityKeyDescription',
    description: 'Help to subscribe with the partner',
  },
  IsEligible: {
    id: 'Partners.IsEligible',
    defaultMessage: 'Error.Partners.IsEligible',
    description: 'Is partner eligible to MédecinDirect ?',
  },
  Keywords: {
    id: 'Partners.Keywords',
    defaultMessage: 'Error.Partners.Keywords',
  },
  KeywordsDescription: {
    id: 'Partners.KeywordsDescription',
    defaultMessage: 'Error.Partners.KeywordsDescription',
  },
  KeywordsPlaceholder: {
    id: 'Partners.KeywordsPlaceholder',
    defaultMessage: 'Partners.KeywordsPlaceholder',
  },
  Name: {
    id: 'Partners.Name',
    defaultMessage: 'Error.Partners.Name',
  },
  URL: {
    id: 'Partners.URL',
    defaultMessage: 'Error.Partners.URL',
  },
});
