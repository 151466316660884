import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row, FormGroup, Input } from 'reactstrap';
import { Field, Formik, Form } from 'formik';

import {
  PageHeader,
  Section,
  SectionBody,
  Spinner,
  Button,
  FormLabel,
  FormInputFeedback,
} from 'components';
import { service } from 'services';
import { api, useFetch, sendToast, urlSchema } from 'utils';
import { Application } from 'models';
import { ToastTypes } from '../../../constants';
import messages from './messages';
import initialValues from './initialValues';
import validationSchema from './validationShema';
import { ButtonsWrapper } from 'themes/jupiter/elements';

const Create = ({ isLoading, history }) => {
  const { formatMessage } = useIntl();
  const [universes, areUniversLoading] = useFetch(api.admin.getUnivers);

  const onSubmit = (values, { setSubmitting }) => {
    service
      .post(api.admin.postApplication, values)
      .then((res) => {
        setSubmitting(false);
        if (res.data.succeeded === false) {
          sendToast(ToastTypes.error, {
            header: <FormattedMessage {...messages.FailTitle} />,
            body: <FormattedMessage {...messages.FailBody} />,
          });
          return;
        } else {
          sendToast(ToastTypes.success, {
            header: <FormattedMessage {...messages.SuccessTitle} />,
            body: <FormattedMessage {...messages.SuccessBody} />,
          });
          history.push(urlSchema.applications.list);
        }
      })
      .catch(() => {
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.FailTitle} />,
          body: <FormattedMessage {...messages.FailBody} />,
        });
      });
  };

  return (
    <>
      <PageHeader>
        <FormattedMessage {...messages.Title} tagName='h1' />
      </PageHeader>
      <Row>
        <Col lg='6'>
          <Section>
            <SectionBody>
              {isLoading || areUniversLoading ? (
                <Spinner size='lg' />
              ) : (
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <Application universes={universes} />
                      <FormGroup>
                        <FormLabel for='ref-organisation'>
                          {formatMessage(messages.Organization)}
                        </FormLabel>
                        <Field name='refOrganization'>
                          {({ field }) => (
                            <Input
                              {...field}
                              id='ref-organisation'
                              type='select'
                            >
                              <option value=''>-</option>
                              {universes.map((universe) => (
                                <option key={universe.id} value={universe.id}>
                                  {universe.label}
                                </option>
                              ))}
                            </Input>
                          )}
                        </Field>
                        <FormInputFeedback name='refOrganization' />
                      </FormGroup>
                      <ButtonsWrapper bottom>
                        <Button
                          color='primary'
                          isProcessing={isSubmitting}
                          label='Create'
                          size='lg'
                          data-tracking='ApplicationCreateSubmit'
                          type='submit'
                        />
                      </ButtonsWrapper>
                    </Form>
                  )}
                </Formik>
              )}
            </SectionBody>
          </Section>
        </Col>
      </Row>
    </>
  );
};

export default Create;
