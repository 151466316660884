import React, { useContext, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { Spinner } from 'reactstrap';
import { observable, runInAction } from 'mobx';
import AceEditor from 'react-ace';
import { ThemeContext } from 'styled-components';
import { useIntl } from 'react-intl';
import 'ace-builds/src-noconflict/theme-xcode';

import { FormInput, FormInputFeedback } from 'components';
import { api, useFetch } from 'utils';
import messages from './messages';
import { TemplateMessagesType } from '../../constants';

export const message = observable.box('');

export const TemplateMessage = () => {
  const { formatMessage } = useIntl();
  const theme = useContext(ThemeContext);
  const { errors, initialValues, setFieldValue, touched } = useFormikContext();
  const [universes, areUniversesLoading] = useFetch(api.universes.getListItems);
  const [languages, areLanguagesLoading] = useFetch(api.languages.getValid);

  const onChange = (value) => {
    setFieldValue('message', value);
    runInAction(() => {
      message.set(value);
    });
  };

  useEffect(() => {
    return runInAction(() => {
      message.set('');
    });
  }, []);

  useEffect(() => {
    runInAction(() => {
      message.set(initialValues.message);
    });
  }, [initialValues]);

  return (
    <>
      <FormInput
        label={formatMessage(messages.Name)}
        name='name'
        type='text'
        required
      />
      <div className='form-group'>
        <label className='required' htmlFor='type'>
          {formatMessage(messages.Type)}
        </label>
        <Field as='select' className='form-control' id='type' name='type'>
          <option value=''>-</option>
          {Object.keys(TemplateMessagesType).map((el) => (
            <option key={el} value={el}>
              {el}
            </option>
          ))}
        </Field>
        {touched.type && errors.type && <FormInputFeedback msg={errors.type} />}
      </div>
      <div className='form-group'>
        <label className='required' htmlFor='code-language'>
          {formatMessage(messages.CodeLanguage)}
        </label>
        {areLanguagesLoading ? (
          <Spinner color='primary' />
        ) : (
          <Field
            as='select'
            className='form-control'
            id='code-language'
            name='codeLanguage'
          >
            <option value=''>-</option>
            {languages.map((el) => (
              <option key={el.idLanguage} value={el.code}>
                {el.label}
              </option>
            ))}
          </Field>
        )}
        {touched.codeLanguage && errors.codeLanguage && (
          <FormInputFeedback msg={errors.codeLanguage} />
        )}
      </div>
      <div className='form-group'>
        <label className='required' htmlFor='refOrganization'>
          {formatMessage(messages.RefOrganization)}
        </label>
        {areUniversesLoading ? (
          <Spinner color='primary' />
        ) : (
          <Field
            as='select'
            className='form-control'
            id='refOrganization'
            name='refOrganization'
          >
            <option value=''>-</option>
            {universes.map((el) => (
              <option key={el.id} value={el.id}>
                {el.label}
              </option>
            ))}
          </Field>
        )}
        {touched.refOrganization && errors.refOrganization && (
          <FormInputFeedback msg={errors.refOrganization} />
        )}
      </div>
      <FormInput
        id='object'
        label={formatMessage(messages.Object)}
        name='object'
        type='text'
      />
      <div className='mb-3'>
        <p className='text-secondary font-weight-bold mb-1'>
          {formatMessage(messages.Message)}
        </p>
        <AceEditor
          defaultValue={initialValues.message}
          mode='html'
          name='message-editor'
          style={{
            border: `1px solid ${theme.color('border')}`,
            borderRadius: theme.borderRadius,
          }}
          theme='xcode'
          width={`${document.querySelector('#object')}px`}
          wrapEnabled
          {...{ onChange }}
        />
      </div>
    </>
  );
};
