import { defineMessages } from 'react-intl';

const messages = defineMessages({
  administrators: {
    id: 'Menu.Administrators',
    defaultMessage: 'Error.Menu.Administrators',
    description: "Administrators' menu label",
  },
  alerts: {
    id: 'Menu.Alerts',
    defaultMessage: 'Error.Menu.Alerts',
    description: "Alerts' menu label",
  },
  applications: {
    id: 'Menu.Applications',
    defaultMessage: 'Error.Menu.Applications',
    description: "Applications' menu label",
  },
  banners: {
    id: 'Menu.Banners',
    defaultMessage: 'Error.Menu.Banners',
    description: "News' menu label",
  },
  clients: {
    id: 'Menu.Clients',
    defaultMessage: 'Error.Menu.Clients',
    description: "Clients' menu label",
  },
  countries: {
    id: 'Menu.Countries',
    defaultMessage: 'Error.Menu.Countries',
    description: "Countries' menu label",
  },
  dialogues: {
    id: 'Menu.Dialogues',
    defaultMessage: 'Error.Menu.Dialogues',
    description: "Dialogues' menu label",
  },
  dialogueActions: {
    id: 'Menu.DialogueActions',
    defaultMessage: 'Error.Menu.DialogueActions',
    description: "Dialogue actions' menu label",
  },
  dialogueStatus: {
    id: 'Menu.DialogueStatus',
    defaultMessage: 'Error.Menu.DialogueStatus',
    description: "Dialogue status' menu label",
  },
  dialogueTypes: {
    id: 'Menu.DialogueTypes',
    defaultMessage: 'Error.Menu.DialogueTypes',
    description: "Dialogue types' menu label",
  },
  doctors: {
    id: 'Menu.Doctors',
    defaultMessage: 'Error.Menu.Doctors',
    description: "Doctors' menu label",
  },
  documentsType: {
    id: 'Menu.DocumentsType',
    defaultMessage: 'Error.Menu.DocumentsType',
    description: "Documents Type' menu label",
  },
  EligiblePartners: {
    id: 'Menu.EligiblePartners',
    defaultMessage: 'Error.Menu.EligiblePartners',
    description: 'Eligible partners menu',
  },
  identitiesVerification: {
    id: 'Menu.IdentitiesVerification',
    defaultMessage: 'Error.Menu.IdentitiesVerification',
    description: "Identities verification's menu label",
  },
  identitiesValidationsNhi: {
    id: 'Menu.IdentitiesValidationsNhi',
    defaultMessage: 'Error.Menu.IdentitiesVerification',
    description: "Identities verification's menu label",
  },
  languageLevel: {
    id: 'Menu.LanguageLevel',
    defaultMessage: 'Error.Menu.LanguageLevel',
    description: "Language Level's menu label",
  },
  messagesTemplate: {
    id: 'Menu.MessagesTemplate',
    defaultMessage: 'Error.Menu.MessagesTemplate',
    description: "Messages Template's menu label",
  },
  news: {
    id: 'Menu.News',
    defaultMessage: 'Error.Menu.News',
    description: "News' menu label",
  },
  offers: {
    id: 'Menu.Offers',
    defaultMessage: 'Error.Menu.Offers',
    description: "Offers' menu label",
  },
  organisationConfigs: {
    id: 'Menu.OrganisationConfigs',
    defaultMessage: 'Error.Menu.OrganisationConfigs',
    description: "OrganisationConfigs' menu label",
  },
  universes: {
    id: 'Menu.Universes',
    defaultMessage: 'Error.Menu.Universes',
    description: "Organizations' menu label",
  },
  patients: {
    id: 'Menu.Patients',
    defaultMessage: 'Error.Menu.Patients',
    description: "Patients' menu label",
  },
  products: {
    id: 'Menu.Products',
    defaultMessage: 'Error.Menu.Products',
    description: "Products' menu label",
  },
  productTerms: {
    id: 'Menu.ProductTerms',
    defaultMessage: 'Error.Menu.ProductTerms',
    description: "Product terms' menu label",
  },
  profiles: {
    id: 'Menu.Profiles',
    defaultMessage: 'Error.Menu.Profiles',
    description: "Profiles' menu label",
  },
  roles: {
    id: 'Menu.Roles',
    defaultMessage: 'Error.Menu.Roles',
    description: "Roles' menu label",
  },
  settings: {
    id: 'Menu.Settings',
    defaultMessage: 'Error.Menu.Settings',
    description: "Settings' menu label",
  },
  specialties: {
    id: 'Menu.Specialties',
    defaultMessage: 'Error.Menu.Specialties',
    description: "Specialties' menu label",
  },
  security: {
    id: 'Menu.Security',
    defaultMessage: 'Error.Menu.Security',
    description: "Security' menu label",
  },
  Sepa: {
    id: 'Menu.Sepa',
    defaultMessage: 'Error.Menu.Sepa',
  },
  surveys: {
    id: 'Menu.Surveys',
    defaultMessage: 'Error.Menu.Surveys',
    description: "Surveys' menu label",
  },
  users: {
    id: 'Menu.Users',
    defaultMessage: 'Error.Menu.Users',
    description: "Users' menu label",
  },
  vouchers: {
    id: 'Menu.Vouchers',
    defaultMessage: 'Error.Menu.Vouchers',
    description: "Vouchers' menu label",
  },
  voucherGroups: {
    id: 'Menu.VoucherGroups',
    defaultMessage: 'Error.Menu.VoucherGroups',
    description: "Voucher groups' menu label",
  },
  marketing: {
    id: 'Menu.Marketing',
    defaultMessage: 'Error.Menu.Marketing',
  },
  marketingSearch: {
    id: 'Menu.MarketingSearch',
    defaultMessage: 'Error.MenuMarketing',
  },
  webservices: {
    id: 'Menu.Webservices',
    defaultMessage: 'Error.Menu.Webservices',
    description: "Webservices' menu label",
  },
  webServicesStatus: {
    id: 'Menu.WebServicesStatus',
    defaultMessage: 'Error.Menu.WebServicesStatus',
  },
  webservicesConfig: {
    id: 'Menu.WebservicesConfig',
    defaultMessage: 'Error.Menu.WebservicesConfig',
    description: 'Webservices config menu label',
  },
  fileImports: {
    id: 'Menu.FileImports',
    defaultMessage: 'Error.Menu.FileImports',
    description: "fileimports' menu label",
  },
  fileImportClients: {
    id: 'Menu.FileImportClients',
    defaultMessage: 'Error.Menu.FileImportClients',
    description: 'fileimportClients menu label',
  },
  fileImportConfigs: {
    id: 'Menu.FileImportConfigs',
    defaultMessage: 'Error.Menu.FileImportConfigs',
    description: 'FileimportConfigs menu label',
  },
  hangfire: {
    id: 'Menu.Hangfire',
    defaultMessage: 'Error.Menu.Hangfire',
    description: 'Hangfire menu label',
  },
  partnerOperations: {
    id: 'Menu.PartnerOperations',
    defaultMessage: 'Error.Menu.PartnerOperations',
    description: 'PartnerOperations menu label',
  },
  partnerOperationsDeposit: {
    id: 'Menu.partnerOperationsDeposit',
    defaultMessage: 'Error.Menu.partnerOperationsDeposit',
    description: 'partnerOperationsDeposit menu label',
  },
  billing: {
    id: 'Menu.Billing',
    defaultMessage: 'Menu.Billing',
    description: 'Billing menu label',
  },
  feeNotes: {
    id: 'Menu.FeeNotes',
    defaultMessage: 'Error.Menu.FeeNotes',
    description: 'feeNotes menu label',
  },
});

export default messages;
