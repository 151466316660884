import { defineMessages } from 'react-intl';

export default defineMessages({
  Title: {
    id: 'Screens.FileImports.List.Title',
    defaultMessage: 'Error.Screens.FileImports.List.Title',
    description: 'FileImports page title',
  },
  Code: {
    id: 'Screens.FileImports.List.Code',
    defaultMessage: 'Error.Screens.FileImports.List.Code',
  },
  LastFirstName: {
    id: 'Screens.FileImports.List.LastFirstName',
    defaultMessage: 'Error.Screens.FileImports.List.LastFirstName',
  },
  Birthdate: {
    id: 'Screens.FileImports.List.Birthdate',
    defaultMessage: 'Error.Screens.FileImports.List.Birthdate',
  },
  Partner: {
    id: 'Screens.FileImports.List.Partner',
    defaultMessage: 'Error.Screens.FileImports.List.Partner',
  },
  Entity: {
    id: 'Screens.FileImports.List.Entity',
    defaultMessage: 'Error.Screens.FileImports.List.Entity',
  },
  ProductAssociated: {
    id: 'Screens.FileImports.List.ProductAssociated',
    defaultMessage: 'Error.Screens.FileImports.List.ProductAssociated',
  },
  Firstname: {
    id: 'Screens.FileImports.List.Firstname',
    defaultMessage: 'Error.Screens.FileImports.List.Firstname',
  },
  Lastname: {
    id: 'Screens.FileImports.List.Lastname',
    defaultMessage: 'Error.Screens.FileImports.List.Lastname',
  },
  PlaceholderPartners: {
    id: 'Screens.FileImports.List.Placeholder.Partners',
    defaultMessage: 'Error.Screens.FileImports.List.Placeholder.Partners',
  },
  NoElement: {
    id: 'Shared.NoElement',
    defaultMessage: 'Error.Shared.NoElement',
  },
});
