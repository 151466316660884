import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { object, array } from 'yup';

import CsvStore from '../stores/CsvStore';
import crossIco from '../assets/crossIco.svg';
import { csvColumns } from '../constants/csvColumns';
import { csvEnums } from '../constants/csvEnums';

const validationSchema = object().shape(csvColumns);

const arrayValidation = array().of(validationSchema);

const checkFields = (fileName) => new Promise((resolve) => {
    arrayValidation.validate(CsvStore.filesToUpload[fileName].data)
        .then()
        .catch((error) => {
            console.log(error);
        });
    resolve('hey');
});

const ListItem = props => {
    const { fileName } = props;
    const { name, errors, data } = CsvStore.filesToUpload[fileName];
    const hasErrors = (errors && errors.length > 0);

    const FileStatus = () => (!hasErrors)
        ? <>
            <h6><span className='badge bg-dffff8 text-success'>Fichier prêt</span></h6>
            <h6 className='text-success'>Le fichier est prêt à être déposé.</h6>
        </>
        : <>
            <h6><span className='badge bg-danger'>Fichier non conforme</span></h6>
            {errors.length} Erreur{(errors.length > 1) ? 's' : ''}
            <p className='m-0'>{csvEnums[errors[0].type]}</p>
            <h6 className='text-danger'>Veuillez sélectionner un nouveau fichier.</h6>
        </>;

    useEffect(() => {
        //to remove
        if (false) {
            checkFields(fileName).then((e) => {
                console.log(e);
            });
        }
    }, []);

    return <div className='row'>
        <div className="col position-relative">
            <div className="ratio ratio-1x1 position-absolute w-2rem right-0 top--1rem">
                <button className='rounded-circle border-0 btn-danger' onClick={() => CsvStore.dropFile(fileName)}>
                    <img alt='crossIco' className='img-fluid ' src={crossIco} />
                </button>
            </div>
            <div className="container-fluid border rounded py-3">
                <h6 className="text-color-2d3047">{name}</h6>
                <FileStatus />
            </div>
        </div>
    </div>
}

const List = () => <div className='d-grid my-4 gap-4'>
    {
        Object.keys(CsvStore.filesToUpload).map(
            (fileName, index) => {
                return <ListItem key={index} fileName={fileName} />
            }
        )
    }
</div>;


export const FilesList = observer(
    () => (Object.keys(CsvStore.filesToUpload).length > 0)
        ? <List />
        : null
);
