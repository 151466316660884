import { defineMessages } from 'react-intl';

export default defineMessages({
  ToastFail: {
    id: 'Toasts.Request.Fail',
    defaultMessage: 'Error.Toasts.Request.Fail',
  },
  Title: {
    id: 'Screens.Doctors.Create.Title',
    defaultMessage: 'Error.Screens.Doctors.Create.Title',
  },
  SuccessTitle: {
    id: 'Message.Success',
    defaultMessage: 'Error.Message.Success',
  },
  SuccessBody: {
    id: 'Screens.Doctors.Create.SuccesBody',
    defaultMessage: 'Error.Screens.Doctors.Create.SuccesBody',
  },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
  },
});
