import * as yup from 'yup';
import messages from './messages';

export default (intl) =>
  yup.object().shape({
    code: yup.string().required('Shared.FieldRequired'),
    label: yup.string().required('Shared.FieldRequired'),
    refOrganizationConfig: yup
      .string()
      .required(intl.formatMessage({ ...messages.FieldRequired })),
  });
