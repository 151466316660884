import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { PageHeader, RoleManager, Spinner } from 'components';
import { Col, Row, Badge } from 'reactstrap';

import { api, useFetch, urlSchema } from 'utils';
import messages from './messages';

const Details = () => {
  const intl = useIntl();
  const { specialtyId } = useParams();
  const [specialty, isSpecialtyLoading] = useFetch(
    api.admin.getSpecialty(specialtyId),
  );

  if (isSpecialtyLoading) return <Spinner size='lg' />;

  return (
    <>
      <PageHeader>
        <FormattedMessage
          {...messages.SpecialtyDetailsTitle}
          values={{ nameSpecialty: specialty.code }}
          tagName='h1'
        />
        <RoleManager entity='Specialties' right='CRUD'>
          <Link
            data-tracking='SpecialtiesDetailsBtnEdit'
            className='btn btn-primary'
            to={`${urlSchema.specialties.update}/${specialtyId}`}
          >
            {intl.formatMessage({ ...messages.ButtonEdit })}
          </Link>
        </RoleManager>
        <RoleManager entity='Doctors' right='Reader'>
          <Link
            to={`${urlSchema.doctors.list}?specialties=${specialtyId}`}
            className='btn btn-primary ml-2'
            rel='noopener noreferrer'
          >
            {intl.formatMessage({
              ...messages.ReadDoctors,
            })}
          </Link>
        </RoleManager>
      </PageHeader>
      <Row>
        <Col md='6'>
          <section className='section'>
            <div className='section__body'>
              <div className='model'>
                <figure className='model__logo'>
                  <img src={`data:image/*;base64, ${specialty.rawFile}`} />
                </figure>
                <div className='model__actions'>
                  <Badge color={specialty.valid ? 'success' : 'danger'}>
                    <div className='d-flex align-items-center'>
                      {specialty.valid ? (
                        <>
                          <span className='mr-1'>
                            {intl.formatMessage(messages.Valid)}
                          </span>
                          <i aria-hidden className='uil uil-check-circle' />
                        </>
                      ) : (
                        <>
                          <span className='mr-1'>
                            {intl.formatMessage(messages.Invalid)}
                          </span>
                          <i aria-hidden className='uil uil-times-circle' />
                        </>
                      )}
                    </div>
                  </Badge>
                  <Badge color={specialty.active ? 'success' : 'danger'}>
                    <div className='d-flex align-items-center'>
                      {specialty.valid ? (
                        <>
                          <span className='mr-1'>
                            {intl.formatMessage(messages.Active)}
                          </span>
                          <i aria-hidden className='uil uil-check-circle' />
                        </>
                      ) : (
                        <>
                          <span className='mr-1'>
                            {intl.formatMessage(messages.Inactive)}
                          </span>
                          <i aria-hidden className='uil uil-times-circle' />
                        </>
                      )}
                    </div>
                  </Badge>
                  <Badge color={specialty.exclusive ? 'success' : 'danger'}>
                    <div className='d-flex align-items-center'>
                      {specialty.exclusive ? (
                        <>
                          <span className='mr-1'>
                            {intl.formatMessage(messages.Exclusive)}
                          </span>
                          <i aria-hidden className='uil uil-check-circle' />
                        </>
                      ) : (
                        <>
                          <span className='mr-1'>
                            {intl.formatMessage(messages.Unexclusive)}
                          </span>
                          <i aria-hidden className='uil uil-times-circle' />
                        </>
                      )}
                    </div>
                  </Badge>
                </div>

                <div className='model__property'>
                  <h3 className='model__title'>
                    {intl.formatMessage({ ...messages.SpecialtyCode })}
                  </h3>
                  <p className='model__value'>{specialty.code}</p>
                </div>
                <div className='model__property'>
                  <h3 className='model__title'>
                    {intl.formatMessage({ ...messages.SpecialtyName })}
                  </h3>
                  <p className='model__value'>{specialty.label}</p>
                </div>
                <div className='model__property'>
                  <h3 className='model__title'>
                    {intl.formatMessage({
                      ...messages.OrganizationConfigCode,
                    })}
                  </h3>
                  <p className='model__value'>
                    {specialty.organizationConfigCode}
                  </p>
                </div>
              </div>
            </div>
          </section>
        </Col>
      </Row>
    </>
  );
};

export default Details;
