import { defineMessages } from 'react-intl';

export default defineMessages({
  UpdateOfferTitle: {
    id: 'Offers.Update.Title',
    defaultMessage: 'Error.Offers.Update.Title',
    description: 'Title of partner creation page',
  },
  FailedUploadLogoBody: {
    id: 'Offers.Update.UploadLogo.FailBody',
    defaultMessage: 'Error.Offers.Update.UploadLogo.FailBody',
  },
  CreateOfferSubtitle: {
    id: 'Screens.Offers.CreateOffer.Subtitle',
    defaultMessage: 'Error.Screens.Offers.CreateOffer.Subtitle',
    description: 'Subtitle of partner creation page',
  },
  CTA: {
    id: 'Screens.Offers.CreateOffer.CTA',
    defaultMessage: 'Error.Screens.Offers.CreateOffer.CTA',
  },
  SuccessTitle: {
    id: 'Message.Success',
    defaultMessage: 'Error.Message.Success',
    description: 'Toast title in case of success',
  },
  SuccessBody: {
    id: 'Offers.Create.SuccessBody',
    defaultMessage: 'Error.Offers.Create.SuccessBody',
    description: 'Toast body in case of success',
  },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
    description: 'Toast title in case of Error',
  },
  FailBody: {
    id: 'Offers.Create.FailBody',
    defaultMessage: 'Error.Offers.Create.FailBody',
    description: 'Toast body in case of error',
  },
  ToastErrorDescription: {
    id: 'Toasts.Offers.Error.Description',
    defaultMessage: 'Error.Toasts.Offers.Error.Description',
  },
  ToastErrorCode: {
    id: 'Toasts.Offers.Error.Code',
    defaultMessage: 'Error.Toasts.Offers.Error.Code',
  },
  ToastDescriptionDuplicateCode: {
    id: 'Toasts.Offers.Error.Description.DuplicateCode',
    defaultMessage: 'Error.Toasts.Offers.Error.Description.DuplicateCode',
  },
});
