import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonDownload: {
    id: 'Buttons.Download',
    defaultMessage: 'Error.Buttons.Download',
  },
  NoElement: {
    id: 'Shared.NoElement',
    defaultMessage: 'Error.Shared.NoElement',
  },
  Subtitle: {
    id: 'Screens.Sepa.List.Subtitle',
    defaultMessage: 'Error.Screens.Sepa.List.Subtitle',
  },
  Title: {
    id: 'Menu.Sepa',
    defaultMessage: 'Error.Menu.Sepa',
  },
});
