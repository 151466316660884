import { defineMessages } from 'react-intl';

export default defineMessages({
  identityValidationAlert: {
    id: 'Screens.Dialogues.Create.IdentityValidation.Alert',
    defaultMessage: 'Error.Screens.Dialogues.Create.IdentityValidation.Alert'
  },
  AddressValidAlert: {
    id: 'Screens.Dialogues.Create.AddressValid.Alert',
    defaultMessage: 'Error.Screens.Dialogues.Create.AddressValid.Alert'
  },
  PatientFileCompletedAlert: {
    id: 'Screens.Dialogues.Create.PatientFileCompletedAlert.Alert',
    defaultMessage:
      'Error.Screens.Dialogues.Create.PatientFileCompletedAlert.Alert'
  }
});
