import React from 'react';
import { useIntl } from 'react-intl';
import { Formik, Form } from 'formik';
import { Button, Spinner } from 'reactstrap';
import { historyShape } from 'react-router-props';

import { ApiError, PageHeader } from 'components';
import { PartnerOperator } from 'models';
import { service } from 'services';
import { api, sendToast, urlSchema, useFetch } from 'utils';
import validationSchema from 'models/PartnerOperator/validationSchema';
import Clients from './Clients';
import initialValues from './initialValues';
import messages from './messages';
import { CodeOrganization, ToastTypes } from '../../../constants';

const Create = ({ history }) => {
  const { formatMessage } = useIntl();
  const [universes, areUniversesLoading] = useFetch(api.universes.getListItems);

  const onSubmit = (values, { setSubmitting }) => {
    const formattedValues = values;
    formattedValues.infosAdmin.selectedClients = values.selectedClients;

    service
      .post(api.partnerOperators.post, formattedValues)
      .then(({ data }) => {
        if (data.succeeded === false) {
          setSubmitting(false);
          sendToast(ToastTypes.error, {
            body: formatMessage({ ...messages.ToastFail }),
          });
          return;
        }
        sendToast(ToastTypes.success, {
          body: formatMessage({ ...messages.ToastSuccess }),
        });
        history.push(urlSchema.administrators.list);
      })
      .catch((error) => {
        sendToast(ToastTypes.error, {
          header: formatMessage({ ...messages.ToastFail }),
          body: <ApiError {...{ error }} />,
        });
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <h1>{formatMessage({ ...messages.Title })}</h1>
      </PageHeader>
      <section className='section'>
        <div className='section__body'>
          {areUniversesLoading ? (
            <Spinner color='primary' size='lg' />
          ) : (
            <Formik
              initialValues={{
                ...initialValues,
                refUniverse: universes.find(
                  (el) => el.code === CodeOrganization.MD,
                ).id,
              }}
              onSubmit={onSubmit}
              validationSchema={validationSchema(formatMessage)}
            >
              {(formikProps) => (
                <Form>
                  <div className='row'>
                    <div className='col-6'>
                      <div className='model__property'>
                        <h3 className='model__title'>
                          {formatMessage({ ...messages.CodeCompany })}
                        </h3>
                        <p className='model__value'>
                          {formikProps.values.infosAdmin.codeCompany}
                        </p>
                      </div>
                      <div className='model__property'>
                        <h3 className='model__title'>
                          {formatMessage({ ...messages.RefUniverse })}
                        </h3>
                        <p className='model__value'>
                          {
                            universes.find(
                              (el) => el.id === formikProps.values.refUniverse,
                            ).label
                          }
                        </p>
                      </div>
                      <PartnerOperator />
                    </div>
                    <div className='col-6'>
                      <Clients />
                    </div>
                  </div>
                  <Button color='primary' size='lg' type='submit'>
                    {formatMessage({ ...messages.ButtonCreate })}
                  </Button>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </section>
    </>
  );
};

Create.propTypes = { history: historyShape.isRequired };

export default Create;
