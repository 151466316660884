import { defineMessages } from 'react-intl';

export default defineMessages({
  Name: {
    id: 'Model.WebService.Name',
    defaultMessage: 'Error.Model.WebService.Name',
  },
  ClassName: {
    id: 'Model.WebService.ClassName',
    defaultMessage: 'Error.Model.WebService.ClassName',
  },
  Valid: {
    id: 'Shared.Valid',
    defaultMessage: 'Error.Shared.Valid',
  },
});
