const AUTH_URL = window.__env.REACT_APP_API_AUTH_URL;
const CLIENT_ID = 'MD.React.Admin';

const oidcConfiguration = {
  api_auth_url: AUTH_URL,
  authority: `/login`,
  client_id: CLIENT_ID,
  idleTime: window.__env.REACT_APP_IDLE_TIME,
  platform: 'ADMIN',
  providers: [
    {
      client: {
        client_id: CLIENT_ID,
        client_secret: 'secret',
      },
      grant_type: 'authentication',
      hasToSendClient: true,
      hasValidation: false,
      originalRealmURL: new URL(AUTH_URL),
      providerId: 'md',
      realmURL: new URL(AUTH_URL),
      response_type: 'code id_token',
      scope:
        'openid given_name family_name role md_profile profile md_website MD.WebAPI.Dialogue MD.WebAPI.Identity offline_access',
    },
  ],
};

export default oidcConfiguration;
