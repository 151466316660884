import styled, { css } from 'styled-components';

const ListHeader = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space(2)};
    text-transform: uppercase;
    ${theme.fontSize(theme.fontSizeSm(), theme.fontWeightBold)};
  `}
`;

const ListBody = styled.ul`
  ${({ theme }) => css`
    padding-left: 0;
    list-style-type: none;
  `}
`;

const List = styled.div`
  ${({ theme, type }) => css`
    margin-bottom: ${theme.space(3)};
    border-radius: ${theme.borderRadiusSm};

    ${type === 'principal'
      ? `
      background-color: ${theme.color('blue-lightest')};
      border: 1px solid ${theme.blue};

      ${ListHeader}{
        color: ${theme.blue};
        border-bottom: 1px solid ${theme.blue};
      };

      li {
        border-bottom: 1px solid ${theme.color('blue-lightest')};
        &:last-of-type {
          border-bottom: none;
        }
      }
      `
      : ``}
    ${type === 'valid'
      ? `
        border: 1px solid ${theme.grayShade(300)};

        ${ListHeader}{
          color: ${theme.color('gray-font')};
          border-bottom: 1px solid ${theme.grayShade(300)};
        };
        li {
        border-bottom: 1px solid ${theme.grayShade(300)};
        &:last-of-type {
          border-bottom: none;
        }
      }
      `
      : ``}
      ${type === 'invalid'
      ? `
          border: 1px solid ${theme.color('danger')};
          background-color: ${theme.color('red-lightest')};

        ${ListHeader}{
          color: ${theme.color('danger')};
          border-bottom: 1px solid ${theme.color('danger')};
        };
        li {
        border-bottom: 1px solid ${theme.color('danger')};
        &:last-of-type {
          border-bottom: none;
        }
      }
      `
      : ``}
  `};
`;

const ProductsListWrapper = styled.ul`
  ${({ theme }) => css`
    list-style-type: none;
    padding-left: 0;
  `}
`;

ProductsListWrapper.ListHeader = ListHeader;
ProductsListWrapper.List = List;
ProductsListWrapper.ListBody = ListBody;

export default ProductsListWrapper;
