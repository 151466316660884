import React from 'react';
import {
  Link,
  useHistory,
  useLocation,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { useIntl } from 'react-intl';
import classnames from 'classnames';

import {
  PageHeader,
  RoleManager,
  Section,
  SectionBody,
  Translation,
} from '../../../components';
import { urlSchema } from '../../../utils';

import PatientsMdList from './PatientsMdList';
import PatientsNhiList from './PatientsNhiList';

const List = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <>
      <PageHeader>
        <h1>
          <Translation id='Menu.Patients' />
        </h1>
        <RoleManager entity='Patients' right='CRUD'>
          <Link
            data-tracking='PatientsListBtnCreate'
            className='btn btn-primary'
            to={urlSchema.patients.create}
          >
            {formatMessage({
              id: 'Buttons.Create',
              defaultMessage: 'Error.Buttons.Create',
            })}
          </Link>
        </RoleManager>
      </PageHeader>
      <Section>
        <SectionBody>
          <ul className='nav nav-tabs custom-nav-tabs'>
            <li className='nav-item'>
              <a
                className={classnames('nav-link custom-tab', {
                  active: location.pathname.includes('/MD'),
                })}
                onClick={() => history.push(urlSchema.patients.listMD)}
                role='button'
              >
                <Translation id='Patients.MDIdentity' />
              </a>
            </li>
            <RoleManager entity='NHIs' right='Reader'>
              <li className='nav-item'>
                <a
                  className={classnames('nav-link custom-tab', {
                    active: location.pathname.includes('/NHI'),
                  })}
                  onClick={() => history.push(urlSchema.patients.listNHI)}
                  role='button'
                >
                  <Translation id='Patients.NHIsIdentity' />
                </a>
              </li>
            </RoleManager>
          </ul>
          <div className='tab-content'>
            <Switch>
              <Route
                path={urlSchema.patients.listMD}
                component={PatientsMdList}
              />
              <Route
                path={urlSchema.patients.listNHI}
                component={PatientsNhiList}
              />
              <Route exact path={urlSchema.patients.list}>
                <Redirect to={urlSchema.patients.listMD} />
              </Route>
            </Switch>
          </div>
        </SectionBody>
      </Section>
    </>
  );
};

export default List;
