import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { matchShape } from 'react-router-props';
import { Col, Row } from 'reactstrap';

import {
  LinksList,
  PageHeader,
  RoleManager,
  Section,
  SectionBody,
  SectionHeader,
  Spinner,
} from 'components';
import { api, urlSchema, useFetch } from 'utils';
import messages from './messages';

const Read = ({ match }) => {
  const { formatMessage } = useIntl();
  const { profileId } = match.params;
  const [profile, isProfileLoading] = useFetch(
    `${api.profiles.get}/${profileId}`,
  );

  return (
    <>
      <PageHeader>
        <h1>
          {formatMessage({ ...messages.Title }, { label: profile.code || '' })}
        </h1>
        <RoleManager entity='Profiles' right='CRUD'>
          <Link
            className='btn btn-primary'
            to={`${urlSchema.profiles.update}/${profileId}`}
          >
            {formatMessage({ ...messages.ButtonEdit })}
          </Link>
        </RoleManager>
      </PageHeader>
      <Row>
        <Col md='5'>
          <Section>
            <SectionBody>
              {isProfileLoading ? (
                <Spinner color='primary' size='lg' />
              ) : (
                <>
                  <div className='model__property'>
                    <h5 className='model__title'>
                      {formatMessage({ ...messages.Label })}
                    </h5>
                    <p className='model__value'>{profile.label}</p>
                  </div>
                  <div className='model__property'>
                    <h5 className='model__title'>
                      {formatMessage({ ...messages.Code })}
                    </h5>
                    <p className='model__value'>{profile.code}</p>
                  </div>
                  <div className='model__property'>
                    <h5 className='model__title'>
                      {formatMessage({ ...messages.RefOrganizationConfig })}
                    </h5>
                    <Link
                      className='model__value'
                      to={`${urlSchema.universes.read}/${profile.idProfile}`}
                    >
                      {profile.organizationConfigCode}
                    </Link>
                  </div>
                  <div className='model__property'>
                    <h5 className='model__title'>
                      {formatMessage({ ...messages.Ranking })}
                    </h5>
                    <p className='model__value'>{profile.ranking}</p>
                  </div>
                </>
              )}
            </SectionBody>
          </Section>
        </Col>
        <Col md='7'>
          <Section>
            <SectionHeader>
              <h2>{formatMessage({ ...messages.Roles })}</h2>
              <RoleManager entity='Profiles' right='CRUD'>
                <Link
                  className='btn btn-outline-primary btn-sm ml-auto'
                  to={`${urlSchema.profiles.editRoles}/${profile.idProfile}`}
                >
                  {formatMessage({ ...messages.ButtonManage })}
                </Link>
              </RoleManager>
            </SectionHeader>
            <SectionBody>
              {isProfileLoading ? (
                <Spinner color='primary' size='lg' />
              ) : profile?.profilesRoles?.length > 0 ? (
                <LinksList
                  color='warning'
                  data={profile.profilesRoles}
                  idKey={(el) => el.idProfileRole}
                  labelKey={(el) => el.roleCode}
                  urlBase={urlSchema.roles.read}
                />
              ) : (
                <p className='m-0 text-secondary'>
                  {formatMessage({ ...messages.NoElement })}
                </p>
              )}
            </SectionBody>
          </Section>
          <Section>
            <SectionHeader>
              <h2>{formatMessage({ ...messages.Products })}</h2>
              <RoleManager entity='Profiles' right='CRUD'>
                <Link
                  className='btn btn-outline-primary btn-sm ml-auto'
                  to={`${urlSchema.profiles.editProducts}/${profile.idProfile}`}
                >
                  {formatMessage({ ...messages.ButtonManage })}
                </Link>
              </RoleManager>
            </SectionHeader>
            <SectionBody>
              {isProfileLoading ? (
                <Spinner color='primary' size='lg' />
              ) : profile?.profilesProducts.length > 0 ? (
                <LinksList
                  areInnerLinks
                  color='info'
                  data={profile.profilesProducts}
                  idKey={(el) => el.refProduct.id}
                  labelKey={(el) => el.refProduct.label}
                  urlBase={urlSchema.products.read}
                />
              ) : (
                <p className='m-0 text-secondary'>
                  {formatMessage({ ...messages.NoElement })}
                </p>
              )}
            </SectionBody>
          </Section>
        </Col>
      </Row>
    </>
  );
};

Read.propTypes = { match: matchShape.isRequired };

export default Read;
