import styled from 'styled-components';

const Item = styled.li`
  ${({ theme }) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${theme.space(3)};

    div {
      &:nth-child(1){
        display: flex;
          flex-direction: column;
          align-items: center;
        img {
          max-width: 100%;
          height: auto;
          display: block;
          border: 1px solid ${theme.color('blue-lightest')};
        }
        button {
          margin-top: ${theme.space(1)};
        }
      }
      &:nth-child(2){
        padding-left: ${theme.space(3)};
    }
    
  `}
`;

const JourneyText = styled.p`
  ${({ theme }) => `
    ${theme.fontSize(theme.fontSizeSm(), theme.fontWeightBold)};
    margin-bottom: 0;
    
  `}
`;

const ProductInfo = styled.p`
  ${({ theme }) => `
    ${theme.fontSize(theme.fontSizeSm(), theme.fontWeightNormal)};
    margin-bottom: 0;
  `}
`;

const ProductText = styled.p`
  ${({ theme }) => `
 
    ${theme.fontSize(theme.fontSizeSm(), theme.fontWeightNormal)};
    margin-bottom: 0;
  `}
`;

const ProductTitle = styled.p`
  ${({ theme }) => `
 
    ${theme.fontSize(theme.fontSizeBase, theme.fontWeightBold)};
    margin-bottom: 0;
  `}
`;

Item.JourneyText = JourneyText;
Item.ProductText = ProductText;
Item.ProductTitle = ProductTitle;
Item.ProductInfo = ProductInfo;

export default Item;
