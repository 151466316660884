import React from 'react';
import { Link } from 'react-router-dom';
import { matchShape } from 'react-router-props';
import { FormattedMessage } from 'react-intl';
import { api, useFetch, urlSchema } from 'utils';
import { Badge, Col, Row } from 'reactstrap';
import moment from 'moment';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-javascript';

import { LinksList, PageHeader, RoleManager, Spinner } from 'components';
import { useTheme } from 'styled-components';

const Read = ({ match }) => {
  const { surveyId } = match.params;
  const theme = useTheme();

  const [survey, isSurveyLoading] = useFetch(
    api.questionnaires.getDetails(surveyId),
  );

  const [profiles, areProfilesLoading] = useFetch(api.profiles.get);
  const [products, areProductsLoading] = useFetch(api.admin.getProducts);

  return (
    <>
      <PageHeader>
        <FormattedMessage
          tagName='h1'
          id='screens_questionnaire_detail_title'
          defaultMessage='error_screens_questionnaire_detail_title'
          values={{ name: survey?.title }}
        />
        <RoleManager entity='Products' right='CRUD'>
          <Link
            className='btn btn-primary'
            to={`${urlSchema.surveyUpdate}/${survey.idQuestionnaire}`}
          >
            <FormattedMessage
              id='shared_edit'
              defaultMessage='error_shared_edit'
            />
          </Link>
        </RoleManager>
      </PageHeader>
      <Row>
        <Col lg='8'>
          <div className='section'>
            {isSurveyLoading ? (
              <Spinner color='primary' size='lg' />
            ) : (
              <>
                <div className='section__header'>
                  <h3>
                    <Badge color={survey.valid ? 'success' : 'danger'}>
                      {survey.valid ? (
                        <>
                          <i className='uil uil-check mr-1' aria-hidden />
                          <FormattedMessage id='Shared.Valid' />
                        </>
                      ) : (
                        <>
                          <i className='uil uil-multiply mr-1' aria-hidden />
                          <FormattedMessage id='Shared.Invalid' />
                        </>
                      )}
                    </Badge>
                  </h3>
                  <h3>
                    <Badge
                      color={survey.isGenerateQuestion ? 'success' : 'danger'}
                    >
                      {survey.isGenerateQuestion ? (
                        <>
                          <i className='uil uil-check mr-1' aria-hidden />
                          <FormattedMessage
                            id='screens_questionnaire_isGenerateQuestion_true'
                            defaultMessage='error_screens_questionnaire_isGenerateQuestion_true'
                          />
                        </>
                      ) : (
                        <>
                          <i className='uil uil-multiply mr-1' aria-hidden />
                          <FormattedMessage
                            id='screens_questionnaire_isGenerateQuestion_false'
                            defaultMessage='error_screens_questionnaire_isGenerateQuestion_false'
                          />
                        </>
                      )}
                    </Badge>
                  </h3>
                </div>
                <div className='section__body'>
                  <div className='model'>
                    <Row>
                      <Col md='12' className='d-flex'>
                        {survey.rawFile ? (
                          <figure className='model__logo'>
                            <img
                              alt={`${survey.label}'s logo`}
                              src={`data:image/*;base64, ${survey.rawFile}`}
                            />
                          </figure>
                        ) : (
                          <div className='model__no-logo'>
                            <FormattedMessage
                              tagName='p'
                              id='Shared.NoLogo'
                              defaultMessage='Error.Shared.NoLogo'
                            />
                          </div>
                        )}
                      </Col>
                      <Col md='12'>
                        <div className='model__property'>
                          <h4 className='model__title'>
                            <FormattedMessage
                              id='shared_title'
                              defaultMessage='error_shared_title'
                            />
                          </h4>
                          <p className='model__value'>{survey?.title}</p>
                        </div>
                        <div className='model__property'>
                          <h4 className='model__title'>
                            <FormattedMessage
                              id='shared_description'
                              error='error_shared_description'
                            />
                          </h4>
                          <p className='model__value'>{survey?.message}</p>
                        </div>
                        <div className='model__property'>
                          <h4 className='model__title'>
                            <FormattedMessage
                              id='screens_questionnaire_creationDate'
                              defaultMessage='error_screens_questionnaire_creationDate'
                            />
                          </h4>
                          <p className='model__value'>
                            {moment(survey?.dateCreation).format('DD/MM/YYYY')}
                          </p>
                        </div>
                        <div className='model__property'>
                          <h4 className='model__title'>
                            <FormattedMessage
                              id='shared_speciality'
                              defaultMessage='error_shared_speciality'
                            />
                          </h4>
                          <p className='model__value'>
                            {survey?.labelSpecialty}
                          </p>
                        </div>
                      </Col>
                      <Col md='12'>
                        <div className='model__property'>
                          <h4 className='model__title'>
                            <FormattedMessage
                              id='screens_questionnaire_JSON_structure_title'
                              defaultMessage='error_screens_questionnaire_JSON_structure_title'
                            />
                          </h4>
                          <div className='form-group'>
                            <AceEditor
                              defaultValue={survey.jsonContent}
                              showGutter
                              showPrintMargin
                              mode='json'
                              name='message-editor'
                              style={{
                                border: `1px solid ${theme.color('border')}`,
                                borderRadius: theme.borderRadius,
                              }}
                              theme='monokai'
                              height='400px'
                              wrapEnabled
                              readOnly
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md='12'>
                        <div className='model__property'>
                          <h4 className='model__title'>
                            <FormattedMessage
                              id='screens_questionnaire_javascript_title'
                              defaultMessage='error_screens_questionnaire_javascript_title'
                            />
                          </h4>
                          <AceEditor
                            defaultValue={survey.jsContent}
                            showGutter
                            showPrintMargin
                            mode='javascript'
                            name='message-editor'
                            style={{
                              border: `1px solid ${theme.color('border')}`,
                              borderRadius: theme.borderRadius,
                            }}
                            theme='monokai'
                            height='150px'
                            wrapEnabled
                            readOnly
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </>
            )}
          </div>
        </Col>
        {/* Products */}
        <Col lg='4'>
          <div className='section'>
            {isSurveyLoading || areProductsLoading ? (
              <Spinner color='primary' size='lg' />
            ) : (
              <>
                <div className='section__header'>
                  <h1>
                    <FormattedMessage
                      id='Model.Profile.Products'
                      defaultMessage='Error.Model.Profile.Products'
                    />
                  </h1>
                </div>
                <div className='section__body'>
                  {survey.questionnairesProducts.length > 0 ? (
                    <LinksList
                      areInnerLinks
                      color='warning'
                      data={survey.questionnairesProducts}
                      idKey={(el) => el.refProduct}
                      labelKey={(elem) =>
                        products.find((el) => el.idProduct === elem.refProduct)
                          .label
                      }
                      urlBase={urlSchema.products.read}
                    />
                  ) : (
                    <p className='text-secondary m-0'>
                      <FormattedMessage id='Shared.NoElement' />
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
          {/* Profiles */}
          <div className='section'>
            {isSurveyLoading || areProfilesLoading ? (
              <Spinner color='primary' size='lg' />
            ) : (
              <>
                <div className='section__header'>
                  <h1>
                    <FormattedMessage
                      id='Buttons.Profiles'
                      defaultMessage='Error.Buttons.Profiles'
                    />
                  </h1>
                </div>
                <div className='section__body'>
                  {survey.questionnairesProfiles.length > 0 ? (
                    <LinksList
                      areInnerLinks
                      color='warning'
                      data={survey.questionnairesProfiles}
                      idKey={(el) => el.refProfile}
                      labelKey={(elem) =>
                        profiles.find((el) => el.idProfile === elem.refProfile)
                          .label
                      }
                      urlBase={urlSchema.profiles.read}
                    />
                  ) : (
                    <p className='text-secondary m-0'>
                      <FormattedMessage id='Shared.NoElement' />
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

Read.propTypes = { match: matchShape.isRequired };

export default Read;
