import React from 'react';
import moment from 'moment';
import { useParams, Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Badge, Col, Row } from 'reactstrap';

import {
  PageHeader,
  RoleManager,
  Section,
  SectionBody,
  Spinner,
} from 'components';

import { api, urlSchema, useFetch } from 'utils';
import { ReadModelWrapper } from 'themes/jupiter/elements';
import messages from './messages';

const Read = () => {
  const { formatMessage } = useIntl();
  const { idVoucher } = useParams();
  const [voucher, isVoucherLoading] = useFetch(api.admin.getVoucher(idVoucher));

  return isVoucherLoading ? (
    <Spinner size='lg' />
  ) : (
    <>
      <PageHeader>
        <FormattedMessage
          {...messages.Title}
          tagName='h1'
          values={{ code: voucher.code }}
        />
        <RoleManager entity='Vouchers' right='CRUD'>
          <Link
            className='btn btn-primary ml-3'
            to={`${urlSchema.vouchersGroupsEdit}/${voucher.refVoucherGroup}?idVoucher=${voucher.idVoucher}`}
          >
            <FormattedMessage {...messages.ButtonEdit} />
          </Link>
        </RoleManager>
      </PageHeader>
      <Row>
        <Col md='8'>
          <Section>
            <SectionBody>
              <h3>
                <Badge color={voucher.valid ? 'success' : 'danger'}>
                  {voucher.valid
                    ? formatMessage(messages.Valid)
                    : formatMessage(messages.Invalid)}
                </Badge>
              </h3>
              <ReadModelWrapper>
                <Row>
                  <Col md='6'>
                    <ReadModelWrapper.PropertyWrapper>
                      <ReadModelWrapper.PropertyTitle>
                        <FormattedMessage {...messages.Code} />
                      </ReadModelWrapper.PropertyTitle>
                      <ReadModelWrapper.PropertyValue>
                        {voucher.code}
                      </ReadModelWrapper.PropertyValue>
                    </ReadModelWrapper.PropertyWrapper>
                  </Col>
                  <Col md='6'>
                    <ReadModelWrapper.PropertyWrapper>
                      <ReadModelWrapper.PropertyTitle>
                        <FormattedMessage {...messages.VoucherGroupName} />
                      </ReadModelWrapper.PropertyTitle>
                      <ReadModelWrapper.PropertyValue>
                        {voucher.refVoucherGroupName}
                      </ReadModelWrapper.PropertyValue>
                    </ReadModelWrapper.PropertyWrapper>
                  </Col>
                  <Col md='6'>
                    <ReadModelWrapper.PropertyWrapper>
                      <ReadModelWrapper.PropertyTitle>
                        <FormattedMessage
                          {...messages.VoucherGroupDescription}
                        />
                      </ReadModelWrapper.PropertyTitle>
                      <ReadModelWrapper.PropertyValue>
                        {voucher.refVoucherGroupDescription !== ''
                          ? voucher.refVoucherGroupDescription
                          : '-'}
                      </ReadModelWrapper.PropertyValue>
                    </ReadModelWrapper.PropertyWrapper>
                  </Col>
                  <Col md='6'>
                    <ReadModelWrapper.PropertyWrapper>
                      <ReadModelWrapper.PropertyTitle>
                        <FormattedMessage {...messages.RelatedProduct} />
                      </ReadModelWrapper.PropertyTitle>
                      <ReadModelWrapper.PropertyValue>
                        {voucher.productDisplayLabel}
                      </ReadModelWrapper.PropertyValue>
                    </ReadModelWrapper.PropertyWrapper>
                  </Col>
                  <Col md='6'>
                    <ReadModelWrapper.PropertyWrapper>
                      <ReadModelWrapper.PropertyTitle>
                        <FormattedMessage {...messages.VoucherUsed} />
                      </ReadModelWrapper.PropertyTitle>
                      <ReadModelWrapper.PropertyValue>
                        {voucher.usedCount} /{' '}
                        {voucher.useLimit !== null ? voucher.useLimit : '∞'}
                      </ReadModelWrapper.PropertyValue>
                    </ReadModelWrapper.PropertyWrapper>
                  </Col>
                  <Col md='6'>
                    <ReadModelWrapper.PropertyWrapper>
                      <ReadModelWrapper.PropertyTitle>
                        <FormattedMessage {...messages.VoucherDateExpiration} />
                      </ReadModelWrapper.PropertyTitle>
                      <ReadModelWrapper.PropertyValue>
                        <FormattedMessage
                          {...messages.ExpirationDate}
                          values={{
                            date: moment(voucher.dateExpiration).format('LL'),
                          }}
                        />
                      </ReadModelWrapper.PropertyValue>
                    </ReadModelWrapper.PropertyWrapper>
                  </Col>
                  <Col md='6'>
                    <ReadModelWrapper.PropertyWrapper>
                      <ReadModelWrapper.PropertyTitle>
                        <FormattedMessage {...messages.VoucherDateCreation} />
                      </ReadModelWrapper.PropertyTitle>
                      <ReadModelWrapper.PropertyValue>
                        <FormattedMessage
                          {...messages.CreationDate}
                          values={{
                            date: moment(voucher.dateCreation).format('LL'),
                          }}
                        />
                      </ReadModelWrapper.PropertyValue>
                    </ReadModelWrapper.PropertyWrapper>
                  </Col>
                </Row>
              </ReadModelWrapper>
            </SectionBody>
          </Section>
        </Col>
      </Row>
    </>
  );
};

export default Read;
