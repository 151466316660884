import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';
import ButtonWrapper from './elements';

const Button = ({
  children,
  disabled,
  isProcessing,
  label,
  type,
  customLabel,
  ...props
}) => {
  return (
    <ButtonWrapper disabled={disabled || isProcessing} type={type} {...props}>
      {isProcessing && (
        <ButtonWrapper.SpinnerWrapper>
          <Spinner size='sm' />
        </ButtonWrapper.SpinnerWrapper>
      )}
      <ButtonWrapper.Flex isProcessing={isProcessing}>
        {label && (
          <span>
            <FormattedMessage
              id={`${customLabel ? label : `Buttons.${label}`}`}
              defaultMessage={`${
                customLabel ? `Error.${label}` : `Error.Buttons.${label}`
              }`}
            />
          </span>
        )}
        {children}
      </ButtonWrapper.Flex>
    </ButtonWrapper>
  );
};

Button.defaultProps = {
  children: null,
  disabled: false,
  isProcessing: false,
  type: 'submit',
  customLabel: false,
};

Button.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  isProcessing: PropTypes.bool,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  customLabel: PropTypes.bool,
};

export default Button;
