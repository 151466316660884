import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Field, FieldArray, Form, Formik } from 'formik';
import { useDebouncedCallback } from 'use-debounce';
import { Col, Collapse, Container, Input, Row } from 'reactstrap';
import cn from 'classnames';
import qs from 'qs';

import { service } from 'services';
import {
  api,
  removeKeysWithEmptyString,
  sendToast,
  urlSchema,
  useFetch,
} from 'utils';
import { Button, Checkbox, PageHeader, RoleManager, Spinner } from 'components';

import { ToastTypes } from '../../../constants';
import messages from './messages';

const List = () => {
  const intl = useIntl();
  const [actus, setActus] = useState([]);
  const [isCollapseOpen, setCollapseIsOpen] = useState(false);
  const [profiles, areProfilesLoading] = useFetch(api.profiles.get);
  const debouncedCallback = useDebouncedCallback((fn) => {
    fn();
  }, 350);
  const [isLoading, setIsLoading] = useState(true);

  const getResults = (values = {}, callback) => {
    const parameters = qs.stringify(removeKeysWithEmptyString(values), {
      skipNulls: true,
    });

    setIsLoading(true);
    service
      .get(`${api.actualities.getActualities}?${parameters}`)
      .then(({ data }) => {
        setActus(data);
        setIsLoading(false);
        if (typeof callback === 'function') callback();
      })
      .catch(() => {
        sendToast(ToastTypes.error, {
          header: '',
          body: (
            <FormattedMessage
              id='Toasts.Actualities.Fail'
              defaultMessage='Error.Toasts.Actualities.Fail'
            />
          ),
        });
      });
  };

  const onSubmit = (values, props) => {
    getResults(values, () => {
      props.setSubmitting(false);
    });
  };

  useEffect(() => {
    getResults({
      isValid: true,
      searchString: '',
      profiles: [],
    });
  }, []);

  return (
    <>
      <PageHeader>
        <FormattedMessage {...messages.Title} tagName='h1' />
        <RoleManager entity='Actualities' right='CRUD'>
          <Link
            to={urlSchema.actualities.create}
            className='btn btn-primary btm-sm'
          >
            <FormattedMessage {...messages.ButtonCreate} />
          </Link>
        </RoleManager>
      </PageHeader>
      <section className='section'>
        <header className='section__header'>
          <Formik
            initialValues={{ searchString: '', profiles: [], isValid: true }}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isSubmitting, values }) => (
              <Form className='w-100'>
                <Row className='mb-3'>
                  <Col className='md-6'>
                    <Field
                      className='form-control'
                      name='searchString'
                      type='text'
                    >
                      {({ field }) => {
                        const { onChange } = field;
                        return (
                          <Input
                            {...field}
                            id='search-string'
                            onChange={(e) => {
                              onChange(e);
                              debouncedCallback(() => handleSubmit());
                            }}
                            placeholder={intl.formatMessage({
                              ...messages.Search,
                            })}
                          />
                        );
                      }}
                    </Field>
                  </Col>
                  <Col md='1'>
                    <Field name='isValid'>
                      {({ field, form }) => (
                        <Checkbox
                          {...field}
                          checked={form.values.isValid}
                          id='is-valid'
                          onChange={(e) => {
                            field.onChange(e);
                            handleSubmit();
                          }}
                        >
                          {intl.formatMessage({
                            ...messages.Valid,
                          })}
                        </Checkbox>
                      )}
                    </Field>
                  </Col>
                  <Col md='2'>
                    <Button
                      color='primary'
                      type='button'
                      label='Profiles'
                      onClick={() => {
                        setCollapseIsOpen(!isCollapseOpen);
                      }}
                    />
                  </Col>
                  <Col md='auto' className='ml-auto'>
                    <Button
                      color='primary'
                      data-tracking='ActualitesBtnSearch'
                      isProcessing={isSubmitting}
                      type='submit'
                      label='Research'
                    />
                  </Col>
                </Row>
                <Collapse isOpen={isCollapseOpen}>
                  <Container fluid>
                    <FieldArray
                      name='profiles'
                      render={({ push, remove }) => (
                        <>
                          {profiles.map((profile) => {
                            return (
                              <button
                                className={cn('combined-btn', {
                                  'combined-btn--success':
                                    values.profiles.findIndex(
                                      (id) => id === profile.idProfile,
                                    ) >= 0,
                                })}
                                key={profile.idProfile}
                                onClick={() => {
                                  const index = values.profiles.findIndex(
                                    (id) => {
                                      return id === profile.idProfile;
                                    },
                                  );
                                  if (index >= 0) {
                                    remove(index);
                                  } else push(profile.idProfile);
                                }}
                                type='button'
                              >
                                <span>{profile.code}</span>
                                {values.profiles.findIndex(
                                  (el) => el === profile.idProfile,
                                ) >= 0 ? (
                                  <i className='uil uil-check' />
                                ) : (
                                  <i className='uil uil-plus' />
                                )}
                              </button>
                            );
                          })}
                        </>
                      )}
                    />
                  </Container>
                </Collapse>
              </Form>
            )}
          </Formik>
        </header>
        <div className='section__body'>
          {isLoading || areProfilesLoading ? (
            <Spinner color='primary' size='lg' />
          ) : actus.length > 0 ? (
            <div className='row row-cols-1 row-cols-md-4'>
              {actus.map((item) => {
                return (
                  <div className='col mb-4'>
                    <div className='card h-100 shadow-sm'>
                      <div className='card-header'>
                        <h5
                          className='card-title mb-0 text-truncate'
                          data-toggle='tooltip'
                          title={item.title}
                        >
                          {item.title}
                        </h5>
                        <small className='text-muted font-italic'>
                          <FormattedMessage id='Shared.CreatedOn' />{' '}
                          {moment(item.dateCreation).format('DD/MM/YYYY')}
                        </small>
                      </div>
                      <div className='card-body'>
                        <p
                          className='card-text text-truncate'
                          data-toggle='tooltip'
                          title={item.body}
                        >
                          {item.body}
                        </p>
                        <Link
                          to={`${urlSchema.actualities.read}/${item.idActuality}`}
                          className='m-0 w-100 btn btn-sm btn-primary'
                        >
                          <FormattedMessage id='Buttons.Show' />
                        </Link>
                      </div>
                      <div className='card-footer d-flex flex-column'>
                        <small className='text-success font-italic'>
                          <FormattedMessage id='Shared.PublishedOn' />{' '}
                          {moment(item.dateStart).format('DD/MM/YYYY')}
                        </small>
                        <small className='text-danger font-italic'>
                          <FormattedMessage id='Shared.ExpiresOn' />{' '}
                          {moment(item.dateEnd).format('DD/MM/YYYY')}
                        </small>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <FormattedMessage id='Shared.NoResultFound' tagName='p' />
          )}
        </div>
      </section>
    </>
  );
};

export default List;
