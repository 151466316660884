import { defineMessages } from 'react-intl';

import messages from '../messages';

export default defineMessages({
  ...messages,
  ButtonEdit: { id: 'Buttons.Edit', defaultMessage: 'Error.Buttons.Edit' },
  ButtonGeneratePassword: {
    id: 'Buttons.GeneratePassword',
    defaultMessage: 'Error.Buttons.GeneratePassword',
  },
  ButtonManage: {
    id: 'Buttons.Manage',
    defaultMessage: 'Error.Buttons.Manage',
  },
  Invalid: { id: 'Model.Invalid', defaultMessage: 'Error.Model.Invalid' },
  Title: {
    id: 'Screens.PartnerOperators.Read.Title',
    defaultMessage: 'Error.Screens.PartnerOperators.Read.Title',
  },
  ToastSuccess: {
    id: 'Toasts.Users.ForgotPassword.Success',
    defaultMessage: 'Error.Toasts.Users.ForgotPassword.Success',
  },
  ToastFail: {
    id: 'Toasts.Users.ForgotPassword.Fail',
    defaultMessage: 'Error.Toasts.Users.ForgotPassword.Fail',
  },
  Valid: { id: 'Model.Valid', defaultMessage: 'Error.Model.Valid' },
});
