import { defineMessages } from 'react-intl';

export default defineMessages({
  Title: {
    id: 'Screens.TemplateMessages.Update.Title',
    defaultMessage: 'Error.Screens.TemplateMessages.Update.Title',
  },
  ToastFail: {
    id: 'Toasts.Request.Fail',
    defaultMessage: 'Error.Toasts.Request.Fail',
  },
  ToastSuccess: {
    id: 'Toasts.TemplateMessages.Update.Success',
    defaultMessage: 'Error.Toasts.TemplateMessages.Update.Success',
  },
});
