import styled, { css } from 'styled-components';
import { Label } from 'reactstrap';

const LabelWrapper = styled(Label)`
  ${({ required, theme }) => css`
    ${required &&
    `&::after {
        content: "*";
        color: ${theme.color('danger')};
        margin-left: ${theme.space(1)};
      }`}
  `}
`;

export default LabelWrapper;
