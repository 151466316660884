import React from 'react';
import { FormattedDate as RIFormattedDate } from 'react-intl';
import Proptypes from 'prop-types';
import moment from 'moment-timezone';

import { Settings } from '../../constants';

const FormattedDate = ({ origin, value, weekdayLong, ...props }) => {
  const parisDate = moment.tz(value, Settings.DB_TIMEZONE);
  const localFromParis = parisDate.clone().local();

  return (
    <RIFormattedDate
      value={localFromParis}
      weekday={weekdayLong ? 'long' : 'short'}
      day='numeric'
      month='short'
      year='numeric'
      hour='2-digit'
      minute='2-digit'
      {...props}
    />
  );
};

FormattedDate.defaultProps = {
  origin: undefined,
  weekdayLong: false,
};

FormattedDate.propTypes = {
  origin: Proptypes.string,
  weekdayLong: Proptypes.bool,
  value: Proptypes.string.isRequired,
};

export default FormattedDate;
