import { defineMessages } from 'react-intl';

export default defineMessages({
  Placeholder: {
    id: 'Screens.Doctors.EditSpecialties.Placeholder',
    defaultMessage: 'Error.Screens.Doctors.EditSpecialties.Placeholder',
  },
  Title: {
    id: 'Screens.Doctors.EditSpecialties.Title',
    defaultMessage: 'Error.Screens.Doctors.EditSpecialties.Title',
  },
  ToastFail: {
    id: 'Shared.Toast.Update.Fail',
    defaultMessage: 'Error.Shared.Toast.Update.Fail',
  },
  ToastSuccess: {
    id: 'Shared.Toast.Update.Success',
    defaultMessage: 'Error.Shared.Toast.Update.Success',
  },
});
