import React from 'react';
import PropTypes from 'prop-types';

import SectionNavWrapper from './elements';

const SectionNav = ({ children }) => {
  return <SectionNavWrapper>{children}</SectionNavWrapper>;
};

SectionNav.propTypes = {
  children: PropTypes.node.isRequired
};

export default SectionNav;
