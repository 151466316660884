import React from 'react';
import * as yup from 'yup';
import { FormattedMessage } from 'react-intl';
import messages from '../message';

export default yup.object().shape({
  description: yup.string(),
  prefixe: yup.string()
    .min(2, <FormattedMessage {...messages['validation.prefixe.min']} />)
    .max(5, <FormattedMessage {...messages['validation.prefixe.max']} />)
    .required(<FormattedMessage {...messages['validation.prefixe.required']} />),
  numberOfVouchers: yup.number().min(1, <FormattedMessage {...messages['validation.numberOfVouchers']} />).required(),
  dateExpiration: yup.date()
    .min(new Date(),  <FormattedMessage {...messages['validation.dateExpiration']} />)
    .required(<FormattedMessage {...messages['validation.dateExpiration']} />),
});
