import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Field, useFormikContext } from 'formik';
import { Col, Collapse, Row, Spinner } from 'reactstrap';

import {
  Checkbox,
  FormInput,
  FormInputFeedback,
  FormIntlTelInput,
  FormRadioButton,
  FormPlacesAutoComplete,
  Translation,
} from '../../components';
import { api, useFetch } from '../../utils';
// TODO: Cette constante doit être disponible via l'API
import { CodeNotificationType } from '../../constants';

const Patient = () => {
  const { formatMessage } = useIntl();
  const { values } = useFormikContext();
  const [countries, areCountriesLoading] = useFetch(api.identity.getCountries);
  const [isAddressCollapseOpened, setAddressCollapseOpened] = useState(false);
  // declaring messages with more than 3 occurences
  const messages = {
    Address: { id: 'Shared.Address', defaultMessage: 'Error.Shared.Address' },
  };

  return (
    <>
      <Row>
        <Col md='6'>
          <FormInput
            id='firstname'
            name='firstname'
            label={<Translation id='Shared.Firstname' />}
            type='text'
            required
          />
        </Col>
        <Col md='6'>
          <FormInput
            id='lastname'
            label={<Translation id='Shared.Lastname' />}
            name='lastname'
            type='text'
            required
          />
        </Col>
      </Row>
      <FormInput
        id='birthdate'
        label={<Translation id='Shared.Birthdate' />}
        name='birthdate'
        type='date'
        required
      />
      <div className='mb-3'>
        <p className='font-weight-bold text-secondary m-0 required'>
          <Translation id='Shared.Gender' />
        </p>
        <div>
          <div className='form-check form-check-inline'>
            <Field
              check
              component={FormRadioButton}
              id='gender-unknown'
              inline
              inputValue='0'
              label={<Translation id='Shared.Gender.Unknown' />}
              name='gender'
              required
            />
          </div>
          <div className='form-check form-check-inline'>
            <Field
              check
              component={FormRadioButton}
              id='gender-male'
              inline
              inputValue='1'
              label={<Translation id='Shared.Man' />}
              name='gender'
            />
          </div>
          <div className='form-check form-check-inline'>
            <Field
              check
              component={FormRadioButton}
              id='gender-woman'
              inline
              inputValue='2'
              label={<Translation id='Shared.Woman' />}
              name='gender'
              required
            />
          </div>
        </div>
        <FormInputFeedback name='gender' />
      </div>
      <FormInput
        id='email'
        label={<Translation id='Shared.Email' />}
        name='email'
        placeholder={<Translation id='Shared.Email' />}
        required
        type='email'
      />
      <Row>
        <Col md='6'>
          <FormIntlTelInput
            labelId='Shared.Phone'
            name='phoneNumber'
            id='phoneNumber'
          />
        </Col>
        <Col md='6'>
          <FormIntlTelInput
            labelId='Shared.Mobile'
            name='mobileNumber'
            id='mobileNumber'
            required
          />
        </Col>
      </Row>
      <Field
        component={FormPlacesAutoComplete}
        disabled={isAddressCollapseOpened}
        label={formatMessage(messages.Address)}
        name='address1'
        placeholder={formatMessage(messages.Address)}
        required
      />
      <FormInput
        id='address2'
        label={<Translation id='Shared.AdditionalAddress' />}
        name='address2'
        placeholder={formatMessage({
          id: 'Shared.AdditionalAddress',
          defaultMessage: 'Error.Shared.AdditionalAddress',
        })}
        type='text'
      />
      <button
        type='button'
        className='btn btn-outline-primary btn-sm mb-3 d-inline-flex alig-items-center gap-1'
        onClick={() => setAddressCollapseOpened(!isAddressCollapseOpened)}
      >
        <span>
          <Translation id='Screens.Patients.Create.PatientInformation.ShowCompleteAdress' />
        </span>
        <i aria-hidden className='uil uil-angle-down' />
      </button>
      <Collapse isOpen={isAddressCollapseOpened}>
        <Row>
          <Col md='12'>
            <FormInput
              label={<Translation id='Shared.Address' />}
              placeholder={<Translation id='Shared.Address' />}
              name='address1'
              required
            />
          </Col>
          <Col md='6'>
            <FormInput
              label={
                <Translation id='Screens.Patients.Create.PatientInformation.ManualZipCode' />
              }
              placeholder={
                <Translation id='Screens.Patients.Create.PatientInformation.ManualZipCode' />
              }
              name='zipCode'
              required
            />
          </Col>
          <Col md='6'>
            <FormInput
              label={
                <Translation id='Screens.Patients.Create.PatientInformation.ManualCity' />
              }
              placeholder={
                <Translation id='Screens.Patients.Create.PatientInformation.ManualCity' />
              }
              name='city'
              required
            />
          </Col>
        </Row>
        {areCountriesLoading ? (
          <Spinner color='primary' />
        ) : (
          <div className='form-group'>
            <label htmlFor='codeCountry' className='required'>
              <Translation id='Screens.Patients.Create.PatientInformation.ManualCountry' />
            </label>
            <Field
              as='select'
              className='form-control'
              id='codeCountry'
              name='codeCountry'
            >
              {countries.map((country) => (
                <option key={country.label} value={country.code}>
                  {country.label}
                </option>
              ))}
            </Field>
          </div>
        )}
      </Collapse>
      <div className='form-group'>
        <Field name='valid'>
          {({ field }) => (
            <Checkbox {...field} checked={values.valid} id='valid'>
              <Translation id='Screens.Patients.Create.PatientInformation.ValidAccount' />
            </Checkbox>
          )}
        </Field>
        <FormInputFeedback name='valid' />
      </div>
      <div className='bg-light mb-3 p-3'>
        <p className='font-weight-bold m-0 required text-secondary'>
          <Translation id='Screens.Patients.Create.PatientInformation.NotificationPreferance' />
        </p>
        <div>
          {Object.keys(CodeNotificationType).map((entry) => (
            <div className='form-check form-check-inline' key={entry}>
              <Field
                check
                component={FormRadioButton}
                id={`notification-${entry}`}
                inline
                inputValue={entry}
                label={
                  <Translation
                    id={`Screens.Patients.Create.PatientInformation.Notification.${entry}`}
                  />
                }
                name='notification'
              />
            </div>
          ))}
        </div>
        <FormInputFeedback name='notification' />
      </div>
      <div className='form-group'>
        <Field name='infosPatient.newsletter'>
          {({ field }) => (
            <Checkbox
              {...field}
              checked={values.infosPatient.newsletter}
              id='newsletterAcceptance'
            >
              <Translation id='Screens.Patients.Create.PatientInformation.NewsletterAppliance' />
            </Checkbox>
          )}
        </Field>
        <FormInputFeedback name='infosPatient.newsletter' />
      </div>
    </>
  );
};

export default Patient;
