import React, { useState, useEffect } from 'react';
import { Col, Row, FormGroup } from 'reactstrap';
import { FastField, Field } from 'formik';
import { useIntl } from 'react-intl';

import {
  Checkbox,
  FormInput,
  FormInputFeedback,
  FormSelect,
  Spinner,
} from 'components';

import { api, useFetch } from 'utils';

const Actualities = () => {
  const { formatMessage } = useIntl();
  const [products, areProductsLoading] = useFetch(api.admin.getProducts);

  const [data, setData] = useState();

  const handleAddItem = (newItem) => {
    setData([newItem, ...products]);
  };

  const myNewValue = {
    code: formatMessage({
      id: 'Model.EmptyProduct',
      defaultMessage: 'Error.Model.EmptyProduct',
    }),
    label: formatMessage({
      id: 'Model.EmptyProduct',
      defaultMessage: 'Error.Model.EmptyProduct',
    }),
  };

  useEffect(() => {
    handleAddItem(myNewValue);
  }, [areProductsLoading]);

  return (
    <>
      <FormInput
        Component={FastField}
        id='title'
        label={formatMessage({
          id: 'Screens.Actuality.Title',
          defaultMessage: 'Screens.Actuality.Title',
        })}
        name='title'
        type='text'
        required
      />
      <FormInput
        Component={FastField}
        id='body'
        label={formatMessage({
          id: 'Screens.Actuality.Resume',
          defaultMessage: 'Screens.Actuality.Resume',
        })}
        name='body'
        type='textarea'
        required
      />
      <FormInput
        Component={FastField}
        id='url'
        label={formatMessage({
          id: 'Shared.Link',
          defaultMessage: 'Shared.Link',
        })}
        name='url'
        type='text'
      />
      <Row>
        <Col md='6'>
          <FormInput
            Component={FastField}
            id='dateStart'
            label={formatMessage({
              id: 'Screens.Actuality.PublicationDate',
              defaultMessage: 'Screens.Actuality.PublicationDate',
            })}
            name='dateStart'
            type='date'
          />
        </Col>
        <Col md='6'>
          <FormInput
            Component={FastField}
            id='dateEnd'
            label={formatMessage({
              id: 'Models.Product.ExpirationDate',
              defaultMessage: 'Models.Product.ExpirationDate',
            })}
            name='dateEnd'
            type='date'
          />
        </Col>
      </Row>
      {areProductsLoading ? (
        <Spinner />
      ) : (
        <FormGroup>
          <Field id='refProduct' name='refProduct'>
            {({ field, meta, form }) => (
              <FormSelect
                placeholder={formatMessage({
                  id: 'Screens.Profiles.EditProducts.Placeholder',
                  defaultMessage: 'Screens.Profiles.EditProducts.Placeholder',
                })}
                hasError={Boolean(meta.touched && meta.error)}
                label={formatMessage({
                  id: 'Screens.FileImports.List.ProductAssociated',
                  defaultMessage: 'Screens.FileImports.List.ProductAssociated',
                })}
                meta={meta}
                name={field.name}
                onBlur={() => form.setFieldTouched(field.name, true)}
                onChange={(opt) => {
                  form.setFieldValue(field.name, opt.value);
                }}
                options={data.map((el) => {
                  return {
                    code: el.idProduct,
                    label: el.code,
                    value: el.idProduct,
                  };
                })}
              />
            )}
          </Field>
        </FormGroup>
      )}
      <FormInput
        id='ranking'
        type='number'
        name='ranking'
        label={formatMessage({
          id: 'Model.Role.Ranking',
          defaultMessage: 'Model.Role.Ranking',
        })}
      />
      <div className='mb-3'>
        <Field name='valid'>
          {({ field, form }) => (
            <Checkbox {...field} checked={form.values.valid} id='valid'>
              {formatMessage({
                id: 'Screens.Actualities.List.Valid',
                defaultMessage: "'Screens.Actualities.List.Valid'",
              })}
            </Checkbox>
          )}
        </Field>
        <FormInputFeedback name='valid' />
      </div>
    </>
  );
};

export default Actualities;
