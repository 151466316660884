export default {
  name: '',
  endPoint: '',
  certificatePassword: '',
  userAgentCode: '',
  authentificationLogin: '',
  authentificationPassword: '',
  codeOk: '',
  codeKo: '',
  codeResigned: '',
  requestType: '',
  userMembershipCode: '',
  userFirstName: '',
  userLastName: '',
  userBirthDate: '',
  certificate: '',
};
