import React from 'react';
import { ListWrapper } from '../../themes/jupiter/elements';
import {
  PageHeader,
  Section,
  SectionBody,
  Translation,
} from '../../components';

import { Table } from './Table';
import { Header } from './Header';
import { ValidationModal } from './Modal';

export const IdentityValidationsNhi = () => {
  return (
    <>
      <PageHeader>
        <Translation id='Screens.IdentityValidationsIns.Title' tagName='h1' />
      </PageHeader>
      <Section>
        <SectionBody>
          <ListWrapper>
            <section className='section mb-0'>
              <Header />
              <Table />
            </section>
          </ListWrapper>
        </SectionBody>
      </Section>
      <ValidationModal />
    </>
  );
};
