import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonCreate: {
    id: 'Buttons.Create',
    defaultMessage: 'Error.Buttons.Create',
  },
  Profiles: {
    id: 'Model.Admin.Profiles',
    defaultMessage: 'Error.Model.Admin.Profiles',
  },
  ProfilesPlaceholder: {
    id: 'Components.FormList.ProfilesPlaceholder',
    defaultMessage: 'Error.Components.FormList.ProfilesPlaceholder',
  },
  Title: {
    id: 'Screens.Admins.Create.Title',
    defaultMessage: 'Error.Screens.Admins.Create.Title',
  },
  ToastFail: {
    id: 'Toasts.Actualities.Create.Fail',
    defaultMessage: 'Error.Toasts.Actualities.Create.Fail',
  },
  ToastSuccess: {
    id: 'Toasts.Actualities.Create.Sucess',
    defaultMessage: 'Error.Toasts.Actualities.Create.Sucess',
  },
});
