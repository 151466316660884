import { service } from '../../index';
import { api } from '../../../utils';
import { GenericErrorResponse, IPractitioner } from '../../../types';

export const getPractitioners = async (
  identifier: string,
  identifierType: 0 | 8 = 0,
): Promise<IPractitioner[] | GenericErrorResponse> => {
  try {
    const { data } = await service.get(
      api.directories.getPractitioners(identifier),
      {
        params: { identifierType },
      },
    );

    if (data && 'succeeded' in data && data.succeeded === false) {
      throw new Error(data.errorSet[0].code);
    }

    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};
