import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';

import { PageHeader, Section, SectionBody, Spinner } from 'components';
import { api, useFetch } from 'utils';
import WebServiceItem from './WebServiceItem';
import messages from './messages';

const Status = () => {
  const [checkAll, setCheckAll] = useState(false);
  const [webServices, areWebServicesLoading] = useFetch(
    api.webServices.getValid,
  );

  return (
    <>
      <PageHeader>
        <FormattedMessage tagName='h1' {...messages.Title} />
      </PageHeader>
      <Section>
        <SectionBody>
          <Button
            className='mb-3'
            color='primary'
            onClick={() => {
              setCheckAll(!checkAll);
            }}
            outline
            type='button'
          >
            <FormattedMessage {...messages.ButtonCheckAll} />
          </Button>
          {areWebServicesLoading ? (
            <Spinner color='primary' size='lg' />
          ) : (
            <ul className='list-unstyled'>
              {webServices.map((webService) => (
                <WebServiceItem
                  key={webService.webServiceId}
                  isTriggered={checkAll}
                  {...webService}
                />
              ))}
            </ul>
          )}
        </SectionBody>
      </Section>
    </>
  );
};

export default Status;
