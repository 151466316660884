import { defineMessages } from 'react-intl';

export default defineMessages({
  AccountCreationDate: {
    id: 'Shared.AccountCreationDate',
    defaultMessage: 'Error.Shared.AccountCreationDate',
  },
  AccountInformation: {
    id: 'Model.User.AccountInformation',
    defaultMessage: 'Error.Model.User.AccountInformation',
  },
  Address: {
    id: 'Model.User.Address1',
    defaultMessage: 'Error.Model.User.Address1',
  },
  Available: {
    id: 'Shared.Available',
    defaultMessage: 'Error.Shared.Available',
  },
  Birthdate: {
    id: 'Shared.Birthdate',
    defaultMessage: 'Error.Shared.Birthdate',
  },
  ButtonsManage: {
    id: 'Buttons.Manage',
    defaultMessage: 'Error.Buttons.Manage',
  },
  ButtonsEdit: {
    id: 'Buttons.Edit',
    defaultMessage: 'Error.Buttons.Edit',
  },
  CardCPS: {
    id: 'Screens.Doctors.Read.CardCPS',
    defaultMessage: 'Error.Screens.Doctors.Read.CardCPS',
  },
  ContractType: {
    id: 'Screens.Doctors.Read.ContractType',
    defaultMessage: 'Error.Screens.Doctors.Read.ContractType',
  },
  ElectronicSignature: {
    id: 'Screens.Doctors.Read.ElectronicSignature',
    defaultMessage: 'Error.Screens.Doctors.Read.ElectronicSignature',
  },
  Email: {
    id: 'Shared.Email',
    defaultMessage: 'Error.Shared.Email',
  },
  Error: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
  },
  Invalid: {
    id: 'Shared.Invalid',
    defaultMessage: 'Error.Shared.Invalid',
  },
  FeeNotes: {
    id: 'Menu.FeeNotes',
    defaultMessage: 'Error.Menu.FeeNotes',
  },
  Mobile: {
    id: 'Screens.Doctors.Read.MobileNumber',
    defaultMessage: 'Error.Screens.Doctors.Read.MobileNumber',
  },
  NewPasswordSuccess: {
    id: 'Screens.Doctors.Read.NewPasswordSuccess',
    defaultMessage: 'Error.Screens.Doctors.Read.NewPasswordSuccess',
  },
  NewPasswordError: {
    id: 'Screens.Doctors.Read.NewPasswordError',
    defaultMessage: 'Error.Screens.Doctors.Read.NewPasswordError',
  },
  NoElement: {
    id: 'Shared.NoElement',
    defaultMessage: 'Error.Shared.NoElement',
  },
  Notification: {
    id: 'Model.Admin.Notification',
    defaultMessage: 'Error.Model.Admin.Notification',
  },
  OrdinalQualification: {
    id: 'Screens.Doctors.Read.OrdinalQualification',
    defaultMessage: 'Error.Screens.Doctors.Read.OrdinalQualification',
  },
  PrescriptionPad: {
    id: 'Screens.Doctors.Read.PrescriptionPad',
    defaultMessage: 'Error.Screens.Doctors.Read.PrescriptionPad',
  },
  Profiles: {
    id: 'Model.User.Profiles',
    defaultMessage: 'Error.Model.User.Profiles',
  },
  ProfessionalInformation: {
    id: 'Model.User.ProfessionalInformation',
    defaultMessage: 'Error.Model.User.ProfessionalInformation',
  },
  ReadCPS: {
    id: 'Screens.Doctors.Read.ReadCPS',
    defaultMessage: 'Error.Shared.Valid',
  },
  Specialties: {
    id: 'Menu.Specialties',
    defaultMessage: 'Error.Menu.Specialties',
  },
  Success: {
    id: 'Toasts.Request.Success',
    defaultMessage: 'Error.Toasts.Request.Success',
  },
  Title: {
    id: 'Screens.Doctors.Read.Title',
    defaultMessage: 'Error.Screens.Doctors.Read.Title',
  },
  Unavailable: {
    id: 'Shared.Unavailable',
    defaultMessage: 'Error.Shared.Unavailable',
  },
  UserInformation: {
    id: 'Model.User.UserInformation',
    defaultMessage: 'Error.Model.User.UserInformation',
  },
  Valid: {
    id: 'Shared.Valid',
    defaultMessage: 'Error.Shared.Valid',
  },
});
