import React, { useEffect, useState } from 'react';
import {
  Link,
  useParams,
  useHistory,
  useLocation,
  Switch,
  Route,
} from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';

import { service } from '../../../services';
import { api, sendToast, useFetch, urlSchema } from '../../../utils';
import {
  PageHeader,
  RoleManager,
  SectionHeader,
  Section,
  SectionBody,
  Spinner,
} from '../../../components';

import SurveyList from './SurveysList';
import ReadNhi from './ReadNhi';
import PatientDialoguesList from './PatientDialoguesList';
import UserLogs from './UserLogs';

import HealthPartnersList from './HealthPartnersList';
import PatientInformation from './PatientInformation';
import HealthJourneys from './HealthJourneys';

import { ToastTypes } from '../../../constants';
import messages from './messages';
import { Translation } from '../../../components';

const Read = () => {
  const { locale } = useIntl();
  const { patientId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [refreshPatientInformation, setRefreshPatientInformation] = useState(1);
  const [areAllSubscriptionsRefreshed, setAreAllSubscriptionsRefreshed] =
    useState(false);
  const [refreshHealthPartners, setRefreshHealthPartners] = useState(1);
  const [offers, isOffersLoading] = useFetch(
    api.dialogues.getOffers(patientId, locale.split('-')[0]),
    undefined,
    [refreshHealthPartners, locale],
  );
  const [healthPartners, isHealthPartnersLoading] = useFetch(
    api.manager.getPatientHealthPartners(patientId),
    undefined,
    [refreshHealthPartners],
  );
  const [patient, isPatientLoading] = useFetch(
    api.manager.getPatient(patientId),
    undefined,
    [refreshPatientInformation],
  );
  const [patientSurveys, isPatientSurveysLoading] = useFetch(
    api.dialogues.getSurveys(patientId),
  );
  const [userLogs, isUserLogsLoading] = useFetch(
    api.manager.getUserLogs(patientId),
    undefined,
    [refreshPatientInformation],
  );

  useEffect(() => {
    service
      .post(api.identity.postRefreshAllSubscriptions(patientId))
      .then(() => {
        setAreAllSubscriptionsRefreshed(true);
      })
      .catch(() => {
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.FailTitle} />,
          body: <FormattedMessage {...messages.FailBody} />,
        });
      });
  }, [patientId]);

  return (
    <>
      <PageHeader>
        <FormattedMessage
          {...messages.Title}
          values={{ name: `${patient?.firstname} ${patient?.lastname}` }}
          tagName='h1'
        />
      </PageHeader>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <ul className='nav nav-tabs custom-nav-tabs mb-3 bg-white'>
              <li className='nav-item'>
                <a
                  className={classnames('nav-link p-3 custom-tab', {
                    active: location.pathname.includes('/MD'),
                  })}
                  onClick={() =>
                    history.push(`${urlSchema.patients.readMd}/${patientId}`)
                  }
                  role='button'
                >
                  <Translation id='Patients.MDIdentity' />
                </a>
              </li>
              <RoleManager entity='NHIs' right='Reader'>
                <li className='nav-item'>
                  <a
                    className={classnames('nav-link p-3 custom-tab', {
                      active: location.pathname.includes('/NHI'),
                    })}
                    onClick={() =>
                      history.push(`${urlSchema.patients.readNHI}/${patientId}`)
                    }
                    role='button'
                  >
                    <Translation id='Patients.NHIsIdentity' />
                  </a>
                </li>
              </RoleManager>
            </ul>
            <div className='tab-content'>
              <Switch>
                <Route path={urlSchema.patients.readMd}>
                  <div className='tab-pane active'>
                    <div className='row'>
                      <div className='col-md-7'>
                        <Section>
                          <SectionBody>
                            {isPatientLoading || isUserLogsLoading ? (
                              <Spinner size='md' />
                            ) : (
                              <>
                                <div className='d-flex justify-content-between mb-3'>
                                  <h1>
                                    <Translation id='Patients.MDIdentity' />
                                  </h1>
                                  <RoleManager entity='Patients' right='CRUD'>
                                    <Link
                                      className='btn btn-primary'
                                      to={`${urlSchema.patients.update}/${patient?.idUser}`}
                                    >
                                      <FormattedMessage
                                        {...messages.ButtonEdit}
                                      />
                                    </Link>
                                  </RoleManager>
                                </div>
                                <PatientInformation
                                  logs={userLogs}
                                  refresh={setRefreshPatientInformation}
                                  patient={patient}
                                />
                              </>
                            )}
                          </SectionBody>
                        </Section>
                        <Section>
                          <SectionHeader>
                            <FormattedMessage
                              id='Screens.Patients.Read.Dialogues'
                              defaultMessage='Error.Screens.Patients.Read.Dialogues'
                              tagName='h1'
                            />
                            <Link
                              className='btn btn-outline-primary btn-sm ml-auto'
                              to={urlSchema.patients.newDialogue(patientId)}
                            >
                              <FormattedMessage
                                id='Buttons.ShowHealthfile'
                                defaultMessage='Error.Buttons.ShowHealthfile'
                              />
                            </Link>
                          </SectionHeader>
                          <SectionBody>
                            <PatientDialoguesList patientId={patientId} />
                          </SectionBody>
                        </Section>
                        <Section>
                          <SectionHeader>
                            <FormattedMessage
                              id='Screens.Patients.Read.Surveys'
                              defaultMessage='Error.Screens.Patients.Read.Surveys'
                              tagName='h1'
                            />
                          </SectionHeader>
                          <SectionBody>
                            {isPatientSurveysLoading ? (
                              <Spinner size='md' />
                            ) : (
                              <SurveyList data={patientSurveys} />
                            )}
                          </SectionBody>
                        </Section>
                      </div>
                      <div className='col-md-5'>
                        <Section>
                          <SectionHeader>
                            <FormattedMessage
                              id='Screens.Patients.Read.History'
                              defaultMessage='Error.Screens.Patients.Read.History'
                              tagName='h1'
                            />
                          </SectionHeader>
                          <SectionBody>
                            {isUserLogsLoading ? (
                              <Spinner size='md' />
                            ) : (
                              <UserLogs data={userLogs} />
                            )}
                          </SectionBody>
                        </Section>
                        <Section>
                          <SectionHeader>
                            <FormattedMessage
                              id='Screens.Patients.Read.HealthPartners'
                              defaultMessage='Error.Screens.Patients.Read.HealthPartners'
                              tagName='h1'
                            />
                            <Link
                              className='btn btn-outline-primary btn-sm ml-auto'
                              to={urlSchema.patients.addHealthPartner(
                                patientId,
                              )}
                            >
                              <FormattedMessage
                                id='Buttons.Manage'
                                defaultMessage='Error.Buttons.Manage'
                              />
                            </Link>
                          </SectionHeader>
                          <SectionBody>
                            {!areAllSubscriptionsRefreshed ||
                            isHealthPartnersLoading ? (
                              <Spinner size='md' />
                            ) : (
                              <HealthPartnersList
                                refresh={setRefreshHealthPartners}
                                data={healthPartners}
                              />
                            )}
                          </SectionBody>
                        </Section>
                        <Section>
                          <SectionHeader>
                            <FormattedMessage
                              id='Screens.Patients.Read.HealthJourneys'
                              defaultMessage='Error.Screens.Patients.Read.HealthJourneys'
                              tagName='h1'
                            />
                          </SectionHeader>
                          <SectionBody>
                            {isOffersLoading ? (
                              <Spinner size='md' />
                            ) : (
                              <HealthJourneys data={offers} />
                            )}
                          </SectionBody>
                        </Section>
                      </div>
                    </div>
                  </div>
                </Route>
                <Route path={`${urlSchema.patients.readNHI}/:patientId`}>
                  {!isPatientLoading && (
                    <ReadNhi patientId={patient.patientId} />
                  )}
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Read;
