import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { historyShape, matchShape } from 'react-router-props';
import { Formik, Form } from 'formik';
import { Col, Row } from 'reactstrap';

import { Button, PageHeader, Section, SectionBody, Spinner } from 'components';
import { Profile } from 'models';
import { service } from 'services';
import { api, sendToast, urlSchema, useFetch } from 'utils';
import validationSchema from 'models/Profile/validationSchema';
import { Methods, ToastTypes } from '../../../constants';
import messages from './messages';

const Update = ({ history, match }) => {
  const intl = useIntl();
  const { profileId } = match.params;
  const [profile, isProfileLoading] = useFetch(
    `${api.profiles.get}/${profileId}`,
  );

  const onSubmit = (values, { setSubmitting }) => {
    service
      .put(api.profiles.put(profileId), values)
      .then(({ data }) => {
        if (typeof data.succeeded !== 'undefined' && !data.succeeded) {
          sendToast(ToastTypes.error, {
            header: <FormattedMessage {...messages.ToastFail} />,
            body: data.errorSet[0].description,
          });
        } else {
          sendToast(ToastTypes.success, {
            header: '',
            body: <FormattedMessage {...messages.ToastSuccess} />,
          });
          history.push(urlSchema.profiles.list);
        }
      })
      .catch(() => {
        sendToast(ToastTypes.error, {
          header: '',
          body: <FormattedMessage {...messages.ToastFail} />,
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <FormattedMessage
          tagName='h1'
          {...messages.Title}
          values={{ label: profile?.label || '' }}
        />
        <Link
          data-tracking='ProfilesBtnEditRoles'
          className='btn btn-primary'
          to={`${urlSchema.profiles.editRoles}/${profileId}`}
        >
          {intl.formatMessage({ ...messages.ButtonEditRoles })}
        </Link>
      </PageHeader>
      <Row>
        <Col md='6'>
          <Section>
            <SectionBody>
              {isProfileLoading ? (
                <Spinner color='primary' size='lg' />
              ) : (
                <Formik
                  initialValues={profile}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema(intl)}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <Profile method={Methods.PUT} />
                      <div className='text-right'>
                        <Button
                          color='primary'
                          isProcessing={isSubmitting}
                          label='Update'
                          size='lg'
                          type='submit'
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </SectionBody>
          </Section>
        </Col>
      </Row>
    </>
  );
};

Update.propTypes = {
  history: historyShape.isRequired,
  match: matchShape.isRequired,
};

export default Update;
