import React from 'react';
import { Formik, Form } from 'formik';
import { FormattedMessage } from 'react-intl';

import { ProductTerm } from 'models';
import { Button, PageHeader } from 'components';
import { service } from 'services';
import { api, urlSchema, sendToast } from 'utils';
import { ButtonsWrapper } from 'themes/jupiter/elements';
import initialValues from './initialValues';
import validationSchema from './validationSchema';
import { ToastTypes } from '../../../constants';
import messages from './messages';

const Create = ({ history }) => {
  const onSubmit = (values, { setSubmitting }) => {
    service
      .post(api.manager.postProductTerms, values)
      .then(({ data }) => {
        if (data.succeeded === false) {
          sendToast(ToastTypes.error, {
            header: <FormattedMessage {...messages.FailTitle} />,
            body: (
              <>
                {data.errorSet.map((error) => (
                  <p>
                    {error.code}: {error.description}
                  </p>
                ))}
              </>
            ),
          });
          setSubmitting(false);
          return;
        }
        sendToast(ToastTypes.success, {
          header: <FormattedMessage {...messages.SuccessTitle} />,
          body: <FormattedMessage {...messages.SuccessBody} />,
        });
        history.push(`${urlSchema.productTerms.read}/${data.idProductTerm}`);
      })
      .catch(({ data }) => {
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.FailTitle} />,
          body: (
            <>
              {data.errorSet.map((error) => (
                <p>
                  {error.code}: {error.description}
                </p>
              ))}
            </>
          ),
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <FormattedMessage {...messages.Title} tagName='h1' />
      </PageHeader>
      <section className='section'>
        <div className='section__body'>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ isSubmitting }) => (
              <Form>
                <ProductTerm />
                <ButtonsWrapper bottom>
                  <Button
                    color='primary'
                    disabled={isSubmitting}
                    isProcessing={isSubmitting}
                    label='Create'
                    size='lg'
                    type='submit'
                  />
                </ButtonsWrapper>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </>
  );
};

export default Create;
