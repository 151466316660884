import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonBack: {
    id: 'Buttons.Back',
    defaultMessage: 'Error.Buttons.Back',
  },
  ButtonEdit: {
    id: 'Buttons.Edit',
    defaultMessage: 'Error.Buttons.Edit',
  },
  Client: {
    id: 'Partners.Client',
    defaultMessage: 'Error.Partners.Client',
    description: "Partner's client",
  },
  IsEligible: {
    id: 'Shared.IsEligible',
    defaultMessage: 'Error.Shared.IsEligible',
  },
  IsNotEligible: {
    id: 'Shared.IsNotEligible',
    defaultMessage: 'Error.Shared.IsNotEligible',
  },
  EligibilityKey: {
    id: 'Partners.EligibilityKey',
    defaultMessage: 'Error.Partners.EligibilityKey',
    description: 'The way a patient wants to log in',
  },
  EligibilityKeyDescription: {
    id: 'Partners.EligibilityKeyDescription',
    defaultMessage: 'Error.Partners.EligibilityKeyDescription',
    description: 'Help to subscribe with the partner',
  },
  Keywords: {
    id: 'Partners.Keywords',
    defaultMessage: 'Error.Partners.Keywords',
  },
  Name: {
    id: 'Partners.Name',
    defaultMessage: 'Error.Partners.Name',
  },
  PhoneNumber: {
    id: 'Partners.PhoneNumber',
    defaultMessage: 'Error.Partners.PhoneNumber',
  },
  Title: {
    id: 'Partners.Read.Title',
    defaultMessage: 'Partners.Read.Title',
  },
});
