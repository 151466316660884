import { defineMessages } from 'react-intl';
import { generateTranslationsObject } from 'utils';

const prefix = 'Model.Admin.';
const keys = [
  'AdditionalAdress',
  'Adress',
  'AdressChoice',
  'AdressChoicePlaceholder',
  'BirthDate',
  'City',
  'CodeCompany',
  'Country',
  'Email',
  'FirstName',
  'LastName',
  'Notification',
  'NotificationEmail',
  'NotificationNone',
  'NotificationPush',
  'NotificationSMS',
  'NotificationSMSEmail',
  'OrganizationConfig',
  'PostalCode',
  'SelectCompany',
  'SelectOrganization',
  'Signature',
  'Valid',
];

export default defineMessages(
  generateTranslationsObject(prefix, keys, {
    ButtonShowAdress: {
      id: 'Buttons.ShowFullAdress',
      defaultMessage: 'Error.Buttons.ShowFullAdress',
    },
    FieldRequired: {
      id: 'Model.FieldRequired',
      defaultMessage: 'Error.Model.FieldRequired',
    },
    InvalidEmail: {
      id: 'Shared.InvalidEmailFormat',
      defaultMessage: 'Error.Shared.InvalidEmailFormat',
    },
  }),
);
