import { makeAutoObservable } from 'mobx';
import _ from 'lodash';

import { service } from 'services';
import { api } from 'utils';

const productsPlaceholder = [
  {
    idProduct: 'xxx',
    code: 'xxx',
    label: 'xxx',
  },
];

const voucherPlaceHolder = {
  dateLastUpdate: 'xx',
  idVoucherGroup: 'xxx',
  description: 'xxx',
  name: 'xxx',
};

const voucherGroupsPlaceHolder = [voucherPlaceHolder];

const voucherDetailsPlaceHolder = [
  {
    code: 'xxx',
    dateCreation: 'xxx',
    dateExpiration: 'xxx',
    dateLastUpdate: 'xxx',
    dateUsed: null,
    idVoucher: 'xxx',
    prefixe: '',
    productTag: null,
    refProduct: 'xxx',
    refProductNavigation: null,
    refVoucherGroup: 'xxx',
    useLimit: null,
    useType: 0,
    usedCount: 0,
    valid: false,
  },
];

const getPageD = (requests, voucherId) =>
  Promise.all(
    requests.map((request) =>
      service.get(request, {
        params: {
          id: voucherId,
        },
      }),
    ),
  );

class voucherGroupData {
  _vouchers = [];

  _voucherInfos = Object.assign(voucherPlaceHolder, {});

  _voucherDetails = Object.assign(voucherDetailsPlaceHolder, {});

  _voucherGroupsDetails = null;

  _voucherGroups = [...voucherGroupsPlaceHolder];

  _products = [...productsPlaceholder];

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Get details of a lot of vouchers
   * @param String voucherId
   */
  async getVoucherGroupsDetails(voucherId) {
    try {
      const result = await service.get(
        api.voucherGroups.getVoucherById(voucherId),
      );
      this._voucherGroupsDetails = result.data;
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Get list of vouchers from a lot
   * @param String voucherId
   */
  async getVouchers(voucherId) {
    try {
      const result = await service.get(
        `${api.voucherGroups.getVoucherById(voucherId)}/Vouchers`,
      );
      this._vouchers = result.data;
    } catch (e) {
      console.error(e);
    }
  }

  retrieveProducts() {
    return service.get(api.products.getListItems).then((results) => {
      this.products = results.data;
    });
  }

  retrieveVoucherInfos(voucherId) {
    const axiosRequests = [
      api.voucherGroups.getVoucherById(voucherId),
      api.voucherGroups.getVoucherById(voucherId) + '/Vouchers',
    ];
    return getPageD(axiosRequests, voucherId).then((results) => {
      this.voucherInfos = results[0].data;
      this.voucherDetails = results[1].data;
    });
  }

  retrieveVoucherList(newParameters) {
    return service
      .get(api.voucherGroups.getList, {
        params: newParameters,
      })
      .then((results) => {
        this.voucherGroups = results.data;
      });
  }

  updateVoucherGroup(params) {
    const {
      idVoucherGroup,
      dateExpiration,
      useType,
      useLimit,
      valid,
      description,
      productTag,
    } = params;
    return service.put(
      api.voucherGroups.getVoucherById(this.voucherInfos.idVoucherGroup),
      {
        IdVoucherGroup: idVoucherGroup,
        DateExpiration: dateExpiration,
        UseType: useType,
        UseLimit: useLimit,
        Valid: valid,
        Description: description,
        ProductTag: productTag,
      },
    );
  }

  createVoucherGroup(params) {
    return service.post(api.voucherGroups.getList, params);
  }

  get products() {
    return this._products;
  }

  get vouchers() {
    return this._vouchers;
  }

  set vouchers(newVouchers) {
    this._vouchers = newVouchers;
  }

  get voucherGroupsDetails() {
    return this._voucherGroupsDetails;
  }

  set voucherGroupsDetails(newVoucherGroupsDetails) {
    this._voucherGroupsDetails = newVoucherGroupsDetails;
  }

  get voucherInfos() {
    return this._voucherInfos;
  }

  get voucherDetails() {
    return this._voucherDetails;
  }

  get voucherGroups() {
    return this._voucherGroups;
  }

  set voucherInfos(infos) {
    this._voucherInfos = infos;
  }

  set voucherDetails(infos) {
    this._voucherDetails = infos;
  }

  set voucherGroups(groups) {
    this._voucherGroups = _.orderBy(
      groups,
      [(group) => group.name?.split(' ').join('').toLowerCase()],
      ['asc'],
    );
  }

  set products(products) {
    this._products = products;
  }
}

export default new voucherGroupData();
