import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'reactstrap';

import { PageHeader, Section, SectionBody, Spinner } from 'components';
import { Partner } from 'models';
import { service } from 'services';
import { api, sendToast, urlSchema, useFetch } from 'utils';
import { Methods, ToastTypes } from '../../../constants';
import messages from './messages';

const Update = () => {
  const { partnerId } = useParams();
  const history = useHistory();
  const [partner, isPartnerLoading] = useFetch(
    api.manager.getPartner(partnerId),
  );
  const [initialValues, setInitialValues] = useState(undefined);

  const onSubmit = (values, { setSubmitting }) => {
    let newValues = values;

    if (
      values.eligiblePartnersResources.length > 0 &&
      !values.eligiblePartnersResources[0].idEligiblePartnersResources
    ) {
      if (
        values.eligiblePartnersResources[0].eligibilityKeyDescription ||
        values.eligiblePartnersResources[0].eligibilityKey
      ) {
        newValues = {
          ...values,
          eligiblePartnersResources: [
            {
              codeLanguage: 'fr',
              eligibilityKey:
                values.eligiblePartnersResources[0].eligibilityKey || '',
              eligibilityKeyDescription:
                values.eligiblePartnersResources[0].eligibilityKeyDescription ||
                '',
              refEligiblePartner: values.idEligiblePartner,
            },
          ],
        };
      }
    }

    service
      .put(api.manager.putPartner, newValues)
      .then(() => {
        sendToast(ToastTypes.success, {
          header: <FormattedMessage {...messages.actionSuccess} />,
          body: (
            <FormattedMessage
              {...messages.actionSuccessResponse}
              values={{
                partnerName: values.name,
              }}
            />
          ),
        });
        setSubmitting(false);
        history.push(`${urlSchema.readPartner}/${partnerId}`);
      })
      .catch(() => {
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.actionError} />,
          body: (
            <FormattedMessage
              {...messages.actionErrorResponse}
              values={{
                partnerName: values.name,
              }}
            />
          ),
        });
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (!isPartnerLoading) {
      setInitialValues(partner);
    }
  }, [isPartnerLoading]);

  if (isPartnerLoading) return <Spinner size='lg' />;

  return (
    <>
      <PageHeader>
        <FormattedMessage
          {...messages.Title}
          values={{
            partnerName: partner.name,
          }}
          tagName='h1'
        />
      </PageHeader>
      <Row>
        <Col md='8' lg='6'>
          <Section>
            <SectionBody>
              <Partner
                initialValues={initialValues}
                isLoading={isPartnerLoading}
                method={Methods.PUT}
                onSubmit={onSubmit}
              />
            </SectionBody>
          </Section>
        </Col>
      </Row>
    </>
  );
};

export default Update;
