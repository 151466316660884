import React from 'react';
import { PropTypes } from 'prop-types';
import { Label } from 'reactstrap';
import { Field } from 'formik';

import FormCellRadioButtonWrapper from './elements';

const FormCellRadioButton = ({
  name,
  label,
  value,
  onClick,
  onChange: customOnChange,
}) => {
  return (
    <Field name={name}>
      {({ field: { onChange, onBlur }, form: { values } }) => (
        <FormCellRadioButtonWrapper isActive={values[name] === value}>
          <Label for={`${name}-${value}`}>
            <input
              className='hidden-input'
              name={name}
              id={`${name}-${value}`}
              type='radio'
              value={value}
              checked={values[name] === value}
              onChange={customOnChange || onChange}
              onBlur={onBlur}
              onClick={onClick}
            />
            <FormCellRadioButtonWrapper.Title>
              {label}
            </FormCellRadioButtonWrapper.Title>
          </Label>
        </FormCellRadioButtonWrapper>
      )}
    </Field>
  );
};

FormCellRadioButton.defaultProps = {
  onClick: null,
  onChange: null,
};

FormCellRadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
};

export default FormCellRadioButton;
