import styled, { css } from 'styled-components';

const FormInputLogoWrapper = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.color('border')};
    border-radius: ${theme.borderRadius};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: ${theme.space(3)};
    max-width: 200px;
    min-height: 224px;
    padding: ${theme.space(3)};
  `}
`;

const ImageWrapper = styled.figure`
  ${({ theme }) => css`
    position: relative;

    button {
      background-color: ${theme.red};
      border-radius: 100%;
      border: 0;
      color: ${theme.white};
      line-height: 20px;
      padding: 0;
      position: absolute;
      right: -10px;
      text-align: center;
      top: -10px;
      width: 20px;
    }

    img {
      border-radius: ${theme.borderRadius};
      height: auto;
      max-width: 100%;
    }
  `}
`;

const InputWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.space(2)};

    i {
      margin-right: ${theme.space(3)};
    }

    label {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  `}
`;

const Loader = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.color('gray-lightest')};
    border-radius: ${theme.borderRadius};
    display: flex;
    justify-content: center;
    min-height: 128px;
    position: relative;
    width: 100%;
  `}
`;

const ProgressBar = styled.div`
  ${({ percentage, theme }) => css`
    width: ${`${percentage}%`};
    transition: ${theme.transitionBase};
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    background-color: ${theme.green};
  `}
`;

FormInputLogoWrapper.ImageWrapper = ImageWrapper;
FormInputLogoWrapper.InputWrapper = InputWrapper;
FormInputLogoWrapper.Loader = Loader;
FormInputLogoWrapper.ProgressBar = ProgressBar;

export default FormInputLogoWrapper;
