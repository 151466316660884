import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Badge, Col, Container, Row } from 'reactstrap';

import {
  LinksList,
  LogoItem,
  PageHeader,
  RoleManager,
  Section,
  SectionBody,
  Spinner,
} from 'components';

import { api, urlSchema, useFetch } from 'utils';
import { OffersListWrapper, OfferItemWrapper } from './elements';
import messages from './messages';

const List = () => {
  const intl = useIntl();
  const [offers, areOffersLoading] = useFetch(api.admin.getOffers);

  return (
    <>
      <PageHeader>
        <OffersListWrapper>
          <FormattedMessage {...messages.Title} tagName='h1' />
          <FormattedMessage {...messages.Subtitle} tagName='p' />
          <RoleManager entity='Offers' right='CRUD'>
            <Link
              data-tracking='OfferListBtnCreate'
              className='btn btn-primary mr-2'
              to={urlSchema.createOffer}
            >
              {intl.formatMessage({ ...messages.ButtonCreate })}
            </Link>
            <Link
              data-tracking='OfferListBtnSort'
              className='btn btn-primary'
              to={urlSchema.sortOffers}
            >
              {intl.formatMessage({ ...messages.ButtonSort })}
            </Link>
          </RoleManager>
        </OffersListWrapper>
      </PageHeader>
      <Section>
        <SectionBody>
          {areOffersLoading ? (
            <Spinner size='lg' />
          ) : offers.length > 0 ? (
            <OffersListWrapper.List>
              {offers.map((offer) => (
                <OfferItemWrapper>
                  <Container fluid>
                    <Row>
                      <Col className='text-center' md='3'>
                        <LogoItem data={offer.logo} title={offer.name} />
                        <Badge color={offer.isValid ? 'success' : 'danger'}>
                          {offer.isValid
                            ? intl.formatMessage({ ...messages.Active })
                            : intl.formatMessage({ ...messages.Inactive })}
                        </Badge>
                      </Col>
                      <Col md='7'>
                        <OfferItemWrapper.IllustratedList>
                          <i
                            aria-hidden
                            className='uil uil-user-md uil--md'
                            title={intl.formatMessage({
                              ...messages.DefaultSpecialty,
                            })}
                          />
                          <div>
                            <a
                              className='btn btn-outline-primary btn-sm'
                              href={`${urlSchema.specialties.read}/${offer.refSpecialty}`}
                            >
                              {offer.specialtyLabel}
                            </a>
                          </div>
                        </OfferItemWrapper.IllustratedList>
                        {offer.products.length > 0 && (
                          <OfferItemWrapper.IllustratedList>
                            <i
                              aria-hidden
                              className='uil uil-shopping-bag uil--md'
                              title={intl.formatMessage({
                                ...messages.Products,
                              })}
                            />
                            <LinksList
                              color='warning'
                              data={offer.products}
                              idKey={(el) => el.refProduct}
                              labelKey={(el) => el.displayLabel}
                              limit={4}
                              urlBase={urlSchema.products.read}
                            />
                          </OfferItemWrapper.IllustratedList>
                        )}
                        {offer.profiles.length > 0 && (
                          <OfferItemWrapper.IllustratedList>
                            <i
                              aria-hidden
                              className='uil uil-users-alt uil--md'
                              title={intl.formatMessage({
                                ...messages.Profiles,
                              })}
                            />
                            <LinksList
                              color='primary'
                              data={offer.profiles}
                              idKey={(el) => el.refProfile}
                              labelKey={(el) => el.displayLabel}
                              limit={4}
                              urlBase={urlSchema.profiles.read}
                            />
                          </OfferItemWrapper.IllustratedList>
                        )}
                      </Col>
                      <Col>
                        <OfferItemWrapper.Actions>
                          <RoleManager entity='Offers' right='CRUD'>
                            <Link
                              aria-label={intl.formatMessage({
                                ...messages.ButtonUpdate,
                              })}
                              title={intl.formatMessage({
                                ...messages.ButtonUpdate,
                              })}
                              to={`${urlSchema.updateOffer}/${offer.id}`}
                            >
                              <i className='uil uil-pen uil--md' aria-hidden />
                            </Link>
                          </RoleManager>
                          <RoleManager entity='Offers' right='Reader'>
                            <Link
                              aria-label={intl.formatMessage({
                                ...messages.ButtonRead,
                              })}
                              title={intl.formatMessage({
                                ...messages.ButtonRead,
                              })}
                              to={`${urlSchema.readOffer}/${offer.id}`}
                            >
                              <i className='uil uil-eye uil--md' aria-hidden />
                            </Link>
                          </RoleManager>
                        </OfferItemWrapper.Actions>
                      </Col>
                    </Row>
                  </Container>
                </OfferItemWrapper>
              ))}
            </OffersListWrapper.List>
          ) : (
            <p className='mb-0 text-center'>
              <FormattedMessage {...messages.NoOffer} />
            </p>
          )}
        </SectionBody>
      </Section>
    </>
  );
};

export default List;
