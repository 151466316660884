import { defineMessages } from 'react-intl';

import { generateTranslationsObject } from 'utils';

const prefix = 'Model.Specialty.';
const keys = [
  'Active',
  'Code',
  'Exclusive',
  'Label',
  'RefOrganizationConfig',
  'Valid',
];

export default defineMessages(
  generateTranslationsObject(prefix, keys, {
    FieldRequired: {
      id: 'Shared.FieldRequired',
      defaultMessage: 'Error.Shared.FieldRequired',
    },
    Translations: {
      id: 'Specialty.Translations',
      defaultMessage: 'Error.Specialty.Translations',
    },
  }),
);
