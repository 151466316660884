const resources = {
  ACCOUNT_BLOCKED: 'Compte bloqué',
  ACCOUNT_UNBLOCKED: 'Compte débloqué',
  ADDRESS_UPDATED: 'Adresse mise à jour',
  IDENTITY_VERIFICATION_MANUAL_UNVALIDATE: 'Identité refusée',
  IDENTITY_VERIFICATION_MANUAL_VALIDATE: 'Identité validée',
  PASSWORD_RENEWAL: 'Mot de passe regénéré',
  PASSWORD_UPDATED: 'Mot de passe mis à jour',
  PREFERENCES_UPDATED: 'Préférences mises à jour',
  PROFILE_UPDATED: 'Profil mis à jour',
  SUBSCRIPTION_ACTIVATION: 'Abonnement réactivé',
  SUBSCRIPTION_CREATION: 'Abonnement ajouté',
  SUBSCRIPTION_EXPIRATION: 'Abonnement expiré',
  USER_CONNEXION: 'Connexion',
  WRONG_PASSWORD: 'Mauvais mot de passe',
  NoLog: 'Aucun historique'
};

export default resources;
