import React from 'react';
import PropTypes from 'prop-types';
import BreadcrumbItem from './BreadcrumbItem';

import BreadcrumbWrapper from './elements';

const Breadcrumb = ({ links }) => {
  return (
    <BreadcrumbWrapper>
      <ul>
        {links.map((element) => (
          <BreadcrumbItem key={element.index} {...element} />
        ))}
      </ul>
    </BreadcrumbWrapper>
  );
};

Breadcrumb.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool.isRequired,
      checked: PropTypes.bool.isRequired,
      index: PropTypes.number.isRequired,
      label: PropTypes.element.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Breadcrumb;
