import React from 'react';
import PropTypes from 'prop-types';

import LabelWrapper from './elements';

const FormLabel = ({ children, ...props }) => (
  <LabelWrapper {...props}>{children}</LabelWrapper>
);

FormLabel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormLabel;
