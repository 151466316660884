import { defineMessages } from 'react-intl';

import { generateTranslationsObject } from 'utils';

const prefix = 'Model.User.';
const keys = [
  'AccountInformation',
  'Blocked',
  'CodeCompany',
  'CreationDate',
  'Email',
  'MobilePhone',
  'Notification',
  'Profiles',
  'RefUniverse',
  'Signature',
  'UserInformation',
];

export default defineMessages(
  generateTranslationsObject(prefix, keys, {
    ButtonEdit: { id: 'Buttons.Edit', defaultMessage: 'Error.Buttons.Edit' },
    ButtonManage: {
      id: 'Buttons.Manage',
      defaultMessage: 'Error.Buttons.Manage',
    },
    ButtonGeneratePassword: {
      id: 'Buttons.GeneratePassword',
      defaultMessage: 'Error.Buttons.GeneratePassword',
    },
    CodeCompany: {
      id: 'Model.Administrator.CodeCompany',
      defaultMessage: 'Error.Model.Administrator.CodeCompany',
    },
    Description: {
      id: 'Model.Administrator.Description',
      defaultMessage: 'Error.Model.Administrator.Description',
    },
    Invalid: { id: 'Model.Invalid', defaultMessage: 'Error.Model.Invalid' },
    Title: {
      id: 'Screens.Admins.Read.Title',
      defaultMessage: 'Error.Screens.Admins.Read.Title',
    },
    ToastSuccess: {
      id: 'Toasts.Users.ForgotPassword.Success',
      defaultMessage: 'Error.Toasts.Users.ForgotPassword.Success',
    },
    ToastFail: {
      id: 'Toasts.Users.ForgotPassword.Fail',
      defaultMessage: 'Error.Toasts.Users.ForgotPassword.Fail',
    },
    Valid: { id: 'Model.Valid', defaultMessage: 'Error.Model.Valid' },
  }),
);
