import { defineMessages } from 'react-intl';

export default defineMessages({
  Active: {
    id: 'Shared.Active',
    defaultMessage: 'Error.Shared.Active',
  },
  ButtonEdit: {
    id: 'Buttons.Edit',
    defaultMessage: 'Error.Buttons.Edit',
    description: 'Button to edit an offer',
  },
  CreationDate: {
    id: 'Model.CreationDate',
    defaultMessage: 'Error.Model.CreationDate',
  },
  CTA: {
    id: 'Offers.CTA',
    defaultMessage: 'Error.Offers.CTA',
    description: "Offer's button text",
  },
  DefaultSpecialty: {
    id: 'Shared.DefaultSpecialty',
    defaultMessage: 'Shared.DefaultSpecialty',
    description: 'Default specialty',
  },
  Description: {
    id: 'Offers.Description',
    defaultMessage: 'Error.Offers.Description',
    description: "Offer's description",
  },
  DetailsOf: {
    id: 'Offers.Details',
    defaultMessage: 'Error.Offers.Details',
    description: 'Details of an offer page title',
  },
  Inactive: {
    id: 'Shared.Inactive',
    defaultMessage: 'Error.Shared.Inactive',
  },
  LastUpdateDate: {
    id: 'Model.LastUpdateDate',
    defaultMessage: 'Error.Model.LastUpdateDate',
  },
  Name: {
    id: 'Offers.Name',
    defaultMessage: 'Error.Offers.Name',
    description: "Offer's name",
  },
  Products: {
    id: 'Menu.Products',
    defaultMessage: 'Error.Menu.Products',
  },
  Profiles: {
    id: 'Menu.Profiles',
    defaultMessage: 'Error.Menu.Profiles',
  },
  Rooms: { id: 'Offers.Rooms', defaultMessage: 'Error.Offers.Rooms' },
  FutureLimit: {
    id: 'Screens.Offers.RoomsInfo.FutureLimit',
    defaultMessage: 'Error.Screens.Offers.RoomsInfo.FutureLimit',
  },
  DutySlotDuration: {
    id: 'Screens.Offers.RoomsInfo.DutySlotDuration',
    defaultMessage: 'Error.Screens.Offers.RoomsInfo.DutySlotDuration',
  },
  ConsultationDuration: {
    id: 'Screens.Offers.RoomsInfo.ConsultationDuration',
    defaultMessage: 'Error.Screens.Offers.RoomsInfo.ConsultationDuration',
  },
  MinBookingNotice: {
    id: 'Screens.Offers.RoomsInfo.MinBookingNotice',
    defaultMessage: 'Error.Screens.Offers.RoomsInfo.MinBookingNotice',
  },
  Day: {
    id: 'Shared.Day',
    defaultMessage: 'Error.Shared.Day'
  },
  Hour: {
    id: 'Shared.Hour',
    defaultMessage: 'Error.Shared.Hour'
  },
  Minute: {
    id: 'Shared.Minute',
    defaultMessage: 'Error.Shared.Minute'
  }
});
