import React from 'react';
import { toast } from 'react-toastify';
import { Toast } from 'components';
import { ToastTypes } from '../constants';

const defaultConfig = {
  position: toast.POSITION.BOTTOM_RIGHT,
  hideProgressBar: true,
  draggable: false,
  autoClose: 7000,
  className: 'toast',
};

const sendToast = (type, messages, options = {}) => {
  const toastType =
    Object.keys(ToastTypes).indexOf(type) !== -1 ? type : ToastTypes.info;

  return toast[toastType](<Toast type={toastType} messages={messages} />, {
    ...defaultConfig,
    ...options,
    className: `toast-${toastType}`,
  });
};

export default sendToast;
