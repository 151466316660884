import React from 'react';
import { Switch } from 'react-router-dom';

import { ProtectedRoute } from 'components';
import { urlSchema } from 'utils';

// Screens
import Create from './Create';
import EditProducts from './EditProducts';
import EditRoles from './EditRoles';
import List from './List';
import Read from './Read';
import Update from './Update';

const routes = [
  { path: urlSchema.profiles.create, component: Create, right: 'CRUD' },
  {
    path: `${urlSchema.profiles.read}/:profileId`,
    component: Read,
    right: 'Reader',
  },
  {
    path: `${urlSchema.profiles.update}/:profileId`,
    component: Update,
    right: 'CRUD',
  },
  {
    path: `${urlSchema.profiles.editRoles}/:profileId`,
    component: EditRoles,
    right: 'CRUD',
  },
  {
    path: `${urlSchema.profiles.editProducts}/:profileId`,
    component: EditProducts,
    right: 'CRUD',
  },
  { path: '/', component: List, right: 'Reader' },
];

const Profiles = () => {
  return (
    <Switch>
      {routes.map((item) => (
        <ProtectedRoute key={item.path} entity='Profiles' {...item} />
      ))}
    </Switch>
  );
};

export default Profiles;
