import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonCopy: {
    id: 'Buttons.Copy',
    defaultMessage: 'Error.Buttons.Copy',
    description: 'Button to copy'
  },
  Copied: {
    id: 'Shared.Copied',
    defaultMessage: 'Error.Shared.Copied',
    description: 'It has been copied'
  }
});
