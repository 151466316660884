import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import { PageHeader, RoleManager, Spinner } from 'components';
import { api, urlSchema, useFetch } from 'utils';
import messages from './messages';

const List = () => {
  const intl = useIntl();
  const [roles, areRolesLoading] = useFetch(api.roles.get);

  return (
    <>
      <PageHeader>
        <FormattedMessage tagName='h1' {...messages.Title} />
        <RoleManager entity='Roles' right='CRUD'>
          <Link
            data-tracking='RolesListBtnCreate'
            className='btn btn-primary'
            to={urlSchema.roles.create}
          >
            <FormattedMessage {...messages.ButtonCreate} />
          </Link>
        </RoleManager>
      </PageHeader>
      <section className='section'>
        <div className='section__body'>
          <header className='research-table__header'>
            <Container fluid>
              <Row>
                <Col>
                  <FormattedMessage tagName='h5' {...messages.Code} />
                </Col>
              </Row>
            </Container>
          </header>
          {areRolesLoading ? (
            <Spinner color='primary' size='lg' />
          ) : (
            <>
              {roles.length > 0 ? (
                <ul className='research-table__results'>
                  {roles.map((role) => (
                    <li key={role.idRole}>
                      <Container fluid>
                        <Row className='align-items-center'>
                          <Col>
                            <h5 className='m-0'>{role.code}</h5>
                            <p className='text-secondary m-0 small'>
                              {role.label}
                            </p>
                          </Col>
                          <Col className='ml-auto research-table__actions'>
                            <RoleManager entity='Roles' right='CRUD'>
                              <Link
                                aria-label={intl.formatMessage({
                                  ...messages.ButtonEdit,
                                })}
                                title={intl.formatMessage({
                                  ...messages.ButtonEdit,
                                })}
                                to={`${urlSchema.roles.update}/${role.idRole}`}
                              >
                                <i aria-hidden className='uil uil-pen' />
                              </Link>
                            </RoleManager>
                            <RoleManager entity='Roles' right='Reader'>
                              <Link
                                aria-label={intl.formatMessage({
                                  ...messages.ButtonRead,
                                })}
                                title={intl.formatMessage({
                                  ...messages.ButtonRead,
                                })}
                                to={`${urlSchema.roles.read}/${role.idRole}`}
                              >
                                <i aria-hidden className='uil uil-eye' />
                              </Link>
                            </RoleManager>
                          </Col>
                        </Row>
                      </Container>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className='text-secondary m-0'>
                  <FormattedMessage {...messages.NoElement} />
                </p>
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default List;
