
import { makeAutoObservable } from 'mobx';
import moment from 'moment-timezone';

class voucherGroupCreateData {
    _form = {
        numberOfVouchers: 0,
        prefixe: '',
        description: '',
        productTag: '',
        refProduct: '',
        dateExpiration: moment().format(),
        useLimit: 1,
        useType: 1,
        valid: false,
    }

    constructor() {
        makeAutoObservable(this);
    }

    get form() {
        return this._form;
    }

    set form(form) {
        this._form = form;
    }
};


export default new voucherGroupCreateData();