import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, useFormikContext } from 'formik';
import { isNil } from 'lodash';

import { service } from 'services';
import { api } from 'utils';
import { FormInputAutosuggest } from 'components';

const getLifenDoctors = async (value) => {
  const response = await service.get(api.identity.getLifenDoctors(value));
  return response.data;
};

const FormLifenInput = ({ name }) => {
  const { errors, initialValues, touched, setFieldValue } = useFormikContext();

  return (
    <div className='d-flex flex-column'>
      <FormInputAutosuggest
        {...{ name }}
        clearOnSelect
        fetchRequest={getLifenDoctors}
        initialValue={
          isNil(initialValues.familyDoctorDetails)
            ? ''
            : `${initialValues.familyDoctorDetails.firstname} ${initialValues.familyDoctorDetails.lastname}`
        }
        inputProperties={{
          invalid: touched?.rpps && errors?.rpps,
          name: 'familyDoctorAutocomplete',
        }}
        labelKey='displayLabel'
        onSuggestionsFetchRequested={() => {
          setFieldValue('rpps', '');
        }}
        onSuggestionSelected={(el) => {
          setFieldValue('rpps', el.rpps);
        }}
        required
      />
      <p>
        <small className='text-danger'>
          <ErrorMessage name='rpps' />
        </small>
      </p>
    </div>
  );
};

FormLifenInput.propTypes = {
  name: PropTypes.string.isRequired,
};

export { FormLifenInput };
