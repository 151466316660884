import { defineMessages } from 'react-intl';

export default defineMessages({
  Title: {
    id: 'Screens.Marketing.Title',
    defaultMessage: 'Error.Screens.Marketing.Title',
  },
  Subtitle: {
    id: 'Screens.Marketing.Subtitle',
    defaultMessage: 'Error.Screens.Marketing.Subtitle',
  },
  DemographicCriteriaTitle: {
    id: 'Screens.Marketing.DemographicCriteria.Title',
    defaultMessage: 'Error.Screens.Marketing.DemographicCriteria.Title',
  },
  DemographicCriteriaAgeFrom: {
    id: 'Screens.Marketing.DemographicCriteria.AgeFrom',
    defaultMessage: 'Error.Screens.Marketing.DemographicCriteria.AgeFrom',
  },
  DemographicCriteriaAgeTo: {
    id: 'Screens.Marketing.DemographicCriteria.AgeTo',
    defaultMessage: 'Error.Screens.Marketing.DemographicCriteria.AgeTo',
  },
  DemographicCriteriaMinorBeneficiaryChecked: {
    id: 'Screens.Marketing.DemographicCriteria.MinorBeneficiaryChecked',
    defaultMessage:
      'Error.Screens.Marketing.DemographicCriteria.MinorBeneficiaryChecked',
  },
  UsageCriteriaTitle: {
    id: 'Screens.Marketing.UsageCriteria.Title',
    defaultMessage: 'Error.Screens.Marketing.UsageCriteria.Title',
  },
  UsageCriteriaOnlyNewsletterChecked: {
    id: 'Screens.Marketing.UsageCriteria.OnlyNewsletterChecked',
    defaultMessage:
      'Error.Screens.Marketing.UsageCriteria.OnlyNewsletterChecked',
  },
  ConsultationsNumber: {
    id: 'Screens.Marketing.UsageCriteria.ConsultationsNumber',
    defaultMessage: 'Error.Screens.Marketing.UsageCriteria.ConsultationsNumber',
  },
  RangeConsultationDate: {
    id: 'Screens.Marketing.UsageCriteria.RangeConsultationDate',
    defaultMessage:
      'Error.Screens.Marketing.UsageCriteria.RangeConsultationDate',
  },
  RangeSignupDate: {
    id: "Screens.Marketing.UsageCriteria.RangeSignupDate",
    defaultMessage: "Error.Screens.Marketing.UsageCriteria.RangeSignupDate",
  },
  ContactExportTitle: {
    id: 'Screens.Marketing.UsageCriteria.ContactExport.Title',
    defaultMessage: 'Error.Screens.Marketing.UsageCriteria.ContactExport.Title',
  },
  ContactExportNumberContactFound: {
    id: 'Screens.Marketing.UsageCriteria.ContactExport.NumberContactFound',
    defaultMessage:
      'Error.Screens.Marketing.UsageCriteria.ContactExport.NumberContactFound',
  },
  ContactExportFolderLabel: {
    id: 'Screens.Marketing.UsageCriteria.ContactExport.FolderId',
    defaultMessage:
      'Error.Screens.Marketing.UsageCriteria.ContactExport.FolderId',
  },
  ContactExportNumberListName: {
    id: 'Screens.Marketing.UsageCriteria.ContactExport.ListName',
    defaultMessage:
      'Error.Screens.Marketing.UsageCriteria.ContactExport.ListName',
  },
  ContactExportInformationMutedText: {
    id: 'Screens.Marketing.UsageCriteria.ContactExport.Information.MutedText',
    defaultMessage:
      'Error.Screens.Marketing.UsageCriteria.ContactExport.Information.MutedText',
  },
  ContactExportInformationExportToSendinblue: {
    id: 'Screens.Marketing.UsageCriteria.ContactExport.Information.ExportToSendinblue',
    defaultMessage:
      'Error.Screens.Marketing.UsageCriteria.ContactExport.Information.ExportToSendinblue',
  },
  ContactExtractPlaceholder: {
    id: 'Screens.Marketing.ContactExtractPlaceholder',
    defaultMessage: 'Error.Screens.Marketing.ContactExtractPlaceholder',
  },
  ExportMarketingSuccessBody: {
    id: 'Screens.Marketing.Toast.Success',
    defaultMessage: 'Error.Screens.Marketing.Toast.Success',
  },
  ExportMarketingFailedBody: {
    id: 'Screens.Marketing.Toast.Failed',
    defaultMessage: 'Error.Screens.Marketing.Toast.Failed',
  },
  SearchContactsFailedBody: {
    id: 'Screens.Marketing.Toast.ErrorSearch',
    defaultMessage: 'Error.Screens.Marketing.Toast.ErrorSearch',
  },
  Gender: {
    id: 'Shared.Gender',
    defaultMessage: 'Error.Shared.Gender',
  },
  Man: {
    id: 'Shared.Man',
    defaultMessage: 'Error.Shared.Man',
  },
  Woman: {
    id: 'Shared.Woman',
    defaultMessage: 'Error.Shared.Woman',
  },
  Search: {
    id: 'Buttons.Search',
    defaultMessage: 'Error.Buttons.Search',
  },
  Clients: {
    id: 'Menu.Clients',
    defaultMessage: 'Error.Menu.Clients',
  },
  SelectAll: {
    id: 'Buttons.SelectAll',
    defaultMessage: 'Error.Buttons.SelectAll',
  },
  UnselectAll: {
    id: 'Buttons.UnselectAll',
    defaultMessage: 'Error.Buttons.UnselectAll',
  },
  SelectFilePlaceholder: {
    id: 'Buttons.ChooseAFile',
    defaultMessage: 'Error.Buttons.ChooseAFile',
  },
  LessThanOrEqualTo: {
    id: "Shared.LessThanOrEqualTo",
    defaultMessage: "Error.Shared.LessThanOrEqualTo"
  },
  EqualTo: {
    id: "Shared.EqualTo",
    defaultMessage: "Error.Shared.EqualTo"
  },
  GreaterThanOrEqualTo: {
    id: "Shared.GreaterThanOrEqualTo",
    defaultMessage: "Error.Shared.GreaterThanOrEqualTo"
  }
});
