import { useState, useEffect } from 'react';

import { service } from 'services';

const useFetch = (url, params = {}, update = []) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchUrl() {
      try {
        const response = await service.get(url, {
          params,
        });

        setData(response.data);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    }

    if (!loading) setLoading(true);
    fetchUrl();
  }, update);

  return [data, loading];
};

export default useFetch;
