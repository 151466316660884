import PropTypes from 'prop-types';

import { hasRightToAccess, useOidc } from 'utils';

const RoleManager = ({ entity, right, children }) => {
  const oidcUser = useOidc();
  const userRoles = oidcUser.profile.roles;

  return <>{hasRightToAccess(entity, right, userRoles) && children}</>;
};

RoleManager.propTypes = {
  children: PropTypes.node.isRequired,
  entity: PropTypes.string.isRequired,
  right: PropTypes.string.isRequired,
};

export { RoleManager };
