import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ModalBody, ModalFooter, ModalHeader, Modal } from 'reactstrap';
import { Button } from 'components';

import messages from './messages';

const DeleteRoomModal = ({
  offerName,
  modalInfo,
  setModalInfo,
  deleteItem,
}) => {

  const { formatMessage } = useIntl();
  const closeModal = () =>
    setModalInfo({
      ...modalInfo,
      isOpen: false,
    });

  const channelName = formatMessage({
    id: `Constants.${modalInfo.channel}`,
    defaultMessage: `Error.Constants.${modalInfo.channel}`,
  });

  return (
    <Modal isOpen={modalInfo.isOpen} toggle={closeModal} >
      <ModalHeader>
        <FormattedMessage
          {...messages.DeleteModalTitle}
          values={{ channel: channelName }}
        />
      </ModalHeader>
      <ModalBody>
        <p className='text-center m-0'>
          <FormattedMessage
            {...messages.DeleteModalBody}
            values={{
              channel: channelName,
              offerName,
            }}
          />
        </p>
      </ModalBody>
      <ModalFooter className='justify-content-end'>
        <Button
          type='button'
          color='text'
          label='Cancel'
          onClick={() => closeModal()}
        />
        <Button
          type='button'
          color='danger'
          label='Delete'
          onClick={() => deleteItem()}
        />
      </ModalFooter>
    </Modal>
  );
};

DeleteRoomModal.propTypes = {
  offerName: PropTypes.string.isRequired,
  modalInfo: PropTypes.shape().isRequired,
  setModalInfo: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
};

export default DeleteRoomModal;
