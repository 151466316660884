import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useFormikContext, Field } from 'formik';
import { Spinner } from 'reactstrap';
import { useDebouncedCallback } from 'use-debounce';
import { isNil } from 'lodash';

import { Translation, FormSelect } from '../../../components';
import { service } from '../../../services';
import { api } from '../../../utils';

const getRPPS = async (value, additionalParameters = null) => {
  return service.get(api.doctors.getPractitioner(value), {
    params: additionalParameters,
  });
};

const matchingIdentifierType = { PA: 0, PR: 8 };

export const RPPSInput = ({ accountType, setDoctorDirectoryData }) => {
  const { formatMessage } = useIntl();
  const { errors, setFieldError, setFieldValue, setValues, touched, values } =
    useFormikContext();
  const [state, setState] = useState({
    data: null,
    isLoading: false,
    isValid: !isNil(values.collegeNumber),
    value: null,
  });
  const debouncedCallback = useDebouncedCallback(async (newValue) => {
    setDoctorDirectoryData(null);
    setState({
      data: null,
      isLoading: true,
      isValid: false,
      value: newValue,
    });
    try {
      const { data } = await getRPPS(newValue, {
        identifierType:
          matchingIdentifierType[values.practitionerIdentifierNumberType],
      });
      if (data.length > 0) {
        setState((previousState) => ({
          ...previousState,
          data,
          isValid: true,
        }));
        setFieldValue('collegeNumber', newValue);
      } else {
        // if the RPPS number wasn't recognized, we empty the personal
        // data from the health directory to be displayed
        setDoctorDirectoryData(undefined);
        setFieldError(
          'collegeNumber',
          formatMessage({
            id: 'Model.Doctor.InvalidRPPSNumber',
            defaultMessage: 'Error.Model.Doctor.InvalidRPPSNumber',
          }),
        );
        setValues((formikValues) => ({
          ...formikValues,
          firstname: '',
          lastname: '',
          title: '',
        }));
      }
      setState((previousState) => ({ ...previousState, isLoading: false }));
    } catch (error) {
      console.error(error);
      setFieldValue('collegeNumber', '');
    }
  }, 350);

  return (
    <>
      <div className='form-group rpps'>
        <label className='required' htmlFor='collegeNumberAutocomplete'>
          <Translation id={`Model.Doctor.${accountType}Number`} />
        </label>
        <div className='position-relative'>
          <div className='rpps__icon'>
            {state.isLoading ? (
              <Spinner color='primary' size='sm' />
            ) : state.isValid && !isNil(state.value) ? (
              <i aria-hidden className='uil uil-check-circle text-success' />
            ) : isNil(state.data) && !isNil(state.value) ? (
              <i
                aria-hidden
                className='uil uil-exclamation-triangle text-danger'
              />
            ) : null}
          </div>
          <input
            defaultValue={values.collegeNumber}
            className={`form-control ${
              (errors?.collegeNumber && touched?.collegeNumber) ||
              !state.isValid
                ? 'is-invalid'
                : ''
            }`}
            name='collegeNumberAutocomplete'
            onChange={({ target: { value } }) => {
              if (value === '') {
                setDoctorDirectoryData(null);
                setState({
                  data: null,
                  isLoading: false,
                  isValid: false,
                  value,
                });
              } else debouncedCallback(value.trim());
            }}
            type='text'
          />
        </div>
        {(errors?.collegeNumber && touched?.collegeNumber) || !state.isValid ? (
          <p className='text-danger small m-0'>{errors.collegeNumber}</p>
        ) : null}
      </div>
      <div className='form-group selectHealthProfessional'>
        {state.isValid && !isNil(state.data) ? (
          <Field name='healthProfessionalSelected'>
            {({ field, meta }) => (
              <FormSelect
                hasError={Boolean(meta.touched && meta.error)}
                label={formatMessage({
                  id: 'Model.Doctor.SelectHealthProfessional',
                  defaultMessage: 'Error.Model.Doctor.SelectHealthProfessional',
                })}
                meta={meta}
                name={field.name}
                onChange={(opt) => {
                  const element = state.data.find(
                    (element) => element.id === opt.value,
                  );
                  setDoctorDirectoryData(element);
                  // we set the title to lower case except the first letter, in order to pre-select it (in case of update)
                  const modifiedTitle = element.titleCode
                    ? `${element.titleCode.toString().charAt(0)}${element.titleCode.toString().slice(1).toLowerCase()}`
                    : '';
                  setValues((formikValues) => ({
                    ...formikValues,
                    [field.name]: true,
                    directoryMatchingId: element.id,
                    firstname: element.firstName,
                    lastname: element.lastName,
                    title: modifiedTitle,
                  }));
                }}
                options={state.data.map((element) => {
                  return {
                    code: element.id,
                    label: element.displayLabel,
                    value: element.id,
                  };
                })}
                required
              />
            )}
          </Field>
        ) : null}
      </div>
    </>
  );
};
