import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonCreate: {
    id: 'Buttons.Create',
    defaultMessage: 'Error.Buttons.Create',
  },
  ButtonRead: {
    id: 'Buttons.Read',
    defaultMessage: 'Error.Buttons.Read',
  },
  ButtonReadTemplateMessages: {
    id: 'Buttons.ReadMessageTemplates',
    defaultMessage: 'Error.Buttons.ReadMessageTemplates',
  },
  ReadDoctors: {
    id: 'Screens.Universes.List.Buttons.ReadDoctors',
    defaultMessage: 'Error.Screens.Universes.List.Buttons.ReadDoctors',
  },
  ReadCustomers: {
    id: 'Screens.Universes.List.Buttons.ReadCustomers',
    defaultMessage: 'Error.Screens.Universes.List.Buttons.ReadCustomers',
  },
  Code: {
    id: 'Universes.Code',
    defaultMessage: 'Error.Universes.Code',
  },
  Universes: {
    id: 'Screens.Universes.List.Column.Universes',
    defaultMessage: 'Error.Screens.Universes.List.Column.Universes',
  },
  Clients: {
    id: 'Universes.Customers',
    defaultMessage: 'Error,Universes.Customers',
  },
  Doctors: {
    id: 'Universes.Doctors',
    defaultMessage: 'Error,Universes.Doctors',
  },
  TemplateMessages: {
    id: 'Model.Universe.TemplateMessages',
    defaultMessage: 'Error.Model.Universe.TemplateMessages',
  },
  Title: {
    id: 'Screens.Universes.List.Title',
    defaultMessage: 'Error.Screens.Universes.List.Title',
  },
  Placeholder: {
    id: 'Screens.Universes.List.Placeholder',
    defaultMessage: 'Error.Screens.Universes.List.Placeholder',
  },
  DefaultValue: {
    id: 'Screens.Universes.List.DefaultValue',
    defaultMessage: 'Error.Screens.Universes.List.DefaultValue',
  },
  Empty: {
    id: 'Model.Empty',
    defaultMessage: 'Error.Model.Empty',
  },
});
