function formatResources(languages, data) {
  const products = data.products.map((product) => {
    return product.refProduct;
  });
  const profiles = data.profiles.map((product) => {
    return product.refProfile;
  });

  const obj = {
    ...data,
    products,
    profiles,
  };

  languages.forEach(({ code }) => {
    obj[code] = {
      name: data.name.find((item) => item.languageCode === code).content,
      description: data.description.find((item) => item.languageCode === code)
        .content,
      cta: data.cta.find((item) => item.languageCode === code).content,
    };
  });

  delete obj.cta;
  delete obj.description;
  delete obj.name;
  return obj;
}

export default formatResources;
