import React from 'react';
import { Badge, Row, Col } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { PageHeader, RoleManager, Spinner } from 'components';
import { ReadModelWrapper } from 'themes/jupiter/elements';
import { api, urlSchema, useFetch } from 'utils';
import messages from './messages';

const Read = ({ match }) => {
  const intl = useIntl();
  const { params } = match;
  const [productTerms, isProductTermsLoading] = useFetch(
    api.manager.getProductTermsDetails(params.idProductTerm),
  );
  const [productTermsResources, isProductTermsResourcesLoading] = useFetch(
    api.manager.getProductTermsResourcesDetails(params.idProductTerm),
  );

  return (
    <>
      <PageHeader>
        <FormattedMessage
          {...messages.Title}
          values={{
            productTermsCode: productTerms.code,
          }}
          tagName='h1'
        />
        <Link
          aria-label={intl.formatMessage(messages.ButtonsEdit)}
          title={intl.formatMessage(messages.ButtonsEdit)}
          className='btn btn-primary'
          to={`${urlSchema.productTerms.edit}/${productTerms.idProductTerm}`}
        >
          <FormattedMessage {...messages.ButtonsEdit} />
        </Link>
      </PageHeader>
      {isProductTermsLoading ? (
        <Spinner size='lg' />
      ) : (
        <Row>
          <Col lg='8'>
            <section className='section'>
              <section className='section__body'>
                <div className='model'>
                  <div className='model__actions mb-2'>
                    <Badge color={productTerms.valid ? 'success' : 'danger'}>
                      {productTerms.valid ? (
                        <FormattedMessage {...messages.Valid} />
                      ) : (
                        <FormattedMessage {...messages.Invalid} />
                      )}
                    </Badge>
                  </div>
                  <Row>
                    <Col lg='6'>
                      <div className='model__property'>
                        <h4 className='model__title'>
                          {intl.formatMessage(messages.ProductTermsCode)}
                        </h4>
                        <p className='model__value'>{productTerms.code}</p>
                      </div>
                    </Col>
                    <Col lg='6'>
                      <div className='model__property'>
                        <h4 className='model__title'>
                          {intl.formatMessage(messages.ProductTermsName)}
                        </h4>
                        <p className='model__value'>{productTerms.label}</p>
                      </div>
                    </Col>
                    <Col lg='6'>
                      <div className='model__property'>
                        <h4 className='model__title'>
                          {intl.formatMessage(messages.ProductTermsOptional)}
                        </h4>
                        <div className='model__actions'>
                          {productTerms.optional ? (
                            <FormattedMessage {...messages.Optional} />
                          ) : (
                            <FormattedMessage {...messages.NoOptional} />
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </section>
            </section>
          </Col>
          <Col lg='4'>
            <section className='section'>
              <section className='section__header'>
                <FormattedMessage
                  {...messages.ProductTermsRessourceTitle}
                  tagName='h2'
                />
                <RoleManager entity='ProductTermsResources' right='CRUD'>
                  <Link
                    className='btn btn-outline-primary btn-sm ml-auto'
                    to={`${urlSchema.productTermsResources.create}/${productTerms.idProductTerm}`}
                  >
                    <FormattedMessage {...messages.ButtonsAdd} />
                  </Link>
                </RoleManager>
              </section>
              <section className='section__body'>
                {isProductTermsResourcesLoading ? (
                  <Spinner size='lg' />
                ) : productTermsResources.length > 0 ? (
                  productTermsResources.map((item) => (
                    <ReadModelWrapper className='d-inline'>
                      <Link
                        className='btn btn-outline-warning btn-sm mr-2'
                        to={`${urlSchema.productTermsResources.read}/${item.idProductTermsResource}`}
                      >
                        <p className='model__value'>{item.codeLanguage}</p>
                      </Link>
                    </ReadModelWrapper>
                  ))
                ) : (
                  <FormattedMessage {...messages.NoElement} />
                )}
              </section>
            </section>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Read;
