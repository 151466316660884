import React from 'react';
import { Switch } from 'react-router-dom';

import { ProtectedRoute } from 'components';
import { urlSchema } from 'utils';

// Screens
import Create from './Create';
import List from './List';
import Read from './Read';
import Sort from './Sort';
import Update from './Update';

const routes = [
  { path: urlSchema.createOffer, component: Create, right: 'CRUD' },
  {
    path: urlSchema.sortOffers,
    component: Sort,
    right: 'Reader',
  },
  {
    path: `${urlSchema.updateOffer}/:refOffer`,
    component: Update,
    right: 'CRUD',
  },
  {
    path: `${urlSchema.readOffer}/:refOffer`,
    component: Read,
    right: 'Reader',
  },
  { path: '/', component: List, right: 'Reader' },
];

const Offers = () => {
  return (
    <Switch>
      {routes.map((item) => (
        <ProtectedRoute entity='Offers' key={item.path} {...item} />
      ))}
    </Switch>
  );
};

export default Offers;
