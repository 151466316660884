export default {
  _001111: '001111',
  ALLIANZ: 'ALLIANZ',
  APRIL: 'APRIL',
  BLUELINEA: 'BLUELINEA',
  FOCUS_INOVA: 'FOCUS INOVA',
  KRYS_OPTICIEN: 'KRYS OPTICIEN',
  KRYS_ORTHOPTISTE: 'KRYS ORTHOPTISTE',
  MEDECIN_DIRECT: 'MEDECIN DIRECT',
  OPTEVEN: 'OPTEVEN',
  PARTENAIRE: 'PARTENAIRE',
  QUALISOCIAL: 'QUALISOCIAL',
  ENTORIA: 'ENTORIA',
};
