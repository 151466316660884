import axios from 'axios';

import { api } from 'utils';

const service = axios.create({});

const BASE_URL = window.__env.REACT_APP_API_AUTH_URL;
const IDENTITY_API = window.__env.REACT_APP_API_IDENTITY_URL;

const AuthService = {
  confirmEmail: async (userId, code) =>
    service.put(`${IDENTITY_API}/Users/ConfirmEmail/${userId}`, code, {
      headers: { 'Content-Type': 'application/json' },
    }),
  getUserInfo: async () => service.get(api.users.getUserInformation),
  patientRegistration: async (payload) =>
    service.post(`${BASE_URL}/authority/PatientRegistration`, payload),
  verifyEmail: async (payload) =>
    service.post(`${IDENTITY_API}/PatientRegistration/VerifyEmail`, payload),
  resetPassword: async (payload) =>
    service.post(`${BASE_URL}/authority/account/resetpassword`, {
      payload,
    }),
  postSSO: async (payload) =>
    service.post(`${BASE_URL}/authority/account/sso`, payload),
  postMultiFactorAuthentication: async (token) =>
    service.post(`${BASE_URL}/authority/account/mfaprovider`, {
      token,
    }),
  postOtp1: async (payload) =>
    service.post(`${BASE_URL}/authority/account/mfa`, payload),
  postOtp2: async (payload) =>
    service.post(`${BASE_URL}/authority/account/otp`, payload),
  postLogin: async (payload) =>
    service.post(`${BASE_URL}/authority/account`, payload),
  postToken: async (formData) =>
    service.post(`${BASE_URL}/connect/token`, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }),
  tokenRevocation: async (formData) => {
    const postTokenHeaders = [
      'client_id',
      'client_secret',
      'token',
      'token_type_hint',
    ];
    const params = new URLSearchParams();

    for (const property in formData) {
      if (postTokenHeaders.includes(property)) {
        params.append(property, configuration[property]);
      }
    }

    return service.post(`${BASE_URL}/connect/revocation`, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
  instance: service,
};

export { AuthService, service };
