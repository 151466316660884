import { defineMessages } from 'react-intl';

const messages = defineMessages({
  PartnerCoverage: {
    id: 'Screens.Patients.Read.HealthJourneyItem.PartnerCoverage',
    defaultMessage:
      'Error.Screens.Patients.Read.HealthJourneyItem.PartnerCoverage'
  },
  InformationTitle: {
    id: 'Screens.Patients.Read.HealthJourneyItem.InformationTitle',
    defaultMessage: 'Error.Screens.Patients.Read.HealthJourneyItem.InformationTitle'
  }
});

export default messages;
