import styled, {css} from 'styled-components';

const BreadcrumbWrapper = styled.nav`
  margin: 0 auto;
  max-width: 80%;
  width: 80%;

  ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
`;

export default BreadcrumbWrapper;
