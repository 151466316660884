import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { historyShape } from 'react-router-props';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row, Button as RSButton, Spinner } from 'reactstrap';
import { Formik, Form } from 'formik';

import { Section, SectionBody, SectionFooter, Button } from 'components';
import { service } from 'services';
import { api, useFetch, sendToast, urlSchema } from 'utils';
import { ToastTypes } from '../../../constants';

import CreateOfferForm from './CreateOfferForm';
import CreateRoomForm from './CreateRoomForm';
import CreateBreadcrumbs from './Breadcrumbs';

import OffersCreateHeader from './elements';

import getValidationSchema from './validationSchema';
import getInitialValues from './initialValues';
import messages from './messages';

const Create = ({ history }) => {
  const [formLogoData, setFormLogoData] = useState();
  const [validLanguages, areValidLanguagesLoading] = useFetch(
    api.identity.getValidLanguages,
  );
  const [step, setStep] = useState(0);
  const intl = useIntl();
  const locale = intl.locale.split('-')[0].toUpperCase();

  const formatTranslationValues = (values, languages) => {
    const formattedValue = {
      name: [],
      cta: [],
      description: [],
    };

    languages.forEach((lang) => {
      Object.keys(formattedValue).forEach((key) => {
        formattedValue[key].push({
          content: values[lang.code][key],
          languageCode: lang.code,
        });
      });
    });

    return formattedValue;
  };

  const onSubmit = (values, { setSubmitting, setTouched }) => {
    if (step === 0) {
      setSubmitting(false);
      setStep(1);
      setTouched({});
    }
    if (step === 1) {
      setSubmitting(true);
      service
        .post(api.admin.postOfferLogo, formLogoData)
        .then(({ data }) => {
          const formattedTranslations = formatTranslationValues(
            values,
            validLanguages,
          );
          const newValues = {
            code: values.code,
            isValid: values.isValid,
            rooms: values.rooms,
            allowFollowUpEncounter: values.allowFollowUpEncounter,
            products: values.products,
            profiles: values.profiles,
            options: values.options,
            refSpecialty: values.refSpecialty,
            refLogo: data.fileId,
            ...formattedTranslations,
          };
          service
            .post(api.admin.postOffer, newValues)
            .then(({ data }) => {
              console.log(data);
              if (data.succeeded === false) {
                data.errorSet.forEach((error) => {
                  sendToast(ToastTypes.error, {
                    header: <FormattedMessage {...messages.FailTitle} />,
                    body: (
                      <>
                        <p>
                          <FormattedMessage {...messages.ToastErrorCode} />{' '}
                          <code>{error.code}</code>
                        </p>
                        {error.code === 'DuplicateCode' ? (
                          <p>
                            <FormattedMessage
                              {...messages.ToastDescriptionDuplicateCode}
                            />
                          </p>
                        ) : (
                          <p>
                            <FormattedMessage
                              {...messages.ToastErrorDescription}
                            />{' '}
                            <code>{error.description}</code>
                          </p>
                        )}
                      </>
                    ),
                  });
                });
                setSubmitting(false);
              } else {
                setSubmitting(false);
                sendToast(ToastTypes.success, {
                  header: <FormattedMessage {...messages.SuccessTitle} />,
                  body: <FormattedMessage {...messages.SuccessBody} />,
                });
                history.push(`${urlSchema.readOffer}/${data.id}`);
              }
            })
            .catch((err) => {
              setSubmitting(false);
              console.log(err);
              sendToast(ToastTypes.error, {
                header: <FormattedMessage {...messages.FailTitle} />,
                body: (
                  <>
                    <FormattedMessage {...messages.FailBody} />
                    {err.errorSet.code}
                  </>
                ),
              });
            });
        })
        .catch((err) => {
          console.log(err);
          setSubmitting(false);
          sendToast(ToastTypes.error, {
            header: <FormattedMessage {...messages.FailTitle} />,
            body: (
              <>
                <FormattedMessage {...messages.FailBody} />
                {err.errorSet.code}
              </>
            ),
          });
        });
    }
  };

  if (areValidLanguagesLoading) return <Spinner />;

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={getInitialValues(validLanguages)}
      validationSchema={getValidationSchema(step, intl, validLanguages)}
    >
      {({ values, isValid, isSubmitting }) => (
        <Section>
          <div className='pt-3'>
            <CreateBreadcrumbs
              isValid={isValid}
              setStep={setStep}
              currentStep={step}
            />
          </div>
          <Form>
            <>
              {step === 0 && (
                <SectionBody>
                  <OffersCreateHeader>
                    <FormattedMessage
                      {...messages.CreateOfferTitle}
                      tagName='h1'
                    />
                    <FormattedMessage
                      {...messages.CreateOfferSubtitle}
                      tagName='p'
                    />
                  </OffersCreateHeader>
                  <CreateOfferForm
                    validLanguages={validLanguages}
                    setFormLogoData={setFormLogoData}
                  />
                </SectionBody>
              )}
              {step === 1 && (
                <SectionBody>
                  <OffersCreateHeader>
                    <FormattedMessage
                      {...messages.CreateRoomTitle}
                      tagName='h1'
                      values={{
                        offerName: values[locale].name,
                      }}
                    />
                    <FormattedMessage
                      {...messages.CreateRoomSubtitle}
                      tagName='p'
                    />
                  </OffersCreateHeader>
                  <Row>
                    <Col lg='12'>
                      <CreateRoomForm />
                    </Col>
                  </Row>
                </SectionBody>
              )}
              <SectionFooter>
                <div className='d-flex justify-content-end'>
                  <>
                    {step > 0 && (
                      <Button
                        type='button'
                        outline
                        onClick={() => setStep(0)}
                        color='secondary'
                        label='Back'
                        className='mr-2'
                      />
                    )}
                    <Button
                      label={
                        step === 0
                          ? 'Buttons.Next'
                          : 'Screens.Offers.CreateOffer.CTA'
                      }
                      customLabel
                      type='submit'
                      color='primary'
                      isProcessing={isSubmitting}
                    />
                  </>
                </div>
              </SectionFooter>
            </>
          </Form>
        </Section>
      )}
    </Formik>
  );
};

Create.propTypes = {
  history: historyShape,
};

export default withRouter(Create);
