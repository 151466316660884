import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import WrittingIcon from '../../../../../assets/images/dialogue_e_icon.svg';
import PhoneIcon from '../../../../../assets/images/dialogue_t_icon.svg';
import VideoAnswer from '../../../../../assets/images/dialogue_v_icon.svg';
import HealthJourneyItemWrapper from './elements';
import messages from './messages';

const HealthJourneyItem = ({ data, openByDefault = false }) => {
  const [isOpen, setIsOpen] = useState(openByDefault);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const renderChannelIcon = (channel) => {
    switch (channel) {
      case 'Messaging':
        return <img alt='Messaging icon' src={WrittingIcon} />;
      case 'Phone':
        return <img alt='Phone icon' src={PhoneIcon} />;
      case 'Visio':
        return <img alt='Visio icon' src={VideoAnswer} />;
      default:
        return <img alt='Unknown icon' src={WrittingIcon} />;
    }
  };

  return (
    <HealthJourneyItemWrapper>
      <HealthJourneyItemWrapper.HealthJourneyHeader>
        <button type='button' onClick={() => handleToggle()}>
          <span>{data.name}</span>
          <i
            className={`uil uil-angle-${isOpen ? 'up' : 'down'}`}
            aria-hidden
          />
        </button>
      </HealthJourneyItemWrapper.HealthJourneyHeader>
      <Collapse isOpen={isOpen}>
        <HealthJourneyItemWrapper.HealthJourneyBody>
          <FormattedMessage {...messages.InformationTitle} tagName='h5' />
          <p>{data.description}</p>
          <div>
            {data.channels.map((channel) => (
              <HealthJourneyItemWrapper.SpecialtyItem
                key={`color-${channel}`}
                color='blue'
              >
                <span className='d-flex align-items-center'>
                  {renderChannelIcon(channel)}
                  <FormattedMessage
                    id={`Shared.By${channel}`}
                    defaultMessage={`Error.Shared.By${channel}`}
                    tagName='span'
                  />
                </span>
              </HealthJourneyItemWrapper.SpecialtyItem>
            ))}
          </div>
          {!data.paidOnly && (
            <HealthJourneyItemWrapper.SpecialtyItem color='green'>
              <FormattedMessage {...messages.PartnerCoverage} />
            </HealthJourneyItemWrapper.SpecialtyItem>
          )}
        </HealthJourneyItemWrapper.HealthJourneyBody>
      </Collapse>
    </HealthJourneyItemWrapper>
  );
};

HealthJourneyItem.defaultProps = {
  openByDefault: false,
};

HealthJourneyItem.propTypes = {
  data: PropTypes.shape().isRequired,
  openByDefault: PropTypes.bool,
};

export default HealthJourneyItem;
