import styled from 'styled-components';

const FormRadioButtonWrapper = styled.div`
  ${({ theme }) => `
  label {
      padding-left: ${theme.space(2)}
  }
`}
`;

export { FormRadioButtonWrapper };
