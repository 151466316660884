import { defineMessages } from 'react-intl';

export default defineMessages({
  Title: {
    id: 'Screens.ProductTerms.Read.Title',
    defaultMessage: 'Error.Screens.ProductTerms.Read.Title',
    description: 'ProductTerms read page title',
  },
  ButtonsEdit: {
    id: 'Buttons.Edit',
    defaultMessage: 'Error.Buttons.Edit',
    description: 'Button to edit ProductTerms',
  },
  ProductTermsCode: {
    id: 'ProductTerms.Code',
    defaultMessage: 'Error.ProductTerms.Code',
  },
  ProductTermsName: {
    id: 'ProductTerms.Name',
    defaultMessage: 'Error.ProductTerms.Name',
  },
  ProductTermsOptional: {
    id: 'ProductTerms.Optional',
    defaultMessage: 'Error.ProductTerms.Optional',
  },
  ProductTermsValid: {
    id: 'ProductTerms.Valid',
    defaultMessage: 'Error.ProductTerms.Valid',
  },
  ProductTermsRessourceTitle: {
    id: 'Screens.ProductTerms.Read.ProductTermsRessourceTitle',
    defaultMessage:
      'Error.Screens.ProductTerms.Read.ProductTermsRessourceTitle',
  },
  ButtonsAdd: {
    id: 'Buttons.Add',
    defaultMessage: 'Error.Buttons.Add',
    description: 'Button to Add ProductTermsRessource',
  },
  NoElement: {
    id: 'Shared.NoElement',
    defaultMessage: 'Error.Shared.NoElement',
  },
  Valid: {
    id: 'Model.Valid',
    defaultMessage: 'Error.Model.Valid',
  },
  Invalid: {
    id: 'Model.Invalid',
    defaultMessage: 'Error.Model.Invalid',
  },
  Optional: {
    id: 'Model.Optional',
    defaultMessage: 'Error.Model.Optional',
  },
  NoOptional: {
    id: 'Model.NoOptional',
    defaultMessage: 'Error.Model.NoOptional',
  },
});
