import React from 'react';
import PropTypes from 'prop-types';

const SectionHeader = ({ children, ...props }) => (
  <header className='section__header' {...props}>
    {children}
  </header>
);

SectionHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SectionHeader;
