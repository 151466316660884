import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import { FormattedMessage } from 'react-intl';

import FormInputFeedbackWrapper from './elements';

const FormInputFeedback = ({ msg, name }) =>
  msg ? (
    <FormInputFeedbackWrapper>
      <FormattedMessage id={msg} defaultMessage={`Error.${msg}`} />
    </FormInputFeedbackWrapper>
  ) : (
    <ErrorMessage name={name}>
      {(value) => <FormInputFeedbackWrapper>{value}</FormInputFeedbackWrapper>}
    </ErrorMessage>
  );

FormInputFeedback.defaultProps = {
  name: undefined,
  msg: false,
};

FormInputFeedback.propTypes = {
  msg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  name: PropTypes.string,
};

export default FormInputFeedback;
