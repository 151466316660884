import React from 'react';
import { ErrorMessage } from 'formik';
import { FormFeedback } from 'reactstrap';
import PropTypes from 'prop-types';

const FormErrorMessage = ({ name }) => (
  <ErrorMessage name={name}>
    {(msg) => <FormFeedback>{msg}</FormFeedback>}
  </ErrorMessage>
);

FormErrorMessage.propTypes = {
  name: PropTypes.string.isRequired
};

export default FormErrorMessage;
