import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row, Badge } from 'reactstrap';
import {
  PageHeader,
  RoleManager,
  Section,
  SectionBody,
  Spinner,
} from 'components';

import { api, urlSchema, useFetch } from 'utils';
import { ReadModelWrapper } from 'themes/jupiter/elements';
import messages from './messages';

const Read = () => {
  const intl = useIntl();
  const { partnerId } = useParams();
  const [partner, isPartnerLoading] = useFetch(
    api.manager.getPartner(partnerId),
  );

  if (isPartnerLoading) return <Spinner size='lg' />;

  return (
    <>
      <PageHeader>
        <FormattedMessage
          {...messages.Title}
          values={{ partnerName: partner.name }}
          tagName='h1'
        />
        <RoleManager entity='EligiblePartners' right='CRUD'>
          <Link
            className='btn btn-primary'
            to={`${urlSchema.updatePartner}/${partnerId}`}
          >
            {intl.formatMessage({ ...messages.ButtonEdit })}
          </Link>
        </RoleManager>
      </PageHeader>
      <Row>
        <Col md='6'>
          <Section>
            <SectionBody>
              {partner.isEligible ? (
                <Badge color='primary'>
                  {intl.formatMessage({ ...messages.IsEligible })}
                </Badge>
              ) : (
                <Badge color='danger'>
                  {intl.formatMessage({ ...messages.IsNotEligible })}
                </Badge>
              )}
              <ReadModelWrapper>
                <Row>
                  <Col md='6'>
                    <ReadModelWrapper.PropertyWrapper>
                      <ReadModelWrapper.PropertyTitle>
                        <FormattedMessage {...messages.Name} />
                      </ReadModelWrapper.PropertyTitle>
                      <ReadModelWrapper.PropertyValue>
                        {partner.name}
                      </ReadModelWrapper.PropertyValue>
                    </ReadModelWrapper.PropertyWrapper>
                  </Col>
                  <Col md='6'>
                    <ReadModelWrapper.PropertyWrapper>
                      <ReadModelWrapper.PropertyTitle>
                        <FormattedMessage {...messages.Client} />
                      </ReadModelWrapper.PropertyTitle>
                      {partner.refClientNavigation ? (
                        <Link
                          to={`${urlSchema.clients.read}/${partner.refClientNavigation.idClient}`}
                        >
                          {partner.refClientNavigation.label}
                        </Link>
                      ) : (
                        <ReadModelWrapper.PropertyValue>
                          -
                        </ReadModelWrapper.PropertyValue>
                      )}
                    </ReadModelWrapper.PropertyWrapper>
                  </Col>
                  <Col md='6'>
                    <ReadModelWrapper.PropertyWrapper>
                      <ReadModelWrapper.PropertyTitle>
                        <FormattedMessage {...messages.PhoneNumber} />
                      </ReadModelWrapper.PropertyTitle>
                      <ReadModelWrapper.PropertyValue>
                        {partner.partnerPhoneNumber || '-'}
                      </ReadModelWrapper.PropertyValue>
                    </ReadModelWrapper.PropertyWrapper>
                  </Col>
                  <Col md='6'>
                    <ReadModelWrapper.PropertyWrapper>
                      <ReadModelWrapper.PropertyTitle>
                        <FormattedMessage {...messages.Keywords} />
                      </ReadModelWrapper.PropertyTitle>
                      <ReadModelWrapper.PropertyValue>
                        {partner.keywords || '-'}
                      </ReadModelWrapper.PropertyValue>
                    </ReadModelWrapper.PropertyWrapper>
                  </Col>
                </Row>
                {Array.isArray(partner.eligiblePartnersResources) &&
                  partner.eligiblePartnersResources.length > 0 &&
                  partner.eligiblePartnersResources.map((resource) => (
                    <Row>
                      <Col md='6'>
                        <ReadModelWrapper.PropertyItem>
                          <ReadModelWrapper.PropertyWrapper>
                            <ReadModelWrapper.PropertyTitle>
                              <FormattedMessage {...messages.EligibilityKey} />
                            </ReadModelWrapper.PropertyTitle>
                            <ReadModelWrapper.PropertyValue>
                              {resource.eligibilityKey || '-'}
                            </ReadModelWrapper.PropertyValue>
                          </ReadModelWrapper.PropertyWrapper>
                          <ReadModelWrapper.PropertyWrapper>
                            <ReadModelWrapper.PropertyTitle>
                              <FormattedMessage
                                {...messages.EligibilityKeyDescription}
                              />
                            </ReadModelWrapper.PropertyTitle>
                            <ReadModelWrapper.PropertyValue>
                              {resource.eligibilityKeyDescription || '-'}
                            </ReadModelWrapper.PropertyValue>
                          </ReadModelWrapper.PropertyWrapper>
                          <ReadModelWrapper.DecoratorWrapper>
                            <img
                              alt='Resource language'
                              height='24'
                              src={
                                require(`../../../assets/images/flags/${resource.codeLanguage}_flag.svg`)
                                  .default
                              }
                            />
                          </ReadModelWrapper.DecoratorWrapper>
                        </ReadModelWrapper.PropertyItem>
                      </Col>
                    </Row>
                  ))}
              </ReadModelWrapper>
            </SectionBody>
          </Section>
        </Col>
      </Row>
    </>
  );
};

export default Read;
