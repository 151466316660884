import React, { useState } from 'react';
import { matchShape } from 'react-router-props';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'reactstrap';
import AceEditor from 'react-ace';
import { Formik, Form, FieldArray, FastField, Field } from 'formik';
import { useTheme } from 'styled-components';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-javascript';

import { ToastTypes } from '../../../constants';
import { service } from 'services';
import { api, useFetch, urlSchema, sendToast } from 'utils';
import { validationSchema } from 'models/Surveys/validationSchema';
import messages from './messages';
import initialValues from './initialValues';

import {
  Button,
  FormList,
  PageHeader,
  Spinner,
  Checkbox,
  FormInput,
  FormInputFeedback,
  FormInputLogo,
} from 'components';

const Create = ({ match, history }) => {
  const surveyId = 'e0346101-a38d-4223-9e34-05e8ac429152';
  const theme = useTheme();

  const [survey, isSurveyLoading] = useFetch(
    api.questionnaires.getDetails(surveyId),
  );

  const [profiles, areProfilesLoading] = useFetch(api.profiles.get);
  const [products, areProductsLoading] = useFetch(api.admin.getProducts);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [specialties] = useFetch(api.admin.getSpecialties, {
    isValid: true,
    searchString: '',
    refOrganizationConfig: '',
  });

  const questionnairesHasProducts = survey.questionnairesProducts?.length > 0;

  const aceEditorStyle = {
    border: `1px solid ${theme.color('border')}`,
    borderRadius: theme.borderRadius,
    width: '100%',
  };

  const handleSubmit = async (e) => {
    setIsSubmitting(true);
    const { rawFile, ...allOtherValues } = e;
    const postPicture = typeof rawFile === 'object' && rawFile !== null;

    service
      .post(api.questionnaires.post, {
        ...allOtherValues,
      })
      .then(async ({ data }) => {
        if (typeof data.succeeded !== 'undefined' && !data.succeeded) {
          sendToast(ToastTypes.error, {
            header: <FormattedMessage {...messages.ToastFail} />,
            body: data.errorSet[0].description,
          });
        } else {
          const { idQuestionnaire } = data;
          if (postPicture) {
            const newFormData = new FormData();
            newFormData.append(rawFile.name, rawFile);
            try {
              await service.post(
                api.questionnaires.upload(idQuestionnaire),
                newFormData,
              );
            } catch (error) {
              console.error(error);
            }
          }

          sendToast(ToastTypes.success, {
            header: '',
            body: <FormattedMessage {...messages.ToastSuccess} />,
          });
          history.push(`${urlSchema.surveyDetails}/${idQuestionnaire}`);
        }
      })
      .catch(() => {
        sendToast(ToastTypes.error, {
          header: '',
          body: <FormattedMessage {...messages.ToastFail} />,
        });
      })
      .then(() => {
        setIsSubmitting(false);
      });
  };

  return !isSurveyLoading ? (
    <Formik
      {...{ validationSchema }}
      onSubmit={handleSubmit}
      {...{ initialValues }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <PageHeader>
            <FormattedMessage
              tagName='h1'
              id='screens_questionnaire_create_title'
              defaultMessage='error_screens_questionnaire_create_title'
              values={{ name: survey?.title }}
            />
          </PageHeader>
          <Row>
            <Col lg='8'>
              <div className='section'>
                {isSurveyLoading ? (
                  <Spinner color='primary' size='lg' />
                ) : (
                  <>
                    <div className='section__body'>
                      <div className='model'>
                        <Row>
                          <Col md='12' className='d-flex'>
                            <FormInputLogo
                              defaultImage={
                                values.rawFile
                                  ? {
                                      contentType: 'image/*',
                                      rawFile: values.rawFile,
                                    }
                                  : undefined
                              }
                              name='rawFile'
                              setFormLogoData={(e) =>
                                setFieldValue('rawFile', e)
                              }
                            />
                          </Col>
                          <Col md='12'>
                            <div className='model__property'>
                              <FormInput
                                id='code'
                                label={
                                  <FormattedMessage
                                    id='shared_title'
                                    defaultMessage='error_shared_title'
                                  />
                                }
                                name='title'
                                required
                                type='text'
                              />
                            </div>
                            <div className='model__property'>
                              <FormInput
                                id='message'
                                label={
                                  <FormattedMessage
                                    id='shared_description'
                                    defaultMessage='error_shared_description'
                                  />
                                }
                                name='message'
                                required
                                type='textarea'
                              />
                            </div>
                          </Col>
                          <Col md='12'>
                            <div className='model__property'>
                              <h4 className='model__title required'>
                                <FormattedMessage
                                  id='screens_questionnaire_JSON_structure_title'
                                  defaultMessage='error_screens_questionnaire_JSON_structure_title'
                                />
                              </h4>
                              <div className='form-group'>
                                <AceEditor
                                  defaultValue={values.jsonContent}
                                  showGutter
                                  mode='json'
                                  name='jsonContent'
                                  onChange={(value) => {
                                    setFieldValue('jsonContent', value);
                                  }}
                                  setOptions={{ useWorker: false }}
                                  style={aceEditorStyle}
                                  theme='monokai'
                                  height='400px'
                                  wrapEnabled
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md='12'>
                            <div className='model__property'>
                              <h4 className='model__title'>
                                <FormattedMessage
                                  id='screens_questionnaire_javascript_title'
                                  defaultMessage='error_screens_questionnaire_javascript_title'
                                />
                              </h4>
                              <AceEditor
                                defaultValue={values.jsContent}
                                onChange={(value) => {
                                  setFieldValue('jsContent', value);
                                }}
                                setOptions={{ useWorker: false }}
                                showGutter
                                mode='javascript'
                                id='jscontent'
                                name='jsContent'
                                style={aceEditorStyle}
                                theme='monokai'
                                height='150px'
                                wrapEnabled
                              />
                            </div>
                          </Col>

                          <Col md='12'>
                            <div className='section'>
                              {isSurveyLoading || areProfilesLoading ? (
                                <Spinner color='primary' size='lg' />
                              ) : (
                                <>
                                  <div className='section__header'>
                                    <h1>
                                      <FormattedMessage
                                        id='Buttons.Profiles'
                                        defaultMessage='Error.Buttons.Profiles'
                                      />
                                    </h1>
                                  </div>
                                  <div className='section__body'>
                                    <FieldArray
                                      name='selectedProfiles'
                                      render={({ push, remove }) => (
                                        <>
                                          {profiles.map((profile) => {
                                            const hasProfile =
                                              values.selectedProfiles.find(
                                                (t) => t === profile.idProfile,
                                              );
                                            const ico = hasProfile
                                              ? 'check'
                                              : 'plus';
                                            const btnCls = hasProfile
                                              ? 'combined-btn combined-btn--success'
                                              : 'combined-btn';

                                            return (
                                              <button
                                                className={btnCls}
                                                key={profile.idProfile}
                                                type='button'
                                                onClick={() => {
                                                  const res =
                                                    values.selectedProfiles.findIndex(
                                                      (e) =>
                                                        e === profile.idProfile,
                                                    );
                                                  if (
                                                    values.selectedProfiles.includes(
                                                      profile.idProfile,
                                                    )
                                                  ) {
                                                    remove(res);
                                                  } else {
                                                    push(profile.idProfile);
                                                  }
                                                }}
                                              >
                                                <div className='combined-btn__container'>
                                                  <span>{profile.label}</span>

                                                  <i
                                                    aria-hidden
                                                    className={`uil uil-${ico}`}
                                                  />
                                                </div>
                                              </button>
                                            );
                                          })}
                                        </>
                                      )}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </Col>
                          <Col md='12'>
                            <div className='model__property'>
                              <FastField name='isGenerateQuestion'>
                                {({ field, form }) => (
                                  <Checkbox
                                    {...field}
                                    checked={form.values.isGenerateQuestion}
                                    id='isGenerateQuestion'
                                  >
                                    <FormattedMessage id='screens_questionnaire_isGenerateQuestion_true' />
                                  </Checkbox>
                                )}
                              </FastField>
                              <FormInputFeedback name='isGenerateQuestion' />
                              {values.isGenerateQuestion && (
                                <>
                                  <h4 className='model__title'>
                                    <FormattedMessage
                                      id='shared_speciality'
                                      defaultMessage='error_shared_speciality'
                                    />
                                  </h4>
                                  <Field as='select' name='refSpecialty'>
                                    <option disabled>
                                      Selectionner une spécialité
                                    </option>
                                    {specialties.map((e) => (
                                      <option
                                        value={e.idSpecialty}
                                        selected={
                                          e.idSpecialty === survey.refSpecialty
                                        }
                                      >
                                        {e.label}
                                      </option>
                                    ))}
                                  </Field>
                                </>
                              )}
                            </div>
                            <div className='model__property'>
                              <FastField name='valid'>
                                {({ field, form }) => (
                                  <Checkbox
                                    {...field}
                                    checked={form.values.valid}
                                    id='valid'
                                  >
                                    <FormattedMessage id='Shared.Valid' />
                                  </Checkbox>
                                )}
                              </FastField>
                              <FormInputFeedback name='valid' />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <Button
                color='primary'
                isProcessing={isSubmitting}
                label='Create'
                size='lg'
                type='submit'
              />
            </Col>
            {/* Products */}
            <Col lg='4'>
              <div className='section'>
                {isSurveyLoading || areProductsLoading ? (
                  <Spinner color='primary' size='lg' />
                ) : (
                  <>
                    <div className='section__header'>
                      <h1>
                        <FormattedMessage
                          id='Model.Profile.Products'
                          defaultMessage='Error.Model.Profile.Products'
                        />
                      </h1>
                    </div>
                    <div className='section__body'>
                      <FormList
                        data={products}
                        name='selectedProducts'
                        itemValueKey='idProduct'
                        itemLabelKey='code'
                        researchOptions={{
                          isEnabled: false,
                          placeholder: 'test',
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  ) : null;
};

Create.propTypes = { match: matchShape.isRequired };

export default Create;
