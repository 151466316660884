import styled, { css } from 'styled-components';

const Col = styled.div`
  ${({ theme }) => css``}
`;

const Wrapper = styled.div`
  ${({ theme }) => css`
    @media ${theme.screenLgMin} {
      align-items: center;
      display: flex;
      justify-content: space-between;

      ${Col} {
        width: calc((100% - ${theme.space(3)}) / 3);
      }
    }
  `}
`;

Wrapper.Col = Col;

export default Wrapper;
