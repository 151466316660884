import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-xml';

import { Spinner, Translation } from 'components';
import { WebServiceLogsSurcharged } from './types';
import { getNHIPatientWebServiceLogDetail } from 'services/manager/NHIs/getNHIPatientWebServiceLogDetail';

export const ModalWebServiceLogDetails = ({
  log,
  onToggle,
}: {
  log: WebServiceLogsSurcharged;
  onToggle: () => void;
}) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['nhi-log-detail', { logId: log.id }],
    queryFn: () => getNHIPatientWebServiceLogDetail(log.id),
  });

  return (
    <Modal size='lg' isOpen={true} toggle={onToggle}>
      <ModalHeader>
        <Translation id='Screens.Patient.Read.Nhi.Logs.WebServiceLog.Title' />
      </ModalHeader>
      <ModalBody>
        {error && <Translation id='Shared.ErrorFetching' />}
        {isLoading && <Spinner color='primary' size='sm' />}
        {data && (
          <div>
            <h2 className='mb-2'>
              <Translation id='Screens.Patient.Read.Nhi.Logs.WebServiceLog.RequestBodyRaw' />
            </h2>
            <AceEditor
              defaultValue={data.requestBodyRaw}
              mode='xml'
              name='xmlContent'
              setOptions={{ useWorker: false }}
              style={{
                width: '100%',
              }}
              theme='monokai'
              height='400px'
              wrapEnabled
            />
            <hr />
            <h2 className='mb-2'>
              <Translation id='Screens.Patient.Read.Nhi.Logs.WebServiceLog.ResponseBodyRaw' />
            </h2>
            <AceEditor
              defaultValue={data.responseBodyRaw}
              mode='xml'
              name='xmlContent'
              setOptions={{ useWorker: false }}
              style={{
                width: '100%',
              }}
              theme='monokai'
              height='400px'
              wrapEnabled
            />
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};
