import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ProtectedRoute } from 'components';
import { urlSchema } from 'utils';

// Screens
import Create from './Create';
import Update from './Update';
import List from './List';
import Details from './Details';

const Specialties = ({ match }) => {
  const { path } = match;

  const routes = [
    {
      path: urlSchema.specialties.create,
      component: Create,
      right: 'CRUD',
    },
    {
      path: `${urlSchema.specialties.update}/:refSpecialty`,
      component: Update,
      right: 'CRUD',
    },
    {
      path: `${urlSchema.specialties.read}/:specialtyId`,
      component: Details,
      right: 'Reader',
    },
    { path: '/', component: List, right: 'Reader' },
  ];

  return (
    <Switch>
      {routes.map((item) => (
        <ProtectedRoute entity='Specialties' key={item.path} {...item} />
      ))}
    </Switch>
  );
};

export default Specialties;
