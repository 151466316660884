import React from 'react';
import { FastField, FieldArray, Formik, Form } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, Spinner } from 'reactstrap';
import cn from 'classnames';
import { historyShape, matchShape } from 'react-router-props';

import { service } from 'services';
import { api, sendToast, urlSchema, useFetch } from 'utils';
import { ApiError, Button, PageHeader } from 'components';
import messages from './messages';
import { ToastTypes } from '../../../constants';

const EditProfiles = ({ history, match }) => {
  const { idApplication } = match.params;
  const { formatMessage } = useIntl();
  const [application, isApplicationLoading] = useFetch(
    api.applications.getDetails(idApplication),
  );
  const [profiles, areProfilesLoading] = useFetch(api.profiles.get);

  const onSubmit = (values, { setSubmitting }) => {
    service
      .put(api.applications.putProfiles(values.idApplication), values)
      .then(({ data }) => {
        if (typeof data.succeeded !== 'undefined' && !data.succeeded) {
          sendToast(ToastTypes.error, {
            header: <FormattedMessage {...messages.FailTitle} />,
            body: (
              <FormattedMessage
                {...messages.FailBody}
                values={{ name: application.label }}
              />
            ),
          });
          return;
        }
        sendToast(ToastTypes.success, {
          header: <FormattedMessage {...messages.SuccessTitle} />,
          body: (
            <FormattedMessage
              {...messages.SuccessBody}
              values={{ name: application.label }}
            />
          ),
        });
        history.push(`${urlSchema.applications.read}/${idApplication}`);
      })
      .catch((error) => {
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.FailTitle} />,
          body: <ApiError {...{ error }} />,
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <FormattedMessage
          {...messages.Title}
          values={{ name: application.label }}
          tagName='h1'
        />
      </PageHeader>
      <section className='section'>
        <div className='section__body'>
          {isApplicationLoading || areProfilesLoading ? (
            <Spinner color='primary' size='lg' />
          ) : (
            <Formik
              initialValues={{
                idApplication,
                selectedProfiles: application.applicationsProfiles.map(
                  (el) => el.refProfile,
                ),
                searchString: '',
              }}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, values }) => (
                <Form>
                  <FastField name='searchString'>
                    {({ field }) => (
                      <Input
                        className='mb-3'
                        placeholder={formatMessage({
                          ...messages.Placeholder,
                        })}
                        size='lg'
                        type='text'
                        {...field}
                      />
                    )}
                  </FastField>
                  <FieldArray
                    name='selectedProfiles'
                    render={({ push, remove }) => (
                      <>
                        {profiles.map((profile) => {
                          if (
                            profile.label
                              .toLowerCase()
                              .includes(values.searchString.toLowerCase())
                          )
                            return (
                              <button
                                className={cn('combined-btn', {
                                  'combined-btn--success':
                                    values.selectedProfiles.findIndex(
                                      (id) => id === profile.idProfile,
                                    ) >= 0,
                                })}
                                key={profile.idProfile}
                                onClick={() => {
                                  const index =
                                    values.selectedProfiles.findIndex((id) => {
                                      return id === profile.idProfile;
                                    });
                                  if (index >= 0) {
                                    remove(index);
                                  } else push(profile.idProfile);
                                }}
                                type='button'
                              >
                                <span>{profile.code}</span>
                                {values.selectedProfiles.findIndex(
                                  (el) => el === profile.idProfile,
                                ) >= 0 ? (
                                  <i
                                    aria-hidden
                                    className='uil uil-check uil--md'
                                  />
                                ) : (
                                  <i
                                    aria-hidden
                                    className='uil uil-plus uil--md'
                                  />
                                )}
                              </button>
                            );

                          return null;
                        })}
                      </>
                    )}
                  />
                  <div className='mt-3 text-right'>
                    <Button
                      color='primary'
                      disabled={isSubmitting}
                      isProcessing={isSubmitting}
                      label='Validate'
                      size='lg'
                      type='submit'
                    />
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </section>
    </>
  );
};

EditProfiles.propTypes = {
  history: historyShape.isRequired,
  match: matchShape.isRequired,
};

export default EditProfiles;
