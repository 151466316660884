import { service } from 'services';
import { api } from 'utils';

export const Services = {
  getPartnerOperationsPartnersMine: () =>
    service.get(api.partnerOperations.getPartnersMine),
  getPartnerOperationsCsv: (partnerCode) =>
    service.get(api.partnerOperations.getCsv(partnerCode)),
  postPartnerOperationsCsv: (partnerCode, formData) =>
    service.post(api.partnerOperations.postCsv(partnerCode), formData),
  deletePartnerOperationsCsv: (partnerCode, csvPartnerUploadedId) =>
    service.delete(
      api.partnerOperations.deleteCsv(partnerCode, csvPartnerUploadedId),
    ),
};
