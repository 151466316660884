import React from 'react';
import PropTypes from 'prop-types';

const ModelProperty = ({ title, value }) => (
  <div className='model__property'>
    <h5 className='model__title'>{title}</h5>
    <p className='model__value'>{value || '-'}</p>
  </div>
);

ModelProperty.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default ModelProperty;
