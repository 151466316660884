/* eslint-disable unicorn/filename-case */
import { api } from '../../../utils';
import { service } from '../../index';
import { GenericErrorResponse } from '../../types';

export type AccessLogDetail = {
  nhiHistoryId: {
    previousValue: number;
    value: number;
    hasChanged: true;
  };
  oid: {
    previousValue: string;
    value: string;
    hasChanged: boolean;
  };
  nhiRegistrationNumber: {
    previousValue: string;
    value: string;
    hasChanged: boolean;
  };
  birthGender: {
    previousValue: 'Unknown' | 'Male' | 'Female';
    value: 'Unknown' | 'Male' | 'Female';
    hasChanged: boolean;
  };
  birthFirstName: {
    previousValue: string;
    value: string;
    hasChanged: boolean;
  };
  birthFirstNames: {
    previousValue: string;
    value: string;
    hasChanged: boolean;
  };
  birthLastName: {
    previousValue: string;
    value: string;
    hasChanged: boolean;
  };
  birthDate: {
    previousValue: string;
    value: string;
    hasChanged: boolean;
  };
  unQualifiedBirthDate: {
    previousValue: string;
    value: string;
    hasChanged: boolean;
  };
  birthPlaceInseeCode: {
    previousValue: string;
    value: string;
    hasChanged: boolean;
  };
  usedFirstName: {
    previousValue: string;
    value: string;
    hasChanged: boolean;
  };
  usedLastName: {
    previousValue: string;
    value: string;
    hasChanged: boolean;
  };
  status: {
    previousValue: 'Temporary' | 'Recovered' | 'Validated' | 'Qualified';
    value: 'Temporary' | 'Recovered' | 'Validated' | 'Qualified';
    hasChanged: boolean;
  };
  attribute: {
    previousValue: 'Homonymous' | 'Dubious' | 'Fictional';
    value: 'Homonymous' | 'Dubious' | 'Fictional';
    hasChanged: boolean;
  };
};

export const getNHIPatientAccessLogDetail = async (
  accessLogId: number,
): Promise<AccessLogDetail> => {
  try {
    const { data } = await service.get<GenericErrorResponse | AccessLogDetail>(
      api.manager.getNHIPatientAccessLogDetail(accessLogId),
    );

    if (data && 'succeeded' in data && data.succeeded === false) {
      throw new Error(data.errorSet[0].code);
    }

    return data as AccessLogDetail;
  } catch (error) {
    throw new Error(error.message);
  }
};
