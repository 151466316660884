import React from 'react';
import { Field } from 'formik';
import { useIntl } from 'react-intl';

import {
  Checkbox,
  FormInputFeedback,
  FormInput,
  FormSelect,
  Spinner,
} from 'components';
import { api, useFetch } from 'utils';
import messages from './messages';

const getConsolidatedProducts = (products) =>
  products.map((p) => ({ ...p, value: p.code }));

const FileImportClient = ({ fileImportClients, context }) => {
  const intl = useIntl();

  const [products, areProductsLoading] = useFetch(api.products.getListItems);

  return areProductsLoading ? (
    <Spinner color='primary' size='lg' />
  ) : (
    <>
      <FormInput
        hasRawError
        id='codeClient'
        label={intl.formatMessage(messages.Partner)}
        name='codeClient'
        required
        type='text'
      />
      <FormInput
        hasRawError
        id='codeEntity'
        label={intl.formatMessage(messages.Entity)}
        name='codeEntity'
        required
        type='text'
      />
      {products.length ? (
        <Field name='refProduct'>
          {({ field, form, meta }) => (
            <FormSelect
              {...{ meta }}
              defaultValue={
                fileImportClients?.refProduct.label
                  ? getConsolidatedProducts(products).find(
                      (el) => el.id === fileImportClients.refProduct.id,
                    )
                  : null
              }
              hasError={Boolean(meta.touched && meta.error)}
              label={intl.formatMessage(messages.AssociatedProduct)}
              placeholder={intl.formatMessage(messages.ProductPlaceHolder)}
              name={field.name}
              onChange={(opt) => {
                form.setFieldValue(field.name, opt.id);
              }}
              options={getConsolidatedProducts(products)}
              required
            />
          )}
        </Field>
      ) : (
        <Spinner color='primary' />
      )}
      {context === 'update' && (
        <div>
          <Field name='status'>
            {({ field, form }) => (
              <Checkbox {...field} checked={form.values.status} id='status'>
                {intl.formatMessage(messages.Activated)}
              </Checkbox>
            )}
          </Field>
          <FormInputFeedback name='status' />
        </div>
      )}
    </>
  );
};

export default FileImportClient;
