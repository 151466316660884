import { defineMessages } from 'react-intl';

import messages from '../messages';

export default defineMessages({
  ...messages,
  ButtonCreate: {
    id: 'Buttons.Create',
    defaultMessage: 'Error.Buttons.Create',
  },
  ClientsPlaceholder: {
    id: 'Components.FormList.ClientsPlaceholder',
    defaultMessage: 'Error.Components.FormList.ClientsPlaceholder',
  },
  Title: {
    id: 'Screens.PartnerOperators.Create.Title',
    defaultMessage: 'Error.Screens.PartnerOperators.Create.Title',
  },
  ToastFail: {
    id: 'Toasts.PartnerOperators.Create.Fail',
    defaultMessage: 'Error.Toasts.PartnerOperators.Create.Fail',
  },
  ToastSuccess: {
    id: 'Toasts.PartnerOperators.Create.Success',
    defaultMessage: 'Error.Toasts.PartnerOperators.Create.Success',
  },
});
