import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  Label,
  Input,
  InputGroupAddon,
  FormGroup,
  InputGroup
} from 'reactstrap';
import { Field } from 'formik';

import { BooleanCardContainer, BooleanContainerContent } from './elements';
import FormErrorMessage from '../FormErrorMessage';
import FormRadioButton from '../FormRadioButton';
import FormLabel from '../FormLabel';

const FormBooleanCard = ({
  title,
  name,
  radioName,
  values,
  placeholder,
  content,
  inputType,
  unit,
  children,
  required,
  backgroundColor
}) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  return (
    <BooleanCardContainer backgroundColor={backgroundColor}>
      <FormGroup>
        <FormLabel required={required}>{title}</FormLabel>
        <Label check for={`${name}-yes`}>
          <Field
            component={FormRadioButton}
            id={`${radioName}-yes`}
            name={radioName}
            inputValue='true'
            label={formatMessage({ id: 'Shared.Yes' })}
          />
        </Label>
        <Label check for={`${name}-no`}>
          <Field
            component={FormRadioButton}
            id={`${radioName}-no`}
            name={radioName}
            inputValue='false'
            label={formatMessage({ id: 'Shared.No' })}
          />
        </Label>
        {values[radioName] === 'true' && (
          <BooleanContainerContent>
            <InputGroup>
              {children || (
                <>
                  <Field name={name}>
                    {({ field, form: { touched, errors } }) => (
                      <Input
                        name={name}
                        placeholder={placeholder}
                        type={inputType}
                        invalid={Boolean(touched[name] && errors[name])}
                        {...field}
                      />
                    )}
                  </Field>
                  {unit && (
                    <InputGroupAddon addonType='append'>{unit}</InputGroupAddon>
                  )}
                </>
              )}
              <FormErrorMessage name={name} />
              <p>{content}</p>
            </InputGroup>
          </BooleanContainerContent>
        )}
      </FormGroup>
    </BooleanCardContainer>
  );
};

FormBooleanCard.defaultProps = {
  children: null,
  unit: null,
  content: '',
  placeholder: '',
  inputType: 'text',
  backgroundColor: 'gray-lightest'
};

FormBooleanCard.propTypes = {
  values: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  radioName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  content: PropTypes.string,
  inputType: PropTypes.oneOf(['number', 'textarea', 'text', 'email']),
  unit: PropTypes.string,
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
  required: PropTypes.bool.isRequired
};

export default FormBooleanCard;
