import React from 'react';
import { useIntl } from 'react-intl';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useParams } from 'react-router-dom';
import { Row, Col, FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import * as Yup from 'yup';

import { Button, FormPlacesAutoComplete } from 'components';
import { service } from 'services';
import { ToastTypes } from '../../../../../constants';
import { api, sendToast } from 'utils';
import { ButtonsWrapper } from 'themes/jupiter/elements';
import messages from './messages';

const UpdateAddressTab = ({ address, setPrerequisitesTrigger }) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const { patientId } = useParams();

  const validationSchema = Yup.object().shape({
    address1: Yup.string().required(formatMessage(messages.addressError)),
    address2: Yup.string(),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const { data } = await service.put(
        api.admin.updatePatientAddress(patientId),
        values,
      );

      if (data.succeeded === false) {
        throw new Error(data.errorSet[0].code);
      }

      setPrerequisitesTrigger(Math.random());
      sendToast(ToastTypes.success, {
        header: formatMessage({
          id: 'Shared.Success',
          defaultMessage: 'Error.Shared.Success',
        }),
        body: formatMessage({
          id: 'Shared.Toast.Update.Success',
          defaultMessage: 'Error.Shared.Toast.Update.Success',
        }),
      });
    } catch (error) {
      sendToast(ToastTypes.error, {
        header: formatMessage({
          id: 'Shared.Error',
          defaultMessage: 'Error.Shared.Error',
        }),
        body: formatMessage({
          id: 'Shared.Toast.Update.Fail',
          defaultMessage: 'Error.Shared.Toast.Update.Fail',
        }),
      });
      console.log(
        'An unexpected error happened while updating patient adress',
        error.message,
      );
    } finally {
      setSubmitting(false);
    }
  };

  const validate = ({ city, zipCode, codeCountry }) => {
    if (!city || !zipCode || !codeCountry) {
      return {
        address1: formatMessage(messages.addressError),
      };
    }
    return {};
  };

  return (
    <>
      <Formik
        initialValues={{
          ...address,
          googleAddress: '',
          address1: address.address1 ?? '',
          address2: address.address2 ?? '',
        }}
        validationSchema={validationSchema}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ touched, errors, isSubmitting }) => (
          <Form>
            <Row>
              <Col md='12'>
                <Field
                  name='address1'
                  placeholder={formatMessage(messages.addressPlaceholder)}
                  label={formatMessage(messages.addressLabel)}
                  component={FormPlacesAutoComplete}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col md='12'>
                <Field name='address2'>
                  {({ field }) => (
                    <FormGroup>
                      <Label for={field.name}>
                        {formatMessage(messages.address2Label)}
                      </Label>
                      <Input
                        placeholder={formatMessage(
                          messages.address2Placeholder,
                        )}
                        {...field}
                        invalid={!!errors[field.name] && !!touched[field.name]}
                      />
                      <ErrorMessage name={field.name}>
                        {(msg) => (
                          <FormFeedback valid={false}>{msg}</FormFeedback>
                        )}
                      </ErrorMessage>
                    </FormGroup>
                  )}
                </Field>
              </Col>
            </Row>
            <ButtonsWrapper bottom>
              <Button
                type='submit'
                color='primary'
                label='Confirm'
                disabled={isSubmitting}
              />
            </ButtonsWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UpdateAddressTab;
