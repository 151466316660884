import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { historyShape } from 'react-router-props';
import { ReturnButtonContainer } from './elements';

const ReturnButton = ({ history, returnUrl, onClick, isLink }) => {
  const goBack = () => {
    if (returnUrl) {
      history.push(returnUrl);
    } else {
      history.goBack();
    }
  };
  return (
    <ReturnButtonContainer>
      {isLink ? (
        <a className='btn btn-warning' href={returnUrl}>
          <i className='uil uil-arrow-left' />
          <FormattedMessage
            id='Buttons.Back'
            defaultMessage='Error.Buttons.Back'
            data-tracking='ReturnButton'
          />
        </a>
      ) : (
        <Button color='warning' onClick={onClick || goBack}>
          <i className='uil uil-arrow-left' />
          <FormattedMessage
            id='Buttons.Back'
            defaultMessage='Error.Buttons.Back'
            data-tracking='ReturnButton'
          />
        </Button>
      )}
    </ReturnButtonContainer>
  );
};

ReturnButton.defaultProps = { returnUrl: null, onClick: null, isLink: false };

ReturnButton.propTypes = {
  history: historyShape.isRequired,
  returnUrl: PropTypes.string,
  onClick: PropTypes.func,
  isLink: PropTypes.bool,
};

export default withRouter(ReturnButton);
