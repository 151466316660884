import { makeObservable, observable, computed } from "mobx";
import { Services } from "../https/services";

class SurveyStore {
    _questionnaires = [];
    constructor() {
        makeObservable(this, {
            _questionnaires: observable.shallow,
            questionnaires: computed,
        })
    }
    get questionnaires() {
        return this._questionnaires
    }

    set questionnaires(questionnaires) {
        this._questionnaires = questionnaires
    }

    async getAllSurveys(searchOptions) {
        this.questionnaires = [];
        const result = await Services.questionnaires(searchOptions);
        this.questionnaires = result.data;
    }
}

export default new SurveyStore();