import { makeAutoObservable, runInAction } from 'mobx';

import { service } from 'services';
import { api, useOidc } from 'utils';

class Configuration {
  _verificationsTypes = [];
  _profiles = [];

  constructor() {
    makeAutoObservable(this);
  }

  initialize() {
    if (useOidc().profile.roles.includes('VerificationsTypesItems'))
      try {
        Promise.all([service.get(api.verificationsTypes.getListItems)]).then(
          (responses) => {
            runInAction(() => {
              this.verificationsTypes = responses[0].data;
            });
          },
        );
      } catch (error) {
        console.log(error);
      }
  }

  getRefWebServiceType() {
    return this._verificationsTypes.find((element) => element.code === 'WS').id;
  }

  get profiles() {
    return this._profiles;
  }

  set profiles(newProfiles) {
    this._profiles = newProfiles;
  }

  get verificationsTypes() {
    return this._verificationsTypes;
  }

  set verificationsTypes(newVerificationsTypes) {
    this._verificationsTypes = newVerificationsTypes;
  }
}

export default new Configuration();
