import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonSelect: {
    id: 'Buttons.Select',
    defaultMessage: 'Error.Buttons.Select',
    description: 'Label of button to select a specialty',
  },
  CTA: {
    id: 'Offers.CTA',
    defaultMessage: 'Error.Offers.CTA',
    description: "Offer' button text",
  },
  DefaultSpecialty: {
    id: 'Offers.DefaultSpecialty',
    defaultMessage: 'Error.Offers.DefaultSpecialty',
    description: "Offer's default specialty",
  },
  Description: {
    id: 'Offers.Description',
    defaultMessage: 'Error.Offers.Description',
    description: "Offer's description",
  },
  IsValid: { id: 'Offers.IsValid', defaultMessage: 'Error.Offers.IsValid' },
  MedicalControlService: {
    id: 'Offers.MedicalControlService',
    defaultMessage: 'Error.Offers.MedicalControlService',
  },
  Name: {
    id: 'Offers.Name',
    defaultMessage: 'Error.Offers.Name',
    description: "Offer's name",
  },
  RelatedProducts: {
    id: 'Offers.RelatedProducts',
    defaultMessage: 'Error.Offers.RelatedProducts',
    description: 'Related products to an offer',
  },
  RelatedProfiles: {
    id: 'Offers.RelatedProfiles',
    defaultMessage: 'Error.Offers.RelatedProfiles',
    description: 'Related profiles to an offer',
  },
  Options: {
    id: 'Offers.Options',
    defaultMessage: 'Error.Offers.Options',
  },
  NotEditable: {
    id: 'Shared.NotEditable',
    defaultMessage: 'Shared.NotEditable',
  },
  Code: {
    id: 'Screens.Offers.List.Code',
    defaultMessage: 'Error.Screens.Offers.List.Code',
  },
  CodePlaceholder: {
    id: 'Offers.Create.Placeholder',
    defaultMessage: 'Error.Offers.Create.Placeholder',
  },
  FollowUpEncounter: {
    id: 'Offers.FollowUpEncounter',
    defaultMessage: 'Error.Offers.FollowUpEncounter',
  },
});
