import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonBack: {
    id: 'Buttons.Back',
    defaultMessage: 'Error.Buttons.Back',
  },
  SuccessTitle: {
    id: 'Message.Success',
    defaultMessage: 'Error.Message.Success',
  },
  SuccessBody: {
    id: 'Screens.Customers.CreateCustomer.SuccessBody',
    defaultMessage: 'Error.Screens.Customers.CreateCustomer.SuccessBody',
  },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
  },
  FailBody: {
    id: 'Screens.Customers.CreateCustomer.FailBody',
    defaultMessage: 'Error.Screens.Customers.CreateCustomer.FailBody',
  },
  Title: {
    id: 'Screens.Customers.CreateCustomer.Title',
    defaultMessage: 'Error.Screens.Customers.CreateCustomer.Title',
    description: 'Title of Customer create screen',
  },
  FieldRequired: {
    id: 'Model.FieldRequired',
    defaultMessage: 'Error.Model.FieldRequired',
  },
  Organization: {
    id: 'Customers.Organization',
    defaultMessage: 'Error.Customers.Organization',
    description: 'Label organization',
  },
});
