import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import {
  Bar,
  Dot,
  DotChecked,
  DotContainer,
  Flex,
  NavigationLinkWrapper,
} from './elements';

const ItemRedirection = ({ path, onClick, label }) => {
  if (path) return <NavLink to={path}>{label}</NavLink>;
  if (onClick)
    return (
      <button className='btn btn-link' onClick={onClick}>
        {label}
      </button>
    );

  return null;
};

const BreadcrumbItem = ({
  active,
  checked,
  index,
  isLast,
  label,
  path,
  onClick,
}) => {
  return (
    <NavigationLinkWrapper
      active={active}
      checked={checked}
      isFirst={index === 1}
      isLast={isLast}
    >
      <ItemRedirection path={path} onClick={onClick} label={label} />

      <Flex>
        <Bar />
        <DotContainer>
          {checked ? (
            <DotChecked className='uil uil-check' aria-hidden />
          ) : (
            <Dot />
          )}
        </DotContainer>
        <Bar />
      </Flex>
    </NavigationLinkWrapper>
  );
};

BreadcrumbItem.defaultProps = {
  isLast: false,
  path: '',
};

BreadcrumbItem.propTypes = {
  active: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  label: PropTypes.element.isRequired,
  path: PropTypes.string,
  isLast: PropTypes.bool,
};

export default BreadcrumbItem;
