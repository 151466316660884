import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Badge, Col, Row, Spinner } from 'reactstrap';
import moment from 'moment';

import { api, urlSchema, useFetch } from 'utils';
import { PageHeader, RoleManager, LinksList } from 'components';

const Read = () => {
  const { actualityId } = useParams();
  const [actuality, isActualityLoading] = useFetch(
    api.actualities.getActuality(actualityId),
  );
  const [profiles, areProfilesLoading] = useFetch(api.profiles.get);
  const [products, areProductsLoading] = useFetch(api.admin.getProducts);

  return (
    <>
      <PageHeader>
        <FormattedMessage
          tagName='h1'
          values={{ code: actuality.title || '' }}
          id='Screens.Actuality.Read.Title'
        />
        <RoleManager entity='Roles' right='CRUD'>
          <Link
            className='btn btn-primary'
            to={`${urlSchema.actualities.update}/${actuality.idActuality}`}
          >
            <FormattedMessage id='Buttons.Edit' />
          </Link>
        </RoleManager>
      </PageHeader>
      <Row>
        <Col md='8'>
          {isActualityLoading || areProfilesLoading || areProductsLoading ? (
            <Spinner color='primary' size='lg' />
          ) : (
            <section className='section'>
              <div className='section__body'>
                <table className='table table-bordered table-hover'>
                  <tbody>
                    <tr>
                      <th scope='row'>
                        <FormattedMessage id='Shared.State' />
                      </th>
                      <td>
                        <Badge
                          color={
                            actuality.valid === true ? 'success' : 'danger'
                          }
                        >
                          <div className='d-flex align-items-center'>
                            {actuality.valid ? (
                              <>
                                <span className='mr-1'>
                                  <FormattedMessage id='Shared.Valid' />
                                </span>
                                <i
                                  aria-hidden
                                  className='uil uil-check-circle'
                                />
                              </>
                            ) : (
                              <>
                                <span className='mr-1'>
                                  <FormattedMessage id='Shared.Invalid' />
                                </span>
                                <i
                                  aria-hidden
                                  className='uil uil-times-circle'
                                />
                              </>
                            )}
                          </div>
                        </Badge>
                      </td>
                    </tr>
                    <tr>
                      <th scope='row'>
                        <FormattedMessage id='Screens.Actuality.Title' />
                      </th>
                      <td>{actuality.title}</td>
                    </tr>
                    <tr>
                      <th scope='row'>
                        <FormattedMessage id='Screens.Actuality.Resume' />
                      </th>
                      <td colSpan='2'>{actuality.body}</td>
                    </tr>
                    <tr>
                      <th scope='row'>
                        <FormattedMessage id='Shared.Link' />
                      </th>
                      <td colSpan='2'>
                        <a rel='noopener noreferrer' href={actuality.url}>
                          {actuality.url}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <th scope='row' className='text-muted'>
                        <FormattedMessage id='Shared.AccountCreationDate' />
                      </th>
                      <td colSpan='2'>
                        {moment(actuality.dateCreation).format('DD/MM/YYYY')}
                      </td>
                    </tr>
                    <tr>
                      <th scope='row' className='text-success'>
                        <FormattedMessage id='Screens.Actuality.PublicationDate' />
                      </th>
                      <td colSpan='2'>
                        {moment(actuality.dateStart).format('DD/MM/YYYY')}
                      </td>
                    </tr>
                    <tr>
                      <th scope='row' className='text-danger'>
                        <FormattedMessage id='Screens.VoucherGroups.add.expirationDate' />
                      </th>
                      <td colSpan='2'>
                        {moment(actuality.dateEnd).format('DD/MM/YYYY')}
                      </td>
                    </tr>
                    <tr>
                      <th scope='row'>
                        <FormattedMessage id='Screens.Clients.ProductTags.Product' />
                      </th>
                      <td colSpan='2'>
                        {actuality.refProduct ? (
                          products.find(
                            (el) => el.idProduct === actuality.refProduct,
                          ).code
                        ) : (
                          <FormattedMessage id='Model.EmptyProduct' />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope='row'>
                        <FormattedMessage id='Model.Role.Ranking' />
                      </th>
                      <td colSpan='2'>{actuality.ranking}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          )}
        </Col>
        <Col md='4'>
          {isActualityLoading || areProfilesLoading || areProductsLoading ? (
            <Spinner color='primary' size='lg' />
          ) : (
            <section className='section'>
              <header className='section__header'>
                <FormattedMessage id='Offers.RelatedProfiles' tagName='h2' />
              </header>

              <div className='section__body'>
                {actuality.actualitiesProfiles.length > 0 ? (
                  <LinksList
                    areInnerLinks
                    color='warning'
                    data={actuality.actualitiesProfiles}
                    idKey={(el) => el.refProfile}
                    labelKey={(elem) =>
                      profiles.find((el) => el.idProfile === elem.refProfile)
                        .label
                    }
                    urlBase={urlSchema.profiles.read}
                  />
                ) : (
                  <p className='text-secondary m-0'>
                    <FormattedMessage id='Shared.NoElement' />
                  </p>
                )}
              </div>
            </section>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Read;
