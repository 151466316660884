import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { PageHeader, Section, SectionBody } from 'components';
import { Partner } from 'models';

import { service } from 'services';
import { api, sendToast, urlSchema } from 'utils';
import { Methods, ToastTypes } from '../../../constants';
import messages from './messages';
import initialValues from './initialValues';

const Create = () => {
  const history = useHistory();

  const onSubmit = (values, { setSubmitting }) => {
    service
      .post(api.manager.postPartner, values)
      .then(({ data }) => {
        sendToast(ToastTypes.success, {
          header: <FormattedMessage {...messages.actionSuccess} />,
          body: (
            <FormattedMessage
              {...messages.actionSuccessResponse}
              values={{
                partnerName: values.name,
              }}
            />
          ),
        });
        setSubmitting(false);
        history.push(`${urlSchema.readPartner}/${data.idEligiblePartner}`);
      })
      .catch(() => {
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.actionError} />,
          body: (
            <FormattedMessage
              {...messages.actionErrorResponse}
              values={{
                partnerName: values.name,
              }}
            />
          ),
        });
      });
  };

  return (
    <>
      <PageHeader>
        <FormattedMessage {...messages.Title} tagName='h1' />
      </PageHeader>
      <Row>
        <Col md='8' lg='6'>
          <Section>
            <SectionBody>
              <Partner
                initialValues={initialValues}
                method={Methods.POST}
                onSubmit={onSubmit}
              />
            </SectionBody>
          </Section>
        </Col>
      </Row>
    </>
  );
};

export default Create;
