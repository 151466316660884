import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { DotLink } from './elements';

const SidebarLink = ({ link, text }) => (
  <NavLink to={link}>
    <DotLink />
    <span>{text}</span>
  </NavLink>
);

SidebarLink.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default SidebarLink;
