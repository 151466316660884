import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Badge, Row, Col } from 'reactstrap';

import messages from '../messages';
import { LegalInformation } from '../../../../models/Doctor/components/LegalInformation';

const matchingIdentifierType = { PA: 0, PR: 8 };

export const ProfessionalInformation = ({ contratTypes, data }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <div className='mb-3'>
        <FormattedMessage {...messages.ProfessionalInformation} tagName='h2' />
      </div>
      <div className='model__actions mb-2'>
        <Badge color={data.generateFeesNotes ? 'success' : 'danger'}>
          <div className='d-flex align-items-center'>
            {data.generateFeesNotes ? (
              <>
                <span className='mr-1'>
                  <FormattedMessage {...messages.FeeNotes} />
                </span>
                <i aria-hidden className='uil uil-check-circle' />
              </>
            ) : (
              <>
                <span className='mr-1'>
                  <FormattedMessage {...messages.FeeNotes} />
                </span>
                <i aria-hidden className='uil uil-times-circle' />
              </>
            )}
          </div>
        </Badge>
        <Badge color={data.cpsReader ? 'success' : 'warning'}>
          <div className='d-flex align-items-center'>
            {data.cpsReader ? (
              <>
                <span className='mr-1'>
                  <FormattedMessage {...messages.ReadCPS} />
                </span>
                <i aria-hidden className='uil uil-check-circle' />
              </>
            ) : (
              <>
                <span className='mr-1'>
                  <FormattedMessage {...messages.ReadCPS} />
                </span>
                <i aria-hidden className='uil uil-question-circle' />
              </>
            )}
          </div>
        </Badge>
        <Badge color={data.cpsCard ? 'success' : 'warning'}>
          <div className='d-flex align-items-center'>
            {data.cpsCard ? (
              <>
                <span className='mr-1'>
                  <FormattedMessage {...messages.CardCPS} />
                </span>
                <i aria-hidden className='uil uil-check-circle' />
              </>
            ) : (
              <>
                <span className='mr-1'>
                  <FormattedMessage {...messages.CardCPS} />
                </span>
                <i aria-hidden className='uil uil-question-circle' />
              </>
            )}
          </div>
        </Badge>
        <Badge color={data.prescriptionBook ? 'success' : 'warning'}>
          <div className='d-flex align-items-center'>
            {data.prescriptionBook ? (
              <>
                <span className='mr-1'>
                  <FormattedMessage {...messages.PrescriptionPad} />
                </span>
                <i aria-hidden className='uil uil-check-circle' />
              </>
            ) : (
              <>
                <span className='mr-1'>
                  <FormattedMessage {...messages.PrescriptionPad} />
                </span>
                <i aria-hidden className='uil uil-question-circle' />
              </>
            )}
          </div>
        </Badge>
        <Badge color={data.refDocumentScanSignature ? 'success' : 'warning'}>
          <div className='d-flex align-items-center'>
            {data.refDocumentScanSignature ? (
              <>
                <span className='mr-1'>
                  <FormattedMessage {...messages.ElectronicSignature} />
                </span>
                <i aria-hidden className='uil uil-check-circle' />
              </>
            ) : (
              <>
                <span className='mr-1'>
                  <FormattedMessage {...messages.ElectronicSignature} />
                </span>
                <i aria-hidden className='uil uil-question-circle' />
              </>
            )}
          </div>
        </Badge>
      </div>
      <div className='row'>
        <div className='col-lg-6'>
          <div className='model__property'>
            <h3 className='model__title'>
              {formatMessage({
                id: `Model.Doctor.${data.practitionerIdentifierNumberType}Number`,
                defaultMessage: `Error.Model.Doctor.${data.practitionerIdentifierNumberType}Number`,
              })}
            </h3>
            {data.collegeNumber && (
              <p className='model__value'>{data.collegeNumber}</p>
            )}
          </div>
        </div>
        <div className='col-lg-6'>
          <div className='model__property'>
            <h3 className='model__title'>
              {formatMessage({
                ...messages.OrdinalQualification,
              })}
            </h3>
            <p className='model__value'>{data.ordinalQualificationLabel}</p>
          </div>
        </div>
        <div className='col-lg-6'>
          <div className='model__property'>
            <h3 className='model__title'>
              {formatMessage({
                ...messages.ContractType,
              })}
            </h3>
            <p className='model__value'>
              {
                contratTypes.find(
                  (element) => element.id === data.feesContractTypeId,
                ).label
              }
            </p>
          </div>
        </div>
      </div>
      <LegalInformation
        identifier={{
          id: data.collegeNumber,
          type: matchingIdentifierType[data.practitionerIdentifierNumberType],
        }}
      />
    </>
  );
};

ProfessionalInformation.displayName =
  'Screens_Doctors_Read_ProfessionalInformation';
