import styled from 'styled-components';

const BooleanCardContainer = styled.div`
  background-color: ${(props) => props.theme.color(props.backgroundColor)};
  border-radius: ${(props) => props.theme.space(1)};
  padding: ${(props) => props.theme.space(3)};
  margin-bottom: ${(props) => props.theme.space(2)};
  p {
    font-weight: bold;
  }

  label {
    margin-right: ${(props) => props.theme.space(2)};

    &:first-child {
      display: block;
      margin: 0 0 ${(props) => props.theme.space(2)} 0;
    }
  }

  .form-group {
    margin-bottom: 0;
  }

  .input-group-text {
    background-color: ${(props) => props.theme.white};
  }
`;

const BooleanContainerContent = styled.div`
  margin-top: ${(props) => props.theme.space(2)};

  p {
    margin-top: ${(props) => props.theme.space(2)};
    margin-bottom: 0;
    font-size: ${(props) => props.theme.fontSizeSm()}px;
    color: ${(props) => props.theme.booleanCardContentText};
  }
`;

const RadioWrapper = styled.div`
  background-color: ${(props) => props.theme.white};
`;

export { BooleanCardContainer, BooleanContainerContent, RadioWrapper };
