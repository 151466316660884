import { defineMessages } from 'react-intl';

export default defineMessages({
  Title: {
    id: 'Screens.Vouchers.List.Title',
    defaultMessage: 'Error.Screens.Vouchers.List.Title',
    description: 'Vouchers page title',
  },
  CreationDate: {
    id: 'Model.CreationDate',
    defaultMessage: 'Error.Model.CreationDate',
  },
  Code: {
    id: 'Vouchers.Code',
    defaultMessage: 'Error.Vouchers.Code',
    description: 'Title Code',
  },
  ProductDisplayLabel: {
    id: 'Vouchers.ProductDisplayLabel',
    defaultMessage: 'Error.Vouchers.ProductDisplayLabel',
    description: 'Title ProductDisplayLabel',
  },
  DateExpiration: {
    id: 'Vouchers.DateExpiration',
    defaultMessage: 'Error.Vouchers.DateExpiration',
    description: 'Title DateExpiration',
  },
  DateCreation: {
    id: 'Vouchers.DateCreation',
    defaultMessage: 'Error.Vouchers.DateCreation',
    description: 'Title DateCreation',
  },
  NoElement: {
    id: 'Shared.NoElement',
    defaultMessage: 'Error.Shared.NoElement',
  },
  ButtonsRead: {
    id: 'Buttons.Read',
    defaultMessage: 'Error.Buttons.Read',
  },
  CreationDate: {
    id: 'Model.CreationDate',
    defaultMessage: 'Error.Model.CreationDate',
  },
  ExpirationDate: {
    id: 'Model.ExpirationDate',
    defaultMessage: 'Error.Model.ExpirationDate',
  },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
    description: 'Toast title in case of Error',
  },
  FailBody: {
    id: 'Message.Api.Error',
    defaultMessage: 'Error.Message.Api.Error',
    description: 'Toast body in case of error',
  },
});
