import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  Button,
  Checkbox,
  FormInputFeedback,
  PageHeader,
  Section,
  SectionBody,
  Spinner,
  FormInput,
} from 'components';
import { Customer } from 'models';
import { ButtonsWrapper } from 'themes/jupiter/elements';
import { service } from 'services';
import { api, sendToast, urlSchema, useFetch } from 'utils';
import { ToastTypes } from '../../../constants';
import messages from './messages';
import validationSchema from './validationShema';

const Update = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { idCustomer } = useParams();
  const [formLogoData, setFormLogoData] = useState();
  const [client, isClientLoading] = useFetch(
    api.admin.getCustomerDetails(idCustomer),
  );

  const onSubmit = (values, { setSubmitting }) => {
    service
      .put(api.clients.put(idCustomer), values)
      .then(({ data }) => {
        if (data.succeeded === false) {
          setSubmitting(false);
          sendToast(ToastTypes.error, {
            header: <FormattedMessage {...messages.FailTitle} />,
            body: <FormattedMessage {...messages.FailBody} />,
          });
          return;
        }
        if (values.logoFile !== undefined && values.logoFile !== null) {
          service
            .post(api.admin.postCustomerLogo(idCustomer), formLogoData)
            .then(() => {
              sendToast(ToastTypes.success, {
                header: <FormattedMessage {...messages.SuccessTitle} />,
                body: <FormattedMessage {...messages.SuccessBody} />,
              });
              setSubmitting(false);
              history.push(`${urlSchema.clients.read}/${idCustomer}`);
            })
            .catch(() => {
              sendToast(ToastTypes.error, {
                header: <FormattedMessage {...messages.FailTitle} />,
                body: <FormattedMessage {...messages.FailBody} />,
              });
            });
        } else {
          sendToast(ToastTypes.success, {
            header: <FormattedMessage {...messages.SuccessTitle} />,
            body: <FormattedMessage {...messages.SuccessBody} />,
          });
          setSubmitting(false);
          history.push(`${urlSchema.clients.read}/${idCustomer}`);
        }
      })
      .catch(() => {
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.FailTitle} />,
          body: <FormattedMessage {...messages.FailBody} />,
        });
        setSubmitting(false);
      });
  };

  if (isClientLoading)
    return (
      <Section>
        <SectionBody>
          <Spinner size='lg' />
        </SectionBody>
      </Section>
    );

  return (
    <>
      <PageHeader>
        <FormattedMessage
          {...messages.Edit}
          values={{ customerLabel: client.label }}
          tagName='h1'
        />
      </PageHeader>
      <Row>
        <Col lg='6'>
          <Section>
            <SectionBody>
              <Formik
                initialValues={{
                  ...client,
                  refClientSellsy:
                    client.refClientSellsy === '0000000000'
                      ? ''
                      : client.refClientSellsy,
                  labelClientSellsy: client.labelClientSellsy ?? '',
                }}
                validationSchema={validationSchema(formatMessage)}
                onSubmit={onSubmit}
              >
                {({ isSubmitting, values, ...props }) => (
                  <Form>
                    <Customer
                      customerLogo={client}
                      setFormLogoData={setFormLogoData}
                      {...props}
                    />
                    <FormInput
                      id='universeDisplayLabel'
                      label={formatMessage(messages.Organization)}
                      name='universeDisplayLabel'
                      disabled
                      required
                      type='text'
                    />
                    <div className='mb-3'>
                      <Field name='valid'>
                        {({ field }) => (
                          <Checkbox
                            {...field}
                            checked={values.valid}
                            id='valid'
                          >
                            <FormattedMessage {...messages.CheckBoxe} />
                          </Checkbox>
                        )}
                      </Field>
                      <FormInputFeedback name='valid' />
                    </div>
                    <ButtonsWrapper bottom>
                      <Button
                        color='primary'
                        label='Update'
                        size='lg'
                        data-tracking='CustomerUpdateSubmit'
                        type='submit'
                      />
                    </ButtonsWrapper>
                  </Form>
                )}
              </Formik>
            </SectionBody>
          </Section>
        </Col>
      </Row>
    </>
  );
};

export default Update;
