import styled, { css } from 'styled-components';

const AddRoomFormWrapper = styled.div`
  ${({ theme, hasError }) => css`
    margin-bottom: ${theme.space(3)};

    .row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      margin: 0;
      padding: ${theme.space(2)} ${theme.space(3)} ${theme.space(3)} 0px;
      border-radius: ${theme.borderRadiusSm};
      border: 1px solid
        ${hasError ? theme.color('danger') : theme.grayShade(400)};

      .form-group {
        margin-bottom: 0;
        padding: ${theme.space(1)} ${theme.space(1)} ${theme.space(2)}
          ${theme.space(1)};

        p {
          position: absolute;
        }
      }

      .col-md-2 {
        padding: 0;

        .btn {
          width: 100%;
          margin-bottom: ${theme.space(2)};
        }
      }
    }
  `}
`;

export default AddRoomFormWrapper;
