import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Field, FastField, useFormikContext } from 'formik';
import { FormGroup } from 'reactstrap';
import { isNil } from 'lodash';

import { LegalInformation } from './components/LegalInformation';
import {
  Checkbox,
  FormLabel,
  FormInput,
  FormInputFeedback,
  FormSelect,
  FormRadioButton,
  FormInputLogo,
  FormIntlTelInput,
  FormPlacesAutoComplete,
  Spinner,
  Translation,
} from '../../components';
import { service } from '../../services';
import { api, useFetch } from '../../utils';
import { RPPSInput } from './components/RPPSInput';
import { DoctorTitle, DoctorAccountTypes } from '../../constants';

const Doctor = ({
  setFormLogoData,
  type,
  doctorDocument,
  doctorDirectoryDataFromUpdate,
}) => {
  // Formik properties
  const { errors, values, setFieldValue } = useFormikContext();
  const { formatMessage, locale } = useIntl();
  const [areProfilesLoading, setAreProfilesLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [contractType, areContractTypeLoading] = useFetch(
    api.doctors.getContractTypes,
  );
  const [ordinalQualifications, areOrdinalQualificationsLoading] = useFetch(
    api.doctors.getOrdinalQualifications,
  );
  const [universes, areUniversesLoading] = useFetch(api.admin.getUnivers);
  const [doctorDirectoryData, setDoctorDirectoryData] = useState(
    doctorDirectoryDataFromUpdate,
  );
  const titles = [{ label: '-', value: '' }, ...DoctorTitle];

  useEffect(() => {
    const getProfiles = async () => {
      // Codie isn't used anylonger but is still available in the back-end side
      // Until it is deleted from there, we filter the universes so that we only
      // have the MD universe to choose the profiles from
      if (!areUniversesLoading && universes.length > 0) {
        setAreProfilesLoading(true);
        const referenceUniverseMD = universes.find(
          (element) => element.code === 'MD',
        ).id;
        setFieldValue('refUniverse', referenceUniverseMD);
        try {
          const result = await service.get(
            `${api.profiles.get}?RefUniverse=${referenceUniverseMD}&SearchString=.DOCTOR`,
          );
          setProfiles(result.data);
          setAreProfilesLoading(false);
        } catch (error) {
          console.error(error);
        }
      } else {
        setAreProfilesLoading(false);
      }
    };
    getProfiles();
  }, [universes]);

  const renderMainFields = () => (
    <div className='row'>
      <div className='col-lg-6'>
        <div className='mb-3'>
          <Translation id='Screens.Doctors.Create.PersonalInfo' tagName='h2' />
        </div>
        <div className='row'>
          <div className='col-lg-4'>
            <Field name='title'>
              {({ field, meta, form }) => (
                <FormSelect
                  hasError={Boolean(meta.touched && meta.error)}
                  label={formatMessage({
                    id: 'Screens.Doctors.Create.TitleDoctor',
                    defaultMessage: 'Error.Screens.Doctors.Create.TitleDoctor',
                  })}
                  meta={meta}
                  name={field.name}
                  onChange={(opt) => {
                    form.setFieldValue(field.name, opt.value);
                  }}
                  options={titles}
                />
              )}
            </Field>
          </div>
          <div className='col-lg-4'>
            <FormInput
              Component={FastField}
              hasRawError
              label={formatMessage({
                id: 'Model.User.FirstName',
                defaultMessage: 'Error.Model.User.FirstName',
              })}
              name='firstname'
              type='text'
              required
            />
          </div>
          <div className='col-lg-4'>
            <FormInput
              Component={FastField}
              hasRawError
              label={formatMessage({
                id: 'Model.User.LastName',
                defaultMessage: 'Error.Model.User.LastName',
              })}
              name='lastname'
              type='text'
              required
            />
          </div>
        </div>
        <FormInput
          Component={FastField}
          hasRawError
          label={formatMessage({
            id: 'Shared.Email',
            defaultMessage: 'Error.Shared.Email',
          })}
          name='email'
          type='text'
          required
        />
        <FormInput
          id='birthdate'
          Component={FastField}
          hasRawError
          label={formatMessage({
            id: 'Model.User.BirthDate',
            defaultMessage: 'Error.Model.User.BirthDate',
          })}
          name='birthdate'
          type='date'
          required
        />
        <div className='row'>
          <div className='col-lg-6'>
            <FormIntlTelInput
              id='phone-number'
              labelId='Doctors.PhoneNumber'
              name='phoneNumber'
            />
          </div>
          <div className='col-lg-6'>
            <FormIntlTelInput
              id='mobile-number'
              labelId='Model.User.MobilePhone'
              name='mobileNumber'
              required
              {...{ locale }}
            />
          </div>
        </div>
        <Field
          label={formatMessage({
            id: 'Model.User.Address1',
            defaultMessage: 'Error.Model.User.Address1',
          })}
          name='address1'
          placeholder={formatMessage({
            id: 'Model.User.Address1',
            defaultMessage: 'Error.Model.User.Address1',
          })}
          component={FormPlacesAutoComplete}
          required
        />
        <FormInput
          id='address2'
          label={formatMessage({
            id: 'Model.User.Address2',
            defaultMessage: 'Error.Model.User.Address2',
          })}
          placeholder={formatMessage({
            id: 'Model.User.Address2',
            defaultMessage: 'Error.Model.User.Address2',
          })}
          name='address2'
          type='text'
        />
        <div className='row'>
          <div className='col-md-6'>
            <FormInput
              Component={FastField}
              hasRawError
              label={formatMessage({
                id: 'Model.User.ZipCode',
                defaultMessage: 'Error.Model.User.ZipCode',
              })}
              name='zipCode'
              type='text'
              required
            />
          </div>
          <div className='col-md-6'>
            <FormInput
              Component={FastField}
              hasRawError
              label={formatMessage({
                id: 'Model.User.City',
                defaultMessage: 'Error.Model.User.City',
              })}
              name='city'
              type='text'
              required
            />
          </div>
        </div>
        <FormGroup check inline className='d-flex mb-3'>
          <Field name='valid'>
            {({ field }) => (
              <Checkbox
                {...field}
                checked={values.valid}
                id='operatorInscriptionConsent'
              >
                <Translation id='Screens.Doctors.Create.ValidAccount' />
              </Checkbox>
            )}
          </Field>
          <FormInputFeedback name='valid' />
        </FormGroup>
      </div>
      <div className='col-lg-6'>
        <div className='mb-3'>
          <Translation id='Screens.Doctors.Create.ProInfo' tagName='h2' />
        </div>
        {!isNil(doctorDirectoryData) && (
          <LegalInformation data={doctorDirectoryData} />
        )}
        {type === 'update' ? null : (
          <>
            {areProfilesLoading ? (
              <Spinner color='primary' size='sm' />
            ) : (
              <Field name='selectedProfiles'>
                {({ field, form, meta }) => (
                  <FormSelect
                    {...{ meta }}
                    {...field}
                    hasError={Boolean(meta.touched && meta.error)}
                    label={formatMessage({
                      id: 'Model.Doctor.SelectProfiles',
                      defaultMessage: 'Error.Model.Doctor.SelectProfiles',
                    })}
                    isMulti
                    name={field.name}
                    onChange={(opt) => {
                      if (opt === null) form.setFieldValue(field.name, []);
                      else form.setFieldValue(field.name, opt);
                    }}
                    options={profiles.map((element) => {
                      return {
                        label: `${element.label} (${element.code})`,
                        value: element.idProfile,
                      };
                    })}
                    required
                  />
                )}
              </Field>
            )}
          </>
        )}
        <div className='row'>
          {areContractTypeLoading ? (
            <Spinner color='primary' size='sm' />
          ) : (
            <div className='col-md-6'>
              <Field name='feesContractTypeId'>
                {({ field, meta, form }) => (
                  <FormSelect
                    {...{ meta }}
                    hasError={Boolean(meta.touched && meta.error)}
                    label={formatMessage({
                      id: 'Screens.Doctors.Read.ContractType',
                      defaultMessage: 'Error.Screens.Doctors.Read.ContractType',
                    })}
                    name={field.name}
                    onChange={(opt) => {
                      form.setFieldValue(field.name, opt.value);
                    }}
                    options={contractType.map((element) => {
                      return {
                        label: element.label,
                        value: element.id.toString(),
                      };
                    })}
                    required
                  />
                )}
              </Field>
            </div>
          )}
          {areOrdinalQualificationsLoading ? (
            <Spinner color='primary' size='sm' />
          ) : (
            <div className='col-md-6'>
              <Field name='ordinalQualificationId'>
                {({ field, meta, form }) => (
                  <FormSelect
                    {...{ meta }}
                    hasError={Boolean(meta.touched && meta.error)}
                    label={formatMessage({
                      id: 'Screens.Doctors.Read.OrdinalQualification',
                      defaultMessage:
                        'Error.Screens.Doctors.Read.OrdinalQualification',
                    })}
                    name={field.name}
                    onChange={(opt) => {
                      form.setFieldValue(field.name, opt.value);
                    }}
                    options={ordinalQualifications.map((element) => {
                      return {
                        label: element.label,
                        value: element.id.toString(),
                      };
                    })}
                    required
                  />
                )}
              </Field>
            </div>
          )}
        </div>
        <div className='bg-light rounded p-3 mb-3'>
          <FormLabel required>
            {formatMessage({
              id: 'Menu.FeeNotes',
              defaultMessage: 'Error.Menu.FeeNotes',
            })}
          </FormLabel>
          <div>
            <FormGroup check inline>
              <Field
                id='generateFeesNotes-true'
                name='generateFeesNotes'
                component={FormRadioButton}
                inputValue='true'
                label={formatMessage({
                  id: 'Model.Doctor.Generated',
                  defaultMessage: 'Error.Model.Doctor.Generated',
                })}
              />
            </FormGroup>
            <FormGroup check inline>
              <Field
                id='generateFeesNotes-false'
                name='generateFeesNotes'
                component={FormRadioButton}
                inputValue='false'
                label={formatMessage({
                  id: 'Model.Doctor.NotGenerated',
                  defaultMessage: 'Error.Model.Doctor.NotGenerated',
                })}
              />
            </FormGroup>
            {errors.generateFeesNotes && (
              <p className='text-danger small m-0'>
                {errors.generateFeesNotes}
              </p>
            )}
          </div>
        </div>
        <FormGroup check inline className='d-flex mb-3'>
          <Field name='available'>
            {({ field }) => (
              <Checkbox {...field} checked={values.available} id='validAccount'>
                <Translation id='Model.Doctor.Available' />
              </Checkbox>
            )}
          </Field>
          {/* valinfos ? */}
          <FormInputFeedback name='valinfosDoctor.available' />
        </FormGroup>
        <FormInput
          label={formatMessage({
            id: 'Model.Doctor.Nationality',
            defaultMessage: 'Error.Model.Doctor.Nationality',
          })}
          name='nationality'
          type='text'
        />
        <FormInput
          label={formatMessage({
            id: 'Model.Doctor.SigningMessages',
            defaultMessage: 'Error.Model.Doctor.SigningMessages',
          })}
          name='signature'
          type='text'
        />
        <FormInput
          label={formatMessage({
            id: 'Model.Doctor.Function',
            defaultMessage: 'Error.Model.Doctor.Function',
          })}
          name='fonction'
          type='text'
        />
        <div className='bg-light rounded p-3 mb-3'>
          <FormLabel required>
            {formatMessage({
              id: 'Screens.Doctors.Read.CardCPS',
              defaultMessage: 'Error.Screens.Doctors.Read.CardCPS',
            })}
          </FormLabel>
          <div>
            <FormGroup check inline>
              <Field
                id='cpsCard-true'
                name='cpsCard'
                component={FormRadioButton}
                inputValue='true'
                label={formatMessage({
                  id: 'Shared.Yes',
                  defaultMessage: 'Error.Shared.Yes',
                })}
              />
            </FormGroup>
            <FormGroup check inline>
              <Field
                id='cpsCard-false'
                name='cpsCard'
                component={FormRadioButton}
                inputValue='false'
                label={formatMessage({
                  id: 'Shared.No',
                  defaultMessage: 'Error.Shared.No',
                })}
              />
            </FormGroup>
            <FormGroup check inline>
              <Field
                id='cpsCard-null'
                name='cpsCard'
                component={FormRadioButton}
                inputValue='null'
                label={formatMessage({
                  id: 'Model.Doctor.NotSpecified',
                  defaultMessage: 'Error.Model.Doctor.NotSpecified',
                })}
              />
            </FormGroup>
            {errors.infosDoctor?.cpsCard && (
              <p className='text-danger small m-0'>{errors.cpsCard}</p>
            )}
          </div>
        </div>
        <div className='bg-light rounded p-3 mb-3'>
          <FormLabel required>
            {formatMessage({
              id: 'Screens.Doctors.Read.ReadCPS',
              defaultMessage: 'Error.Screens.Doctors.Read.ReadCPS',
            })}
          </FormLabel>
          <div>
            <FormGroup check inline>
              <Field
                id='cpsReader-true'
                name='cpsReader'
                component={FormRadioButton}
                inputValue='true'
                label={formatMessage({
                  id: 'Shared.Yes',
                  defaultMessage: 'Error.Shared.Yes',
                })}
              />
            </FormGroup>
            <FormGroup check inline>
              <Field
                id='cpsReader-false'
                name='cpsReader'
                component={FormRadioButton}
                inputValue='false'
                label={formatMessage({
                  id: 'Shared.No',
                  defaultMessage: 'Error.Shared.No',
                })}
              />
            </FormGroup>
            <FormGroup check inline>
              <Field
                id='cpsReader-null'
                name='cpsReader'
                component={FormRadioButton}
                inputValue='null'
                label={formatMessage({
                  id: 'Model.Doctor.NotSpecified',
                  defaultMessage: 'Error.Model.Doctor.NotSpecified',
                })}
              />
            </FormGroup>
            {errors.infosDoctor?.cpsReader && (
              <p className='text-danger small m-0'>{errors.cpsReader}</p>
            )}
          </div>
        </div>
        <div className='bg-light rounded p-3 mb-3'>
          <FormLabel required>
            {formatMessage({
              id: 'Screens.Doctors.Read.PrescriptionPad',
              defaultMessage: 'Error.Screens.Doctors.Read.PrescriptionPad',
            })}
          </FormLabel>
          <div>
            <FormGroup check inline>
              <Field
                id='prescriptionBook-true'
                name='prescriptionBook'
                component={FormRadioButton}
                inputValue='true'
                label={formatMessage({
                  id: 'Shared.Yes',
                  defaultMessage: 'Error.Shared.Yes',
                })}
              />
            </FormGroup>
            <FormGroup check inline>
              <Field
                id='prescriptionBook-false'
                name='prescriptionBook'
                component={FormRadioButton}
                inputValue='false'
                label={formatMessage({
                  id: 'Shared.No',
                  defaultMessage: 'Error.Shared.No',
                })}
              />
            </FormGroup>
            <FormGroup check inline>
              <Field
                id='prescriptionBook-null'
                name='prescriptionBook'
                component={FormRadioButton}
                inputValue='null'
                label={formatMessage({
                  id: 'Model.Doctor.NotSpecified',
                  defaultMessage: 'Error.Model.Doctor.NotSpecified',
                })}
              />
            </FormGroup>
            {errors.infosDoctor?.prescriptionBook && (
              <p className='text-danger small m-0'>{errors.prescriptionBook}</p>
            )}
          </div>
        </div>
        <FormInput
          label={formatMessage({
            id: 'Model.Doctor.Comments',
            defaultMessage: 'Error.Model.Doctor.Comments',
          })}
          name='comments'
          type='text'
        />
        <FormGroup className='m-0'>
          <FormLabel>
            {formatMessage({
              id: 'Screens.Model.Doctors.Signature',
              defaultMessage: 'Error.Screens.Model.Doctors.Signature',
            })}
          </FormLabel>
        </FormGroup>
        <FormInputLogo
          defaultImage={
            doctorDocument?.succeeded !== false && doctorDocument?.length !== 0
              ? doctorDocument
              : undefined
          }
          name='refSignature'
          setFormLogoData={setFormLogoData}
        />
      </div>
    </div>
  );

  return (
    <>
      <div className='row'>
        <div className='col-lg-6'>
          <Field name='practitionerIdentifierNumberType'>
            {({ field, meta, form }) => (
              <FormSelect
                {...{ meta }}
                hasError={Boolean(meta.touched && meta.error)}
                label={formatMessage({
                  id: 'Screens.Doctors.Create.AccountType',
                  defaultMessage: 'Error.Screens.Doctors.Create.AccountType',
                })}
                name={field.name}
                onChange={(opt) => {
                  // Resets field values fetched through the health directory
                  form.setValues((formikValues) => {
                    return {
                      ...formikValues,
                      [field.name]: opt.value,
                      collegeNumber: '',
                      collegeNumberAutocomplete: '',
                      firstname: '',
                      lastname: '',
                      title: '',
                    };
                  });
                  setDoctorDirectoryData(undefined);
                }}
                options={DoctorAccountTypes.map((element) => {
                  return {
                    code: element.code,
                    label: element.label,
                    value: element.code,
                  };
                })}
                required
              />
            )}
          </Field>
        </div>
      </div>
      {values.practitionerIdentifierNumberType ===
      '' ? undefined : values.practitionerIdentifierNumberType === 'PN' ? (
        renderMainFields()
      ) : (
        <>
          <div className='row'>
            <div className='col-lg-6'>
              <RPPSInput
                setDoctorDirectoryData={setDoctorDirectoryData}
                // we give a key to this component in order to re-render it
                // each time the account type is modified by the user
                key={values.practitionerIdentifierNumberType}
                accountType={values.practitionerIdentifierNumberType}
              />
            </div>
          </div>
          {values.healthProfessionalSelected || type === 'update'
            ? renderMainFields()
            : undefined}
        </>
      )}
    </>
  );
};

export default Doctor;
