import { defineMessages } from 'react-intl';

export default defineMessages({
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
    description: 'Toast title in case of Error',
  },
  FailBody: {
    id: 'Sreens.OrganizationConfigs.Create.FailBody',
    defaultMessage: 'Error.Sreens.OrganizationConfigs.Create.FailBody',
    description: 'Toast body in case of error',
  },
  SuccessTitle: {
    id: 'Message.Success',
    defaultMessage: 'Error.Message.Success',
    description: 'Toast title in case of success',
  },
  SuccessBody: {
    id: 'Sreens.OrganizationConfigs.Create.SuccessBody',
    defaultMessage: 'Error.Sreens.OrganizationConfigs.Create.SuccessBody',
    description: 'Toast body in case of success',
  },
  Title: {
    id: 'Screens.OrganizationConfigs.Create.Title',
    defaultMessage: 'Error.Screens.Universes.Create.Title',
  },
});
