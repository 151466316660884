import styled from 'styled-components';

const ErrorMessageContent = styled.div`
  ${({ theme }) => `
    color: ${theme.color('danger')};
    ${theme.fontSize(theme.fontSizeSm())}
`}
`;

const BooleanCardErrorWrapper = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme.space(2)}
`}
`;

export { ErrorMessageContent, BooleanCardErrorWrapper };
