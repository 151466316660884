import { defineMessages } from 'react-intl';

export default defineMessages({
  Title: {
    id: 'Screens.ProductTerms.Create.Title',
    defaultMessage: 'Error.Screens.ProductTerms.Create.Title',
    description: 'ProductTerms create page title',
  },
  FailTitle: {
    id: 'Message.Error',
    defaultMessage: 'Error.Message.Error',
    description: 'Toast title in case of error',
  },
  SuccessTitle: {
    id: 'Message.Success',
    defaultMessage: 'Error.Message.Success',
    description: 'Toast title in case of success',
  },
  SuccessBody: {
    id: 'Screens.ProductTerms.Create.SuccessBody',
    defaultMessage: 'Error.Screens.ProductTerms.Create.SuccessBody',
    description: 'Toast body in case of success',
  },
});
