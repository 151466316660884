import styled, { css } from 'styled-components';
import { Button } from 'reactstrap';

const ButtonWrapper = styled(Button)`
  ${({ theme }) => css`
    position: relative;
  `}
`;

const Flex = styled.div`
  ${({ isProcessing, theme }) => css`
    align-items: center;
    display: flex;
    justify-content: center;

    * {
      opacity: ${isProcessing ? 0 : 1};
    }

    i {
      margin-right: ${theme.space(2)};
    }
  `}
`;

const SpinnerWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  `}
`;

ButtonWrapper.Flex = Flex;
ButtonWrapper.SpinnerWrapper = SpinnerWrapper;

export default ButtonWrapper;
