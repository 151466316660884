import React, { useState } from 'react';
import { Input, Col, Row, Collapse, FormGroup } from 'reactstrap';
import { FastField, Field } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  Button,
  Checkbox,
  FormInput,
  FormInputFeedback,
  FormIntlTelInput,
  FormLabel,
  FormPlacesAutoComplete,
  FormRadioButton,
  FormSelect,
  Spinner,
} from 'components';

import { api, useFetch } from 'utils';
import { CodeCompany } from '../../constants';
import { GreyBlockWrapper } from './elements';

import messages from './messages';

const Admin = ({ isUpdating }) => {
  const [universes, areUniversesLoading] = useFetch(api.universes.getListItems);
  const [countries, areCountriesLoading] = useFetch(api.identity.getCountries);
  const [isAdressCollapseOpen, setAdressCollapseOpen] = useState(false);
  const { formatMessage } = useIntl();
  const placeholders = {
    mobileNumber: '6 12 34 56 78',
    phoneNumber: '1 12 34 56 78',
  };

  return (
    <>
      <Row>
        <Col md='6'>
          <FormInput
            Component={FastField}
            hasRawError
            id='firstname'
            label={formatMessage({ ...messages.FirstName })}
            name='firstname'
            required
            type='text'
          />
        </Col>
        <Col md='6'>
          <FormInput
            Component={FastField}
            hasRawError
            id='lastname'
            label={formatMessage({ ...messages.LastName })}
            name='lastname'
            required
            type='text'
          />
        </Col>
      </Row>
      <FormInput
        Component={FastField}
        hasRawError
        id='email'
        label={formatMessage({ ...messages.Email })}
        name='email'
        required
        type='email'
      />
      <FormInput
        Component={FastField}
        id='birthdate'
        hasRawError
        label={formatMessage({ ...messages.BirthDate })}
        name='birthdate'
        required
        type='date'
      />
      <Row>
        <Col md='6'>
          <FormIntlTelInput
            customPlaceholder={placeholders.phoneNumber}
            id='phoneNumber'
            labelId='Shared.Phone'
            name='phoneNumber'
            type='text'
          />
        </Col>
        <Col md='6'>
          <FormIntlTelInput
            customPlaceholder={placeholders.mobileNumber}
            id='cellphoneNumber'
            labelId='Shared.Mobile'
            name='mobileNumber'
            type='text'
          />
        </Col>
      </Row>
      <Field
        name='address1'
        label={formatMessage({ ...messages.AdressChoice })}
        placeholder={formatMessage({ ...messages.AdressChoicePlaceholder })}
        component={FormPlacesAutoComplete}
        disabled={isAdressCollapseOpen}
      />
      <FormInput
        id='additionalAdress'
        type='text'
        name='address2'
        label={formatMessage({ ...messages.AdditionalAdress })}
      />
      <Button
        className='mb-2'
        type='button'
        color='primary'
        size='sm'
        outline
        label='ShowFullAdress'
        onClick={() => setAdressCollapseOpen(!isAdressCollapseOpen)}
      />
      <Collapse isOpen={isAdressCollapseOpen}>
        <FormInput
          id='adress'
          label={formatMessage({ ...messages.Adress })}
          name='address1'
          type='text'
        />
        <Row>
          <Col md='6'>
            <FormInput
              id='postalCode'
              label={formatMessage({ ...messages.PostalCode })}
              name='zipCode'
              type='text'
            />
          </Col>
          <Col md='6'>
            <FormInput
              id='city'
              label={formatMessage({ ...messages.City })}
              name='city'
              type='text'
            />
          </Col>
        </Row>
        {areCountriesLoading ? (
          <Spinner />
        ) : (
          <FormGroup>
            <Field name='codeCountry' id='codeCountry'>
              {({ field }) => (
                <>
                  <FormLabel>
                    <FormattedMessage {...messages.Country} />
                  </FormLabel>
                  <Input {...field} type='select'>
                    {countries.map((country) => (
                      <option key={country.label} value={country.code}>
                        {country.label}
                      </option>
                    ))}
                  </Input>
                </>
              )}
            </Field>
          </FormGroup>
        )}
      </Collapse>
      <div className='mb-3'>
        <Field name='valid'>
          {({ field, form }) => (
            <Checkbox
              {...field}
              checked={form.values.valid}
              id='valid'
              required
            >
              {formatMessage({ ...messages.Valid })}
            </Checkbox>
          )}
        </Field>
        <FormInputFeedback name='valid' />
      </div>
      <GreyBlockWrapper>
        <FormLabel required>
          <FormattedMessage {...messages.Notification} />
        </FormLabel>
        <div>
          <FormGroup check inline>
            <Field
              name='notification'
              id='notificationEmail'
              component={FormRadioButton}
              inputValue='EMAIL'
              label={formatMessage(messages.NotificationEmail)}
            />
          </FormGroup>
          <FormGroup check inline>
            <Field
              name='notification'
              id='notificationSMS'
              component={FormRadioButton}
              inputValue='SMS'
              label={formatMessage(messages.NotificationSMS)}
            />
          </FormGroup>
          <FormGroup check inline>
            <Field
              name='notification'
              id='notificationPush'
              component={FormRadioButton}
              inputValue='PUSH'
              label={formatMessage(messages.NotificationPush)}
            />
          </FormGroup>
          <FormGroup check inline>
            <Field
              name='notification'
              id='notificationSMSEmail'
              component={FormRadioButton}
              inputValue='SMSEMAIL'
              label={formatMessage(messages.NotificationSMSEmail)}
            />
          </FormGroup>
          <FormGroup check inline>
            <Field
              name='notification'
              id='notificationNone'
              component={FormRadioButton}
              inputValue='NONE'
              label={formatMessage(messages.NotificationNone)}
            />
          </FormGroup>
        </div>
      </GreyBlockWrapper>
      <FormGroup>
        <Field id='organization' name='refUniverse'>
          {({ field, meta, form }) =>
            areUniversesLoading ? (
              <Spinner />
            ) : (
              <FormSelect
                placeholder={formatMessage({ ...messages.SelectOrganization })}
                hasError={Boolean(meta.touched && meta.error)}
                label={formatMessage({ ...messages.OrganizationConfig })}
                meta={meta}
                name={field.name}
                onBlur={() => form.setFieldTouched(field.name, true)}
                onChange={(opt) => {
                  form.setFieldValue(field.name, opt.value);
                }}
                options={universes.map((organization) => {
                  return {
                    code: organization.code,
                    label: `${organization.label} (${organization.code})`,
                    value: organization.id,
                  };
                })}
                required
              />
            )
          }
        </Field>
      </FormGroup>
      <FormGroup>
        <Field id='codeCompany' name='infosAdmin.codeCompany'>
          {({ field, meta, form }) => (
            <FormSelect
              placeholder={formatMessage({ ...messages.SelectCompany })}
              hasError={Boolean(meta.touched && meta.error)}
              label={formatMessage({ ...messages.CodeCompany })}
              meta={meta}
              name={field.name}
              onBlur={() => form.setFieldTouched(field.name, true)}
              onChange={(opt) => {
                form.setFieldValue(field.name, opt.value);
              }}
              options={Object.entries(CodeCompany).map(([key, val]) => {
                return {
                  code: key,
                  label: val,
                  value: val,
                };
              })}
            />
          )}
        </Field>
      </FormGroup>
      <FormInput
        Component={FastField}
        id='signature'
        label={formatMessage({ ...messages.Signature })}
        name='infosAdmin.description'
        type='textarea'
      />
    </>
  );
};

export default Admin;
