import { defineMessages } from 'react-intl';

import messages from '../messages';

export default defineMessages({
  ...messages,
  ClientsPlaceholder: {
    id: 'Components.FormList.ClientsPlaceholder',
    defaultMessage: 'Error.Components.FormList.ClientsPlaceholder',
  },
  Title: {
    id: 'Screens.PartnerOperators.Update.Title',
    defaultMessage: 'Error.Screens.PartnerOperators.Update.Title',
  },
  ToastClientsFail: {
    id: 'Toasts.PartnerOperators.Update.Clients.Fail',
    defaultMessage: 'Error.Toasts.PartnerOperators.Update.Clients.Fail',
  },
  ToastClientsSuccess: {
    id: 'Toasts.PartnerOperators.Update.Clients.Success',
    defaultMessage: 'Error.Toasts.PartnerOperators.Update.Clients.Success',
  },
  ToastFail: {
    id: 'Toasts.PartnerOperators.Update.Fail',
    defaultMessage: 'Error.Toasts.PartnerOperators.Update.Fail',
  },
  ToastSuccess: {
    id: 'Toasts.PartnerOperators.Update.Success',
    defaultMessage: 'Error.Toasts.PartnerOperators.Update.Success',
  },
});
