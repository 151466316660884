import React from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import { Spinner } from 'reactstrap';

import { ApiError, Button, FormList } from 'components';
import { service } from 'services';
import { api, sendToast, useFetch } from 'utils';
import messages from '../messages';
import { ToastTypes } from '../../../../constants';

const Profiles = ({ data }) => {
  const adminId = useParams().adminRef;
  const { formatMessage } = useIntl();
  const [profiles, areProfilesLoading] = useFetch(api.profiles.getListItems, {
    userType: 'ADMIN',
  });

  const onSubmit = (values, { setSubmitting }) => {
    service
      .put(api.administrators.putProfiles(values.idUser), values)
      .then((res) => {
        if (typeof res.data !== 'undefined' && res.data.succeeded === false) {
          sendToast(ToastTypes.error, {
            header: <FormattedMessage {...messages.ToastProfilesFail} />,
          });
          return;
        }
        sendToast(ToastTypes.success, {
          header: <FormattedMessage {...messages.ToastProfilesSuccess} />,
        });
      })
      .catch((error) => {
        sendToast(ToastTypes.error, {
          header: <FormattedMessage {...messages.ToastProfilesFail} />,
          body: <ApiError {...{ error }} />,
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  if (areProfilesLoading) return <Spinner color='primary' size='lg' />;

  return (
    <Formik
      initialValues={{
        idUser: adminId,
        selectedProfiles: data,
      }}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <Form>
          <FormList
            data={profiles}
            itemLabelKey='label'
            itemValueKey='id'
            name='selectedProfiles'
            researchOptions={{
              isEnabled: true,
              placeholder: formatMessage({ ...messages.ProfilesPlaceholder }),
            }}
          />
          <Button
            color='primary'
            isProcessing={formikProps.isSubmitting}
            label='Save'
            type='submit'
          />
        </Form>
      )}
    </Formik>
  );
};

export default Profiles;
