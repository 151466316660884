import { defineMessages } from 'react-intl';

export default defineMessages({
  ProductTermsLanguage: {
    id: 'ProductTerms.Language',
    defaultMessage: 'Error.ProductTerms.Language',
  },
  ProductTermsCP: {
    id: 'ProductTerms.CP',
    defaultMessage: 'Error.ProductTerms.CP',
  },
  FieldRequired: {
    id: 'Shared.FieldRequired',
    defaultMessage: 'Error.Shared.FieldRequired',
  },
});
