import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useWindowScroll } from 'react-use';

import messages from './messages';

const PageHeader = ({ children }) => {
  const history = useHistory();
  const intl = useIntl();
  const ref = useRef(null);
  const { y } = useWindowScroll();
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, [setHeight]);

  return (
    <header className='page-header' ref={ref}>
      <div
        className={`page-header__content ${
          y > height ? 'page-header__content--sticky' : ''
        }`}
      >
        <div>
          <button
            aria-label={intl.formatMessage({ ...messages.ButtonBack })}
            className='page-header__btn-back'
            onClick={() => {
              history.goBack();
            }}
            type='button'
          >
            <i aria-hidden className='uil uil-arrow-left uil--lg' />
          </button>
        </div>
        {children}
      </div>
    </header>
  );
};

PageHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageHeader;
