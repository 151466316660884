import styled from 'styled-components';

const ToastContainerWrapper = styled.div`
  ${({ theme }) => `
    .Toastify {
      &__toast-container {
        padding: ${theme.space(2)};

        @media ${theme.screenMdMin}{
          padding: 0;
          width: ${theme.toastWidth};
        }

        .Toastify__toast {
          margin-top: ${theme.space(2)};
          border: 1px solid ${theme.color('gray-lightest')};
          background-color: ${theme.white};
          padding: ${theme.space(2)} 0;
          border-radius: ${theme.space(2)};
          box-shadow: 
            0
            0 
            ${theme.space(2)} 
            ${theme.grayShade(400)};
          
          @media ${theme.screenMdMin}{
            margin-top: 0;
          }

          &-icon {
            margin-left: 1rem;
            margin-right: 1rem;
          }

          &-body {
            > p {
              margin-bottom: 0;
            }
          }
        }
      }
    };
  `}
`;

export default ToastContainerWrapper;
