import { defineMessages } from 'react-intl';
export default defineMessages({
  Language: {
    id: 'Screens.TemplateMessages.Details.Language',
    defaultMessage: 'Error.Screens.TemplateMessages.Details.Language',
  },
  Name: {
    id: 'Screens.TemplateMessages.Details.Name',
    defaultMessage: 'Error.Screens.TemplateMessages.Details.Name',
  },
  Object: {
    id: 'Screens.TemplateMessages.Details.Object',
    defaultMessage: 'Error.Screens.TemplateMessages.Details.Object',
  },
  Organization: {
    id: 'Screens.TemplateMessages.Details.Organization',
    defaultMessage: 'Error.Screens.TemplateMessages.Details.Organization',
  },
  Title: {
    id: 'Screens.TemplateMessages.Details.Title',
    defaultMessage: 'Error.Screens.TemplateMessages.Details.Title',
  },
  ToastFail: {
    id: 'Toasts.Request.Fail',
    defaultMessage: 'Error.Toasts.Request.Fail',
  },
  ToastSuccess: {
    id: 'Toasts.TemplateMessages.Create.Success',
    defaultMessage: 'Error.Toasts.TemplateMessages.Create.Success',
  },
  Type: {
    id: 'Screens.TemplateMessages.Details.Type',
    defaultMessage: 'Error.Screens.TemplateMessages.Details.Type',
  },
  UpdateButton: {
    id: 'Buttons.Edit',
    defaultMessage: 'Error.Buttons.Edit',
  },
});
