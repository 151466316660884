import * as yup from 'yup';

const validationSchema = yup.object().shape({
  roomChannel: yup
    .string('Screens.Offers.Create.CreateRoomForm.ChannelRequired')
    .nullable()
    .required('Screens.Offers.Create.CreateRoomForm.ChannelRequired'),
});

export default validationSchema;
